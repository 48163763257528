export const setupMocks = async () => {
  const enableMock = process.env.REACT_APP_MOCK_RESPONSES === 'true';
  if (enableMock) {
    // compiler hung up on this import if you don't have mocks built
    // even if the env var is not true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { worker } = await import('@kinetic-ui/mocks');
    worker.start({
      serviceWorker: { url: `${process.env.PUBLIC_URL}/mockServiceWorker.js` },
    });
  }
};
