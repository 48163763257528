import {
  useDeleteUserTenantPreferenceMutation,
  useKineticUserQuery,
  useSaveUserPrefMutation,
} from 'generated/graphql';
import { useCallback } from 'react';
import { PREFERENCE_KEYS } from 'types/preferenceTypes';

/** Low level hook that grabs all the user tenant scoped preferences, cache-first */
export const useUserTenantPreferences = <TValue = Record<string, unknown>>() => {
  const { data, loading } = useKineticUserQuery({ fetchPolicy: 'cache-first' });
  const [savePref] = useSaveUserPrefMutation();
  const [deletePref] = useDeleteUserTenantPreferenceMutation();

  const setPreference = useCallback(
    (key: PREFERENCE_KEYS, value: TValue) => {
      return savePref({
        variables: { key, value },
      });
    },
    [savePref]
  );

  const deletePreference = useCallback(
    (key: PREFERENCE_KEYS) => {
      return deletePref({ variables: { key } });
    },
    [deletePref]
  );

  return {
    data: data?.kineticUser.preferences,
    loading,
    setPreference,
    deletePreference,
  };
};
