import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  LocalTime: any;
  Object: any;
  _Any: any;
  _FieldSet: any;
};

export enum AtspmSignalReport {
  ApproachDelay = 'APPROACH_DELAY',
  ApproachVolume = 'APPROACH_VOLUME',
  ArrivalsOnRed = 'ARRIVALS_ON_RED',
  FlowProfile = 'FLOW_PROFILE',
  Pcd = 'PCD',
  PhaseTerm = 'PHASE_TERM',
  SplitFailure = 'SPLIT_FAILURE',
  SplitMonitor = 'SPLIT_MONITOR',
  TurningMovement = 'TURNING_MOVEMENT',
  YellowAndRedViolations = 'YELLOW_AND_RED_VIOLATIONS'
}

export type AcceptedWord = {
  __typename?: 'AcceptedWord';
  /**  Identifier of the accepted word  */
  id: Scalars['ID'];
  /** The accepted word  */
  word: Scalars['String'];
};

export type AcceptedWordInput = {
  /**  Identifier of the accepted word  */
  id?: InputMaybe<Scalars['ID']>;
  /** The accepted word  */
  word: Scalars['String'];
};

export type Action = {
  __typename?: 'Action';
  route?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toolTip?: Maybe<Scalars['String']>;
};

export type ActionInput = {
  route?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  toolTip?: InputMaybe<Scalars['String']>;
};

export type ActionSetRule = {
  __typename?: 'ActionSetRule';
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  identifier for event form  */
  formId: Scalars['String'];
  /**  rule identifier  */
  id: Scalars['ID'];
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  rule tree  */
  tree: Scalars['Object'];
};

export type ActionSetRuleConnection = {
  __typename?: 'ActionSetRuleConnection';
  /**  connection edges  */
  edges: Array<ActionSetRuleConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type ActionSetRuleConnectionEdge = {
  __typename?: 'ActionSetRuleConnectionEdge';
  /**  cursor position  */
  cursor?: Maybe<Scalars['String']>;
  /**  node  */
  node: ActionSetRule;
};

export type ActionSetRuleInput = {
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  identifier for event form  */
  formId: Scalars['String'];
  /**  rule identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  rule tree  */
  tree: Scalars['Object'];
};

export type ActionSetRuleInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ActivationType {
  /**  Event will be automatically activated  */
  Automatic = 'AUTOMATIC',
  /**  Event will require confirmation before activating  */
  ConfirmationRequired = 'CONFIRMATION_REQUIRED',
  /**  Event will be manually activated  */
  Manual = 'MANUAL'
}

export type ActivityLog = {
  __typename?: 'ActivityLog';
  action: Scalars['String'];
  changes?: Maybe<Array<ActivityLogChange>>;
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  module: KineticModule;
  object?: Maybe<Scalars['Object']>;
  timestamp: Scalars['DateTime'];
  type?: Maybe<Scalars['String']>;
  user?: Maybe<OrganizationKineticUser>;
  userFullName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ActivityLogChange = {
  __typename?: 'ActivityLogChange';
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  operation?: Maybe<ActivityLogChangeType>;
  path?: Maybe<Scalars['String']>;
};

export enum ActivityLogChangeType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection';
  edges: Array<ActivityLogEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ActivityLog;
};

export type ActivityLogInput = {
  action: Scalars['String'];
  description: Scalars['String'];
  entityId: Scalars['ID'];
  module: KineticModule;
  type?: InputMaybe<Scalars['String']>;
};

export type ActivityLogName = {
  __typename?: 'ActivityLogName';
  id: Scalars['ID'];
  integrationType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ActivityLogNameConnection = {
  __typename?: 'ActivityLogNameConnection';
  edges: Array<ActivityLogNameEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ActivityLogNameEdge = {
  __typename?: 'ActivityLogNameEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ActivityLogName;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  count?: Maybe<CountAggregation>;
  name?: Maybe<Scalars['String']>;
  sum?: Maybe<SumAggregation>;
  values?: Maybe<Array<Maybe<ValueAggregation>>>;
};

export type AggregationInput = {
  /**  Field to aggregate on. Can use JSON Path notation. */
  field?: InputMaybe<Scalars['String']>;
  /**  Filters to apply to the historical data before aggregation */
  filters?: InputMaybe<Array<FilterInput>>;
  /**  Range to enclose aggregations */
  interval: AggregationIntervalInput;
  /**  Name of the aggregation to compute, e.g. "Total Incidents" */
  name?: InputMaybe<Scalars['String']>;
  /**  Limit the number of VALUE aggregations */
  top?: InputMaybe<Scalars['Int']>;
  /**
   *  ISO8601 for the start date when computing comparative
   *  trend values for aggregations. If omitted, it will default
   *  to 3 periods before the input interval. E.g. if the interval
   *  is WEEKLY starting September 18th, this value will default
   *  to August 28th.
   */
  trendStart?: InputMaybe<Scalars['DateTime']>;
  /**  Type of aggregation to perform */
  type: AggregationType;
};

export type AggregationIntervalInput = {
  /**  ISO8601 datetime for the start of the interval */
  date: Scalars['DateTime'];
  mode: AggregationIntervalMode;
};

export enum AggregationIntervalMode {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum AggregationType {
  /**  Number of matches to a filter with the given field */
  Count = 'COUNT',
  /**  Sum of values for an aggregation. Only for numeric values */
  Sum = 'SUM',
  /**  Distinct values along with their total count */
  Values = 'VALUES'
}

export type Alarm = {
  __typename?: 'Alarm';
  /**  Is this alarm in its current alarming state acknowledged  */
  acknowledged: Scalars['Boolean'];
  /**  When the alarm was acknowledged  */
  acknowledgedAt?: Maybe<Scalars['DateTime']>;
  /**  User ID that acknowledged the alarm  */
  acknowledgedBy?: Maybe<Scalars['ID']>;
  /**  User name that acknowledged the alarm  */
  acknowledgedByDisplayName?: Maybe<Scalars['String']>;
  /**  Is this alarm currently going off  */
  active: Scalars['Boolean'];
  /**  When this alarm was activated  */
  activeAt?: Maybe<Scalars['DateTime']>;
  /**  The alarm's owning center, if any */
  centerId?: Maybe<Scalars['String']>;
  /**  When the alarm was cleared, if cleared  */
  clearedAt?: Maybe<Scalars['DateTime']>;
  /**  Additional comments tied to the acknowledgement  */
  comments?: Maybe<Scalars['String']>;
  /**  The configuration attached to this alarm  */
  config: AlarmConfig;
  /** Total number of times this alarm has been fired */
  count: Scalars['Int'];
  /**
   * Additional metadata associated with this alarm.
   * E.g. the device or intersection that triggered it,
   * index information for NTCIP alarms (Preempt, etc)
   */
  data?: Maybe<Scalars['Object']>;
  /**  Additional details about this alarm  */
  description?: Maybe<Scalars['String']>;
  /**  Display name of this alarm. E.g. `Communication Failure` */
  displayName?: Maybe<Scalars['String']>;
  /**  Unique identifier for the entity that triggered the alarm  */
  entityId: Scalars['ID'];
  /**  Type of entity that triggered the alarm, e.g. an intersection  */
  entityType: AlarmEntityType;
  id: Scalars['ID'];
  /**  Name of this alarm. E.g. `CommFail`  */
  name: Scalars['String'];
  /**
   * When this alarm was first received. If the alarm has not been received
   * since last activation, this will be null
   */
  receivedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Number of recurrences of this alarm. Includes both
   * recurrence count that activated it as well as the
   * number of recurrences since it's been active
   */
  recurrenceCount: Scalars['Int'];
  /**  Severity of the alarm when it was activated  */
  severity: AlarmSeverity;
  /**  Kinetic module that triggered the alarm  */
  source: KineticModule;
  /**
   * When the alarm was last modified
   * e.g. by acknowledgement, clearing, or a new count
   */
  updatedAt: Scalars['DateTime'];
};

export enum AlarmAcknowledgementType {
  AutomaticOnActivation = 'AUTOMATIC_ON_ACTIVATION',
  AutomaticOnDeactivation = 'AUTOMATIC_ON_DEACTIVATION',
  NoAcknowledgementPossible = 'NO_ACKNOWLEDGEMENT_POSSIBLE',
  Standard = 'STANDARD'
}

export type AlarmConfig = {
  __typename?: 'AlarmConfig';
  acknowledgementType: AlarmAcknowledgementType;
  alarmSeverity: AlarmSeverity;
  alarmType: AlarmType;
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  entityId?: Maybe<Scalars['ID']>;
  entityType?: Maybe<AlarmEntityType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  recurrenceCount?: Maybe<Scalars['Int']>;
  recurrencePeriod?: Maybe<Scalars['Int']>;
  recurrenceUnit?: Maybe<ChronoUnit>;
  source: KineticModule;
  systemNotification: Scalars['Boolean'];
  triggerOnRecurrence: Scalars['Boolean'];
  urgentNotification: Scalars['Boolean'];
};

export type AlarmConfigInput = {
  acknowledgementType: AlarmAcknowledgementType;
  alarmSeverity: AlarmSeverity;
  alarmType: AlarmType;
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  entityId?: InputMaybe<Scalars['ID']>;
  entityType?: InputMaybe<AlarmEntityType>;
  name: Scalars['String'];
  recurrenceCount?: InputMaybe<Scalars['Int']>;
  recurrencePeriod?: InputMaybe<Scalars['Int']>;
  recurrenceUnit?: InputMaybe<ChronoUnit>;
  source: KineticModule;
  systemNotification: Scalars['Boolean'];
  triggerOnRecurrence: Scalars['Boolean'];
  urgentNotification: Scalars['Boolean'];
};

export type AlarmConfigsConnection = {
  __typename?: 'AlarmConfigsConnection';
  edges: Array<AlarmConfigsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AlarmConfigsConnectionEdge = {
  __typename?: 'AlarmConfigsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: AlarmConfig;
};

export type AlarmConfigsQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter alarms.  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type AlarmConnection = {
  __typename?: 'AlarmConnection';
  edges: Array<AlarmConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AlarmConnectionEdge = {
  __typename?: 'AlarmConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Alarm;
};

export enum AlarmEntityType {
  Intersection = 'INTERSECTION',
  Sign = 'SIGN'
}

export enum AlarmSeverity {
  Critical = 'CRITICAL',
  Informational = 'INFORMATIONAL',
  Warning = 'WARNING'
}

export enum AlarmType {
  Custom = 'CUSTOM',
  Standard = 'STANDARD'
}

export type AlarmsQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter alarms.  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type Alert = {
  __typename?: 'Alert';
  alertId: Scalars['String'];
  confidence?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSON']>;
  location?: Maybe<Scalars['Object']>;
  severity?: Maybe<Severity>;
  source?: Maybe<AlertSource>;
  type?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['DateTime']>;
};

export enum AlertSource {
  Cad = 'CAD',
  Queue = 'QUEUE',
  TravelTime = 'TRAVEL_TIME',
  Waze = 'WAZE',
  Weather = 'WEATHER'
}

export type AlertState = {
  __typename?: 'AlertState';
  alertId?: Maybe<Scalars['String']>;
  alertStatus?: Maybe<Status>;
};

export type ArchivePartitionField = {
  __typename?: 'ArchivePartitionField';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArchivePartitionFieldType>;
};

export enum ArchivePartitionFieldType {
  Int = 'INT',
  Isodate = 'ISODATE',
  String = 'STRING'
}

export type ArchiveRuleConfig = {
  __typename?: 'ArchiveRuleConfig';
  archiveExpireAfterDays?: Maybe<Scalars['Int']>;
  collectionName?: Maybe<Scalars['String']>;
  dateField?: Maybe<Scalars['String']>;
  dbType?: Maybe<DbInstanceType>;
  expireAfterDays?: Maybe<Scalars['Int']>;
  partitionFields?: Maybe<Array<Maybe<ArchivePartitionField>>>;
  version?: Maybe<Scalars['Int']>;
};

export type AxisInfo = {
  __typename?: 'AxisInfo';
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  series: Array<Maybe<Scalars['String']>>;
  /**  Title  */
  title: Scalars['String'];
  /**  Data Type  */
  type: DataType;
};

export type BasicIntersectionDevice = {
  __typename?: 'BasicIntersectionDevice';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Camera = {
  __typename?: 'Camera';
  autoFocusEnabled?: Maybe<Scalars['Boolean']>;
  autoIrisEnabled?: Maybe<Scalars['Boolean']>;
  autoPanEnabled?: Maybe<Scalars['Boolean']>;
  capabilities?: Maybe<Array<CameraCapabilityDto>>;
  capabilitiesState: Scalars['Object'];
  communicationParameters?: Maybe<Scalars['Object']>;
  communicationStatus?: Maybe<CommunicationState>;
  dataReceived?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastPollAttempt?: Maybe<Scalars['DateTime']>;
  lastSuccessfulPoll?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['JSON']>;
  locationDescription?: Maybe<Scalars['String']>;
  locationFixed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  poweredOn?: Maybe<Scalars['Boolean']>;
  presets?: Maybe<Array<Maybe<Preset>>>;
  privateStreamRecordingInProgress?: Maybe<Scalars['Boolean']>;
  ptzLock: PtzLockInfo;
  publicFeedEnabled?: Maybe<Scalars['Boolean']>;
  publicStreamRecordingInProgress?: Maybe<Scalars['Boolean']>;
  referencePoint?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  streams?: Maybe<Array<Maybe<VideoStream>>>;
  timeDrift?: Maybe<Scalars['Int']>;
  tours?: Maybe<Array<Maybe<Tour>>>;
  trustedStreamRecordingInProgress?: Maybe<Scalars['Boolean']>;
  userNotes?: Maybe<Scalars['String']>;
};

export type CameraAutoFocusInput = {
  cameraId: Scalars['String'];
  enable: Scalars['Boolean'];
};

export type CameraAutoIrisInput = {
  cameraId: Scalars['String'];
  enable: Scalars['Boolean'];
};

export enum CameraCapability {
  Antifog = 'ANTIFOG',
  Autotracker = 'AUTOTRACKER',
  IntelligentTracker = 'INTELLIGENT_TRACKER',
  IntermediateWiper = 'INTERMEDIATE_WIPER',
  Ircorrection = 'IRCORRECTION',
  NightModeSettings = 'NIGHT_MODE_SETTINGS',
  OneshotWipes = 'ONESHOT_WIPES',
  Scan360 = 'SCAN360',
  StartTourA = 'START_TOUR_A',
  StartTourB = 'START_TOUR_B',
  Washer = 'WASHER',
  WashingProcedure = 'WASHING_PROCEDURE',
  WhiteBalance = 'WHITE_BALANCE',
  Wiper = 'WIPER'
}

export type CameraCapabilityDto = {
  __typename?: 'CameraCapabilityDto';
  capability: CameraCapability;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CameraConnection = {
  __typename?: 'CameraConnection';
  edges: Array<CameraConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CameraConnectionEdge = {
  __typename?: 'CameraConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Camera;
};

export type CameraFocusInput = {
  cameraId: Scalars['String'];
  focusSpeed: Scalars['Int'];
};

export type CameraGotoPresetInput = {
  cameraId: Scalars['String'];
  presetId: Scalars['String'];
};

export type CameraGroup = {
  __typename?: 'CameraGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<GroupItem>;
  name: Scalars['String'];
  subGroups: Array<SubGroup>;
};

export type CameraGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<GroupItemInput>>;
  name: Scalars['String'];
  subGroups: Array<InputMaybe<SubGroupInput>>;
};

export type CameraGroupListFormat = {
  searchFields?: InputMaybe<Array<CameraGroupSearchField>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<CameraGroupSortField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum CameraGroupSearchField {
  Description = 'DESCRIPTION',
  Name = 'NAME'
}

export enum CameraGroupSortField {
  Id = 'ID',
  Name = 'NAME'
}

export type CameraInputQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CameraIrisInput = {
  cameraId: Scalars['String'];
  irisSpeed: Scalars['Int'];
};

export type CameraPanTiltInput = {
  cameraId: Scalars['String'];
  panSpeed: Scalars['Int'];
  tiltSpeed: Scalars['Int'];
};

export type CameraPublicFeedInput = {
  cameraId: Scalars['String'];
  connect: Scalars['Boolean'];
  streamManagerId: Scalars['String'];
};

export type CameraPublicStreamTargetInput = {
  cameraId: Scalars['String'];
  enable: Scalars['Boolean'];
  streamManagerId: Scalars['String'];
};

export type CameraStreamRecordingInput = {
  cameraId: Scalars['String'];
  recordingInitiatedBy?: InputMaybe<RecordingInitiatedBy>;
  recordingInitiatorId?: InputMaybe<Scalars['String']>;
  recordingType?: InputMaybe<RecordingType>;
  start: Scalars['Boolean'];
  streamAccessType: VideoStreamAccessType;
  streamRecorderId: Scalars['String'];
};

export type CameraZoomInput = {
  cameraId: Scalars['String'];
  zoomSpeed: Scalars['Int'];
};

export type CenterInfo = {
  __typename?: 'CenterInfo';
  centerId: Scalars['String'];
  id: Scalars['String'];
};

export type CentralAuthPinSettings = {
  __typename?: 'CentralAuthPinSettings';
  id: Scalars['String'];
  pin: Scalars['String'];
};

export type ChartData = {
  __typename?: 'ChartData';
  /**  Preferred Color */
  color?: Maybe<Scalars['String']>;
  /**  Data points  */
  dataPoints?: Maybe<Array<Maybe<DataPoint>>>;
  /**  Chart Legend  */
  legend?: Maybe<Scalars['String']>;
  /**  Chart data name  */
  name: Scalars['String'];
  /**  Preferred Shape  */
  shape?: Maybe<Scalars['String']>;
  /**  Chart Type  */
  type: Scalars['String'];
};

export type CheckPoint = {
  __typename?: 'CheckPoint';
  /**  Background fill color  */
  color?: Maybe<Scalars['String']>;
  /**  Data Points  */
  dataPoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  Legend  */
  legend?: Maybe<Scalars['String']>;
  /**  Data Type  */
  type: DataType;
};

export enum ChronoUnit {
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Seconds = 'SECONDS'
}

export type ColumnProperty = {
  __typename?: 'ColumnProperty';
  name: Scalars['String'];
  type: DataType;
};

export type CommandIntersection = {
  __typename?: 'CommandIntersection';
  devices: Array<BasicIntersectionDevice>;
  group?: Maybe<BasicIntersectionDevice>;
  id: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export enum CommandType {
  ActionPlan = 'ACTION_PLAN',
  Pattern = 'PATTERN',
  PhaseHold = 'PHASE_HOLD',
  Preempt = 'PREEMPT',
  RingMax2 = 'RING_MAX2',
  SetTime = 'SET_TIME',
  SpecialFunction = 'SPECIAL_FUNCTION',
  StopTime = 'STOP_TIME',
  SystemPatternOverride = 'SYSTEM_PATTERN_OVERRIDE',
  VehicleRecall = 'VEHICLE_RECALL'
}

export type CommandValue = {
  __typename?: 'CommandValue';
  name: Scalars['String'];
  value?: Maybe<Scalars['Object']>;
};

export enum CommunicationDetailsType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
  Radio = 'RADIO',
  Verbal = 'VERBAL'
}

export type CommunicationLog = {
  __typename?: 'CommunicationLog';
  attributes: Scalars['Object'];
  contact?: Maybe<CommunicationLogContact>;
  detailsType?: Maybe<Scalars['String']>;
  discussion?: Maybe<Scalars['String']>;
  eventIds: Array<Scalars['ID']>;
  events?: Maybe<Array<Maybe<Event>>>;
  id: Scalars['ID'];
  impactedGroup?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reported?: Maybe<Scalars['DateTime']>;
};

export type CommunicationLogConnection = {
  __typename?: 'CommunicationLogConnection';
  edges: Array<CommunicationLogEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommunicationLogContact = {
  __typename?: 'CommunicationLogContact';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CommunicationLogContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CommunicationLogEdge = {
  __typename?: 'CommunicationLogEdge';
  cursor: Scalars['String'];
  node: CommunicationLog;
};

export type CommunicationLogInput = {
  attributes: Scalars['Object'];
  contact?: InputMaybe<CommunicationLogContactInput>;
  detailsType?: InputMaybe<CommunicationDetailsType>;
  discussion?: InputMaybe<Scalars['String']>;
  eventIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  id?: InputMaybe<Scalars['ID']>;
  impactedGroup?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  reported?: InputMaybe<Scalars['DateTime']>;
};

export type CommunicationLogInputQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CommunicationLogStreamObject = {
  __typename?: 'CommunicationLogStreamObject';
  dto?: Maybe<CommunicationLog>;
  streamObjectOperation?: Maybe<StreamObjectOperation>;
};

export type CommunicationReportRow = {
  __typename?: 'CommunicationReportRow';
  communicationParameters?: Maybe<Scalars['String']>;
  communicationState: CommunicationState;
  deviceId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  downTime: Scalars['Int'];
  failureCount: Scalars['Int'];
  group?: Maybe<Scalars['String']>;
  integrationType: IntegrationType;
  intersectionId: Scalars['String'];
  intersectionName: Scalars['String'];
  latency: Scalars['Int'];
  requestCount: Scalars['Int'];
};

export type CommunicationReportRowEdge = {
  __typename?: 'CommunicationReportRowEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CommunicationReportRow;
};

export enum CommunicationState {
  Disabled = 'DISABLED',
  Failed = 'FAILED',
  NoData = 'NO_DATA',
  Operational = 'OPERATIONAL',
  OperationalWithError = 'OPERATIONAL_WITH_ERROR',
  OperationalWithWarning = 'OPERATIONAL_WITH_WARNING',
  Suspect = 'SUSPECT'
}

export type Contact = {
  __typename?: 'Contact';
  cellNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  faxNumber?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  groups?: Maybe<Array<Maybe<ContactGroupDto>>>;
  homeNumber?: Maybe<Scalars['String']>;
  hoursOfOperation?: Maybe<Array<Maybe<HoursOfOperation>>>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  mailingAddress?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<Maybe<OrganizationDto>>>;
  otherNumber?: Maybe<Scalars['String']>;
  pagerNumber?: Maybe<Scalars['String']>;
  publicMode?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  workNumber: Scalars['String'];
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ContactConnectionEdge = {
  __typename?: 'ContactConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Contact;
};

export type ContactGroup = {
  __typename?: 'ContactGroup';
  id?: Maybe<Scalars['ID']>;
};

export type ContactGroupConnection = {
  __typename?: 'ContactGroupConnection';
  edges: Array<ContactGroupConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ContactGroupConnectionEdge = {
  __typename?: 'ContactGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ContactGroupDto;
};

export type ContactGroupDto = {
  __typename?: 'ContactGroupDto';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  items: Array<Maybe<Contact>>;
  name: Scalars['String'];
  parentGroup?: Maybe<Scalars['String']>;
  subGroups: Array<Maybe<ContactGroupDto>>;
};

export type ContactGroupFilterInput = {
  field: Scalars['String'];
  operator: FilterOperator;
  value?: InputMaybe<Scalars['Object']>;
};

export type ContactGroupInput = {
  contacts: Array<InputMaybe<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  parentGroup?: InputMaybe<Scalars['String']>;
  subGroups: Array<InputMaybe<ContactGroupInput>>;
};

export type ContactGroupQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<ContactGroupFilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ContactGroupStreamObject = {
  __typename?: 'ContactGroupStreamObject';
  dto?: Maybe<ContactGroupDto>;
  streamObjectOperation?: Maybe<StreamObjectOperation>;
};

export type ContactInput = {
  cellNumber?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  faxNumber?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeNumber?: InputMaybe<Scalars['String']>;
  hoursOfOperation?: InputMaybe<Array<InputMaybe<HoursOfOperationInput>>>;
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mailingAddress?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  otherNumber?: InputMaybe<Scalars['String']>;
  pagerNumber?: InputMaybe<Scalars['String']>;
  publicMode?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
  workNumber: Scalars['String'];
};

export type ContactInputQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ContactOrganizationQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ContactStreamObject = {
  __typename?: 'ContactStreamObject';
  dto?: Maybe<Contact>;
  streamObjectOperation?: Maybe<StreamObjectOperation>;
};

export enum ConversionUnitType {
  Imperial = 'Imperial',
  Metric = 'Metric'
}

export enum CoordStatus {
  Badcycle = 'BADCYCLE',
  Badplan = 'BADPLAN',
  Commandfree = 'COMMANDFREE',
  Coord = 'COORD',
  Coordfree = 'COORDFREE',
  Failed = 'FAILED',
  Inputfree = 'INPUTFREE',
  Invalidoffset = 'INVALIDOFFSET',
  Minsunder = 'MINSUNDER',
  Other = 'OTHER',
  Splitover = 'SPLITOVER',
  Transition = 'TRANSITION'
}

export type Corridor = {
  __typename?: 'Corridor';
  /**  activation type  */
  activationType: CorridorActivationType;
  /**  response confirmed  */
  confirmed: Scalars['Boolean'];
  /**  description  */
  description?: Maybe<Scalars['String']>;
  /**  detections */
  detections: Array<Detection>;
  /**  events  */
  events: Array<Scalars['ID']>;
  /**  bounds  */
  geometry: Scalars['Object'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  integrations  */
  integrations: Array<Scalars['String']>;
  /**  lanes  */
  lanes: Array<CorridorLane>;
  /**  name  */
  name: Scalars['String'];
  /**  response period (secs)  */
  responsePeriod: Scalars['Int'];
  /**  settings  */
  settings: Scalars['Object'];
  /**  type  */
  type: CorridorType;
};

export enum CorridorActivationType {
  /**  Corridor will be automatically activated  */
  Automatic = 'AUTOMATIC',
  /**  Corridor will require confirmation before activating  */
  ConfirmationRequired = 'CONFIRMATION_REQUIRED',
  /**  Corridor will be manually activated  */
  Manual = 'MANUAL'
}

export type CorridorConnection = {
  __typename?: 'CorridorConnection';
  /**  Connection Edges  */
  edges: Array<CorridorConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type CorridorConnectionEdge = {
  __typename?: 'CorridorConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The Corridor object  */
  node: Corridor;
};

export type CorridorInput = {
  /**  activation type  */
  activationType: CorridorActivationType;
  /**  response confirmed  */
  confirmed: Scalars['Boolean'];
  /**  description  */
  description?: InputMaybe<Scalars['String']>;
  /**  detections */
  detections: Array<Scalars['String']>;
  /**  events  */
  events: Array<Scalars['ID']>;
  /**  bounds  */
  geometry: Scalars['Object'];
  /**  identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  integrations  */
  integrations: Array<Scalars['String']>;
  /**  lanes  */
  lanes: Array<CorridorLaneInput>;
  /**  name  */
  name: Scalars['String'];
  /**  response period (secs)  */
  responsePeriod?: InputMaybe<Scalars['Int']>;
  /**  settings  */
  settings: Scalars['Object'];
  /**  type  */
  type: CorridorType;
};

export type CorridorInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type CorridorLane = {
  __typename?: 'CorridorLane';
  /**  the direction  */
  direction: Direction;
  /**  end reference  */
  endReference: Scalars['Float'];
  /**  identifier of lane  */
  id: Scalars['ID'];
  /**  line type  */
  lineType: CorridorLineType;
  /**  lane position  */
  position: Scalars['Int'];
  /**  The road of the lane  */
  road: Scalars['String'];
  /**  start reference  */
  startReference: Scalars['Float'];
  /**  the lane type  */
  type: LaneType;
};

export type CorridorLaneInput = {
  /**  the direction  */
  direction: Direction;
  /**  end reference  */
  endReference: Scalars['Float'];
  /**  identifier of lane  */
  id: Scalars['ID'];
  /**  line type  */
  lineType: CorridorLineType;
  /**  lane position  */
  position: Scalars['Int'];
  /**  The road of the lane  */
  road: Scalars['String'];
  /**  start reference  */
  startReference: Scalars['Float'];
  /**  the lane type  */
  type: LaneType;
};

export enum CorridorLineType {
  Dash = 'DASH',
  DashOffset = 'DASH_OFFSET',
  None = 'NONE',
  Solid = 'SOLID',
  SolidOffset = 'SOLID_OFFSET'
}

export type CorridorRule = {
  __typename?: 'CorridorRule';
  /**  if the rule is to be executed automatically  */
  automatic: Scalars['Boolean'];
  /**  associated corridor  */
  corridorId: Scalars['String'];
  /**  rule description  */
  description?: Maybe<Scalars['String']>;
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  rule identifier  */
  id: Scalars['ID'];
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  the sign message template  */
  template: SignMessage;
  /**  rule tree  */
  tree: Scalars['Object'];
  /**  rule type  */
  type: CorridorRuleType;
};

export type CorridorRuleConnection = {
  __typename?: 'CorridorRuleConnection';
  edges: Array<CorridorRuleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CorridorRuleEdge = {
  __typename?: 'CorridorRuleEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CorridorRule;
};

export type CorridorRuleInput = {
  /**  if the rule is to be executed automatically  */
  automatic: Scalars['Boolean'];
  /**  associated corridor  */
  corridorId: Scalars['String'];
  /**  rule description  */
  description?: InputMaybe<Scalars['String']>;
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  rule identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  the sign message template  */
  template: SignMessageInput;
  /**  rule tree  */
  tree: Scalars['Object'];
  /**  rule type  */
  type: CorridorRuleType;
};

export type CorridorRuleInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  corridor identifier for rules  */
  corridorId?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum CorridorRuleType {
  Queue = 'QUEUE',
  Tolling = 'TOLLING',
  Weather = 'WEATHER'
}

export enum CorridorType {
  /**  corridor  */
  Corridor = 'CORRIDOR',
  /**  tunnel  */
  Tunnel = 'TUNNEL'
}

export type CountAggregation = {
  __typename?: 'CountAggregation';
  /**  Previous trending number of results */
  trend: Scalars['Int'];
  /**  Number of results */
  value: Scalars['Int'];
};

export type CrosshairStatus = {
  __typename?: 'CrosshairStatus';
  anchor?: Maybe<IconAnchor>;
  color?: Maybe<SignalOutputColor>;
  phase?: Maybe<Scalars['Int']>;
  type?: Maybe<SignalIndicationType>;
};

export enum DbInstanceType {
  Historical = 'HISTORICAL',
  HistoricalFederated = 'HISTORICAL_FEDERATED',
  Operational = 'OPERATIONAL',
  OperationalFederated = 'OPERATIONAL_FEDERATED'
}

export type DailyTimeInterval = {
  /**  The days of the week upon which to fire. 1-7 as defined by Calendar.SUNDAY - Calendar.SATURDAY. */
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**  The time of day to complete firing at the given interval. */
  endTimeOfDay?: InputMaybe<TimeOfDay>;
  /**  Get the the number of times for interval this job should repeat, after which it will be automatically deleted. leave blank to indefinitely repeat. */
  repeatCount?: InputMaybe<Scalars['Int']>;
  repeatInterval?: InputMaybe<RepeatInterval>;
  /**  The time of day to start firing at the given interval. */
  startTimeOfDay?: InputMaybe<TimeOfDay>;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  x: Scalars['String'];
  y: Scalars['String'];
};

export type DataSource = {
  __typename?: 'DataSource';
  /**  the attributes for the data source  */
  attributes: Scalars['Object'];
  /**  the config for the data source  */
  config: DataSourceConfig;
  /**  if the option is available for selection  */
  enabled?: Maybe<Scalars['Boolean']>;
  /**  the identifier of data source  */
  id: Scalars['ID'];
  /**  identifies if it is a system source  */
  system?: Maybe<Scalars['Boolean']>;
  /**  the value of the data source  */
  value: Scalars['String'];
};

export type DataSourceConfig = {
  __typename?: 'DataSourceConfig';
  /**  the fields for the data source object  */
  fields: Array<FieldConfig>;
  /**  the identifier of the form  */
  id: Scalars['ID'];
  /**  the module that the form belongs to  */
  module: KineticModule;
  /**  the name of the data source object  */
  name: Scalars['String'];
  /**  identifies if it is a system source config  */
  system?: Maybe<Scalars['Boolean']>;
};

export type DataSourceConfigConnection = {
  __typename?: 'DataSourceConfigConnection';
  /**  connection edges  */
  edges: Array<DataSourceConfigConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type DataSourceConfigConnectionEdge = {
  __typename?: 'DataSourceConfigConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: DataSourceConfig;
};

export type DataSourceConfigInput = {
  /**  the field configs for the data source object  */
  fields: Array<FieldConfigInput>;
  /**  the identifier of the form  */
  id?: InputMaybe<Scalars['ID']>;
  /**  the module that the form belongs to  */
  module: KineticModule;
  /**  the name of the data source object  */
  name: Scalars['String'];
};

export type DataSourceConfigInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  Kinetic Module to filter by  */
  module?: InputMaybe<KineticModule>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type DataSourceConnection = {
  __typename?: 'DataSourceConnection';
  /**  connection edges  */
  edges: Array<DataSourceConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type DataSourceConnectionEdge = {
  __typename?: 'DataSourceConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: DataSource;
};

export type DataSourceInput = {
  /**  the attributes for the data source  */
  attributes: Scalars['Object'];
  /**  the form config for the data source  */
  configId: Scalars['String'];
  /**  if the option is available for selection  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /**  the identifier of data source  */
  id?: InputMaybe<Scalars['ID']>;
  /**  the value of the data source  */
  value: Scalars['String'];
};

export type DataSourceInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  return only enabled data sources if true, otherwise return all  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  data source collection id  */
  formId?: InputMaybe<Scalars['ID']>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum DataType {
  Date = 'DATE',
  Datetime = 'DATETIME',
  Numeric = 'NUMERIC',
  String = 'STRING'
}

export enum DataTypes {
  Control = 'CONTROL',
  ParameterTransactionNotRequired = 'PARAMETER_TRANSACTION_NOT_REQUIRED',
  ParameterTransactionRequired = 'PARAMETER_TRANSACTION_REQUIRED',
  Status = 'STATUS',
  Unknown = 'UNKNOWN'
}

export type DatabaseComparisonMibValue = {
  __typename?: 'DatabaseComparisonMibValue';
  bitIndex?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  indexes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  tableName: Scalars['String'];
  value?: Maybe<Scalars['Object']>;
};

export type DatabaseExportTemplate = {
  __typename?: 'DatabaseExportTemplate';
  /**  Device protocol / API the template can be used with  */
  deviceApi: DeviceViewsProtocolEntityEnum;
  id: Scalars['ID'];
  /**  Unique name of the template  */
  name: Scalars['String'];
  /**  Tables to be exported. This must be internal names, e.g. MaxViewPhaseTimingPlansGrid  */
  tableNames: Array<Scalars['String']>;
};

export type DatabaseExportTemplateInput = {
  deviceApi: DeviceViewsProtocolEntityEnum;
  /**  Existing template ID to update, or null to create a new template  */
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tableNames: Array<Scalars['String']>;
};

export enum DatabaseLockAction {
  Lock = 'LOCK',
  Unlock = 'UNLOCK'
}

export enum DatabaseLockUpdateAction {
  Admin = 'ADMIN',
  TimedUnlock = 'TIMED_UNLOCK',
  User = 'USER'
}

export type DatabaseMibValue = {
  bitIndex?: InputMaybe<Scalars['Int']>;
  indexes?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  tableName: Scalars['String'];
  value?: InputMaybe<Scalars['Object']>;
};

export type DateRange = {
  /** Range end timestamp */
  endTimestamp: Scalars['DateTime'];
  /** Range start timestamp */
  startTimestamp: Scalars['DateTime'];
};

export type DateTasks = {
  __typename?: 'DateTasks';
  /**  the date for the tasks  */
  date: Scalars['DateTime'];
  /**  the list of task occurrences for the range  */
  tasks?: Maybe<Array<Maybe<TaskOccurrence>>>;
};

export enum DateViewType {
  Daily = 'DAILY',
  DailyRealtime = 'DAILY_REALTIME',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Detection = {
  __typename?: 'Detection';
  alertStates: Array<Maybe<AlertState>>;
  alerts: Array<Maybe<Alert>>;
  associatedEventIds?: Maybe<Array<Scalars['String']>>;
  confidence?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  location?: Maybe<Scalars['Object']>;
  severity?: Maybe<Severity>;
  sources: Array<Maybe<AlertSource>>;
  status: Status;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['String']>;
};

export type DetectionConnection = {
  __typename?: 'DetectionConnection';
  edges: Array<DetectionConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectionConnectionEdge = {
  __typename?: 'DetectionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Detection;
};

export type Detector = {
  __typename?: 'Detector';
  /** axle 1 weight lane wise upper and lower threshold */
  axle1WeightThreshold?: Maybe<Array<DetectorWeightThreshold>>;
  classScheme?: Maybe<Scalars['String']>;
  communicationParameters?: Maybe<Scalars['Object']>;
  communicationState?: Maybe<CommunicationState>;
  connectInterval?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  /** the protocol of the device */
  deviceProtocol?: Maybe<Scalars['String']>;
  deviceType?: Maybe<DetectorDeviceType>;
  /**  direction  */
  direction?: Maybe<Scalars['String']>;
  /** direction description configuration */
  directionDescriptions?: Maybe<Array<DirectionDescriptionConfig>>;
  equipmentNumber?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['String']>;
  /** gross weight lane wise upper and lower threshold */
  grossWeightThreshold?: Maybe<Array<DetectorWeightThreshold>>;
  hiTrac?: Maybe<HiTrac>;
  id: Scalars['ID'];
  /** lane description configuration */
  laneDescription?: Maybe<Array<DetectorLaneDescriptionConfig>>;
  /**  lane data  */
  lanes: Array<DetectorLaneData>;
  /**  last connect  */
  lastDataReceived?: Maybe<Scalars['DateTime']>;
  lastVehicle?: Maybe<DetectorVehicle>;
  location?: Maybe<Scalars['Object']>;
  maneuvers?: Maybe<Array<DetectorManeuverSettingConfig>>;
  maxGrossWeight?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  numberOfLanes?: Maybe<Scalars['Int']>;
  /**  reference point  */
  referencePoint?: Maybe<Scalars['Float']>;
  /**  road  */
  road?: Maybe<Scalars['String']>;
  roadType?: Maybe<Scalars['String']>;
  siteImages?: Maybe<Array<File>>;
  siteNumber?: Maybe<Scalars['Float']>;
  /** speed bins configuration */
  speedBins?: Maybe<Array<DetectorSpeedBinConfig>>;
  speedLimit?: Maybe<Scalars['Float']>;
  speedUnit?: Maybe<Scalars['String']>;
  /** system enabled flag */
  systemEnabled?: Maybe<Scalars['Boolean']>;
  timeZone?: Maybe<Scalars['String']>;
  timezoneId?: Maybe<Scalars['String']>;
  type?: Maybe<DetectorType>;
};

export type DetectorActivity = {
  __typename?: 'DetectorActivity';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  level?: Maybe<FieldConfigOption>;
  message?: Maybe<FieldConfigOption>;
  parameter?: Maybe<Scalars['String']>;
  subtype?: Maybe<FieldConfigOption>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type DetectorActivityConnection = {
  __typename?: 'DetectorActivityConnection';
  edges: Array<DetectorActivityConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorActivityConnectionEdge = {
  __typename?: 'DetectorActivityConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorActivity;
};

export type DetectorActivityQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  The detector id of detector */
  detectorId: Scalars['ID'];
  /**  End time filter */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter routes */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Log level filter */
  level?: InputMaybe<DetectorLogLevel>;
  /**  Limit the number of results. We do not allow returning all docs */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. If not sent, all fields will be searched. */
  searchFields?: InputMaybe<Array<DetectorActivitySearchField>>;
  /**  Text to search for */
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<DetectorActivitySortField>;
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export enum DetectorActivitySearchField {
  Level = 'LEVEL',
  Message = 'MESSAGE'
}

export enum DetectorActivitySortField {
  Level = 'LEVEL',
  Message = 'MESSAGE',
  Timestamp = 'TIMESTAMP'
}

export type DetectorAiClass = {
  __typename?: 'DetectorAiClass';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DetectorAlarm = {
  __typename?: 'DetectorAlarm';
  acknowledged?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  cleared?: Maybe<Scalars['Boolean']>;
  clearedAt?: Maybe<Scalars['DateTime']>;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  detector?: Maybe<Detector>;
  firstReceivedAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<DetectorAlarmGroup>;
  id: Scalars['ID'];
  receivedAt?: Maybe<Scalars['DateTime']>;
  subtypes?: Maybe<Array<DetectorAlarmSubtype>>;
  type?: Maybe<DetectorAlarmType>;
};

export type DetectorAlarmConnection = {
  __typename?: 'DetectorAlarmConnection';
  edges: Array<DetectorAlarmConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorAlarmConnectionEdge = {
  __typename?: 'DetectorAlarmConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorAlarm;
};

export type DetectorAlarmGroup = {
  __typename?: 'DetectorAlarmGroup';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DetectorAlarmQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  End time filter */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter routes */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. If not sent, all fields will be searched. */
  searchFields?: InputMaybe<Array<DetectorAlarmsSearchField>>;
  /**  Text to search for */
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<DetectorAlarmsSortField>;
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorAlarmSubtype = {
  __typename?: 'DetectorAlarmSubtype';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DetectorAlarmType = {
  __typename?: 'DetectorAlarmType';
  code?: Maybe<DetectorAlarmTypeCode>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notificationEnabled?: Maybe<Scalars['String']>;
  severity?: Maybe<NotificationSeverity>;
};

export enum DetectorAlarmTypeCode {
  BackDoorOpen = 'BACK_DOOR_OPEN',
  BrownoutReset = 'BROWNOUT_RESET',
  CommunicationsFailure = 'COMMUNICATIONS_FAILURE',
  DataError = 'DATA_ERROR',
  FlashChipAccessFailure = 'FLASH_CHIP_ACCESS_FAILURE',
  FlashChipFault = 'FLASH_CHIP_FAULT',
  FrontDoorOpen = 'FRONT_DOOR_OPEN',
  LastConnectError = 'LAST_CONNECT_ERROR',
  LastConnectWarning = 'LAST_CONNECT_WARNING',
  LineCpuOrRtcFailure = 'LINE_CPU_OR_RTC_FAILURE',
  LoopFailure = 'LOOP_FAILURE',
  LowBattery = 'LOW_BATTERY',
  LowVoltage = 'LOW_VOLTAGE',
  MainsFail = 'MAINS_FAIL',
  MemoryCorruption = 'MEMORY_CORRUPTION',
  PrimarySelfTestFailed = 'PRIMARY_SELF_TEST_FAILED',
  ProductionDataIncomplete = 'PRODUCTION_DATA_INCOMPLETE',
  Profiler_1SelfTestFailed = 'PROFILER_1_SELF_TEST_FAILED',
  Profiler_2SelfTestFailed = 'PROFILER_2_SELF_TEST_FAILED',
  SdCardAccessFailure = 'SD_CARD_ACCESS_FAILURE',
  SdCardFault = 'SD_CARD_FAULT',
  SensorFailure = 'SENSOR_FAILURE',
  TimeDriftError = 'TIME_DRIFT_ERROR',
  Unknown = 'UNKNOWN'
}

export enum DetectorAlarmsSearchField {
  Description = 'DESCRIPTION',
  Type = 'TYPE'
}

export enum DetectorAlarmsSortField {
  Id = 'ID',
  ReceivedAt = 'RECEIVED_AT'
}

export type DetectorAlertFrequencyOption = {
  __typename?: 'DetectorAlertFrequencyOption';
  code?: Maybe<DetectorEmailAlertFrequency>;
  label: Scalars['String'];
  types?: Maybe<Array<DetectorAlertType>>;
};

export enum DetectorAlertSubtype {
  BackDoorOpen = 'BACK_DOOR_OPEN',
  BrownoutReset = 'BROWNOUT_RESET',
  CommunicationsFailure = 'COMMUNICATIONS_FAILURE',
  DataError = 'DATA_ERROR',
  FlashChipAccessFailure = 'FLASH_CHIP_ACCESS_FAILURE',
  FlashChipFault = 'FLASH_CHIP_FAULT',
  FrontDoorOpen = 'FRONT_DOOR_OPEN',
  Htv = 'HTV',
  LastConnectError = 'LAST_CONNECT_ERROR',
  LastConnectWarning = 'LAST_CONNECT_WARNING',
  LineCpuOrRtcFailure = 'LINE_CPU_OR_RTC_FAILURE',
  LoopFailure = 'LOOP_FAILURE',
  LowBattery = 'LOW_BATTERY',
  LowVoltage = 'LOW_VOLTAGE',
  MainsFail = 'MAINS_FAIL',
  MemoryCorruption = 'MEMORY_CORRUPTION',
  PrimarySelfTestFailed = 'PRIMARY_SELF_TEST_FAILED',
  ProductionDataIncomplete = 'PRODUCTION_DATA_INCOMPLETE',
  Profiler_1SelfTestFailed = 'PROFILER_1_SELF_TEST_FAILED',
  Profiler_2SelfTestFailed = 'PROFILER_2_SELF_TEST_FAILED',
  SdCardAccessFailure = 'SD_CARD_ACCESS_FAILURE',
  SdCardFault = 'SD_CARD_FAULT',
  SensorFailure = 'SENSOR_FAILURE',
  TimeDriftError = 'TIME_DRIFT_ERROR',
  Vcr = 'VCR',
  VehiclePacketMissingForDay = 'VEHICLE_PACKET_MISSING_FOR_DAY'
}

export type DetectorAlertSubtypeOption = {
  __typename?: 'DetectorAlertSubtypeOption';
  code?: Maybe<DetectorAlertSubtype>;
  label: Scalars['String'];
  type?: Maybe<DetectorAlertType>;
};

export enum DetectorAlertType {
  CaltransTns = 'CALTRANS_TNS',
  ConfigurationChange = 'CONFIGURATION_CHANGE',
  DiagnosticErrors = 'DIAGNOSTIC_ERRORS',
  Euro_6ClassSpeed = 'EURO_6_CLASS_SPEED'
}

export type DetectorAlertTypeOption = {
  __typename?: 'DetectorAlertTypeOption';
  code?: Maybe<DetectorAlertType>;
  label: Scalars['String'];
};

export type DetectorBattery = {
  __typename?: 'DetectorBattery';
  id: Scalars['ID'];
  timestamp?: Maybe<Scalars['DateTime']>;
  volts?: Maybe<Scalars['Float']>;
};

export enum DetectorBatteryGranularity {
  All = 'ALL',
  Hourly = 'HOURLY'
}

export type DetectorBatteryInputQuery = {
  /**  The detector id of detector */
  detectorId: Scalars['ID'];
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  granularity?: InputMaybe<DetectorBatteryGranularity>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorClass = {
  __typename?: 'DetectorClass';
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DetectorClassIndex = {
  __typename?: 'DetectorClassIndex';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DetectorClassIntervalCount = {
  __typename?: 'DetectorClassIntervalCount';
  classCounts: Scalars['Object'];
  date: Scalars['String'];
  hour: Scalars['Int'];
  isDailyTotal: Scalars['Boolean'];
  isHourlyTotal: Scalars['Boolean'];
  minute: Scalars['Int'];
  total: Scalars['Int'];
};

export type DetectorConfig = {
  __typename?: 'DetectorConfig';
  classNumberOfAxles?: Maybe<Array<Scalars['Int']>>;
  classString?: Maybe<Array<Scalars['String']>>;
  detectorId?: Maybe<Scalars['String']>;
  endTimestamp?: Maybe<Scalars['DateTime']>;
  extraConfig?: Maybe<DetectorExtraConfig>;
  id?: Maybe<Scalars['String']>;
  laneDirection?: Maybe<Array<Scalars['Int']>>;
  loopCorrectionFactor?: Maybe<Array<Scalars['Int']>>;
  loopLength?: Maybe<Array<Scalars['Int']>>;
  loopSensitivity?: Maybe<Array<Scalars['Int']>>;
  presenceTime?: Maybe<Array<Scalars['Int']>>;
  reverseDirection?: Maybe<Array<Scalars['Int']>>;
  roadConfiguration?: Maybe<Array<Scalars['Int']>>;
  sensorSpacing?: Maybe<Array<Scalars['Int']>>;
  storeCodes?: Maybe<Array<Scalars['Int']>>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type DetectorConfigInput = {
  classNumberOfAxles?: InputMaybe<Array<Scalars['Int']>>;
  classString?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  laneDirection?: InputMaybe<Array<Scalars['Int']>>;
  loopCorrectionFactor?: InputMaybe<Array<Scalars['Int']>>;
  loopLength?: InputMaybe<Array<Scalars['Int']>>;
  loopSensitivity?: InputMaybe<Array<Scalars['Int']>>;
  presenceTime?: InputMaybe<Array<Scalars['Int']>>;
  reverseDirection?: InputMaybe<Array<Scalars['Int']>>;
  roadConfiguration?: InputMaybe<Array<Scalars['Int']>>;
  sensorSpacing?: InputMaybe<Array<Scalars['Int']>>;
  storeCodes?: InputMaybe<Array<Scalars['Int']>>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorConnection = {
  __typename?: 'DetectorConnection';
  edges: Array<DetectorConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorConnectionEdge = {
  __typename?: 'DetectorConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Detector;
};

export type DetectorConnectionGroup = {
  __typename?: 'DetectorConnectionGroup';
  bytesIncoming?: Maybe<Scalars['Int']>;
  bytesOutgoing?: Maybe<Scalars['Int']>;
  connectionPushType?: Maybe<Scalars['String']>;
  elapsedSeconds?: Maybe<Scalars['Int']>;
  groupID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logs?: Maybe<Array<DetectorConnectionLog>>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type DetectorConnectionLog = {
  __typename?: 'DetectorConnectionLog';
  groupID?: Maybe<Scalars['String']>;
  hexDump?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: Maybe<FieldConfigOption>;
  message?: Maybe<Scalars['String']>;
  messageParams?: Maybe<Scalars['Object']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type DetectorConnectionLogConnection = {
  __typename?: 'DetectorConnectionLogConnection';
  edges: Array<DetectorConnectionLogConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorConnectionLogConnectionEdge = {
  __typename?: 'DetectorConnectionLogConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorConnectionGroup;
};

export type DetectorConnectionLogDetailsConnection = {
  __typename?: 'DetectorConnectionLogDetailsConnection';
  edges: Array<DetectorConnectionLogDetailsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorConnectionLogDetailsConnectionEdge = {
  __typename?: 'DetectorConnectionLogDetailsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorConnectionLog;
};

export type DetectorConnectionLogDetailsQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  The connection id of detector */
  connectionId: Scalars['ID'];
  /**  End time filter */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter routes */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs */
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<DetectorConnectionLogDetailsSortField>;
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export enum DetectorConnectionLogDetailsSortField {
  Timestamp = 'TIMESTAMP'
}

export type DetectorConnectionLogInput = {
  hexDump?: InputMaybe<Scalars['String']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  messageParams?: InputMaybe<Array<Scalars['Object']>>;
  numberOfBytes?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorConnectionLogQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  The detector id of detector */
  detectorId: Scalars['ID'];
  /**  End time filter */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter routes */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Log level filter */
  level?: InputMaybe<DetectorLogLevel>;
  /**  Limit the number of results. We do not allow returning all docs */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. If not sent, all fields will be searched. */
  searchFields?: InputMaybe<Array<DetectorConnectionLogSearchField>>;
  /**  Text to search for */
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<DetectorConnectionLogSortField>;
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export enum DetectorConnectionLogSearchField {
  Message = 'MESSAGE'
}

export enum DetectorConnectionLogSortField {
  Timestamp = 'TIMESTAMP'
}

/**  Device type from integrations form */
export type DetectorDeviceType = {
  __typename?: 'DetectorDeviceType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum DetectorDirection {
  /**  East  */
  East = 'EAST',
  /**  North  */
  North = 'NORTH',
  /**  Northeast  */
  NorthEast = 'NORTH_EAST',
  /**  Northwest  */
  NorthWest = 'NORTH_WEST',
  /**  South  */
  South = 'SOUTH',
  /**  Southeast  */
  SouthEast = 'SOUTH_EAST',
  /**  Southwest  */
  SouthWest = 'SOUTH_WEST',
  /**  West  */
  West = 'WEST'
}

export enum DetectorEmailAlertFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Realtime = 'REALTIME',
  Weekly = 'WEEKLY'
}

export type DetectorEmailAlertSetting = {
  __typename?: 'DetectorEmailAlertSetting';
  allSites: Scalars['Boolean'];
  detectorGroups?: Maybe<Array<DetectorGroup>>;
  detectors?: Maybe<Array<Detector>>;
  emailIds: Array<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<DetectorAlertFrequencyOption>;
  frequencyDay?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  subtypes?: Maybe<Array<DetectorAlertSubtypeOption>>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DetectorAlertTypeOption>;
  userIds: Array<Scalars['String']>;
};

export type DetectorEmailAlertSettingInput = {
  allSites: Scalars['Boolean'];
  detectorGroups?: InputMaybe<Array<Scalars['String']>>;
  detectorIds?: InputMaybe<Array<Scalars['String']>>;
  enabled: Scalars['Boolean'];
  frequency: DetectorEmailAlertFrequency;
  frequencyDay?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  subtypes?: InputMaybe<Array<DetectorAlertSubtype>>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  type: DetectorAlertType;
  userIds: Array<Scalars['String']>;
};

export type DetectorExtraConfig = {
  __typename?: 'DetectorExtraConfig';
  classConfigDateTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  laneDirection?: Maybe<Array<Scalars['Int']>>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  loopCorrectionFactor?: Maybe<Array<Scalars['Int']>>;
  loopLength?: Maybe<Array<Scalars['Int']>>;
  loopSensitivity?: Maybe<Array<Scalars['Int']>>;
  lowBatteryLevel?: Maybe<Scalars['Int']>;
  port?: Maybe<Scalars['Int']>;
  reverseDirection?: Maybe<Array<Scalars['Int']>>;
  roadConfiguration?: Maybe<Array<Scalars['Int']>>;
  sensorSpacing?: Maybe<Array<Scalars['Int']>>;
  siteIdentifier?: Maybe<Scalars['String']>;
  storeCodes?: Maybe<Array<Scalars['Int']>>;
  storeConfigDateTime?: Maybe<Scalars['DateTime']>;
};

export type DetectorExtraConfigInput = {
  classConfigDateTime?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  laneDirection?: InputMaybe<Array<Scalars['Int']>>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  loopCorrectionFactor?: InputMaybe<Array<Scalars['Int']>>;
  loopLength?: InputMaybe<Array<Scalars['Int']>>;
  loopSensitivity?: InputMaybe<Array<Scalars['Int']>>;
  lowBatteryLevel?: InputMaybe<Scalars['Int']>;
  port?: InputMaybe<Scalars['Int']>;
  reverseDirection?: InputMaybe<Array<Scalars['Int']>>;
  roadConfiguration?: InputMaybe<Array<Scalars['Int']>>;
  sensorSpacing?: InputMaybe<Array<Scalars['Int']>>;
  siteIdentifier?: InputMaybe<Scalars['String']>;
  storeCodes?: InputMaybe<Array<Scalars['Int']>>;
  storeConfigDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorGroup = {
  __typename?: 'DetectorGroup';
  /**  the description  */
  description?: Maybe<Scalars['String']>;
  /**  associated sites  */
  detectorIds: Array<Scalars['String']>;
  /**  enabled flag */
  enabled: Scalars['Boolean'];
  /**  the id  */
  id: Scalars['ID'];
  /**  the name  */
  name: Scalars['String'];
};

export type DetectorGroupConnection = {
  __typename?: 'DetectorGroupConnection';
  /**  connection edges  */
  edges: Array<DetectorGroupConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type DetectorGroupConnectionEdge = {
  __typename?: 'DetectorGroupConnectionEdge';
  /**  cursor position  */
  cursor?: Maybe<Scalars['String']>;
  /**  node  */
  node: DetectorGroup;
};

export type DetectorGroupInput = {
  /**  the description  */
  description?: InputMaybe<Scalars['String']>;
  /**  associated Detectors  */
  detectorIds: Array<Scalars['String']>;
  /**  enabled flag  */
  enabled: Scalars['Boolean'];
  /**  the id  */
  id?: InputMaybe<Scalars['ID']>;
  /**  the name  */
  name: Scalars['String'];
};

export type DetectorGroupInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type DetectorInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs. */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for. */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Show disabled detectors */
  showDisabled?: InputMaybe<Scalars['Boolean']>;
  /**  Which field to sort the results on. */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on. */
  sortOrder?: InputMaybe<SortOrder>;
};

export type DetectorLaneData = {
  __typename?: 'DetectorLaneData';
  /**  direction  */
  direction: Scalars['String'];
  /**  engineering lane  */
  engineeringLane: Scalars['Int'];
  /**  lane index  */
  laneIndex: Scalars['Int'];
  /**  current occupancy  */
  occupancy?: Maybe<Scalars['Float']>;
  /**  current speed  */
  speed?: Maybe<Scalars['Float']>;
  /**  status  */
  status?: Maybe<Scalars['String']>;
  /**  lane type  */
  type: LaneType;
  /**  current volume  */
  volume?: Maybe<Scalars['Float']>;
};

export type DetectorLaneDescriptionConfig = {
  __typename?: 'DetectorLaneDescriptionConfig';
  lane?: Maybe<Scalars['Int']>;
  laneDescription?: Maybe<Scalars['String']>;
  laneIndex?: Maybe<DetectorLaneIndex>;
};

export enum DetectorLaneIndex {
  Index_1 = 'INDEX_1',
  Index_2 = 'INDEX_2',
  Index_3 = 'INDEX_3',
  Index_4 = 'INDEX_4',
  Index_5 = 'INDEX_5',
  Index_6 = 'INDEX_6',
  Index_7 = 'INDEX_7',
  Index_8 = 'INDEX_8',
  Index_9 = 'INDEX_9',
  Index_10 = 'INDEX_10',
  Index_11 = 'INDEX_11',
  Index_12 = 'INDEX_12',
  Index_13 = 'INDEX_13',
  Index_14 = 'INDEX_14',
  Index_15 = 'INDEX_15',
  Index_16 = 'INDEX_16'
}

export enum DetectorLogLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Fatal = 'FATAL',
  Info = 'INFO',
  Trace = 'TRACE',
  Warning = 'WARNING'
}

export enum DetectorLogMessage {
  AdcNonZero = 'ADC_NON_ZERO',
  AdcZero = 'ADC_ZERO',
  AntennaBaudTimeout = 'ANTENNA_BAUD_TIMEOUT',
  AntennaReconfigure = 'ANTENNA_RECONFIGURE',
  AntennaResponseId = 'ANTENNA_RESPONSE_ID',
  AntennaSearchState = 'ANTENNA_SEARCH_STATE',
  AntennaStarted = 'ANTENNA_STARTED',
  AntennaStopped = 'ANTENNA_STOPPED',
  AntennaTimedOut = 'ANTENNA_TIMED_OUT',
  AntennaUninit = 'ANTENNA_UNINIT',
  AuxiliaryAntBaudTimeout = 'AUXILIARY_ANT_BAUD_TIMEOUT',
  AuxiliaryAntReconfigure = 'AUXILIARY_ANT_RECONFIGURE',
  AuxiliaryAntResponseId = 'AUXILIARY_ANT_RESPONSE_ID',
  AuxiliaryAntSearchState = 'AUXILIARY_ANT_SEARCH_STATE',
  AuxiliaryAntStarted = 'AUXILIARY_ANT_STARTED',
  AuxiliaryAntStopped = 'AUXILIARY_ANT_STOPPED',
  AuxiliaryAntTimedOut = 'AUXILIARY_ANT_TIMED_OUT',
  AuxiliaryAntUninit = 'AUXILIARY_ANT_UNINIT',
  AuxData = 'AUX_DATA',
  BadOffset = 'BAD_OFFSET',
  BatteryVoltage = 'BATTERY_VOLTAGE',
  BatteryVoltageWarning = 'BATTERY_VOLTAGE_WARNING',
  BluetoothDisabled = 'BLUETOOTH_DISABLED',
  BluetoothReady = 'BLUETOOTH_READY',
  BtConfigFail = 'BT_CONFIG_FAIL',
  BtNoCarrier = 'BT_NO_CARRIER',
  BtReconfigure = 'BT_RECONFIGURE',
  BtResetFail = 'BT_RESET_FAIL',
  BtWaitRing = 'BT_WAIT_RING',
  ClassTableChange = 'CLASS_TABLE_CHANGE',
  ClearanceText = 'CLEARANCE_TEXT',
  ClockSpeed = 'CLOCK_SPEED',
  CodeDebug = 'CODE_DEBUG',
  CommsOverflow = 'COMMS_OVERFLOW',
  ConfigurationChange = 'CONFIGURATION_CHANGE',
  ConfigSaveErr = 'CONFIG_SAVE_ERR',
  Connection = 'CONNECTION',
  CreateDummyVeh = 'CREATE_DUMMY_VEH',
  CreateDummyVeh2 = 'CREATE_DUMMY_VEH2',
  CurrentFirmware = 'CURRENT_FIRMWARE',
  DaylightSavingBias = 'DAYLIGHT_SAVING_BIAS',
  DaylightSavingChange = 'DAYLIGHT_SAVING_CHANGE',
  DaysDiff = 'DAYS_DIFF',
  DebugMac = 'DEBUG_MAC',
  DetectionFirmware = 'DETECTION_FIRMWARE',
  DeviceReady = 'DEVICE_READY',
  DiagnosticCode = 'DIAGNOSTIC_CODE',
  DialinConnected = 'DIALIN_CONNECTED',
  EndOffset = 'END_OFFSET',
  Erase_4K = 'ERASE_4K',
  Ethernet = 'ETHERNET',
  EthernetConfig = 'ETHERNET_CONFIG',
  EthernetConnect = 'ETHERNET_CONNECT',
  EthernetConnectFail = 'ETHERNET_CONNECT_FAIL',
  EthernetHeartbeatMissed = 'ETHERNET_HEARTBEAT_MISSED',
  EthernetPowerdown = 'ETHERNET_POWERDOWN',
  EthernetPowerup = 'ETHERNET_POWERUP',
  EthernetReady = 'ETHERNET_READY',
  EthernetSocketClosed = 'ETHERNET_SOCKET_CLOSED',
  FinishModem = 'FINISH_MODEM',
  FirmwareDebug = 'FIRMWARE_DEBUG',
  FirmwareState = 'FIRMWARE_STATE',
  FirmwareUpdate = 'FIRMWARE_UPDATE',
  FirstSensor = 'FIRST_SENSOR',
  FlashAddress = 'FLASH_ADDRESS',
  FlashEraseFailed = 'FLASH_ERASE_FAILED',
  FlashId = 'FLASH_ID',
  FlashReadFailed = 'FLASH_READ_FAILED',
  FlashWriteFailed = 'FLASH_WRITE_FAILED',
  ForceReboot = 'FORCE_REBOOT',
  FtpBadPassword = 'FTP_BAD_PASSWORD',
  FtpBadUsername = 'FTP_BAD_USERNAME',
  FtpConnect = 'FTP_CONNECT',
  FtpDeleteFile = 'FTP_DELETE_FILE',
  FtpDownloadFile = 'FTP_DOWNLOAD_FILE',
  FtpHangup = 'FTP_HANGUP',
  FtpListen = 'FTP_LISTEN',
  FtpListening = 'FTP_LISTENING',
  FtpListFiles = 'FTP_LIST_FILES',
  FtpLoginSuccess = 'FTP_LOGIN_SUCCESS',
  FtpShutdown = 'FTP_SHUTDOWN',
  GatewayReady = 'GATEWAY_READY',
  GprsConnected = 'GPRS_CONNECTED',
  GprsConnectTimeout = 'GPRS_CONNECT_TIMEOUT',
  GprsDataCheck = 'GPRS_DATA_CHECK',
  GprsDisconnected = 'GPRS_DISCONNECTED',
  GprsRxError = 'GPRS_RX_ERROR',
  GprsState = 'GPRS_STATE',
  GprsTransferBlockEnd = 'GPRS_TRANSFER_BLOCK_END',
  GprsTransferBlockStart = 'GPRS_TRANSFER_BLOCK_START',
  GprsTransferDataEnd = 'GPRS_TRANSFER_DATA_END',
  GprsTransferDataStart = 'GPRS_TRANSFER_DATA_START',
  GprsTransferEnd = 'GPRS_TRANSFER_END',
  GprsTransferSize = 'GPRS_TRANSFER_SIZE',
  GprsTransferStart = 'GPRS_TRANSFER_START',
  GpsError = 'GPS_ERROR',
  GpsReady = 'GPS_READY',
  HaltTimeError = 'HALT_TIME_ERROR',
  HeaderFound = 'HEADER_FOUND',
  HeaderWrap = 'HEADER_WRAP',
  HicommCsFail = 'HICOMM_CS_FAIL',
  HicommError = 'HICOMM_ERROR',
  HicommInstruction = 'HICOMM_INSTRUCTION',
  HourIndex = 'HOUR_INDEX',
  HourInvalid = 'HOUR_INVALID',
  Iccid = 'ICCID',
  InvalidTimeSets = 'INVALID_TIME_SETS',
  IwrapVersion = 'IWRAP_VERSION',
  LegacyConfigUpdate = 'LEGACY_CONFIG_UPDATE',
  Log_3V3A = 'LOG_3V3A',
  LogPointer = 'LOG_POINTER',
  LogVsys = 'LOG_VSYS',
  MacConfigChange = 'MAC_CONFIG_CHANGE',
  MappingUpdate = 'MAPPING_UPDATE',
  MapDayPointer = 'MAP_DAY_POINTER',
  Memory = 'MEMORY',
  MemoryReadAddress = 'MEMORY_READ_ADDRESS',
  MemoryReadFail = 'MEMORY_READ_FAIL',
  MemoryReset = 'MEMORY_RESET',
  MemoryWriteAddress = 'MEMORY_WRITE_ADDRESS',
  MemoryWriteOversize = 'MEMORY_WRITE_OVERSIZE',
  Modem = 'MODEM',
  Modem_2G = 'MODEM_2G',
  Modem_4GSignal = 'MODEM_4G_SIGNAL',
  ModemActivationFail = 'MODEM_ACTIVATION_FAIL',
  ModemArfcn = 'MODEM_ARFCN',
  ModemAtFailed = 'MODEM_AT_FAILED',
  ModemAuthType = 'MODEM_AUTH_TYPE',
  ModemAutoband = 'MODEM_AUTOBAND',
  ModemBands = 'MODEM_BANDS',
  ModemBandsUtms = 'MODEM_BANDS_UTMS',
  ModemBer = 'MODEM_BER',
  ModemBoardType = 'MODEM_BOARD_TYPE',
  ModemChangeBands = 'MODEM_CHANGE_BANDS',
  ModemChangeBandsUtms = 'MODEM_CHANGE_BANDS_UTMS',
  ModemClass = 'MODEM_CLASS',
  ModemCommand = 'MODEM_COMMAND',
  ModemConnectFail = 'MODEM_CONNECT_FAIL',
  ModemContext = 'MODEM_CONTEXT',
  ModemDcdLow = 'MODEM_DCD_LOW',
  ModemDebug = 'MODEM_DEBUG',
  ModemDebug2 = 'MODEM_DEBUG2',
  ModemDebugNetwork = 'MODEM_DEBUG_NETWORK',
  ModemDebugRssi = 'MODEM_DEBUG_RSSI',
  ModemDebugSim = 'MODEM_DEBUG_SIM',
  ModemEarfcn = 'MODEM_EARFCN',
  ModemError = 'MODEM_ERROR',
  ModemFirmware = 'MODEM_FIRMWARE',
  ModemImei = 'MODEM_IMEI',
  ModemMessage = 'MODEM_MESSAGE',
  ModemModel = 'MODEM_MODEL',
  ModemModelName = 'MODEM_MODEL_NAME',
  ModemNetworkName = 'MODEM_NETWORK_NAME',
  ModemNetworkRegistration = 'MODEM_NETWORK_REGISTRATION',
  ModemNoCarrier = 'MODEM_NO_CARRIER',
  ModemNoNetwork = 'MODEM_NO_NETWORK',
  ModemNoSignal = 'MODEM_NO_SIGNAL',
  ModemNoSim = 'MODEM_NO_SIM',
  ModemOkFail = 'MODEM_OK_FAIL',
  ModemPowerFail = 'MODEM_POWER_FAIL',
  ModemPowerRetry = 'MODEM_POWER_RETRY',
  ModemPowerSaveMode = 'MODEM_POWER_SAVE_MODE',
  ModemReady = 'MODEM_READY',
  ModemRepeat = 'MODEM_REPEAT',
  ModemResetFail = 'MODEM_RESET_FAIL',
  ModemResetRetry = 'MODEM_RESET_RETRY',
  ModemShutdownFail = 'MODEM_SHUTDOWN_FAIL',
  ModemStatus = 'MODEM_STATUS',
  ModemStopShutdown = 'MODEM_STOP_SHUTDOWN',
  ModemUarfcn = 'MODEM_UARFCN',
  ModemUsageChange = 'MODEM_USAGE_CHANGE',
  ModemWaitDialin = 'MODEM_WAIT_DIALIN',
  MoteHealthCheck = 'MOTE_HEALTH_CHECK',
  MoteTableFlash = 'MOTE_TABLE_FLASH',
  MoteTableFull = 'MOTE_TABLE_FULL',
  NetworkCellId = 'NETWORK_CELL_ID',
  NetworkDetach = 'NETWORK_DETACH',
  NetworkFirewall = 'NETWORK_FIREWALL',
  NetworkName = 'NETWORK_NAME',
  NetworkType = 'NETWORK_TYPE',
  NewMapKey = 'NEW_MAP_KEY',
  NoVolumeByLane = 'NO_VOLUME_BY_LANE',
  NtcipInfo = 'NTCIP_INFO',
  OldestVbvIndex = 'OLDEST_VBV_INDEX',
  OutputOff = 'OUTPUT_OFF',
  OutputOn = 'OUTPUT_ON',
  PacketNetworkType = 'PACKET_NETWORK_TYPE',
  PacketWrap = 'PACKET_WRAP',
  PeripheralAreaFetch = 'PERIPHERAL_AREA_FETCH',
  PgsApiError = 'PGS_API_ERROR',
  PgsVehicleCount = 'PGS_VEHICLE_COUNT',
  PmError = 'PM_ERROR',
  PmPowerDown = 'PM_POWER_DOWN',
  PmPowerUp = 'PM_POWER_UP',
  PowerSwitch = 'POWER_SWITCH',
  Pro1Config = 'PRO1_CONFIG',
  Pro1DataSize = 'PRO1_DATA_SIZE',
  Pro1ExtStatusChange = 'PRO1_EXT_STATUS_CHANGE',
  Pro1Firmware = 'PRO1_FIRMWARE',
  Pro1Nak = 'PRO1_NAK',
  Pro1StateChange = 'PRO1_STATE_CHANGE',
  Pro1StatusChange = 'PRO1_STATUS_CHANGE',
  Pro1TxCount = 'PRO1_TX_COUNT',
  Pro1TxError = 'PRO1_TX_ERROR',
  Pro2Config = 'PRO2_CONFIG',
  Pro2DataSize = 'PRO2_DATA_SIZE',
  Pro2ExtStatusChange = 'PRO2_EXT_STATUS_CHANGE',
  Pro2Firmware = 'PRO2_FIRMWARE',
  Pro2Nak = 'PRO2_NAK',
  Pro2StateChange = 'PRO2_STATE_CHANGE',
  Pro2StatusChange = 'PRO2_STATUS_CHANGE',
  Pro2TxCount = 'PRO2_TX_COUNT',
  Pro2TxError = 'PRO2_TX_ERROR',
  ProbeFault = 'PROBE_FAULT',
  ProbeRecover = 'PROBE_RECOVER',
  ProcessorTemperature = 'PROCESSOR_TEMPERATURE',
  Profiler = 'PROFILER',
  ProConfig = 'PRO_CONFIG',
  ProConfig2 = 'PRO_CONFIG2',
  ReadFlashMsg = 'READ_FLASH_MSG',
  ReadLog = 'READ_LOG',
  ReadLogGprs = 'READ_LOG_GPRS',
  Reconfig = 'RECONFIG',
  ReedSwitch = 'REED_SWITCH',
  ReflashFirmware = 'REFLASH_FIRMWARE',
  ResetLog = 'RESET_LOG',
  ResetPin = 'RESET_PIN',
  ResetReason = 'RESET_REASON',
  ResetSd = 'RESET_SD',
  ResetUnknown = 'RESET_UNKNOWN',
  Rtc = 'RTC',
  RtcDisabled = 'RTC_DISABLED',
  RtcFail = 'RTC_FAIL',
  RtcOscFault = 'RTC_OSC_FAULT',
  RtcOsfCleared = 'RTC_OSF_CLEARED',
  RtcTime = 'RTC_TIME',
  RtcTimeDay = 'RTC_TIME_DAY',
  RtcTimeDow = 'RTC_TIME_DOW',
  RtcTimeFail = 'RTC_TIME_FAIL',
  RtcTimeHour = 'RTC_TIME_HOUR',
  RtcTimeMinute = 'RTC_TIME_MINUTE',
  RtcTimeMonth = 'RTC_TIME_MONTH',
  RtcTimeSecond = 'RTC_TIME_SECOND',
  RtcTimeYear = 'RTC_TIME_YEAR',
  RtError = 'RT_ERROR',
  RtPowerDown = 'RT_POWER_DOWN',
  RtPowerUp = 'RT_POWER_UP',
  RtStatus = 'RT_STATUS',
  SdCardAccess = 'SD_CARD_ACCESS',
  SdCardNotPresent = 'SD_CARD_NOT_PRESENT',
  SdCardPresent = 'SD_CARD_PRESENT',
  SdErrorCode = 'SD_ERROR_CODE',
  SdFail = 'SD_FAIL',
  SdInitFail = 'SD_INIT_FAIL',
  SdInvalidCard = 'SD_INVALID_CARD',
  SdInvalidResponse = 'SD_INVALID_RESPONSE',
  SdManfDate = 'SD_MANF_DATE',
  SdManfId = 'SD_MANF_ID',
  SdNotIdle = 'SD_NOT_IDLE',
  SdNumAttempts = 'SD_NUM_ATTEMPTS',
  SdNumBlocks = 'SD_NUM_BLOCKS',
  SdOem = 'SD_OEM',
  SdPreEraseInvalid = 'SD_PRE_ERASE_INVALID',
  SdR1Response = 'SD_R1_RESPONSE',
  SdR2Response = 'SD_R2_RESPONSE',
  SdR3Response = 'SD_R3_RESPONSE',
  SdReadFail = 'SD_READ_FAIL',
  SdReadResponse = 'SD_READ_RESPONSE',
  SdReadStatusFail = 'SD_READ_STATUS_FAIL',
  SdReboot = 'SD_REBOOT',
  SdSerial = 'SD_SERIAL',
  SdTransferFail = 'SD_TRANSFER_FAIL',
  SdWriteBlockFail = 'SD_WRITE_BLOCK_FAIL',
  SdWriteProtect = 'SD_WRITE_PROTECT',
  SdWriteResponse = 'SD_WRITE_RESPONSE',
  SearchHeader = 'SEARCH_HEADER',
  SecurityText = 'SECURITY_TEXT',
  SendingConnect = 'SENDING_CONNECT',
  Sensor = 'SENSOR',
  SensorsPerLane = 'SENSORS_PER_LANE',
  Sensor_1Average = 'SENSOR_1_AVERAGE',
  Sensor_2Average = 'SENSOR_2_AVERAGE',
  Sensor_3Average = 'SENSOR_3_AVERAGE',
  Sensor_4Average = 'SENSOR_4_AVERAGE',
  Sensor_5Average = 'SENSOR_5_AVERAGE',
  Sensor_6Average = 'SENSOR_6_AVERAGE',
  Sensor_7Average = 'SENSOR_7_AVERAGE',
  Sensor_8Average = 'SENSOR_8_AVERAGE',
  Sensor_9Average = 'SENSOR_9_AVERAGE',
  Sensor_10Average = 'SENSOR_10_AVERAGE',
  Sensor_11Average = 'SENSOR_11_AVERAGE',
  Sensor_12Average = 'SENSOR_12_AVERAGE',
  Sensor_13Average = 'SENSOR_13_AVERAGE',
  Sensor_14Average = 'SENSOR_14_AVERAGE',
  Sensor_15Average = 'SENSOR_15_AVERAGE',
  Sensor_16Average = 'SENSOR_16_AVERAGE',
  SensorLane = 'SENSOR_LANE',
  SensorProbeNew = 'SENSOR_PROBE_NEW',
  SensorProbeOld = 'SENSOR_PROBE_OLD',
  SentEscape = 'SENT_ESCAPE',
  ShutdownBatteryVoltage = 'SHUTDOWN_BATTERY_VOLTAGE',
  SignalQuality = 'SIGNAL_QUALITY',
  SizeRecord = 'SIZE_RECORD',
  SlaveError = 'SLAVE_ERROR',
  SlaveResetReason = 'SLAVE_RESET_REASON',
  SlaveStackMemory = 'SLAVE_STACK_MEMORY',
  SlaveStackPointerFunc = 'SLAVE_STACK_POINTER_FUNC',
  SlaveStackPointerVal = 'SLAVE_STACK_POINTER_VAL',
  SocketError = 'SOCKET_ERROR',
  SolarVoltage = 'SOLAR_VOLTAGE',
  Spi1Error = 'SPI1_ERROR',
  Spi1Nak = 'SPI1_NAK',
  Spi1Reset = 'SPI1_RESET',
  Spi1SendError = 'SPI1_SEND_ERROR',
  Spi2Error = 'SPI2_ERROR',
  Spi2Nak = 'SPI2_NAK',
  Spi2Reset = 'SPI2_RESET',
  Spi2SendError = 'SPI2_SEND_ERROR',
  SpiError = 'SPI_ERROR',
  StackMemory = 'STACK_MEMORY',
  StackPointerFunc = 'STACK_POINTER_FUNC',
  StackPointerVal = 'STACK_POINTER_VAL',
  StartupBatteryVoltage = 'STARTUP_BATTERY_VOLTAGE',
  StartGps = 'START_GPS',
  StartModem = 'START_MODEM',
  StopGps = 'STOP_GPS',
  StrainOffset = 'STRAIN_OFFSET',
  StrainZero = 'STRAIN_ZERO',
  StrainZeroComplete = 'STRAIN_ZERO_COMPLETE',
  System = 'SYSTEM',
  SystemHalt = 'SYSTEM_HALT',
  SystemInit = 'SYSTEM_INIT',
  SystemInitComplete = 'SYSTEM_INIT_COMPLETE',
  TdfAddress = 'TDF_ADDRESS',
  TdfAddress_4K = 'TDF_ADDRESS_4K',
  TdfDaysDiff = 'TDF_DAYS_DIFF',
  TdfErase_4K = 'TDF_ERASE_4K',
  TdfHoursCopied = 'TDF_HOURS_COPIED',
  TdfHoursDiff = 'TDF_HOURS_DIFF',
  TdfInvalid = 'TDF_INVALID',
  TdfReset = 'TDF_RESET',
  TdfSaveHour = 'TDF_SAVE_HOUR',
  TelitAtFailed = 'TELIT_AT_FAILED',
  TimeDiff = 'TIME_DIFF',
  TimeUpdate = 'TIME_UPDATE',
  TnlUpdated = 'TNL_UPDATED',
  TransFlashSz = 'TRANS_FLASH_SZ',
  TransLastHour = 'TRANS_LAST_HOUR',
  UiDelay = 'UI_DELAY',
  Unknown1 = 'UNKNOWN1',
  Unknown2 = 'UNKNOWN2',
  Unknown3 = 'UNKNOWN3',
  Unknown4 = 'UNKNOWN4',
  UserRequestFactoryReset = 'USER_REQUEST_FACTORY_RESET',
  ValidDateTime = 'VALID_DATE_TIME',
  Vbv = 'VBV',
  VbvDayUsage = 'VBV_DAY_USAGE',
  VbvHourSdPointer = 'VBV_HOUR_SD_POINTER',
  VbvHourUsage = 'VBV_HOUR_USAGE',
  VbvLastDate = 'VBV_LAST_DATE',
  VbvLastFullHour = 'VBV_LAST_FULL_HOUR',
  VbvLastPartialHour = 'VBV_LAST_PARTIAL_HOUR',
  VbvMaxDayStorage = 'VBV_MAX_DAY_STORAGE',
  VbvPointer = 'VBV_POINTER',
  VbvReadFail = 'VBV_READ_FAIL',
  VbvSdPointer = 'VBV_SD_POINTER',
  VbvTransferDataEnd = 'VBV_TRANSFER_DATA_END',
  VbvTransferDataStart = 'VBV_TRANSFER_DATA_START',
  VbvTransferEnd = 'VBV_TRANSFER_END',
  VbvTransferStart = 'VBV_TRANSFER_START',
  VehicleBytes = 'VEHICLE_BYTES',
  VehicleCount = 'VEHICLE_COUNT',
  VehicleFront = 'VEHICLE_FRONT',
  WaitRingTimeout = 'WAIT_RING_TIMEOUT',
  WanIp = 'WAN_IP',
  WatchdogTimeout = 'WATCHDOG_TIMEOUT',
  WriteFlashMsg = 'WRITE_FLASH_MSG',
  WsError = 'WS_ERROR',
  WsPowerDown = 'WS_POWER_DOWN',
  WsPowerUp = 'WS_POWER_UP'
}

export type DetectorLogSubtype = {
  __typename?: 'DetectorLogSubtype';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DetectorManeuverSettingConfig = {
  __typename?: 'DetectorManeuverSettingConfig';
  destinationDirection?: Maybe<Scalars['String']>;
  maneuverDescription?: Maybe<Scalars['String']>;
  maneuverId?: Maybe<Scalars['String']>;
  originDirection?: Maybe<Scalars['String']>;
};

export type DetectorPerformanceReportAvailabilityQuery = {
  detectorIds: Array<Scalars['ID']>;
  endDateTime: Scalars['Date'];
  ruleIds: Array<Scalars['ID']>;
  startDateTime: Scalars['Date'];
};

export type DetectorPerformanceReportDayWiseAvailabilityInfo = {
  __typename?: 'DetectorPerformanceReportDayWiseAvailabilityInfo';
  availability?: Maybe<Array<DetectorReportRuleAvailability>>;
  date?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<DetectorReportRule>>;
};

export type DetectorPerformanceReportQuery = {
  detectorIds: Array<Scalars['ID']>;
  endDateTime: Scalars['Date'];
  ruleId: Scalars['ID'];
  startDateTime: Scalars['Date'];
};

export type DetectorPerformanceReportResult = {
  __typename?: 'DetectorPerformanceReportResult';
  date?: Maybe<Scalars['String']>;
  detector?: Maybe<Detector>;
  intervalList?: Maybe<Array<Scalars['Object']>>;
};

export type DetectorReceiverIpPort = {
  __typename?: 'DetectorReceiverIpPort';
  ip: Scalars['String'];
  port: Scalars['Int'];
};

export enum DetectorRecordAvailability {
  Full = 'FULL',
  NoConnect = 'NO_CONNECT',
  NoData = 'NO_DATA',
  Partial = 'PARTIAL'
}

export type DetectorReportConfigFormSection = FormSection & {
  __typename?: 'DetectorReportConfigFormSection';
  fields: Array<FieldConfig>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DetectorReportConfigFormView = FormView & {
  __typename?: 'DetectorReportConfigFormView';
  id: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<DetectorReportConfigFormSection>;
  type: FormViewType;
  views: Array<DetectorReportConfigFormView>;
};

export type DetectorReportConfiguration = {
  __typename?: 'DetectorReportConfiguration';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ruleIds?: Maybe<Array<Scalars['String']>>;
};

export type DetectorReportConfigurationConnection = {
  __typename?: 'DetectorReportConfigurationConnection';
  edges: Array<DetectorReportConfigurationConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorReportConfigurationConnectionEdge = {
  __typename?: 'DetectorReportConfigurationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorReportConfiguration;
};

export type DetectorReportConfigurationInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  ruleIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DetectorReportRule = {
  __typename?: 'DetectorReportRule';
  config: DetectorReportRuleConfig;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  reportTemplateName: Scalars['String'];
  template: DetectorReportTemplate;
};

export type DetectorReportRuleAvailability = {
  __typename?: 'DetectorReportRuleAvailability';
  availability?: Maybe<Scalars['Boolean']>;
  ruleId?: Maybe<Scalars['String']>;
};

export type DetectorReportRuleConfig = {
  __typename?: 'DetectorReportRuleConfig';
  comparableQuery?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  settings: Scalars['Object'];
};

export type DetectorReportRuleConnection = {
  __typename?: 'DetectorReportRuleConnection';
  edges: Array<DetectorReportRuleConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorReportRuleConnectionEdge = {
  __typename?: 'DetectorReportRuleConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorReportRule;
};

export type DetectorReportRuleForm = {
  __typename?: 'DetectorReportRuleForm';
  extraConfig: Scalars['Object'];
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  reportTemplate?: Maybe<DetectorReportTemplate>;
  views: Array<DetectorReportConfigFormView>;
};

export type DetectorReportRuleInput = {
  comparableQuery?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
  settings: Scalars['Object'];
  template: DetectorReportTemplate;
};

export enum DetectorReportTemplate {
  AiClassAccuracyCheck = 'AI_CLASS_ACCURACY_CHECK',
  AxleDataCheck = 'AXLE_DATA_CHECK',
  HistoricalCompareCheck = 'HISTORICAL_COMPARE_CHECK',
  MissingDataCheck = 'MISSING_DATA_CHECK',
  RatioCheck = 'RATIO_CHECK',
  ZeroFlowCheck = 'ZERO_FLOW_CHECK'
}

export type DetectorReportTemplateOption = {
  __typename?: 'DetectorReportTemplateOption';
  code?: Maybe<DetectorReportTemplate>;
  label?: Maybe<Scalars['String']>;
};

export type DetectorSpeedBinConfig = {
  __typename?: 'DetectorSpeedBinConfig';
  speedBinIndex?: Maybe<Scalars['Int']>;
  speedBinName?: Maybe<Scalars['String']>;
  speedEndRange?: Maybe<Scalars['Float']>;
  speedStartRange?: Maybe<Scalars['Float']>;
};

export type DetectorSpeedReportQuery = {
  detectorId: Scalars['ID'];
  endDate: Scalars['Date'];
  lane?: InputMaybe<Scalars['Int']>;
  laneDirection?: InputMaybe<Direction>;
  startDate: Scalars['Date'];
};

export type DetectorTmasReport = {
  __typename?: 'DetectorTMASReport';
  content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DetectorTmasReportQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
  type: DetectorTmasReportType;
};

export enum DetectorTmasReportType {
  TnsClass = 'TNS_CLASS',
  TnsVolume = 'TNS_VOLUME'
}

export type DetectorTurningReport = {
  __typename?: 'DetectorTurningReport';
  classNames: Array<Scalars['String']>;
  data: Array<DetectorTurningReportOriginData>;
};

export type DetectorTurningReportDestinationData = {
  __typename?: 'DetectorTurningReportDestinationData';
  data: Array<DetectorClassIntervalCount>;
  isInwardTotal: Scalars['Boolean'];
  isOutwardTotal: Scalars['Boolean'];
  name: Scalars['String'];
  total: Scalars['Int'];
};

export type DetectorTurningReportOriginData = {
  __typename?: 'DetectorTurningReportOriginData';
  data: Array<DetectorTurningReportDestinationData>;
  inwardCount: Scalars['Int'];
  name: Scalars['String'];
  outwardCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type DetectorTurningReportQuery = {
  detectorId: Scalars['ID'];
  endDate: Scalars['Date'];
  granularity: DetectorVehicleVolumeGranularity;
  startDate: Scalars['Date'];
  viewType: DateViewType;
};

export enum DetectorType {
  Classifier = 'CLASSIFIER',
  CyclePed = 'CYCLE_PED',
  VideoDetector = 'VIDEO_DETECTOR',
  Wim = 'WIM'
}

export type DetectorVehicle = {
  __typename?: 'DetectorVehicle';
  aiClassName?: Maybe<Scalars['String']>;
  aiClassVerified?: Maybe<Scalars['Boolean']>;
  aiClassVerifiedTS?: Maybe<Scalars['DateTime']>;
  aiConfidence?: Maybe<Scalars['Int']>;
  anprImageAvailable?: Maybe<Scalars['Boolean']>;
  axleSpace1?: Maybe<Scalars['Float']>;
  axleSpace2?: Maybe<Scalars['Float']>;
  axleSpace3?: Maybe<Scalars['Float']>;
  axleSpace4?: Maybe<Scalars['Float']>;
  axleSpace5?: Maybe<Scalars['Float']>;
  axleSpace6?: Maybe<Scalars['Float']>;
  axleSpace7?: Maybe<Scalars['Float']>;
  axleSpace8?: Maybe<Scalars['Float']>;
  axleSpace9?: Maybe<Scalars['Float']>;
  axleSpace10?: Maybe<Scalars['Float']>;
  axleSpace11?: Maybe<Scalars['Float']>;
  axleSpace12?: Maybe<Scalars['Float']>;
  axleSpace13?: Maybe<Scalars['Float']>;
  axleSpace14?: Maybe<Scalars['Float']>;
  axleSpace15?: Maybe<Scalars['Float']>;
  axleSpace16?: Maybe<Scalars['Float']>;
  axleSpace17?: Maybe<Scalars['Float']>;
  axleSpace18?: Maybe<Scalars['Float']>;
  axleSpace19?: Maybe<Scalars['Float']>;
  axleSpace20?: Maybe<Scalars['Float']>;
  axleSpace21?: Maybe<Scalars['Float']>;
  axleSpace22?: Maybe<Scalars['Float']>;
  axleSpace23?: Maybe<Scalars['Float']>;
  axleSpace24?: Maybe<Scalars['Float']>;
  axleSpace25?: Maybe<Scalars['Float']>;
  axleSpace26?: Maybe<Scalars['Float']>;
  axleSpace27?: Maybe<Scalars['Float']>;
  axleSpace28?: Maybe<Scalars['Float']>;
  axleSpace29?: Maybe<Scalars['Float']>;
  axleSpacings?: Maybe<Array<Scalars['Int']>>;
  axleWeight1?: Maybe<Scalars['Float']>;
  axleWeight2?: Maybe<Scalars['Float']>;
  axleWeight3?: Maybe<Scalars['Float']>;
  axleWeight4?: Maybe<Scalars['Float']>;
  axleWeight5?: Maybe<Scalars['Float']>;
  axleWeight6?: Maybe<Scalars['Float']>;
  axleWeight7?: Maybe<Scalars['Float']>;
  axleWeight8?: Maybe<Scalars['Float']>;
  axleWeight9?: Maybe<Scalars['Float']>;
  axleWeight10?: Maybe<Scalars['Float']>;
  axleWeight11?: Maybe<Scalars['Float']>;
  axleWeight12?: Maybe<Scalars['Float']>;
  axleWeight13?: Maybe<Scalars['Float']>;
  axleWeight14?: Maybe<Scalars['Float']>;
  axleWeight15?: Maybe<Scalars['Float']>;
  axleWeight16?: Maybe<Scalars['Float']>;
  axleWeight17?: Maybe<Scalars['Float']>;
  axleWeight18?: Maybe<Scalars['Float']>;
  axleWeight19?: Maybe<Scalars['Float']>;
  axleWeight20?: Maybe<Scalars['Float']>;
  axleWeight21?: Maybe<Scalars['Float']>;
  axleWeight22?: Maybe<Scalars['Float']>;
  axleWeight23?: Maybe<Scalars['Float']>;
  axleWeight24?: Maybe<Scalars['Float']>;
  axleWeight25?: Maybe<Scalars['Float']>;
  axleWeight26?: Maybe<Scalars['Float']>;
  axleWeight27?: Maybe<Scalars['Float']>;
  axleWeight28?: Maybe<Scalars['Float']>;
  axleWeight29?: Maybe<Scalars['Float']>;
  axleWeight30?: Maybe<Scalars['Float']>;
  axlesCount?: Maybe<Scalars['Int']>;
  chassisCode?: Maybe<Scalars['Int']>;
  classInfo?: Maybe<DetectorClass>;
  dimensionProfile?: Maybe<File>;
  dimensionProfileAvailable?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<HiTracVehicleDirection>;
  firstConnectAt?: Maybe<Scalars['DateTime']>;
  grossWeight?: Maybe<Scalars['Float']>;
  headway?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  images?: Maybe<Array<File>>;
  imagesAvailable?: Maybe<Scalars['Boolean']>;
  lane?: Maybe<Scalars['Int']>;
  laneDirection?: Maybe<DetectorDirection>;
  lastAxleSpeed?: Maybe<Scalars['Int']>;
  leftTyreType?: Maybe<Array<Scalars['Int']>>;
  legalStatus?: Maybe<Scalars['Int']>;
  legalStatusCodes?: Maybe<Array<LegalStatusCode>>;
  length?: Maybe<Scalars['Int']>;
  loopFailure?: Maybe<Scalars['Boolean']>;
  loopOnTime?: Maybe<Scalars['Int']>;
  loopSignature?: Maybe<File>;
  loopSignatureAvailable?: Maybe<Scalars['Boolean']>;
  maneuverId?: Maybe<Scalars['String']>;
  maxChassisList?: Maybe<Array<Scalars['Int']>>;
  minChassisList?: Maybe<Array<Scalars['Int']>>;
  ocr?: Maybe<Scalars['String']>;
  ocrRear?: Maybe<Scalars['String']>;
  overViewImageAvailable?: Maybe<Scalars['Boolean']>;
  overWeight?: Maybe<Scalars['Boolean']>;
  rightTyreType?: Maybe<Array<Scalars['Int']>>;
  sensorWeights?: Maybe<Array<Array<Scalars['Int']>>>;
  sensorWheelWeights?: Maybe<Array<Scalars['Int']>>;
  serial?: Maybe<Scalars['Int']>;
  speed?: Maybe<Scalars['Float']>;
  straddle?: Maybe<Scalars['Boolean']>;
  straddleLane?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  typeAPositions?: Maybe<Array<Scalars['Int']>>;
  typeBPositions?: Maybe<Array<Scalars['Int']>>;
  tyreA1Parameter?: Maybe<Scalars['Int']>;
  tyreA2Parameter?: Maybe<Scalars['Int']>;
  tyreA3Parameter?: Maybe<Scalars['Int']>;
  tyreA4Parameter?: Maybe<Scalars['Int']>;
  tyreB1Parameter?: Maybe<Scalars['Int']>;
  tyreB2Parameter?: Maybe<Scalars['Int']>;
  tyreB3Parameter?: Maybe<Scalars['Int']>;
  tyreB4Parameter?: Maybe<Scalars['Int']>;
  validity?: Maybe<Array<HiTracVehicleValidity>>;
  verifiedAiClassName?: Maybe<Scalars['String']>;
  verifiedClassIndex?: Maybe<Scalars['Int']>;
  verifiedClassName?: Maybe<Scalars['String']>;
  verifiedClassNameStatus?: Maybe<Scalars['Boolean']>;
  verifiedClassNameStatusAt?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Float']>;
};

export type DetectorVehicle3DProfile = {
  __typename?: 'DetectorVehicle3DProfile';
  dimensionProfile: Array<Array<Scalars['Int']>>;
  id: Scalars['String'];
};

export type DetectorVehicleAxleWeightChart = {
  __typename?: 'DetectorVehicleAxleWeightChart';
  data?: Maybe<Array<DetectorVehicleAxleWeightChartDetail>>;
  lane?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleAxleWeightChartDetail = {
  __typename?: 'DetectorVehicleAxleWeightChartDetail';
  averageWeight: Scalars['Int'];
  grossWeight: Scalars['Int'];
  temperature: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type DetectorVehicleAxleWeightChartQuery = {
  classIndex: Scalars['Int'];
  detectorId: Scalars['ID'];
  endDate: Scalars['Date'];
  granularity: DetectorVehicleVolumeGranularity;
  startDate: Scalars['Date'];
};

export type DetectorVehicleConnection = {
  __typename?: 'DetectorVehicleConnection';
  edges: Array<DetectorVehicleConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DetectorVehicleConnectionEdge = {
  __typename?: 'DetectorVehicleConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DetectorVehicle;
};

export type DetectorVehicleDayWiseAvailability = {
  __typename?: 'DetectorVehicleDayWiseAvailability';
  availability?: Maybe<DetectorRecordAvailability>;
  id?: Maybe<Scalars['String']>;
};

export type DetectorVehicleDayWiseAvailabilityQuery = {
  detectorIds: Array<Scalars['ID']>;
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
};

export type DetectorVehicleDayWisePartialData = {
  __typename?: 'DetectorVehicleDayWisePartialData';
  numberOfPartialDataOccurance?: Maybe<Scalars['Int']>;
  packetLossDate?: Maybe<Scalars['String']>;
  packetLossInfo: Array<DetectorVehiclePartialData>;
  totalMissingRecordCount?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleImportProgress = {
  __typename?: 'DetectorVehicleImportProgress';
  fileIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  status?: Maybe<DetectorVehicleImportStatus>;
  type?: Maybe<DetectorVehicleImportType>;
};

export type DetectorVehicleImportProgressInput = {
  fileIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<DetectorVehicleImportStatus>;
  type?: InputMaybe<DetectorVehicleImportType>;
};

export enum DetectorVehicleImportStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum DetectorVehicleImportType {
  VehicleImport = 'VEHICLE_IMPORT'
}

export type DetectorVehiclePartialData = {
  __typename?: 'DetectorVehiclePartialData';
  endSerial?: Maybe<Scalars['Int']>;
  missingRecordsCount?: Maybe<Scalars['Int']>;
  packetLossEndTime?: Maybe<Scalars['DateTime']>;
  packetLossStartTime?: Maybe<Scalars['DateTime']>;
  startSerial?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleQuery = {
  /**  Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  The class index of vehicle */
  classIndexes?: InputMaybe<Array<Scalars['Int']>>;
  /**  The class name of vehicle */
  classNames?: InputMaybe<Array<Scalars['String']>>;
  /**  The detector id of detector */
  detectorId: Scalars['ID'];
  /**  End time filter */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter routes */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. If not sent, all fields will be searched. */
  searchFields?: InputMaybe<Array<DetectorVehiclesSearchField>>;
  /**  Text to search for */
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<DetectorVehiclesSortField>;
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type DetectorVehicleSignature = {
  __typename?: 'DetectorVehicleSignature';
  id: Scalars['String'];
  signature: Array<Scalars['Int']>;
};

export type DetectorVehicleSpeedBinReport = {
  __typename?: 'DetectorVehicleSpeedBinReport';
  averageSpeed?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  lane?: Maybe<Scalars['Int']>;
  laneDirection?: Maybe<Direction>;
  speedAt85Percent?: Maybe<Scalars['Int']>;
  speedBinInfo?: Maybe<Scalars['Object']>;
  speedStandardDeviation?: Maybe<Scalars['Int']>;
  totalVolume?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleSpeedBinReportResult = {
  __typename?: 'DetectorVehicleSpeedBinReportResult';
  intervals?: Maybe<Array<DetectorVehicleSpeedBinReport>>;
};

export type DetectorVehicleVolume = {
  __typename?: 'DetectorVehicleVolume';
  timestamp?: Maybe<Scalars['DateTime']>;
  volume?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleVolumeCount = {
  __typename?: 'DetectorVehicleVolumeCount';
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DetectorVehicleVolumeCountQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
};

export enum DetectorVehicleVolumeGranularity {
  All = 'ALL',
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutes_1 = 'MINUTES_1',
  Minutes_5 = 'MINUTES_5',
  Minutes_10 = 'MINUTES_10',
  Minutes_15 = 'MINUTES_15',
  Minutes_30 = 'MINUTES_30'
}

export enum DetectorVehicleVolumeGroup {
  Class = 'CLASS',
  Direction = 'DIRECTION',
  DirectionClass = 'DIRECTION_CLASS',
  Lane = 'LANE',
  LaneClass = 'LANE_CLASS',
  LaneDirection = 'LANE_DIRECTION',
  LaneDirectionClass = 'LANE_DIRECTION_CLASS',
  Maneuver = 'MANEUVER',
  ManeuverClass = 'MANEUVER_CLASS',
  SpeedBand = 'SPEED_BAND',
  Summary = 'SUMMARY'
}

export type DetectorVehicleVolumeGroupCountsQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  groupBy?: InputMaybe<DetectorVehicleVolumeGroup>;
  startDateTime: Scalars['DateTime'];
};

export type DetectorVehicleVolumeGroupInfo = {
  __typename?: 'DetectorVehicleVolumeGroupInfo';
  name?: Maybe<Scalars['String']>;
  volumeInfo?: Maybe<Array<DetectorVehicleVolume>>;
};

export type DetectorVehicleVolumeIntervalCount = {
  __typename?: 'DetectorVehicleVolumeIntervalCount';
  date?: Maybe<Scalars['String']>;
  intervalList?: Maybe<Array<DetectorVehicleVolumeIntervalInfo>>;
  lane?: Maybe<Scalars['String']>;
  laneDirection?: Maybe<Scalars['String']>;
  maneuver?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  peaks?: Maybe<DetectorVolumePeaks>;
};

export type DetectorVehicleVolumeIntervalCountsClearQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
};

export type DetectorVehicleVolumeIntervalCountsQuery = {
  dateRanges: Array<DateRange>;
  detectorIds: Array<Scalars['ID']>;
  granularity?: InputMaybe<DetectorVehicleVolumeGranularity>;
  groupBy?: InputMaybe<DetectorVehicleVolumeGroup>;
  viewType: DateViewType;
};

export type DetectorVehicleVolumeIntervalInfo = {
  __typename?: 'DetectorVehicleVolumeIntervalInfo';
  count?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
};

export type DetectorVehicleVolumeQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  granularity?: InputMaybe<DetectorVehicleVolumeGranularity>;
  groupBy?: InputMaybe<DetectorVehicleVolumeGroup>;
  startDateTime: Scalars['DateTime'];
};

export type DetectorVehicleYearlySummary = {
  __typename?: 'DetectorVehicleYearlySummary';
  averageDailyTraffic?: Maybe<Scalars['Float']>;
  averageSpeed?: Maybe<Scalars['Float']>;
  averageSpeedAt85Percent?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type DetectorVehicleYearlySummaryQuery = {
  detectorId: Scalars['ID'];
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  viewType?: InputMaybe<DateViewType>;
};

export enum DetectorVehiclesSearchField {
  AiClassName = 'AI_CLASS_NAME',
  Ocr = 'OCR',
  RearOcr = 'REAR_OCR',
  VerifiedAiClassName = 'VERIFIED_AI_CLASS_NAME'
}

export enum DetectorVehiclesSortField {
  Lane = 'LANE',
  Serial = 'SERIAL',
  Speed = 'SPEED',
  Timestamp = 'TIMESTAMP'
}

export type DetectorVerificationClass = {
  __typename?: 'DetectorVerificationClass';
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DetectorVolumePeaks = {
  __typename?: 'DetectorVolumePeaks';
  amPeakCount?: Maybe<Scalars['Int']>;
  amPeakHour?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Int']>;
  daytimeAverage?: Maybe<Scalars['Int']>;
  daytimeTotal?: Maybe<Scalars['Int']>;
  nightTimeAverage?: Maybe<Scalars['Int']>;
  nightTimeTotal?: Maybe<Scalars['Int']>;
  pmPeakCount?: Maybe<Scalars['Int']>;
  pmPeakHour?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  twelveHourAverage?: Maybe<Scalars['Int']>;
  twelveHourTotal?: Maybe<Scalars['Int']>;
  weekdayAverage?: Maybe<Scalars['Int']>;
  weekdayTotal?: Maybe<Scalars['Int']>;
  weekendAverage?: Maybe<Scalars['Int']>;
  weekendTotal?: Maybe<Scalars['Int']>;
};

export type DetectorWeather = {
  __typename?: 'DetectorWeather';
  date?: Maybe<Scalars['String']>;
  detectorId?: Maybe<Scalars['String']>;
  hour?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  weatherInfo?: Maybe<Weather>;
};

export type DetectorWeatherQuery = {
  detectorId: Scalars['ID'];
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
};

export type DetectorWeightThreshold = {
  __typename?: 'DetectorWeightThreshold';
  lane?: Maybe<Scalars['Int']>;
  laneIndex?: Maybe<DetectorLaneIndex>;
  weightLowerThreshold?: Maybe<Scalars['Float']>;
  weightUpperThreshold?: Maybe<Scalars['Float']>;
};

export type DeviceDatabase = {
  __typename?: 'DeviceDatabase';
  approved: Scalars['Boolean'];
  approvedComment?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  files: Array<DeviceDatabaseFile>;
  firmwareVersion?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intersectionId: Scalars['String'];
  locked: Scalars['Boolean'];
  lockedByUser?: Maybe<OrganizationKineticUser>;
  lockedDate?: Maybe<Scalars['DateTime']>;
  progress?: Maybe<Scalars['Int']>;
  protocol?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DeviceDatabaseStatus>;
  type: DeviceDatabaseType;
  user?: Maybe<OrganizationKineticUser>;
};

export type DeviceDatabaseActionUpdated = {
  __typename?: 'DeviceDatabaseActionUpdated';
  hasActiveDatabaseAction: Scalars['Boolean'];
  intersectionId: Scalars['ID'];
};

export type DeviceDatabaseComparison = {
  __typename?: 'DeviceDatabaseComparison';
  compareDatabaseId: Scalars['ID'];
  differences: Array<DeviceDatabaseComparisonDifference>;
  originalDatabaseId: Scalars['ID'];
};

export type DeviceDatabaseComparisonDifference = {
  __typename?: 'DeviceDatabaseComparisonDifference';
  compareMib: DatabaseComparisonMibValue;
  originalMib: DatabaseComparisonMibValue;
  type: DeviceDatabaseComparisonDifferenceType;
};

export enum DeviceDatabaseComparisonDifferenceType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export type DeviceDatabaseConnection = {
  __typename?: 'DeviceDatabaseConnection';
  edges: Array<DeviceDatabaseConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceDatabaseConnectionEdge = {
  __typename?: 'DeviceDatabaseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DeviceDatabase;
};

export type DeviceDatabaseFile = {
  __typename?: 'DeviceDatabaseFile';
  format: DeviceDatabaseFormat;
  key: Scalars['String'];
  url: Scalars['String'];
};

export type DeviceDatabaseFileRequest = {
  fileId?: InputMaybe<Scalars['String']>;
  fileKey?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
};

export enum DeviceDatabaseFormat {
  Bin = 'BIN',
  Json = 'JSON'
}

export type DeviceDatabaseLock = {
  __typename?: 'DeviceDatabaseLock';
  databaseId?: Maybe<Scalars['String']>;
  intersectionId: Scalars['String'];
  lockedDate: Scalars['DateTime'];
  userDisplayName: Scalars['String'];
  userId: Scalars['String'];
};

export type DeviceDatabaseLockStatus = {
  __typename?: 'DeviceDatabaseLockStatus';
  action: DatabaseLockAction;
  databaseId?: Maybe<Scalars['String']>;
  databaseType: DeviceDatabaseType;
  intersectionId: Scalars['String'];
  updatedBy: DatabaseLockUpdateAction;
  userDisplayName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceDatabaseMibChangeLog = {
  __typename?: 'DeviceDatabaseMibChangeLog';
  comment?: Maybe<Scalars['String']>;
  databaseId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  deviceDatabaseType?: Maybe<DeviceDatabaseType>;
  id: Scalars['ID'];
  intersectionId: Scalars['String'];
  mib?: Maybe<Scalars['Object']>;
  updatedAt: Scalars['DateTime'];
  userDisplayName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceDatabaseMibChangeLogConnection = {
  __typename?: 'DeviceDatabaseMibChangeLogConnection';
  edges: Array<DeviceDatabaseMibChangeLogConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceDatabaseMibChangeLogConnectionEdge = {
  __typename?: 'DeviceDatabaseMibChangeLogConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DeviceDatabaseMibChangeLog;
};

export enum DeviceDatabaseStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  FieldReady = 'FIELD_READY',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

export enum DeviceDatabaseType {
  Draft = 'DRAFT',
  FullDownload = 'FULL_DOWNLOAD',
  Live = 'LIVE',
  ManualUpload = 'MANUAL_UPLOAD',
  PartialDownload = 'PARTIAL_DOWNLOAD',
  PostLockUpload = 'POST_LOCK_UPLOAD',
  ScheduledUpload = 'SCHEDULED_UPLOAD',
  UserSavedUpload = 'USER_SAVED_UPLOAD'
}

export type DeviceEvent = {
  __typename?: 'DeviceEvent';
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  intersectionId: Scalars['String'];
  parameter?: Maybe<Scalars['Object']>;
  timestamp: Scalars['DateTime'];
  vendorEventCode: Scalars['Int'];
};

export type DeviceEventConnection = {
  __typename?: 'DeviceEventConnection';
  edges: Array<DeviceEventConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceEventConnectionEdge = {
  __typename?: 'DeviceEventConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DeviceEvent;
};

export enum DeviceViewsProtocolEntityEnum {
  CaltransAb3418E = 'CALTRANS_AB3418E',
  EconoliteAsc3 = 'ECONOLITE_ASC3',
  EconoliteEos = 'ECONOLITE_EOS',
  Maxtime = 'MAXTIME',
  MaxtimeRampmeter = 'MAXTIME_RAMPMETER',
  SiemensSepac = 'SIEMENS_SEPAC'
}

export type DeviceViewsUi = {
  __typename?: 'DeviceViewsUi';
  id: DeviceViewsProtocolEntityEnum;
  views: IntersectionDeviceUiView;
};

export enum Direction {
  /**  East  */
  East = 'EAST',
  /**  North  */
  North = 'NORTH',
  /**  Other  */
  Other = 'OTHER',
  /**  Reversible  */
  Reversible = 'REVERSIBLE',
  /**  South  */
  South = 'SOUTH',
  /**  West  */
  West = 'WEST'
}

export type DirectionDescriptionConfig = {
  __typename?: 'DirectionDescriptionConfig';
  description: Scalars['String'];
  direction: Scalars['String'];
};

export type DirectionalProgrammedSplits = {
  __typename?: 'DirectionalProgrammedSplits';
  down?: Maybe<PhaseServiceTimes>;
  up?: Maybe<PhaseServiceTimes>;
};

export type Directory = {
  __typename?: 'Directory';
  directories: Array<Directory>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  owner: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  publicAccess: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
};

export type DirectoryInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
};

export type EffectivePrivilegeAssignment = {
  __typename?: 'EffectivePrivilegeAssignment';
  kineticModule: KineticModule;
  privilegeType: PrivilegeType;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: Maybe<Scalars['Object']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type Element = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type EmailAttachment = {
  data: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
};

export type EmailMessage = {
  attachments: Array<EmailAttachment>;
  bcc?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

export type EmailRuleConnection = {
  __typename?: 'EmailRuleConnection';
  edges: Array<EmailRuleConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type EmailRuleConnectionEdge = {
  __typename?: 'EmailRuleConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EmailRuleDto;
};

export type EmailRuleDto = {
  __typename?: 'EmailRuleDto';
  active: Scalars['Boolean'];
  /**  Query configuration  */
  config?: Maybe<Scalars['Object']>;
  description?: Maybe<Scalars['String']>;
  /**  when the task should end  */
  end?: Maybe<Scalars['DateTime']>;
  executive: Scalars['Boolean'];
  formId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  module?: Maybe<KineticModule>;
  name: Scalars['String'];
  recipients?: Maybe<Array<Maybe<RecipientDto>>>;
  /**  when the on-call task recurrence should end  */
  recurrenceEndDate?: Maybe<Scalars['DateTime']>;
  /**  when the on-call task recurrence should start */
  recurrenceStartDate?: Maybe<Scalars['DateTime']>;
  /**  RFC 5545 string rrule configuration  */
  rrule?: Maybe<Scalars['String']>;
  /**  The mvel rule string  */
  rule?: Maybe<Scalars['String']>;
  /**  when the task should start  */
  start?: Maybe<Scalars['DateTime']>;
  /**  The associated templates  */
  templates: Array<EmailTemplateDto>;
};

export type EmailRuleInput = {
  active: Scalars['Boolean'];
  config?: InputMaybe<Scalars['Object']>;
  description?: InputMaybe<Scalars['String']>;
  /**  when the task should end  */
  end?: InputMaybe<Scalars['DateTime']>;
  executive: Scalars['Boolean'];
  formId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  module?: InputMaybe<KineticModule>;
  name: Scalars['String'];
  recipients?: InputMaybe<Array<InputMaybe<Recipient>>>;
  /**  when the task recurrence should end  */
  recurrenceEndDate?: InputMaybe<Scalars['DateTime']>;
  /**  RFC 5545 string rrule configuration  */
  rrule?: InputMaybe<Scalars['String']>;
  /**  The mvel rule string  */
  rule?: InputMaybe<Scalars['String']>;
  /**  when the task should start  */
  start?: InputMaybe<Scalars['DateTime']>;
  /**  The associated templates  */
  templates: Array<Scalars['String']>;
};

export type EmailRuleQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  formId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  onlyOnCall?: InputMaybe<Scalars['Boolean']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EmailServerSettings = {
  __typename?: 'EmailServerSettings';
  auth: Scalars['Boolean'];
  from: Scalars['String'];
  host: Scalars['String'];
  ignoreTls: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  port: Scalars['Int'];
  requireStartTls: Scalars['Boolean'];
  sslEnabled: Scalars['Boolean'];
  sslTrust: Scalars['Boolean'];
  startTls: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
};

export type EmailServerSettingsInput = {
  auth: Scalars['Boolean'];
  from: Scalars['String'];
  host: Scalars['String'];
  ignoreTls: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
  port: Scalars['Int'];
  requireStartTls: Scalars['Boolean'];
  sslEnabled: Scalars['Boolean'];
  sslTrust: Scalars['Boolean'];
  startTls: Scalars['Boolean'];
  username?: InputMaybe<Scalars['String']>;
};

export type EmailTemplateConnection = {
  __typename?: 'EmailTemplateConnection';
  edges: Array<EmailTemplateConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type EmailTemplateConnectionEdge = {
  __typename?: 'EmailTemplateConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EmailTemplateDto;
};

export type EmailTemplateDto = {
  __typename?: 'EmailTemplateDto';
  body: Scalars['String'];
  form?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  module: KineticModule;
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  type: TemplateType;
};

export type EmailTemplateInput = {
  body: Scalars['String'];
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  module: KineticModule;
  name: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  type: TemplateType;
};

export type EmbeddedLayoutInput = {
  areas: Array<LayoutAreaInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export type Event = {
  __typename?: 'Event';
  /**  The activation type of the planned event  */
  activationType?: Maybe<ActivationType>;
  /**  Associated communication logs */
  associatedCommunicationLogs: Array<CommunicationLog>;
  /**  Associated detections */
  associatedDetections: Array<Detection>;
  /**  The attributes of the event built from an event form  */
  attributes: Scalars['Object'];
  /**  The event category  */
  category: EventCategory;
  /**  Does the event require confirmation before activating  */
  confirmationRequired?: Maybe<Scalars['Boolean']>;
  /**  The list of contacts  */
  contacts: Array<Contact>;
  /**  The date at which the event was created  */
  created: Scalars['DateTime'];
  /**  The user who created the event  */
  createdBy?: Maybe<Scalars['String']>;
  /**  The end time of the event  */
  end?: Maybe<Scalars['DateTime']>;
  fiveOneOneMessage: FiveOneOneMessage;
  /**  The kinetic form to use for this event  */
  form: KineticForm;
  /**  The geometry of the event  */
  geometry: Scalars['Object'];
  id: Scalars['ID'];
  /**  list of custom labels  */
  labels: Array<Scalars['String']>;
  /**  The lanes associated with this event  */
  lanes: Array<EventLane>;
  /**  The time at which the lanes were cleared  */
  lanesClear?: Maybe<Scalars['DateTime']>;
  /**  The time at which the event was last updated  */
  lastUpdated: Scalars['DateTime'];
  /**  The user who last updated the event  */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /**  The user's display name who last updated the event */
  lastUpdatedByDisplayName?: Maybe<Scalars['String']>;
  /**  The list of linked events for the plan  */
  links: Array<EventLink>;
  /**  The name of the event  */
  name: Scalars['String'];
  /**  internal notes  */
  notes: Array<EventNote>;
  /**  The time at which the scene was cleared  */
  sceneClear?: Maybe<Scalars['DateTime']>;
  /**  The start time of the event  */
  start?: Maybe<Scalars['DateTime']>;
  /**  The event status  */
  status: EventStatus;
  /**  The event type  */
  type: Scalars['String'];
};

export enum EventCategory {
  /**  Incident Category  */
  Incident = 'INCIDENT',
  /**  Planned Event Category  */
  PlannedEvent = 'PLANNED_EVENT',
  /**  Scheduled Event Category  */
  ScheduledEvent = 'SCHEDULED_EVENT',
  /**  Weather Category  */
  Weather = 'WEATHER'
}

export type EventConnection = {
  __typename?: 'EventConnection';
  /**  connection edges  */
  edges: Array<EventConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type EventConnectionEdge = {
  __typename?: 'EventConnectionEdge';
  /**  connection edge node  */
  cursor?: Maybe<Scalars['String']>;
  /**  node cursor  */
  node: Event;
};

export type EventInput = {
  /**  The activation type of the planned event  */
  activationType?: InputMaybe<ActivationType>;
  /**  The attributes of the event built from an event form  */
  attributes: Scalars['Object'];
  /**  The event category  */
  category: EventCategory;
  /**  The list of contacts  */
  contacts: Array<Scalars['String']>;
  /**  The kinetic form to use for this event  */
  formId: Scalars['String'];
  /**  The geometry of the event  */
  geometry: Scalars['Object'];
  /**  The identifier of the event  */
  id?: InputMaybe<Scalars['ID']>;
  /**  list of custom labels  */
  labels: Array<Scalars['String']>;
  /**  The lanes associated with this event  */
  lanes: Array<EventLaneInput>;
  /**  The name of the event  */
  name: Scalars['String'];
  /**  The event status  */
  status: EventStatus;
  /**  The event type  */
  type: Scalars['String'];
};

export type EventInputQuery = {
  /**  The activation type of the planned event  */
  activationType?: InputMaybe<ActivationType>;
  /** Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /** Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /**  Fetch by specific event category */
  category?: InputMaybe<EventCategory>;
  /**  End time filter  */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /** The fields and values to filter events */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /** Limit the number of results. We do not allow returning all docs. */
  limit?: InputMaybe<Scalars['Int']>;
  /** The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /** Text to search for. */
  searchText?: InputMaybe<Scalars['String']>;
  /** Which field to sort the results on. */
  sortField?: InputMaybe<Scalars['String']>;
  /** Which direction to sort the field on. */
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter  */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type EventLane = {
  __typename?: 'EventLane';
  /**  The time at which the lane was closed  */
  closedTime?: Maybe<Scalars['DateTime']>;
  /**  Direction of the the lane  */
  direction: Direction;
  /**  Position of lane relative to type  */
  engineeringLane: Scalars['Int'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  Status of the lane  */
  laneStatus: LaneStatus;
  /**  Type of lane  */
  laneType: LaneType;
  /**  line type  */
  lineType: Scalars['String'];
  /**  The time at which the lane was reopened  */
  openTime?: Maybe<Scalars['DateTime']>;
  /**  Indicates the roadway type for the lane  */
  roadwayType: RoadwayType;
};

export type EventLaneInput = {
  /**  The time at which the lane was closed  */
  closedTime?: InputMaybe<Scalars['DateTime']>;
  /**  Direction of the the lane  */
  direction: Direction;
  /**  Position of lane relative to type  */
  engineeringLane: Scalars['Int'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  Status of the lane  */
  laneStatus: LaneStatus;
  /**  Type of lane  */
  laneType: LaneType;
  /**  line type  */
  lineType: Scalars['String'];
  /**  The time at which the lane was reopened  */
  openTime?: InputMaybe<Scalars['DateTime']>;
  /**  Indicates the roadway type for the lane  */
  roadwayType: RoadwayType;
};

export type EventLink = {
  __typename?: 'EventLink';
  /**  Comment on the link  */
  comment?: Maybe<Scalars['String']>;
  /**  The identifier of the linked event  */
  id: Scalars['String'];
  /**  The name of the linked event  */
  name?: Maybe<Scalars['String']>;
  /**  The status of the linked event  */
  status?: Maybe<EventStatus>;
  /**  Type of link for the events  */
  type: EventLinkType;
};

export type EventLinkInput = {
  /**  Comment on the link  */
  comment?: InputMaybe<Scalars['String']>;
  /**  Identifier of the event to link  */
  id: Scalars['ID'];
  /**  The name of the linked Event */
  name?: InputMaybe<Scalars['String']>;
  /**  The status of the linked event  */
  status?: InputMaybe<EventStatus>;
  /**  Type of link for the events  */
  type: EventLinkType;
};

export enum EventLinkType {
  /**  does the event cause  */
  Causes = 'CAUSES',
  /**  is the event a duplicate  */
  Duplicates = 'DUPLICATES',
  /**  is the event caused  */
  IsCausedBy = 'IS_CAUSED_BY',
  /**  is the event a duplicate  */
  IsDuplicatedBy = 'IS_DUPLICATED_BY',
  /**  is the event related  */
  IsRelatedTo = 'IS_RELATED_TO'
}

export type EventNote = {
  __typename?: 'EventNote';
  /**  content of the note  */
  note: Scalars['String'];
  /**  timestamp of the note  */
  timestamp: Scalars['DateTime'];
  /**  user who recorded the note  */
  user: Scalars['String'];
};

export type EventNoteInput = {
  /**  content of the note  */
  note: Scalars['String'];
  /**  timestamp of the note  */
  timestamp: Scalars['DateTime'];
  /**  user who recorded the note  */
  user: Scalars['ID'];
};

export enum EventRuleType {
  /**  action sets  */
  ActionSet = 'ACTION_SET',
  /**  signs  */
  Sign = 'SIGN'
}

export enum EventStatus {
  /**  Event is currently active  */
  Active = 'ACTIVE',
  /**  Event has been closed  */
  Closed = 'CLOSED',
  /**  Planned Event created but not scheduled  */
  Draft = 'DRAFT',
  /**  Planned Event is no longer active and no more scheduled occurrences  */
  Expired = 'EXPIRED',
  /**  Event has been scheduled  */
  Scheduled = 'SCHEDULED',
  /**  Event is not yet verified, but has been entered  */
  Suspect = 'SUSPECT',
  /**  Event has been verified  */
  Verified = 'VERIFIED'
}

export type EventTemplate = {
  __typename?: 'EventTemplate';
  /**  The description of the template  */
  description?: Maybe<Scalars['String']>;
  /**  The identifier of the template  */
  id: Scalars['ID'];
  /**  The name of the template  */
  name: Scalars['String'];
  /**  The event to serve as the template  */
  template: Event;
};

export type EventTemplateConnection = {
  __typename?: 'EventTemplateConnection';
  /**  connection edges  */
  edges: Array<EventTemplateConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type EventTemplateConnectionEdge = {
  __typename?: 'EventTemplateConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: EventTemplate;
};

export type EventTemplateInput = {
  /**  The description of the template  */
  description?: InputMaybe<Scalars['String']>;
  /**  The identifier of the template  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The name of the template  */
  name: Scalars['String'];
  /**  The event to serve as the template  */
  template: EventInput;
};

export type EventTemplateInputQuery = {
  /** Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /** Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /** The fields and values to filter events */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /** Limit the number of results. We do not allow returning all docs. */
  limit?: InputMaybe<Scalars['Int']>;
  /** The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /** Text to search for. */
  searchText?: InputMaybe<Scalars['String']>;
  /** Which field to sort the results on. */
  sortField?: InputMaybe<Scalars['String']>;
  /** Which direction to sort the field on. */
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventType = {
  __typename?: 'EventType';
  /**  The event category for the type  */
  category: EventCategory;
  /**  The description of the event type  */
  description?: Maybe<Scalars['String']>;
  /**  The list of detection codes for this type  */
  detectionCodes: Array<Scalars['String']>;
  /**  The identifier of the event type  */
  id: Scalars['ID'];
  /**  The name of the event type  */
  name: Scalars['String'];
};

export type EventTypeConnection = {
  __typename?: 'EventTypeConnection';
  /**  connection edges  */
  edges: Array<EventTypeConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type EventTypeConnectionEdge = {
  __typename?: 'EventTypeConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: EventType;
};

export type EventTypeInput = {
  /**  The event category for the type  */
  category: EventCategory;
  /**  The description of the event type  */
  description?: InputMaybe<Scalars['String']>;
  /**  The list of detection codes for this type  */
  detectionCodes: Array<Scalars['String']>;
  /**  The identifier of the event type  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The name of the event type  */
  name: Scalars['String'];
};

export type EventTypeInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The category to filter on  */
  category?: InputMaybe<EventCategory>;
  /**  The fields and values to filter events  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type ExportColumn = {
  /**  Dot-delimited path in the exported object to extract the field value from.  */
  field: Scalars['String'];
  /**  Human-readable column title  */
  title: Scalars['String'];
  /**
   * Optional type hint when converting data for export.
   * If omitted, the exporter will make a best guess while converting.
   */
  type?: InputMaybe<ExportColumnType>;
};

/**  How to treat exportable data when exporting as a column */
export enum ExportColumnType {
  /**
   * Interpret the column data as a datetime value.
   * Supports values stored as either ISO8601 or Unix timestamps
   */
  Date = 'DATE',
  Text = 'TEXT'
}

export type ExportConfig = {
  /**  Columns to include in the export  */
  columns: Array<ExportColumn>;
  /**
   * Name to use for the exported file.
   * Exported files will always be stored in My Files / Downloads of managed files.
   */
  fileName: Scalars['String'];
  /**
   * Default timezone to use for date columns.
   * If omitted, UTC will be used.
   */
  timeZone?: InputMaybe<Scalars['String']>;
};

export type ExportReport = {
  logo?: InputMaybe<Scalars['String']>;
  metaData: Array<InputMaybe<Element>>;
  printDateTime?: InputMaybe<Scalars['String']>;
  reportTitle: Scalars['String'];
  tables: Array<ExportReportTable>;
};

export type ExportReportHeader = {
  name: Scalars['String'];
};

export type ExportReportRow = {
  rows: Array<Scalars['Object']>;
};

export type ExportReportTable = {
  headers: Array<ExportReportHeader>;
  headersOptional?: InputMaybe<Scalars['Boolean']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  rows: Array<ExportReportRow>;
  swapped?: InputMaybe<Scalars['Boolean']>;
  tableNameOptional?: InputMaybe<Scalars['Boolean']>;
};

export enum ExportReportType {
  Csv = 'CSV',
  Excel = 'EXCEL',
  Html = 'HTML',
  Pdf = 'PDF'
}

export type ExtractZipResults = {
  __typename?: 'ExtractZipResults';
  /**
   *  All directories created from the zip.
   *  First directory is always the root.
   */
  directories: Array<Directory>;
  /**  All files created from the zip */
  files: Array<ManagedFile>;
};

export type FieldConfig = {
  __typename?: 'FieldConfig';
  accept?: Maybe<Scalars['String']>;
  activeLogic?: Maybe<Scalars['JSON']>;
  choicesTitle?: Maybe<Scalars['String']>;
  chosenTitle?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['JSON']>;
  deselectAllLabel?: Maybe<Scalars['String']>;
  disableFuture?: Maybe<Scalars['Boolean']>;
  disablePast?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledLogic?: Maybe<Scalars['JSON']>;
  displayFormat?: Maybe<Scalars['String']>;
  fieldConfigs?: Maybe<Array<FieldConfig>>;
  fieldSize?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  help?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  limitTags?: Maybe<Scalars['Int']>;
  loading?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Float']>;
  mode?: Maybe<Scalars['String']>;
  moduleName?: Maybe<Scalars['String']>;
  multiline?: Maybe<Scalars['Boolean']>;
  multiple?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: Maybe<Array<Maybe<FieldConfigOption>>>;
  required?: Maybe<Scalars['Boolean']>;
  requiredLogic?: Maybe<Scalars['JSON']>;
  rows?: Maybe<Scalars['Int']>;
  selectAllLabel?: Maybe<Scalars['String']>;
  showInput?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  uniqueFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadType?: Maybe<Scalars['String']>;
  useCheckedIcon?: Maybe<Scalars['Boolean']>;
  useIcon?: Maybe<Scalars['Boolean']>;
  useStepper?: Maybe<Scalars['Boolean']>;
  valueLabelDisplay?: Maybe<Scalars['String']>;
};

export type FieldConfigInput = {
  accept?: InputMaybe<Scalars['String']>;
  activeLogic?: InputMaybe<Scalars['JSON']>;
  choicesTitle?: InputMaybe<Scalars['String']>;
  chosenTitle?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['JSON']>;
  deselectAllLabel?: InputMaybe<Scalars['String']>;
  disableFuture?: InputMaybe<Scalars['Boolean']>;
  disablePast?: InputMaybe<Scalars['Boolean']>;
  displayFormat?: InputMaybe<Scalars['String']>;
  fieldSize?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  help?: InputMaybe<Scalars['String']>;
  hint?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  limitTags?: InputMaybe<Scalars['Int']>;
  loading?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<Scalars['Float']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Float']>;
  mode?: InputMaybe<Scalars['String']>;
  moduleName?: InputMaybe<Scalars['String']>;
  multiline?: InputMaybe<Scalars['Boolean']>;
  multiple?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: InputMaybe<Array<InputMaybe<FieldConfigOptionInput>>>;
  required?: InputMaybe<Scalars['Boolean']>;
  requiredLogic?: InputMaybe<Scalars['JSON']>;
  rows?: InputMaybe<Scalars['Int']>;
  selectAllLabel?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sourceId?: InputMaybe<Scalars['String']>;
  step?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  uniqueFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uploadType?: InputMaybe<Scalars['String']>;
  useCheckedIcon?: InputMaybe<Scalars['Boolean']>;
  useIcon?: InputMaybe<Scalars['Boolean']>;
  useStepper?: InputMaybe<Scalars['Boolean']>;
};

export type FieldConfigOption = {
  __typename?: 'FieldConfigOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type FieldConfigOptionInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type File = {
  __typename?: 'File';
  additionalData?: Maybe<Scalars['Object']>;
  contentType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  module?: Maybe<KineticModule>;
  name: Scalars['String'];
  path: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FileEdge = {
  __typename?: 'FileEdge';
  cursor: Scalars['String'];
  node: File;
};

export type Filter = {
  __typename?: 'Filter';
  field: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['Object'];
};

export type FilterInput = {
  field: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['Object'];
};

export enum FilterOperator {
  /**  AND operator for combining filters. The filter input field is not used here and the value is an array of filters. */
  And = 'AND',
  /**  Element Matching operator for combining filters of complex objects. The filter input field is not used here and the value is an array of filters. */
  ElemMatch = 'ELEM_MATCH',
  Equals = 'EQUALS',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanEquals = 'LESS_THAN_EQUALS',
  /**  Element 'Non' Matching operator for combining filters of complex objects. The filter input field is not used here and the value is an array of filters. */
  NotElemMatch = 'NOT_ELEM_MATCH',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
  /**  OR operator for combining filters. The filter input field is not used here and the value is an array of filters. */
  Or = 'OR',
  Regex = 'REGEX'
}

export type FiveOneOneMessage = {
  __typename?: 'FiveOneOneMessage';
  extraText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reasonForSuppression?: Maybe<Scalars['String']>;
  suppressed?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

export enum FlashStatus {
  Auto = 'AUTO',
  Faultmonitor = 'FAULTMONITOR',
  Localmanual = 'LOCALMANUAL',
  Mmu = 'MMU',
  Off = 'OFF',
  Other = 'OTHER',
  Preempt = 'PREEMPT',
  Startup = 'STARTUP'
}

export type FlatIntersectionGroup = {
  __typename?: 'FlatIntersectionGroup';
  description?: Maybe<Scalars['String']>;
  externalCenterId?: Maybe<Scalars['String']>;
  group: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  readonly: Scalars['Boolean'];
};

export type ForbiddenWord = {
  __typename?: 'ForbiddenWord';
  /**  Identifier of the forbidden word  */
  id: Scalars['ID'];
  /** The forbidden word  */
  word: Scalars['String'];
};

export type ForbiddenWordInput = {
  /**  Identifier of the forbidden word  */
  id?: InputMaybe<Scalars['ID']>;
  /** The forbidden word  */
  word: Scalars['String'];
};

export type FormSection = {
  fields: Array<Maybe<FieldConfig>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FormView = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<FormSection>;
  type: FormViewType;
  views: Array<FormView>;
};

export enum FormViewType {
  Embedded = 'EMBEDDED',
  Route = 'ROUTE'
}

export enum GateActivityType {
  /**  Activated the associated sign messages during the gate operation  */
  ActivatedSignMessages = 'ACTIVATED_SIGN_MESSAGES',
  /**  Gate Controller Operation override authorization  */
  AuthorizeOverrideOperation = 'AUTHORIZE_OVERRIDE_OPERATION',
  /**  Gate's Configuration was changed  */
  ConfigurationChanged = 'CONFIGURATION_CHANGED',
  /**  Confirmed Area Clear for Gate Operation Step  */
  ConfirmedAreaClear = 'CONFIRMED_AREA_CLEAR',
  /**  Confirmed Gates in Correct Position  */
  ConfirmedGatePosition = 'CONFIRMED_GATE_POSITION',
  /**  Confirmed Operator On-site  */
  ConfirmedOperatorOnsite = 'CONFIRMED_OPERATOR_ONSITE',
  /**  Confirmed Signs have correct message posted  */
  ConfirmedSignMessage = 'CONFIRMED_SIGN_MESSAGE',
  /**  Continued the Gate Operation even though some Gate positions are currently unknown  */
  ContinuedOperationWithGatesUnknown = 'CONTINUED_OPERATION_WITH_GATES_UNKNOWN',
  /**  User attempted to start new gate operation while having another gate operation running, and choose to interrupt the currently running Gate Operation  */
  CurrentUserOperationInterruptRequested = 'CURRENT_USER_OPERATION_INTERRUPT_REQUESTED',
  /**  A Flow Conflict Restriction Violation has been detected  */
  FlowConflict = 'FLOW_CONFLICT',
  /**  A flow conflict violation was detected but was suppressed due to restriction bypass  */
  FlowConflictSuppressed = 'FLOW_CONFLICT_SUPPRESSED',
  /**  An operation command was issued by a user  */
  GateChangeCommandIssued = 'GATE_CHANGE_COMMAND_ISSUED',
  /**  The operator has bypassed a gate command step in the gate operation wizard  */
  GateCommandBypassed = 'GATE_COMMAND_BYPASSED',
  /**  Gate command was sent while there were operations in progress  */
  GateCommandSentWhileOperationsActive = 'GATE_COMMAND_SENT_WHILE_OPERATIONS_ACTIVE',
  /**  Command was sent to a controller while existing violations were bypassed  */
  GateCommandSentWithViolationsBypassed = 'GATE_COMMAND_SENT_WITH_VIOLATIONS_BYPASSED',
  /**  Gate Command Sent Successfully  */
  GateCommandSuccessful = 'GATE_COMMAND_SUCCESSFUL',
  /**  Gate is reporting one or more errors  */
  GateError = 'GATE_ERROR',
  /**  Gate Controller Operation approved by Supervisor  */
  GateOperationApprovedBySupervisor = 'GATE_OPERATION_APPROVED_BY_SUPERVISOR',
  /**  Gate Controller Operation rejected by Supervisor  */
  GateOperationRejectedBySupervisor = 'GATE_OPERATION_REJECTED_BY_SUPERVISOR',
  /**  User Interrupted Another Gate Operation to Start Their Own  */
  InterruptedOperation = 'INTERRUPTED_OPERATION',
  /**  Notes Added for a Gate Operation Step  */
  NotesAdded = 'NOTES_ADDED',
  /**  Notified the Tunnel Supervisor  */
  NotifiedTunnelSupervisor = 'NOTIFIED_TUNNEL_SUPERVISOR',
  /**  Gate Operation Aborted  */
  OperationAborted = 'OPERATION_ABORTED',
  /**  User authentication for Gate Operation failed  */
  OperationAuthenticationFailed = 'OPERATION_AUTHENTICATION_FAILED',
  /**  Gate Operation Successfully Completed  */
  OperationCompleted = 'OPERATION_COMPLETED',
  /**  Gate Operation Started  */
  OperationStarted = 'OPERATION_STARTED',
  /**  Gate's Position has changed *  */
  PositionChanged = 'POSITION_CHANGED',
  /**  Received call from HOV Operator  */
  ReceivedOperatorCall = 'RECEIVED_OPERATOR_CALL',
  /**  Gate's Status has received a notable update  */
  StatusChanged = 'STATUS_CHANGED',
  /**  Represents change in critical status of the GateController associated Signs  */
  ToggleCriticalSignsStatus = 'TOGGLE_CRITICAL_SIGNS_STATUS',
  /**  Represents change (enable/disable) in Gate Bypass Restrictions status  */
  ToggleGateBypassRestrictions = 'TOGGLE_GATE_BYPASS_RESTRICTIONS',
  /**  Represents change (enable/disable) in Gate Enable Safe Reversal Time status  */
  ToggleGateEnableSafeReversalTime = 'TOGGLE_GATE_ENABLE_SAFE_REVERSAL_TIME',
  /**  An unauthorized access has been detected  */
  UnauthorizedAccess = 'UNAUTHORIZED_ACCESS',
  /**  User ignored unexpected gate message.  */
  UnexpectedMessageIgnored = 'UNEXPECTED_MESSAGE_IGNORED',
  /**  User resolved unexpected gate message.  */
  UnexpectedMessageResolved = 'UNEXPECTED_MESSAGE_RESOLVED'
}

export enum GateApprovalType {
  /**  Approve Gate Control System Operation.  */
  GateApproveOperation = 'GATE_APPROVE_OPERATION',
  /**  Enable / Disable Gate Bypass Restrictions.  */
  GateBypassRestrictions = 'GATE_BYPASS_RESTRICTIONS',
  /**  Gate close operation.  */
  GateClose = 'GATE_CLOSE',
  /**  Manage Gate Control System Operation.  */
  GateIndividualOperation = 'GATE_INDIVIDUAL_OPERATION',
  /**  Manage Gate Control System Operation.  */
  GateManage = 'GATE_MANAGE',
  /**  Gate open inbound operation.  */
  GateOpenInbound = 'GATE_OPEN_INBOUND',
  /**  Gate open outbound operation.  */
  GateOpenOutbound = 'GATE_OPEN_OUTBOUND',
  /**  Gate override gate controller operation.  */
  GateOverrideGcOperation = 'GATE_OVERRIDE_GC_OPERATION',
  /**  Reject Gate Control System Operation.  */
  GateRejectOperation = 'GATE_REJECT_OPERATION',
  /**  Enable / Disable Safe Reversal Time.  */
  SafeReversalTime = 'SAFE_REVERSAL_TIME'
}

export type GateArmDatabaseState = {
  __typename?: 'GateArmDatabaseState';
  /**  arm health  */
  health: GateArmHealth;
  /**  native id  */
  nativeId: Scalars['Int'];
  /**  arm position  */
  position: GateArmPosition;
};

export enum GateArmHealth {
  /**  failed  */
  Failed = 'FAILED',
  /**  ok  */
  Ok = 'OK',
  /**  unknown  */
  Unknown = 'UNKNOWN'
}

export enum GateArmPosition {
  /**  closed  */
  Closed = 'CLOSED',
  /**  open  */
  Open = 'OPEN',
  /**  transitioning  */
  Transitioning = 'TRANSITIONING',
  /**  unknown  */
  Unknown = 'UNKNOWN'
}

export enum GateControlMode {
  /**  local  */
  Local = 'LOCAL',
  /**  manual  */
  Manual = 'MANUAL',
  /**  remote  */
  Remote = 'REMOTE',
  /**  schedule  */
  Schedule = 'SCHEDULE'
}

export type GateController = {
  __typename?: 'GateController';
  /**  if restrictions are bypassed  */
  bypassingRestrictions?: Maybe<Scalars['Boolean']>;
  /**  communication status  */
  communicationStatus: CommunicationState;
  /**  description  */
  description?: Maybe<Scalars['String']>;
  /**  direction  */
  direction: Direction;
  /**  entrance direction  */
  entranceDirection: GateControllerDirection;
  /**  the list of gate arms  */
  gateArms: Array<GateControllerArm>;
  /**  the list of cameras  */
  gateCameras: Array<Scalars['ID']>;
  /**  the list of signs  */
  gateSigns: Array<Scalars['ID']>;
  /**  identifier  */
  id: Scalars['ID'];
  /**  interlock status  */
  interlock?: Maybe<Scalars['Boolean']>;
  /**  last data received  */
  lastDataReceived: Scalars['DateTime'];
  /**  location  */
  location: Scalars['Object'];
  /**  control mode  */
  mode: GateControlMode;
  /**  name  */
  name: Scalars['String'];
  /**  operation status  */
  operationStatus: GateOperationStatus;
  /**  reference point  */
  referencePoint: Scalars['Float'];
  /**  if remote operations are disabled  */
  remoteOperationDisabled?: Maybe<Scalars['Boolean']>;
  /**  the list of restrictions  */
  restrictions: Array<GateControllerRestriction>;
  /**  road  */
  road: Scalars['String'];
  /**  if safe reversal time is disabled  */
  safeReversalTimeDisabled?: Maybe<Scalars['Boolean']>;
  /**  current status  */
  status: GateControllerStatus;
  /**  supported errors  */
  supportedErrors: Array<GateControllerError>;
  /**  system enabled  */
  systemEnabled: Scalars['Boolean'];
  /**  if user auth is required  */
  userAuthenticationRequired?: Maybe<Scalars['Boolean']>;
};

export type GateControllerArm = {
  __typename?: 'GateControllerArm';
  /**  direction  */
  direction: GateDirection;
  /**  arm health  */
  health: GateArmHealth;
  /**  location  */
  location: Scalars['String'];
  /**  name  */
  name: Scalars['String'];
  /**  native id  */
  nativeId: Scalars['Int'];
  /**  arm position  */
  position: GateArmPosition;
};

export type GateControllerConnection = {
  __typename?: 'GateControllerConnection';
  /**  Connection Edges  */
  edges: Array<GateControllerEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export enum GateControllerDirection {
  /**  inbound  */
  Inbound = 'INBOUND',
  /**  none  */
  None = 'NONE',
  /**  outbound  */
  Outbound = 'OUTBOUND'
}

export type GateControllerEdge = {
  __typename?: 'GateControllerEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: GateController;
};

export enum GateControllerError {
  /**  Communication data error (in last received message)  */
  Communication = 'COMMUNICATION',
  /**  Configuration failure  */
  Configuration = 'CONFIGURATION',
  /**  Cabinet door is open  */
  DoorOpen = 'DOOR_OPEN',
  /**  EPROM checksum error  */
  Eprom = 'EPROM',
  /**  Gate failure  */
  GateFailure = 'GATE_FAILURE',
  /**  Illegal command in last message  */
  IllegalCommand = 'ILLEGAL_COMMAND',
  /**  Interlock disabled  */
  InterlockDisabled = 'INTERLOCK_DISABLED',
  /**  Power-failure recovery  */
  Power = 'POWER',
  /**  RAM failure or controller data base corrupted  */
  Ram = 'RAM',
  /**  Sign failure  */
  Sign = 'SIGN',
  /**  Signal failure  */
  Signal = 'SIGNAL'
}

export type GateControllerInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type GateControllerRestriction = {
  __typename?: 'GateControllerRestriction';
  /**  restricting action  */
  restrictedStatus: GateControllerStatus;
  /**  restricting controller  */
  restrictingControllerId: Scalars['ID'];
  /**  violating action  */
  violationStatus: GateControllerStatus;
};

export type GateControllerSignMessage = {
  __typename?: 'GateControllerSignMessage';
  /**  controller  */
  controller: Scalars['ID'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  message  */
  message: SignMessage;
  /**  sign  */
  sign: Scalars['ID'];
  /**  type  */
  type: Scalars['ID'];
};

export type GateControllerSignMessageConnection = {
  __typename?: 'GateControllerSignMessageConnection';
  /**  Connection Edges  */
  edges: Array<GateControllerSignMessageEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type GateControllerSignMessageEdge = {
  __typename?: 'GateControllerSignMessageEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: GateControllerSignMessage;
};

export type GateControllerSignMessageInput = {
  /**  controller  */
  controller: Scalars['ID'];
  /**  identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  message  */
  message: SignMessageInput;
  /**  sign  */
  sign: Scalars['ID'];
  /**  type  */
  type: Scalars['ID'];
};

export type GateControllerSignMessageInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum GateControllerStatus {
  /**  closed  */
  Closed = 'CLOSED',
  /**  mixed  */
  Mixed = 'MIXED',
  /**  open inbound  */
  OpenInbound = 'OPEN_INBOUND',
  /**  open outbound  */
  OpenOutbound = 'OPEN_OUTBOUND',
  /**  unknown  */
  Unknown = 'UNKNOWN'
}

export type GateControllerSystem = {
  __typename?: 'GateControllerSystem';
  /**  description  */
  description?: Maybe<Scalars['String']>;
  /**  identifier of the system  */
  id: Scalars['ID'];
  /**  list of inbound gate controllers  */
  inboundControllers: Array<GateController>;
  /**  the inbound direction  */
  inboundDirection: Direction;
  /**  managing user  */
  managingUser?: Maybe<Scalars['ID']>;
  /**  minimum reversal time in seconds  */
  minReversalTimeSecs?: Maybe<Scalars['Int']>;
  /**  name of the system  */
  name: Scalars['String'];
  /**  list of gate operations  */
  operations: Array<GateOperation>;
  /**  list of outbound gate controllers  */
  outboundControllers: Array<GateController>;
  /**  the outbound direction  */
  outboundDirection: Direction;
  /**  schematic details  */
  schematic: GateControllerSystemSchematic;
};

export type GateControllerSystemConnection = {
  __typename?: 'GateControllerSystemConnection';
  /**  Connection Edges  */
  edges: Array<GateControllerSystemEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type GateControllerSystemEdge = {
  __typename?: 'GateControllerSystemEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: GateControllerSystem;
};

export type GateControllerSystemInput = {
  /**  description  */
  description?: InputMaybe<Scalars['String']>;
  /**  identifier of the system  */
  id?: InputMaybe<Scalars['ID']>;
  /**  list of inbound gate controllers  */
  inboundControllers: Array<Scalars['ID']>;
  /**  the inbound direction  */
  inboundDirection: Direction;
  /**  minimum reversal time in seconds  */
  minReversalTimeSecs?: InputMaybe<Scalars['Int']>;
  /**  name of the system  */
  name: Scalars['String'];
  /**  list of gate operations  */
  operations: Array<GateOperationInput>;
  /**  list of outbound gate controllers  */
  outboundControllers: Array<Scalars['ID']>;
  /**  the outbound direction  */
  outboundDirection: Direction;
  /**  schematic details  */
  schematic: GateControllerSystemSchematicInput;
};

export type GateControllerSystemInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type GateControllerSystemSchematic = {
  __typename?: 'GateControllerSystemSchematic';
  /**  horizontal offsets  */
  horizontalOffsets?: Maybe<Scalars['Object']>;
  /**  schematic image  */
  image?: Maybe<Scalars['Object']>;
  /**  rotation values  */
  rotations?: Maybe<Scalars['Object']>;
  /**  horizontal offsets  */
  verticalOffsets?: Maybe<Scalars['Object']>;
};

export type GateControllerSystemSchematicInput = {
  /**  horizontal offsets  */
  horizontalOffsets?: InputMaybe<Scalars['Object']>;
  /**  schematic image  */
  image?: InputMaybe<Scalars['Object']>;
  /**  rotation values  */
  rotations?: InputMaybe<Scalars['Object']>;
  /**  horizontal offsets  */
  verticalOffsets?: InputMaybe<Scalars['Object']>;
};

export type GateDatabaseState = {
  __typename?: 'GateDatabaseState';
  /**  gate arms  */
  gateArms: Array<GateArmDatabaseState>;
  /**  gate controller  */
  id: Scalars['ID'];
};

export enum GateDirection {
  /**  both  */
  Both = 'BOTH',
  /**  inbound  */
  Inbound = 'INBOUND',
  /**  outbound  */
  Outbound = 'OUTBOUND'
}

export type GateOperation = {
  __typename?: 'GateOperation';
  /**  identifier  */
  id: Scalars['ID'];
  /**  name of the operation  */
  name: Scalars['String'];
  /**  operation steps  */
  steps: Array<GateOperationStep>;
};

export type GateOperationActivityInput = {
  /**  additional details  */
  additionalDetails?: InputMaybe<Scalars['String']>;
  /**  operation id  */
  operationId: Scalars['ID'];
  /**  operation step  */
  step: GateOperationStepInput;
  /**  activity type  */
  type: GateActivityType;
};

export type GateOperationApproval = {
  __typename?: 'GateOperationApproval';
  /**  if the approval is approved  */
  approved: Scalars['Boolean'];
  /**  the approving user  */
  approver?: Maybe<Scalars['ID']>;
  /**  time at which the approval was created  */
  created: Scalars['DateTime'];
  /**  the identifier  */
  id: Scalars['ID'];
  /**  the operation id  */
  operation: Scalars['ID'];
  /**  if the approval was rejected  */
  rejected: Scalars['Boolean'];
  /**  type of gate approval  */
  type: GateApprovalType;
  /**  the requesting user  */
  user: Scalars['ID'];
  /**  display name of requesting user  */
  userDisplayName: Scalars['String'];
};

export type GateOperationApprovalConnection = {
  __typename?: 'GateOperationApprovalConnection';
  /**  Connection Edges  */
  edges: Array<GateOperationApprovalEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type GateOperationApprovalEdge = {
  __typename?: 'GateOperationApprovalEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: GateOperationApproval;
};

export type GateOperationApprovalInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type GateOperationInput = {
  /**  identifier  */
  id: Scalars['ID'];
  /**  name of the operation  */
  name: Scalars['String'];
  /**  operation steps  */
  steps: Array<GateOperationStepInput>;
};

export enum GateOperationStatus {
  /**  None of the restricting controllers were in a state that should block this gate  */
  Allowed = 'ALLOWED',
  /**  A restricting controller is in a state that should block this controller  */
  Blocked = 'BLOCKED',
  /**  Only safe gate operations allowed: CLOSE, OPEN EXIT  */
  SafeOnly = 'SAFE_ONLY'
}

export type GateOperationStep = {
  __typename?: 'GateOperationStep';
  /**  the controller to act upon  */
  controller: Scalars['ID'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  the type of sign message  */
  messageType: Scalars['ID'];
  /**  the type of operation  */
  operationType: GateOperationType;
};

export type GateOperationStepInput = {
  /**  the controller to act upon  */
  controller: Scalars['ID'];
  /**  identifier  */
  id: Scalars['ID'];
  /**  the type of sign message  */
  messageType: Scalars['ID'];
  /**  the type of operation  */
  operationType: GateOperationType;
};

export enum GateOperationType {
  /**  close all gates  */
  Close = 'CLOSE',
  /**  open inbound gates  */
  OpenInbound = 'OPEN_INBOUND',
  /**  open outbound gates  */
  OpenOutbound = 'OPEN_OUTBOUND'
}

export type GenericInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type GlobalFileConnection = {
  __typename?: 'GlobalFileConnection';
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GraphData = {
  __typename?: 'GraphData';
  /**  Checkpoints In X Axis  */
  checkPoints: Array<Maybe<CheckPoint>>;
  /**  Chart Data  */
  data: Array<ChartData>;
  /**  Overlay Info  */
  overlay: Array<OverlayInfo>;
  /**  Name of the graph  */
  title: Scalars['String'];
  /**  xAxis info  */
  xAxis: AxisInfo;
  /**  yAxis info  */
  yAxis: Array<AxisInfo>;
};

export enum GraphicLocation {
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  MiddleLeft = 'MIDDLE_LEFT',
  MiddleRight = 'MIDDLE_RIGHT',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT'
}

export type GroupItem = {
  __typename?: 'GroupItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: VideoItemType;
};

export type GroupItemInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: VideoItemType;
};

/**  Grouped Report Table  */
export type GroupedReportTable = {
  __typename?: 'GroupedReportTable';
  /**  Name of group for related tables  */
  groupName: Scalars['String'];
  groupedTables: Array<Table>;
};

/**  Hi Trac Specific data models */
export type HiTrac = {
  __typename?: 'HiTrac';
  firmwareVersions?: Maybe<Array<HiTracFirmwareRecord>>;
  lastBattery?: Maybe<Scalars['Float']>;
  lastConnect?: Maybe<Scalars['DateTime']>;
  lastResetReason?: Maybe<Scalars['String']>;
  lastResetTime?: Maybe<Scalars['DateTime']>;
};

export type HiTracFirmwareRecord = {
  __typename?: 'HiTracFirmwareRecord';
  processor?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export type HiTracLogRecord = {
  count?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['Int']>;
  parameter1?: InputMaybe<Scalars['Int']>;
  parameter2?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export enum HiTracVehicleDirection {
  Forward = 'FORWARD',
  Reverse = 'REVERSE'
}

export type HiTracVehicleInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  equipmentNumber?: InputMaybe<Scalars['Float']>;
  hexDump?: InputMaybe<Scalars['String']>;
  missingRecordsCount?: InputMaybe<Scalars['Int']>;
  numberOfBytes?: InputMaybe<Scalars['Float']>;
  packetLossStartTime?: InputMaybe<Scalars['DateTime']>;
  packetsLost?: InputMaybe<Scalars['Boolean']>;
  records?: InputMaybe<Array<HiTracVehicleRecordInput>>;
  serialNumber?: InputMaybe<Scalars['Int']>;
  siteNumber?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  temperature?: InputMaybe<Scalars['Int']>;
};

export type HiTracVehicleRecordInput = {
  axleSpacings?: InputMaybe<Array<Scalars['Int']>>;
  axleWeights?: InputMaybe<Array<Scalars['Int']>>;
  axlesCount?: InputMaybe<Scalars['Int']>;
  chassisCode?: InputMaybe<Scalars['Int']>;
  classIndex?: InputMaybe<Scalars['Int']>;
  direction?: InputMaybe<HiTracVehicleDirection>;
  failReason?: InputMaybe<Scalars['String']>;
  failed?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<Scalars['Int']>;
  headway?: InputMaybe<Scalars['Int']>;
  lane?: InputMaybe<Scalars['Int']>;
  laneDirection?: InputMaybe<DetectorDirection>;
  lastAxleSpeed?: InputMaybe<Scalars['Int']>;
  legalStatus?: InputMaybe<Scalars['Int']>;
  length?: InputMaybe<Scalars['Int']>;
  loopFailure?: InputMaybe<Scalars['Boolean']>;
  loopOnTime?: InputMaybe<Scalars['Int']>;
  maxChassisList?: InputMaybe<Array<Scalars['Int']>>;
  minChassisList?: InputMaybe<Array<Scalars['Int']>>;
  overWeight?: InputMaybe<Scalars['Boolean']>;
  sensorWeights?: InputMaybe<Array<Array<Scalars['Int']>>>;
  sensorWheelWeights?: InputMaybe<Array<Scalars['Int']>>;
  serialNumber?: InputMaybe<Scalars['Int']>;
  speed?: InputMaybe<Scalars['Float']>;
  straddle?: InputMaybe<Scalars['Boolean']>;
  straddleLane?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['DateTime']>;
  typeAPositions?: InputMaybe<Array<Scalars['Int']>>;
  typeBPositions?: InputMaybe<Array<Scalars['Int']>>;
  tyreA1Parameter?: InputMaybe<Scalars['Int']>;
  tyreA2Parameter?: InputMaybe<Scalars['Int']>;
  tyreA3Parameter?: InputMaybe<Scalars['Int']>;
  tyreA4Parameter?: InputMaybe<Scalars['Int']>;
  tyreB1Parameter?: InputMaybe<Scalars['Int']>;
  tyreB2Parameter?: InputMaybe<Scalars['Int']>;
  tyreB3Parameter?: InputMaybe<Scalars['Int']>;
  tyreB4Parameter?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Array<HiTracVehicleValidity>>;
};

export enum HiTracVehicleValidity {
  BadGap = 'BAD_GAP',
  HighSpeed = 'HIGH_SPEED',
  LoopFailure = 'LOOP_FAILURE',
  LowSpeed = 'LOW_SPEED',
  None = 'NONE',
  ReverseDirection = 'REVERSE_DIRECTION',
  SpeedChange = 'SPEED_CHANGE',
  StraddleVehicle = 'STRADDLE_VEHICLE',
  Unclassified = 'UNCLASSIFIED'
}

export type HistoricalEntity = {
  __typename?: 'HistoricalEntity';
  entity: Scalars['JSON'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: HistoricalEntityType;
};

export type HistoricalEntityConnection = {
  __typename?: 'HistoricalEntityConnection';
  edges: Array<HistoricalEntityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type HistoricalEntityEdge = {
  __typename?: 'HistoricalEntityEdge';
  cursor?: Maybe<Scalars['String']>;
  node: HistoricalEntity;
};

export enum HistoricalEntityType {
  Corridor = 'CORRIDOR',
  Event = 'EVENT',
  Integration = 'INTEGRATION'
}

export type HomePresetInput = {
  isHomePreset: Scalars['Boolean'];
  presetId: Scalars['String'];
};

export enum HorizontalJustification {
  Center = 'CENTER',
  Default = 'DEFAULT',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type HoursOfOperation = {
  __typename?: 'HoursOfOperation';
  daysOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type HoursOfOperationInput = {
  daysOfWeek?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type IconAnchor = {
  __typename?: 'IconAnchor';
  bearing?: Maybe<Scalars['Float']>;
  coordinates?: Maybe<Array<Scalars['Float']>>;
};

export type Integration = {
  __typename?: 'Integration';
  config: IntegrationConfig;
  description?: Maybe<Scalars['String']>;
  groups: Array<IntegrationGroup>;
  id: Scalars['ID'];
  name: Scalars['String'];
  state: IntegrationState;
  systemEnabled: Scalars['Boolean'];
};

export type IntegrationConfig = {
  __typename?: 'IntegrationConfig';
  form: IntegrationsForm;
  integrationType: IntegrationType;
  location?: Maybe<Scalars['Object']>;
  locationSupported?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['Object']>;
};

export type IntegrationConfigFormSection = FormSection & {
  __typename?: 'IntegrationConfigFormSection';
  fields: Array<Maybe<FieldConfig>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IntegrationConfigFormView = FormView & {
  __typename?: 'IntegrationConfigFormView';
  id: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<IntegrationConfigFormSection>;
  type: FormViewType;
  views: Array<IntegrationConfigFormView>;
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  edges: Array<IntegrationConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IntegrationConnectionEdge = {
  __typename?: 'IntegrationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Integration;
};

export type IntegrationGroup = {
  __typename?: 'IntegrationGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrations: Array<Integration>;
  name: Scalars['String'];
};

export type IntegrationGroupConnection = {
  __typename?: 'IntegrationGroupConnection';
  edges: Array<IntegrationGroupConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IntegrationGroupConnectionEdge = {
  __typename?: 'IntegrationGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: IntegrationGroup;
};

export type IntegrationGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  integrations?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type IntegrationJob = {
  __typename?: 'IntegrationJob';
  /**  description of the job */
  description?: Maybe<Scalars['String']>;
  /**  id of the integration */
  id: Scalars['String'];
  /**  the request id */
  requestId?: Maybe<Scalars['String']>;
};

export type IntegrationState = {
  __typename?: 'IntegrationState';
  /**  Current state data. */
  current: Scalars['Object'];
  /**  state data split into sections for viewing in the ui. */
  views: Array<Maybe<IntegrationStateView>>;
};

export type IntegrationStateSection = FormSection & {
  __typename?: 'IntegrationStateSection';
  fields: Array<Maybe<FieldConfig>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IntegrationStateView = FormView & {
  __typename?: 'IntegrationStateView';
  id: Scalars['ID'];
  /**  The name of the section */
  name: Scalars['String'];
  sections: Array<IntegrationStateSection>;
  type: FormViewType;
  views: Array<IntegrationStateView>;
};

export enum IntegrationType {
  /**  Alert field entity type  */
  Alert = 'ALERT',
  /**  AVL field entity type  */
  Avl = 'AVL',
  /**  Center To Center */
  C2C = 'C2C',
  /**  CAD field entity type  */
  Cad = 'CAD',
  /**  Callbox field entity type  */
  Callbox = 'CALLBOX',
  /**  Camera field entity type  */
  Camera = 'CAMERA',
  /**  Detector field entity type  */
  Counts = 'COUNTS',
  /**  Fog Light field entity type  */
  FogLight = 'FOG_LIGHT',
  /**  Gate Controller field entity type  */
  GateController = 'GATE_CONTROLLER',
  /**  HAR Beach field entity type  */
  HarBeacon = 'HAR_BEACON',
  /**  HAR Transmitter field entity type  */
  HarTransmitter = 'HAR_TRANSMITTER',
  /**  Modem entity type  */
  Modem = 'MODEM',
  /**  Network Video Recorder entity type  */
  NetworkVideoRecorder = 'NETWORK_VIDEO_RECORDER',
  /**  Devices for monitoring/administering parking areas  */
  ParkingArea = 'PARKING_AREA',
  /**  Response field entity type  */
  Response = 'RESPONSE',
  /**  Sign field entity type  */
  Sign = 'SIGN',
  /**  Signal Field entity type */
  Signal = 'SIGNAL',
  /**  Travel Time Segment field entity type  */
  TravelTimeSegment = 'TRAVEL_TIME_SEGMENT',
  /**  Video Analyzers field entity type  */
  VideoAnalyzers = 'VIDEO_ANALYZERS',
  /**  Video Stream Manager entity type  */
  VideoStreamManager = 'VIDEO_STREAM_MANAGER',
  /**  Video Switcher field entity type  */
  VideoSwitcher = 'VIDEO_SWITCHER',
  /**  Weather Station field entity type  */
  WeatherStation = 'WEATHER_STATION'
}

export type IntegrationsForm = {
  __typename?: 'IntegrationsForm';
  description?: Maybe<Scalars['String']>;
  extraConfig: Scalars['Object'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  locationSupported?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  views: Array<Maybe<IntegrationConfigFormView>>;
};

export type IntegrationsFormConnection = {
  __typename?: 'IntegrationsFormConnection';
  edges: Array<IntegrationsFormConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IntegrationsFormConnectionEdge = {
  __typename?: 'IntegrationsFormConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: IntegrationsForm;
};

export enum IntegrationsFormSortField {
  Description = 'DESCRIPTION',
  Id = 'ID',
  IntegrationType = 'INTEGRATION_TYPE',
  Name = 'NAME'
}

export type IntegrationsInput = {
  description?: InputMaybe<Scalars['String']>;
  formId: Scalars['ID'];
  groups?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  integrationType: IntegrationType;
  location?: InputMaybe<Scalars['Object']>;
  name: Scalars['String'];
  settings: Scalars['Object'];
  systemEnabled: Scalars['Boolean'];
};

export type Intersection = {
  __typename?: 'Intersection';
  /**  Alarm configurations associated with an intersection  */
  alarms: Array<AlarmConfig>;
  centerInfo?: Maybe<CenterInfo>;
  crosswalks: Array<IntersectionCrosswalk>;
  details: IntersectionDetails;
  devices: Array<IntersectionDevice>;
  groupId?: Maybe<Scalars['String']>;
  icons: Array<IntersectionIcon>;
  /**  The identifier of the intersection  */
  id: Scalars['ID'];
  lanes: Array<IntersectionLane>;
  location: Scalars['Object'];
  maneuvers: Array<IntersectionManeuver>;
  multiModals: Array<IntersectionMultiModal>;
  statusCategory: IntersectionStatusCategory;
};

export type IntersectionComment = {
  __typename?: 'IntersectionComment';
  comment: Scalars['String'];
  id: Scalars['ID'];
  intersectionId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  user: OrganizationKineticUser;
};

export type IntersectionCommunicationStatus = {
  __typename?: 'IntersectionCommunicationStatus';
  category: IntersectionStatusCategory;
  communicationState: CommunicationState;
  intersectionId: Scalars['ID'];
  mainlineColor: SignalOutputColor;
};

export type IntersectionConnection = {
  __typename?: 'IntersectionConnection';
  edges: Array<IntersectionConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IntersectionConnectionEdge = {
  __typename?: 'IntersectionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Intersection;
};

export type IntersectionCrosswalk = {
  __typename?: 'IntersectionCrosswalk';
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  icon?: Maybe<Array<IntersectionIcon>>;
  location: Scalars['Object'];
  signalIndication: SignalIndication;
};

export type IntersectionCrosswalkInput = {
  description?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  icon?: InputMaybe<Array<IntersectionIconInput>>;
  location: Scalars['Object'];
  signalIndication: SignalIndicationInput;
};

export type IntersectionDetailStatus = {
  __typename?: 'IntersectionDetailStatus';
  activeAlarms: Array<Scalars['String']>;
  category: IntersectionStatusCategory;
  crosshairs: Array<CrosshairStatus>;
  customIndexedMibs: Array<MibIcon>;
  dynamicIndexedMibs: Array<MibIndexed>;
  firmwareVersion?: Maybe<Scalars['String']>;
  intersectionId: Scalars['ID'];
  isPreemptActive: Scalars['Boolean'];
  preemptStatus: Array<PreemptStatus>;
  specialFunctionStatus: Array<SpecialFunctionStatus>;
  time: IntersectionDeviceTime;
  unitFlashStatus: FlashStatus;
};

export type IntersectionDetails = {
  __typename?: 'IntersectionDetails';
  labels: Array<Scalars['String']>;
  mainPhases: Array<Scalars['Int']>;
  mainStreet?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  sideStreet?: Maybe<Scalars['String']>;
};

export type IntersectionDetailsInput = {
  labels: Array<Scalars['String']>;
  mainPhases: Array<Scalars['Int']>;
  mainStreet?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  sideStreet?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type IntersectionDevice = {
  __typename?: 'IntersectionDevice';
  connectionInfo?: Maybe<Scalars['String']>;
  fieldEntityProtocol?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['Object']>;
  name: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  type: IntersectionDeviceType;
};

export type IntersectionDeviceInput = {
  connectionInfo?: InputMaybe<Scalars['String']>;
  fieldEntityProtocol?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: InputMaybe<Scalars['Object']>;
  name: Scalars['String'];
  type: IntersectionDeviceType;
};

export type IntersectionDeviceTime = {
  __typename?: 'IntersectionDeviceTime';
  drift?: Maybe<Scalars['Float']>;
  globalTime?: Maybe<Scalars['Float']>;
};

export enum IntersectionDeviceType {
  Signal = 'SIGNAL',
  Stop = 'STOP',
  Yield = 'YIELD'
}

export type IntersectionDeviceUi = {
  __typename?: 'IntersectionDeviceUI';
  version: Scalars['String'];
  views?: Maybe<Array<IntersectionDeviceUiView>>;
};

export type IntersectionDeviceUiColumn = {
  __typename?: 'IntersectionDeviceUIColumn';
  description?: Maybe<Scalars['String']>;
  displayAsHeader?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  mib: IntersectionDeviceUiMib;
  mibName: Scalars['String'];
  name: Scalars['String'];
  readonly: Scalars['Boolean'];
  type: IntersectionDeviceUiColumnType;
};

export type IntersectionDeviceUiColumnBit = {
  __typename?: 'IntersectionDeviceUIColumnBit';
  appendIndex?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  multiply?: Maybe<Scalars['Int']>;
  plus?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
};

export type IntersectionDeviceUiColumnEnumeration = {
  __typename?: 'IntersectionDeviceUIColumnEnumeration';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IntersectionDeviceUiColumnType = {
  __typename?: 'IntersectionDeviceUIColumnType';
  bits?: Maybe<Array<IntersectionDeviceUiColumnBit>>;
  decimalPos?: Maybe<Scalars['Int']>;
  displayAsHeader?: Maybe<Scalars['Boolean']>;
  enum?: Maybe<Array<IntersectionDeviceUiColumnEnumeration>>;
  isHexadecimal?: Maybe<Scalars['Boolean']>;
  isMultiLines?: Maybe<Scalars['Boolean']>;
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  onlyFirstRowDescription?: Maybe<Scalars['Boolean']>;
  swapRowsColumns?: Maybe<Scalars['Boolean']>;
  type: IntersectionDeviceUiColumnTypeEnum;
};

export enum IntersectionDeviceUiColumnTypeEnum {
  BitField = 'BIT_FIELD',
  DateTime = 'DATE_TIME',
  Decimal = 'DECIMAL',
  Enumeration = 'ENUMERATION',
  NumSequence = 'NUM_SEQUENCE',
  String = 'STRING'
}

export type IntersectionDeviceUiIndex = {
  __typename?: 'IntersectionDeviceUIIndex';
  displayed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  rows?: Maybe<Scalars['Int']>;
};

export type IntersectionDeviceUiMib = {
  __typename?: 'IntersectionDeviceUIMib';
  data?: Maybe<Array<IntersectionDeviceUiMibData>>;
  displayName?: Maybe<Scalars['String']>;
  mibIndex?: Maybe<Array<IntersectionDeviceUiMibIndex>>;
  name: Scalars['String'];
  tableDisplayName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
};

export type IntersectionDeviceUiMibBitField = {
  __typename?: 'IntersectionDeviceUIMibBitField';
  description?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  prevValue?: Maybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};

export type IntersectionDeviceUiMibData = {
  __typename?: 'IntersectionDeviceUIMibData';
  bitField?: Maybe<Array<IntersectionDeviceUiMibBitField>>;
  counter?: Maybe<Scalars['Int']>;
  index: Scalars['Int'];
  mibIndex?: Maybe<Array<IntersectionDeviceUiMibIndex>>;
  prevValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IntersectionDeviceUiMibIndex = {
  __typename?: 'IntersectionDeviceUIMibIndex';
  data?: Maybe<Array<IntersectionDeviceUiMibData>>;
  name: Scalars['String'];
};

export type IntersectionDeviceUiTable = {
  __typename?: 'IntersectionDeviceUITable';
  col?: Maybe<Scalars['Int']>;
  columns?: Maybe<Array<IntersectionDeviceUiColumn>>;
  displayName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  indexes?: Maybe<Array<IntersectionDeviceUiIndex>>;
  name?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  swap?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type IntersectionDeviceUiView = {
  __typename?: 'IntersectionDeviceUIView';
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<IntersectionDeviceUiTable>>;
  views?: Maybe<Array<IntersectionDeviceUiView>>;
};

export type IntersectionGroup = {
  __typename?: 'IntersectionGroup';
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<BasicIntersectionDevice>>;
  externalCenterId?: Maybe<Scalars['String']>;
  group: Scalars['Boolean'];
  groups: Array<IntersectionGroup>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  readonly: Scalars['Boolean'];
};

export type IntersectionGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  group: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parentId?: InputMaybe<Scalars['String']>;
};

export type IntersectionIcon = {
  __typename?: 'IntersectionIcon';
  bearing: Scalars['Int'];
  config: Scalars['Object'];
  link?: Maybe<Scalars['String']>;
  location: Scalars['Object'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Object']>;
  type: IntersectionIconType;
};

export type IntersectionIconInput = {
  bearing: Scalars['Int'];
  config: Scalars['Object'];
  link?: InputMaybe<Scalars['String']>;
  location: Scalars['Object'];
  name?: InputMaybe<Scalars['String']>;
  type: IntersectionIconType;
};

export type IntersectionIconStatus = {
  __typename?: 'IntersectionIconStatus';
  icons?: Maybe<Array<IntersectionIcon>>;
  intersectionId: Scalars['ID'];
};

export enum IntersectionIconType {
  Alarm = 'ALARM',
  Ball = 'BALL',
  Bikemaneuver = 'BIKEMANEUVER',
  Custom = 'CUSTOM',
  Emergency = 'EMERGENCY',
  Label = 'LABEL',
  Lrtmaneuver = 'LRTMANEUVER',
  Pattern = 'PATTERN',
  Pedmaneuver = 'PEDMANEUVER',
  Rail = 'RAIL',
  RampIndication = 'RAMP_INDICATION',
  RampLaneActRate = 'RAMP_LANE_ACT_RATE',
  RampLaneCmd = 'RAMP_LANE_CMD',
  RampLaneCmdRate = 'RAMP_LANE_CMD_RATE',
  RampLaneCmdSource = 'RAMP_LANE_CMD_SOURCE',
  RampLanePlan = 'RAMP_LANE_PLAN',
  RampWarningFlasher = 'RAMP_WARNING_FLASHER',
  Specialfx = 'SPECIALFX',
  Status = 'STATUS',
  Vehdetector = 'VEHDETECTOR',
  Vehmaneuver = 'VEHMANEUVER'
}

export type IntersectionInput = {
  crosswalks: Array<IntersectionCrosswalkInput>;
  details: IntersectionDetailsInput;
  devices: Array<IntersectionDeviceInput>;
  groupId?: InputMaybe<Scalars['String']>;
  icons: Array<IntersectionIconInput>;
  /**  Identifier of the intersection  */
  id?: InputMaybe<Scalars['ID']>;
  lanes: Array<IntersectionLaneInput>;
  location: Scalars['Object'];
  maneuvers: Array<IntersectionManeuverInput>;
  multiModals: Array<IntersectionMultiModalInput>;
};

export type IntersectionLane = {
  __typename?: 'IntersectionLane';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  laneDirection: IntersectionLaneDirection;
  location: Scalars['Object'];
  travelDirection?: Maybe<IntersectionTravelDirection>;
  vehicleDetectors: Array<IntersectionVehicleDetector>;
};

export enum IntersectionLaneDirection {
  Approach = 'APPROACH',
  Departure = 'DEPARTURE'
}

export type IntersectionLaneInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  laneDirection: IntersectionLaneDirection;
  location: Scalars['Object'];
  travelDirection?: InputMaybe<IntersectionTravelDirection>;
  vehicleDetectors?: InputMaybe<Array<IntersectionVehicleDetectorInput>>;
};

export type IntersectionLocation = {
  __typename?: 'IntersectionLocation';
  deviceIds: Array<Scalars['String']>;
  intersectionId: Scalars['ID'];
  location: Scalars['Object'];
};

export type IntersectionManeuver = {
  __typename?: 'IntersectionManeuver';
  deviceId?: Maybe<Scalars['String']>;
  icon: IntersectionIcon;
  maneuverType: IntersectionManeuverType;
  permissiveSignalIndication?: Maybe<SignalIndication>;
  protectedSignalIndication?: Maybe<SignalIndication>;
  refApproachLane: Scalars['String'];
  refDepartureLane: Scalars['String'];
};

export type IntersectionManeuverInput = {
  deviceId?: InputMaybe<Scalars['String']>;
  icon: IntersectionIconInput;
  maneuverType: IntersectionManeuverType;
  permissiveSignalIndication?: InputMaybe<SignalIndicationInput>;
  protectedSignalIndication?: InputMaybe<SignalIndicationInput>;
  refApproachLane: Scalars['String'];
  refDepartureLane: Scalars['String'];
};

export enum IntersectionManeuverType {
  Bike = 'BIKE',
  Lrt = 'LRT',
  Ped = 'PED',
  Vehicle = 'VEHICLE',
  Vehicleped = 'VEHICLEPED'
}

export enum IntersectionMapIcon {
  Ball = 'BALL',
  Bike = 'BIKE',
  Custom = 'CUSTOM',
  Hand = 'HAND',
  Leftarrow = 'LEFTARROW',
  Lrtgo = 'LRTGO',
  Lrtstop = 'LRTSTOP',
  Rightarrow = 'RIGHTARROW',
  Slightleftarrow = 'SLIGHTLEFTARROW',
  Slightrightarrow = 'SLIGHTRIGHTARROW',
  Stopbar = 'STOPBAR',
  Straightarrow = 'STRAIGHTARROW',
  Triangle = 'TRIANGLE',
  Uturn = 'UTURN',
  Walk = 'WALK'
}

export type IntersectionMultiModal = {
  __typename?: 'IntersectionMultiModal';
  deviceId: Scalars['String'];
  icon: IntersectionIcon;
  signalIndication?: Maybe<SignalIndication>;
};

export type IntersectionMultiModalInput = {
  deviceId: Scalars['String'];
  icon: IntersectionIconInput;
  signalIndication?: InputMaybe<SignalIndicationInput>;
};

export type IntersectionOverlapStatus = {
  __typename?: 'IntersectionOverlapStatus';
  intersectionId: Scalars['ID'];
  overlapStatus: Array<OverlapStatus>;
};

export type IntersectionPatternStatus = {
  __typename?: 'IntersectionPatternStatus';
  activeCycleLength: Scalars['Int'];
  activeOffset: Scalars['Int'];
  coordStatus: CoordStatus;
  intersectionId: Scalars['ID'];
  localCycle: Scalars['Int'];
  masterCycle: Scalars['Int'];
  patternNumber: Scalars['Int'];
  patternType: PatternType;
  programmedCycleLength: Scalars['Int'];
  programmedOffset: Scalars['Int'];
  splitTable: Scalars['Int'];
  timeDrift: Scalars['Int'];
};

export type IntersectionPedStatus = {
  __typename?: 'IntersectionPedStatus';
  intersectionId: Scalars['ID'];
  pedStatus: Array<PedStatus>;
};

export type IntersectionPhaseStatus = {
  __typename?: 'IntersectionPhaseStatus';
  intersectionId: Scalars['ID'];
  phaseStatus: Array<PhaseStatus>;
};

export type IntersectionPhaseSummaryCounts = {
  __typename?: 'IntersectionPhaseSummaryCounts';
  commDisabled: Scalars['Int'];
  commFailed: Scalars['Int'];
  green: Scalars['Int'];
  red: Scalars['Int'];
  unknown: Scalars['Int'];
  yellow: Scalars['Int'];
};

export type IntersectionSimpleStatus = {
  __typename?: 'IntersectionSimpleStatus';
  communicationState: CommunicationState;
  intersectionId: Scalars['ID'];
  mainActionColor?: Maybe<SignalOutputColor>;
  mainCommandSourceColor?: Maybe<SignalOutputColor>;
  mainImplementAction?: Maybe<RmcImplementAction>;
  mainImplementCommandSource?: Maybe<RmcImplementCommandSource>;
  mainImplementPlan: Scalars['Int'];
  mainImplementRate: Scalars['Int'];
  mainStatus?: Maybe<RmcImplementAction>;
  mainlineColor: SignalOutputColor;
  maneuverColor: Array<ManeuverColor>;
};

export type IntersectionStatusCategories = {
  __typename?: 'IntersectionStatusCategories';
  badPlan: Scalars['Int'];
  cabinetFlash: Scalars['Int'];
  commDisabled: Scalars['Int'];
  commFailed: Scalars['Int'];
  coordination: Scalars['Int'];
  free: Scalars['Int'];
  localFlash: Scalars['Int'];
  preempt: Scalars['Int'];
  transition: Scalars['Int'];
  unknown: Scalars['Int'];
};

export enum IntersectionStatusCategory {
  BadPlan = 'BAD_PLAN',
  CabinetFlash = 'CABINET_FLASH',
  CommDisabled = 'COMM_DISABLED',
  CommFailed = 'COMM_FAILED',
  Coord = 'COORD',
  Free = 'FREE',
  LocalFlash = 'LOCAL_FLASH',
  Preempt = 'PREEMPT',
  Transition = 'TRANSITION',
  Unknown = 'UNKNOWN'
}

export type IntersectionTemplate = {
  __typename?: 'IntersectionTemplate';
  /**  The description of the template  */
  description?: Maybe<Scalars['String']>;
  /**  The identifier of the template  */
  id: Scalars['ID'];
  /**  The name of the template  */
  name: Scalars['String'];
  /**  The intersectiion to serve as the template  */
  template: Intersection;
};

export type IntersectionTemplateConnection = {
  __typename?: 'IntersectionTemplateConnection';
  /**  connection edges  */
  edges: Array<IntersectionTemplateConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type IntersectionTemplateConnectionEdge = {
  __typename?: 'IntersectionTemplateConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: IntersectionTemplate;
};

export type IntersectionTemplateInput = {
  /**  The description of the template  */
  description?: InputMaybe<Scalars['String']>;
  /**  The identifier of the template  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The name of the template  */
  name: Scalars['String'];
  /**  The intersection to serve as the template  */
  template: IntersectionInput;
};

export type IntersectionTemplateInputQuery = {
  /** Fetch items after this cursor. Cannot be used with before */
  after?: InputMaybe<Scalars['String']>;
  /** Fetch items before this cursor. Cannot be used with after */
  before?: InputMaybe<Scalars['String']>;
  /** The fields and values to filter intertsections */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /** Limit the number of results. We do not allow returning all docs. */
  limit?: InputMaybe<Scalars['Int']>;
  /** The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /** Text to search for. */
  searchText?: InputMaybe<Scalars['String']>;
  /** Which field to sort the results on. */
  sortField?: InputMaybe<Scalars['String']>;
  /** Which direction to sort the field on. */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum IntersectionTravelDirection {
  East = 'EAST',
  North = 'NORTH',
  NorthEast = 'NORTH_EAST',
  NorthWest = 'NORTH_WEST',
  Other = 'OTHER',
  South = 'SOUTH',
  SouthEast = 'SOUTH_EAST',
  SouthWest = 'SOUTH_WEST',
  West = 'WEST'
}

export type IntersectionVehicleDetector = {
  __typename?: 'IntersectionVehicleDetector';
  description?: Maybe<Scalars['String']>;
  distance: Scalars['Int'];
  icon: IntersectionIcon;
  length: Scalars['Int'];
  location?: Maybe<Scalars['Object']>;
  number: Scalars['Int'];
  showOnMap: Scalars['Boolean'];
  type?: Maybe<IntersectionVehicleDetectorType>;
  width: Scalars['Int'];
};

export type IntersectionVehicleDetectorInput = {
  description?: InputMaybe<Scalars['String']>;
  distance: Scalars['Int'];
  icon: IntersectionIconInput;
  length: Scalars['Int'];
  location?: InputMaybe<Scalars['Object']>;
  number: Scalars['Int'];
  showOnMap: Scalars['Boolean'];
  type?: InputMaybe<IntersectionVehicleDetectorType>;
  width: Scalars['Int'];
};

export enum IntersectionVehicleDetectorType {
  Advanced = 'ADVANCED',
  Demand = 'DEMAND',
  Exit = 'EXIT',
  Passage = 'PASSAGE',
  Queue = 'QUEUE',
  Stopbar = 'STOPBAR'
}

export enum IntervalUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Millisecond = 'MILLISECOND',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type JobRequest = {
  __typename?: 'JobRequest';
  /**  The failure message to why the job failed to be created.  */
  failure?: Maybe<Scalars['String']>;
  /**  id of the integration  */
  id: Scalars['String'];
  /**  request id of the job  */
  requestId?: Maybe<Scalars['String']>;
  /**  if the job was successfully queued  */
  success: Scalars['Boolean'];
};

export type JobResult = {
  __typename?: 'JobResult';
  /**  The main response of the job */
  body?: Maybe<Scalars['JSON']>;
  /**  The failure message to what went wrong with the job. */
  failure?: Maybe<Scalars['String']>;
  /**  id of the integration */
  id: Scalars['String'];
  /**  the type of job run */
  jobType: JobType;
  /**  the request id */
  requestId?: Maybe<Scalars['String']>;
  /**  if the job was successfully run */
  success: Scalars['Boolean'];
};

export enum JobType {
  AlertPoll = 'ALERT_POLL',
  AlertUpdate = 'ALERT_UPDATE',
  BackupDeviceDatabase = 'BACKUP_DEVICE_DATABASE',
  C2CPoll = 'C2C_POLL',
  CadSubscription = 'CAD_SUBSCRIPTION',
  CameraAutoFocus = 'CAMERA_AUTO_FOCUS',
  CameraAutoIris = 'CAMERA_AUTO_IRIS',
  CameraCapabilities = 'CAMERA_CAPABILITIES',
  CameraConnectStreamFile = 'CAMERA_CONNECT_STREAM_FILE',
  CameraDisablePublicStreamTarget = 'CAMERA_DISABLE_PUBLIC_STREAM_TARGET',
  CameraDisconnectStreamFile = 'CAMERA_DISCONNECT_STREAM_FILE',
  CameraEnablePublicStreamTarget = 'CAMERA_ENABLE_PUBLIC_STREAM_TARGET',
  CameraFocus = 'CAMERA_FOCUS',
  CameraGetMaxPresets = 'CAMERA_GET_MAX_PRESETS',
  CameraGoToPreset = 'CAMERA_GO_TO_PRESET',
  CameraIris = 'CAMERA_IRIS',
  CameraPanTilt = 'CAMERA_PAN_TILT',
  CameraPoll = 'CAMERA_POLL',
  CameraSavePreset = 'CAMERA_SAVE_PRESET',
  CameraStartStreamRecording = 'CAMERA_START_STREAM_RECORDING',
  CameraStopStreamRecording = 'CAMERA_STOP_STREAM_RECORDING',
  CameraZoom = 'CAMERA_ZOOM',
  ChangeDeviceFirmware = 'CHANGE_DEVICE_FIRMWARE',
  Detector = 'DETECTOR',
  DetectorConnectionLogs = 'DETECTOR_CONNECTION_LOGS',
  DetectorPoll = 'DETECTOR_POLL',
  DetectorSubscription = 'DETECTOR_SUBSCRIPTION',
  DetectorVehicleRecords = 'DETECTOR_VEHICLE_RECORDS',
  DownloadDeviceDatabase = 'DOWNLOAD_DEVICE_DATABASE',
  /**  Value representing that a gate controller has been polled */
  GateControllerPoll = 'GATE_CONTROLLER_POLL',
  GetPublicFeed = 'GET_PUBLIC_FEED',
  GetStreamFileEnabled = 'GET_STREAM_FILE_ENABLED',
  GetStreamTargetEnabled = 'GET_STREAM_TARGET_ENABLED',
  InstantCameraSnapshot = 'INSTANT_CAMERA_SNAPSHOT',
  ResponsePoll = 'RESPONSE_POLL',
  SetSnapshotsTag = 'SET_SNAPSHOTS_TAG',
  SetTimeNow = 'SET_TIME_NOW',
  SignalGetMibs = 'SIGNAL_GET_MIBS',
  SignalInterval = 'SIGNAL_INTERVAL',
  SignalPoll = 'SIGNAL_POLL',
  SignalPollSubscription = 'SIGNAL_POLL_SUBSCRIPTION',
  SignalSetMibs = 'SIGNAL_SET_MIBS',
  SignalSubscription = 'SIGNAL_SUBSCRIPTION',
  SignalSubscriptionOnDemand = 'SIGNAL_SUBSCRIPTION_ON_DEMAND',
  SignalTrafficEvents = 'SIGNAL_TRAFFIC_EVENTS',
  SignalUiTable = 'SIGNAL_UI_TABLE',
  SignalUiViews = 'SIGNAL_UI_VIEWS',
  /**  Value representing that the message being sent is for blanking the signage */
  SignBlank = 'SIGN_BLANK',
  SignCapabilities = 'SIGN_CAPABILITIES',
  /**  Value representing that the graphic is being marked for deletion */
  SignDeleteGraphic = 'SIGN_DELETE_GRAPHIC',
  /**  Value representing that the message being sent is for displaying something */
  SignDisplay = 'SIGN_DISPLAY',
  /**  Value representing that the graphic is being downloaded */
  SignDownloadGraphic = 'SIGN_DOWNLOAD_GRAPHIC',
  /**  Value representing that messages can be downloaded from the controller */
  SignDownloadMessage = 'SIGN_DOWNLOAD_MESSAGE',
  /**  Value representing that the message being sent is for extending message displaying time */
  SignExtend = 'SIGN_EXTEND',
  /**  Value representing that the sign fan test has been completed */
  SignFanTest = 'SIGN_FAN_TEST',
  /**  Value representing that the message being sent is for getting supported fonts */
  SignFont = 'SIGN_FONT',
  SignFonts = 'SIGN_FONTS',
  /**  Value representing that the message being sent is for getting font info */
  SignFontInfo = 'SIGN_FONT_INFO',
  /**  Value representing that the message being sent is for polling the display */
  SignPoll = 'SIGN_POLL',
  /**  Value representing that the message being sent is for Setting Brightness */
  SignSetBrightness = 'SIGN_SET_BRIGHTNESS',
  /**  Value representing that the message being sent is for a Soft Reset */
  SignSoftReset = 'SIGN_SOFT_RESET',
  /**  Value representing that the graphic is being uploaded */
  SignUploadGraphic = 'SIGN_UPLOAD_GRAPHIC',
  /**  Value representing that messages can be uploaded to the controller */
  SignUploadMessage = 'SIGN_UPLOAD_MESSAGE',
  StreamManagerPoll = 'STREAM_MANAGER_POLL',
  TravelTimeSegmentPoll = 'TRAVEL_TIME_SEGMENT_POLL',
  UseCameraCapability = 'USE_CAMERA_CAPABILITY',
  WeatherStationPoll = 'WEATHER_STATION_POLL'
}

export type KeycloakClient = {
  __typename?: 'KeycloakClient';
  clientId: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  secret: Scalars['String'];
};

export type KineticAuthJwt = {
  __typename?: 'KineticAuthJwt';
  accessToken: Scalars['String'];
  privilegeToken: Scalars['String'];
};

export type KineticEffectivePrivilegeAssignment = {
  __typename?: 'KineticEffectivePrivilegeAssignment';
  id: Scalars['ID'];
  kineticModule: KineticModule;
  privilegeType: PrivilegeType;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: Maybe<Scalars['Object']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type KineticForm = {
  __typename?: 'KineticForm';
  /**  can the form be modified within a tenant  */
  canOverride?: Maybe<Scalars['Boolean']>;
  /**  form description  */
  description?: Maybe<Scalars['String']>;
  /**  extra form config  */
  extraConfig: Scalars['Object'];
  id: Scalars['ID'];
  /**  when the form was last modified for tenant forms  */
  modified?: Maybe<Scalars['DateTime']>;
  /**  form module  */
  module: KineticModule;
  /**  form name  */
  name: Scalars['String'];
  /**  identifies if it is a system form  */
  system?: Maybe<Scalars['Boolean']>;
  /**  form views  */
  views: Array<Maybe<KineticFormView>>;
};

export type KineticFormConnection = {
  __typename?: 'KineticFormConnection';
  /**  connection edges  */
  edges: Array<KineticFormConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type KineticFormConnectionEdge = {
  __typename?: 'KineticFormConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: KineticForm;
};

export type KineticFormField = {
  __typename?: 'KineticFormField';
  /**  accept type  */
  accept?: Maybe<Scalars['String']>;
  /**  json logic for active  */
  activeLogic?: Maybe<Scalars['Object']>;
  /**  can the field be modified within a tenant  */
  canOverride?: Maybe<Scalars['Boolean']>;
  /**  The ID of the data source associated with this field */
  dataSource?: Maybe<Scalars['String']>;
  /**  Default for the form field  */
  defaultValue?: Maybe<Scalars['Object']>;
  /**  field description  */
  description?: Maybe<Scalars['String']>;
  /**  prevent future dates for date field type  */
  disableFuture?: Maybe<Scalars['Boolean']>;
  /**  prevent previous dates for date field type  */
  disablePast?: Maybe<Scalars['Boolean']>;
  /**  display date format for date field type  */
  displayFormat?: Maybe<Scalars['String']>;
  /**  field size  */
  fieldSize?: Maybe<Scalars['String']>;
  /**  date format for date field type  */
  format?: Maybe<Scalars['String']>;
  /**  help details  */
  help?: Maybe<Scalars['String']>;
  /**  field hint  */
  hint?: Maybe<Scalars['String']>;
  /**  The icon config  */
  iconConfig?: Maybe<Scalars['Object']>;
  /**  unique identifier  */
  id: Scalars['ID'];
  /**  field label  */
  label: Scalars['String'];
  /**  if the loading indicator is shown  */
  loading?: Maybe<Scalars['Boolean']>;
  /**  max value for number fields  */
  max?: Maybe<Scalars['Float']>;
  /**  min value for number fields  */
  min?: Maybe<Scalars['Float']>;
  /**  the mode of the field */
  mode?: Maybe<Scalars['String']>;
  /**  the module for upload  */
  moduleName?: Maybe<Scalars['String']>;
  /**  if multi select supported  */
  multiple?: Maybe<Scalars['Boolean']>;
  /**  name of the field  */
  name: Scalars['String'];
  /**  field options  */
  options?: Maybe<Array<KineticFormFieldOption>>;
  /**  if field is required  */
  required?: Maybe<Scalars['Boolean']>;
  /**  json logic for required  */
  requiredLogic?: Maybe<Scalars['Object']>;
  /**  order for options list  */
  sortOrder?: Maybe<Scalars['String']>;
  /**  collection id for type data source  */
  source?: Maybe<Scalars['ID']>;
  /**  stepper value  */
  step?: Maybe<Scalars['Float']>;
  /**  identifies if it is a system form field  */
  system?: Maybe<Scalars['Boolean']>;
  /**  field type  */
  type: Scalars['String'];
  /**  upload type  */
  uploadType?: Maybe<Scalars['String']>;
  /**  if number field should use stepper  */
  useStepper?: Maybe<Scalars['Boolean']>;
};

export type KineticFormFieldConnection = {
  __typename?: 'KineticFormFieldConnection';
  /**  connection edges  */
  edges: Array<KineticFormFieldConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type KineticFormFieldConnectionEdge = {
  __typename?: 'KineticFormFieldConnectionEdge';
  /**  node cursor  */
  cursor?: Maybe<Scalars['String']>;
  /**  connection edge node  */
  node: KineticFormField;
};

export type KineticFormFieldInput = {
  /**  accept type  */
  accept?: InputMaybe<Scalars['String']>;
  /**  json logic for active  */
  activeLogic?: InputMaybe<Scalars['Object']>;
  /**  The ID of the data source associated with this field */
  dataSource?: InputMaybe<Scalars['String']>;
  /**  field description  */
  description?: InputMaybe<Scalars['String']>;
  /**  prevent future dates for date field type  */
  disableFuture?: InputMaybe<Scalars['Boolean']>;
  /**  prevent previous dates for date field type  */
  disablePast?: InputMaybe<Scalars['Boolean']>;
  /**  display date format for date field type  */
  displayFormat?: InputMaybe<Scalars['String']>;
  /**  field size  */
  fieldSize?: InputMaybe<Scalars['String']>;
  /**  date format for date field type  */
  format?: InputMaybe<Scalars['String']>;
  /**  help details  */
  help?: InputMaybe<Scalars['String']>;
  /**  field hint  */
  hint?: InputMaybe<Scalars['String']>;
  /**  The icon config  */
  iconConfig?: InputMaybe<Scalars['Object']>;
  /**  unique identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  field label  */
  label: Scalars['String'];
  /**  if the loading indicator is shown  */
  loading?: InputMaybe<Scalars['Boolean']>;
  /**  max value for number fields  */
  max?: InputMaybe<Scalars['Float']>;
  /**  min value for number fields  */
  min?: InputMaybe<Scalars['Float']>;
  /**  the mode of the field */
  mode?: InputMaybe<Scalars['String']>;
  /**  the module for upload  */
  moduleName?: InputMaybe<Scalars['String']>;
  /**  if multi select supported  */
  multiple?: InputMaybe<Scalars['Boolean']>;
  /**  name of the field  */
  name: Scalars['String'];
  /**  field options  */
  options?: InputMaybe<Array<KineticFormFieldOptionInput>>;
  /**  if field is required  */
  required?: InputMaybe<Scalars['Boolean']>;
  /**  json logic for required  */
  requiredLogic?: InputMaybe<Scalars['Object']>;
  /**  order for options list  */
  sortOrder?: InputMaybe<Scalars['String']>;
  /**  collection id for type data source  */
  source?: InputMaybe<Scalars['ID']>;
  /**  stepper value  */
  step?: InputMaybe<Scalars['Float']>;
  /**  field type  */
  type: Scalars['String'];
  /**  upload type  */
  uploadType?: InputMaybe<Scalars['String']>;
  /**  if number field should use stepper  */
  useStepper?: InputMaybe<Scalars['Boolean']>;
};

export type KineticFormFieldInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type KineticFormFieldOption = {
  __typename?: 'KineticFormFieldOption';
  /**  option label  */
  label: Scalars['String'];
  /**  type */
  type?: Maybe<Scalars['String']>;
  /**  option value  */
  value: Scalars['String'];
};

export type KineticFormFieldOptionInput = {
  /**  option label  */
  label: Scalars['String'];
  /**  option value  */
  value: Scalars['String'];
};

export type KineticFormInput = {
  /**  form description  */
  description?: InputMaybe<Scalars['String']>;
  /**  extra form config  */
  extraConfig: Scalars['Object'];
  /**  form identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  form module  */
  module: KineticModule;
  /**  form name  */
  name: Scalars['String'];
  /**  form views  */
  views: Array<InputMaybe<KineticFormViewInput>>;
};

export type KineticFormInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  Kinetic Module to filter by  */
  module?: InputMaybe<KineticModule>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type KineticFormSection = {
  __typename?: 'KineticFormSection';
  /**  list of fields  */
  fields: Array<Maybe<KineticFormField>>;
  /**  section identifier  */
  id: Scalars['ID'];
  /**  name of section  */
  name: Scalars['String'];
};

export type KineticFormSectionInput = {
  /**  list of identifiers for kinetic form fields  */
  fields: Array<Scalars['String']>;
  /**  section identifier  */
  id: Scalars['ID'];
  /**  name of section  */
  name: Scalars['String'];
};

export type KineticFormView = {
  __typename?: 'KineticFormView';
  /**  view identifier  */
  id: Scalars['ID'];
  /**  view name  */
  name: Scalars['String'];
  /**  view sections  */
  sections: Array<KineticFormSection>;
};

export type KineticFormViewInput = {
  /**  view identifier  */
  id: Scalars['ID'];
  /**  view name  */
  name: Scalars['String'];
  /**  view sections  */
  sections: Array<KineticFormSectionInput>;
};

export type KineticLicenseDetails = {
  __typename?: 'KineticLicenseDetails';
  accountId?: Maybe<Scalars['String']>;
  activationId: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  leaseExpiresAt?: Maybe<Scalars['DateTime']>;
};

export enum KineticLicenseFeature {
  C2C = 'C2C',
  Corridors = 'CORRIDORS',
  Counts = 'COUNTS',
  Events = 'EVENTS',
  Gates = 'GATES',
  Response = 'RESPONSE',
  Signals = 'SIGNALS',
  Signs = 'SIGNS',
  TravelTime = 'TRAVEL_TIME',
  Tunnels = 'TUNNELS',
  Video = 'VIDEO',
  Weather = 'WEATHER'
}

export type KineticLicenseFeatureValue = {
  __typename?: 'KineticLicenseFeatureValue';
  enabled: Scalars['Boolean'];
  feature: KineticLicenseFeature;
  used?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export enum KineticModule {
  C2C = 'C2C',
  Contacts = 'CONTACTS',
  Corridors = 'CORRIDORS',
  Counts = 'COUNTS',
  Emails = 'EMAILS',
  Events = 'EVENTS',
  Gates = 'GATES',
  Integrations = 'INTEGRATIONS',
  Parking = 'PARKING',
  Response = 'RESPONSE',
  Signals = 'SIGNALS',
  Signs = 'SIGNS',
  System = 'SYSTEM',
  TravelTime = 'TRAVEL_TIME',
  Tunnels = 'TUNNELS',
  Video = 'VIDEO',
  Weather = 'WEATHER'
}

export type KineticOrganization = {
  __typename?: 'KineticOrganization';
  address?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type KineticOrganizationInput = {
  address?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type KineticPrivilege = {
  __typename?: 'KineticPrivilege';
  id: Scalars['ID'];
  kineticModule: KineticModule;
  privilegeType: PrivilegeType;
};

export type KineticPrivilegeAssignment = {
  __typename?: 'KineticPrivilegeAssignment';
  id: Scalars['ID'];
  kineticModule: KineticModule;
  privilegeType: PrivilegeType;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: Maybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type KineticPrivilegeAssignmentInput = {
  id: Scalars['ID'];
  kineticModule: KineticModule;
  privilegeType: PrivilegeType;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: InputMaybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type KineticRole = {
  __typename?: 'KineticRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignment>;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: Maybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type KineticRoleInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignmentInput>;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: InputMaybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type KineticUser = {
  __typename?: 'KineticUser';
  email?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kineticPreference?: Maybe<Scalars['JSON']>;
  kineticPreferences: Array<UserPreference>;
  lastName?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  preference?: Maybe<Scalars['JSON']>;
  preferences: Array<UserPreference>;
  username: Scalars['String'];
};


export type KineticUserFilesArgs = {
  key: Scalars['String'];
};


export type KineticUserKineticPreferenceArgs = {
  key: Scalars['ID'];
};


export type KineticUserPreferenceArgs = {
  key: Scalars['ID'];
};

export type KineticUserConnection = {
  __typename?: 'KineticUserConnection';
  edges: Array<KineticUserConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type KineticUserConnectionEdge = {
  __typename?: 'KineticUserConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: OrganizationKineticUser;
};

export type KineticUserGroup = {
  __typename?: 'KineticUserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignment>;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: Maybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
  roles: Array<KineticRole>;
  users: Array<OrganizationKineticUser>;
};

export type KineticUserGroupInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignmentInput>;
  restrictedAreas: Array<Scalars['Object']>;
  restrictedEntities?: InputMaybe<Scalars['Object']>;
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
  roleIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type KineticUserPrivilegeAssignment = {
  __typename?: 'KineticUserPrivilegeAssignment';
  id: Scalars['ID'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignment>;
  roles: Array<KineticRole>;
};

export type KineticUserPrivilegeAssignmentInput = {
  id: Scalars['ID'];
  privilegeAssignmentList: Array<KineticPrivilegeAssignmentInput>;
  roleIds: Array<Scalars['String']>;
  userGroupIds: Array<Scalars['String']>;
};

export enum LabelType {
  Executive = 'EXECUTIVE',
  FirstResponse = 'FIRST_RESPONSE'
}

export type LaneControlCommand = {
  __typename?: 'LaneControlCommand';
  /**  if the command is the default  */
  defaultCommand: Scalars['Boolean'];
  /**  the translated image data  */
  image: Scalars['Object'];
  /**  the translated message markup  */
  message: Scalars['String'];
  /**  name of the command  */
  name: Scalars['String'];
};

export type LaneControlCommandInput = {
  /**  if the command is the default  */
  defaultCommand: Scalars['Boolean'];
  /**  the translated image data  */
  image: Scalars['Object'];
  /**  the translated message markup  */
  message: Scalars['String'];
  /**  name of the command  */
  name: Scalars['String'];
};

export type LaneControlSettings = {
  __typename?: 'LaneControlSettings';
  /**  The list of configured commands  */
  commands: Array<LaneControlCommand>;
  /**  if the sign should be blank by default  */
  defaultBlank: Scalars['Boolean'];
  /**  The identifier of the settings  */
  id: Scalars['ID'];
  /**  The name of the settings  */
  name: Scalars['String'];
  /**  The list of sign identifiers who use these settings  */
  signs: Array<Scalars['ID']>;
  /**  if the settings should be used as the system default  */
  systemDefault: Scalars['Boolean'];
};

export type LaneControlSettingsConnection = {
  __typename?: 'LaneControlSettingsConnection';
  /**  The contained edges  */
  edges: Array<LaneControlSettingsConnectionEdge>;
  /**  Pagination info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type LaneControlSettingsConnectionEdge = {
  __typename?: 'LaneControlSettingsConnectionEdge';
  /**  the location of the edge in the page results  */
  cursor?: Maybe<Scalars['String']>;
  /**  entity details for the node  */
  node: LaneControlSettings;
};

export type LaneControlSettingsInput = {
  /**  The list of configured commands  */
  commands: Array<LaneControlCommandInput>;
  /**  if the sign should be blank by default  */
  defaultBlank: Scalars['Boolean'];
  /**  The identifier of the settings  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The name of the settings  */
  name: Scalars['String'];
  /**  The list of sign identifiers who use these settings  */
  signs: Array<Scalars['ID']>;
  /**  if the settings should be used as the system default  */
  systemDefault: Scalars['Boolean'];
};

export enum LaneStatus {
  /**  Lane is cautioned  */
  Caution = 'CAUTION',
  /**  Lane is closed  */
  Closed = 'CLOSED',
  /**  Lane is open  */
  Open = 'OPEN'
}

export enum LaneType {
  /**  Express Lane  */
  ExpressLane = 'EXPRESS_LANE',
  /**  General Purpose  */
  GeneralPurpose = 'GENERAL_PURPOSE',
  /**  HOV  */
  Hov = 'HOV',
  /**  Left Shoulder  */
  LeftShoulder = 'LEFT_SHOULDER',
  /**  Median  */
  Median = 'MEDIAN',
  /**  Off Ramp  */
  OffRamp = 'OFF_RAMP',
  /**  On Ramp  */
  OnRamp = 'ON_RAMP',
  /**  Right Shoulder  */
  RightShoulder = 'RIGHT_SHOULDER'
}

export type Layout = {
  __typename?: 'Layout';
  areas: Array<LayoutArea>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LayoutArea = {
  __typename?: 'LayoutArea';
  columns: Scalars['Int'];
  rows: Scalars['Int'];
};

export type LayoutAreaInput = {
  columns: Scalars['Int'];
  rows: Scalars['Int'];
};

export type LayoutInput = {
  areas: Array<LayoutAreaInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum LegalStatusCode {
  AlarmSign = 'ALARM_SIGN',
  Axle_1Overloaded = 'AXLE_1_OVERLOADED',
  Axle_2Overloaded = 'AXLE_2_OVERLOADED',
  Axle_3Overloaded = 'AXLE_3_OVERLOADED',
  Axle_4Overloaded = 'AXLE_4_OVERLOADED',
  Axle_5Overloaded = 'AXLE_5_OVERLOADED',
  Axle_6Overloaded = 'AXLE_6_OVERLOADED',
  Axle_7Overloaded = 'AXLE_7_OVERLOADED',
  Axle_8Overloaded = 'AXLE_8_OVERLOADED',
  AxleOverloaded = 'AXLE_OVERLOADED',
  GrossOverloaded = 'GROSS_OVERLOADED',
  OutOfLane = 'OUT_OF_LANE',
  OverHeight = 'OVER_HEIGHT',
  OverLength = 'OVER_LENGTH',
  OverSpeed = 'OVER_SPEED',
  Reserved = 'RESERVED'
}

export type LocalDeviceDatabase = {
  __typename?: 'LocalDeviceDatabase';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: LocalDeviceDatabaseType;
};

export enum LocalDeviceDatabaseType {
  Active = 'ACTIVE',
  Factory = 'FACTORY',
  Unknown = 'UNKNOWN',
  Usb = 'USB',
  User = 'USER'
}

export type LocationArea = {
  __typename?: 'LocationArea';
  /**  The code of the area, distinct by type  */
  code: Scalars['String'];
  /**  The density of the area  */
  density?: Maybe<LocationAreaDensity>;
  /**  The geometry of the area  */
  geometry: Scalars['Object'];
  /**  The name of the area  */
  name: Scalars['String'];
  /**  The type of the area  */
  type: Scalars['String'];
};

export type LocationAreaConnection = {
  __typename?: 'LocationAreaConnection';
  edges: Array<LocationAreaConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LocationAreaConnectionEdge = {
  __typename?: 'LocationAreaConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: LocationArea;
};

export enum LocationAreaDensity {
  DenseUrban = 'DENSE_URBAN',
  Rural = 'RURAL',
  Urban = 'URBAN'
}

export type LocationRoadwayArea = {
  __typename?: 'LocationRoadwayArea';
  /**  The list of contained areas  */
  areas: Scalars['Object'];
  /**  The snapped geometry on the area  */
  geometry: Scalars['Object'];
};

export type LocationRoadwayPoint = {
  __typename?: 'LocationRoadwayPoint';
  /**  The list of contained areas  */
  areas: Scalars['Object'];
  /**  The density of the point  */
  density?: Maybe<LocationAreaDensity>;
  /**  The direction of the route  */
  direction: Scalars['String'];
  /**  The number of current express lanes  */
  expressLaneCount?: Maybe<Scalars['Int']>;
  /**  The snapped point on the route  */
  geometry: Scalars['Object'];
  /**  The nearest landmark  */
  landmark?: Maybe<Scalars['String']>;
  /**  The number of current general lanes  */
  laneCount?: Maybe<Scalars['Int']>;
  /**  The measure of the route  */
  measure?: Maybe<Scalars['Float']>;
  /**  The number of opposite express lanes  */
  oppositeExpressLaneCount?: Maybe<Scalars['Int']>;
  /**  The number of opposite general lanes  */
  oppositeLaneCount?: Maybe<Scalars['Int']>;
  /**  The name of the route  */
  route: Scalars['String'];
};

export type LocationRoadwaySegment = {
  __typename?: 'LocationRoadwaySegment';
  /**  The list of contained areas  */
  areas: Scalars['Object'];
  /**  The density of the start point  */
  density: LocationAreaDensity;
  /**  The direction of the route  */
  direction: Scalars['String'];
  /**  The end measure of the segment  */
  endMeasure: Scalars['Float'];
  /**  The snapped linestring on the route  */
  geometry: Scalars['Object'];
  /**  The list of lane counts  */
  lanes: Array<LocationRoadwaySegmentLaneCount>;
  /**  The name of the route  */
  route: Scalars['String'];
  /**  The start measure of the segment  */
  startMeasure: Scalars['Float'];
};

export type LocationRoadwaySegmentLaneCount = {
  __typename?: 'LocationRoadwaySegmentLaneCount';
  /**  The to measure of the config  */
  endReference: Scalars['Float'];
  /**  The number of current express lanes  */
  expressLaneCount?: Maybe<Scalars['Int']>;
  /**  The number of current general lanes  */
  laneCount: Scalars['Int'];
  /**  The number of opposite express lanes  */
  oppositeExpressLaneCount?: Maybe<Scalars['Int']>;
  /**  The number of opposite general lanes  */
  oppositeLaneCount: Scalars['Int'];
  /**  The from measure of the config  */
  startReference: Scalars['Float'];
};

export type LocationRoute = {
  __typename?: 'LocationRoute';
  /**  The classification of the route  */
  classification: Scalars['String'];
  /**  The direction of the route  */
  direction: Scalars['String'];
  /**  The from measure of the route  */
  fromMeasure?: Maybe<Scalars['Float']>;
  /**  The geometry of the route  */
  geometry: Scalars['Object'];
  /**  The identifier of the route  */
  id: Scalars['ID'];
  /**  The name of the route ex: I-70  */
  name: Scalars['String'];
  /**  The native id of the route */
  nativeId: Scalars['String'];
  /**  The to measure of the route  */
  toMeasure?: Maybe<Scalars['Float']>;
};

export type LocationRouteConnection = {
  __typename?: 'LocationRouteConnection';
  edges: Array<LocationRouteConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LocationRouteConnectionEdge = {
  __typename?: 'LocationRouteConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: LocationRoute;
};

export type LocationRouteInput = {
  /**  The classification of the route  */
  classification: Scalars['String'];
  /**  The direction of the route  */
  direction: Scalars['String'];
  /**  The from measure of the route  */
  fromMeasure?: InputMaybe<Scalars['Float']>;
  /**  The geometry of the route  */
  geometry: Scalars['Object'];
  /**  The identifier of the route  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The name of the route ex: I-70  */
  name: Scalars['String'];
  /**  The native id of the route */
  nativeId?: InputMaybe<Scalars['String']>;
  /**  The to measure of the route  */
  toMeasure?: InputMaybe<Scalars['Float']>;
};

export type LocationRouteLandmark = {
  __typename?: 'LocationRouteLandmark';
  /**  The coordinate of the reference post  */
  geometry: Scalars['Object'];
  /**  The measure of the reference post  */
  measure: Scalars['Float'];
  /**  The name of the landmark  */
  name: Scalars['String'];
  /**  The native id of the route for the reference post */
  routeNativeId: Scalars['String'];
};

export type LocationRouteLandmarkConnection = {
  __typename?: 'LocationRouteLandmarkConnection';
  edges: Array<LocationRouteLandmarkConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LocationRouteLandmarkConnectionEdge = {
  __typename?: 'LocationRouteLandmarkConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: LocationRouteLandmark;
};

export type LocationRouteMeasure = {
  __typename?: 'LocationRouteMeasure';
  /**  The coordinate of the reference post  */
  geometry: Scalars['Object'];
  /**  The measure of the reference post  */
  measure: Scalars['Float'];
  /**  The native id of the route for the reference post */
  routeNativeId: Scalars['String'];
};

export type ManagedFile = {
  __typename?: 'ManagedFile';
  contentType: Scalars['String'];
  directoryId: Scalars['String'];
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  width?: Maybe<Scalars['Int']>;
};

export type ManagedFileConnection = {
  __typename?: 'ManagedFileConnection';
  edges: Array<ManagedFileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ManagedFileEdge = {
  __typename?: 'ManagedFileEdge';
  cursor: Scalars['String'];
  node: ManagedFile;
};

export type ManagedFileInput = {
  directoryId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ManagedFiles = {
  __typename?: 'ManagedFiles';
  managedFiles?: Maybe<Array<Maybe<ManagedFile>>>;
};

export type ManeuverColor = {
  __typename?: 'ManeuverColor';
  color?: Maybe<SignalOutputColor>;
  id: Scalars['String'];
};

export type MapConfig = {
  __typename?: 'MapConfig';
  geometry: Scalars['Object'];
  id: Scalars['ID'];
  layers: Array<Scalars['String']>;
  name: Scalars['String'];
  zoom?: Maybe<Scalars['Float']>;
};

export type MapConfigInput = {
  geometry: Scalars['Object'];
  id?: InputMaybe<Scalars['ID']>;
  layers: Array<Scalars['String']>;
  name: Scalars['String'];
  zoom?: InputMaybe<Scalars['Float']>;
};

export type MapLayerConfig = {
  __typename?: 'MapLayerConfig';
  baseLayer: Scalars['Boolean'];
  data: Scalars['Object'];
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MapLayerType;
  visible: Scalars['Boolean'];
};

export type MapLayerConfigInput = {
  baseLayer: Scalars['Boolean'];
  data: Scalars['Object'];
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  type: MapLayerType;
  visible: Scalars['Boolean'];
};

export enum MapLayerType {
  Geojson = 'GEOJSON',
  Quadkey = 'QUADKEY',
  Vector = 'VECTOR',
  Wms = 'WMS',
  Xyz = 'XYZ'
}

export type MibDefinition = {
  __typename?: 'MibDefinition';
  blockDataId: Scalars['Int'];
  blockDataType: Scalars['Int'];
  blockSupported: Scalars['Boolean'];
  dataType: DataTypes;
  hexadecimal: Scalars['Boolean'];
  indexes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name: Scalars['String'];
  numberOfBits: Scalars['Int'];
  oerType: OerType;
  oid: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  type: MibType;
};

export type MibIcon = {
  __typename?: 'MibIcon';
  iconId: Scalars['String'];
  mib?: Maybe<MibIndexed>;
  value?: Maybe<Scalars['String']>;
};

export type MibIndexed = {
  __typename?: 'MibIndexed';
  bitIndex?: Maybe<Scalars['Int']>;
  indexes?: Maybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
};

export enum MibType {
  BitField = 'BIT_FIELD',
  Integer = 'INTEGER',
  String = 'STRING',
  TableEntry = 'TABLE_ENTRY',
  Unknown = 'UNKNOWN'
}

export type MibValue = {
  bitIndex?: InputMaybe<Scalars['Int']>;
  indexes?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  shortName?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Object']>;
};

export type MibWithIndexes = {
  indexes: Array<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  oid?: InputMaybe<Scalars['String']>;
};

export type ModuleFormSection = FormSection & {
  __typename?: 'ModuleFormSection';
  fields: Array<Maybe<FieldConfig>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ModuleSettingsFormView = FormView & {
  __typename?: 'ModuleSettingsFormView';
  id: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<ModuleFormSection>;
  type: FormViewType;
  views: Array<FormView>;
};

export type MongoArchiveJob = {
  __typename?: 'MongoArchiveJob';
  config?: Maybe<ArchiveRuleConfig>;
  errorReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastArchivedAt?: Maybe<Scalars['DateTime']>;
  lastStartTimestamp?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MongoArchiveJobStatusCode>;
};

export enum MongoArchiveJobStatusCode {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**  Device Database Upload */
  _backupDeviceDatabase: JobRequest;
  /**  Get the camera auxiliary capabilities */
  _cameraCapabilities?: Maybe<JobRequest>;
  /**  Device Firmware Change */
  _changeDeviceFirmware: JobRequest;
  /**  Device Database Download */
  _downloadDeviceDatabase: JobRequest;
  /**  close gates  */
  _gateControllerClose: JobRequest;
  /**  open inbound gates  */
  _gateControllerOpenInbound: JobRequest;
  /**  open outbound gates  */
  _gateControllerOpenOutbound: JobRequest;
  /**  Get the signal mibs for a device. */
  _getSignalMibs: JobRequest;
  /**  Create a generic job */
  _runOneTimeJob: JobRequest;
  _sdl: Scalars['String'];
  /**  Set Signal Mibs */
  _setSignalMibs: JobRequest;
  /** Set Time Now */
  _setTimeNow: JobRequest;
  /**  Blank the display on the requested sign  */
  _signBlank: JobRequest;
  /**  Set the brightness level on a sign  */
  _signBrightness: JobRequest;
  /**  Check for sign capabilities  */
  _signCapabilities: JobRequest;
  /**  Delete graphic Definition  */
  _signDeleteGraphic: JobRequest;
  /**  Display the requested messages on the sign  */
  _signDisplay: JobRequest;
  /**  Download Graphic Definition  */
  _signDownloadGraphic: JobRequest;
  /**  Download Message Definition  */
  _signDownloadMessage: JobRequest;
  /**  Extend the currently displayed message  */
  _signExtend: JobRequest;
  /**  Run Fan Test  */
  _signFanTest: JobRequest;
  /**  Download the fonts installed on the controller  */
  _signFonts: JobRequest;
  /**  Run Lamp Test  */
  _signLampTest: JobRequest;
  /**  Run Pixel Test  */
  _signPixelTest: JobRequest;
  /**  Poll the requested sign  */
  _signPoll: JobRequest;
  /**  Reset a sign  */
  _signReset: JobRequest;
  /**  Upload Graphic Definition  */
  _signUploadGraphic: JobRequest;
  /**  Upload Message Definition  */
  _signUploadMessage: JobRequest;
  /**  Get the signal UI table for a device. */
  _signalGetUiTable: JobRequest;
  /**  Get the signal UI view for a device. */
  _signalGetUiView: JobRequest;
  _useCameraCapability?: Maybe<JobRequest>;
  /**  Acknowledge one or more active alarms  */
  acknowledgeAlarms: Array<Alarm>;
  acknowledgeNotification?: Maybe<Notification>;
  /**  activate the set of sign messages for the controller and type  */
  activateGateSignMessages: Array<JobRequest>;
  /**  Activate resources, data is optional and it should be a map of resource id to data  */
  activateResources: Array<JobRequest>;
  /**  Add an activity log. */
  addActivityLog: ActivityLog;
  /**  add gate operation activity  */
  addGateOperationActivity: Scalars['Boolean'];
  /**  Add a comment to an intersection */
  addIntersectionComment: IntersectionComment;
  /**  Admin unlock a saved database - unlocks a database lock made by another user  */
  adminUnlockDatabase: Scalars['Boolean'];
  /**  Admin unlock a live database - unlocks a database lock made by another user  */
  adminUnlockLiveDatabase: Scalars['Boolean'];
  /**  Promote a device database to approved. */
  approveDatabase: DeviceDatabase;
  /**  approve the given gate operation  */
  approveGateOperation: Scalars['Boolean'];
  /**  Associated comm logs with an event */
  associateCommLogsToEvent?: Maybe<Scalars['Boolean']>;
  /**  Associated comm logs with an events */
  associateCommLogsToEvents?: Maybe<Scalars['Boolean']>;
  /**  Associated detection with events */
  associateDetectionToEvents?: Maybe<Scalars['Boolean']>;
  /**  Associated detections with an event */
  associateDetectionsToEvent?: Maybe<Scalars['Boolean']>;
  associateEventsToCommLogs?: Maybe<Scalars['Boolean']>;
  /**  Associate event to detections */
  associateEventsToDetections?: Maybe<Scalars['Boolean']>;
  /**  Creates a database backup (upload) from the current active controller database */
  backupDeviceDatabase: DeviceDatabase;
  /**  Set the auto focus */
  cameraAutoFocus?: Maybe<JobRequest>;
  /**  Set the auto iris */
  cameraAutoIris?: Maybe<JobRequest>;
  cameraExplicitLock?: Maybe<Scalars['Boolean']>;
  /**  camera focus command */
  cameraFocus?: Maybe<JobRequest>;
  /**  camera go to preset */
  cameraGoToPreset?: Maybe<JobRequest>;
  /**  camera iris command */
  cameraIris?: Maybe<JobRequest>;
  /**  get the max presets of a camera */
  cameraMaxPresets?: Maybe<JobRequest>;
  cameraOnDemandLock?: Maybe<Scalars['Boolean']>;
  /**  camera pan tilt */
  cameraPanTilt?: Maybe<JobRequest>;
  /**  Poll the camera */
  cameraPoll?: Maybe<JobRequest>;
  cameraPublicFeed?: Maybe<JobRequest>;
  cameraPublicStreamTarget?: Maybe<JobRequest>;
  cameraRecording?: Maybe<JobRequest>;
  /**  Get the recent camera snapshot. */
  cameraSnapshot: JobRequest;
  /**  Start or stop saving camera snapshots */
  cameraSnapshots?: Maybe<JobRequest>;
  /**  Start camera stream recording */
  cameraStreamRecording?: Maybe<JobRequest>;
  /**  camera zoom */
  cameraZoom?: Maybe<JobRequest>;
  /**  Cancel a database backup or download action  */
  cancelDeviceDatabaseAction: Scalars['Boolean'];
  /**  cancel the given gate operation  */
  cancelGateOperation: Scalars['Boolean'];
  /**  Cancel a running job */
  cancelJob: Scalars['Boolean'];
  /**  Cancel task occurrence  */
  cancelTaskOccurrence: Scalars['Boolean'];
  changeTourState: Scalars['Boolean'];
  /**  Remove acknowledgement state from active alarms  */
  clearAlarmAcknowledgement: Array<Alarm>;
  /**  Clear one or more active alarms  */
  clearAlarms: Array<Alarm>;
  /**  Clear all active alarms for a specific source module  */
  clearAllAlarms: Scalars['Boolean'];
  /**  Clear all active alarms associated with a specific entity (e.g. intersection)  */
  clearAllAlarmsForEntity: Scalars['Boolean'];
  /**  Clears all detector alarms */
  clearAllDetectorAlarms?: Maybe<Scalars['Boolean']>;
  /**  Clears detector alarms for given id's */
  clearDetectorAlarms?: Maybe<Array<DetectorAlarm>>;
  /**  Clone an event  */
  cloneEvent: Event;
  /**  Close an event  */
  closeEvent?: Maybe<Scalars['Boolean']>;
  /**  close gates  */
  closeGates: JobRequest;
  /**
   * Copy a device database from one device to another as a draft.
   * Returns the new database copy associated with toIntersectionId.
   */
  copyDeviceDatabaseDraft: DeviceDatabase;
  /**  Copy a file to a new directory */
  copyFile: Scalars['Boolean'];
  /**  Create a draft database from a present database */
  createDraftDatabase: DeviceDatabase;
  /**  Create an event from a detection  */
  createEventFromDetection: Event;
  createKeycloakClient: KeycloakClient;
  createUser: OrganizationKineticUser;
  /**  delete action set rule  */
  deleteActionSetResponseRule: Scalars['Boolean'];
  /**  Delete an alarm config  */
  deleteAlarmConfig: Scalars['Boolean'];
  /** Delete all intersection comments */
  deleteAllIntersectionComments: Scalars['Boolean'];
  deleteCameraGroups: Scalars['Boolean'];
  deleteContact?: Maybe<Contact>;
  deleteContactGroup?: Maybe<ContactGroup>;
  /**  Delete an Corridor by id  */
  deleteCorridor?: Maybe<Scalars['Boolean']>;
  /**  Delete a response rule  */
  deleteCorridorRule?: Maybe<Scalars['Boolean']>;
  /**  delete data source form, will also delete associated data sources  */
  deleteDataSourceConfigs?: Maybe<Scalars['Boolean']>;
  /**  delete data sources  */
  deleteDataSources?: Maybe<Scalars['Boolean']>;
  /**  Delete a database export template  */
  deleteDatabaseExportTemplate: Scalars['Boolean'];
  /**  delete detector  email  alert setting */
  deleteDetectorEmailAlertSetting?: Maybe<Scalars['Boolean']>;
  /**  delete detector group  */
  deleteDetectorGroup: Scalars['Boolean'];
  deleteDetectorReportConfiguration?: Maybe<Scalars['Boolean']>;
  /**  deletes detector Report Rules */
  deleteDetectorReportRule?: Maybe<Scalars['Boolean']>;
  /**  Delete a draft or system database.  */
  deleteDeviceDatabase: DeviceDatabase;
  deleteEmailRule?: Maybe<Scalars['Boolean']>;
  deleteEmailRulesList?: Maybe<Scalars['Boolean']>;
  deleteEmailTemplate?: Maybe<Scalars['Boolean']>;
  deleteEmailTemplateList?: Maybe<Scalars['Boolean']>;
  /**  Delete an event template  */
  deleteEventTemplate?: Maybe<Scalars['Boolean']>;
  /**  Delete an event type  */
  deleteEventType?: Maybe<Scalars['Boolean']>;
  deleteFile: Scalars['Boolean'];
  /**  delete a gate controller sign message  */
  deleteGateControllerSignMessage: Scalars['Boolean'];
  /**  delete a gate control system  */
  deleteGateControllerSystem: Scalars['Boolean'];
  deleteGlobalFile: Scalars['Boolean'];
  deleteGlobalFiles: Scalars['Boolean'];
  /**  Delete an integration by id.  */
  deleteIntegration?: Maybe<Scalars['Boolean']>;
  /**  Delete integration group */
  deleteIntegrationGroup?: Maybe<Scalars['Boolean']>;
  /**  Delete an intersection by id.  */
  deleteIntersection: Scalars['Boolean'];
  /** Delete all intersection comment by id */
  deleteIntersectionComment: Scalars['Boolean'];
  /**  Delete an intersection template  */
  deleteIntersectionTemplate?: Maybe<Scalars['Boolean']>;
  /**  Delete a scheduled job */
  deleteJob: Scalars['Boolean'];
  /**  Delete the form fields by unique identifier  */
  deleteKineticFormFields?: Maybe<Scalars['Boolean']>;
  /**  Delete the forms by unique identifier  */
  deleteKineticForms?: Maybe<Scalars['Boolean']>;
  deleteKineticUserPreference: Scalars['Boolean'];
  /**  Delete lane control settings  */
  deleteLaneControlSettings: Scalars['Boolean'];
  /**  Delete Managed Files */
  deleteManagedFiles: Scalars['Boolean'];
  /**  Delete a map config */
  deleteMapConfig?: Maybe<Scalars['Boolean']>;
  /**  Delete a map layer config */
  deleteMapLayerConfig?: Maybe<Scalars['Boolean']>;
  deleteOrganizationContact?: Maybe<Scalars['Boolean']>;
  deleteOrganizationContactList?: Maybe<Scalars['Boolean']>;
  deleteOrganizationPreference: Scalars['Boolean'];
  deletePresets: Scalars['Boolean'];
  deleteRecordings: Scalars['Boolean'];
  /**  Delete a resource plan  */
  deleteResourcePlan: Scalars['Boolean'];
  /**  Delete resource plan task  */
  deleteResourcePlanTask: Scalars['Boolean'];
  /**  Remove resource from plan  */
  deleteResources: ResourcePlan;
  /**  Delete a response rule  */
  deleteResponseRule?: Maybe<Scalars['Boolean']>;
  deleteRole: Scalars['Boolean'];
  /**  Delete the route with the provided identifier  */
  deleteRoute?: Maybe<Scalars['Boolean']>;
  deleteScenes: Scalars['Boolean'];
  /**  Delete Scheduled Event task  */
  deleteScheduledEventTask: Scalars['Boolean'];
  deleteScreens: Scalars['Boolean'];
  /**  Delete sign cutout  */
  deleteSignCutout: Scalars['Boolean'];
  /**  Delete sign gantry  */
  deleteSignGantry: Scalars['Boolean'];
  /**  Delete sign message priorities  */
  deleteSignMessagePriorities: Scalars['Boolean'];
  /**  Delete sign messages */
  deleteSignMessages: Scalars['Boolean'];
  /**  Delete a response rule  */
  deleteSignResponseRule?: Maybe<Scalars['Boolean']>;
  /**  Delete an Action Set  */
  deleteSignalActionSet: Scalars['Boolean'];
  /**  Delete a signal corridor  */
  deleteSignalCorridor: Scalars['Boolean'];
  /**  Delete a signal corridor leg  */
  deleteSignalCorridorLeg: Scalars['Boolean'];
  /**  Delete a signal corridor link  */
  deleteSignalCorridorLink: Scalars['Boolean'];
  /**  Delete a detector group  */
  deleteSignalDetectorGroup: Scalars['Boolean'];
  /**  Delete a signal scheduled task  */
  deleteSignalScheduledTask: Scalars['Boolean'];
  deleteTours: Scalars['Boolean'];
  /**  Delete a traffic responsive plan  */
  deleteTrafficResponsivePlan: Scalars['Boolean'];
  /**  Delete an tunnel by id  */
  deleteTunnel?: Maybe<Scalars['Boolean']>;
  /**  Delete an tunnel log by id  */
  deleteTunnelLog?: Maybe<Scalars['Boolean']>;
  deleteUserGroup: Scalars['Boolean'];
  deleteUserPreference: Scalars['Boolean'];
  deleteVideoTask: Scalars['Boolean'];
  deleteWidgets: Scalars['Boolean'];
  /**  Returns true if config exists for timestamp */
  detectorConfigExists?: Maybe<Scalars['Boolean']>;
  deviceEventExport: Scalars['Boolean'];
  dismissNotification: Scalars['Boolean'];
  dismissNotifications: Scalars['Boolean'];
  /**  display a series of travel time messages  */
  displayTravelTimeMessages: Array<JobRequest>;
  /**  Send a database to a controller and activate it. */
  downloadDeviceDatabase: DeviceDatabase;
  enableCameraAutoFocus?: Maybe<JobRequest>;
  enableCameraAutoIris?: Maybe<JobRequest>;
  /** Enable or disable list of integrations */
  enableIntegrations?: Maybe<Array<Maybe<Integration>>>;
  /**  Expire resources  */
  expireResources: Array<JobRequest>;
  /**
   * Starts a background asynchronous export of activity
   * logs into the user's managed files.
   */
  exportActivityLogs: Scalars['Boolean'];
  /**  Export alarms matching the given query to the file manager  */
  exportAlarms: Scalars['Boolean'];
  /**  Export PVR matching the given query to the file manager  */
  exportDayWisePVRDetails: Scalars['Boolean'];
  /**  Extract a managed zip file into a destination path.  */
  extractZip: ExtractZipResults;
  /**  Finalizes a draft database and promotes it to all system databases. */
  finalizeDraftDeviceDatabase: DeviceDatabase;
  /**  Finish a signal scheduled task  */
  finishSignalScheduledTask: Scalars['Boolean'];
  focusCamera?: Maybe<JobRequest>;
  /**  save state for generic database protocol  */
  gateUpdateDatabaseState: Scalars['Boolean'];
  /**  Returns detector tmas report content */
  generateDetectorTMASReport?: Maybe<DetectorTmasReport>;
  /**  Generate Device Database Reports  */
  generateDeviceDatabaseReportExports: Scalars['Boolean'];
  /**  Generate report export and upload to My Downloads  */
  generateReportExport: ManagedFiles;
  gotoPreset?: Maybe<JobRequest>;
  /**  Ignore a detection for the current user. */
  ignoreDetections: Scalars['Boolean'];
  /**  import vehicles data. */
  importDetectorVehiclesData?: Maybe<Scalars['Boolean']>;
  /**  Initialize the My Files directory */
  initializeMyFilesDirectory: Directory;
  irisCamera?: Maybe<JobRequest>;
  /**  Link two events together  */
  linkEvent?: Maybe<Scalars['Boolean']>;
  /**  Load corridor devices by boundary  */
  loadDevices: Corridor;
  /**  Lock a saved database, requesting a lock on an already locked database will restart the unlock timer  */
  lockDatabase: Scalars['Boolean'];
  /**  Lock a live database, requesting a lock on an already locked database will restart the unlock timer  */
  lockLiveDatabase: Scalars['Boolean'];
  /**  set the current user to manage the gate control system  */
  manageGateSystem: GateOperationApproval;
  /**  Move a file to a new directory */
  moveFile: Scalars['Boolean'];
  /**  Multiple poll integrations" */
  multiplePoll?: Maybe<Array<JobRequest>>;
  /**  open inbound gates  */
  openInboundGates: JobRequest;
  /**  open outbound gates  */
  openOutboundGates: JobRequest;
  panTiltCamera?: Maybe<JobRequest>;
  /**  Connect/Disconnect camera stream file */
  publicStreamFile?: Maybe<JobRequest>;
  /**  Enable/Disable camera stream target */
  publicStreamTarget?: Maybe<JobRequest>;
  regenerateClientSecret: Scalars['Boolean'];
  /**  Reject an event  */
  rejectEvent?: Maybe<Scalars['Boolean']>;
  /**  reject the given gate operation  */
  rejectGateOperation: Scalars['Boolean'];
  /**  Remove comm logs associations from an event */
  removeAssociatedCommLogsFromEvent?: Maybe<Scalars['Boolean']>;
  /**  Remove comm logs associations from an events */
  removeAssociatedCommLogsFromEvents?: Maybe<Scalars['Boolean']>;
  /**  Remove detections associated with any events  */
  removeAssociatedDetectionsFromAllEvents?: Maybe<Scalars['Boolean']>;
  /**  Remove a detection associations from an event */
  removeAssociatedDetectionsFromEvent?: Maybe<Scalars['Boolean']>;
  /**  Remove a detection associations from events */
  removeAssociatedDetectionsFromEvents?: Maybe<Scalars['Boolean']>;
  /**  Internal Service Call only - Not to be used by UI  */
  removeAssociatedEventsFromAllCommLogs?: Maybe<Scalars['Boolean']>;
  /**  Remove an event from all detections  */
  removeAssociatedEventsFromAllDetections?: Maybe<Scalars['Boolean']>;
  removeAssociatedEventsFromCommLogs?: Maybe<Scalars['Boolean']>;
  /**  Remove associated events from detections */
  removeAssociatedEventsFromDetections?: Maybe<Scalars['Boolean']>;
  /**  Remove any messages for the signs with the association id  */
  removeAssociations?: Maybe<Array<JobRequest>>;
  removePartialOrganizationPreference: Scalars['JSON'];
  removePartialUserPreference: Scalars['JSON'];
  /**  Remove the provided messages in the sign queue  */
  removeQueuedMessages: JobRequest;
  /**  remove a series of travel time messages  */
  removeTravelTimeMessages: Array<JobRequest>;
  removeUser?: Maybe<Scalars['Boolean']>;
  removeUserPicture?: Maybe<Scalars['Boolean']>;
  removeUserPreferences: Scalars['Boolean'];
  reorderScreens?: Maybe<Scalars['Boolean']>;
  /**  Resets detector cache */
  resetDetectorCache?: Maybe<Scalars['Boolean']>;
  /**  Regenerates performance report */
  resetDetectorPerformanceReport?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  /**  create or update action set rule  */
  saveActionSetResponseRule: ActionSetRule;
  /**  Save event activation confirmation. True to require confirmation, False to not require confirmation.  */
  saveActivationConfirmation?: Maybe<Scalars['Boolean']>;
  /**  Save an alarm config  */
  saveAlarmConfig: AlarmConfig;
  /**  Save multiple alarm configs  */
  saveAlarmConfigs: Array<AlarmConfig>;
  /**  Save and Close an event  */
  saveAndCloseEvent?: Maybe<Scalars['Boolean']>;
  /**  Save atlas archive module settings  */
  saveAtlasArchiveModuleSettings?: Maybe<Scalars['Boolean']>;
  saveCameraGroup?: Maybe<CameraGroup>;
  /**  Connect preset with camera */
  saveCameraPreset?: Maybe<JobRequest>;
  saveCentralAuthPinSettings?: Maybe<CentralAuthPinSettings>;
  saveCommunicationLog: CommunicationLog;
  saveContact?: Maybe<Contact>;
  saveContactGroup?: Maybe<ContactGroupDto>;
  /**  Save an Corridor  */
  saveCorridor: Corridor;
  /**  Save a corridor rule  */
  saveCorridorRule: CorridorRule;
  /**  save data source  */
  saveDataSource: DataSource;
  /**  save data source form  */
  saveDataSourceConfig: DataSourceConfig;
  /**  Save a template for database exports  */
  saveDatabaseExportTemplate: DatabaseExportTemplate;
  /**  Saves detector  vehicle config */
  saveDetectorConfig?: Maybe<DetectorConfig>;
  /**  Saves detector  vehicle config */
  saveDetectorConnectionLog?: Maybe<Array<DetectorConnectionLog>>;
  /**  Saves detector  email  alert setting */
  saveDetectorEmailAlertSetting?: Maybe<DetectorEmailAlertSetting>;
  /**  Saves detector  extra  config */
  saveDetectorExtraConfig?: Maybe<DetectorConfig>;
  /**  create or update detector group  */
  saveDetectorGroup: DetectorGroup;
  /**  Saves detector Report Rule */
  saveDetectorReportConfiguration?: Maybe<DetectorReportConfiguration>;
  /**  Saves detector Report Rule */
  saveDetectorReportRule?: Maybe<DetectorReportRule>;
  saveEmailRule?: Maybe<EmailRuleDto>;
  saveEmailServerSettings: EmailServerSettings;
  saveEmailTemplate?: Maybe<EmailTemplateDto>;
  /**  Save an event  */
  saveEvent: Event;
  /**  Add a note to an event  */
  saveEventNote: Scalars['Boolean'];
  /**  Save an event template  */
  saveEventTemplate: EventTemplate;
  /**  Save an event type  */
  saveEventType: EventType;
  /**  Save events module settings  */
  saveEventsModuleSettings?: Maybe<Scalars['Boolean']>;
  /**  save gate controller sign messages  */
  saveGateControllerSignMessage: GateControllerSignMessage;
  /**  save a gate control system  */
  saveGateControllerSystem: GateControllerSystem;
  /**  Save gate module settings  */
  saveGateModuleSettings?: Maybe<Scalars['Boolean']>;
  /**  Saves hi trac log packets */
  saveHiTracLogPackets?: Maybe<Scalars['Boolean']>;
  /**  Saves hi trac vehicle packets */
  saveHiTracVehiclePackets?: Maybe<Scalars['Boolean']>;
  /**  Save an integration.  */
  saveIntegration?: Maybe<Integration>;
  /**  Save integration groups  */
  saveIntegrationGroup?: Maybe<IntegrationGroup>;
  /**  Save an intersection.  */
  saveIntersection: Intersection;
  /**  Save the Intersection Groups */
  saveIntersectionGroups: Array<IntersectionGroup>;
  /**  Save an intersection template  */
  saveIntersectionTemplate: IntersectionTemplate;
  /**  Save Signal module settings  */
  saveKineticAuthSettings?: Maybe<Scalars['Boolean']>;
  /**  Save the provided form  */
  saveKineticForm: KineticForm;
  /**  Save the provided form field  */
  saveKineticFormField: KineticFormField;
  saveKineticUserPreference: Scalars['JSON'];
  /**  Save lane control settings  */
  saveLaneControlSettings: LaneControlSettings;
  saveLicense: Scalars['Boolean'];
  /** Save a map config */
  saveMapConfig: MapConfig;
  /**  Save a map layer config. */
  saveMapLayerConfig: MapLayerConfig;
  /**  Save module-specific map setting  */
  saveModuleMapSettings?: Maybe<Scalars['Boolean']>;
  saveNotification?: Maybe<Notification>;
  saveNotificationPreferences?: Maybe<NotificationPreference>;
  saveOrganization?: Maybe<KineticOrganization>;
  saveOrganizationContact?: Maybe<OrganizationDto>;
  saveOrganizationPreference: Scalars['JSON'];
  savePreset?: Maybe<Preset>;
  /**  Save a resource plan  */
  saveResourcePlan: ResourcePlan;
  /**  Save resource plan task  */
  saveResourcePlanTask?: Maybe<Task>;
  /**  Save a Resource to plan  */
  saveResources: ResourcePlan;
  /**  Save response module settings  */
  saveResponseModuleSettings?: Maybe<Scalars['Boolean']>;
  /**  Save a response rule  */
  saveResponseRule: ResponseRule;
  saveRole: KineticRole;
  /**  Save a route with the provided input  */
  saveRoute: LocationRoute;
  saveScene?: Maybe<Scene>;
  /**  Save Scheduled Event task  */
  saveScheduledEventTask?: Maybe<Task>;
  saveScreen?: Maybe<Screen>;
  /**  Save sign cutout  */
  saveSignCutout: SignCutout;
  /**  Save sign gantry  */
  saveSignGantry: SignGantry;
  /**  Save sign message  */
  saveSignMessage?: Maybe<SignLibraryMessage>;
  /**  Save sign message priority  */
  saveSignMessagePriority?: Maybe<SignMessagePriority>;
  /**  Save sign module settings  */
  saveSignModuleSettings?: Maybe<Scalars['Boolean']>;
  /**  Save a response rule  */
  saveSignResponseRule: SignResponseRule;
  /**  Save an Action Set  */
  saveSignalActionSet: SignalActionSet;
  /**  Save a signal corridor  */
  saveSignalCorridor: SignalCorridor;
  /**  Save a signal corridor leg  */
  saveSignalCorridorLeg: SignalCorridorLeg;
  /**  Save a signal corridor link  */
  saveSignalCorridorLink: SignalCorridorLink;
  /**  Save a detector group  */
  saveSignalDetectorGroup: SignalDetectorGroup;
  /**  Save signal module settings  */
  saveSignalModuleSettings?: Maybe<Scalars['Boolean']>;
  /**  Save a signal scheduled task  */
  saveSignalScheduledTask?: Maybe<Task>;
  /**  Save the system Directories */
  saveSystemDirectories: Array<Directory>;
  saveTask?: Maybe<Task>;
  /**  Save the time drift settings */
  saveTimeDriftSettings: TimeDriftSettings;
  saveTour: Tour;
  /**  Save a traffic responsive plan  */
  saveTrafficResponsivePlan: TrafficResponsivePlan;
  /**  Save an tunnel  */
  saveTunnel: Tunnel;
  /**  Save an tunnel log */
  saveTunnelLog: TunnelLog;
  /**  Save the user Directories */
  saveUserDirectories: Array<Directory>;
  saveUserGroup: KineticUserGroup;
  saveUserPreference: Scalars['JSON'];
  saveVideoModuleSettings?: Maybe<Scalars['Boolean']>;
  saveWidget?: Maybe<Widget>;
  sendEmail: Scalars['Boolean'];
  /**  Sets alarms Acknowledge/Un Acknowledge */
  setAlarmAcknowledgeState?: Maybe<Array<DetectorAlarm>>;
  setAsHomePreset?: Maybe<Preset>;
  /**  Update Mibs on a device database  */
  setDatabaseMibs: DeviceDatabase;
  /**  Update lane control settings for the provided sign  */
  setSignLaneControlSettings: Scalars['Boolean'];
  /**  Set Mibs on a device  */
  setSignalMibs: Scalars['Boolean'];
  /**  Set a devices time to now */
  setTimeNow: Scalars['Boolean'];
  /**  Blank the requested signs  */
  signBlank: Array<JobRequest>;
  /**  Set the brightness  */
  signBrightness: JobRequest;
  /**  Check for sign capabilities  */
  signCapabilities: JobRequest;
  /**  delete sign group  */
  signDeleteGroup: Scalars['Boolean'];
  /**  Display the requested messages  */
  signDisplay: Array<JobRequest>;
  /**  Extend the currently displayed message  */
  signExtend: JobRequest;
  /**  Run a fan test  */
  signFanTest: JobRequest;
  /**  Download the fonts  */
  signFonts: JobRequest;
  /**  Run a lamp test  */
  signLampTest: JobRequest;
  /**  Display Lane Control  */
  signLaneControlDisplay: Array<JobRequest>;
  /**  Run a pixel test  */
  signPixelTest: JobRequest;
  /**  Poll the requested signs  */
  signPoll: Array<JobRequest>;
  /**  Reset a sign  */
  signReset: JobRequest;
  /**  save font  */
  signSaveFont: Scalars['Boolean'];
  /**  create or update sign group  */
  signSaveGroup: SignGroup;
  /**  set mapping of commands to slot  */
  signSetCommands: Scalars['Boolean'];
  /**  update default font  */
  signSetDefaultFont: Scalars['Boolean'];
  /**  update fonts  */
  signSetFonts: Scalars['Boolean'];
  /**  set sign message preferences  */
  signSetMessagePreferences: Scalars['Boolean'];
  /**  set sign speed settings  */
  signSetSpeedSettings: Scalars['Boolean'];
  /**  upload message definition  */
  signUploadMessage: JobRequest;
  /**  Subscribe to a list of mibs with an optional request id. Use the same request id to keep the current subscription alive. */
  subscribeMibs: JobRequest;
  /**  Sync used licenses. */
  syncIntegrationLicenses?: Maybe<Scalars['Boolean']>;
  syncLicense: Scalars['Boolean'];
  /**  toggle the bypassing restriction flag  */
  toggleGateRestrictionBypass: Scalars['Boolean'];
  /**  toggle the safe reversal time flag  */
  toggleGateSafeReversalTime: Scalars['Boolean'];
  tourHeartBeat: Scalars['Boolean'];
  /**  Poll the given travel time segments  */
  travelTimeSegmentPoll: Array<JobRequest>;
  /**  Unlink two events  */
  unlinkEvent?: Maybe<Scalars['Boolean']>;
  /**  Unlock a saved database  */
  unlockDatabase: Scalars['Boolean'];
  /**  Unlock a live database  */
  unlockLiveDatabase: Scalars['Boolean'];
  unlockPTZCamera?: Maybe<Scalars['Boolean']>;
  /**  unmanage the gate control system  */
  unmanageGateSystem: GateControllerSystem;
  /**  Update the comments associated with an alarm  */
  updateAlarmComments: Array<Alarm>;
  /**  confirm response if confirmation required  */
  updateCorridorConfirmation?: Maybe<Scalars['Boolean']>;
  /**  updates  detector  email  alert setting's status */
  updateDetectorEmailAlertSettingStatus?: Maybe<Scalars['Boolean']>;
  /**  update vehicle's AI class name */
  updateDetectorVehicleAIClassName?: Maybe<Scalars['Boolean']>;
  /**  update vehicle's AI Verified status */
  updateDetectorVehicleAIVerificationStatus?: Maybe<Scalars['Boolean']>;
  /**  update vehicle's verified class name. DetectorVehicle object will be used to determine status of verified class name icon. */
  updateDetectorVehicleVerifiedClassName?: Maybe<DetectorVehicle>;
  /**  update vehicle's verified class name status */
  updateDetectorVehicleVerifiedClassNameStatus?: Maybe<Scalars['Boolean']>;
  /**  Updates vehicle aggregate data */
  updateDetectorVehicleVolumeIntervalCounts?: Maybe<Scalars['Boolean']>;
  /**  Update archive flag on a device database  */
  updateDeviceDatabasesArchiveFlag: Scalars['Boolean'];
  updatePartialOrganizationPreference: Scalars['JSON'];
  updatePartialUserPreference: Scalars['JSON'];
  /**  Update a queued message and evaluate queue  */
  updateQueuedMessage: JobRequest;
  updateUserPrivileges: KineticUserPrivilegeAssignment;
  useCameraCapability?: Maybe<JobRequest>;
  /**  Poll the given weather stations  */
  weatherStationPoll: Array<JobRequest>;
  zoomCamera?: Maybe<JobRequest>;
};


export type Mutation_BackupDeviceDatabaseArgs = {
  deviceId: Scalars['String'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_CameraCapabilitiesArgs = {
  cameraId: Scalars['String'];
  requestId: Scalars['String'];
};


export type Mutation_ChangeDeviceFirmwareArgs = {
  deviceId: Scalars['String'];
  firmwareUrl: Scalars['String'];
  productName: Scalars['String'];
  productVersion: Scalars['String'];
  requestId?: InputMaybe<Scalars['String']>;
  timeout: Scalars['Int'];
};


export type Mutation_DownloadDeviceDatabaseArgs = {
  binFile?: InputMaybe<DeviceDatabaseFileRequest>;
  deviceId: Scalars['String'];
  jsonFile: DeviceDatabaseFileRequest;
  requestId?: InputMaybe<Scalars['String']>;
  tables?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Mutation_GateControllerCloseArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type Mutation_GateControllerOpenInboundArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type Mutation_GateControllerOpenOutboundArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type Mutation_GetSignalMibsArgs = {
  id: Scalars['String'];
  mibs: Array<MibWithIndexes>;
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_RunOneTimeJobArgs = {
  id: Scalars['String'];
  requestId: Scalars['String'];
  settings: Scalars['Object'];
};


export type Mutation_SetSignalMibsArgs = {
  id: Scalars['String'];
  ignoreMissingBitIndexes?: InputMaybe<Scalars['Boolean']>;
  mibs: Array<SetMibsInput>;
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SetTimeNowArgs = {
  deviceId: Scalars['String'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignBlankArgs = {
  blankArguments: SignBlankArguments;
};


export type Mutation_SignBrightnessArgs = {
  brightness?: InputMaybe<Scalars['Int']>;
  brightnessMode: SignBrightnessMode;
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignCapabilitiesArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignDeleteGraphicArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  slot: Scalars['Int'];
};


export type Mutation_SignDisplayArgs = {
  displayArguments: SignDisplayArguments;
};


export type Mutation_SignDownloadGraphicArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  slot: Scalars['Int'];
};


export type Mutation_SignDownloadMessageArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  slot: Scalars['Int'];
};


export type Mutation_SignExtendArgs = {
  expirationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignFanTestArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignFontsArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignLampTestArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignPixelTestArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignPollArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignResetArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_SignUploadGraphicArgs = {
  arguments: SignUploadGraphicArguments;
};


export type Mutation_SignUploadMessageArgs = {
  arguments: SignUploadMessageArguments;
};


export type Mutation_SignalGetUiTableArgs = {
  id: Scalars['String'];
  requestId?: InputMaybe<Scalars['String']>;
  tableName: Scalars['String'];
};


export type Mutation_SignalGetUiViewArgs = {
  id: Scalars['String'];
  requestId?: InputMaybe<Scalars['String']>;
};


export type Mutation_UseCameraCapabilityArgs = {
  cameraId: Scalars['String'];
  capability: CameraCapability;
  option: Scalars['String'];
};


export type MutationAcknowledgeAlarmsArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};


export type MutationAcknowledgeNotificationArgs = {
  id: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationActivateGateSignMessagesArgs = {
  id: Scalars['ID'];
  type: Scalars['ID'];
};


export type MutationActivateResourcesArgs = {
  data?: InputMaybe<Scalars['Object']>;
  id: Scalars['ID'];
  resources?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationAddActivityLogArgs = {
  input: ActivityLogInput;
};


export type MutationAddGateOperationActivityArgs = {
  activity: GateOperationActivityInput;
};


export type MutationAddIntersectionCommentArgs = {
  comment: Scalars['String'];
  intersectionId: Scalars['ID'];
};


export type MutationAdminUnlockDatabaseArgs = {
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationAdminUnlockLiveDatabaseArgs = {
  intersectionId: Scalars['String'];
};


export type MutationApproveDatabaseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationApproveGateOperationArgs = {
  id: Scalars['ID'];
};


export type MutationAssociateCommLogsToEventArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventId: Scalars['ID'];
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssociateCommLogsToEventsArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssociateDetectionToEventsArgs = {
  detectionId: Scalars['ID'];
  eventIds: Array<Scalars['ID']>;
};


export type MutationAssociateDetectionsToEventArgs = {
  detectionIds: Array<Scalars['ID']>;
  eventId: Scalars['ID'];
};


export type MutationAssociateEventsToCommLogsArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssociateEventsToDetectionsArgs = {
  detectionIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
};


export type MutationBackupDeviceDatabaseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  makeApproved?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCameraAutoFocusArgs = {
  enable: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationCameraAutoIrisArgs = {
  enable: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationCameraExplicitLockArgs = {
  cameraId: Scalars['ID'];
};


export type MutationCameraFocusArgs = {
  focusSpeed: Scalars['Int'];
  id: Scalars['String'];
};


export type MutationCameraGoToPresetArgs = {
  id: Scalars['String'];
  presetNumber: Scalars['Int'];
};


export type MutationCameraIrisArgs = {
  id: Scalars['String'];
  irisSpeed: Scalars['Int'];
};


export type MutationCameraMaxPresetsArgs = {
  id: Scalars['String'];
};


export type MutationCameraOnDemandLockArgs = {
  cameraId: Scalars['ID'];
};


export type MutationCameraPanTiltArgs = {
  id: Scalars['String'];
  panSpeed: Scalars['Int'];
  tiltSpeed: Scalars['Int'];
};


export type MutationCameraPollArgs = {
  id: Scalars['String'];
};


export type MutationCameraPublicFeedArgs = {
  cameraPublicFeedInput: CameraPublicFeedInput;
};


export type MutationCameraPublicStreamTargetArgs = {
  cameraPublicStreamTargetInput: CameraPublicStreamTargetInput;
};


export type MutationCameraRecordingArgs = {
  cameraStreamRecordingInput?: InputMaybe<CameraStreamRecordingInput>;
};


export type MutationCameraSnapshotArgs = {
  requestId: Scalars['String'];
  streamManagerId: Scalars['String'];
  streamName: Scalars['String'];
};


export type MutationCameraSnapshotsArgs = {
  action: StreamTagRequestAction;
  cameraId: Scalars['String'];
  incidentId: Scalars['String'];
};


export type MutationCameraStreamRecordingArgs = {
  cameraId: Scalars['String'];
  recordingInitiatedBy: RecordingInitiatedBy;
  recordingInitiatorDisplayName?: InputMaybe<Scalars['String']>;
  recordingInitiatorId?: InputMaybe<Scalars['String']>;
  recordingType: RecordingType;
  start: Scalars['Boolean'];
  streamAccessType: Scalars['String'];
  streamRecorderId: Scalars['String'];
};


export type MutationCameraZoomArgs = {
  id: Scalars['String'];
  zoomSpeed: Scalars['Int'];
};


export type MutationCancelDeviceDatabaseActionArgs = {
  databaseId: Scalars['ID'];
  intersectionId: Scalars['ID'];
};


export type MutationCancelGateOperationArgs = {
  id: Scalars['ID'];
};


export type MutationCancelJobArgs = {
  id: Scalars['String'];
  requestId: Scalars['String'];
};


export type MutationCancelTaskOccurrenceArgs = {
  date: Scalars['DateTime'];
  id: Scalars['ID'];
};


export type MutationChangeTourStateArgs = {
  paused?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['ID'];
};


export type MutationClearAlarmAcknowledgementArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};


export type MutationClearAlarmsArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};


export type MutationClearAllAlarmsArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  source: KineticModule;
};


export type MutationClearAllAlarmsForEntityArgs = {
  entityId: Scalars['ID'];
  entityType: AlarmEntityType;
};


export type MutationClearDetectorAlarmsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationCloneEventArgs = {
  id: Scalars['ID'];
};


export type MutationCloseEventArgs = {
  closureResolution?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lanesClear?: InputMaybe<Scalars['DateTime']>;
  sceneClear?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCloseGatesArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type MutationCopyDeviceDatabaseDraftArgs = {
  databaseId: Scalars['String'];
  fromIntersectionId: Scalars['String'];
  toIntersectionId: Scalars['String'];
};


export type MutationCopyFileArgs = {
  destinationDirectoryId: Scalars['String'];
  fileId: Scalars['String'];
};


export type MutationCreateDraftDatabaseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationCreateEventFromDetectionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  user: OrganizationKineticUserInput;
};


export type MutationDeleteActionSetResponseRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAlarmConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAllIntersectionCommentsArgs = {
  intersectionId: Scalars['ID'];
};


export type MutationDeleteCameraGroupsArgs = {
  groups: Array<Scalars['ID']>;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCorridorArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCorridorRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDataSourceConfigsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteDataSourcesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteDatabaseExportTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDetectorEmailAlertSettingArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDeleteDetectorGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDetectorReportConfigurationArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteDetectorReportRuleArgs = {
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteDeviceDatabaseArgs = {
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationDeleteEmailRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEmailRulesListArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEmailTemplateListArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteEventTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  module?: InputMaybe<KineticModule>;
};


export type MutationDeleteGateControllerSignMessageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGateControllerSystemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGlobalFileArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteGlobalFilesArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntegrationGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntersectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntersectionCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntersectionTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['String'];
  requestId: Scalars['String'];
};


export type MutationDeleteKineticFormFieldsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteKineticFormsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteKineticUserPreferenceArgs = {
  key: Scalars['ID'];
};


export type MutationDeleteLaneControlSettingsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteManagedFilesArgs = {
  fileIds: Array<Scalars['ID']>;
};


export type MutationDeleteMapConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMapLayerConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationContactArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationContactListArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteOrganizationPreferenceArgs = {
  key: Scalars['ID'];
};


export type MutationDeletePresetsArgs = {
  presets: Array<Scalars['ID']>;
};


export type MutationDeleteRecordingsArgs = {
  videoRecords?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDeleteResourcePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResourcePlanTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResourcesArgs = {
  id: Scalars['ID'];
  resources: Array<Scalars['ID']>;
};


export type MutationDeleteResponseRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRouteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteScenesArgs = {
  scenes: Array<Scalars['ID']>;
};


export type MutationDeleteScheduledEventTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteScreensArgs = {
  screens: Array<Scalars['ID']>;
};


export type MutationDeleteSignCutoutArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignGantryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignMessagePrioritiesArgs = {
  priorities: Array<Scalars['ID']>;
};


export type MutationDeleteSignMessagesArgs = {
  messages: Array<Scalars['ID']>;
};


export type MutationDeleteSignResponseRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalActionSetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalCorridorArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalCorridorLegArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalCorridorLinkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalDetectorGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignalScheduledTaskArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};


export type MutationDeleteToursArgs = {
  tours: Array<Scalars['ID']>;
};


export type MutationDeleteTrafficResponsivePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTunnelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTunnelLogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserPreferenceArgs = {
  key: Scalars['ID'];
};


export type MutationDeleteVideoTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationDeleteWidgetsArgs = {
  widgets: Array<Scalars['ID']>;
};


export type MutationDetectorConfigExistsArgs = {
  detectorId?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};


export type MutationDeviceEventExportArgs = {
  endDateTime: Scalars['DateTime'];
  fileName: Scalars['String'];
  intersectionId: Scalars['String'];
  rule?: InputMaybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationDismissNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDisplayTravelTimeMessagesArgs = {
  requests: Array<TravelTimeDisplayRequest>;
};


export type MutationDownloadDeviceDatabaseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
  tables?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEnableCameraAutoFocusArgs = {
  cameraAutoFocusInput: CameraAutoFocusInput;
};


export type MutationEnableCameraAutoIrisArgs = {
  cameraAutoIrisInput: CameraAutoIrisInput;
};


export type MutationEnableIntegrationsArgs = {
  enabled: Scalars['Boolean'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationExpireResourcesArgs = {
  id: Scalars['ID'];
  resources?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationExportActivityLogsArgs = {
  config: ExportConfig;
  filters: Array<FilterInput>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type MutationExportAlarmsArgs = {
  customDataKeys?: InputMaybe<Array<Scalars['String']>>;
  fileName: Scalars['String'];
  query?: InputMaybe<AlarmsQuery>;
};


export type MutationExportDayWisePvrDetailsArgs = {
  dateFormat?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<DetectorVehicleQuery>;
  unitType?: InputMaybe<ConversionUnitType>;
};


export type MutationExtractZipArgs = {
  fileId: Scalars['ID'];
  path: Scalars['String'];
};


export type MutationFinalizeDraftDeviceDatabaseArgs = {
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationFinishSignalScheduledTaskArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};


export type MutationFocusCameraArgs = {
  cameraFocusInput: CameraFocusInput;
};


export type MutationGateUpdateDatabaseStateArgs = {
  id: Scalars['ID'];
  position: GateArmPosition;
};


export type MutationGenerateDetectorTmasReportArgs = {
  query?: InputMaybe<DetectorTmasReportQuery>;
};


export type MutationGenerateDeviceDatabaseReportExportsArgs = {
  databaseId: Scalars['String'];
  exportReportTypes: Array<ExportReportType>;
  intersectionId: Scalars['String'];
  tableNames: Array<Scalars['String']>;
};


export type MutationGenerateReportExportArgs = {
  directoryId: Scalars['String'];
  exportTypes: Array<ExportReportType>;
  report: ExportReport;
  userId: Scalars['String'];
};


export type MutationGotoPresetArgs = {
  cameraGotoPresetInput: CameraGotoPresetInput;
};


export type MutationIgnoreDetectionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationImportDetectorVehiclesDataArgs = {
  dateFormat: Scalars['String'];
  fileIds: Array<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationIrisCameraArgs = {
  cameraIrisInput: CameraIrisInput;
};


export type MutationLinkEventArgs = {
  eventId: Scalars['ID'];
  link: EventLinkInput;
};


export type MutationLoadDevicesArgs = {
  id: Scalars['ID'];
};


export type MutationLockDatabaseArgs = {
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationLockLiveDatabaseArgs = {
  intersectionId: Scalars['String'];
};


export type MutationManageGateSystemArgs = {
  id: Scalars['ID'];
  operation: Scalars['ID'];
};


export type MutationMoveFileArgs = {
  destinationDirectoryId: Scalars['String'];
  fileId: Scalars['String'];
};


export type MutationMultiplePollArgs = {
  ids: Array<Scalars['ID']>;
  integrationType: IntegrationType;
};


export type MutationOpenInboundGatesArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type MutationOpenOutboundGatesArgs = {
  id: Scalars['ID'];
  requestId?: InputMaybe<Scalars['String']>;
  systemId: Scalars['ID'];
};


export type MutationPanTiltCameraArgs = {
  cameraPanTiltInput: CameraPanTiltInput;
};


export type MutationPublicStreamFileArgs = {
  cameraId: Scalars['String'];
  connect: Scalars['Boolean'];
  streamManagerId: Scalars['String'];
};


export type MutationPublicStreamTargetArgs = {
  cameraId: Scalars['String'];
  enable: Scalars['Boolean'];
  streamManagerId: Scalars['String'];
};


export type MutationRejectEventArgs = {
  id: Scalars['ID'];
  rejectionReasons: Array<Scalars['String']>;
};


export type MutationRejectGateOperationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveAssociatedCommLogsFromEventArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventId: Scalars['ID'];
};


export type MutationRemoveAssociatedCommLogsFromEventsArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAssociatedDetectionsFromAllEventsArgs = {
  detectionId: Array<Scalars['ID']>;
};


export type MutationRemoveAssociatedDetectionsFromEventArgs = {
  detectionIds: Array<Scalars['ID']>;
  eventId: Scalars['ID'];
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAssociatedDetectionsFromEventsArgs = {
  detectionIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAssociatedEventsFromAllCommLogsArgs = {
  eventIds: Array<Scalars['ID']>;
};


export type MutationRemoveAssociatedEventsFromAllDetectionsArgs = {
  eventIds: Array<Scalars['ID']>;
};


export type MutationRemoveAssociatedEventsFromCommLogsArgs = {
  commLogIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAssociatedEventsFromDetectionsArgs = {
  detectionIds: Array<Scalars['ID']>;
  eventIds: Array<Scalars['ID']>;
  internalCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAssociationsArgs = {
  associationId: Scalars['ID'];
  signs: Array<Scalars['ID']>;
};


export type MutationRemovePartialOrganizationPreferenceArgs = {
  key: Scalars['ID'];
  valueId?: InputMaybe<Scalars['String']>;
};


export type MutationRemovePartialUserPreferenceArgs = {
  key: Scalars['ID'];
  valueId?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveQueuedMessagesArgs = {
  id: Scalars['ID'];
  queuedMessages: Array<Scalars['ID']>;
};


export type MutationRemoveTravelTimeMessagesArgs = {
  requests: Array<Scalars['ID']>;
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationReorderScreensArgs = {
  screens: Array<ScreenOrderInput>;
};


export type MutationResetDetectorCacheArgs = {
  id: Scalars['String'];
};


export type MutationResetDetectorPerformanceReportArgs = {
  query?: InputMaybe<DetectorPerformanceReportQuery>;
};


export type MutationResetPasswordArgs = {
  id: Scalars['ID'];
  newPassword: Scalars['String'];
};


export type MutationSaveActionSetResponseRuleArgs = {
  input: ActionSetRuleInput;
};


export type MutationSaveActivationConfirmationArgs = {
  confirmationRequired: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationSaveAlarmConfigArgs = {
  input: AlarmConfigInput;
};


export type MutationSaveAlarmConfigsArgs = {
  input: Array<AlarmConfigInput>;
};


export type MutationSaveAndCloseEventArgs = {
  closureResolution?: InputMaybe<Scalars['String']>;
  input: EventInput;
  lanesClear?: InputMaybe<Scalars['DateTime']>;
  sceneClear?: InputMaybe<Scalars['DateTime']>;
};


export type MutationSaveAtlasArchiveModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveCameraGroupArgs = {
  input: CameraGroupInput;
};


export type MutationSaveCameraPresetArgs = {
  id: Scalars['String'];
  presetId: Scalars['String'];
  presetNumber: Scalars['Int'];
};


export type MutationSaveCentralAuthPinSettingsArgs = {
  pin: Scalars['String'];
};


export type MutationSaveCommunicationLogArgs = {
  input: CommunicationLogInput;
};


export type MutationSaveContactArgs = {
  contact: ContactInput;
};


export type MutationSaveContactGroupArgs = {
  contactGroup: ContactGroupInput;
};


export type MutationSaveCorridorArgs = {
  input: CorridorInput;
};


export type MutationSaveCorridorRuleArgs = {
  input: CorridorRuleInput;
};


export type MutationSaveDataSourceArgs = {
  input: DataSourceInput;
};


export type MutationSaveDataSourceConfigArgs = {
  input: DataSourceConfigInput;
};


export type MutationSaveDatabaseExportTemplateArgs = {
  input: DatabaseExportTemplateInput;
};


export type MutationSaveDetectorConfigArgs = {
  input?: InputMaybe<SaveDetectorConfigInput>;
};


export type MutationSaveDetectorConnectionLogArgs = {
  input?: InputMaybe<SaveDetectorConnectionLogInput>;
};


export type MutationSaveDetectorEmailAlertSettingArgs = {
  input?: InputMaybe<DetectorEmailAlertSettingInput>;
};


export type MutationSaveDetectorExtraConfigArgs = {
  input?: InputMaybe<SaveDetectorExtraConfigInput>;
};


export type MutationSaveDetectorGroupArgs = {
  input: DetectorGroupInput;
};


export type MutationSaveDetectorReportConfigurationArgs = {
  input?: InputMaybe<DetectorReportConfigurationInput>;
};


export type MutationSaveDetectorReportRuleArgs = {
  input?: InputMaybe<DetectorReportRuleInput>;
};


export type MutationSaveEmailRuleArgs = {
  emailRule: EmailRuleInput;
};


export type MutationSaveEmailServerSettingsArgs = {
  settings: EmailServerSettingsInput;
};


export type MutationSaveEmailTemplateArgs = {
  template: EmailTemplateInput;
};


export type MutationSaveEventArgs = {
  input: EventInput;
};


export type MutationSaveEventNoteArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationSaveEventTemplateArgs = {
  input: EventTemplateInput;
};


export type MutationSaveEventTypeArgs = {
  input: EventTypeInput;
};


export type MutationSaveEventsModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveGateControllerSignMessageArgs = {
  input: GateControllerSignMessageInput;
};


export type MutationSaveGateControllerSystemArgs = {
  input: GateControllerSystemInput;
};


export type MutationSaveGateModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveHiTracLogPacketsArgs = {
  input?: InputMaybe<SaveHiTracLogPacketsInput>;
};


export type MutationSaveHiTracVehiclePacketsArgs = {
  input?: InputMaybe<SaveHiTracVehiclePacketsInput>;
};


export type MutationSaveIntegrationArgs = {
  input: IntegrationsInput;
};


export type MutationSaveIntegrationGroupArgs = {
  input?: InputMaybe<IntegrationGroupInput>;
};


export type MutationSaveIntersectionArgs = {
  intersectionInput?: InputMaybe<IntersectionInput>;
};


export type MutationSaveIntersectionGroupsArgs = {
  input: Array<IntersectionGroupInput>;
  removedGroups: Array<Scalars['String']>;
};


export type MutationSaveIntersectionTemplateArgs = {
  input: IntersectionTemplateInput;
};


export type MutationSaveKineticAuthSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveKineticFormArgs = {
  form?: InputMaybe<KineticFormInput>;
};


export type MutationSaveKineticFormFieldArgs = {
  field?: InputMaybe<KineticFormFieldInput>;
};


export type MutationSaveKineticUserPreferenceArgs = {
  key: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationSaveLaneControlSettingsArgs = {
  input: LaneControlSettingsInput;
};


export type MutationSaveLicenseArgs = {
  key: Scalars['String'];
};


export type MutationSaveMapConfigArgs = {
  config: MapConfigInput;
};


export type MutationSaveMapLayerConfigArgs = {
  config: MapLayerConfigInput;
};


export type MutationSaveModuleMapSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveNotificationArgs = {
  notificationInput?: InputMaybe<NotificationInput>;
};


export type MutationSaveNotificationPreferencesArgs = {
  notificationPreferences?: InputMaybe<NotificationPreferenceInput>;
};


export type MutationSaveOrganizationArgs = {
  organization?: InputMaybe<KineticOrganizationInput>;
};


export type MutationSaveOrganizationContactArgs = {
  organization: OrganizationInput;
};


export type MutationSaveOrganizationPreferenceArgs = {
  key: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationSavePresetArgs = {
  input: PresetInput;
};


export type MutationSaveResourcePlanArgs = {
  input: ResourcePlanInput;
};


export type MutationSaveResourcePlanTaskArgs = {
  input: TaskInput;
};


export type MutationSaveResourcesArgs = {
  id: Scalars['ID'];
  resources: Array<ResourceInput>;
};


export type MutationSaveResponseModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveResponseRuleArgs = {
  input: ResponseRuleInput;
};


export type MutationSaveRoleArgs = {
  role?: InputMaybe<KineticRoleInput>;
};


export type MutationSaveRouteArgs = {
  input: LocationRouteInput;
};


export type MutationSaveSceneArgs = {
  input: SceneInput;
};


export type MutationSaveScheduledEventTaskArgs = {
  input: TaskInput;
};


export type MutationSaveScreenArgs = {
  input: ScreenInput;
};


export type MutationSaveSignCutoutArgs = {
  input: SignCutoutInput;
};


export type MutationSaveSignGantryArgs = {
  input: SignGantryInput;
};


export type MutationSaveSignMessageArgs = {
  input: SignLibraryMessageInput;
};


export type MutationSaveSignMessagePriorityArgs = {
  input: SignMessagePriorityInput;
};


export type MutationSaveSignModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveSignResponseRuleArgs = {
  input: SignResponseRuleInput;
};


export type MutationSaveSignalActionSetArgs = {
  input: SignalActionSetInput;
};


export type MutationSaveSignalCorridorArgs = {
  input: SignalCorridorInput;
};


export type MutationSaveSignalCorridorLegArgs = {
  input: SignalCorridorLegInput;
};


export type MutationSaveSignalCorridorLinkArgs = {
  input: SignalCorridorLinkInput;
};


export type MutationSaveSignalDetectorGroupArgs = {
  input: SignalDetectorGroupInput;
};


export type MutationSaveSignalModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveSignalScheduledTaskArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  input: TaskInput;
};


export type MutationSaveSystemDirectoriesArgs = {
  input: Array<DirectoryInput>;
  removedDirectories: Array<Scalars['String']>;
};


export type MutationSaveTaskArgs = {
  input: TaskInput;
};


export type MutationSaveTimeDriftSettingsArgs = {
  input: TimeDriftSettingsInput;
};


export type MutationSaveTourArgs = {
  input: TourInput;
};


export type MutationSaveTrafficResponsivePlanArgs = {
  input: TrafficResponsivePlanInput;
};


export type MutationSaveTunnelArgs = {
  input: TunnelInput;
};


export type MutationSaveTunnelLogArgs = {
  input: TunnelLogInput;
};


export type MutationSaveUserDirectoriesArgs = {
  input: Array<DirectoryInput>;
  removedDirectories: Array<Scalars['String']>;
};


export type MutationSaveUserGroupArgs = {
  userGroup: KineticUserGroupInput;
};


export type MutationSaveUserPreferenceArgs = {
  key: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationSaveVideoModuleSettingsArgs = {
  settings: Scalars['JSON'];
};


export type MutationSaveWidgetArgs = {
  input: WidgetInput;
};


export type MutationSendEmailArgs = {
  message: EmailMessage;
};


export type MutationSetAlarmAcknowledgeStateArgs = {
  acknowledge?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationSetAsHomePresetArgs = {
  homePresetInput: HomePresetInput;
};


export type MutationSetDatabaseMibsArgs = {
  comment?: InputMaybe<Scalars['String']>;
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
  mibs: Array<DatabaseMibValue>;
};


export type MutationSetSignLaneControlSettingsArgs = {
  id: Scalars['ID'];
  signId: Scalars['ID'];
};


export type MutationSetSignalMibsArgs = {
  comment?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  mibs: Array<MibValue>;
};


export type MutationSetTimeNowArgs = {
  deviceId: Scalars['ID'];
};


export type MutationSignBlankArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationSignBrightnessArgs = {
  brightness?: InputMaybe<Scalars['Int']>;
  brightnessMode: SignBrightnessMode;
  id: Scalars['ID'];
};


export type MutationSignCapabilitiesArgs = {
  id: Scalars['ID'];
};


export type MutationSignDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationSignDisplayArgs = {
  requests: Array<SignMessageDisplayRequest>;
};


export type MutationSignExtendArgs = {
  expirationTime: Scalars['DateTime'];
  id: Scalars['ID'];
};


export type MutationSignFanTestArgs = {
  id: Scalars['ID'];
};


export type MutationSignFontsArgs = {
  id: Scalars['ID'];
};


export type MutationSignLampTestArgs = {
  id: Scalars['ID'];
};


export type MutationSignLaneControlDisplayArgs = {
  requests: Array<SignLaneControlDisplayRequest>;
};


export type MutationSignPixelTestArgs = {
  id: Scalars['ID'];
};


export type MutationSignPollArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationSignResetArgs = {
  id: Scalars['ID'];
};


export type MutationSignSaveFontArgs = {
  font: SignFontInput;
};


export type MutationSignSaveGroupArgs = {
  input: SignGroupInput;
};


export type MutationSignSetCommandsArgs = {
  commands: Array<SignCommandNumberInput>;
  id: Scalars['ID'];
};


export type MutationSignSetDefaultFontArgs = {
  hash: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationSignSetFontsArgs = {
  fontNumbers: Array<SignFontNumberInput>;
  id: Scalars['ID'];
};


export type MutationSignSetMessagePreferencesArgs = {
  id: Scalars['ID'];
  preference?: InputMaybe<Scalars['ID']>;
};


export type MutationSignSetSpeedSettingsArgs = {
  id: Scalars['ID'];
  settings: SignSpeedSettingsInput;
};


export type MutationSignUploadMessageArgs = {
  id: Scalars['ID'];
  message: SignMessageInput;
  requestId?: InputMaybe<Scalars['String']>;
  slot: Scalars['Int'];
};


export type MutationSubscribeMibsArgs = {
  id: Scalars['String'];
  mibs: Array<MibWithIndexes>;
  onDemand?: InputMaybe<Scalars['Boolean']>;
  requestId?: InputMaybe<Scalars['String']>;
};


export type MutationToggleGateRestrictionBypassArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationToggleGateSafeReversalTimeArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationTourHeartBeatArgs = {
  tourId: Scalars['ID'];
};


export type MutationTravelTimeSegmentPollArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUnlinkEventArgs = {
  eventId: Scalars['ID'];
  linkId: Scalars['ID'];
};


export type MutationUnlockDatabaseArgs = {
  databaseId: Scalars['String'];
  intersectionId: Scalars['String'];
};


export type MutationUnlockLiveDatabaseArgs = {
  intersectionId: Scalars['String'];
};


export type MutationUnlockPtzCameraArgs = {
  cameraId: Scalars['ID'];
};


export type MutationUnmanageGateSystemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAlarmCommentsArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateCorridorConfirmationArgs = {
  confirmed: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationUpdateDetectorEmailAlertSettingStatusArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateDetectorVehicleAiClassNameArgs = {
  className?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDetectorVehicleAiVerificationStatusArgs = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDetectorVehicleVerifiedClassNameArgs = {
  classIndex: Scalars['Int'];
  id: Scalars['String'];
};


export type MutationUpdateDetectorVehicleVerifiedClassNameStatusArgs = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDetectorVehicleVolumeIntervalCountsArgs = {
  query?: InputMaybe<DetectorVehicleVolumeIntervalCountsClearQuery>;
};


export type MutationUpdateDeviceDatabasesArchiveFlagArgs = {
  archive: Scalars['Boolean'];
  databaseIds: Array<Scalars['String']>;
  intersectionId: Scalars['String'];
};


export type MutationUpdatePartialOrganizationPreferenceArgs = {
  key: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationUpdatePartialUserPreferenceArgs = {
  key: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationUpdateQueuedMessageArgs = {
  id: Scalars['ID'];
  queuedMessage: SignQueuedMessageInput;
};


export type MutationUpdateUserPrivilegesArgs = {
  user: KineticUserPrivilegeAssignmentInput;
};


export type MutationUseCameraCapabilityArgs = {
  cameraId: Scalars['ID'];
  capability: CameraCapability;
  option: Scalars['String'];
};


export type MutationWeatherStationPollArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationZoomCameraArgs = {
  cameraZoomInput: CameraZoomInput;
};

export type Notification = {
  __typename?: 'Notification';
  acknowledgedBy?: Maybe<Scalars['String']>;
  acknowledgedByName?: Maybe<Scalars['String']>;
  acknowledgedNotes?: Maybe<Scalars['String']>;
  acknowledgedTime?: Maybe<Scalars['DateTime']>;
  actions?: Maybe<Array<Maybe<Action>>>;
  createdTime?: Maybe<Scalars['DateTime']>;
  /**  Notification data  */
  data: Scalars['JSON'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ignorable?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['JSON']>;
  objectId?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  severity?: Maybe<NotificationSeverity>;
  source: KineticModule;
  systemNotification?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedTS?: Maybe<Scalars['DateTime']>;
  urgentNotification?: Maybe<Scalars['Boolean']>;
  userIds: Array<Maybe<Scalars['String']>>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NotificationConnectionEdge = {
  __typename?: 'NotificationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Notification;
};

export type NotificationInput = {
  actions?: InputMaybe<Array<InputMaybe<ActionInput>>>;
  /**  Notification data  */
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  ignorable?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['JSON']>;
  objectId?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<NotificationSeverity>;
  source: KineticModule;
  systemNotification?: InputMaybe<Scalars['Boolean']>;
  translationDetails: NotificationTranslationDetailsInput;
  updatedTS?: InputMaybe<Scalars['DateTime']>;
  urgentNotification?: InputMaybe<Scalars['Boolean']>;
  userIds: Array<InputMaybe<Scalars['String']>>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  location?: Maybe<Scalars['JSON']>;
  preferenceSourceList: Array<Maybe<NotificationPreferenceSource>>;
};

export type NotificationPreferenceInput = {
  location?: InputMaybe<Scalars['JSON']>;
  preferenceSourceList: Array<InputMaybe<NotificationPreferenceSourceInput>>;
};

export type NotificationPreferenceSource = {
  __typename?: 'NotificationPreferenceSource';
  severitySet: Array<Maybe<NotificationSeverity>>;
  source?: Maybe<KineticModule>;
};

export type NotificationPreferenceSourceInput = {
  severitySet: Array<InputMaybe<NotificationSeverity>>;
  source?: InputMaybe<KineticModule>;
};

export enum NotificationSeverity {
  /**  Critical Notification */
  Critical = 'CRITICAL',
  /**  Information only notification */
  Info = 'INFO',
  /**  Warning Notification */
  Warning = 'WARNING'
}

export enum NotificationSortField {
  Acknowledged = 'ACKNOWLEDGED',
  AcknowledgedBy = 'ACKNOWLEDGED_BY',
  AcknowledgedNotes = 'ACKNOWLEDGED_NOTES',
  Created = 'CREATED',
  Details = 'DETAILS',
  Severity = 'SEVERITY',
  Source = 'SOURCE',
  Title = 'TITLE',
  UpdatedTs = 'UPDATED_TS'
}

export type NotificationTranslationDetailsInput = {
  af?: InputMaybe<TranslatedNotification>;
  an?: InputMaybe<TranslatedNotification>;
  ar?: InputMaybe<TranslatedNotification>;
  as?: InputMaybe<TranslatedNotification>;
  ast?: InputMaybe<TranslatedNotification>;
  az?: InputMaybe<TranslatedNotification>;
  be?: InputMaybe<TranslatedNotification>;
  bg?: InputMaybe<TranslatedNotification>;
  bn?: InputMaybe<TranslatedNotification>;
  br?: InputMaybe<TranslatedNotification>;
  bs?: InputMaybe<TranslatedNotification>;
  ca?: InputMaybe<TranslatedNotification>;
  ce?: InputMaybe<TranslatedNotification>;
  ch?: InputMaybe<TranslatedNotification>;
  co?: InputMaybe<TranslatedNotification>;
  cr?: InputMaybe<TranslatedNotification>;
  cs?: InputMaybe<TranslatedNotification>;
  cv?: InputMaybe<TranslatedNotification>;
  cy?: InputMaybe<TranslatedNotification>;
  da?: InputMaybe<TranslatedNotification>;
  de?: InputMaybe<TranslatedNotification>;
  el?: InputMaybe<TranslatedNotification>;
  en: TranslatedNotification;
  eo?: InputMaybe<TranslatedNotification>;
  es?: InputMaybe<TranslatedNotification>;
  et?: InputMaybe<TranslatedNotification>;
  eu?: InputMaybe<TranslatedNotification>;
  fa?: InputMaybe<TranslatedNotification>;
  fi?: InputMaybe<TranslatedNotification>;
  fj?: InputMaybe<TranslatedNotification>;
  fo?: InputMaybe<TranslatedNotification>;
  fr?: InputMaybe<TranslatedNotification>;
  fur?: InputMaybe<TranslatedNotification>;
  fy?: InputMaybe<TranslatedNotification>;
  ga?: InputMaybe<TranslatedNotification>;
  gd?: InputMaybe<TranslatedNotification>;
  gl?: InputMaybe<TranslatedNotification>;
  gu?: InputMaybe<TranslatedNotification>;
  he?: InputMaybe<TranslatedNotification>;
  hi?: InputMaybe<TranslatedNotification>;
  hr?: InputMaybe<TranslatedNotification>;
  hsb?: InputMaybe<TranslatedNotification>;
  ht?: InputMaybe<TranslatedNotification>;
  hu?: InputMaybe<TranslatedNotification>;
  hy?: InputMaybe<TranslatedNotification>;
  id?: InputMaybe<TranslatedNotification>;
  is?: InputMaybe<TranslatedNotification>;
  it?: InputMaybe<TranslatedNotification>;
  iu?: InputMaybe<TranslatedNotification>;
  ja?: InputMaybe<TranslatedNotification>;
  ji?: InputMaybe<TranslatedNotification>;
  ka?: InputMaybe<TranslatedNotification>;
  kk?: InputMaybe<TranslatedNotification>;
  km?: InputMaybe<TranslatedNotification>;
  kn?: InputMaybe<TranslatedNotification>;
  ko?: InputMaybe<TranslatedNotification>;
  ks?: InputMaybe<TranslatedNotification>;
  ky?: InputMaybe<TranslatedNotification>;
  la?: InputMaybe<TranslatedNotification>;
  lb?: InputMaybe<TranslatedNotification>;
  lt?: InputMaybe<TranslatedNotification>;
  lv?: InputMaybe<TranslatedNotification>;
  mi?: InputMaybe<TranslatedNotification>;
  mk?: InputMaybe<TranslatedNotification>;
  ml?: InputMaybe<TranslatedNotification>;
  mo?: InputMaybe<TranslatedNotification>;
  mr?: InputMaybe<TranslatedNotification>;
  ms?: InputMaybe<TranslatedNotification>;
  mt?: InputMaybe<TranslatedNotification>;
  my?: InputMaybe<TranslatedNotification>;
  nb?: InputMaybe<TranslatedNotification>;
  ne?: InputMaybe<TranslatedNotification>;
  ng?: InputMaybe<TranslatedNotification>;
  nl?: InputMaybe<TranslatedNotification>;
  nn?: InputMaybe<TranslatedNotification>;
  no?: InputMaybe<TranslatedNotification>;
  nv?: InputMaybe<TranslatedNotification>;
  oc?: InputMaybe<TranslatedNotification>;
  om?: InputMaybe<TranslatedNotification>;
  or?: InputMaybe<TranslatedNotification>;
  pa?: InputMaybe<TranslatedNotification>;
  pl?: InputMaybe<TranslatedNotification>;
  pt?: InputMaybe<TranslatedNotification>;
  qu?: InputMaybe<TranslatedNotification>;
  rm?: InputMaybe<TranslatedNotification>;
  ro?: InputMaybe<TranslatedNotification>;
  ru?: InputMaybe<TranslatedNotification>;
  sa?: InputMaybe<TranslatedNotification>;
  sb?: InputMaybe<TranslatedNotification>;
  sc?: InputMaybe<TranslatedNotification>;
  sd?: InputMaybe<TranslatedNotification>;
  sg?: InputMaybe<TranslatedNotification>;
  si?: InputMaybe<TranslatedNotification>;
  sk?: InputMaybe<TranslatedNotification>;
  sl?: InputMaybe<TranslatedNotification>;
  so?: InputMaybe<TranslatedNotification>;
  sq?: InputMaybe<TranslatedNotification>;
  sr?: InputMaybe<TranslatedNotification>;
  sv?: InputMaybe<TranslatedNotification>;
  sw?: InputMaybe<TranslatedNotification>;
  sx?: InputMaybe<TranslatedNotification>;
  sz?: InputMaybe<TranslatedNotification>;
  ta?: InputMaybe<TranslatedNotification>;
  te?: InputMaybe<TranslatedNotification>;
  th?: InputMaybe<TranslatedNotification>;
  tig?: InputMaybe<TranslatedNotification>;
  tk?: InputMaybe<TranslatedNotification>;
  tlh?: InputMaybe<TranslatedNotification>;
  tn?: InputMaybe<TranslatedNotification>;
  tr?: InputMaybe<TranslatedNotification>;
  ts?: InputMaybe<TranslatedNotification>;
  tt?: InputMaybe<TranslatedNotification>;
  uk?: InputMaybe<TranslatedNotification>;
  ur?: InputMaybe<TranslatedNotification>;
  ve?: InputMaybe<TranslatedNotification>;
  vi?: InputMaybe<TranslatedNotification>;
  vo?: InputMaybe<TranslatedNotification>;
  wa?: InputMaybe<TranslatedNotification>;
  xh?: InputMaybe<TranslatedNotification>;
  zh?: InputMaybe<TranslatedNotification>;
  zu?: InputMaybe<TranslatedNotification>;
};

export type NotificationsQuery = {
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  notificationSortField?: InputMaybe<NotificationSortField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum OerType {
  FixString = 'FIX_STRING',
  Int = 'INT',
  Int_8 = 'INT_8',
  Int_16 = 'INT_16',
  Int_32 = 'INT_32',
  Oid = 'OID',
  Uint = 'UINT',
  Uint_8 = 'UINT_8',
  Uint_16 = 'UINT_16',
  Uint_32 = 'UINT_32',
  Unknown = 'UNKNOWN',
  VarString = 'VAR_STRING'
}

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrganizationConnectionEdge = {
  __typename?: 'OrganizationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: OrganizationDto;
};

export type OrganizationDto = {
  __typename?: 'OrganizationDto';
  cellNumber?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  email: Scalars['String'];
  faxNumber?: Maybe<Scalars['String']>;
  homeNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  otherNumber?: Maybe<Scalars['String']>;
  pagerNumber?: Maybe<Scalars['String']>;
  useAsContact?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  workNumber: Scalars['String'];
};

export type OrganizationInput = {
  cellNumber?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  faxNumber?: InputMaybe<Scalars['String']>;
  homeNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  otherNumber?: InputMaybe<Scalars['String']>;
  pagerNumber?: InputMaybe<Scalars['String']>;
  useAsContact?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
  workNumber: Scalars['String'];
};

export type OrganizationKineticUser = {
  __typename?: 'OrganizationKineticUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumbers: Array<Scalars['String']>;
  privilegeAssignmentList: Array<KineticPrivilegeAssignment>;
  roleIds: Array<Scalars['String']>;
  userGroupIds: Array<Scalars['String']>;
  username: Scalars['String'];
};

export type OrganizationKineticUserInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumbers: Array<Scalars['String']>;
  privilegeAssignmentList: Array<KineticPrivilegeAssignmentInput>;
  roleIds: Array<Scalars['String']>;
  temporaryPassword?: InputMaybe<Scalars['String']>;
  userGroupIds: Array<Scalars['String']>;
  username: Scalars['String'];
};

export type OrganizationPreference = {
  __typename?: 'OrganizationPreference';
  key: Scalars['ID'];
  value: Scalars['JSON'];
};

export type OrganizationPreferenceStreamObject = {
  __typename?: 'OrganizationPreferenceStreamObject';
  id: Scalars['ID'];
  organizationPreference: OrganizationPreference;
  preferenceKey: Scalars['String'];
  streamObjectOperation: StreamObjectOperation;
};

export type OrganizationQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type OrganizationStreamObject = {
  __typename?: 'OrganizationStreamObject';
  dto?: Maybe<OrganizationDto>;
  streamObjectOperation?: Maybe<StreamObjectOperation>;
};

export type OverlapStatus = {
  __typename?: 'OverlapStatus';
  color: SignalOutputColor;
  number: Scalars['Int'];
};

export type OverlayInfo = {
  __typename?: 'OverlayInfo';
  /**  Background fill color  */
  color?: Maybe<Scalars['String']>;
  /**  Labels  */
  label: Scalars['String'];
  /**  Opacity  */
  opacity?: Maybe<Scalars['Float']>;
  /**  Data Type  */
  type: DataType;
  x1: Scalars['String'];
  x2: Scalars['String'];
};

export enum OwnershipType {
  Personal = 'PERSONAL',
  System = 'SYSTEM'
}

export type PtzLockInfo = {
  __typename?: 'PTZLockInfo';
  fullName?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  lastUsedTime?: Maybe<Scalars['DateTime']>;
  lockEndsAt?: Maybe<Scalars['DateTime']>;
  lockStartedAt?: Maybe<Scalars['DateTime']>;
  requireExplicitCameraControlLocking?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum PatternType {
  Flash = 'FLASH',
  Free = 'FREE',
  Pattern = 'PATTERN',
  Unknown = 'UNKNOWN'
}

export enum PavementStatus {
  /**  Absorption  */
  Absorption = 'ABSORPTION',
  /**  Absorption At Dew Point  */
  AbsorptionAtDewpoint = 'ABSORPTION_AT_DEWPOINT',
  /**  Chemically Wet  */
  ChemicallyWet = 'CHEMICALLY_WET',
  /**  Dew  */
  Dew = 'DEW',
  /**  Dry  */
  Dry = 'DRY',
  /**  Error  */
  Error = 'ERROR',
  /**  Frost  */
  Frost = 'FROST',
  /**  Ice Warning  */
  IceWarning = 'ICE_WARNING',
  /**  Ice Watch  */
  IceWatch = 'ICE_WATCH',
  /**  Other  */
  Other = 'OTHER',
  /**  Snow Warning  */
  SnowWarning = 'SNOW_WARNING',
  /**  Snow Watch  */
  SnowWatch = 'SNOW_WATCH',
  /**  Trace Moisture  */
  TraceMoisture = 'TRACE_MOISTURE',
  /**  Wet  */
  Wet = 'WET'
}

export type PedStatus = {
  __typename?: 'PedStatus';
  color?: Maybe<SignalOutputColor>;
  hasCall: Scalars['Boolean'];
  number: Scalars['Int'];
};

export type PhaseServiceTimes = {
  __typename?: 'PhaseServiceTimes';
  green: Scalars['Int'];
  red: Scalars['Int'];
  yellow: Scalars['Int'];
};

export type PhaseStatus = {
  __typename?: 'PhaseStatus';
  actualSplit: Scalars['Int'];
  color?: Maybe<SignalOutputColor>;
  hasCall: Scalars['Boolean'];
  isNext: Scalars['Boolean'];
  lastTerm?: Maybe<PhaseTerminationReason>;
  number: Scalars['Int'];
  programmedSplit: Scalars['Int'];
  timeStampSecondForActualSplit?: Maybe<Scalars['Float']>;
};

export enum PhaseTerminationReason {
  Forceoff = 'FORCEOFF',
  Gapout = 'GAPOUT',
  Max1 = 'MAX1',
  Max2 = 'MAX2',
  Max3 = 'MAX3',
  Preempt = 'PREEMPT',
  Splitmax = 'SPLITMAX',
  Unknown = 'UNKNOWN',
  Yield = 'YIELD'
}

export type Position = {
  __typename?: 'Position';
  /**  The horizontal position  */
  x?: Maybe<Scalars['Int']>;
  /**  The vertical position  */
  y?: Maybe<Scalars['Int']>;
};

export type PositionInput = {
  /**  The horizontal position  */
  x?: InputMaybe<Scalars['Int']>;
  /**  The vertical position  */
  y?: InputMaybe<Scalars['Int']>;
};

export enum PrecipitationSituation {
  /**  Frozen Heavy  */
  FrozenHeavy = 'FROZEN_HEAVY',
  /**  Frozen Moderate  */
  FrozenModerate = 'FROZEN_MODERATE',
  /**  Frozen Slight  */
  FrozenSlight = 'FROZEN_SLIGHT',
  /**  Not Available  */
  NotAvailable = 'NOT_AVAILABLE',
  /**  No Precipitation  */
  NoPrecipitation = 'NO_PRECIPITATION',
  /**  Other  */
  Other = 'OTHER',
  /**  Rain Heavy  */
  RainHeavy = 'RAIN_HEAVY',
  /**  Rain Moderate  */
  RainModerate = 'RAIN_MODERATE',
  /**  Rain Slight  */
  RainSlight = 'RAIN_SLIGHT',
  /**  Snow Heavy  */
  SnowHeavy = 'SNOW_HEAVY',
  /**  Snow Moderate  */
  SnowModerate = 'SNOW_MODERATE',
  /**  Snow Slight  */
  SnowSlight = 'SNOW_SLIGHT',
  /**  Unidentified Heavy  */
  UnidentifiedHeavy = 'UNIDENTIFIED_HEAVY',
  /**  Unidentified Moderate  */
  UnidentifiedModerate = 'UNIDENTIFIED_MODERATE',
  /**  Unidentified Slight  */
  UnidentifiedSlight = 'UNIDENTIFIED_SLIGHT',
  /**  Unknown  */
  Unknown = 'UNKNOWN'
}

export type PreemptStatus = {
  __typename?: 'PreemptStatus';
  active: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  mibValue?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
};

export type Preset = {
  __typename?: 'Preset';
  id: Scalars['ID'];
  isHomePreset?: Maybe<Scalars['Boolean']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  presetIndex: Scalars['Int'];
};

export type PresetInput = {
  cameraId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
};

export enum PrivilegeType {
  Admin = 'ADMIN',
  AlarmAck = 'ALARM_ACK',
  Blank = 'BLANK',
  Configure = 'CONFIGURE',
  Control = 'CONTROL',
  Create = 'CREATE',
  Delete = 'DELETE',
  DevicePlans = 'DEVICE_PLANS',
  EditDatabase = 'EDIT_DATABASE',
  EventResponse = 'EVENT_RESPONSE',
  ManageRecordings = 'MANAGE_RECORDINGS',
  NotificationAck = 'NOTIFICATION_ACK',
  Poll = 'POLL',
  Post = 'POST',
  Ptz = 'PTZ',
  PtzOverrideLock = 'PTZ_OVERRIDE_LOCK',
  SuperviseGates = 'SUPERVISE_GATES',
  SyncTime = 'SYNC_TIME',
  Update = 'UPDATE',
  View = 'VIEW'
}

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  /**  Get a 511 message by id - used by schema stitching */
  _fiveOneOneMessage?: Maybe<FiveOneOneMessage>;
  /**  stitch the intersection entity into the alarm config  */
  _intersectionAlarm?: Maybe<Intersection>;
  /**  plain sdl directive for schema stitching  */
  _sdl: Scalars['String'];
  _service?: Maybe<_Service>;
  _signalCommandTasks: Array<SignalCommandTask>;
  _signalScheduledTasks?: Maybe<Array<Task>>;
  /**  Fetch action set rule by identifier  */
  actionSetResponseRule: ActionSetRule;
  /**  Fetch all configured action set rules  */
  actionSetResponseRules: ActionSetRuleConnection;
  /**  Get a page list of devices tied to activity logs */
  activityLogNames: ActivityLogNameConnection;
  /**  Get a page list of activity logs */
  activityLogs: ActivityLogConnection;
  /**  aggregate data by defining a MongoDB pipeline directly */
  aggregate?: Maybe<Scalars['JSON']>;
  /**  Gets the configuration for an alarm type */
  alarmConfig: AlarmConfig;
  /**  Search for system alarm configurations  */
  alarmConfigs: AlarmConfigsConnection;
  /**  Gets all alarm configs for the given entity  */
  alarmConfigsForEntity: Array<AlarmConfig>;
  /**  Search for active and recent alarms  */
  alarms: AlarmConnection;
  /**  Get all intersection icon statuses */
  allIntersectionIconStatus: Array<IntersectionIconStatus>;
  allOrganizations: Array<KineticOrganization>;
  /**  Get all resource plan tasks  */
  allResourcePlanTasks?: Maybe<Array<Task>>;
  /**  Get all scheduled event plan tasks for a period  */
  allScheduledEventTasks?: Maybe<Array<DateTasks>>;
  /**  Get a list of pageable areas */
  areas: LocationAreaConnection;
  /**  Return the status of the associated message in the queue  */
  associatedMessageQueueStatus: SignMessageQueueStatus;
  /**  Get the atlas archive module settings  */
  atlasArchiveModuleSettings: ModuleSettingsFormView;
  /**  Get ATSPM Report  */
  atspmReport: Report;
  camera?: Maybe<Camera>;
  cameraCapabilities?: Maybe<Array<CameraCapabilityDto>>;
  cameraFutureTasks: Array<Task>;
  cameraGroup?: Maybe<CameraGroup>;
  cameraGroups: Array<CameraGroup>;
  cameraMapEntities: Scalars['JSON'];
  cameraMapEntityIds: Array<Scalars['String']>;
  /**  Get camera presets by camera (integration) ID */
  cameraPresets?: Maybe<Array<Maybe<Scalars['String']>>>;
  cameraPrivateStream?: Maybe<VideoStream>;
  cameras: CameraConnection;
  centralAuthPinSettings?: Maybe<CentralAuthPinSettings>;
  communicationLog?: Maybe<CommunicationLog>;
  communicationLogs: CommunicationLogConnection;
  contact?: Maybe<Contact>;
  contactGroup?: Maybe<ContactGroupDto>;
  contactGroupsQuery: ContactGroupConnection;
  contacts: ContactConnection;
  contactsByIds?: Maybe<Array<Maybe<Contact>>>;
  contactsGroupByIds?: Maybe<Array<Maybe<ContactGroupDto>>>;
  contactsOfOrganization: ContactConnection;
  contactsToBeAssigned: ContactConnection;
  /**  Fetch a Corridor by identifier  */
  corridor: Corridor;
  /**  Get corridor rule by id  */
  corridorRule?: Maybe<CorridorRule>;
  /**  Fetch corridor rule config  */
  corridorRuleConfig: Scalars['Object'];
  /**  Get a pageable list of corridor rules.  */
  corridorRules: CorridorRuleConnection;
  /**  Fetch a pageable list of Corridors  */
  corridors: CorridorConnection;
  /**  Fetch data source  */
  dataSource: DataSource;
  /**  Fetch data source config  */
  dataSourceConfig: DataSourceConfig;
  /**  Fetch data source configs  */
  dataSourceConfigs: DataSourceConfigConnection;
  /**  Fetch data sources  */
  dataSources: DataSourceConnection;
  /**  Retrieve a template by ID  */
  databaseExportTemplate: DatabaseExportTemplate;
  /**  Get all database export templates  */
  databaseExportTemplates: Array<DatabaseExportTemplate>;
  /**  Get a database lock for a given database, if one exists  */
  databaseLock?: Maybe<DeviceDatabaseLock>;
  detection?: Maybe<Detection>;
  detections: DetectionConnection;
  /**  Get detector by id */
  detector: Detector;
  /**  Returns list of activity for given detector */
  detectorActivities: DetectorActivityConnection;
  /**  Returns list of ai classes */
  detectorAiClass?: Maybe<Array<DetectorAiClass>>;
  /**  Returns list of detector alarm groups */
  detectorAlarmGroups?: Maybe<Array<DetectorAlarmGroup>>;
  /**  Returns list of detector alarms sub types */
  detectorAlarmSubtypes?: Maybe<Array<DetectorAlarmSubtype>>;
  /**  Returns list of detector alarms types */
  detectorAlarmTypes?: Maybe<Array<DetectorAlarmType>>;
  /**  Returns list of alarms */
  detectorAlarms: DetectorAlarmConnection;
  /**  Returns list of detector alert types */
  detectorAlertTypes?: Maybe<Array<FieldConfigOption>>;
  /**  Returns list of battery info for given detector */
  detectorBattery: Array<DetectorBattery>;
  /**  Returns list of detector classes configuration */
  detectorClassConfiguration?: Maybe<Array<DetectorClass>>;
  /**  Returns list of detector classes */
  detectorClasses?: Maybe<Array<DetectorClass>>;
  /** Return latest config for detector id */
  detectorConfig?: Maybe<DetectorConfig>;
  /** Return config at particular time for detector id */
  detectorConfigAtTime?: Maybe<DetectorConfig>;
  /**  Returns list of connection log details for given detector */
  detectorConnectionLogDetails: DetectorConnectionLogDetailsConnection;
  /**  Returns list of detector connection log levels */
  detectorConnectionLogLevels?: Maybe<Array<FieldConfigOption>>;
  /**  Returns list of connection log for given detector */
  detectorConnectionLogs: DetectorConnectionLogConnection;
  /**  Returns list of email alert frequencies */
  detectorEmailAlertFrequencies?: Maybe<Array<DetectorAlertFrequencyOption>>;
  /**  Returns a  Detector Email Alert Setting for a given setting id number */
  detectorEmailAlertSetting?: Maybe<DetectorEmailAlertSetting>;
  /**  Returns list of Detector Email Alert Settings */
  detectorEmailAlertSettings?: Maybe<Array<DetectorEmailAlertSetting>>;
  /**  Returns list of email alert subtypes */
  detectorEmailAlertSubtypes?: Maybe<Array<DetectorAlertSubtypeOption>>;
  /**  Returns list of email alert types */
  detectorEmailAlertTypes?: Maybe<Array<DetectorAlertTypeOption>>;
  /**  Fetch Detector group by identifier  */
  detectorGroup?: Maybe<DetectorGroup>;
  /**  Fetch all configured detector groups  */
  detectorGroups: DetectorGroupConnection;
  /**  Returns list of detector log levels */
  detectorLogLevels?: Maybe<Array<FieldConfigOption>>;
  /**  Returns list of detector log message */
  detectorLogMessages?: Maybe<Array<FieldConfigOption>>;
  /**  Returns list of detector log sub types */
  detectorLogSubtypes?: Maybe<Array<FieldConfigOption>>;
  /**  Return the detectors to show on the map. */
  detectorMapEntities: Array<Scalars['JSON']>;
  /**  Returns performance report */
  detectorPerformanceReport: Array<DetectorPerformanceReportResult>;
  /**  Returns Performance Report Availability */
  detectorPerformanceReportAvailability: Array<DetectorPerformanceReportDayWiseAvailabilityInfo>;
  /**  Get detector ip and port */
  detectorReceiverIpPort?: Maybe<DetectorReceiverIpPort>;
  /**  Get report rule by id  */
  detectorReportConfiguration?: Maybe<DetectorReportConfiguration>;
  /**  Gets detector report rules */
  detectorReportConfigurations: DetectorReportConfigurationConnection;
  /**  Gets detector report  by template */
  detectorReportForm?: Maybe<DetectorReportRuleForm>;
  /** Returns Report Query Config */
  detectorReportFormQueryConfig?: Maybe<Scalars['Object']>;
  /**  Get report rule by id  */
  detectorReportRule?: Maybe<DetectorReportRule>;
  /**  Gets detector report rules */
  detectorReportRules: DetectorReportRuleConnection;
  /**  Gets detector report templates */
  detectorReportTemplateOptions: Array<DetectorReportTemplateOption>;
  /** Returns turning report for a site  */
  detectorTurningReport: DetectorTurningReport;
  /**  Returns axle weight chart details */
  detectorVehicleAxleWeightChart: Array<DetectorVehicleAxleWeightChart>;
  /**  Returns vehicle day wise availability */
  detectorVehicleDayWiseAvailability: Array<DetectorVehicleDayWiseAvailability>;
  /**  Returns vehicle day wise partial data availability */
  detectorVehicleDayWisePartialData: Array<DetectorVehicleDayWisePartialData>;
  /**  Returns detectorVehicleDetail for given vehicle ID */
  detectorVehicleDetail: DetectorVehicle;
  /**  Returns speed bin volume data for detector between 2 DateTimes */
  detectorVehicleSpeedBinVolume: Array<DetectorVehicleSpeedBinReport>;
  /** Return detector speedReport */
  detectorVehicleSpeedReport?: Maybe<DetectorVehicleSpeedBinReportResult>;
  /**  Returns timeline series data of vehicle volume */
  detectorVehicleVolume: Array<Maybe<DetectorVehicleVolumeGroupInfo>>;
  /**  Returns volume for given query */
  detectorVehicleVolumeCount: Scalars['Int'];
  /**  Returns volumes for groups */
  detectorVehicleVolumeGroupCounts: Array<DetectorVehicleVolumeCount>;
  /**  Returns vehicle intervals volume */
  detectorVehicleVolumeIntervalCounts: Array<DetectorVehicleVolumeIntervalCount>;
  /**  Returns vehicle yearly summary */
  detectorVehicleYearlySummary: Array<DetectorVehicleYearlySummary>;
  /**  Returns list of vehicles for given detector */
  detectorVehicles: DetectorVehicleConnection;
  /**  Returns count of vehicles for given filter */
  detectorVehiclesCount: Scalars['Int'];
  /**  Returns list of verified class names */
  detectorVerificationClass: Array<DetectorVerificationClass>;
  /**  Returns detector weather info */
  detectorWeather: Array<DetectorWeather>;
  /**  Get a pageable list of detectors */
  detectors: DetectorConnection;
  /**  Fetch a device database by id */
  deviceDatabase?: Maybe<DeviceDatabase>;
  /**  Compare the diff between two device databases  */
  deviceDatabaseComparison: DeviceDatabaseComparison;
  /**  Fetch deviceDatabaseMibChangeLog by intersection id and filter */
  deviceDatabaseMibChangeLogs: DeviceDatabaseMibChangeLogConnection;
  /**  Fetch device databases by device id and filter */
  deviceDatabases: DeviceDatabaseConnection;
  /**  Fetch device events by device id and filter */
  deviceEvents: DeviceEventConnection;
  /**  Gets an entire database views from a device of database file */
  deviceUI?: Maybe<IntersectionDeviceUi>;
  /**  Get an entire database table from a device or database file  */
  deviceUITable?: Maybe<IntersectionDeviceUiTable>;
  deviceViews?: Maybe<DeviceViewsUi>;
  effectivePrivileges: Array<KineticEffectivePrivilegeAssignment>;
  emailRule: EmailRuleDto;
  emailServerSettings?: Maybe<EmailServerSettings>;
  emailTemplate: EmailTemplateDto;
  emailsRule: EmailRuleConnection;
  emailsTemplate: EmailTemplateConnection;
  /**  Get event by id  */
  event?: Maybe<Event>;
  /**  Get current event jurisdiction filters  */
  eventJurisdictionFilters: Array<Scalars['String']>;
  /**  Get current event road filters  */
  eventRoadFilters: Array<Scalars['String']>;
  /**  Fetch query builder config for event rule form  */
  eventRuleQueryConfig: Scalars['Object'];
  /**  Get the event summary report  */
  eventSummaryReport?: Maybe<Scalars['String']>;
  /**  Get event template by id  */
  eventTemplate: EventTemplate;
  /**  Get a list of event templates  */
  eventTemplates: EventTemplateConnection;
  /**  Get an event type by id  */
  eventType: EventType;
  /**  Get current event type filters  */
  eventTypeFilters: Array<Scalars['String']>;
  /**  Get a list of event types  */
  eventTypes: EventTypeConnection;
  /**  Get a pageable list of events.  */
  events: EventConnection;
  /**  Get the events module settings  */
  eventsModuleSettings: ModuleSettingsFormView;
  /**  fetch a configured gate controller  */
  gateController: GateController;
  /**  fetch a configured gate controller  */
  gateControllerSignMessage: GateControllerSignMessage;
  /**  fetch a list of configured gate controllers  */
  gateControllerSignMessages: GateControllerSignMessageConnection;
  /**  fetch a gate control system  */
  gateControllerSystem: GateControllerSystem;
  /**  fetch a list of gate control systems  */
  gateControllerSystems: GateControllerSystemConnection;
  /**  fetch a list of configured gate controllers  */
  gateControllers: GateControllerConnection;
  /**  fetch state for generic database protocol  */
  gateDatabaseState: GateDatabaseState;
  /**  Get the gate module settings  */
  gateModuleSettings: ModuleSettingsFormView;
  /**  fetch a configured gate operation approval  */
  gateOperationApproval: GateOperationApproval;
  /**  fetch a list of current operation approval requests  */
  gateOperationApprovals: GateOperationApprovalConnection;
  /**  Return the linestring for the segment  */
  geocodeSegment?: Maybe<Scalars['Object']>;
  getCameraSnapshot?: Maybe<Scalars['String']>;
  /**  Gets vehicle data  import progress information " */
  getDetectorVehicleImportProgressInfo: DetectorVehicleImportProgress;
  /**  Gets file information  */
  getFileInfo: File;
  getFileTags: Array<Scalars['String']>;
  /**  Gets a list of files  */
  getFiles: FileConnection;
  /**  Get Global Files */
  getGlobalFiles: GlobalFileConnection;
  getKineticUser?: Maybe<OrganizationKineticUser>;
  /**  Retrieve a managed file by ID  */
  getManagedFile?: Maybe<ManagedFile>;
  /**  Gets a list of managed files  */
  getManagedFiles: ManagedFileConnection;
  /**  Fetch all Managed Files for a directory */
  getManagedFilesByDirectory: Array<ManagedFile>;
  /**  Get the parent group for the given id */
  getParentGroup?: Maybe<FlatIntersectionGroup>;
  /**  Get System Directories */
  getSystemDirectories: Array<Directory>;
  /**  Get tenant integration by externalId  */
  getTenantByExternalId?: Maybe<TenantIntegration>;
  /**  Get User Directories */
  getUserDirectories: Array<Directory>;
  /**  Pulls a managed zip file and returns filenames within the zip.  */
  getZipFilenames: Array<Scalars['String']>;
  gitHash: Scalars['String'];
  /**  Tells if an intersection has a database action in progress  */
  hasActiveDatabaseAction: Scalars['Boolean'];
  hasValidLicense?: Maybe<Scalars['Boolean']>;
  /**  Get the historical event summary report  */
  historicalEventSummaryReport?: Maybe<Scalars['String']>;
  /**  Get a page list of historical entities */
  history: HistoricalEntityConnection;
  /**  Get integration by id  */
  integration?: Maybe<Integration>;
  /**  Get an integration from by id  */
  integrationForm: IntegrationsForm;
  /**  Gets a list of available integrations form  */
  integrationForms: IntegrationsFormConnection;
  integrationGroup?: Maybe<IntegrationGroup>;
  integrationGroups: IntegrationGroupConnection;
  /**  Get a pageable list of integrations.  */
  integrations: IntegrationConnection;
  /**  Get a list of areas that contain the provided coordinates  */
  intersectingAreas: Array<LocationArea>;
  /**  Fetch a list of nearby integrations  */
  intersectingIntegrations?: Maybe<Array<Integration>>;
  /**  Fetch an individual intersection by id  */
  intersection?: Maybe<Intersection>;
  /**  Get intersection comments. */
  intersectionComments: Array<IntersectionComment>;
  /**  Get all the intersections communication statuses. IntersectionCommunicationStatus */
  intersectionCommunicationStatus: Array<Scalars['Object']>;
  /**  Get communication status by id */
  intersectionCommunicationStatusById?: Maybe<IntersectionCommunicationStatus>;
  /**  Get all the intersections detailed statuses */
  intersectionDetailStatus: Array<Scalars['Object']>;
  /**  Gets an intersections detailed status */
  intersectionDetailStatusById?: Maybe<IntersectionDetailStatus>;
  /**  Fetch all intersection groups */
  intersectionGroups: Array<IntersectionGroup>;
  /**  Fetch all intersection groups  */
  intersectionGroupsUntyped: Array<Scalars['Object']>;
  /**  Get an intersection icon status */
  intersectionIconStatus: IntersectionIconStatus;
  /**  Search for intersections that match the criteria of ids */
  intersectionIds: Array<Scalars['String']>;
  /**  Get the all the intersections location. Removed type for faster performance IntersectionLocation */
  intersectionLocation: Array<Scalars['Object']>;
  intersectionMibLists: Array<MibDefinition>;
  /**  Get all the intersections overlap statuses */
  intersectionOverlapStatus: Array<IntersectionOverlapStatus>;
  /**  Get phase status by id */
  intersectionOverlapStatusById?: Maybe<IntersectionOverlapStatus>;
  /**  Get all the intersections pattern statuses */
  intersectionPatternStatus: Array<Scalars['Object']>;
  /**  Get the intersection pattern status for a given intersection */
  intersectionPatternStatusById?: Maybe<IntersectionPatternStatus>;
  /**  Get all the intersections ped statuses */
  intersectionPedStatus: Array<IntersectionPedStatus>;
  /**  Get ped status by id */
  intersectionPedStatusById?: Maybe<IntersectionPedStatus>;
  /**  Get all the intersections phase statuses */
  intersectionPhaseStatus: Array<IntersectionPhaseStatus>;
  /**  Get phase status by id */
  intersectionPhaseStatusById?: Maybe<IntersectionPhaseStatus>;
  /**  Get the intersection phase summary counts */
  intersectionPhaseSummaryCounts: IntersectionPhaseSummaryCounts;
  /**  Get all the intersections simple statuses. IntersectionSimpleStatus */
  intersectionSimpleStatus: Array<Scalars['Object']>;
  /**  Get simple status by id */
  intersectionSimpleStatusById?: Maybe<IntersectionSimpleStatus>;
  /**  Get the intersection status categories. */
  intersectionStatusCategories: IntersectionStatusCategories;
  /**  Get intersection template by id  */
  intersectionTemplate: IntersectionTemplate;
  /**  Get a list of intersection templates  */
  intersectionTemplates: IntersectionTemplateConnection;
  /**  Return a paged list of intersections */
  intersections: IntersectionConnection;
  isUsernameUnique: Scalars['Boolean'];
  keycloakClient?: Maybe<KeycloakClient>;
  kineticAuthJwt: KineticAuthJwt;
  kineticAuthSettings: ModuleSettingsFormView;
  /**  Fetch an event form by category and optional type  */
  kineticEventForm?: Maybe<KineticForm>;
  /**  Fetch form by unique identifier  */
  kineticForm: KineticForm;
  /**  Fetch form config by id  */
  kineticFormField: KineticFormField;
  /**  Fetch configured form fields  */
  kineticFormFields: KineticFormFieldConnection;
  /**  Fetch query builder config for form  */
  kineticFormQueryConfig: Scalars['Object'];
  /**  Fetch template fields for form  */
  kineticFormTemplateFields: Array<KineticFormFieldOption>;
  /**  Fetch configured forms  */
  kineticForms: KineticFormConnection;
  kineticUser: KineticUser;
  /**  Fetch a route by id  */
  landmark: LocationRouteLandmark;
  /**  Get a pageable list of routes  */
  landmarks: LocationRouteLandmarkConnection;
  /**  Fetch lane control settings by unique identifier  */
  laneControlSetting: LaneControlSettings;
  /**  Fetch all available lane control settings  */
  laneControlSettings: LaneControlSettingsConnection;
  lastGitTag: Scalars['String'];
  layout?: Maybe<Layout>;
  layouts: Array<Layout>;
  licenseDetails?: Maybe<KineticLicenseDetails>;
  licenseFeatures: Array<KineticLicenseFeatureValue>;
  /**  Get a database lock for a given live database, if one exists  */
  liveDatabaseLock?: Maybe<DeviceDatabaseLock>;
  /**  Get a list of the controllers databases. */
  localDeviceDatabases: Array<LocalDeviceDatabase>;
  /**  Get a map config by id */
  mapConfig?: Maybe<MapConfig>;
  /**  Get all map configs.  */
  mapConfigs: Array<MapConfig>;
  /**  Get a map layer config by id */
  mapLayerConfig?: Maybe<MapLayerConfig>;
  /**  Get all layer configs.  */
  mapLayerConfigs: Array<MapLayerConfig>;
  /**  Gets a module map setting value  */
  moduleMapSettingValue?: Maybe<Scalars['Object']>;
  /**  Gets module map setting form view  */
  moduleMapSettings: ModuleSettingsFormView;
  nearbyCameras?: Maybe<Array<Camera>>;
  /**  Fetch a list of nearby integrations  */
  nearbyIntegrationsByPoint?: Maybe<Array<Integration>>;
  /**  Fetch a list of nearby intersection  */
  nearbyIntersectionsByPoint: Array<Intersection>;
  /**  Return the list of nearest landmarks to the given input point  */
  nearestLandmarks: Array<LocationRouteLandmark>;
  /**  Return the list of nearest routes to the given input point  */
  nearestRoutes: Array<LocationRoute>;
  notificationPreferences?: Maybe<NotificationPreference>;
  notifications: NotificationConnection;
  /**  Get a page list of historical entities */
  onlineArchivalStatus?: Maybe<Array<Maybe<MongoArchiveJob>>>;
  organization?: Maybe<KineticOrganization>;
  organizationContact?: Maybe<OrganizationDto>;
  organizationPreference?: Maybe<Scalars['JSON']>;
  organizationPreferences: Array<OrganizationPreference>;
  organizations: Array<KineticOrganization>;
  organizationsContact: OrganizationConnection;
  preset?: Maybe<Preset>;
  presets: Array<Preset>;
  privileges: Array<KineticPrivilege>;
  ptzLock: PtzLockInfo;
  /**  Get the recent remote agent heartbeats. */
  recentRemoteAgents: Array<RemoteAgentHeartbeat>;
  /**  Fetch a resource plan by identifier  */
  resourcePlan?: Maybe<ResourcePlan>;
  /**  Get future tasks for a resource plan  */
  resourcePlanFutureTasks: Array<Task>;
  /**  Get resource plan task by id  */
  resourcePlanTask?: Maybe<Task>;
  /**  Get scheduled resource plan tasks for a period  */
  resourcePlanTasks?: Maybe<Array<DateTasks>>;
  /**  Fetch a pageable list of resource plans  */
  resourcePlans: ResourcePlanConnection;
  /**  Get the response module settings  */
  responseModuleSettings: ModuleSettingsFormView;
  /**  Fetch response rule by identifier  */
  responseRule: ResponseRule;
  /**  Fetch response rule config  */
  responseRuleConfig: Scalars['Object'];
  /**  Fetch all configured response rules  */
  responseRules: ResponseRuleConnection;
  roads?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  Return the areas contained by the drawn polygon  */
  roadwayArea?: Maybe<LocationRoadwayArea>;
  /**  Return the roadway point for the specified values  */
  roadwayGeometry?: Maybe<LocationRoadwayPoint>;
  /**  Return the nearest roadway point to the given input point  */
  roadwayPoint?: Maybe<LocationRoadwayPoint>;
  /**  Return the list of nearest roadway points to the given input point  */
  roadwayPoints: Array<Maybe<LocationRoadwayPoint>>;
  /**  Return the nearest roadway segment to the given start and end point  */
  roadwaySegment?: Maybe<LocationRoadwaySegment>;
  role?: Maybe<KineticRole>;
  roles: Array<KineticRole>;
  /**  Fetch a route by id  */
  route: LocationRoute;
  /**  Get a pageable list of routes  */
  routes: LocationRouteConnection;
  /**  Get any currently running jobs. */
  runningJobs: Array<IntegrationJob>;
  scene?: Maybe<Scene>;
  scenes: Array<Scene>;
  /**  Get scheduled event tasks for a period  */
  scheduledEventTasks?: Maybe<Array<DateTasks>>;
  screen?: Maybe<Screen>;
  screens: Array<Screen>;
  /**  Fetch an individual sign by id  */
  sign: Sign;
  /**  Fetch controller message for signs by command  */
  signCommandMessage: Array<SignLibraryMessage>;
  /**  Fetch fonts for an individual sign  */
  signControllerFonts: Array<SignControllerFont>;
  /**  Fetch graphics for an individual sign  */
  signControllerGraphics: Array<SignGraphic>;
  /**  Fetch controller messages by sign  */
  signControllerMessages: Array<SignMessage>;
  /**  Fetch sign cutout by id  */
  signCutout: SignCutout;
  /**  Fetch all available sign cutouts  */
  signCutouts: SignCutoutConnection;
  /**  Fetch the font used for common displays  */
  signDefaultFont: SignFont;
  /**  Fetch display type by sign id  */
  signDisplayType: SignDisplayType;
  /**  Return the sign font by id  */
  signFont: SignFont;
  /**  Fetch the list of distinct fonts  */
  signFonts: Array<SignFont>;
  /**  Fetch all configured sign gantries  */
  signGantries: SignGantryConnection;
  /**  Fetch gantry configuration by id  */
  signGantry: SignGantry;
  /**  Return the sign graphic by id  */
  signGraphic: SignGraphic;
  /**  Fetch the list of graphics  */
  signGraphics: Array<SignGraphic>;
  /**  Fetch sign group by identifier  */
  signGroup: SignGroup;
  /**  Fetch all configured sign groups  */
  signGroups: SignGroupConnection;
  /**  Fetch lane control settings by sign identifier  */
  signLaneControlSettings?: Maybe<LaneControlSettings>;
  /**  Return the signs to show on the map. */
  signMapEntities: Array<Scalars['JSON']>;
  /**  Fetch an individual sign message by id  */
  signMessage?: Maybe<SignLibraryMessage>;
  /**  Fetch distinct display types for library message creation  */
  signMessageDisplayTypes: Array<SignMessageDisplayType>;
  /**  Fetch the message history for a sign  */
  signMessageHistory: SignMessageHistoryConnection;
  /**  Fetch a paged list of messages by sign  */
  signMessageLibrary: SignLibraryMessageConnection;
  /**  Return list of message priorities  */
  signMessagePriorities: Array<SignMessagePriority>;
  /**  Get a message priority  */
  signMessagePriority?: Maybe<SignMessagePriority>;
  /**  Return a paged list of sign messages  */
  signMessages: SignLibraryMessageConnection;
  /**  Get the sign module settings  */
  signModuleSettings: ModuleSettingsFormView;
  /**  Get sign rule by id  */
  signResponseRule?: Maybe<SignResponseRule>;
  /**  Get a pageable list of sign rules.  */
  signResponseRules: SignResponseRuleConnection;
  /**  Return the signs to show on the schematic */
  signSchematicEntities: Array<Scalars['JSON']>;
  signalActionSetOptions: Array<Maybe<FieldConfigOption>>;
  /**  Get Action Sets  */
  signalActionSets: SignalActionSetConnection;
  signalAppliedCommandFilterOptions: SignalAppliedCommandFilterOptions;
  signalAppliedCommands: SignalAppliedCommandConnection;
  signalCommandDefinition?: Maybe<SignalCommandDefinition>;
  signalCommandDefinitions?: Maybe<Array<SignalCommandDefinition>>;
  signalCommandTypes: Array<Scalars['String']>;
  /**  Get the signal communication report  */
  signalCommunicationReport: SignalCommunicationReportRowConnection;
  /**  Fetch an individual signal controller by id  */
  signalController?: Maybe<SignalController>;
  signalControllers: SignalControllerConnection;
  /**  Fetch an individual signal corridor by id  */
  signalCorridor?: Maybe<SignalCorridor>;
  /**  Fetch an individual signal corridor leg by id  */
  signalCorridorLeg?: Maybe<SignalCorridorLeg>;
  /**  Return a paged lis of signal corridor legs */
  signalCorridorLegs: SignalCorridorLegConnection;
  /**  Fetch an individual signal corridor link by id  */
  signalCorridorLink?: Maybe<SignalCorridorLink>;
  signalCorridorLinks: SignalCorridorLinkConnection;
  /**  Return a paged list of signal corridors */
  signalCorridors: SignalCorridorConnection;
  /**  Get Detector Groups  */
  signalDetectorGroups: SignalDetectorGroupConnection;
  /**  Get the signal module settings  */
  signalModuleSettings: ModuleSettingsFormView;
  signalScheduledFutureTasksByIntersection?: Maybe<Array<Task>>;
  signalScheduledTask?: Maybe<Task>;
  signalScheduledTasks?: Maybe<Array<TaskOccurrence>>;
  signalScheduledTasksByIntersection?: Maybe<Array<TaskOccurrence>>;
  signalTasks: SignalTaskConnection;
  /**  Return a paged list of signs  */
  signs: SignConnection;
  /**  aggregate data from the historical entities */
  simpleAggregate: Array<Aggregation>;
  snapshots: SnapshotsConnection;
  systemNotifications: NotificationConnection;
  /**  Get task progressions in the system  */
  taskProgress: TaskProgressConnection;
  /**  Get the time drift settings  */
  timeDriftSettings: TimeDriftSettings;
  timeSpaceData: Array<Maybe<TimeSpaceNodeData>>;
  tour?: Maybe<Tour>;
  tours: TourConnection;
  trafficEventCategory: Array<TrafficEventCategory>;
  trafficEventDefinitions: Array<TrafficEventDefinition>;
  trafficResponsivePlanOptions: Array<Maybe<FieldConfigOption>>;
  /**  Get Traffic Responsive Plans  */
  trafficResponsivePlans: TrafficResponsivePlanConnection;
  /**  Fetch a travel time by identifier  */
  travelTime: TravelTime;
  /**  Fetch the historical 5 minute average for this timeframe  */
  travelTimeDowHistory?: Maybe<TravelTimeDowHistory>;
  /**  Fetch a pageable list of travel time history  */
  travelTimeHistory: TravelTimeHistoryConnection;
  /**  Fetch a pageable list of travel times  */
  travelTimes: TravelTimeConnection;
  /**  Fetch a tunnel by identifier  */
  tunnel: Tunnel;
  /**  Fetch a tunnel log by id  */
  tunnelLog: TunnelLog;
  /**  Fetch a pageable list of tunnel logs  */
  tunnelLogs: TunnelLogConnection;
  /**  Return the tunnel boundaries to show on the map. */
  tunnelMapEntities: Array<Scalars['JSON']>;
  /**  Fetch a pageable list of tunnels  */
  tunnels: TunnelConnection;
  ungroupedCamerasTours?: Maybe<CameraGroup>;
  userGroup?: Maybe<KineticUserGroup>;
  userGroups: Array<KineticUserGroup>;
  userPrivilegeAssignment?: Maybe<KineticUserPrivilegeAssignment>;
  userSignalPrivileges: Array<SignalPrivilege>;
  users: KineticUserConnection;
  /**  Validate a display type message  */
  validateDisplayTypeMessage: SignMessageValidationResult;
  /**  Validate a sign library message  */
  validateLibraryMessage: Array<SignLibraryMessageValidationResult>;
  /**  Validate and format a sign message  */
  validateSignMessage: SignMessageValidationResult;
  version: Scalars['String'];
  videoModuleSettings: ModuleSettingsFormView;
  videoRecordings: VideoRecordConnection;
  videoTask?: Maybe<Task>;
  videoTasks?: Maybe<Array<DateTasks>>;
  /**  Fetch an individual weather station by id  */
  weatherStation: WeatherStation;
  /**  Returns historical chart data formatted for chart display  */
  weatherStationChartHistory: Array<WeatherStationChartHistory>;
  /**  Return historical weather data  */
  weatherStationHistory: WeatherStationHistoryConnection;
  /**  Return the weather stations to show on the map. */
  weatherStationMapEntities: Array<Scalars['JSON']>;
  /**  Return a paged list of weather stations  */
  weatherStations: WeatherStationConnection;
  widget?: Maybe<Widget>;
  widgets: Array<Widget>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type Query_FiveOneOneMessageArgs = {
  id: Scalars['ID'];
};


export type Query_IntersectionAlarmArgs = {
  id: Scalars['ID'];
};


export type Query_SignalScheduledTasksArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryActionSetResponseRuleArgs = {
  id: Scalars['ID'];
};


export type QueryActionSetResponseRulesArgs = {
  query?: InputMaybe<ActionSetRuleInputQuery>;
};


export type QueryActivityLogNamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: Array<FilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryActivityLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: Array<FilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryAggregateArgs = {
  aggregation: Array<Scalars['JSON']>;
  end: Scalars['DateTime'];
  entityIds: Array<Scalars['String']>;
  start: Scalars['DateTime'];
};


export type QueryAlarmConfigArgs = {
  id: Scalars['ID'];
};


export type QueryAlarmConfigsArgs = {
  query: AlarmConfigsQuery;
};


export type QueryAlarmConfigsForEntityArgs = {
  entityId: Scalars['ID'];
  entityType: AlarmEntityType;
  source: KineticModule;
};


export type QueryAlarmsArgs = {
  query: AlarmsQuery;
};


export type QueryAllResourcePlanTasksArgs = {
  id: Scalars['ID'];
};


export type QueryAllScheduledEventTasksArgs = {
  from: Scalars['DateTime'];
  query?: InputMaybe<EventInputQuery>;
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QueryAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryAssociatedMessageQueueStatusArgs = {
  associationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryAtlasArchiveModuleSettingsArgs = {
  formViewName: Scalars['String'];
};


export type QueryAtspmReportArgs = {
  endDateTime: Scalars['DateTime'];
  intersectionId: Scalars['String'];
  params?: InputMaybe<Scalars['Object']>;
  reportType: AtspmSignalReport;
  startDateTime: Scalars['DateTime'];
};


export type QueryCameraArgs = {
  id: Scalars['ID'];
};


export type QueryCameraCapabilitiesArgs = {
  cameraId: Scalars['ID'];
};


export type QueryCameraFutureTasksArgs = {
  cameraId: Scalars['String'];
  timezone: Scalars['String'];
};


export type QueryCameraGroupArgs = {
  id: Scalars['ID'];
};


export type QueryCameraGroupsArgs = {
  cameraGroupListFormat?: InputMaybe<CameraGroupListFormat>;
};


export type QueryCameraMapEntitiesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryCameraMapEntityIdsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryCameraPresetsArgs = {
  id: Scalars['ID'];
};


export type QueryCameraPrivateStreamArgs = {
  cameraId: Scalars['ID'];
};


export type QueryCamerasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryCommunicationLogArgs = {
  id: Scalars['ID'];
};


export type QueryCommunicationLogsArgs = {
  query?: InputMaybe<CommunicationLogInputQuery>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactGroupArgs = {
  id: Scalars['ID'];
};


export type QueryContactGroupsQueryArgs = {
  query?: InputMaybe<ContactGroupQuery>;
};


export type QueryContactsArgs = {
  query?: InputMaybe<ContactInputQuery>;
};


export type QueryContactsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryContactsGroupByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryContactsOfOrganizationArgs = {
  query: ContactOrganizationQuery;
};


export type QueryContactsToBeAssignedArgs = {
  query: ContactOrganizationQuery;
};


export type QueryCorridorArgs = {
  id: Scalars['ID'];
};


export type QueryCorridorRuleArgs = {
  id: Scalars['ID'];
};


export type QueryCorridorRulesArgs = {
  query?: InputMaybe<CorridorRuleInputQuery>;
};


export type QueryCorridorsArgs = {
  query?: InputMaybe<CorridorInputQuery>;
};


export type QueryDataSourceArgs = {
  id: Scalars['ID'];
};


export type QueryDataSourceConfigArgs = {
  id: Scalars['ID'];
};


export type QueryDataSourceConfigsArgs = {
  query?: InputMaybe<DataSourceConfigInputQuery>;
};


export type QueryDataSourcesArgs = {
  query?: InputMaybe<DataSourceInputQuery>;
};


export type QueryDatabaseExportTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryDatabaseExportTemplatesArgs = {
  deviceApi?: InputMaybe<DeviceViewsProtocolEntityEnum>;
};


export type QueryDatabaseLockArgs = {
  databaseId: Scalars['ID'];
  intersectionId: Scalars['ID'];
};


export type QueryDetectionArgs = {
  id: Scalars['ID'];
};


export type QueryDetectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDetectorArgs = {
  id: Scalars['ID'];
};


export type QueryDetectorActivitiesArgs = {
  query?: InputMaybe<DetectorActivityQuery>;
};


export type QueryDetectorAlarmsArgs = {
  query?: InputMaybe<DetectorAlarmQuery>;
};


export type QueryDetectorBatteryArgs = {
  query?: InputMaybe<DetectorBatteryInputQuery>;
};


export type QueryDetectorClassConfigurationArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
};


export type QueryDetectorClassesArgs = {
  detectorIds: Array<Scalars['ID']>;
};


export type QueryDetectorConfigArgs = {
  detectorId?: InputMaybe<Scalars['String']>;
};


export type QueryDetectorConfigAtTimeArgs = {
  detectorId?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDetectorConnectionLogDetailsArgs = {
  query?: InputMaybe<DetectorConnectionLogDetailsQuery>;
};


export type QueryDetectorConnectionLogsArgs = {
  query?: InputMaybe<DetectorConnectionLogQuery>;
};


export type QueryDetectorEmailAlertSettingArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDetectorGroupArgs = {
  id: Scalars['ID'];
};


export type QueryDetectorGroupsArgs = {
  query?: InputMaybe<DetectorGroupInputQuery>;
};


export type QueryDetectorMapEntitiesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryDetectorPerformanceReportArgs = {
  query?: InputMaybe<DetectorPerformanceReportQuery>;
};


export type QueryDetectorPerformanceReportAvailabilityArgs = {
  query?: InputMaybe<DetectorPerformanceReportAvailabilityQuery>;
};


export type QueryDetectorReportConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryDetectorReportConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryDetectorReportFormArgs = {
  reportTemplate?: InputMaybe<DetectorReportTemplate>;
};


export type QueryDetectorReportFormQueryConfigArgs = {
  reportTemplate?: InputMaybe<DetectorReportTemplate>;
};


export type QueryDetectorReportRuleArgs = {
  id: Scalars['ID'];
};


export type QueryDetectorReportRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  template?: InputMaybe<DetectorReportTemplate>;
};


export type QueryDetectorTurningReportArgs = {
  query?: InputMaybe<DetectorTurningReportQuery>;
};


export type QueryDetectorVehicleAxleWeightChartArgs = {
  query?: InputMaybe<DetectorVehicleAxleWeightChartQuery>;
};


export type QueryDetectorVehicleDayWiseAvailabilityArgs = {
  query?: InputMaybe<DetectorVehicleDayWiseAvailabilityQuery>;
};


export type QueryDetectorVehicleDayWisePartialDataArgs = {
  query?: InputMaybe<DetectorVehicleDayWiseAvailabilityQuery>;
};


export type QueryDetectorVehicleDetailArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDetectorVehicleSpeedBinVolumeArgs = {
  query?: InputMaybe<DetectorVehicleVolumeCountQuery>;
};


export type QueryDetectorVehicleSpeedReportArgs = {
  query?: InputMaybe<DetectorSpeedReportQuery>;
};


export type QueryDetectorVehicleVolumeArgs = {
  query?: InputMaybe<DetectorVehicleVolumeQuery>;
};


export type QueryDetectorVehicleVolumeCountArgs = {
  query?: InputMaybe<DetectorVehicleVolumeCountQuery>;
};


export type QueryDetectorVehicleVolumeGroupCountsArgs = {
  query?: InputMaybe<DetectorVehicleVolumeGroupCountsQuery>;
};


export type QueryDetectorVehicleVolumeIntervalCountsArgs = {
  query?: InputMaybe<DetectorVehicleVolumeIntervalCountsQuery>;
};


export type QueryDetectorVehicleYearlySummaryArgs = {
  query?: InputMaybe<DetectorVehicleYearlySummaryQuery>;
};


export type QueryDetectorVehiclesArgs = {
  query?: InputMaybe<DetectorVehicleQuery>;
};


export type QueryDetectorVehiclesCountArgs = {
  query?: InputMaybe<DetectorVehicleQuery>;
};


export type QueryDetectorWeatherArgs = {
  query?: InputMaybe<DetectorWeatherQuery>;
};


export type QueryDetectorsArgs = {
  query?: InputMaybe<DetectorInputQuery>;
};


export type QueryDeviceDatabaseArgs = {
  id: Scalars['ID'];
  intersectionId: Scalars['ID'];
};


export type QueryDeviceDatabaseComparisonArgs = {
  compareDatabaseId: Scalars['ID'];
  intersectionId: Scalars['ID'];
  originalDatabaseId: Scalars['ID'];
};


export type QueryDeviceDatabaseMibChangeLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  intersectionId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryDeviceDatabasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  intersectionId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryDeviceEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  intersectionId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  rule?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDeviceUiArgs = {
  databaseId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  intersectionId: Scalars['String'];
  timeoutMs?: InputMaybe<Scalars['Int']>;
};


export type QueryDeviceUiTableArgs = {
  databaseId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Boolean']>;
  firstIndexOnly?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  intersectionId: Scalars['String'];
  name: Scalars['String'];
  timeoutMs?: InputMaybe<Scalars['Int']>;
  topIndex?: InputMaybe<Scalars['Int']>;
};


export type QueryDeviceViewsArgs = {
  id: DeviceViewsProtocolEntityEnum;
};


export type QueryEmailRuleArgs = {
  id: Scalars['ID'];
};


export type QueryEmailTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryEmailsRuleArgs = {
  query?: InputMaybe<EmailRuleQuery>;
};


export type QueryEmailsTemplateArgs = {
  query?: InputMaybe<EmailRuleQuery>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventRuleQueryConfigArgs = {
  id: Scalars['ID'];
  type?: InputMaybe<EventRuleType>;
};


export type QueryEventSummaryReportArgs = {
  id: Scalars['ID'];
};


export type QueryEventTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryEventTemplatesArgs = {
  query?: InputMaybe<EventTemplateInputQuery>;
};


export type QueryEventTypeArgs = {
  id: Scalars['ID'];
};


export type QueryEventTypeFiltersArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEventTypesArgs = {
  query?: InputMaybe<EventTypeInputQuery>;
};


export type QueryEventsArgs = {
  query?: InputMaybe<EventInputQuery>;
};


export type QueryGateControllerArgs = {
  id: Scalars['ID'];
};


export type QueryGateControllerSignMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGateControllerSignMessagesArgs = {
  query?: InputMaybe<GateControllerSignMessageInputQuery>;
};


export type QueryGateControllerSystemArgs = {
  id: Scalars['ID'];
};


export type QueryGateControllerSystemsArgs = {
  query?: InputMaybe<GateControllerSystemInputQuery>;
};


export type QueryGateControllersArgs = {
  query?: InputMaybe<GateControllerInputQuery>;
};


export type QueryGateDatabaseStateArgs = {
  id: Scalars['ID'];
};


export type QueryGateOperationApprovalArgs = {
  id: Scalars['ID'];
};


export type QueryGateOperationApprovalsArgs = {
  query?: InputMaybe<GateOperationApprovalInputQuery>;
};


export type QueryGeocodeSegmentArgs = {
  direction: Scalars['String'];
  endMeasure: Scalars['Float'];
  road: Scalars['String'];
  startMeasure: Scalars['Float'];
};


export type QueryGetCameraSnapshotArgs = {
  cameraId: Scalars['ID'];
};


export type QueryGetDetectorVehicleImportProgressInfoArgs = {
  id: Scalars['ID'];
};


export type QueryGetFileInfoArgs = {
  centerId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type QueryGetFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  centerId?: InputMaybe<Scalars['String']>;
  filterInput?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryGetGlobalFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterInput?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryGetKineticUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetManagedFileArgs = {
  id: Scalars['ID'];
};


export type QueryGetManagedFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterInput?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryGetManagedFilesByDirectoryArgs = {
  directoryId: Scalars['String'];
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryGetParentGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetSystemDirectoriesArgs = {
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryGetTenantByExternalIdArgs = {
  externalId: Scalars['String'];
};


export type QueryGetUserDirectoriesArgs = {
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryGetZipFilenamesArgs = {
  fileId: Scalars['ID'];
};


export type QueryHasActiveDatabaseActionArgs = {
  intersectionId: Scalars['ID'];
};


export type QueryHistoricalEventSummaryReportArgs = {
  id: Scalars['ID'];
};


export type QueryHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  entityIds: Array<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryIntegrationFormArgs = {
  id: Scalars['String'];
};


export type QueryIntegrationFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  integrationType?: InputMaybe<IntegrationType>;
  integrationsFormSortField?: InputMaybe<IntegrationsFormSortField>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryIntegrationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryIntegrationGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  integrationTypes?: InputMaybe<Array<InputMaybe<IntegrationType>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  integrationType?: InputMaybe<IntegrationType>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  systemEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIntersectingAreasArgs = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryIntersectingIntegrationsArgs = {
  bounds: Scalars['Object'];
  integrationTypes?: InputMaybe<Array<InputMaybe<IntegrationType>>>;
};


export type QueryIntersectionArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionCommentsArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionCommunicationStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionDetailStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionIconStatusArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionIdsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryIntersectionMibListsArgs = {
  intersectionId: Scalars['String'];
};


export type QueryIntersectionOverlapStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionPatternStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionPedStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionPhaseStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionSimpleStatusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryIntersectionTemplatesArgs = {
  query?: InputMaybe<IntersectionTemplateInputQuery>;
};


export type QueryIntersectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryIsUsernameUniqueArgs = {
  username: Scalars['String'];
};


export type QueryKineticAuthJwtArgs = {
  id: Scalars['ID'];
};


export type QueryKineticEventFormArgs = {
  category: Scalars['String'];
};


export type QueryKineticFormArgs = {
  id: Scalars['ID'];
};


export type QueryKineticFormFieldArgs = {
  id: Scalars['ID'];
};


export type QueryKineticFormFieldsArgs = {
  query?: InputMaybe<KineticFormFieldInputQuery>;
};


export type QueryKineticFormQueryConfigArgs = {
  id: Scalars['ID'];
};


export type QueryKineticFormTemplateFieldsArgs = {
  id: Scalars['ID'];
};


export type QueryKineticFormsArgs = {
  query?: InputMaybe<KineticFormInputQuery>;
};


export type QueryLandmarkArgs = {
  id: Scalars['String'];
};


export type QueryLandmarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryLaneControlSettingArgs = {
  id: Scalars['ID'];
};


export type QueryLaneControlSettingsArgs = {
  query?: InputMaybe<GenericInputQuery>;
};


export type QueryLayoutArgs = {
  id: Scalars['ID'];
};


export type QueryLiveDatabaseLockArgs = {
  intersectionId: Scalars['ID'];
};


export type QueryLocalDeviceDatabasesArgs = {
  id: Scalars['ID'];
};


export type QueryMapConfigArgs = {
  id: Scalars['ID'];
};


export type QueryMapLayerConfigArgs = {
  id: Scalars['ID'];
};


export type QueryModuleMapSettingValueArgs = {
  key: Scalars['String'];
};


export type QueryModuleMapSettingsArgs = {
  formViewName: Scalars['String'];
};


export type QueryNearbyCamerasArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryNearbyIntegrationsByPointArgs = {
  integrationType?: InputMaybe<IntegrationType>;
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryNearbyIntersectionsByPointArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryNearestLandmarksArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryNearestRoutesArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryNotificationsArgs = {
  query: NotificationsQuery;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationContactArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationPreferenceArgs = {
  key: Scalars['ID'];
};


export type QueryOrganizationsContactArgs = {
  query?: InputMaybe<OrganizationQuery>;
};


export type QueryPresetArgs = {
  id: Scalars['ID'];
};


export type QueryPtzLockArgs = {
  cameraId: Scalars['ID'];
};


export type QueryResourcePlanArgs = {
  id: Scalars['ID'];
};


export type QueryResourcePlanFutureTasksArgs = {
  id: Scalars['String'];
  timezone: Scalars['String'];
};


export type QueryResourcePlanTaskArgs = {
  id: Scalars['ID'];
};


export type QueryResourcePlanTasksArgs = {
  from: Scalars['DateTime'];
  id: Scalars['ID'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QueryResourcePlansArgs = {
  query?: InputMaybe<ResourcePlanInputQuery>;
};


export type QueryResponseRuleArgs = {
  id: Scalars['ID'];
};


export type QueryResponseRuleConfigArgs = {
  actionType: ResponseRuleActionType;
  conditionType: ResponseRuleConditionType;
  form?: InputMaybe<Scalars['ID']>;
};


export type QueryResponseRulesArgs = {
  query?: InputMaybe<ResponseRuleInputQuery>;
};


export type QueryRoadsArgs = {
  integrationType?: InputMaybe<Array<InputMaybe<IntegrationType>>>;
};


export type QueryRoadwayAreaArgs = {
  geometry: Scalars['Object'];
};


export type QueryRoadwayGeometryArgs = {
  direction: Scalars['String'];
  measure: Scalars['Float'];
  road: Scalars['String'];
};


export type QueryRoadwayPointArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryRoadwayPointsArgs = {
  thresholdMiles?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type QueryRoadwaySegmentArgs = {
  endX: Scalars['Float'];
  endY: Scalars['Float'];
  startX: Scalars['Float'];
  startY: Scalars['Float'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRouteArgs = {
  id: Scalars['String'];
};


export type QueryRoutesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySceneArgs = {
  id: Scalars['ID'];
};


export type QueryScenesArgs = {
  sceneListFormat?: InputMaybe<SceneListFormat>;
};


export type QueryScheduledEventTasksArgs = {
  from: Scalars['DateTime'];
  id: Scalars['ID'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QueryScreenArgs = {
  id: Scalars['ID'];
};


export type QueryScreensArgs = {
  screenListFormat?: InputMaybe<ScreenListFormat>;
};


export type QuerySignArgs = {
  id: Scalars['ID'];
};


export type QuerySignCommandMessageArgs = {
  command: Scalars['String'];
  ids: Array<Scalars['ID']>;
};


export type QuerySignControllerFontsArgs = {
  id: Scalars['ID'];
};


export type QuerySignControllerGraphicsArgs = {
  id: Scalars['ID'];
};


export type QuerySignControllerMessagesArgs = {
  id: Scalars['ID'];
};


export type QuerySignCutoutArgs = {
  id: Scalars['ID'];
};


export type QuerySignCutoutsArgs = {
  query?: InputMaybe<SignCutoutInputQuery>;
};


export type QuerySignDisplayTypeArgs = {
  id: Scalars['ID'];
};


export type QuerySignFontArgs = {
  id: Scalars['ID'];
};


export type QuerySignGantriesArgs = {
  query?: InputMaybe<SignGantryInputQuery>;
};


export type QuerySignGantryArgs = {
  id: Scalars['ID'];
};


export type QuerySignGraphicArgs = {
  id: Scalars['ID'];
};


export type QuerySignGroupArgs = {
  id: Scalars['ID'];
};


export type QuerySignGroupsArgs = {
  query?: InputMaybe<SignGroupInputQuery>;
};


export type QuerySignLaneControlSettingsArgs = {
  id: Scalars['ID'];
};


export type QuerySignMapEntitiesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QuerySignMessageArgs = {
  id: Scalars['ID'];
};


export type QuerySignMessageHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  association?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};


export type QuerySignMessageLibraryArgs = {
  query?: InputMaybe<SignMessageInputQuery>;
};


export type QuerySignMessagePriorityArgs = {
  id: Scalars['ID'];
};


export type QuerySignMessagesArgs = {
  query?: InputMaybe<SignMessageInputQuery>;
};


export type QuerySignResponseRuleArgs = {
  id: Scalars['ID'];
};


export type QuerySignResponseRulesArgs = {
  query?: InputMaybe<SignResponseRuleInputQuery>;
};


export type QuerySignSchematicEntitiesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySignalActionSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySignalAppliedCommandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeExternalCenters?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  timezone: Scalars['String'];
};


export type QuerySignalCommandDefinitionArgs = {
  commandType: Scalars['String'];
};


export type QuerySignalCommunicationReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Int']>;
  startDateTime: Scalars['DateTime'];
};


export type QuerySignalControllerArgs = {
  id: Scalars['ID'];
};


export type QuerySignalControllersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  unassigned?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySignalCorridorArgs = {
  id: Scalars['ID'];
};


export type QuerySignalCorridorLegArgs = {
  id: Scalars['ID'];
};


export type QuerySignalCorridorLegsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySignalCorridorLinkArgs = {
  id: Scalars['ID'];
};


export type QuerySignalCorridorLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySignalCorridorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySignalDetectorGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySignalModuleSettingsArgs = {
  formViewName: Scalars['String'];
};


export type QuerySignalScheduledFutureTasksByIntersectionArgs = {
  intersectionId: Scalars['ID'];
  timezone: Scalars['String'];
};


export type QuerySignalScheduledTaskArgs = {
  taskId: Scalars['ID'];
};


export type QuerySignalScheduledTasksArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QuerySignalScheduledTasksByIntersectionArgs = {
  from: Scalars['DateTime'];
  intersectionId: Scalars['ID'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QuerySignalTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerySignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySimpleAggregateArgs = {
  input: Array<AggregationInput>;
};


export type QuerySnapshotsArgs = {
  query?: InputMaybe<SnapshotsInputQuery>;
};


export type QuerySystemNotificationsArgs = {
  query: SystemNotificationsQuery;
};


export type QueryTaskProgressArgs = {
  query?: InputMaybe<TaskProgressQuery>;
};


export type QueryTimeSpaceDataArgs = {
  corridorId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryTourArgs = {
  id: Scalars['ID'];
};


export type QueryToursArgs = {
  query?: InputMaybe<TourInputQuery>;
};


export type QueryTrafficEventDefinitionsArgs = {
  intersectionId: Scalars['String'];
};


export type QueryTrafficResponsivePlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryTravelTimeArgs = {
  id: Scalars['ID'];
};


export type QueryTravelTimeDowHistoryArgs = {
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type QueryTravelTimeHistoryArgs = {
  query?: InputMaybe<TravelTimeHistoryInputQuery>;
};


export type QueryTravelTimesArgs = {
  query?: InputMaybe<TravelTimeInputQuery>;
};


export type QueryTunnelArgs = {
  id: Scalars['ID'];
};


export type QueryTunnelLogArgs = {
  id: Scalars['ID'];
};


export type QueryTunnelLogsArgs = {
  query?: InputMaybe<TunnelLogInputQuery>;
};


export type QueryTunnelMapEntitiesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryTunnelsArgs = {
  query?: InputMaybe<TunnelInputQuery>;
};


export type QueryUngroupedCamerasToursArgs = {
  cameraGroupListFormat?: InputMaybe<CameraGroupListFormat>;
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
};


export type QueryUserGroupsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryUserPrivilegeAssignmentArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryValidateDisplayTypeMessageArgs = {
  id: Scalars['ID'];
  message: SignMessageInput;
};


export type QueryValidateLibraryMessageArgs = {
  message: SignLibraryMessageInput;
};


export type QueryValidateSignMessageArgs = {
  id: Scalars['ID'];
  message: SignMessageInput;
  useDefaultFont?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVideoRecordingsArgs = {
  recordingsQueryInput?: InputMaybe<RecordingsQueryInput>;
};


export type QueryVideoTaskArgs = {
  taskId: Scalars['ID'];
};


export type QueryVideoTasksArgs = {
  cameraId: Scalars['ID'];
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type QueryWeatherStationArgs = {
  id: Scalars['ID'];
};


export type QueryWeatherStationChartHistoryArgs = {
  query: WeatherStationChartHistoryInputQuery;
};


export type QueryWeatherStationHistoryArgs = {
  query: WeatherStationHistoryInputQuery;
};


export type QueryWeatherStationMapEntitiesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryWeatherStationsArgs = {
  query?: InputMaybe<WeatherStationInputQuery>;
};


export type QueryWidgetArgs = {
  id: Scalars['ID'];
};


export type QueryWidgetsArgs = {
  widgetListFormat?: InputMaybe<WidgetListFormat>;
};

export enum RmcImplementAction {
  Dark = 'DARK',
  EmergencyGreen = 'EMERGENCY_GREEN',
  FixedRate = 'FIXED_RATE',
  HoldMeter = 'HOLD_METER',
  HoldNoNmeter = 'HOLD_NO_NMETER',
  HoldRestinGreen = 'HOLD_RESTIN_GREEN',
  RestinGreen = 'RESTIN_GREEN',
  TrafficResponsive = 'TRAFFIC_RESPONSIVE',
  Unknown = 'UNKNOWN'
}

export enum RmcImplementCommandSource {
  Central = 'CENTRAL',
  Communications = 'COMMUNICATIONS',
  Default = 'DEFAULT',
  Interconnect = 'INTERCONNECT',
  Manual = 'MANUAL',
  TimeBaseControl = 'TIME_BASE_CONTROL',
  Unknown = 'UNKNOWN'
}

export enum RampType {
  /**  Config has no ramps  */
  None = 'NONE',
  /**  Config has an off ramp  */
  Off = 'OFF',
  /**  Config has an on ramp  */
  On = 'ON'
}

export type Recipient = {
  email?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<LabelType>;
  name?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  smsRecipient: Scalars['Boolean'];
  type: RecipientType;
};

export type RecipientDto = {
  __typename?: 'RecipientDto';
  email?: Maybe<Scalars['String']>;
  label?: Maybe<LabelType>;
  name?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  smsRecipient: Scalars['Boolean'];
  type: RecipientType;
};

export enum RecipientType {
  Contact = 'CONTACT',
  Group = 'GROUP'
}

export enum RecordingInitiatedBy {
  Event = 'EVENT',
  User = 'USER'
}

export enum RecordingType {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED'
}

export type RecordingsQueryInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RemoteAgentHeartbeat = {
  __typename?: 'RemoteAgentHeartbeat';
  clientId: Scalars['String'];
  instanceId: Scalars['String'];
  internalIpAddress: Scalars['String'];
  labels: Array<Scalars['String']>;
  lastHeartbeat: Scalars['DateTime'];
  processId: Scalars['Int'];
  remoteIpAddress: Scalars['String'];
  systemName: Scalars['String'];
  version: Scalars['String'];
};

export type RepeatInterval = {
  /**  the interval between repeats */
  interval: Scalars['Int'];
  /**  the unit of the interval */
  intervalUnit: IntervalUnit;
};

/**  Generic Object Type for Reports  */
export type Report = {
  __typename?: 'Report';
  /**  Represents tabs that report can have  */
  reportTabs: Array<Maybe<ReportTab>>;
  /**  Type of report selected  */
  reportType: Scalars['String'];
};

export type ReportTab = {
  __typename?: 'ReportTab';
  /**  Holds data to plot graph  */
  graphData: GraphData;
  /**  Report Tab Title  */
  tabName: Scalars['String'];
  /**  Holds data to draw tables  */
  tableData: Array<GroupedReportTable>;
  /**  Name of selected intersection name  */
  title: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  /**  Is the resource adhoc  */
  adhoc?: Maybe<Scalars['Boolean']>;
  /**  Data for the resource  */
  data: Scalars['Object'];
  /**  Identifier of the resource  */
  id: Scalars['String'];
  /**  Resource Status  */
  status: ResourceStatus;
  /**  Resource Type  */
  type: ResourceType;
};

export type ResourceInput = {
  /**  Is the resource adhoc  */
  adhoc?: InputMaybe<Scalars['Boolean']>;
  /**  Data for the resource  */
  data: Scalars['Object'];
  /**  Identifier of the resource  */
  id: Scalars['ID'];
  /**  Resource Status  */
  status?: InputMaybe<ResourceStatus>;
  /**  Resource Type  */
  type?: InputMaybe<ResourceType>;
};

export type ResourcePlan = {
  __typename?: 'ResourcePlan';
  /**  Resource Plan Details  */
  details: ResourcePlanDetails;
  /**  The identifier of the resourcePlan  */
  id: Scalars['ID'];
  /**  The list of resources  */
  resources: Array<Resource>;
};

export type ResourcePlanConnection = {
  __typename?: 'ResourcePlanConnection';
  /**  Connection Edges  */
  edges: Array<ResourcePlanConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type ResourcePlanConnectionEdge = {
  __typename?: 'ResourcePlanConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The resource plan object  */
  node: ResourcePlan;
};

export type ResourcePlanDetails = {
  __typename?: 'ResourcePlanDetails';
  /**  The description of the resourcePlan  */
  description?: Maybe<Scalars['String']>;
  /**  The name of the resourcePlan  */
  name: Scalars['String'];
  /**  plan settings  */
  settings?: Maybe<Scalars['Object']>;
  /**  Resource Plan status  */
  status: ResourceStatus;
};

export type ResourcePlanDetailsInput = {
  /**  The description of the resourcePlan  */
  description?: InputMaybe<Scalars['String']>;
  /**  The name of the resourcePlan  */
  name: Scalars['String'];
  /**  plan settings  */
  settings?: InputMaybe<Scalars['Object']>;
  /**  Resource Plan status  */
  status: ResourceStatus;
};

export type ResourcePlanInput = {
  /**  Resource Plan Details  */
  details: ResourcePlanDetailsInput;
  /**  The identifier of the resourcePlan  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The list of resources  */
  resources: Array<ResourceInput>;
};

export type ResourcePlanInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  identifier of corridor  */
  corridorId?: InputMaybe<Scalars['ID']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ResourceStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  New = 'NEW',
  NotPresent = 'NOT_PRESENT',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Unscheduled = 'UNSCHEDULED'
}

export enum ResourceType {
  ActionSet = 'ACTION_SET',
  Camera = 'CAMERA',
  DualSpeedLimit = 'DUAL_SPEED_LIMIT',
  Email = 'EMAIL',
  FiveOneOne = 'FIVE_ONE_ONE',
  LaneControl = 'LANE_CONTROL',
  Sign = 'SIGN',
  Sms = 'SMS',
  SpeedLimit = 'SPEED_LIMIT'
}

export enum ResponseAlgorithmType {
  Minnesota = 'MINNESOTA'
}

export type ResponseRule = {
  __typename?: 'ResponseRule';
  /**  action details  */
  action: ResponseRuleAction;
  /**  if the rule can be run in automatic mode  */
  automatic: Scalars['Boolean'];
  /**  condition details  */
  condition: ResponseRuleCondition;
  /**  associated corridor  */
  corridor?: Maybe<Scalars['ID']>;
  /**  if the rule is enabled  */
  enabled: Scalars['Boolean'];
  /**  associated event form  */
  form?: Maybe<Scalars['ID']>;
  /**  identifier of the rule  */
  id: Scalars['ID'];
  /**  name of the rule  */
  name: Scalars['String'];
  /**  type of rule  */
  type: ResponseRuleType;
};

export type ResponseRuleAction = {
  __typename?: 'ResponseRuleAction';
  /**  list of action sets to recommend  */
  actionSets: Array<Scalars['ID']>;
  /**  sign message template to recommend  */
  message?: Maybe<SignMessage>;
  /**  list of resource plans to recommend  */
  resourcePlans: Array<Scalars['ID']>;
  /**  action type  */
  type: ResponseRuleActionType;
};

export type ResponseRuleActionInput = {
  /**  list of action sets to recommend  */
  actionSets?: InputMaybe<Array<Scalars['ID']>>;
  /**  sign message template to recommend  */
  message?: InputMaybe<SignMessageInput>;
  /**  list of resource plans to recommend  */
  resourcePlans?: InputMaybe<Array<Scalars['ID']>>;
  /**  action type  */
  type: ResponseRuleActionType;
};

export enum ResponseRuleActionType {
  /**  recommend action sets to activate  */
  ActionSet = 'ACTION_SET',
  /**  recommend resources from plan  */
  ResourcePlan = 'RESOURCE_PLAN',
  /**  recommend sign messages by selection  */
  SignMessage = 'SIGN_MESSAGE'
}

export type ResponseRuleCondition = {
  __typename?: 'ResponseRuleCondition';
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  mvel rule string  */
  rule: Scalars['String'];
  /**  query builder tree  */
  tree: Scalars['Object'];
  /**  condition type  */
  type: ResponseRuleConditionType;
};

export type ResponseRuleConditionInput = {
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  mvel rule string  */
  rule: Scalars['String'];
  /**  query builder tree  */
  tree: Scalars['Object'];
  /**  condition type  */
  type: ResponseRuleConditionType;
};

export enum ResponseRuleConditionType {
  /**  trigger rule by event  */
  Event = 'EVENT',
  /**  trigger rule by queue detection  */
  QueueDetection = 'QUEUE_DETECTION',
  /**  trigger rule by time of day  */
  Tolling = 'TOLLING',
  /**  trigger rule by travel time detection  */
  TravelTimeDetection = 'TRAVEL_TIME_DETECTION',
  /**  trigger rule by weather detection  */
  WeatherDetection = 'WEATHER_DETECTION'
}

export type ResponseRuleConnection = {
  __typename?: 'ResponseRuleConnection';
  /**  connection edges  */
  edges: Array<ResponseRuleConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type ResponseRuleConnectionEdge = {
  __typename?: 'ResponseRuleConnectionEdge';
  /**  cursor position  */
  cursor?: Maybe<Scalars['String']>;
  /**  response rule node  */
  node: ResponseRule;
};

export type ResponseRuleInput = {
  /**  action details  */
  action: ResponseRuleActionInput;
  /**  if the rule can be run in automatic mode  */
  automatic: Scalars['Boolean'];
  /**  condition details  */
  condition: ResponseRuleConditionInput;
  /**  associated corridor  */
  corridor?: InputMaybe<Scalars['ID']>;
  /**  if the rule is enabled  */
  enabled: Scalars['Boolean'];
  /**  associated event form  */
  form?: InputMaybe<Scalars['ID']>;
  /**  identifier of the rule  */
  id?: InputMaybe<Scalars['ID']>;
  /**  name of the rule  */
  name: Scalars['String'];
  /**  type of rule  */
  type: ResponseRuleType;
};

export type ResponseRuleInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ResponseRuleType {
  /**  rule is triggered by corridor response  */
  Corridor = 'CORRIDOR',
  /**  rule is triggered by event response  */
  Event = 'EVENT'
}

export enum RoadwayType {
  /**  Indicates config is in the current direction  */
  Current = 'CURRENT',
  /**  Indicates config is in the opposite direction  */
  Opposite = 'OPPOSITE',
  /**  Indicates config is in the reversible direction  */
  Reversible = 'REVERSIBLE'
}

export type SaveDetectorConfigInput = {
  config?: InputMaybe<DetectorConfigInput>;
  detectorId: Scalars['ID'];
};

export type SaveDetectorConnectionLogInput = {
  connectionId: Scalars['ID'];
  detectorId: Scalars['ID'];
  logs?: InputMaybe<Array<DetectorConnectionLogInput>>;
};

export type SaveDetectorExtraConfigInput = {
  config?: InputMaybe<DetectorExtraConfigInput>;
  detectorId: Scalars['ID'];
};

export type SaveHiTracLogPacketsInput = {
  detectorId: Scalars['ID'];
  deviceType: Scalars['Int'];
  logs?: InputMaybe<Array<HiTracLogRecord>>;
};

export type SaveHiTracVehiclePacketsInput = {
  detectorId: Scalars['ID'];
  vehicles?: InputMaybe<Array<HiTracVehicleInput>>;
};

export type Scene = {
  __typename?: 'Scene';
  content: Array<Maybe<SceneContent>>;
  id: Scalars['ID'];
  layout: Layout;
  name: Scalars['String'];
  ownershipType: OwnershipType;
};

export type SceneContent = Camera | Tour | Widget;

export type SceneContentInput = {
  camera?: InputMaybe<Scalars['ID']>;
  tour?: InputMaybe<Scalars['ID']>;
  widget?: InputMaybe<Scalars['ID']>;
};

export type SceneInput = {
  content: Array<SceneContentInput>;
  id?: InputMaybe<Scalars['ID']>;
  layout: EmbeddedLayoutInput;
  name: Scalars['String'];
  ownershipType: OwnershipType;
};

export type SceneListFormat = {
  searchFields?: InputMaybe<Array<SceneSearchField>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<SceneSortField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum SceneSearchField {
  Name = 'NAME'
}

export enum SceneSortField {
  Id = 'ID',
  Name = 'NAME'
}

export type Schedule = {
  /**  Create a scheduled job that will fire the Job at given moments in time, defined with Unix 'cron-like' schedule definitions. */
  cronExpression?: InputMaybe<Scalars['String']>;
  /**  Creates a scheduled job that will fire every N seconds, minutes or hours (see repeatInterval) during a given time window on specified days of the week. */
  dailyTimeInterval?: InputMaybe<DailyTimeInterval>;
  /**  When to end the schedule */
  end?: InputMaybe<Scalars['DateTime']>;
  /**  Create a scheduled job that will fire upon repeating calendar time intervals. The job will fire every N (see RepeatInterval) units of calendar time (see IntervalUnit) */
  repeatInterval?: InputMaybe<RepeatInterval>;
  /**  Rrule schedule to run the job */
  rruleExpression?: InputMaybe<Scalars['String']>;
  /**  Simple interval to run a job now, or for at a specific interval. */
  simpleInterval?: InputMaybe<SimpleInterval>;
  /**  When to start the schedule */
  start?: InputMaybe<Scalars['DateTime']>;
};

export type Screen = {
  __typename?: 'Screen';
  content: Array<Maybe<SceneContent>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  layout: Layout;
  name: Scalars['String'];
  orderNum?: Maybe<Scalars['Int']>;
  ownershipType: OwnershipType;
};

export type ScreenInput = {
  content: Array<SceneContentInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  layout: EmbeddedLayoutInput;
  name: Scalars['String'];
  orderNum: Scalars['Int'];
  ownershipType: OwnershipType;
};

export type ScreenListFormat = {
  searchFields?: InputMaybe<Array<ScreenSearchField>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ScreenOrderInput = {
  id: Scalars['ID'];
  orderNum: Scalars['Int'];
};

export enum ScreenSearchField {
  Description = 'DESCRIPTION',
  Name = 'NAME'
}

export type SetMibsInput = {
  bitIndex?: InputMaybe<Scalars['Int']>;
  indexes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name: Scalars['String'];
  shortName?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Object']>;
};

export enum Severity {
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  Unknown = 'UNKNOWN'
}

export enum ShoulderType {
  /**  Config has both shoulders  */
  Both = 'BOTH',
  /**  Config only has a left shoulder  */
  Left = 'LEFT',
  /**  Config has no shoulders  */
  None = 'NONE',
  /**  Config only has a right shoulder  */
  Right = 'RIGHT'
}

export type Sign = {
  __typename?: 'Sign';
  /**  The current brightness level  */
  brightness?: Maybe<Scalars['Int']>;
  /**  The number of brightness levels  */
  brightnessLevels?: Maybe<Scalars['Int']>;
  /**  The brightness mode  */
  brightnessMode?: Maybe<SignBrightnessMode>;
  /**  The set of currently supported capabilities  */
  capabilities: Array<SignCapability>;
  /**  mapping of command to slot  */
  commands: Array<SignCommandNumber>;
  /**  Communication Parameters  */
  communicationParameters?: Maybe<Scalars['Object']>;
  /**  The current communication status of the sign  */
  communicationStatus: CommunicationState;
  /**  control mode  */
  controlMode?: Maybe<SignControlMode>;
  /**  The currently displayed message on the sign  */
  currentMessage?: Maybe<SignQueuedMessage>;
  /**  The description of the sign  */
  description?: Maybe<Scalars['String']>;
  /**  The direction of the sign  */
  direction?: Maybe<Scalars['String']>;
  /**  The state of the display  */
  displayState: SignDisplayState;
  /**  Display Type  */
  displayType: SignDisplayType;
  /**  The list of current errors  */
  errors: Array<SignError>;
  /**  The current firmware of the sign  */
  firmware?: Maybe<Scalars['String']>;
  /**  mapping of font to number  */
  fonts: Array<SignFontNumber>;
  /**  mapping of graphic to number  */
  graphics: Array<SignGraphicNumber>;
  /**  The identifier of the sign  */
  id: Scalars['ID'];
  /**  lane control settings identifier  */
  laneControlSettingsId?: Maybe<Scalars['String']>;
  /**  Lane Position  */
  lanePosition?: Maybe<Scalars['Int']>;
  /**  Lane Type  */
  laneType?: Maybe<LaneType>;
  /**  The last time the sign was successfully polled  */
  lastDataReceived?: Maybe<Scalars['DateTime']>;
  /**  Time at which the sign was last reset  */
  lastReset?: Maybe<Scalars['DateTime']>;
  /**  The coordinate of the sign  */
  location: Scalars['JSON'];
  /**  The location description  */
  locationDescription?: Maybe<Scalars['String']>;
  /**  If the sign is in a fixed location  */
  locationFixed?: Maybe<Scalars['Boolean']>;
  /**  Median  */
  median?: Maybe<Scalars['Boolean']>;
  /**  message preferences  */
  messagePreferences?: Maybe<SignMessagePreferences>;
  /**  mapping of message to slot  */
  messages: Array<SignMessageNumber>;
  /**  The name of the sign  */
  name: Scalars['String'];
  /**  The power source of the sign  */
  powerSource?: Maybe<SignPowerSource>;
  /**  The list of currently queued messages */
  queue: Array<SignQueuedMessage>;
  /**  The reference point of the sign  */
  referencePoint?: Maybe<Scalars['Float']>;
  /**  The road that the sign serves  */
  road?: Maybe<Scalars['String']>;
  /**  speed settings  */
  speedSettings?: Maybe<SignSpeedSettings>;
  /**  if the sign is enabled  */
  systemEnabled: Scalars['Boolean'];
  /**  test results  */
  tests?: Maybe<SignTests>;
  /**  list of travel times this sign can display  */
  travelTimeSegments: Array<Scalars['String']>;
  /**  The volt level of the sign  */
  volts?: Maybe<Scalars['Float']>;
};

export enum SignActivityLogType {
  ControllerReset = 'CONTROLLER_RESET',
  SignBrightness = 'SIGN_BRIGHTNESS',
  SignExtendMessageTime = 'SIGN_EXTEND_MESSAGE_TIME',
  SignFontDownloaded = 'SIGN_FONT_DOWNLOADED',
  SignFoundBlank = 'SIGN_FOUND_BLANK',
  SignMessageBlanked = 'SIGN_MESSAGE_BLANKED',
  SignMessageDisplayed = 'SIGN_MESSAGE_DISPLAYED',
  SignMessageQueued = 'SIGN_MESSAGE_QUEUED',
  SignPoll = 'SIGN_POLL',
  SignState = 'SIGN_STATE',
  UnexpectedMessage = 'UNEXPECTED_MESSAGE'
}

export type SignBlankArguments = {
  /**  device id */
  id: Scalars['ID'];
  /**  request id */
  requestId?: InputMaybe<Scalars['String']>;
  /**  user display name */
  userDisplayName?: InputMaybe<Scalars['String']>;
  /**  user id */
  userId?: InputMaybe<Scalars['String']>;
};

export enum SignBrightnessMode {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export enum SignCapability {
  Beacons = 'BEACONS',
  BrightnessControl = 'BRIGHTNESS_CONTROL',
  ChangeBackgroundColor = 'CHANGE_BACKGROUND_COLOR',
  ChangeForegroundColor = 'CHANGE_FOREGROUND_COLOR',
  DownloadFonts = 'DOWNLOAD_FONTS',
  ExtendedFonts = 'EXTENDED_FONTS',
  Flashing = 'FLASHING',
  GpsLocation = 'GPS_LOCATION',
  Graphics = 'GRAPHICS',
  GraphicsWithText = 'GRAPHICS_WITH_TEXT',
  ManageFonts = 'MANAGE_FONTS',
  ManageGraphics = 'MANAGE_GRAPHICS',
  MessagesDualSpeedLimit = 'MESSAGES_DUAL_SPEED_LIMIT',
  MessagesGeneral = 'MESSAGES_GENERAL',
  MessagesLaneControl = 'MESSAGES_LANE_CONTROL',
  MessagesSpeedLimit = 'MESSAGES_SPEED_LIMIT',
  MessagesTolling = 'MESSAGES_TOLLING',
  MessagesTravelTime = 'MESSAGES_TRAVEL_TIME',
  ReportsFirmware = 'REPORTS_FIRMWARE',
  ReportsPowerStatus = 'REPORTS_POWER_STATUS',
  ReportsUptime = 'REPORTS_UPTIME',
  SideMounted = 'SIDE_MOUNTED',
  SoftwareReset = 'SOFTWARE_RESET',
  SupportsResponse = 'SUPPORTS_RESPONSE',
  TestFans = 'TEST_FANS',
  TestLamps = 'TEST_LAMPS',
  TestPixels = 'TEST_PIXELS'
}

export type SignCommandNumber = {
  __typename?: 'SignCommandNumber';
  /**  name of the command  */
  command: Scalars['String'];
  /**  slot of the message  */
  number: Scalars['Int'];
};

export type SignCommandNumberInput = {
  /**  name of the command  */
  command: Scalars['String'];
  /**  slot of the message  */
  number: Scalars['Int'];
};

export type SignConnection = {
  __typename?: 'SignConnection';
  edges: Array<SignConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignConnectionEdge = {
  __typename?: 'SignConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Sign;
};

export enum SignControlMode {
  Central = 'CENTRAL',
  CentralOverride = 'CENTRAL_OVERRIDE',
  External = 'EXTERNAL',
  Local = 'LOCAL',
  Other = 'OTHER',
  Simulation = 'SIMULATION'
}

export type SignControllerFont = {
  __typename?: 'SignControllerFont';
  /**  The height of the font characters in pixels  */
  characterHeight: Scalars['Int'];
  /**  The number of pixels between characters  */
  characterSpacing: Scalars['Int'];
  /**  The font character bitmaps in a hex string  */
  font: Scalars['String'];
  /**  The font file name  */
  fontFileName: Scalars['String'];
  /**  The font number for the font  */
  fontNumber: Scalars['Int'];
  /**  The font version  */
  fontVersion: Scalars['Int'];
  /**  The identifier of the font  */
  id: Scalars['ID'];
  /**  The number of pixels between lines  */
  lineSpacing: Scalars['Int'];
};

export type SignCutout = {
  __typename?: 'SignCutout';
  /**  the description  */
  description?: Maybe<Scalars['String']>;
  /**  the direction  */
  direction: Scalars['String'];
  /**  the id  */
  id: Scalars['ID'];
  /**  cutout image  */
  image: Scalars['Object'];
  /**  the location  */
  location: Scalars['Object'];
  /**  the name  */
  name: Scalars['String'];
  /**  the reference point  */
  referencePoint: Scalars['Float'];
  /**  the road  */
  road: Scalars['String'];
  /**  settings  */
  settings: Scalars['Object'];
  /**  cutout signs  */
  signs: Array<Sign>;
};

export type SignCutoutConnection = {
  __typename?: 'SignCutoutConnection';
  /**  connection edges  */
  edges: Array<SignCutoutConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type SignCutoutConnectionEdge = {
  __typename?: 'SignCutoutConnectionEdge';
  /**  cursor position  */
  cursor?: Maybe<Scalars['String']>;
  /**  node  */
  node: SignCutout;
};

export type SignCutoutInput = {
  /**  the description  */
  description?: InputMaybe<Scalars['String']>;
  /**  the direction  */
  direction: Scalars['String'];
  /**  the id  */
  id?: InputMaybe<Scalars['ID']>;
  /**  cutout image  */
  image: Scalars['Object'];
  /**  the location  */
  location: Scalars['Object'];
  /**  the name  */
  name: Scalars['String'];
  /**  the reference point  */
  referencePoint: Scalars['Float'];
  /**  the road  */
  road: Scalars['String'];
  /**  settings  */
  settings: Scalars['Object'];
  /**  cutout signs  */
  signs: Array<Scalars['String']>;
};

export type SignCutoutInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type SignDisplayArguments = {
  /**  association display names */
  associationDisplayNames?: InputMaybe<Array<Scalars['String']>>;
  /**  associations */
  associations?: InputMaybe<Array<Scalars['String']>>;
  /**  current user display name */
  currentUserDisplayName?: InputMaybe<Scalars['String']>;
  /**  current user id */
  currentUserId?: InputMaybe<Scalars['String']>;
  /**  expiration time */
  expirationTime?: InputMaybe<Scalars['DateTime']>;
  /**  identifier of the queued message to remove if editing */
  expiredQueuedMessageId?: InputMaybe<Scalars['String']>;
  /**  device id */
  id: Scalars['ID'];
  /**  message to display */
  message: SignMessageInput;
  /**  queued user display name */
  queuedUserDisplayName?: InputMaybe<Scalars['String']>;
  /**  user id that queued the message */
  queuedUserId?: InputMaybe<Scalars['String']>;
  /**  request id */
  requestId?: InputMaybe<Scalars['String']>;
};

export enum SignDisplayState {
  Blank = 'BLANK',
  Display = 'DISPLAY'
}

export type SignDisplayType = {
  __typename?: 'SignDisplayType';
  /**  The height of a character for a display  */
  characterHeight?: Maybe<Scalars['Int']>;
  /**  The width of a character for the display  */
  characterWidth?: Maybe<Scalars['Int']>;
  /**  The default font of the display  */
  defaultFont?: Maybe<Scalars['ID']>;
  /**  The hash value of similar displays  */
  hash: Scalars['String'];
  /**  The height of the sign in pixels  */
  height: Scalars['Int'];
  /**  The matrix type  */
  matrixType: SignMatrixType;
  /**  The number of max characters for a line  */
  maxCharacters?: Maybe<Scalars['Int']>;
  /**  The number of max lines for the display  */
  maxLines?: Maybe<Scalars['Int']>;
  /**  The width of the sign in pixels  */
  width: Scalars['Int'];
};

export enum SignError {
  AttachedDevice = 'ATTACHED_DEVICE',
  Communication = 'COMMUNICATION',
  Controller = 'CONTROLLER',
  DoorOpen = 'DOOR_OPEN',
  Fan = 'FAN',
  Lamp = 'LAMP',
  Message = 'MESSAGE',
  Other = 'OTHER',
  OtherController = 'OTHER_CONTROLLER',
  Photocell = 'PHOTOCELL',
  Pixel = 'PIXEL',
  Power = 'POWER',
  Program = 'PROGRAM',
  Prom = 'PROM',
  Ram = 'RAM',
  Temperature = 'TEMPERATURE'
}

export type SignFanTest = {
  __typename?: 'SignFanTest';
  /**  errors running tests  */
  error?: Maybe<Scalars['String']>;
  /**  the list of fan failures  */
  fans: Array<Scalars['Int']>;
  /**  if the test is running  */
  running: Scalars['Boolean'];
};

export type SignFont = {
  __typename?: 'SignFont';
  /**  The height of the font characters in pixels  */
  characterHeight: Scalars['Int'];
  /**  The number of pixels between characters  */
  characterSpacing: Scalars['Int'];
  /**  The font character bitmaps in a hex string  */
  font: Scalars['String'];
  /**  The font file name  */
  fontFileName: Scalars['String'];
  /**  The font version  */
  fontVersion: Scalars['Int'];
  /**  The identifier of the font  */
  id: Scalars['ID'];
  /**  The number of pixels between lines  */
  lineSpacing: Scalars['Int'];
};

export type SignFontInput = {
  /**  The height of the font characters in pixels  */
  characterHeight: Scalars['Int'];
  /**  The number of pixels between characters  */
  characterSpacing: Scalars['Int'];
  /**  The font character bitmaps in a hex string  */
  font: Scalars['String'];
  /**  The font file name  */
  fontFileName: Scalars['String'];
  /**  The font version  */
  fontVersion: Scalars['Int'];
  /**  The number of pixels between lines  */
  lineSpacing: Scalars['Int'];
};

export type SignFontNumber = {
  __typename?: 'SignFontNumber';
  /**  hash of the font  */
  hash: Scalars['String'];
  /**  number of the font */
  number: Scalars['Int'];
};

export type SignFontNumberInput = {
  /**  The unique hash for the font  */
  hash: Scalars['String'];
  /**  The font number for the font  */
  number: Scalars['Int'];
};

export type SignGantry = {
  __typename?: 'SignGantry';
  /**  description  */
  description?: Maybe<Scalars['String']>;
  /**  The direction of the gantry  */
  direction: Scalars['String'];
  /**  identifier of the gantry  */
  id: Scalars['ID'];
  /**  gantry image  */
  image: Scalars['Object'];
  /**  The location of the gantry  */
  location: Scalars['Object'];
  /**  Name of the gantry  */
  name: Scalars['String'];
  /**  The reference point of the gantry  */
  referencePoint: Scalars['Float'];
  /**  The road of the gantry  */
  road: Scalars['String'];
  /**  gantry settings  */
  settings: Scalars['Object'];
  /**  gantry signs  */
  signs: Array<Sign>;
};

export type SignGantryConnection = {
  __typename?: 'SignGantryConnection';
  /**  The contained edges  */
  edges: Array<SignGantryConnectionEdge>;
  /**  Pagination info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type SignGantryConnectionEdge = {
  __typename?: 'SignGantryConnectionEdge';
  /**  the location of the edge in the page results  */
  cursor?: Maybe<Scalars['String']>;
  /**  entity details for the node  */
  node: SignGantry;
};

export type SignGantryInput = {
  /**  description  */
  description?: InputMaybe<Scalars['String']>;
  /**  The direction of the gantry  */
  direction: Scalars['String'];
  /**  identifier of the gantry  */
  id?: InputMaybe<Scalars['ID']>;
  /**  gantry image  */
  image: Scalars['Object'];
  /**  The location of the gantry  */
  location: Scalars['Object'];
  /**  Name of the gantry  */
  name: Scalars['String'];
  /**  The reference point of the gantry  */
  referencePoint: Scalars['Float'];
  /**  The road of the gantry  */
  road: Scalars['String'];
  /**  gantry settings  */
  settings: Scalars['Object'];
  /**  gantry signs  */
  signs: Array<Scalars['String']>;
};

export type SignGantryInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  directions to filter by  */
  directions?: InputMaybe<Array<Scalars['String']>>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  roads to filter by  */
  roads?: InputMaybe<Array<Scalars['String']>>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type SignGraphic = {
  __typename?: 'SignGraphic';
  /**  height of the graphic in pixels  */
  height: Scalars['Int'];
  /**  The identifier of the graphic  */
  id: Scalars['ID'];
  /**  base 64 encoded image  */
  image: Scalars['Object'];
  /**  name of the graphic  */
  name: Scalars['String'];
  /**  optional slot  */
  slot?: Maybe<Scalars['Int']>;
  /**  width of the graphic in pixels  */
  width: Scalars['Int'];
};

export type SignGraphicNumber = {
  __typename?: 'SignGraphicNumber';
  /**  hash of the graphic  */
  hash: Scalars['String'];
  /**  number of the graphic  */
  number: Scalars['Int'];
};

export type SignGroup = {
  __typename?: 'SignGroup';
  /**  settings  */
  attributes: Scalars['Object'];
  /**  the description  */
  description?: Maybe<Scalars['String']>;
  /**  the id  */
  id: Scalars['ID'];
  /**  the name  */
  name: Scalars['String'];
  /**  associated signs  */
  signs: Array<Sign>;
  /**  type  */
  type: SignGroupType;
};

export type SignGroupConnection = {
  __typename?: 'SignGroupConnection';
  /**  connection edges  */
  edges: Array<SignGroupConnectionEdge>;
  /**  page info  */
  pageInfo: PageInfo;
  /**  total results  */
  totalCount: Scalars['Int'];
};

export type SignGroupConnectionEdge = {
  __typename?: 'SignGroupConnectionEdge';
  /**  cursor position  */
  cursor?: Maybe<Scalars['String']>;
  /**  node  */
  node: SignGroup;
};

export type SignGroupInput = {
  /**  settings  */
  attributes: Scalars['Object'];
  /**  the description  */
  description?: InputMaybe<Scalars['String']>;
  /**  the id  */
  id?: InputMaybe<Scalars['ID']>;
  /**  the name  */
  name: Scalars['String'];
  /**  associated signs  */
  signs: Array<Scalars['String']>;
  /**  type  */
  type: SignGroupType;
};

export type SignGroupInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum SignGroupType {
  BridgeOpening = 'BRIDGE_OPENING',
  ChokePoint = 'CHOKE_POINT',
  Cutout = 'CUTOUT',
  Gantry = 'GANTRY',
  General = 'GENERAL',
  LaneControl = 'LANE_CONTROL',
  SpeedSynchronization = 'SPEED_SYNCHRONIZATION',
  Tolling = 'TOLLING',
  VariableSpeedLimit = 'VARIABLE_SPEED_LIMIT'
}

export type SignImage = {
  __typename?: 'SignImage';
  /**  height  */
  height: Scalars['Int'];
  /**  identifier  */
  id: Scalars['String'];
  /**  base 64 encoded string  */
  image: Scalars['Object'];
  /**  name  */
  name: Scalars['String'];
  /**  slot  */
  slot?: Maybe<Scalars['Int']>;
  /**  width  */
  width: Scalars['Int'];
};

export type SignImageInput = {
  /**  height  */
  height: Scalars['Int'];
  /**  identifier  */
  id: Scalars['String'];
  /**  base 64 encoded string  */
  image: Scalars['Object'];
  /**  name  */
  name: Scalars['String'];
  /**  slot  */
  slot?: InputMaybe<Scalars['Int']>;
  /**  width  */
  width: Scalars['Int'];
};

export type SignLampTest = {
  __typename?: 'SignLampTest';
  /**  errors running tests  */
  error?: Maybe<Scalars['String']>;
  /**  if the test is running  */
  running: Scalars['Boolean'];
  /**  stuck off lamps  */
  stuckOff: Array<Scalars['Int']>;
  /**  stuck on lamps  */
  stuckOn: Array<Scalars['Int']>;
};

export type SignLaneControlDisplayRequest = {
  /**  identifiers of associations  */
  associations?: InputMaybe<Array<Scalars['ID']>>;
  /**  The name of the command to display  */
  command: Scalars['String'];
  /**  The time at which to expire the message  */
  expirationTime?: InputMaybe<Scalars['DateTime']>;
  /**  The identifier of the sign to display  */
  id: Scalars['ID'];
};

export type SignLibraryMessage = {
  __typename?: 'SignLibraryMessage';
  /**  The list of exclusions for this message  */
  exclusions?: Maybe<Array<Scalars['String']>>;
  /**  group identifier  */
  group?: Maybe<Scalars['String']>;
  /**  identifier  */
  id: Scalars['ID'];
  /**  The default message  */
  message: SignMessage;
  /**  message name  */
  name: Scalars['String'];
  /**  The list of overrides for the library message based on display  */
  overrides?: Maybe<Array<SignLibraryMessageOverride>>;
  /**  sign identifier  */
  sign?: Maybe<Scalars['ID']>;
};

export type SignLibraryMessageConnection = {
  __typename?: 'SignLibraryMessageConnection';
  edges: Array<SignLibraryMessageConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignLibraryMessageConnectionEdge = {
  __typename?: 'SignLibraryMessageConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignLibraryMessage;
};

export type SignLibraryMessageInput = {
  /**  The list of exclusions for this message  */
  exclusions?: InputMaybe<Array<Scalars['String']>>;
  /**  The identifier of the message  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The default message  */
  message: SignMessageInput;
  /**  The name of the message  */
  name: Scalars['String'];
  /**  The list of overrides for the library message based on display  */
  overrides?: InputMaybe<Array<SignLibraryMessageOverrideInput>>;
  /**  identifier for sign specific message  */
  sign?: InputMaybe<Scalars['ID']>;
};

export type SignLibraryMessageOverride = {
  __typename?: 'SignLibraryMessageOverride';
  /**  The hash value generated by the display dimensions and selected font  */
  hash: Scalars['String'];
  /**  The override message for the display type and font  */
  message: SignMessage;
};

export type SignLibraryMessageOverrideInput = {
  /**  The hash value generated by the display dimensions and selected font  */
  hash: Scalars['String'];
  /**  The override message for the display type and font  */
  message: SignMessageInput;
};

export type SignLibraryMessageValidationResult = {
  __typename?: 'SignLibraryMessageValidationResult';
  /**  The display type hash  */
  hash: Scalars['String'];
  /**  The validation result, null if valid  */
  result?: Maybe<Scalars['String']>;
};

export enum SignMatrixType {
  Character = 'CHARACTER',
  Full = 'FULL',
  Line = 'LINE'
}

export type SignMessage = {
  __typename?: 'SignMessage';
  /**  If beacons should be enabled for this message if supported  */
  beaconsEnabled: Scalars['Boolean'];
  /**  The associated lane control command, if supported  */
  command?: Maybe<Scalars['String']>;
  /**  message image identifiers  */
  images: Array<SignImage>;
  /**  The list of pages attached to the sign message  */
  pages: Array<SignMessagePage>;
  /**  The priority value for the message  */
  priority: Scalars['Int'];
  /**  optional message slot  */
  slot?: Maybe<Scalars['Int']>;
  /**  type of message  */
  type: SignMessageType;
};

export type SignMessageAbbreviation = {
  __typename?: 'SignMessageAbbreviation';
  /**  The text to exchange for the search string  */
  abbreviation: Scalars['String'];
  /**  The identifier of the display type for the abbreviation  */
  displayTypeId: Scalars['ID'];
  /**  The identifier of the abbreviation  */
  id: Scalars['ID'];
  /**  The text to search for  */
  searchString: Scalars['String'];
};

export type SignMessageAbbreviationInput = {
  /**  The text to exchange for the search string  */
  abbreviation: Scalars['String'];
  /**  The identifier of the display type for the abbreviation  */
  displayTypeId: Scalars['ID'];
  /**  The identifier of the abbreviation  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The text to search for  */
  searchString: Scalars['String'];
};

export type SignMessageDisplayRequest = {
  /**  association display names  */
  associationDisplayNames?: InputMaybe<Array<Scalars['String']>>;
  /**  identifiers of associations  */
  associations?: InputMaybe<Array<Scalars['String']>>;
  /**  Time at which to expire the message  */
  expirationTime?: InputMaybe<Scalars['DateTime']>;
  /**  sign identifier  */
  id: Scalars['ID'];
  /**  requested message to display, required if slot missing  */
  message?: InputMaybe<SignMessageInput>;
  /**  requested slot to activate, required if message missing  */
  slot?: InputMaybe<Scalars['Int']>;
};

export type SignMessageDisplayType = {
  __typename?: 'SignMessageDisplayType';
  /**  The height of a character for a display  */
  characterHeight?: Maybe<Scalars['Int']>;
  /**  The width of a character for the display  */
  characterWidth?: Maybe<Scalars['Int']>;
  /**  The default font of the display  */
  defaultFont?: Maybe<Scalars['ID']>;
  /**  The hash value of similar displays  */
  hash: Scalars['String'];
  /**  The height of the sign in pixels  */
  height: Scalars['Int'];
  /**  The matrix type  */
  matrixType: SignMatrixType;
  /**  The number of max characters for a line  */
  maxCharacters?: Maybe<Scalars['Int']>;
  /**  The number of max lines for the display  */
  maxLines?: Maybe<Scalars['Int']>;
  /**  The list of associated sign  */
  signs: Scalars['Object'];
  /**  The width of the sign in pixels  */
  width: Scalars['Int'];
};

export type SignMessageHistory = {
  __typename?: 'SignMessageHistory';
  /**  association display names  */
  associationDisplayNames: Array<Scalars['String']>;
  /**  message associations  */
  associations: Array<Scalars['String']>;
  /**  The user who blanked the message  */
  blankedUser?: Maybe<Scalars['String']>;
  /**  The user id who blanked the message */
  blankedUserId?: Maybe<Scalars['String']>;
  /**  The identifier of the message history  */
  id: Scalars['ID'];
  /**  The message that was posted  */
  message: SignMessage;
  /**  The user who posted the message  */
  postedUser?: Maybe<Scalars['String']>;
  /**  The user id who posted the message */
  postedUserId?: Maybe<Scalars['String']>;
  /**  The user who queued the message  */
  queuedUser?: Maybe<Scalars['String']>;
  /**  The user id who queued the message */
  queuedUserId?: Maybe<Scalars['String']>;
  /**  The identifier of the sign for the history  */
  signId: Scalars['ID'];
  /**  The time at which the message was blanked  */
  timeBlanked?: Maybe<Scalars['DateTime']>;
  /**  The time at which the message was posted  */
  timePosted?: Maybe<Scalars['DateTime']>;
  /**  The time at which the message was added to the queue  */
  timeQueued?: Maybe<Scalars['DateTime']>;
};

export type SignMessageHistoryConnection = {
  __typename?: 'SignMessageHistoryConnection';
  edges: Array<SignMessageHistoryConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignMessageHistoryConnectionEdge = {
  __typename?: 'SignMessageHistoryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignMessageHistory;
};

export type SignMessageImage = {
  __typename?: 'SignMessageImage';
  /**  The image  */
  image: Scalars['Int'];
  /**  The position of the image  */
  position: Position;
};

export type SignMessageImageInput = {
  /**  The image  */
  image: Scalars['Int'];
  /**  The position of the image  */
  position: PositionInput;
};

export type SignMessageInput = {
  /**  If beacons should be enabled for this message if supported  */
  beaconsEnabled?: InputMaybe<Scalars['Boolean']>;
  /**  The associated lane control command, if supported  */
  command?: InputMaybe<Scalars['String']>;
  /**  message image identifiers  */
  images?: InputMaybe<Array<SignImageInput>>;
  /**  The list of pages attached to the sign message  */
  pages: Array<SignMessagePageInput>;
  /**  The number priority value for the message  */
  priority: Scalars['Int'];
  /**  optional message slot  */
  slot?: InputMaybe<Scalars['Int']>;
  /**  type of message  */
  type: SignMessageType;
};

export type SignMessageInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  The sign to filter by  */
  sign?: InputMaybe<Scalars['ID']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
  /**  The message type to filter  */
  type?: InputMaybe<SignMessageType>;
};

export type SignMessageLine = {
  __typename?: 'SignMessageLine';
  /**  The horizontal justification for the line  */
  horizontalJustification?: Maybe<HorizontalJustification>;
  /**  associated integration  */
  integration?: Maybe<Scalars['String']>;
  /**  The segments for the line  */
  lineSegments: Array<SignMessageLineSegment>;
};

export type SignMessageLineInput = {
  /**  The horizontal justification for the line  */
  horizontalJustification?: InputMaybe<HorizontalJustification>;
  /**  associated integration  */
  integration?: InputMaybe<Scalars['String']>;
  /**  The segments for the line  */
  lineSegments: Array<SignMessageLineSegmentInput>;
};

export type SignMessageLineSegment = {
  __typename?: 'SignMessageLineSegment';
  /**  The background color of the segment  */
  backgroundColor?: Maybe<Scalars['String']>;
  /**  The flash off time for the segment  */
  flashOffMillis?: Maybe<Scalars['Int']>;
  /**  The flash on time for the segment  */
  flashOnMillis?: Maybe<Scalars['Int']>;
  /**  If flashing is enabled for the segment  */
  flashingEnabled?: Maybe<Scalars['Boolean']>;
  /**  The font number for the line  */
  font: Scalars['Int'];
  /**  The foreground color of the segment  */
  foregroundColor?: Maybe<Scalars['String']>;
  /**  The horizontal justification for the segment  */
  horizontalJustification?: Maybe<HorizontalJustification>;
  /**  The text of the line  */
  text: Scalars['String'];
};

export type SignMessageLineSegmentInput = {
  /**  The background color of the segment  */
  backgroundColor?: InputMaybe<Scalars['String']>;
  /**  The flash off time for the segment  */
  flashOffMillis?: InputMaybe<Scalars['Int']>;
  /**  The flash on time for the segment  */
  flashOnMillis?: InputMaybe<Scalars['Int']>;
  /**  If flashing is enabled for the segment  */
  flashingEnabled?: InputMaybe<Scalars['Boolean']>;
  /**  The font number for the line  */
  font: Scalars['Int'];
  /**  The foreground color of the segment  */
  foregroundColor?: InputMaybe<Scalars['String']>;
  /**  The horizontal justification for the segment  */
  horizontalJustification?: InputMaybe<HorizontalJustification>;
  /**  The text of the line  */
  text: Scalars['String'];
};

export type SignMessageNumber = {
  __typename?: 'SignMessageNumber';
  /**  hash of the font  */
  hash: Scalars['String'];
  /**  number of the font */
  number: Scalars['Int'];
};

export type SignMessagePage = {
  __typename?: 'SignMessagePage';
  /**  The background color of the page  */
  backgroundColor?: Maybe<Scalars['String']>;
  /**  The list of images to display  */
  images: Array<SignMessageImage>;
  /**  The list of lines for the page  */
  lines: Array<SignMessageLine>;
  /**  The page off time in mills  */
  pageOffTime?: Maybe<Scalars['Int']>;
  /**  The page on time in mills  */
  pageOnTime?: Maybe<Scalars['Int']>;
  /**  The text areas on the page  */
  textAreas: Array<SignMessageTextArea>;
  /**  The vertical justification for the page  */
  verticalJustification?: Maybe<VerticalJustification>;
};

export type SignMessagePageInput = {
  /**  The background color of the page  */
  backgroundColor?: InputMaybe<Scalars['String']>;
  /**  The list of images to display  */
  images?: InputMaybe<Array<SignMessageImageInput>>;
  /**  The list of lines for the page  */
  lines?: InputMaybe<Array<SignMessageLineInput>>;
  /**  The page off time in mills  */
  pageOffTime?: InputMaybe<Scalars['Int']>;
  /**  The page on time in mills  */
  pageOnTime?: InputMaybe<Scalars['Int']>;
  /**  The text areas on the page  */
  textAreas?: InputMaybe<Array<SignMessageTextAreaInput>>;
  /**  The vertical justification for the page  */
  verticalJustification?: InputMaybe<VerticalJustification>;
};

export type SignMessagePreferences = {
  __typename?: 'SignMessagePreferences';
  /**  horizontal justification  */
  horizontalJustification: HorizontalJustification;
  /**  identifier  */
  id: Scalars['String'];
  /**  page off time  */
  pageOffTime: Scalars['Float'];
  /**  page on time  */
  pageOnTime: Scalars['Float'];
  /**  vertical justification  */
  verticalJustification: VerticalJustification;
};

export type SignMessagePriority = {
  __typename?: 'SignMessagePriority';
  /**  The description of the priority  */
  description?: Maybe<Scalars['String']>;
  /**  identifier  */
  id: Scalars['ID'];
  /**  If the priority is the default for messages  */
  messageDefault: Scalars['Boolean'];
  /**  The name of the priority  */
  name: Scalars['String'];
  /**  The number priority value  */
  priority: Scalars['Int'];
  /**  If the priority is the default for travel times  */
  travelTimeDefault: Scalars['Boolean'];
};

export type SignMessagePriorityInput = {
  /**  The description of the priority  */
  description?: InputMaybe<Scalars['String']>;
  /**  identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  If the priority is the default for messages  */
  messageDefault?: InputMaybe<Scalars['Boolean']>;
  /**  The name of the priority  */
  name: Scalars['String'];
  /**  The number priority value  */
  priority: Scalars['Int'];
  /**  If the priority is the default for travel times  */
  travelTimeDefault?: InputMaybe<Scalars['Boolean']>;
};

export enum SignMessageQueueStatus {
  /**  The queued message is currently being displayed on the sign  */
  Active = 'ACTIVE',
  /**  The message is not present in the queue  */
  NotPresent = 'NOT_PRESENT',
  /**  The message has yet to be activated  */
  Pending = 'PENDING',
  /**  The message is currently being queued  */
  Queued = 'QUEUED'
}

export type SignMessageTextArea = {
  __typename?: 'SignMessageTextArea';
  /**  The height of the text area  */
  height: Scalars['Int'];
  /**  The list of lines for the page  */
  lines: Array<SignMessageLine>;
  /**  The position of the text area  */
  position: Position;
  /**  The vertical justification for the page  */
  verticalJustification: VerticalJustification;
  /**  The width of the text area  */
  width: Scalars['Int'];
};

export type SignMessageTextAreaInput = {
  /**  The height of the text area  */
  height: Scalars['Int'];
  /**  The list of lines for the page  */
  lines: Array<SignMessageLineInput>;
  /**  The position of the text area  */
  position: PositionInput;
  /**  The vertical justification for the page  */
  verticalJustification?: InputMaybe<VerticalJustification>;
  /**  The width of the text area  */
  width: Scalars['Int'];
};

export enum SignMessageType {
  /**  Used to display advisory or regulatory dual speed limits  */
  DualSpeedLimit = 'DUAL_SPEED_LIMIT',
  /**  Used to display plain text and/or graphic messages  */
  General = 'GENERAL',
  /**  Used to display information that lives on an integration state  */
  Integration = 'INTEGRATION',
  /**  Used to display lane control graphic messages  */
  LaneControl = 'LANE_CONTROL',
  /**  Used to display advisory or regulatory speed limits  */
  SpeedLimit = 'SPEED_LIMIT',
  /**  Used to display travel time information  */
  TravelTime = 'TRAVEL_TIME'
}

export type SignMessageValidationResult = {
  __typename?: 'SignMessageValidationResult';
  /**  The reason for the validation failure  */
  failure?: Maybe<Scalars['String']>;
  /**  The formatted and validated message, can be null  */
  message?: Maybe<SignMessage>;
};

export type SignPixelTest = {
  __typename?: 'SignPixelTest';
  /**  errors running tests  */
  error?: Maybe<Scalars['String']>;
  /**  the list of error pixels  */
  pixels: Array<SignPixelTestError>;
  /**  if the test is running  */
  running: Scalars['Boolean'];
};

export type SignPixelTestError = {
  __typename?: 'SignPixelTestError';
  /**  status  */
  status?: Maybe<Scalars['String']>;
  /**  x coordinate  */
  x: Scalars['Int'];
  /**  y coordinate  */
  y: Scalars['Int'];
};

export enum SignPowerSource {
  AcLine = 'AC_LINE',
  Battery = 'BATTERY',
  Generator = 'GENERATOR',
  NoSignPower = 'NO_SIGN_POWER',
  Other = 'OTHER',
  PowerShutdown = 'POWER_SHUTDOWN',
  Solar = 'SOLAR'
}

export type SignQueuedMessage = {
  __typename?: 'SignQueuedMessage';
  /**  association display names  */
  associationDisplayNames: Array<Scalars['String']>;
  /**  message associations  */
  associations: Array<Scalars['String']>;
  /**  The expiration time of the message  */
  expirationTime?: Maybe<Scalars['DateTime']>;
  /**  The identifier of the queued message  */
  id: Scalars['ID'];
  /**  The content of the message  */
  message: SignMessage;
  /**  The user who posted the message  */
  user?: Maybe<Scalars['String']>;
};

export type SignQueuedMessageInput = {
  /**  association display names  */
  associationDisplayNames?: InputMaybe<Array<Scalars['String']>>;
  /**  message associations  */
  associations?: InputMaybe<Array<Scalars['String']>>;
  /**  The expiration time of the message  */
  expirationTime?: InputMaybe<Scalars['DateTime']>;
  /**  The identifier of the queued message  */
  id: Scalars['ID'];
  /**  The content of the message  */
  message: SignMessageInput;
  /**  The user who posted the message  */
  user?: InputMaybe<Scalars['String']>;
};

export type SignResponseRule = {
  __typename?: 'SignResponseRule';
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  identifier for event form  */
  formId: Scalars['String'];
  /**  rule identifier  */
  id: Scalars['ID'];
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  the sign message template  */
  template: SignMessage;
  /**  rule tree  */
  tree: Scalars['Object'];
};

export type SignResponseRuleConnection = {
  __typename?: 'SignResponseRuleConnection';
  edges: Array<SignResponseRuleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignResponseRuleEdge = {
  __typename?: 'SignResponseRuleEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignResponseRule;
};

export type SignResponseRuleInput = {
  /**  if the rule is enabled for evaluation  */
  enabled: Scalars['Boolean'];
  /**  identifier for event form  */
  formId: Scalars['String'];
  /**  rule identifier  */
  id?: InputMaybe<Scalars['ID']>;
  /**  rule name  */
  name: Scalars['String'];
  /**  rule priority  */
  priority: Scalars['Int'];
  /**  the mvel rule string  */
  rule: Scalars['String'];
  /**  sign template to be generated for recommendations  */
  template: SignMessageInput;
  /**  rule tree  */
  tree: Scalars['Object'];
};

export type SignResponseRuleInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  form identifier for rules  */
  formId?: InputMaybe<Scalars['String']>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type SignSpeedSettings = {
  __typename?: 'SignSpeedSettings';
  /**  background color  */
  backgroundColor?: Maybe<Scalars['String']>;
  /**  foreground color  */
  foregroundColor?: Maybe<Scalars['String']>;
  /**  number of intervals for speed  */
  intervals: Scalars['Int'];
  /**  Maximum amount of change for speed limits  */
  maximumChange: Scalars['Int'];
  /**  Maximum allowed speed  */
  maximumSpeed: Scalars['Int'];
  /**  Minimum allowed speed  */
  minimumSpeed: Scalars['Int'];
  /**  Minimum allowed secondary speed  */
  secondaryMaximumSpeed?: Maybe<Scalars['Int']>;
  /**  Maximum allowed secondary speed  */
  secondaryMinimumSpeed?: Maybe<Scalars['Int']>;
};

export type SignSpeedSettingsInput = {
  /**  background color  */
  backgroundColor?: InputMaybe<Scalars['String']>;
  /**  foreground color  */
  foregroundColor?: InputMaybe<Scalars['String']>;
  /**  number of intervals for speed  */
  intervals: Scalars['Int'];
  /**  Maximum amount of change for speed limits  */
  maximumChange: Scalars['Int'];
  /**  Maximum allowed speed  */
  maximumSpeed: Scalars['Int'];
  /**  Minimum allowed speed  */
  minimumSpeed: Scalars['Int'];
  /**  Minimum allowed secondary speed  */
  secondaryMaximumSpeed?: InputMaybe<Scalars['Int']>;
  /**  Maximum allowed secondary speed  */
  secondaryMinimumSpeed?: InputMaybe<Scalars['Int']>;
};

export type SignTests = {
  __typename?: 'SignTests';
  /**  fan test  */
  fan?: Maybe<SignFanTest>;
  /**  lamp test  */
  lamp?: Maybe<SignLampTest>;
  /**  pixel tests  */
  pixel?: Maybe<SignPixelTest>;
};

export type SignUploadGraphicArguments = {
  /**  height of image in pixels */
  height: Scalars['Int'];
  /**  device id */
  id: Scalars['ID'];
  /**  base 64 encoded image string */
  image: Scalars['String'];
  /**  name of image */
  name: Scalars['String'];
  /**  request id */
  requestId?: InputMaybe<Scalars['String']>;
  /**  slot to upload the image */
  slot: Scalars['Int'];
  /**  width of image in pixels */
  width: Scalars['Int'];
};

export type SignUploadMessageArguments = {
  /**  device id */
  id: Scalars['ID'];
  /**  height of image in pixels */
  message: SignMessageInput;
  /**  request id */
  requestId?: InputMaybe<Scalars['String']>;
  /**  slot to upload the message */
  slot: Scalars['Int'];
};

export type SignalActionSet = {
  __typename?: 'SignalActionSet';
  centerId?: Maybe<Scalars['String']>;
  commands: Array<Scalars['JSON']>;
  id: Scalars['ID'];
  impact?: Maybe<SignalActionSetImpactType>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  priority: Scalars['Int'];
};

export type SignalActionSetConnection = {
  __typename?: 'SignalActionSetConnection';
  edges: Array<SignalActionSetConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalActionSetConnectionEdge = {
  __typename?: 'SignalActionSetConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalActionSet;
};

export enum SignalActionSetImpactType {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type SignalActionSetInput = {
  commands: Array<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  impact?: InputMaybe<SignalActionSetImpactType>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  priority: Scalars['Int'];
};

export type SignalAppliedCommand = {
  __typename?: 'SignalAppliedCommand';
  command: Scalars['String'];
  device: BasicIntersectionDevice;
  endDateTime: Scalars['DateTime'];
  externalCenterId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inheritedGroup?: Maybe<BasicIntersectionDevice>;
  intersection: BasicIntersectionDevice;
  priority?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  status: Scalars['String'];
  taskId: Scalars['String'];
  value: Scalars['String'];
};

export type SignalAppliedCommandConnection = {
  __typename?: 'SignalAppliedCommandConnection';
  edges: Array<SignalAppliedCommandConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalAppliedCommandConnectionEdge = {
  __typename?: 'SignalAppliedCommandConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalAppliedCommand;
};

export type SignalAppliedCommandFilterOptions = {
  __typename?: 'SignalAppliedCommandFilterOptions';
  commands: Array<Scalars['String']>;
  devices: Array<BasicIntersectionDevice>;
  inheritedGroups: Array<BasicIntersectionDevice>;
  intersections: Array<BasicIntersectionDevice>;
  sources: Array<Scalars['String']>;
};

export type SignalAppliedCommandStreamObject = {
  __typename?: 'SignalAppliedCommandStreamObject';
  dto: SignalAppliedCommand;
  streamObjectOperation: StreamObjectOperation;
};

export type SignalCommand = {
  __typename?: 'SignalCommand';
  commandValues: Array<CommandValue>;
  description?: Maybe<Scalars['String']>;
  type: CommandType;
};

export type SignalCommandDefinition = {
  __typename?: 'SignalCommandDefinition';
  commandType: Scalars['String'];
  commandValues?: Maybe<Array<SignalCommandValueDefinition>>;
  supportedProtocols?: Maybe<Array<Scalars['String']>>;
};

export type SignalCommandTask = {
  __typename?: 'SignalCommandTask';
  command: SignalCommand;
  createdDateTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalCenterId?: Maybe<Scalars['String']>;
  flattenedIntersections?: Maybe<Array<Maybe<CommandIntersection>>>;
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  intersectionIds?: Maybe<Array<Scalars['String']>>;
  labels?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  runningTaskId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  taskId: Scalars['String'];
  taskRecurrenceType?: Maybe<TaskRecurrenceType>;
  taskType?: Maybe<TaskType>;
};

export type SignalCommandValueDefinition = {
  __typename?: 'SignalCommandValueDefinition';
  defaultValue?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  unsetValue?: Maybe<Scalars['Int']>;
};

export type SignalCommunicationReportRowConnection = {
  __typename?: 'SignalCommunicationReportRowConnection';
  edges: Array<CommunicationReportRowEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalController = {
  __typename?: 'SignalController';
  /**  URL or ip:port  */
  connectionParameters?: Maybe<Scalars['String']>;
  /**  Description of the signal controller  */
  description?: Maybe<Scalars['String']>;
  /**  ID of the signal controller */
  id: Scalars['ID'];
  /**  The location of the signal controller  */
  location?: Maybe<Scalars['Object']>;
  /**  Name of the signal controller */
  name: Scalars['String'];
};

export type SignalControllerConnection = {
  __typename?: 'SignalControllerConnection';
  edges: Array<SignalControllerConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalControllerConnectionEdge = {
  __typename?: 'SignalControllerConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalController;
};

export type SignalCorridor = {
  __typename?: 'SignalCorridor';
  entryLeg: SignalCorridorLeg;
  exitLeg: SignalCorridorLeg;
  id: Scalars['ID'];
  links: Array<SignalCorridorConnectedLink>;
  name: Scalars['String'];
};

export type SignalCorridorConnectedLink = {
  __typename?: 'SignalCorridorConnectedLink';
  downLinkLane: SignalCorridorLinkLane;
  link: SignalCorridorLink;
  linkOrder: Scalars['Int'];
  upLinkLane: SignalCorridorLinkLane;
};

export type SignalCorridorConnectedLinkInput = {
  downLinkLaneId: Scalars['String'];
  linkId: Scalars['String'];
  linkOrder: Scalars['Int'];
  upLinkLaneId: Scalars['String'];
};

export type SignalCorridorConnection = {
  __typename?: 'SignalCorridorConnection';
  edges: Array<SignalCorridorConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalCorridorConnectionEdge = {
  __typename?: 'SignalCorridorConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalCorridor;
};

export type SignalCorridorInput = {
  entryLeg: Scalars['String'];
  exitLeg: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  links: Array<SignalCorridorConnectedLinkInput>;
  name: Scalars['String'];
};

export type SignalCorridorLeg = {
  __typename?: 'SignalCorridorLeg';
  approachLocation: Scalars['Object'];
  departureLocation: Scalars['Object'];
  id: Scalars['ID'];
  intersection: Intersection;
  intersectionLanes: Array<IntersectionLane>;
  location: Scalars['Object'];
  name: Scalars['String'];
};

export type SignalCorridorLegConnection = {
  __typename?: 'SignalCorridorLegConnection';
  edges: Array<SignalCorridorLegConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalCorridorLegConnectionEdge = {
  __typename?: 'SignalCorridorLegConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalCorridorLeg;
};

export type SignalCorridorLegInput = {
  approachLocation: Scalars['Object'];
  departureLocation: Scalars['Object'];
  id?: InputMaybe<Scalars['ID']>;
  intersectionId: Scalars['String'];
  intersectionLanes: Array<Scalars['String']>;
  location: Scalars['Object'];
  name: Scalars['String'];
};

export type SignalCorridorLink = {
  __typename?: 'SignalCorridorLink';
  id: Scalars['ID'];
  linkLanes: Array<SignalCorridorLinkLane>;
  name: Scalars['String'];
};

export type SignalCorridorLinkConnection = {
  __typename?: 'SignalCorridorLinkConnection';
  edges: Array<SignalCorridorLinkConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalCorridorLinkConnectionEdge = {
  __typename?: 'SignalCorridorLinkConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalCorridorLink;
};

export type SignalCorridorLinkInput = {
  id?: InputMaybe<Scalars['ID']>;
  linkLanes: Array<SignalCorridorLinkLaneInput>;
  name: Scalars['String'];
};

export type SignalCorridorLinkLane = {
  __typename?: 'SignalCorridorLinkLane';
  approachLeg: SignalCorridorLeg;
  departureLeg: SignalCorridorLeg;
  id: Scalars['String'];
  location: Scalars['Object'];
  queueClearanceTime?: Maybe<Scalars['Int']>;
  speed: Scalars['Float'];
};

export type SignalCorridorLinkLaneInput = {
  approachLegId: Scalars['String'];
  departureLegId: Scalars['String'];
  id: Scalars['String'];
  location: Scalars['Object'];
  queueClearanceTime?: InputMaybe<Scalars['Int']>;
  speed: Scalars['Float'];
};

export type SignalDetector = {
  __typename?: 'SignalDetector';
  detector: Scalars['Int'];
  direction: SignalDetectorDirectionType;
  intersectionId: Scalars['String'];
  movement: SignalDetectorMovementType;
  note?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export enum SignalDetectorDirectionType {
  BothDirections = 'BOTH_DIRECTIONS',
  East = 'EAST',
  Negative = 'NEGATIVE',
  North = 'NORTH',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  NotDirectional = 'NOT_DIRECTIONAL',
  Other = 'OTHER',
  Positive = 'POSITIVE',
  South = 'SOUTH',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST',
  Unknown = 'UNKNOWN',
  West = 'WEST'
}

export type SignalDetectorGroup = {
  __typename?: 'SignalDetectorGroup';
  aggregationMethod: SignalDetectorGroupAggregationMethodType;
  description?: Maybe<Scalars['String']>;
  detectors: Array<SignalDetector>;
  id: Scalars['ID'];
  loggingEnabled: Scalars['Boolean'];
  loggingPeriodMinutes?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export enum SignalDetectorGroupAggregationMethodType {
  Average = 'AVERAGE',
  Sum = 'SUM'
}

export type SignalDetectorGroupConnection = {
  __typename?: 'SignalDetectorGroupConnection';
  edges: Array<SignalDetectorGroupConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalDetectorGroupConnectionEdge = {
  __typename?: 'SignalDetectorGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalDetectorGroup;
};

export type SignalDetectorGroupInput = {
  aggregationMethod: SignalDetectorGroupAggregationMethodType;
  description?: InputMaybe<Scalars['String']>;
  detectors: Array<SignalDetectorInput>;
  id?: InputMaybe<Scalars['ID']>;
  loggingEnabled: Scalars['Boolean'];
  loggingPeriodMinutes?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type SignalDetectorInput = {
  detector: Scalars['Int'];
  direction: SignalDetectorDirectionType;
  intersectionId: Scalars['String'];
  movement: SignalDetectorMovementType;
  note?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export enum SignalDetectorMovementType {
  Left = 'LEFT',
  Right = 'RIGHT',
  Thru = 'THRU',
  Unknown = 'UNKNOWN'
}

export type SignalIndication = {
  __typename?: 'SignalIndication';
  green: SignalOutputColor;
  greenFlashing: Scalars['Boolean'];
  greenIcon: IntersectionMapIcon;
  red: SignalOutputColor;
  redFlashing: Scalars['Boolean'];
  redIcon: IntersectionMapIcon;
  signalNumber: Scalars['Int'];
  signalType: SignalIndicationType;
  yellow: SignalOutputColor;
  yellowFlashing: Scalars['Boolean'];
  yellowIcon: IntersectionMapIcon;
};

export type SignalIndicationInput = {
  green: SignalOutputColor;
  greenFlashing: Scalars['Boolean'];
  greenIcon: IntersectionMapIcon;
  red: SignalOutputColor;
  redFlashing: Scalars['Boolean'];
  redIcon: IntersectionMapIcon;
  signalNumber: Scalars['Int'];
  signalType: SignalIndicationType;
  yellow: SignalOutputColor;
  yellowFlashing: Scalars['Boolean'];
  yellowIcon: IntersectionMapIcon;
};

export enum SignalIndicationType {
  Overlap = 'OVERLAP',
  Ped = 'PED',
  Phase = 'PHASE'
}

export enum SignalOutputColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Dark = 'DARK',
  DarkOrange = 'DARK_ORANGE',
  DarkRed = 'DARK_RED',
  DarkYellow = 'DARK_YELLOW',
  Green = 'GREEN',
  Grey = 'GREY',
  Lavender = 'LAVENDER',
  LightGreen = 'LIGHT_GREEN',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Ultramarine = 'ULTRAMARINE',
  Unknown = 'UNKNOWN',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type SignalPrivilege = {
  __typename?: 'SignalPrivilege';
  lastUpdated: Scalars['Float'];
  privilegeType: PrivilegeType;
  restrictions: Scalars['JSON'];
};

export type SignalTask = {
  __typename?: 'SignalTask';
  associatedId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intersectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
  status?: Maybe<SignalTaskStatus>;
  updated?: Maybe<Scalars['DateTime']>;
  user?: Maybe<OrganizationKineticUser>;
};

export type SignalTaskConnection = {
  __typename?: 'SignalTaskConnection';
  edges: Array<SignalTaskEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SignalTaskEdge = {
  __typename?: 'SignalTaskEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignalTask;
};

export enum SignalTaskStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

export type SimpleInterval = {
  /**  Defines the number of times to repeat, Null would repeat forever. */
  repeatCount?: InputMaybe<Scalars['Int']>;
  /**  The repeat interval */
  repeatInterval?: InputMaybe<RepeatInterval>;
};

export type Snapshot = {
  __typename?: 'Snapshot';
  camera?: Maybe<Camera>;
  id: Scalars['ID'];
  key: Scalars['String'];
  module: KineticModule;
  path: Scalars['String'];
  serverFileName: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type SnapshotConnectionEdge = {
  __typename?: 'SnapshotConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Snapshot;
};

export enum SnapshotInitiatedBy {
  Service = 'SERVICE',
  User = 'USER'
}

export type SnapshotInput = {
  cameraId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  initiatedBy: SnapshotInitiatedBy;
  serverFileName: Scalars['String'];
  takerId?: InputMaybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  url: Scalars['String'];
};

export type SnapshotStreamObject = {
  __typename?: 'SnapshotStreamObject';
  dto?: Maybe<Snapshot>;
  streamObjectOperation?: Maybe<StreamObjectOperation>;
};

export type SnapshotsConnection = {
  __typename?: 'SnapshotsConnection';
  edges: Array<SnapshotConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SnapshotsInputQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cameraId?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  incidentId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SpecialFunctionStatus = {
  __typename?: 'SpecialFunctionStatus';
  active: Scalars['Boolean'];
  number: Scalars['Int'];
};

export enum Status {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export enum StreamObjectOperation {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export enum StreamTagRequestAction {
  Start = 'START',
  Stop = 'STOP'
}

export type SubGroup = {
  __typename?: 'SubGroup';
  items: Array<GroupItem>;
  name: Scalars['String'];
  subGroups: Array<SubGroup>;
};

export type SubGroupInput = {
  items: Array<InputMaybe<GroupItemInput>>;
  name: Scalars['String'];
  subGroups: Array<InputMaybe<SubGroupInput>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _runOneTimeJobSubscribe: JobResult;
  /**  Subscribe to activity logs */
  addedActivityLog: ActivityLog;
  alarmUpdated: Alarm;
  cameraGroupUpdated?: Maybe<CameraGroup>;
  cameraUpdated: Camera;
  communicationLogUpdated?: Maybe<CommunicationLogStreamObject>;
  contactGroupUpdated?: Maybe<ContactGroupStreamObject>;
  contactUpdated?: Maybe<ContactStreamObject>;
  /**  Listen for changes on corridors  */
  corridorUpdated: Corridor;
  /**  Listen for device database lock/unlock actions for an intersection  */
  databaseLockUpdated: Array<DeviceDatabaseLockStatus>;
  /**  Listen for changes on the provided identifiers  */
  detectionUpdated: Detection;
  /**  Returns Detector on update */
  detectorUpdated: Detector;
  detectorVehicleImportProgressUpdated: DetectorVehicleImportProgress;
  detectorVehicleVolumeCountsUpdated?: Maybe<Scalars['Boolean']>;
  /**  Listen for changes to a device database  */
  deviceDatabaseUpdated: DeviceDatabase;
  effectivePrivilegesUpdated?: Maybe<Array<Maybe<KineticEffectivePrivilegeAssignment>>>;
  emailRuleUpdated?: Maybe<EmailRuleDto>;
  emailTemplateUpdated?: Maybe<EmailTemplateDto>;
  /**  Listen for changes for events  */
  eventUpdated: Event;
  /**  Listen for changes to gantry map entities  */
  gantryMapUpdated: Scalars['JSON'];
  /**  Listen for changes for gantry events  */
  gantryUpdated: Scalars['String'];
  /**  listen for gate controller sign message updates  */
  gateControllerSignMessageUpdated: GateControllerSignMessage;
  /**  listen for gate controller system updates  */
  gateControllerSystemUpdated: GateControllerSystem;
  /**  listen for gate controller updates  */
  gateControllerUpdated: GateController;
  /**  listen for gate operation approval updates  */
  gateOperationApprovalUpdated: GateOperationApproval;
  /**  Listen for any changes to intersection comments */
  intersectionCommentsUpdated?: Maybe<Array<IntersectionComment>>;
  /**  Listen for changes on intersection communication status  */
  intersectionCommunicationStatusUpdated: Array<IntersectionCommunicationStatus>;
  /**  Listen for changes on intersections  */
  intersectionConfigUpdated: Array<Intersection>;
  /**  Oversees the activation/deactivation of database actions for provided intersections  */
  intersectionDatabaseActionUpdated: DeviceDatabaseActionUpdated;
  /**  Listen for changes to detailed intersection status changes. */
  intersectionDetailStatusUpdated: Array<Maybe<IntersectionDetailStatus>>;
  /**  Listen for changes in the intersection icon status. If onDemand is null or true create extra mib subscriptions for the devices. */
  intersectionIconStatusUpdate: Array<IntersectionIconStatus>;
  /**  Listen for changes on intersection overlap status  */
  intersectionOverlapStatusUpdated: Array<IntersectionOverlapStatus>;
  /**  Listen for changes to pattern status changes. If onDemand is null or true create extra mib susbcriptions for the devices. */
  intersectionPatternStatusUpdated: Array<IntersectionPatternStatus>;
  /**  Listen for changes on intersection ped status  */
  intersectionPedStatusUpdated: Array<IntersectionPedStatus>;
  /**  Listen for changes on intersection phase status. If onDemand is null or true create extra mib susbcriptions for the devices.  */
  intersectionPhaseStatusUpdated: Array<IntersectionPhaseStatus>;
  /**  Listen for changes on intersection simple status  */
  intersectionSimpleStatusUpdated: Array<IntersectionSimpleStatus>;
  /**  Listen for changes to the intersection status categories. */
  intersectionStatusCategoriesUpdated: IntersectionStatusCategories;
  /**  Subscritpion for JobResults */
  jobResults?: Maybe<JobResult>;
  kineticUserPreferenceUpdated: UserPreferenceStreamObject;
  /**  Listen for changes to library messages  */
  libraryMessageUpdated: SignLibraryMessage;
  notificationUpdated?: Maybe<Notification>;
  organizationPreferenceUpdated: OrganizationPreferenceStreamObject;
  organizationUpdated?: Maybe<OrganizationStreamObject>;
  privilegeAssignmentsUpdated?: Maybe<KineticUserPrivilegeAssignment>;
  /**  Tasks updates subscription.  */
  resourcePlanTaskUpdated: Task;
  /**  Subscription for when a resource plan has been updated  */
  resourcePlanUpdated?: Maybe<ResourcePlan>;
  rolesUpdated?: Maybe<KineticRole>;
  sceneUpdated: Scene;
  /**  Tasks updates subscription.  */
  scheduledEventTaskUpdated: Task;
  screenUpdated: Screen;
  /**  Listen for changes to map entities  */
  signMapUpdated: Scalars['JSON'];
  /**  Listen for changes for sign events  */
  signUpdated: Sign;
  /**  Listen for any changes to signal applied commands */
  signalAppliedCommandUpdated?: Maybe<Array<SignalAppliedCommandStreamObject>>;
  /**  Listen for changes on signal tasks. */
  signalTaskUpdated: SignalTask;
  snapshotsUpdated?: Maybe<SnapshotStreamObject>;
  taskProgressUpdated: TaskProgress;
  taskUpdated: Task;
  tourUpdated: Tour;
  /**  Listen for changes on travel times  */
  travelTimeUpdated: TravelTime;
  /**  Listen for changes for tunnel tunnels  */
  tunnelUpdated: Scalars['ID'];
  userGroupsUpdated?: Maybe<KineticUserGroup>;
  userPreferenceUpdated: UserPreferenceStreamObject;
  videoWallUpdated?: Maybe<VideoWallStreamObject>;
  watchTour: WatchedTour;
  /**  Listen for changes on the provided identifiers  */
  weatherStationUpdated: WeatherStation;
  widgetUpdated: Widget;
};


export type Subscription_RunOneTimeJobSubscribeArgs = {
  id: Scalars['String'];
  requestId: Scalars['String'];
  settings: Scalars['Object'];
};


export type SubscriptionAddedActivityLogArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  module?: InputMaybe<KineticModule>;
};


export type SubscriptionAlarmUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionCameraGroupUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionCameraUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionCommunicationLogUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionContactGroupUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionContactUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionCorridorUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionDatabaseLockUpdatedArgs = {
  intersectionIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionDetectionUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionDetectorUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionDetectorVehicleImportProgressUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionDetectorVehicleVolumeCountsUpdatedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeviceDatabaseUpdatedArgs = {
  databaseIds?: InputMaybe<Array<Scalars['ID']>>;
  intersectionIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionEmailRuleUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionEmailTemplateUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionEventUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionGantryUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionGateControllerSignMessageUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionGateControllerSystemUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionGateControllerUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionGateOperationApprovalUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionCommentsUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionCommunicationStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionConfigUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionDatabaseActionUpdatedArgs = {
  intersectionIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionDetailStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionIconStatusUpdateArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onDemand?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptionIntersectionOverlapStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionPatternStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onDemand?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptionIntersectionPedStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionIntersectionPhaseStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onDemand?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptionIntersectionSimpleStatusUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionJobResultsArgs = {
  requestIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SubscriptionKineticUserPreferenceUpdatedArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type SubscriptionLibraryMessageUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionOrganizationPreferenceUpdatedArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOrganizationUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionPrivilegeAssignmentsUpdatedArgs = {
  ids: Array<Scalars['ID']>;
};


export type SubscriptionResourcePlanTaskUpdatedArgs = {
  creation?: InputMaybe<Scalars['Boolean']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type SubscriptionResourcePlanUpdatedArgs = {
  ids: Array<Scalars['ID']>;
};


export type SubscriptionRolesUpdatedArgs = {
  ids: Array<Scalars['ID']>;
};


export type SubscriptionSceneUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionScheduledEventTaskUpdatedArgs = {
  creation?: InputMaybe<Scalars['Boolean']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type SubscriptionScreenUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionSignUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionSignalTaskUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  intersectionIds?: InputMaybe<Array<Scalars['String']>>;
};


export type SubscriptionSnapshotsUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionTaskUpdatedArgs = {
  creation?: InputMaybe<Scalars['Boolean']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type SubscriptionTourUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionTravelTimeUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionTunnelUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionUserGroupsUpdatedArgs = {
  ids: Array<Scalars['ID']>;
};


export type SubscriptionUserPreferenceUpdatedArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type SubscriptionWatchTourArgs = {
  tourId: Scalars['ID'];
};


export type SubscriptionWeatherStationUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SubscriptionWidgetUpdatedArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SumAggregation = {
  __typename?: 'SumAggregation';
  /**  Previous trending sum of this aggregation */
  trend: Scalars['Float'];
  /**  Sum of this aggregation */
  value: Scalars['Float'];
};

export type SystemNotificationsQuery = {
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  notificationSortField?: InputMaybe<NotificationSortField>;
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type Table = {
  __typename?: 'Table';
  /**  Column properties of table  */
  columnProperties: Array<ColumnProperty>;
  /**  Data of table */
  data: Array<Maybe<Scalars['Object']>>;
  /**  Table Name  */
  name: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  /**  when the task was created  */
  createdAt?: Maybe<Scalars['DateTime']>;
  /**  task data  */
  data: Scalars['JSON'];
  /**  when the task should end  */
  end: Scalars['DateTime'];
  /**  External Center Id */
  externalCenterId?: Maybe<Scalars['String']>;
  /**  task id  */
  id: Scalars['ID'];
  /**  task name  */
  name: Scalars['String'];
  /**  the next scheduled date for the task  */
  nextExecutionDate?: Maybe<Scalars['DateTime']>;
  /**  when the task recurrence should end  */
  recurrenceEndDate?: Maybe<Scalars['DateTime']>;
  /**  when the task recurrence started  */
  recurrenceStartDate?: Maybe<Scalars['DateTime']>;
  /**  RFC 5545 string rrule configuration  */
  rrule?: Maybe<Scalars['String']>;
  /**  when the task should start  */
  start: Scalars['DateTime'];
  /**  recurrence type for the task  */
  taskRecurrenceType: TaskRecurrenceType;
  /**  type for the task  */
  taskType: TaskType;
  /**  timezone  */
  timezone?: Maybe<Scalars['String']>;
  /**  when the task was updated  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskInput = {
  /**  when the task was created  */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /**  task data  */
  data: Scalars['JSON'];
  /**  when the task should end  */
  end: Scalars['DateTime'];
  /**  task id  */
  id?: InputMaybe<Scalars['ID']>;
  /**  task name  */
  name: Scalars['String'];
  /**  when the task recurrence should end  */
  recurrenceEndDate?: InputMaybe<Scalars['DateTime']>;
  /**  when the task recurrence started  */
  recurrenceStartDate?: InputMaybe<Scalars['DateTime']>;
  /**  RFC 5545 string rrule configuration  */
  rrule?: InputMaybe<Scalars['String']>;
  /**  when the task should start  */
  start: Scalars['DateTime'];
  /**  recurrence type for the task  */
  taskRecurrenceType: TaskRecurrenceType;
  /**  type for the task  */
  taskType: TaskType;
  /**  timezone  */
  timezone?: InputMaybe<Scalars['String']>;
  /**  when the task was updated  */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TaskOccurrence = {
  __typename?: 'TaskOccurrence';
  /**  end time of the occurrence  */
  end: Scalars['DateTime'];
  /**  start time of the occurrence  */
  start: Scalars['DateTime'];
  /**  the task attached to the occurrence  */
  task: Task;
};

export type TaskProgress = {
  __typename?: 'TaskProgress';
  associatedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  created: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  progress: Scalars['Int'];
  resource?: Maybe<Scalars['String']>;
  source: KineticModule;
  status: TaskProgressStatus;
  systemTask?: Maybe<Scalars['Boolean']>;
  task: Scalars['String'];
  type: TaskProgressType;
  updated: Scalars['DateTime'];
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaskProgressConnection = {
  __typename?: 'TaskProgressConnection';
  edges: Array<TaskProgressConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TaskProgressConnectionEdge = {
  __typename?: 'TaskProgressConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TaskProgress;
};

export type TaskProgressQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter alarms.  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum TaskProgressStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

export enum TaskProgressType {
  FileDownload = 'FILE_DOWNLOAD',
  JobExecution = 'JOB_EXECUTION',
  SignalDatabaseDownload = 'SIGNAL_DATABASE_DOWNLOAD',
  SignalDatabaseUpload = 'SIGNAL_DATABASE_UPLOAD'
}

export enum TaskRecurrenceType {
  /**  creates a recurring task  */
  RecurringTask = 'RECURRING_TASK',
  /**  creates a single task  */
  SingleTask = 'SINGLE_TASK'
}

export enum TaskType {
  /**  camera public feed */
  CameraCutPublicFeed = 'CAMERA_CUT_PUBLIC_FEED',
  /**  camera home preset  */
  CameraHomePreset = 'CAMERA_HOME_PRESET',
  /**  camera recording  */
  CameraRecord = 'CAMERA_RECORD',
  InternalActivationEvent = 'INTERNAL_ACTIVATION_EVENT',
  InternalScheduledEvent = 'INTERNAL_SCHEDULED_EVENT',
  /**  resource plan recurrence  */
  ResourcePlanRecurrence = 'RESOURCE_PLAN_RECURRENCE',
  ScheduledEvent = 'SCHEDULED_EVENT',
  SignalActionSet = 'SIGNAL_ACTION_SET',
  SignalAlarmNotification = 'SIGNAL_ALARM_NOTIFICATION',
  SignalChangeFirmware = 'SIGNAL_CHANGE_FIRMWARE',
  SignalCommand = 'SIGNAL_COMMAND',
  SignalCommandSet = 'SIGNAL_COMMAND_SET',
  SignalDatabaseArchive = 'SIGNAL_DATABASE_ARCHIVE',
  SignalDatabaseBackup = 'SIGNAL_DATABASE_BACKUP',
  SignalReminder = 'SIGNAL_REMINDER',
  SignalTrafficResponsive = 'SIGNAL_TRAFFIC_RESPONSIVE'
}

export enum TemplateType {
  Email = 'EMAIL',
  EventReport = 'EVENT_REPORT',
  FiveOneOne = 'FIVE_ONE_ONE',
  Sms = 'SMS'
}

export type TenantIntegration = {
  __typename?: 'TenantIntegration';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  tenant?: Maybe<Scalars['String']>;
};

export type TimeDriftException = {
  __typename?: 'TimeDriftException';
  /**  A time without a time-zone in the ISO-8601 calendar system, such as 10:45:30 */
  end: Scalars['LocalTime'];
  /**  A time without a time-zone in the ISO-8601 calendar system, such as 10:15:30 */
  start: Scalars['LocalTime'];
};

export type TimeDriftExceptionInput = {
  /**  A time without a time-zone in the ISO-8601 calendar system, such as 10:45:30 */
  end: Scalars['LocalTime'];
  /**  A time without a time-zone in the ISO-8601 calendar system, such as 10:15:30 */
  start: Scalars['LocalTime'];
};

export type TimeDriftSettings = {
  __typename?: 'TimeDriftSettings';
  /**  The time drift threshold in seconds  */
  alarmThreshold: Scalars['Int'];
  /**  If true, kinetic mobility will send set time now.  */
  autoCorrect: Scalars['Boolean'];
  /**  Auto Correct threshold */
  correctionThreshold: Scalars['Int'];
  /**  The time drift exceptions */
  exceptions: Array<TimeDriftException>;
};

export type TimeDriftSettingsInput = {
  /**  The time drift threshold in seconds  */
  alarmThreshold: Scalars['Int'];
  /**  If true, kinetic mobility will send set time now.  */
  autoCorrect: Scalars['Boolean'];
  /**  Auto Correct threshold */
  correctionThreshold: Scalars['Int'];
  /**  The time drift exceptions */
  exceptions: Array<TimeDriftExceptionInput>;
};

export type TimeOfDay = {
  /**  hour – The hour of day, between 0 and 23. */
  hour?: InputMaybe<Scalars['Int']>;
  /**  minute – The minute of the hour, between 0 and 59. */
  minute?: InputMaybe<Scalars['Int']>;
  /**  second – The second of the minute, between 0 and 59. */
  second?: InputMaybe<Scalars['Int']>;
};

export type TimeSpaceNodeData = {
  __typename?: 'TimeSpaceNodeData';
  deviceId: Scalars['String'];
  downPhase: TimeSpacePhase;
  id: Scalars['String'];
  name: Scalars['String'];
  pattern: Array<TimeSpacePattern>;
  travelDown?: Maybe<TravelDirectionData>;
  travelUp?: Maybe<TravelDirectionData>;
  upPhase: TimeSpacePhase;
};

export type TimeSpaceOffsetData = {
  __typename?: 'TimeSpaceOffsetData';
  timestamp: Scalars['Int'];
  value: Scalars['Int'];
};

export type TimeSpacePattern = {
  __typename?: 'TimeSpacePattern';
  cycleLength: Scalars['Int'];
  from: Scalars['Int'];
  number: Scalars['Int'];
  offset: TimeSpaceOffsetData;
  programmedSplits?: Maybe<DirectionalProgrammedSplits>;
  to: Scalars['Int'];
};

export type TimeSpacePhase = {
  __typename?: 'TimeSpacePhase';
  historicData: Array<PhaseServiceTimes>;
  number?: Maybe<Scalars['Int']>;
};

export type Tour = {
  __typename?: 'Tour';
  description?: Maybe<Scalars['String']>;
  heartBeatIntervalSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  speed?: Maybe<Scalars['Int']>;
  steps: Array<TourStep>;
};

export type TourCamera = {
  __typename?: 'TourCamera';
  camera: Camera;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TourCameraInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TourConnection = {
  __typename?: 'TourConnection';
  edges: Array<TourConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TourConnectionEdge = {
  __typename?: 'TourConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Tour;
};

export type TourInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  speed?: InputMaybe<Scalars['Int']>;
  steps: Array<TourStepInput>;
};

export type TourInputQuery = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type TourPreset = {
  __typename?: 'TourPreset';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TourPresetInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TourStep = {
  __typename?: 'TourStep';
  camera: TourCamera;
  preset?: Maybe<TourPreset>;
};

export type TourStepInput = {
  camera: TourCameraInput;
  preset?: InputMaybe<TourPresetInput>;
};

export type TrafficEventCategory = {
  __typename?: 'TrafficEventCategory';
  categoryId: Scalars['Int'];
  categoryName: Scalars['String'];
  universalEventCodeRange: Scalars['String'];
};

export type TrafficEventDefinition = {
  __typename?: 'TrafficEventDefinition';
  eventCategoryId: Scalars['Int'];
  eventDefinitionType: TrafficEventDefinitionType;
  eventDescription?: Maybe<Scalars['String']>;
  parameterDescription?: Maybe<Scalars['String']>;
  universalEventCode?: Maybe<Scalars['Int']>;
  universalEventName?: Maybe<Scalars['String']>;
  vendorEventCode: Scalars['Int'];
  vendorEventName: Scalars['String'];
};

export enum TrafficEventDefinitionType {
  Econolite = 'ECONOLITE',
  Peek = 'PEEK',
  Qfree = 'QFREE',
  Siemens = 'SIEMENS'
}

export type TrafficResponsivePlan = {
  __typename?: 'TrafficResponsivePlan';
  actions: Array<TrafficResponsivePlanAction>;
  description?: Maybe<Scalars['String']>;
  frequency: Scalars['Int'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
};

export type TrafficResponsivePlanAction = {
  __typename?: 'TrafficResponsivePlanAction';
  command: Scalars['JSON'];
  detectorGroups: Array<TrafficResponsivePlanDetectorGroup>;
  id: Scalars['ID'];
  minimumImprovement: Scalars['Int'];
  name: Scalars['String'];
};

export type TrafficResponsivePlanActionInput = {
  command: Scalars['JSON'];
  detectorGroups: Array<TrafficResponsivePlanDetectorGroupInput>;
  id?: InputMaybe<Scalars['ID']>;
  minimumImprovement: Scalars['Int'];
  name: Scalars['String'];
};

export type TrafficResponsivePlanConnection = {
  __typename?: 'TrafficResponsivePlanConnection';
  edges: Array<TrafficResponsivePlanConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TrafficResponsivePlanConnectionEdge = {
  __typename?: 'TrafficResponsivePlanConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TrafficResponsivePlan;
};

export type TrafficResponsivePlanDetectorGroup = {
  __typename?: 'TrafficResponsivePlanDetectorGroup';
  detectorGroupId: Scalars['String'];
  kValue: Scalars['Float'];
  occupancy: Scalars['Int'];
  volume: Scalars['Int'];
};

export type TrafficResponsivePlanDetectorGroupInput = {
  detectorGroupId: Scalars['String'];
  kValue: Scalars['Float'];
  occupancy: Scalars['Int'];
  volume: Scalars['Int'];
};

export type TrafficResponsivePlanInput = {
  actions: Array<TrafficResponsivePlanActionInput>;
  description?: InputMaybe<Scalars['String']>;
  frequency: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
};

export type TranslatedNotification = {
  details?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TravelDirectionData = {
  __typename?: 'TravelDirectionData';
  distance: Scalars['Float'];
  speed: Scalars['Float'];
};

export type TravelTime = {
  __typename?: 'TravelTime';
  /**  associated signs  */
  associatedSigns: Array<Scalars['String']>;
  /**  communication state */
  communicationState?: Maybe<CommunicationState>;
  /**  description  */
  description?: Maybe<Scalars['String']>;
  /**  distance  */
  distance?: Maybe<Scalars['Float']>;
  /**  geometry  */
  geometry?: Maybe<Scalars['Object']>;
  /**  historic speed  */
  historicSpeed?: Maybe<Scalars['Float']>;
  /**  historic time  */
  historicTime?: Maybe<Scalars['Int']>;
  /**  identifier  */
  id: Scalars['ID'];
  /**  incremental reported time  */
  incrementalReportedTime: Scalars['Int'];
  /**  last connect time */
  lastConnect?: Maybe<Scalars['DateTime']>;
  /**  max reported delay  */
  maximumReportedDelay: Scalars['Int'];
  /**  max reported time  */
  maximumReportedTime: Scalars['Int'];
  /**  min reported delay  */
  minimumReportedDelay: Scalars['Int'];
  /**  min reported time  */
  minimumReportedTime: Scalars['Int'];
  /**  name  */
  name: Scalars['String'];
  /**  parts  */
  parts: Array<TravelTimePart>;
  /**  polling enabled  */
  pollingEnabled: Scalars['Boolean'];
  /**  data quality  */
  quality?: Maybe<TravelTimeDataQuality>;
  /**  reported time  */
  reportedTime?: Maybe<Scalars['Int']>;
  /**  smoothed time  */
  smoothedTime?: Maybe<Scalars['Int']>;
  /**  current speed in kph  */
  speed?: Maybe<Scalars['Float']>;
  /**  current travel time  */
  travelTime?: Maybe<Scalars['Int']>;
};

export type TravelTimeConnection = {
  __typename?: 'TravelTimeConnection';
  /**  Connection Edges  */
  edges: Array<TravelTimeConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type TravelTimeConnectionEdge = {
  __typename?: 'TravelTimeConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: TravelTime;
};

export enum TravelTimeDataQuality {
  /**  data is bad  */
  Bad = 'BAD',
  /**  data is good  */
  Good = 'GOOD',
  /**  no data received  */
  NoData = 'NO_DATA',
  /**  data is suspect  */
  Suspect = 'SUSPECT'
}

export type TravelTimeDisplayRequest = {
  /**  association display names  */
  associationDisplayNames?: InputMaybe<Array<Scalars['String']>>;
  /**  association identifiers  */
  associations?: InputMaybe<Array<Scalars['String']>>;
  /**  expiration time  */
  expirationTime?: InputMaybe<Scalars['DateTime']>;
  /**  sign identifier  */
  id: Scalars['ID'];
  /**  message template to display  */
  template: SignMessageInput;
};

export type TravelTimeDowHistory = {
  __typename?: 'TravelTimeDowHistory';
  /**  day of week  */
  dayOfWeek?: Maybe<Scalars['Int']>;
  /**  entity  */
  entity: Scalars['String'];
  /**  bucket period  */
  period?: Maybe<Scalars['Int']>;
  /**  number of samples  */
  samples?: Maybe<Scalars['Int']>;
  /**  speed  */
  speed?: Maybe<Scalars['Float']>;
  /**  travel time  */
  travelTime?: Maybe<Scalars['Float']>;
};

export type TravelTimeHistory = {
  __typename?: 'TravelTimeHistory';
  /**  entity identifier  */
  entityId: Scalars['ID'];
  /**  historic speed  */
  historicSpeed?: Maybe<Scalars['Float']>;
  /**  historic time  */
  historicTime?: Maybe<Scalars['Int']>;
  /**  identifier  */
  id: Scalars['ID'];
  /**  data quality  */
  quality?: Maybe<TravelTimeDataQuality>;
  /**  reported time  */
  reportedTime?: Maybe<Scalars['Int']>;
  /**  smoothed time  */
  smoothedTime?: Maybe<Scalars['Int']>;
  /**  current speed in kph  */
  speed?: Maybe<Scalars['Float']>;
  /**  timestamp  */
  timestamp: Scalars['DateTime'];
  /**  current travel time  */
  travelTime?: Maybe<Scalars['Int']>;
};

export type TravelTimeHistoryConnection = {
  __typename?: 'TravelTimeHistoryConnection';
  /**  Connection Edges  */
  edges: Array<TravelTimeHistoryConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type TravelTimeHistoryConnectionEdge = {
  __typename?: 'TravelTimeHistoryConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The TravelTime object  */
  node: TravelTimeHistory;
};

export type TravelTimeHistoryInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  end date  */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /**  identifier to filter history  */
  entityId: Scalars['String'];
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
  /**  start date */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type TravelTimeInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text. */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type TravelTimePart = {
  __typename?: 'TravelTimePart';
  /**  direction  */
  direction: Direction;
  /**  end reference  */
  endReference: Scalars['Float'];
  /**  road  */
  road: Scalars['String'];
  /**  start reference  */
  startReference: Scalars['Float'];
};

export type Tunnel = {
  __typename?: 'Tunnel';
  /**  The tunnel details  */
  details: TunnelDetails;
  /**  The location of the tunnel  */
  geometry: Scalars['Object'];
  /**  The identifier of the tunnel  */
  id: Scalars['ID'];
  /**  The list of integrations  */
  integrations: Array<TunnelIntegration>;
  /**  The list of individual zones  */
  zones: Array<TunnelZone>;
};

export type TunnelConnection = {
  __typename?: 'TunnelConnection';
  /**  Connection Edges  */
  edges: Array<TunnelConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type TunnelConnectionEdge = {
  __typename?: 'TunnelConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The tunnel object  */
  node: Tunnel;
};

export type TunnelDetails = {
  __typename?: 'TunnelDetails';
  /**  The description of the tunnel  */
  description?: Maybe<Scalars['String']>;
  /**  The direction of the tunnel  */
  direction: Scalars['String'];
  /**  The end reference point of the tunnel  */
  endReference: Scalars['Float'];
  /**  The name of the tunnel  */
  name: Scalars['String'];
  /**  The road of the tunnel  */
  road: Scalars['String'];
  /**  The start reference point of the tunnel  */
  startReference: Scalars['Float'];
};

export type TunnelDetailsInput = {
  /**  The description of the tunnel  */
  description?: InputMaybe<Scalars['String']>;
  /**  The direction of the tunnel  */
  direction: Scalars['String'];
  /**  The end reference point of the tunnel  */
  endReference: Scalars['Float'];
  /**  The name of the tunnel  */
  name: Scalars['String'];
  /**  The road of the tunnel  */
  road: Scalars['String'];
  /**  The start reference point of the tunnel  */
  startReference: Scalars['Float'];
};

export type TunnelInput = {
  /**  The tunnel details  */
  details: TunnelDetailsInput;
  /**  The location of the tunnel  */
  geometry: Scalars['Object'];
  /**  The identifier of the tunnel  */
  id?: InputMaybe<Scalars['ID']>;
  /**  The list of integrations  */
  integrations: Array<TunnelIntegrationInput>;
  /**  The list of individual zones  */
  zones: Array<TunnelZoneInput>;
};

export type TunnelInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type TunnelIntegration = {
  __typename?: 'TunnelIntegration';
  /**  Attribute data specific to device  */
  attributes: Scalars['Object'];
  /**  The identifier of the device  */
  id: Scalars['ID'];
  /**  Type of integration  */
  type: TunnelIntegrationType;
};

export type TunnelIntegrationInput = {
  /**  Attribute data specific to device  */
  attributes: Scalars['Object'];
  /**  The identifier of the device  */
  id: Scalars['ID'];
  /**  Type of integration  */
  type: TunnelIntegrationType;
};

export enum TunnelIntegrationType {
  /**  Camera field Integration Type  */
  Camera = 'CAMERA',
  /**  Entrance Sign Integration Type  */
  EntranceSign = 'ENTRANCE_SIGN',
  /**  Lane Control Integration Type  */
  LaneControl = 'LANE_CONTROL',
  /**  Sign field Integration Type  */
  Sign = 'SIGN'
}

export type TunnelLog = {
  __typename?: 'TunnelLog';
  /**  time at which log was created  */
  created: Scalars['DateTime'];
  /**  the event details  */
  event: Scalars['String'];
  /**  event info  */
  eventInfo?: Maybe<Scalars['String']>;
  /**  identifier of the log entry  */
  id: Scalars['ID'];
  /**  notes  */
  notes?: Maybe<Scalars['String']>;
  /**  severity of the log entry  */
  severity: TunnelLogSeverity;
  /**  the source of the log  */
  source: Scalars['String'];
  /**  identifier of the tunnel  */
  tunnelId: Scalars['ID'];
  /**  time at which log was updated  */
  updated: Scalars['DateTime'];
  /**  the user of the entry  */
  user: OrganizationKineticUser;
  /**  if the log entry is valid  */
  valid: Scalars['Boolean'];
};

export type TunnelLogConnection = {
  __typename?: 'TunnelLogConnection';
  /**  Connection Edges  */
  edges: Array<TunnelLogConnectionEdge>;
  /**  Page Info  */
  pageInfo: PageInfo;
  /**  Total number of results  */
  totalCount: Scalars['Int'];
};

export type TunnelLogConnectionEdge = {
  __typename?: 'TunnelLogConnectionEdge';
  /**  Edge location in page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The tunnel log object  */
  node: TunnelLog;
};

export type TunnelLogInput = {
  /**  the event details  */
  event: Scalars['String'];
  /**  event info  */
  eventInfo?: InputMaybe<Scalars['String']>;
  /**  identifier of the log entry  */
  id?: InputMaybe<Scalars['ID']>;
  /**  notes  */
  notes?: InputMaybe<Scalars['String']>;
  /**  severity of the log entry  */
  severity: TunnelLogSeverity;
  /**  the source of the log  */
  source: Scalars['String'];
  /**  identifier of the tunnel  */
  tunnelId: Scalars['ID'];
  /**  if the log entry is valid  */
  valid: Scalars['Boolean'];
};

export type TunnelLogInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter routes  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Field to sort object results  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Order to sort results  */
  sortOrder?: InputMaybe<SortOrder>;
  /**  The tunnel identifier  */
  tunnelId: Scalars['ID'];
};

export enum TunnelLogSeverity {
  /**  high  */
  High = 'HIGH',
  /**  low  */
  Low = 'LOW',
  /**  moderate  */
  Moderate = 'MODERATE',
  /**  unknown  */
  Unknown = 'UNKNOWN'
}

export type TunnelZone = {
  __typename?: 'TunnelZone';
  /**  The end reference point of the zone  */
  endReference: Scalars['Float'];
  /**  The identifier of the tunnel  */
  id: Scalars['ID'];
  /**  The number of lanes in the zone  */
  laneCount: Scalars['Int'];
  /**  The name of the zone  */
  name: Scalars['String'];
  /**  The start reference point of the zone  */
  startReference: Scalars['Float'];
};

export type TunnelZoneInput = {
  /**  The end reference point of the zone  */
  endReference: Scalars['Float'];
  /**  The identifier of the tunnel  */
  id: Scalars['ID'];
  /**  The number of lanes in the zone  */
  laneCount: Scalars['Int'];
  /**  The name of the zone  */
  name: Scalars['String'];
  /**  The start reference point of the zone  */
  startReference: Scalars['Float'];
};

export type UserAssignmentIds = {
  __typename?: 'UserAssignmentIds';
  restrictedIntegrationGroups: Array<Scalars['String']>;
  restrictedIntegrations: Array<Scalars['String']>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  key: Scalars['ID'];
  value: Scalars['JSON'];
};

export type UserPreferenceStreamObject = {
  __typename?: 'UserPreferenceStreamObject';
  id: Scalars['ID'];
  preferenceKey: Scalars['String'];
  streamObjectOperation: StreamObjectOperation;
  userPreference: UserPreference;
};

export type ValueAggregation = {
  __typename?: 'ValueAggregation';
  /**  Number of occurrences for this value */
  count: Scalars['Int'];
  /**  Previous trend of occurrences for this value */
  trend: Scalars['Int'];
  value: Scalars['String'];
};

export enum VerticalJustification {
  Bottom = 'BOTTOM',
  Middle = 'MIDDLE',
  Top = 'TOP'
}

export enum VideoItemType {
  Camera = 'CAMERA',
  Tour = 'TOUR',
  Widget = 'WIDGET'
}

export type VideoRecord = {
  __typename?: 'VideoRecord';
  camera?: Maybe<Camera>;
  cameraId: Scalars['String'];
  cameraName: Scalars['String'];
  id: Scalars['ID'];
  recorderId: Scalars['String'];
  recorderName: Scalars['String'];
  recordingEnd: Scalars['DateTime'];
  recordingInitiatedBy: RecordingInitiatedBy;
  recordingInitiatorId?: Maybe<Scalars['String']>;
  recordingInitiatorProperties?: Maybe<Scalars['JSON']>;
  recordingStart: Scalars['DateTime'];
  recordingType: RecordingType;
  serverFileName?: Maybe<Scalars['String']>;
  streamUrl: Scalars['String'];
};

export type VideoRecordConnection = {
  __typename?: 'VideoRecordConnection';
  edges: Array<VideoRecordConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VideoRecordConnectionEdge = {
  __typename?: 'VideoRecordConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: VideoRecord;
};

export type VideoStream = {
  __typename?: 'VideoStream';
  accessType?: Maybe<VideoStreamAccessType>;
  application?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recordingServer?: Maybe<Scalars['String']>;
  streamManager?: Maybe<Scalars['String']>;
  streamName?: Maybe<Scalars['String']>;
  streamTargetName?: Maybe<Scalars['String']>;
  streamType: Scalars['String'];
  url: Scalars['String'];
};

export enum VideoStreamAccessType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Trusted = 'TRUSTED'
}

export type VideoWallObject = CameraGroup | Scene | Screen | Widget;

export enum VideoWallObjectType {
  Group = 'GROUP',
  Scene = 'SCENE',
  Screen = 'SCREEN',
  Widget = 'WIDGET'
}

export type VideoWallStreamObject = {
  __typename?: 'VideoWallStreamObject';
  id: Scalars['ID'];
  streamObjectOperation: StreamObjectOperation;
  videoWallObject?: Maybe<VideoWallObject>;
  videoWallObjectType: VideoWallObjectType;
};

export type WatchedTour = {
  __typename?: 'WatchedTour';
  camera: Camera;
  isPaused?: Maybe<Scalars['Boolean']>;
};

export type Weather = {
  __typename?: 'Weather';
  base?: Maybe<Scalars['String']>;
  clouds?: Maybe<WeatherClouds>;
  cod?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  hour?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  main?: Maybe<WeatherMain>;
  name?: Maybe<Scalars['String']>;
  sys?: Maybe<WeatherSys>;
  timestamp?: Maybe<Scalars['DateTime']>;
  visibility?: Maybe<Scalars['Int']>;
  weather?: Maybe<Array<Maybe<WeatherDetail>>>;
  wind?: Maybe<WeatherWind>;
};

export type WeatherClouds = {
  __typename?: 'WeatherClouds';
  all?: Maybe<Scalars['Int']>;
};

export type WeatherDetail = {
  __typename?: 'WeatherDetail';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  main?: Maybe<Scalars['String']>;
};

export type WeatherMain = {
  __typename?: 'WeatherMain';
  feels_like?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Int']>;
  pressure?: Maybe<Scalars['Int']>;
  temp?: Maybe<Scalars['Float']>;
  temp_max?: Maybe<Scalars['Float']>;
  temp_min?: Maybe<Scalars['Float']>;
};

export enum WeatherSensorCategory {
  /**  Atmospheric  */
  Atmospheric = 'ATMOSPHERIC',
  /**  Pavement  */
  Pavement = 'PAVEMENT',
  /**  Subsurface  */
  Subsurface = 'SUBSURFACE'
}

export enum WeatherSensorType {
  /**  Average Wind Direction  */
  AverageWindDirection = 'AVERAGE_WIND_DIRECTION',
  /**  Average Wind Speed  */
  AverageWindSpeed = 'AVERAGE_WIND_SPEED',
  /**  Dew Point Temperature  */
  DewPointTemperature = 'DEW_POINT_TEMPERATURE',
  /**  Humidity  */
  Humidity = 'HUMIDITY',
  /**  Max Temperature  */
  MaxTemperature = 'MAX_TEMPERATURE',
  /**  Min Temperature  */
  MinTemperature = 'MIN_TEMPERATURE',
  /**  Pavement Black Ice Signal  */
  PavementBlackIceSignal = 'PAVEMENT_BLACK_ICE_SIGNAL',
  /**  Pavement Elevation  */
  PavementElevation = 'PAVEMENT_ELEVATION',
  /**  Pavement Exposure  */
  PavementExposure = 'PAVEMENT_EXPOSURE',
  /**  Pavement Freezing Point  */
  PavementFreezingPoint = 'PAVEMENT_FREEZING_POINT',
  /**  Pavement Salinity  */
  PavementSalinity = 'PAVEMENT_SALINITY',
  /**  Pavement Sensor Error  */
  PavementSensorError = 'PAVEMENT_SENSOR_ERROR',
  /**  Pavement Sensor Type  */
  PavementSensorType = 'PAVEMENT_SENSOR_TYPE',
  /**  Pavement Status  */
  PavementStatus = 'PAVEMENT_STATUS',
  /**  Pavement Temperature  */
  PavementTemperature = 'PAVEMENT_TEMPERATURE',
  /**  Pavement Type  */
  PavementType = 'PAVEMENT_TYPE',
  /**  Precipitation End Time  */
  PrecipitationEndTime = 'PRECIPITATION_END_TIME',
  /**  Precipitation Present  */
  PrecipitationPresent = 'PRECIPITATION_PRESENT',
  /**  Precipitation Rate  */
  PrecipitationRate = 'PRECIPITATION_RATE',
  /**  Precipitation Rate 1 Hour  */
  PrecipitationRate_1Hr = 'PRECIPITATION_RATE_1HR',
  /**  Precipitation Rate 3 Hour  */
  PrecipitationRate_3Hr = 'PRECIPITATION_RATE_3HR',
  /**  Precipitation Rate 6 Hour  */
  PrecipitationRate_6Hr = 'PRECIPITATION_RATE_6HR',
  /**  Precipitation Rate 12 Hour  */
  PrecipitationRate_12Hr = 'PRECIPITATION_RATE_12HR',
  /**  Precipitation Rate 24 Hour  */
  PrecipitationRate_24Hr = 'PRECIPITATION_RATE_24HR',
  /**  Precipitation Situation  */
  PrecipitationSituation = 'PRECIPITATION_SITUATION',
  /**  Precipitation Start Time  */
  PrecipitationStartTime = 'PRECIPITATION_START_TIME',
  /**  Pressure  */
  Pressure = 'PRESSURE',
  /**  Solar Radiation  */
  SolarRadiation = 'SOLAR_RADIATION',
  /**  Spot Wind Direction  */
  SpotWindDirection = 'SPOT_WIND_DIRECTION',
  /**  Spot Wind Speed  */
  SpotWindSpeed = 'SPOT_WIND_SPEED',
  /**  Subsurface Error  */
  SubsurfaceError = 'SUBSURFACE_ERROR',
  /**  Subsurface Moisture  */
  SubsurfaceMoisture = 'SUBSURFACE_MOISTURE',
  /**  Subsurface Temperature  */
  SubsurfaceTemperature = 'SUBSURFACE_TEMPERATURE',
  /**  Temperature  */
  Temperature = 'TEMPERATURE',
  /**  Total Sun  */
  TotalSun = 'TOTAL_SUN',
  /**  Visibility  */
  Visibility = 'VISIBILITY',
  /**  Wet Bulb Temperature  */
  WetBulbTemperature = 'WET_BULB_TEMPERATURE',
  /**  Gust Wind Direction  */
  WindGustDirection = 'WIND_GUST_DIRECTION',
  /**  Gust Wind Speed  */
  WindGustSpeed = 'WIND_GUST_SPEED',
  /**  Wind Situation  */
  WindSituation = 'WIND_SITUATION'
}

export type WeatherStation = {
  __typename?: 'WeatherStation';
  communicationParameters?: Maybe<Scalars['Object']>;
  /**  The current communication status of the weather station  */
  communicationStatus: CommunicationState;
  /**  The description of the weather station  */
  description?: Maybe<Scalars['String']>;
  /**  The direction of the weather station  */
  direction?: Maybe<Scalars['String']>;
  /**  The identifier of the weather station  */
  id: Scalars['ID'];
  /**  The last time the weather station was successfully polled  */
  lastDataReceived?: Maybe<Scalars['DateTime']>;
  /** The coordinate of the weather station  */
  location: Scalars['JSON'];
  /**  The location description  */
  locationDescription?: Maybe<Scalars['String']>;
  /**  The name of the weather station  */
  name: Scalars['String'];
  /**  current readings for the station  */
  readings: Array<WeatherStationReading>;
  /**  The reference point of the weather station  */
  referencePoint?: Maybe<Scalars['Float']>;
  /**  The road of the weather station  */
  road?: Maybe<Scalars['String']>;
  /**  Map of current values to sensor types  */
  values: Scalars['Object'];
};

export type WeatherStationChartHistory = {
  __typename?: 'WeatherStationChartHistory';
  /**  The time series data for the type  */
  data: Array<WeatherStationChartHistoryData>;
  /**  The link address for the sensor  */
  linkAddress: Scalars['Int'];
  /**  The type of weather sensor  */
  type: WeatherSensorType;
};

export type WeatherStationChartHistoryData = {
  __typename?: 'WeatherStationChartHistoryData';
  /**  The timestamp of the data  */
  timestamp: Scalars['DateTime'];
  /**  The value of the data  */
  value: Scalars['Object'];
};

export type WeatherStationChartHistoryInputQuery = {
  /**  The end date time to filter data  */
  end: Scalars['DateTime'];
  /**  The start date time to filter data  */
  start: Scalars['DateTime'];
  /**  The weather sensor type to filter data  */
  types?: InputMaybe<Array<WeatherSensorType>>;
  /**  Identifier of the weather station  */
  weatherStationId: Scalars['ID'];
};

export type WeatherStationConnection = {
  __typename?: 'WeatherStationConnection';
  /**  The edges of the connection object  */
  edges: Array<WeatherStationConnectionEdge>;
  /**  The page info of the connection object  */
  pageInfo: PageInfo;
  /**  The total number of results for the query  */
  totalCount: Scalars['Int'];
};

export type WeatherStationConnectionEdge = {
  __typename?: 'WeatherStationConnectionEdge';
  /**  The position of this object in the page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The weather station object  */
  node: WeatherStation;
};

export type WeatherStationHistory = {
  __typename?: 'WeatherStationHistory';
  /**  The category of the reading  */
  category: WeatherSensorCategory;
  /**  The identifier of the history record  */
  id: Scalars['ID'];
  /**  The link address of the reading, if present  */
  linkAddress?: Maybe<Scalars['Int']>;
  /**  The readings attached to the history entry  */
  readings: Array<Maybe<WeatherStationReading>>;
  /**  The timestamp at which this data was recorded  */
  timestamp: Scalars['DateTime'];
  /**  The identifier of the station  */
  weatherStationId: Scalars['ID'];
};

export type WeatherStationHistoryConnection = {
  __typename?: 'WeatherStationHistoryConnection';
  /**  The edges of the connection object  */
  edges: Array<WeatherStationHistoryConnectionEdge>;
  /**  The page info of the connection object  */
  pageInfo: PageInfo;
  /**  The total number of results for the query  */
  totalCount: Scalars['Int'];
};

export type WeatherStationHistoryConnectionEdge = {
  __typename?: 'WeatherStationHistoryConnectionEdge';
  /**  The position of this object in the page  */
  cursor?: Maybe<Scalars['String']>;
  /**  The weather station object  */
  node: WeatherStationHistory;
};

export type WeatherStationHistoryInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The sensor category to filter  */
  category?: InputMaybe<WeatherSensorCategory>;
  /**  End time filter  */
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  /**  The fields and values to filter  */
  filters?: InputMaybe<Array<FilterInput>>;
  /**  Identifier of the weather station  */
  id: Scalars['ID'];
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The sensor link addresses  */
  linkAddresses?: InputMaybe<Array<Scalars['Int']>>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for.  */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<Scalars['String']>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
  /**  Start time filter  */
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type WeatherStationInputQuery = {
  /**  Fetch items after this cursor. Cannot be used with before  */
  after?: InputMaybe<Scalars['String']>;
  /**  Fetch items before this cursor. Cannot be used with after  */
  before?: InputMaybe<Scalars['String']>;
  /**  The fields and values to filter integrations  */
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  /**  Limit the number of results. We do not allow returning all docs.  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  The fields to search for the search text.  */
  searchFields?: InputMaybe<Array<Scalars['String']>>;
  /**  Text to search for. */
  searchText?: InputMaybe<Scalars['String']>;
  /**  Which field to sort the results on.  */
  sortField?: InputMaybe<WeatherStationSortField>;
  /**  Which direction to sort the field on.  */
  sortOrder?: InputMaybe<SortOrder>;
};

export type WeatherStationReading = {
  __typename?: 'WeatherStationReading';
  /**  The category that this reading belongs to  */
  category: WeatherSensorCategory;
  /**  Index of the sensor for the reading  */
  linkAddress: Scalars['Int'];
  /**  Raw value of the reading  */
  rawValue?: Maybe<Scalars['Object']>;
  /**  The sensor type of the reading  */
  type: WeatherSensorType;
  /**  The units of the reading  */
  units: WeatherUnits;
  /**  If the sensor reading is valid  */
  valid: Scalars['Boolean'];
  /**  The current value of the reading  */
  value: Scalars['Object'];
};

export enum WeatherStationSortField {
  /**  Communication Status  */
  CommunicationStatus = 'COMMUNICATION_STATUS',
  /**  Data Received  */
  DataReceived = 'DATA_RECEIVED',
  /**  Dew Point Temperature  */
  DewPointTemperature = 'DEW_POINT_TEMPERATURE',
  /**  Humidity  */
  Humidity = 'HUMIDITY',
  /**  Name  */
  Name = 'NAME',
  /**  Pavement Status  */
  PavementStatus = 'PAVEMENT_STATUS',
  /**  Precipitation Rate  */
  PrecipitationRate = 'PRECIPITATION_RATE',
  /**  Precipitation Situation  */
  PrecipitationSituation = 'PRECIPITATION_SITUATION',
  /**  Subsurface Temperature  */
  SubsurfaceTemperature = 'SUBSURFACE_TEMPERATURE',
  /**  Temperature  */
  Temperature = 'TEMPERATURE',
  /**  Visibility  */
  Visibility = 'VISIBILITY',
  /**  Wind Gust Direction  */
  WindGustDirection = 'WIND_GUST_DIRECTION',
  /**  Wind Gust Speed  */
  WindGustSpeed = 'WIND_GUST_SPEED'
}

export type WeatherSys = {
  __typename?: 'WeatherSys';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sunrise?: Maybe<Scalars['Int']>;
  sunset?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export enum WeatherUnits {
  /**  Boolean  */
  Boolean = 'BOOLEAN',
  /**  Centimeters  */
  Centimeters = 'CENTIMETERS',
  /**  Centimeters Per Hour  */
  CentimetersPerHour = 'CENTIMETERS_PER_HOUR',
  /**  Degrees  */
  Degrees = 'DEGREES',
  /**  Degrees Celsius  */
  DegreesCelsius = 'DEGREES_CELSIUS',
  /**  Degrees Fahrenheit  */
  DegreesFahrenheit = 'DEGREES_FAHRENHEIT',
  /**  Feet  */
  Feet = 'FEET',
  /**  Inches  */
  Inches = 'INCHES',
  /**  Inches Mercury  */
  InchesMercury = 'INCHES_MERCURY',
  /**  Inches Per Hour  */
  InchesPerHour = 'INCHES_PER_HOUR',
  /**  Kilometers  */
  Kilometers = 'KILOMETERS',
  /**  Kilometers Per Hour  */
  KilometersPerHour = 'KILOMETERS_PER_HOUR',
  /**  Knots  */
  Knots = 'KNOTS',
  /**  Meters  */
  Meters = 'METERS',
  /**  Meters Per Hour  */
  MetersPerHour = 'METERS_PER_HOUR',
  /**  Meters Per Second  */
  MetersPerSecond = 'METERS_PER_SECOND',
  /**  Miles  */
  Miles = 'MILES',
  /**  Miles Per Hour  */
  MilesPerHour = 'MILES_PER_HOUR',
  /**  Millibars  */
  Millibars = 'MILLIBARS',
  /**  Millimeters  */
  Millimeters = 'MILLIMETERS',
  /**  Millimeters Per Hour  */
  MillimetersPerHour = 'MILLIMETERS_PER_HOUR',
  /**  Newtons  */
  Newtons = 'NEWTONS',
  /**  None  */
  None = 'NONE',
  /**  Parts Per Billion By Volume  */
  PartsPerBillionByVolume = 'PARTS_PER_BILLION_BY_VOLUME',
  /**  Parts Per Million By Volume  */
  PartsPerMillionByVolume = 'PARTS_PER_MILLION_BY_VOLUME',
  /**  Percentage  */
  Percentage = 'PERCENTAGE',
  /**  Practical Salinity Unit  */
  PracticalSalinityUnit = 'PRACTICAL_SALINITY_UNIT',
  /**  Siemens Per Meter  */
  SiemensPerMeter = 'SIEMENS_PER_METER',
  /**  Volts  */
  Volts = 'VOLTS',
  /**  Watts Per Square Meter  */
  WattsPerSquareMeter = 'WATTS_PER_SQUARE_METER'
}

export type WeatherWind = {
  __typename?: 'WeatherWind';
  deg?: Maybe<Scalars['Int']>;
  speed?: Maybe<Scalars['Float']>;
};

export type Widget = {
  __typename?: 'Widget';
  id: Scalars['ID'];
  name: Scalars['String'];
  properties: Scalars['JSON'];
  type: WidgetType;
};

export type WidgetInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties: Scalars['JSON'];
  type: WidgetType;
};

export type WidgetListFormat = {
  searchFields?: InputMaybe<Array<WidgetSearchField>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<WidgetSortField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum WidgetSearchField {
  Name = 'NAME'
}

export enum WidgetSortField {
  Id = 'ID',
  Name = 'NAME'
}

export enum WidgetType {
  Iframe = 'IFRAME'
}

export enum WindSituation {
  /**  Calm  */
  Calm = 'CALM',
  /**  Gale  */
  Gale = 'GALE',
  /**  Gusty Winds  */
  GustyWinds = 'GUSTY_WINDS',
  /**  Hurricane Force Winds  */
  HurricaneForceWinds = 'HURRICANE_FORCE_WINDS',
  /**  Light Breeze  */
  LightBreeze = 'LIGHT_BREEZE',
  /**  Moderate Breeze  */
  ModerateBreeze = 'MODERATE_BREEZE',
  /**  Moderate Gale  */
  ModerateGale = 'MODERATE_GALE',
  /**  Other  */
  Other = 'OTHER',
  /**  Strong Winds  */
  StormWinds = 'STORM_WINDS',
  /**  Strong Breeze  */
  StrongBreeze = 'STRONG_BREEZE',
  /**  Strong Gale  */
  StrongGale = 'STRONG_GALE',
  /**  Unknown  */
  Unknown = 'UNKNOWN'
}

export type _Entity = DetectorReportConfiguration | DetectorReportRule | OrganizationKineticUser;

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type FieldConfigFragmentFragment = { __typename?: 'FieldConfig', id: string, name: string, label: string, type: string, required?: boolean | null, help?: string | null, hint?: string | null, fieldSize?: string | null, sortOrder?: string | null, disablePast?: boolean | null, disableFuture?: boolean | null, useStepper?: boolean | null, max?: number | null, min?: number | null, step?: number | null, multiple?: boolean | null, limitTags?: number | null, selectAllLabel?: string | null, deselectAllLabel?: string | null, useCheckedIcon?: boolean | null, useIcon?: boolean | null, choicesTitle?: string | null, defaultValue?: any | null, format?: string | null, displayFormat?: string | null, activeLogic?: any | null, requiredLogic?: any | null, moduleName?: string | null, sourceId?: string | null, uploadType?: string | null, accept?: string | null, loading?: boolean | null, valueLabelDisplay?: string | null, showInput?: boolean | null, maxLength?: number | null, uniqueFields?: Array<string | null> | null, multiline?: boolean | null, rows?: number | null, mode?: string | null, disabled?: boolean | null, disabledLogic?: any | null, options?: Array<{ __typename?: 'FieldConfigOption', label?: string | null, value?: any | null } | null> | null };

type FormSectionFragment_DetectorReportConfigFormSection_Fragment = { __typename?: 'DetectorReportConfigFormSection', name: string, id: string, fields: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig', fieldConfigs?: Array<(
          { __typename?: 'FieldConfig' }
          & FieldConfigFragmentFragment
        )> | null }
        & FieldConfigFragmentFragment
      )> | null }
      & FieldConfigFragmentFragment
    )> | null }
    & FieldConfigFragmentFragment
  )> };

type FormSectionFragment_IntegrationConfigFormSection_Fragment = { __typename?: 'IntegrationConfigFormSection', name: string, id: string, fields: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig', fieldConfigs?: Array<(
          { __typename?: 'FieldConfig' }
          & FieldConfigFragmentFragment
        )> | null }
        & FieldConfigFragmentFragment
      )> | null }
      & FieldConfigFragmentFragment
    )> | null }
    & FieldConfigFragmentFragment
  ) | null> };

type FormSectionFragment_IntegrationStateSection_Fragment = { __typename?: 'IntegrationStateSection', name: string, id: string, fields: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig', fieldConfigs?: Array<(
          { __typename?: 'FieldConfig' }
          & FieldConfigFragmentFragment
        )> | null }
        & FieldConfigFragmentFragment
      )> | null }
      & FieldConfigFragmentFragment
    )> | null }
    & FieldConfigFragmentFragment
  ) | null> };

type FormSectionFragment_ModuleFormSection_Fragment = { __typename?: 'ModuleFormSection', name: string, id: string, fields: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig', fieldConfigs?: Array<(
          { __typename?: 'FieldConfig' }
          & FieldConfigFragmentFragment
        )> | null }
        & FieldConfigFragmentFragment
      )> | null }
      & FieldConfigFragmentFragment
    )> | null }
    & FieldConfigFragmentFragment
  ) | null> };

export type FormSectionFragmentFragment = FormSectionFragment_DetectorReportConfigFormSection_Fragment | FormSectionFragment_IntegrationConfigFormSection_Fragment | FormSectionFragment_IntegrationStateSection_Fragment | FormSectionFragment_ModuleFormSection_Fragment;

export type ModuleFormSectionFragmentFragment = { __typename?: 'ModuleFormSection', name: string, id: string, fields: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig', fieldConfigs?: Array<(
          { __typename?: 'FieldConfig' }
          & FieldConfigFragmentFragment
        )> | null }
        & FieldConfigFragmentFragment
      )> | null }
      & FieldConfigFragmentFragment
    )> | null }
    & FieldConfigFragmentFragment
  ) | null> };

type FormViewFragment_DetectorReportConfigFormView_Fragment = { __typename?: 'DetectorReportConfigFormView', type: FormViewType, id: string, name: string, sections: Array<(
    { __typename?: 'DetectorReportConfigFormSection' }
    & FormSectionFragment_DetectorReportConfigFormSection_Fragment
  )> };

type FormViewFragment_IntegrationConfigFormView_Fragment = { __typename?: 'IntegrationConfigFormView', type: FormViewType, id: string, name: string, sections: Array<(
    { __typename?: 'IntegrationConfigFormSection' }
    & FormSectionFragment_IntegrationConfigFormSection_Fragment
  )> };

type FormViewFragment_IntegrationStateView_Fragment = { __typename?: 'IntegrationStateView', type: FormViewType, id: string, name: string, sections: Array<(
    { __typename?: 'IntegrationStateSection' }
    & FormSectionFragment_IntegrationStateSection_Fragment
  )> };

type FormViewFragment_ModuleSettingsFormView_Fragment = { __typename?: 'ModuleSettingsFormView', type: FormViewType, id: string, name: string, sections: Array<(
    { __typename?: 'ModuleFormSection' }
    & FormSectionFragment_ModuleFormSection_Fragment
  )> };

export type FormViewFragmentFragment = FormViewFragment_DetectorReportConfigFormView_Fragment | FormViewFragment_IntegrationConfigFormView_Fragment | FormViewFragment_IntegrationStateView_Fragment | FormViewFragment_ModuleSettingsFormView_Fragment;

export type NotificationFragmentFragment = { __typename?: 'Notification', id: string, userIds: Array<string | null>, systemNotification?: boolean | null, source: KineticModule, severity?: NotificationSeverity | null, createdTime?: any | null, acknowledgedTime?: any | null, acknowledgedNotes?: string | null, acknowledgedBy?: string | null, acknowledgedByName?: string | null, location?: any | null, objectId?: string | null, ignorable?: boolean | null, title: string, details?: string | null, updatedTS?: any | null, route?: string | null, data: any, actions?: Array<{ __typename?: 'Action', route?: string | null, title?: string | null, toolTip?: string | null } | null> | null };

export type AcknowledgeNotificationMutationVariables = Exact<{
  id: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
}>;


export type AcknowledgeNotificationMutation = { __typename?: 'Mutation', acknowledgeNotification?: { __typename?: 'Notification', id: string, acknowledgedBy?: string | null, acknowledgedTime?: any | null } | null };

export type DismissNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DismissNotificationMutation = { __typename?: 'Mutation', dismissNotification: boolean };

export type DismissNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type DismissNotificationsMutation = { __typename?: 'Mutation', dismissNotifications: boolean };

export type UserNotificationsQueryVariables = Exact<{
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type UserNotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number, edges: Array<{ __typename?: 'NotificationConnectionEdge', cursor?: string | null, node: (
        { __typename?: 'Notification' }
        & NotificationFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SystemNotificationsListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  notificationSortField?: InputMaybe<NotificationSortField>;
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
}>;


export type SystemNotificationsListQuery = { __typename?: 'Query', systemNotifications: { __typename?: 'NotificationConnection', totalCount: number, edges: Array<{ __typename?: 'NotificationConnectionEdge', node: (
        { __typename?: 'Notification' }
        & NotificationFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type UserNotificationsListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  limit?: InputMaybe<Scalars['Int']>;
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type UserNotificationsListQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number, edges: Array<{ __typename?: 'NotificationConnectionEdge', cursor?: string | null, node: (
        { __typename?: 'Notification' }
        & NotificationFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type UserNotificationUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationUpdatedSubscription = { __typename?: 'Subscription', notificationUpdated?: { __typename?: 'Notification', id: string, acknowledgedTime?: any | null, urgentNotification?: boolean | null, acknowledgedBy?: string | null } | null };

export type ClearAllPreferencesMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearAllPreferencesMutation = { __typename?: 'Mutation', removeUserPreferences: boolean };

export type SaveKineticUserPrefMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type SaveKineticUserPrefMutation = { __typename?: 'Mutation', saveKineticUserPreference: any };

export type SaveModuleMapSettingsMutationVariables = Exact<{
  settings: Scalars['JSON'];
}>;


export type SaveModuleMapSettingsMutation = { __typename?: 'Mutation', saveModuleMapSettings?: boolean | null };

export type UserTenantPreferenceUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserTenantPreferenceUpdatedSubscription = { __typename?: 'Subscription', userPreferenceUpdated: { __typename?: 'UserPreferenceStreamObject', streamObjectOperation: StreamObjectOperation, userPreference: { __typename?: 'UserPreference', key: string, value: any } } };

export type UserKineticPreferenceUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserKineticPreferenceUpdatedSubscription = { __typename?: 'Subscription', kineticUserPreferenceUpdated: { __typename?: 'UserPreferenceStreamObject', streamObjectOperation: StreamObjectOperation, userPreference: { __typename?: 'UserPreference', key: string, value: any } } };

export type ModuleMapSettingsQueryVariables = Exact<{
  formViewName: Scalars['String'];
}>;


export type ModuleMapSettingsQuery = { __typename?: 'Query', moduleMapSettings: { __typename?: 'ModuleSettingsFormView', id: string, name: string, type: FormViewType, views: Array<{ __typename?: 'DetectorReportConfigFormView', id: string, name: string, views: Array<(
        { __typename?: 'DetectorReportConfigFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        )> }
        & FormViewFragment_DetectorReportConfigFormView_Fragment
      )> } | { __typename?: 'IntegrationConfigFormView', id: string, name: string, views: Array<(
        { __typename?: 'IntegrationConfigFormView', views: Array<(
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        )> }
        & FormViewFragment_IntegrationConfigFormView_Fragment
      )> } | { __typename?: 'IntegrationStateView', id: string, name: string, views: Array<(
        { __typename?: 'IntegrationStateView', views: Array<(
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        )> }
        & FormViewFragment_IntegrationStateView_Fragment
      )> } | { __typename?: 'ModuleSettingsFormView', id: string, name: string, views: Array<(
        { __typename?: 'DetectorReportConfigFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        )> }
        & FormViewFragment_DetectorReportConfigFormView_Fragment
      ) | (
        { __typename?: 'IntegrationConfigFormView', views: Array<(
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        )> }
        & FormViewFragment_IntegrationConfigFormView_Fragment
      ) | (
        { __typename?: 'IntegrationStateView', views: Array<(
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        )> }
        & FormViewFragment_IntegrationStateView_Fragment
      ) | (
        { __typename?: 'ModuleSettingsFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        ) | (
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        ) | (
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        ) | (
          { __typename?: 'ModuleSettingsFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          ) | (
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          ) | (
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          ) | (
            { __typename?: 'ModuleSettingsFormView' }
            & FormViewFragment_ModuleSettingsFormView_Fragment
          )> }
          & FormViewFragment_ModuleSettingsFormView_Fragment
        )> }
        & FormViewFragment_ModuleSettingsFormView_Fragment
      )> }>, sections: Array<(
      { __typename?: 'ModuleFormSection' }
      & ModuleFormSectionFragmentFragment
    )> } };

export type SaveAtlasArchiveModuleSettingsMutationVariables = Exact<{
  settings: Scalars['JSON'];
}>;


export type SaveAtlasArchiveModuleSettingsMutation = { __typename?: 'Mutation', saveAtlasArchiveModuleSettings?: boolean | null };

export type AtlasArchiveModuleSettingsQueryVariables = Exact<{
  formViewName: Scalars['String'];
}>;


export type AtlasArchiveModuleSettingsQuery = { __typename?: 'Query', atlasArchiveModuleSettings: { __typename?: 'ModuleSettingsFormView', id: string, name: string, type: FormViewType, views: Array<{ __typename?: 'DetectorReportConfigFormView', id: string, name: string, views: Array<(
        { __typename?: 'DetectorReportConfigFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        )> }
        & FormViewFragment_DetectorReportConfigFormView_Fragment
      )> } | { __typename?: 'IntegrationConfigFormView', id: string, name: string, views: Array<(
        { __typename?: 'IntegrationConfigFormView', views: Array<(
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        )> }
        & FormViewFragment_IntegrationConfigFormView_Fragment
      )> } | { __typename?: 'IntegrationStateView', id: string, name: string, views: Array<(
        { __typename?: 'IntegrationStateView', views: Array<(
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        )> }
        & FormViewFragment_IntegrationStateView_Fragment
      )> } | { __typename?: 'ModuleSettingsFormView', id: string, name: string, views: Array<(
        { __typename?: 'DetectorReportConfigFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        )> }
        & FormViewFragment_DetectorReportConfigFormView_Fragment
      ) | (
        { __typename?: 'IntegrationConfigFormView', views: Array<(
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        )> }
        & FormViewFragment_IntegrationConfigFormView_Fragment
      ) | (
        { __typename?: 'IntegrationStateView', views: Array<(
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        )> }
        & FormViewFragment_IntegrationStateView_Fragment
      ) | (
        { __typename?: 'ModuleSettingsFormView', views: Array<(
          { __typename?: 'DetectorReportConfigFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          )> }
          & FormViewFragment_DetectorReportConfigFormView_Fragment
        ) | (
          { __typename?: 'IntegrationConfigFormView', views: Array<(
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          )> }
          & FormViewFragment_IntegrationConfigFormView_Fragment
        ) | (
          { __typename?: 'IntegrationStateView', views: Array<(
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          )> }
          & FormViewFragment_IntegrationStateView_Fragment
        ) | (
          { __typename?: 'ModuleSettingsFormView', views: Array<(
            { __typename?: 'DetectorReportConfigFormView' }
            & FormViewFragment_DetectorReportConfigFormView_Fragment
          ) | (
            { __typename?: 'IntegrationConfigFormView' }
            & FormViewFragment_IntegrationConfigFormView_Fragment
          ) | (
            { __typename?: 'IntegrationStateView' }
            & FormViewFragment_IntegrationStateView_Fragment
          ) | (
            { __typename?: 'ModuleSettingsFormView' }
            & FormViewFragment_ModuleSettingsFormView_Fragment
          )> }
          & FormViewFragment_ModuleSettingsFormView_Fragment
        )> }
        & FormViewFragment_ModuleSettingsFormView_Fragment
      )> }>, sections: Array<(
      { __typename?: 'ModuleFormSection' }
      & ModuleFormSectionFragmentFragment
    )> } };

export type PrivilegeAssignmentFragmentFragment = { __typename?: 'KineticPrivilegeAssignment', id: string, privilegeType: PrivilegeType, kineticModule: KineticModule, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null };

export type KineticUserFragmentFragment = { __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumbers: Array<string>, roleIds: Array<string>, userGroupIds: Array<string>, privilegeAssignmentList: Array<(
    { __typename?: 'KineticPrivilegeAssignment' }
    & PrivilegeAssignmentFragmentFragment
  )> };

export type KineticFormEventTypeFragment = { __typename?: 'EventType', id: string, name: string };

export type KineticFormFieldFragment = { __typename?: 'KineticFormField', id: string, name: string, description?: string | null, label: string, type: string, fieldSize?: string | null, help?: string | null, hint?: string | null, disablePast?: boolean | null, disableFuture?: boolean | null, format?: string | null, displayFormat?: string | null, max?: number | null, min?: number | null, useStepper?: boolean | null, step?: number | null, source?: string | null, multiple?: boolean | null, sortOrder?: string | null, requiredLogic?: any | null, activeLogic?: any | null, moduleName?: string | null, loading?: boolean | null, uploadType?: string | null, accept?: string | null, required?: boolean | null, defaultValue?: any | null, mode?: string | null, iconConfig?: any | null, dataSource?: string | null, options?: Array<{ __typename?: 'KineticFormFieldOption', label: string, value: string, type?: string | null }> | null };

export type KineticFormSectionFragment = { __typename?: 'KineticFormSection', id: string, name: string, fields: Array<(
    { __typename?: 'KineticFormField' }
    & KineticFormFieldFragment
  ) | null> };

export type KineticFormViewFragment = { __typename?: 'KineticFormView', id: string, name: string, sections: Array<(
    { __typename?: 'KineticFormSection' }
    & KineticFormSectionFragment
  )> };

export type KineticFormFragment = { __typename?: 'KineticForm', system?: boolean | null, canOverride?: boolean | null, modified?: any | null, extraConfig: any, description?: string | null, module: KineticModule, name: string, id: string, views: Array<(
    { __typename?: 'KineticFormView' }
    & KineticFormViewFragment
  ) | null> };

export type TaskProgressFragmentFragment = { __typename?: 'TaskProgress', id: string, name: string, description?: string | null, status: TaskProgressStatus, progress: number, resource?: string | null, associatedIds?: Array<string | null> | null, systemTask?: boolean | null, userIds?: Array<string | null> | null, source: KineticModule, created: any, updated: any, error?: string | null, task: string, type: TaskProgressType };

export type ViewFragmentFragment = { __typename?: 'IntersectionDeviceUIView', displayName?: string | null, name?: string | null };

export type SaveEmailServerSettingsMutationVariables = Exact<{
  settings: EmailServerSettingsInput;
}>;


export type SaveEmailServerSettingsMutation = { __typename?: 'Mutation', saveEmailServerSettings: { __typename?: 'EmailServerSettings', host: string, port: number, auth: boolean, startTls: boolean, requireStartTls: boolean, sslTrust: boolean, username?: string | null, password?: string | null, ignoreTls: boolean, from: string, sslEnabled: boolean } };

export type DeleteKineticFormsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteKineticFormsMutation = { __typename?: 'Mutation', deleteKineticForms?: boolean | null };

export type SaveKineticFormMutationVariables = Exact<{
  form: KineticFormInput;
}>;


export type SaveKineticFormMutation = { __typename?: 'Mutation', saveKineticForm: (
    { __typename?: 'KineticForm' }
    & KineticFormFragment
  ) };

export type CreateKeycloakClientMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateKeycloakClientMutation = { __typename?: 'Mutation', createKeycloakClient: { __typename?: 'KeycloakClient', id: string, clientId: string, name?: string | null, secret: string } };

export type RegenerateClientSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type RegenerateClientSecretMutation = { __typename?: 'Mutation', regenerateClientSecret: boolean };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type DeleteUserGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserGroupMutation = { __typename?: 'Mutation', deleteUserGroup: boolean };

export type SaveRoleMutationVariables = Exact<{
  role: KineticRoleInput;
}>;


export type SaveRoleMutation = { __typename?: 'Mutation', saveRole: { __typename?: 'KineticRole', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } };

export type SaveUserGroupMutationVariables = Exact<{
  userGroup: KineticUserGroupInput;
}>;


export type SaveUserGroupMutation = { __typename?: 'Mutation', saveUserGroup: { __typename?: 'KineticUserGroup', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )>, roles: Array<{ __typename?: 'KineticRole', id: string, name: string, privilegeAssignmentList: Array<(
        { __typename?: 'KineticPrivilegeAssignment' }
        & PrivilegeAssignmentFragmentFragment
      )> }>, users: Array<{ __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null }> } };

export type CreateUserMutationVariables = Exact<{
  user: OrganizationKineticUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumbers: Array<string>, roleIds: Array<string>, userGroupIds: Array<string>, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } };

export type UpdateUserPrivilegesMutationVariables = Exact<{
  user: KineticUserPrivilegeAssignmentInput;
}>;


export type UpdateUserPrivilegesMutation = { __typename?: 'Mutation', updateUserPrivileges: { __typename?: 'KineticUserPrivilegeAssignment', id: string, roles: Array<{ __typename?: 'KineticRole', id: string, name: string }>, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } };

export type ResetPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser?: boolean | null };

export type SaveMapConfigMutationVariables = Exact<{
  config: MapConfigInput;
}>;


export type SaveMapConfigMutation = { __typename?: 'Mutation', saveMapConfig: (
    { __typename?: 'MapConfig' }
    & MapConfigFragmentFragment
  ) };

export type SaveMapLayerConfigMutationVariables = Exact<{
  config: MapLayerConfigInput;
}>;


export type SaveMapLayerConfigMutation = { __typename?: 'Mutation', saveMapLayerConfig: (
    { __typename?: 'MapLayerConfig' }
    & MapLayerConfigFragmentFragment
  ) };

export type DeleteMapLayerConfigMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMapLayerConfigMutation = { __typename?: 'Mutation', deleteMapLayerConfig?: boolean | null };

export type DeleteMapConfigMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMapConfigMutation = { __typename?: 'Mutation', deleteMapConfig?: boolean | null };

export type SyncMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncMutation = { __typename?: 'Mutation', syncLicense: boolean };

export type SaveLicenseMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type SaveLicenseMutation = { __typename?: 'Mutation', saveLicense: boolean };

export type SaveKineticFormFieldMutationVariables = Exact<{
  field: KineticFormFieldInput;
}>;


export type SaveKineticFormFieldMutation = { __typename?: 'Mutation', saveKineticFormField: (
    { __typename?: 'KineticFormField' }
    & KineticFormFieldFragment
  ) };

export type DeleteKineticFormFieldsMutationVariables = Exact<{
  selection: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteKineticFormFieldsMutation = { __typename?: 'Mutation', deleteKineticFormFields?: boolean | null };

export type SaveCentralAuthPinSettingsMutationVariables = Exact<{
  pin: Scalars['String'];
}>;


export type SaveCentralAuthPinSettingsMutation = { __typename?: 'Mutation', saveCentralAuthPinSettings?: { __typename?: 'CentralAuthPinSettings', id: string } | null };

export type KeycloakClientQueryVariables = Exact<{ [key: string]: never; }>;


export type KeycloakClientQuery = { __typename?: 'Query', keycloakClient?: { __typename?: 'KeycloakClient', id: string, clientId: string, name?: string | null, secret: string } | null };

export type EmailServerSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailServerSettingsQuery = { __typename?: 'Query', emailServerSettings?: { __typename?: 'EmailServerSettings', host: string, port: number, auth: boolean, startTls: boolean, requireStartTls: boolean, sslTrust: boolean, username?: string | null, password?: string | null, ignoreTls: boolean, from: string, sslEnabled: boolean } | null };

export type RecentRemoteAgentsQueryVariables = Exact<{ [key: string]: never; }>;


export type RecentRemoteAgentsQuery = { __typename?: 'Query', recentRemoteAgents: Array<{ __typename?: 'RemoteAgentHeartbeat', instanceId: string, processId: number, systemName: string, remoteIpAddress: string, clientId: string, version: string, internalIpAddress: string, lastHeartbeat: any, labels: Array<string> }> };

export type GetOrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'KineticOrganization', id: string, name: string, address?: string | null }> };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'KineticRole', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> }> };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoleQuery = { __typename?: 'Query', role?: { __typename?: 'KineticRole', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } | null };

export type RolesUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RolesUpdatedSubscription = { __typename?: 'Subscription', rolesUpdated?: { __typename?: 'KineticRole', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } | null };

export type GetUserGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserGroupQuery = { __typename?: 'Query', userGroup?: { __typename?: 'KineticUserGroup', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, users: Array<{ __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null }>, roles: Array<{ __typename?: 'KineticRole', id: string, name: string }>, privilegeAssignmentList: Array<(
      { __typename?: 'KineticPrivilegeAssignment' }
      & PrivilegeAssignmentFragmentFragment
    )> } | null };

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'KineticUserGroup', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, users: Array<{ __typename?: 'OrganizationKineticUser', id: string }>, roles: Array<{ __typename?: 'KineticRole', id: string, name: string }> }> };

export type UserGroupsUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UserGroupsUpdatedSubscription = { __typename?: 'Subscription', userGroupsUpdated?: { __typename?: 'KineticUserGroup', id: string, name: string, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null, users: Array<{ __typename?: 'OrganizationKineticUser', id: string }>, roles: Array<{ __typename?: 'KineticRole', id: string, name: string }> } | null };

export type GetUserWithPrivilegesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetUserWithPrivilegesQuery = { __typename?: 'Query', users: { __typename?: 'KineticUserConnection', edges: Array<{ __typename?: 'KineticUserConnectionEdge', node: (
        { __typename?: 'OrganizationKineticUser' }
        & KineticUserFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetPrivilegesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrivilegesQuery = { __typename?: 'Query', privileges: Array<{ __typename?: 'KineticPrivilege', id: string, privilegeType: PrivilegeType, kineticModule: KineticModule }> };

export type GetKineticUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKineticUserQuery = { __typename?: 'Query', getKineticUser?: (
    { __typename?: 'OrganizationKineticUser' }
    & KineticUserFragmentFragment
  ) | null };

export type PrivilegeAssignmentsUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PrivilegeAssignmentsUpdatedSubscription = { __typename?: 'Subscription', privilegeAssignmentsUpdated?: { __typename?: 'KineticUserPrivilegeAssignment', id: string } | null };

export type IsUsernameUniqueQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type IsUsernameUniqueQuery = { __typename?: 'Query', isUsernameUnique: boolean };

export type LicenseFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseFeaturesQuery = { __typename?: 'Query', licenseFeatures: Array<{ __typename?: 'KineticLicenseFeatureValue', feature: KineticLicenseFeature, value?: number | null, enabled: boolean, used?: number | null }> };

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = { __typename?: 'Query', version: string, gitHash: string, lastGitTag: string };

export type LicenseDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseDetailsQuery = { __typename?: 'Query', licenseDetails?: { __typename?: 'KineticLicenseDetails', key: string, activationId: string, expiresAt?: any | null, leaseExpiresAt?: any | null, accountId?: string | null, company?: string | null, email?: string | null } | null };

export type HasValidLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type HasValidLicenseQuery = { __typename?: 'Query', hasValidLicense?: boolean | null };

export type KineticFormEventTypesQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<EventCategory>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type KineticFormEventTypesQuery = { __typename?: 'Query', eventTypes: { __typename?: 'EventTypeConnection', edges: Array<{ __typename?: 'EventTypeConnectionEdge', node: (
        { __typename?: 'EventType' }
        & KineticFormEventTypeFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type DataSourceConfigListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<KineticModule>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DataSourceConfigListQuery = { __typename?: 'Query', dataSourceConfigs: { __typename?: 'DataSourceConfigConnection', totalCount: number, edges: Array<{ __typename?: 'DataSourceConfigConnectionEdge', node: { __typename?: 'DataSourceConfig', name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type KineticFormsQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<KineticModule>;
}>;


export type KineticFormsQuery = { __typename?: 'Query', kineticForms: { __typename?: 'KineticFormConnection', totalCount: number, edges: Array<{ __typename?: 'KineticFormConnectionEdge', node: (
        { __typename?: 'KineticForm' }
        & KineticFormFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type KineticFormDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KineticFormDetailQuery = { __typename?: 'Query', kineticForm: (
    { __typename?: 'KineticForm' }
    & KineticFormFragment
  ) };

export type KineticFormFieldsQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type KineticFormFieldsQuery = { __typename?: 'Query', kineticFormFields: { __typename?: 'KineticFormFieldConnection', totalCount: number, edges: Array<{ __typename?: 'KineticFormFieldConnectionEdge', node: (
        { __typename?: 'KineticFormField' }
        & KineticFormFieldFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type KineticFormFieldDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KineticFormFieldDetailQuery = { __typename?: 'Query', kineticFormField: (
    { __typename?: 'KineticFormField' }
    & KineticFormFieldFragment
  ) };

export type GetTaskProgressQueryVariables = Exact<{
  query: TaskProgressQuery;
}>;


export type GetTaskProgressQuery = { __typename?: 'Query', taskProgress: { __typename?: 'TaskProgressConnection', edges: Array<{ __typename?: 'TaskProgressConnectionEdge', cursor?: string | null, node: (
        { __typename?: 'TaskProgress' }
        & TaskProgressFragmentFragment
      ) }> } };

export type TaskProgressUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TaskProgressUpdatedSubscription = { __typename?: 'Subscription', taskProgressUpdated: (
    { __typename?: 'TaskProgress' }
    & TaskProgressFragmentFragment
  ) };

export type SystemActivityLogsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters: Array<FilterInput> | FilterInput;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
}>;


export type SystemActivityLogsQuery = { __typename?: 'Query', activityLogs: { __typename?: 'ActivityLogConnection', totalCount: number, edges: Array<{ __typename?: 'ActivityLogEdge', node: (
        { __typename?: 'ActivityLog' }
        & ActivityLogNodeFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type CentralAuthenticationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CentralAuthenticationSettingsQuery = { __typename?: 'Query', kineticAuthSettings: { __typename?: 'ModuleSettingsFormView', id: string, name: string, type: FormViewType, sections: Array<{ __typename?: 'ModuleFormSection', name: string, id: string, fields: Array<{ __typename?: 'FieldConfig', id: string, name: string, label: string, type: string, required?: boolean | null, hint?: string | null, sourceId?: string | null, defaultValue?: any | null, loading?: boolean | null, showInput?: boolean | null, options?: Array<{ __typename?: 'FieldConfigOption', label?: string | null, value?: any | null } | null> | null } | null> }> } };

export type SaveCentralAuthenticationSettingsMutationVariables = Exact<{
  settings: Scalars['JSON'];
}>;


export type SaveCentralAuthenticationSettingsMutation = { __typename?: 'Mutation', saveKineticAuthSettings?: boolean | null };

export type DeviceViewsQueryVariables = Exact<{
  id: DeviceViewsProtocolEntityEnum;
}>;


export type DeviceViewsQuery = { __typename?: 'Query', deviceViews?: { __typename: 'DeviceViewsUi', views: (
      { __typename: 'IntersectionDeviceUIView', views?: Array<(
        { __typename: 'IntersectionDeviceUIView', views?: Array<(
          { __typename: 'IntersectionDeviceUIView', views?: Array<(
            { __typename: 'IntersectionDeviceUIView', views?: Array<(
              { __typename: 'IntersectionDeviceUIView', views?: Array<(
                { __typename: 'IntersectionDeviceUIView' }
                & ViewFragmentFragment
              )> | null }
              & ViewFragmentFragment
            )> | null }
            & ViewFragmentFragment
          )> | null }
          & ViewFragmentFragment
        )> | null }
        & ViewFragmentFragment
      )> | null }
      & ViewFragmentFragment
    ) } | null };

export type CentralAuthPinSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CentralAuthPinSettingsQuery = { __typename?: 'Query', centralAuthPinSettings?: { __typename?: 'CentralAuthPinSettings', id: string, pin: string } | null };

export type SaveNotificationPreferencesMutationVariables = Exact<{
  notificationPreferences?: InputMaybe<NotificationPreferenceInput>;
}>;


export type SaveNotificationPreferencesMutation = { __typename?: 'Mutation', saveNotificationPreferences?: { __typename?: 'NotificationPreference', location?: any | null, preferenceSourceList: Array<{ __typename?: 'NotificationPreferenceSource', source?: KineticModule | null, severitySet: Array<NotificationSeverity | null> } | null> } | null };

export type NotificationPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationPreferencesQuery = { __typename?: 'Query', notificationPreferences?: { __typename?: 'NotificationPreference', location?: any | null, preferenceSourceList: Array<{ __typename?: 'NotificationPreferenceSource', source?: KineticModule | null, severitySet: Array<NotificationSeverity | null> } | null> } | null };

export type SavedRouteFragment = { __typename?: 'LocationRoute', id: string, name: string, classification: string, fromMeasure?: number | null, toMeasure?: number | null, direction: string, geometry: any };

export type SaveRouteMutationVariables = Exact<{
  input: LocationRouteInput;
}>;


export type SaveRouteMutation = { __typename?: 'Mutation', saveRoute: (
    { __typename?: 'LocationRoute' }
    & SavedRouteFragment
  ) };

export type DeleteRouteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRouteMutation = { __typename?: 'Mutation', deleteRoute?: boolean | null };

export type GetRouteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRouteQuery = { __typename?: 'Query', route: (
    { __typename?: 'LocationRoute' }
    & SavedRouteFragment
  ) };

export type GetRouteDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRouteDetailsQuery = { __typename?: 'Query', route: { __typename?: 'LocationRoute', id: string, name: string } };

export type RoutesListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
}>;


export type RoutesListQuery = { __typename?: 'Query', routes: { __typename?: 'LocationRouteConnection', edges: Array<{ __typename?: 'LocationRouteConnectionEdge', node: { __typename?: 'LocationRoute', id: string, name: string, classification: string, fromMeasure?: number | null, toMeasure?: number | null, direction: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SubGroupShortFragment = { __typename?: 'ContactGroupDto', id: string, name: string, description?: string | null, parentGroup?: string | null, items: Array<{ __typename?: 'Contact', id: string, firstName: string, lastName: string } | null> };

export type ContactSubGroupShortRecursiveFragment = { __typename?: 'ContactGroupDto', subGroups: Array<(
    { __typename?: 'ContactGroupDto', subGroups: Array<(
      { __typename?: 'ContactGroupDto', subGroups: Array<(
        { __typename?: 'ContactGroupDto', subGroups: Array<(
          { __typename?: 'ContactGroupDto', subGroups: Array<(
            { __typename?: 'ContactGroupDto' }
            & SubGroupShortFragment
          ) | null> }
          & SubGroupShortFragment
        ) | null> }
        & SubGroupShortFragment
      ) | null> }
      & SubGroupShortFragment
    ) | null> }
    & SubGroupShortFragment
  ) | null> };

export type ContactGroupShortFragmentFragment = (
  { __typename?: 'ContactGroupDto' }
  & SubGroupShortFragment
  & ContactSubGroupShortRecursiveFragment
);

export type ContactGroupsShortQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
}>;


export type ContactGroupsShortQuery = { __typename?: 'Query', contactGroupsQuery: { __typename?: 'ContactGroupConnection', edges: Array<{ __typename?: 'ContactGroupConnectionEdge', node: (
        { __typename?: 'ContactGroupDto' }
        & ContactGroupShortFragmentFragment
      ) }> } };

export type IntegrationsSelectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type IntegrationsSelectionQuery = { __typename?: 'Query', integrations: { __typename?: 'IntegrationConnection', edges: Array<{ __typename?: 'IntegrationConnectionEdge', node: { __typename?: 'Integration', id: string, name: string, config: { __typename?: 'IntegrationConfig', integrationType: IntegrationType } } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SignDisplayTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignDisplayTypeQuery = { __typename?: 'Query', signDisplayType: (
    { __typename?: 'SignDisplayType' }
    & SignDisplayTypeFragment
  ) };

export type KineticUserQueryVariables = Exact<{ [key: string]: never; }>;


export type KineticUserQuery = { __typename?: 'Query', kineticUser: { __typename?: 'KineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null, picture?: string | null, email?: string | null, preferences: Array<{ __typename?: 'UserPreference', key: string, value: any }>, kineticPreferences: Array<{ __typename?: 'UserPreference', key: string, value: any }> }, organizationPreferences: Array<{ __typename?: 'OrganizationPreference', key: string, value: any }> };

export type ExportActivityLogsMutationVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  searchText: Scalars['String'];
  config: ExportConfig;
  searchFields: Array<Scalars['String']> | Scalars['String'];
}>;


export type ExportActivityLogsMutation = { __typename?: 'Mutation', exportActivityLogs: boolean };

export type CorridorCutoutsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
}>;


export type CorridorCutoutsListQuery = { __typename?: 'Query', signGroups: { __typename?: 'SignGroupConnection', totalCount: number, edges: Array<{ __typename?: 'SignGroupConnectionEdge', node: { __typename?: 'SignGroup', description?: string | null, attributes: any, type: SignGroupType, name: string, id: string, signs: Array<(
          { __typename?: 'Sign' }
          & CorridorSchematicSignFragment
        )> } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type ResourcePlanResourcesListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourcePlanResourcesListQuery = { __typename?: 'Query', resourcePlan?: { __typename?: 'ResourcePlan', resources: Array<{ __typename?: 'Resource', status: ResourceStatus, type: ResourceType, data: any, id: string }> } | null };

export type CorridorSchematicSignFragment = { __typename?: 'Sign', communicationStatus: CommunicationState, travelTimeSegments: Array<string>, referencePoint?: number | null, capabilities: Array<SignCapability>, lanePosition?: number | null, direction?: string | null, laneType?: LaneType | null, road?: string | null, name: string, id: string, displayType: (
    { __typename?: 'SignDisplayType' }
    & SignDisplayTypeFragment
  ), currentMessage?: (
    { __typename?: 'SignQueuedMessage' }
    & SignQueuedMessageFragment
  ) | null, messagePreferences?: (
    { __typename?: 'SignMessagePreferences' }
    & SignMessagePreferencesFragment
  ) | null, speedSettings?: (
    { __typename?: 'SignSpeedSettings' }
    & SignSpeedSettingsFragment
  ) | null, tests?: (
    { __typename?: 'SignTests' }
    & SignTestsTypeFragment
  ) | null };

export type SchematicResponsePostMutationVariables = Exact<{
  requests: Array<SignMessageDisplayRequest> | SignMessageDisplayRequest;
}>;


export type SchematicResponsePostMutation = { __typename?: 'Mutation', signDisplay: Array<{ __typename?: 'JobRequest', requestId?: string | null, success: boolean, failure?: string | null, id: string }> };

export type CorridorSchematicSignsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  limit: Scalars['Int'];
}>;


export type CorridorSchematicSignsQuery = { __typename?: 'Query', signs: { __typename?: 'SignConnection', edges: Array<{ __typename?: 'SignConnectionEdge', node: (
        { __typename?: 'Sign' }
        & CorridorSchematicSignFragment
      ) }> } };

export type LiveCorridorSchematicSignsSubscriptionVariables = Exact<{
  integrations: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type LiveCorridorSchematicSignsSubscription = { __typename?: 'Subscription', signUpdated: (
    { __typename?: 'Sign' }
    & CorridorSchematicSignFragment
  ) };

export type DirectoryFragmentFragment = { __typename?: 'Directory', id: string, order: number, name: string, owner: string, parentId?: string | null, readOnly: boolean };

export type DirectoryRecursiveFragment = (
  { __typename?: 'Directory', directories: Array<(
    { __typename?: 'Directory', directories: Array<(
      { __typename?: 'Directory', directories: Array<(
        { __typename?: 'Directory', directories: Array<(
          { __typename?: 'Directory', directories: Array<(
            { __typename?: 'Directory', directories: Array<(
              { __typename?: 'Directory', directories: Array<(
                { __typename?: 'Directory', directories: Array<(
                  { __typename?: 'Directory', directories: Array<(
                    { __typename?: 'Directory' }
                    & DirectoryFragmentFragment
                  )> }
                  & DirectoryFragmentFragment
                )> }
                & DirectoryFragmentFragment
              )> }
              & DirectoryFragmentFragment
            )> }
            & DirectoryFragmentFragment
          )> }
          & DirectoryFragmentFragment
        )> }
        & DirectoryFragmentFragment
      )> }
      & DirectoryFragmentFragment
    )> }
    & DirectoryFragmentFragment
  )> }
  & DirectoryFragmentFragment
);

export type SaveSystemDirectoriesMutationVariables = Exact<{
  input: Array<DirectoryInput> | DirectoryInput;
  removedDirectories: Array<Scalars['String']> | Scalars['String'];
}>;


export type SaveSystemDirectoriesMutation = { __typename?: 'Mutation', saveSystemDirectories: Array<(
    { __typename?: 'Directory' }
    & DirectoryRecursiveFragment
  )> };

export type SaveUserDirectoriesMutationVariables = Exact<{
  input: Array<DirectoryInput> | DirectoryInput;
  removedDirectories: Array<Scalars['String']> | Scalars['String'];
}>;


export type SaveUserDirectoriesMutation = { __typename?: 'Mutation', saveUserDirectories: Array<(
    { __typename?: 'Directory' }
    & DirectoryRecursiveFragment
  )> };

export type DeleteManagedFilesMutationVariables = Exact<{
  fileIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteManagedFilesMutation = { __typename?: 'Mutation', deleteManagedFiles: boolean };

export type InitMyFilesMutationVariables = Exact<{ [key: string]: never; }>;


export type InitMyFilesMutation = { __typename?: 'Mutation', initializeMyFilesDirectory: { __typename?: 'Directory', id: string, order: number, name: string, owner: string, parentId?: string | null } };

export type MoveFileMutationVariables = Exact<{
  destinationDirectoryId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type MoveFileMutation = { __typename?: 'Mutation', moveFile: boolean };

export type CopyFileMutationVariables = Exact<{
  destinationDirectoryId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type CopyFileMutation = { __typename?: 'Mutation', copyFile: boolean };

export type GetSystemDirectoriesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetSystemDirectoriesQuery = { __typename?: 'Query', getSystemDirectories: Array<(
    { __typename?: 'Directory' }
    & DirectoryRecursiveFragment
  )> };

export type GetUserDirectoriesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetUserDirectoriesQuery = { __typename?: 'Query', getUserDirectories: Array<(
    { __typename?: 'Directory' }
    & DirectoryRecursiveFragment
  )> };

export type GetManagedFilesByDirectoryQueryVariables = Exact<{
  directoryId: Scalars['String'];
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetManagedFilesByDirectoryQuery = { __typename?: 'Query', getManagedFilesByDirectory: Array<{ __typename?: 'ManagedFile', contentType: string, directoryId: string, fileName: string, id: string, name: string, owner?: string | null, tags?: Array<string> | null }> };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'Query', getFileTags: Array<string> };

export type ManagedFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ManagedFileQuery = { __typename?: 'Query', getManagedFile?: { __typename?: 'ManagedFile', contentType: string, directoryId: string, fileName: string, id: string, name: string, owner?: string | null, tags?: Array<string> | null } | null };

export type FieldConfigFieldsFragment = { __typename?: 'FieldConfig', activeLogic?: any | null, choicesTitle?: string | null, chosenTitle?: string | null, defaultValue?: any | null, showInput?: boolean | null, valueLabelDisplay?: string | null, deselectAllLabel?: string | null, disableFuture?: boolean | null, disablePast?: boolean | null, displayFormat?: string | null, fieldSize?: string | null, format?: string | null, help?: string | null, hint?: string | null, id: string, label: string, limitTags?: number | null, max?: number | null, maxLength?: number | null, min?: number | null, multiple?: boolean | null, name: string, sortOrder?: string | null, required?: boolean | null, requiredLogic?: any | null, selectAllLabel?: string | null, step?: number | null, type: string, uniqueFields?: Array<string | null> | null, useCheckedIcon?: boolean | null, useIcon?: boolean | null, useStepper?: boolean | null, moduleName?: string | null, sourceId?: string | null, rows?: number | null, multiline?: boolean | null, mode?: string | null, options?: Array<{ __typename?: 'FieldConfigOption', label?: string | null, value?: any | null } | null> | null };

export type SharedPrivilegeAssignmentFragmentFragment = { __typename?: 'KineticPrivilegeAssignment', id: string, privilegeType: PrivilegeType, kineticModule: KineticModule, restrictedIntegrationGroups: Array<string>, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null };

export type SharedKineticUserFragmentFragment = { __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumbers: Array<string>, roleIds: Array<string>, userGroupIds: Array<string>, privilegeAssignmentList: Array<(
    { __typename?: 'KineticPrivilegeAssignment' }
    & SharedPrivilegeAssignmentFragmentFragment
  )> };

export type FieldConfigRecursiveFragment = (
  { __typename?: 'FieldConfig', fieldConfigs?: Array<(
    { __typename?: 'FieldConfig', fieldConfigs?: Array<(
      { __typename?: 'FieldConfig', fieldConfigs?: Array<(
        { __typename?: 'FieldConfig' }
        & FieldConfigFieldsFragment
      )> | null }
      & FieldConfigFieldsFragment
    )> | null }
    & FieldConfigFieldsFragment
  )> | null }
  & FieldConfigFieldsFragment
);

export type KineticFieldConfigFragment = (
  { __typename?: 'FieldConfig' }
  & FieldConfigFieldsFragment
);

export type MapConfigFragmentFragment = { __typename?: 'MapConfig', id: string, name: string, geometry: any, layers: Array<string>, zoom?: number | null };

export type MapLayerConfigFragmentFragment = { __typename?: 'MapLayerConfig', id: string, name: string, type: MapLayerType, data: any, baseLayer: boolean, visible: boolean, group?: string | null };

export type SignMessageLineSegmentFragment = { __typename?: 'SignMessageLineSegment', horizontalJustification?: HorizontalJustification | null, flashingEnabled?: boolean | null, foregroundColor?: string | null, backgroundColor?: string | null, flashOffMillis?: number | null, flashOnMillis?: number | null, font: number, text: string };

export type SignMessageLineFragment = { __typename?: 'SignMessageLine', integration?: string | null, horizontalJustification?: HorizontalJustification | null, lineSegments: Array<(
    { __typename?: 'SignMessageLineSegment' }
    & SignMessageLineSegmentFragment
  )> };

export type SignPositionFragment = { __typename?: 'Position', x?: number | null, y?: number | null };

export type SignMessageTextAreaFragment = { __typename?: 'SignMessageTextArea', verticalJustification: VerticalJustification, height: number, width: number, position: (
    { __typename?: 'Position' }
    & SignPositionFragment
  ), lines: Array<(
    { __typename?: 'SignMessageLine' }
    & SignMessageLineFragment
  )> };

export type SignMessageImageFragment = { __typename?: 'SignMessageImage', image: number, position: (
    { __typename?: 'Position' }
    & SignPositionFragment
  ) };

export type SignMessagePageFragment = { __typename?: 'SignMessagePage', verticalJustification?: VerticalJustification | null, backgroundColor?: string | null, pageOffTime?: number | null, pageOnTime?: number | null, textAreas: Array<(
    { __typename?: 'SignMessageTextArea' }
    & SignMessageTextAreaFragment
  )>, images: Array<(
    { __typename?: 'SignMessageImage' }
    & SignMessageImageFragment
  )>, lines: Array<(
    { __typename?: 'SignMessageLine' }
    & SignMessageLineFragment
  )> };

export type SignImageFragment = { __typename?: 'SignImage', height: number, width: number, image: any, slot?: number | null, name: string, id: string };

export type SignMessageFragment = { __typename?: 'SignMessage', beaconsEnabled: boolean, priority: number, command?: string | null, slot?: number | null, type: SignMessageType, images: Array<(
    { __typename?: 'SignImage' }
    & SignImageFragment
  )>, pages: Array<(
    { __typename?: 'SignMessagePage' }
    & SignMessagePageFragment
  )> };

export type SignDisplayTypeFragment = { __typename?: 'SignDisplayType', matrixType: SignMatrixType, characterWidth?: number | null, characterHeight?: number | null, defaultFont?: string | null, maxCharacters?: number | null, height: number, width: number, hash: string, maxLines?: number | null };

export type SignTestsTypeFragment = { __typename?: 'SignTests', fan?: { __typename?: 'SignFanTest', running: boolean, error?: string | null, fans: Array<number> } | null, lamp?: { __typename?: 'SignLampTest', running: boolean, stuckOff: Array<number>, stuckOn: Array<number>, error?: string | null } | null, pixel?: { __typename?: 'SignPixelTest', running: boolean, error?: string | null, pixels: Array<{ __typename?: 'SignPixelTestError', x: number, y: number }> } | null };

export type SignMessageDisplayTypeFragment = { __typename?: 'SignMessageDisplayType', matrixType: SignMatrixType, characterWidth?: number | null, characterHeight?: number | null, defaultFont?: string | null, maxCharacters?: number | null, height: number, width: number, hash: string, maxLines?: number | null, signs: any };

export type SignListDisplayTypeFragment = { __typename?: 'SignMessageDisplayType', height: number, width: number, signs: any, hash: string };

export type ActivityLogNodeFragmentFragment = { __typename?: 'ActivityLog', id: string, entityId: string, timestamp: any, action: string, description?: string | null, userId?: string | null, userFullName?: string | null, module: KineticModule, object?: any | null, type?: string | null, changes?: Array<{ __typename?: 'ActivityLogChange', path?: string | null, operation?: ActivityLogChangeType | null, oldValue?: string | null, newValue?: string | null }> | null, user?: { __typename?: 'OrganizationKineticUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null } | null };

export type TaskFragmentFragment = { __typename?: 'Task', id: string, name: string, taskType: TaskType, timezone?: string | null, start: any, end: any, data: any, taskRecurrenceType: TaskRecurrenceType, rrule?: string | null, recurrenceEndDate?: any | null, externalCenterId?: string | null };

export type TaskOccurrenceFragmentFragment = { __typename?: 'TaskOccurrence', start: any, end: any, task: (
    { __typename?: 'Task' }
    & TaskFragmentFragment
  ) };

export type SignSpeedSettingsFragment = { __typename?: 'SignSpeedSettings', secondaryMaximumSpeed?: number | null, secondaryMinimumSpeed?: number | null, backgroundColor?: string | null, foregroundColor?: string | null, maximumChange: number, minimumSpeed: number, maximumSpeed: number, intervals: number };

export type SignQueuedMessageFragment = { __typename?: 'SignQueuedMessage', associationDisplayNames: Array<string>, expirationTime?: any | null, associations: Array<string>, user?: string | null, id: string, message: (
    { __typename?: 'SignMessage' }
    & SignMessageFragment
  ) };

export type JobRequestFragment = { __typename?: 'JobRequest', requestId?: string | null, success: boolean, failure?: string | null, id: string };

export type CameraNodeFragment = { __typename?: 'Camera', id: string, name: string, communicationStatus?: CommunicationState | null, poweredOn?: boolean | null, road?: string | null, direction?: string | null, description?: string | null, location?: any | null, locationDescription?: string | null, autoFocusEnabled?: boolean | null, autoIrisEnabled?: boolean | null, userNotes?: string | null, referencePoint?: string | null, publicFeedEnabled?: boolean | null, privateStreamRecordingInProgress?: boolean | null, capabilitiesState: any, communicationParameters?: any | null, presets?: Array<{ __typename?: 'Preset', id: string, name: string, presetIndex: number, isHomePreset?: boolean | null, isSystem: boolean, isSelected?: boolean | null } | null> | null, ptzLock: { __typename?: 'PTZLockInfo', isLocked?: boolean | null, requireExplicitCameraControlLocking?: boolean | null, userId?: string | null, fullName?: string | null, lockStartedAt?: any | null, lockEndsAt?: any | null, lastUsedTime?: any | null }, streams?: Array<{ __typename?: 'VideoStream', name: string, url: string, accessType?: VideoStreamAccessType | null, application?: string | null, streamName?: string | null, streamType: string, streamManager?: string | null, recordingServer?: string | null } | null> | null, capabilities?: Array<{ __typename?: 'CameraCapabilityDto', capability: CameraCapability, options?: Array<string | null> | null }> | null };

export type SignListFragment = { __typename?: 'Sign', communicationStatus: CommunicationState, lastDataReceived?: any | null, referencePoint?: number | null, lanePosition?: number | null, capabilities: Array<SignCapability>, direction?: string | null, location: any, laneType?: LaneType | null, median?: boolean | null, road?: string | null, name: string, id: string, currentMessage?: (
    { __typename?: 'SignQueuedMessage' }
    & SignQueuedMessageFragment
  ) | null, displayType: (
    { __typename?: 'SignDisplayType' }
    & SignDisplayTypeFragment
  ), tests?: (
    { __typename?: 'SignTests' }
    & SignTestsTypeFragment
  ) | null };

export type DetectorFragment = { __typename?: 'Detector', communicationState?: CommunicationState | null, referencePoint?: number | null, direction?: string | null, timeZone?: string | null, location?: any | null, road?: string | null, name: string, id: string, lanes: Array<{ __typename?: 'DetectorLaneData', engineeringLane: number, laneIndex: number, occupancy?: number | null, direction: string, status?: string | null, volume?: number | null, speed?: number | null, type: LaneType }> };

export type DataSourceFieldConfigFragment = { __typename?: 'FieldConfig', label: string, type: string, name: string, id: string, activeLogic?: any | null, choicesTitle?: string | null, chosenTitle?: string | null, defaultValue?: any | null, deselectAllLabel?: string | null, disableFuture?: boolean | null, disablePast?: boolean | null, displayFormat?: string | null, fieldSize?: string | null, format?: string | null, help?: string | null, hint?: string | null, limitTags?: number | null, max?: number | null, maxLength?: number | null, min?: number | null, multiple?: boolean | null, sortOrder?: string | null, required?: boolean | null, requiredLogic?: any | null, selectAllLabel?: string | null, step?: number | null, uniqueFields?: Array<string | null> | null, useCheckedIcon?: boolean | null, useIcon?: boolean | null, useStepper?: boolean | null, moduleName?: string | null, sourceId?: string | null, rows?: number | null, multiline?: boolean | null, mode?: string | null, fieldConfigs?: Array<(
    { __typename?: 'FieldConfig' }
    & FieldConfigRecursiveFragment
  )> | null, options?: Array<{ __typename?: 'FieldConfigOption', label?: string | null, value?: any | null } | null> | null };

export type DataSourceConfigFragment = { __typename?: 'DataSourceConfig', module: KineticModule, name: string, id: string, system?: boolean | null, fields: Array<(
    { __typename?: 'FieldConfig' }
    & DataSourceFieldConfigFragment
  )> };

export type DataSourceFragment = { __typename?: 'DataSource', attributes: any, enabled?: boolean | null, value: string, id: string, config: (
    { __typename?: 'DataSourceConfig' }
    & DataSourceConfigFragment
  ) };

export type KineticFormFieldFragmentFragment = { __typename?: 'KineticFormField', id: string, name: string, description?: string | null, label: string, type: string, fieldSize?: string | null, help?: string | null, hint?: string | null, disablePast?: boolean | null, disableFuture?: boolean | null, format?: string | null, displayFormat?: string | null, max?: number | null, min?: number | null, useStepper?: boolean | null, step?: number | null, source?: string | null, multiple?: boolean | null, sortOrder?: string | null, requiredLogic?: any | null, activeLogic?: any | null, moduleName?: string | null, loading?: boolean | null, uploadType?: string | null, accept?: string | null, required?: boolean | null, defaultValue?: any | null, options?: Array<{ __typename?: 'KineticFormFieldOption', label: string, value: string, type?: string | null }> | null };

export type KineticFormSectionFragmentFragment = { __typename?: 'KineticFormSection', id: string, name: string, fields: Array<(
    { __typename?: 'KineticFormField' }
    & KineticFormFieldFragmentFragment
  ) | null> };

export type KineticFormViewFragmentFragment = { __typename?: 'KineticFormView', id: string, name: string, sections: Array<(
    { __typename?: 'KineticFormSection' }
    & KineticFormSectionFragmentFragment
  )> };

export type KineticFormFragmentFragment = { __typename?: 'KineticForm', system?: boolean | null, canOverride?: boolean | null, modified?: any | null, extraConfig: any, description?: string | null, module: KineticModule, name: string, id: string, views: Array<(
    { __typename?: 'KineticFormView' }
    & KineticFormViewFragmentFragment
  ) | null> };

export type CorridorEventFragment = { __typename?: 'Event', lastUpdated: any, attributes: any, category: EventCategory, created: any, status: EventStatus, type: string, name: string, id: string, lanes: Array<{ __typename?: 'EventLane', engineeringLane: number, laneStatus: LaneStatus, direction: Direction, laneType: LaneType }> };

export type SignMessageOverrideFragment = { __typename?: 'SignLibraryMessageOverride', hash: string, message: (
    { __typename?: 'SignMessage' }
    & SignMessageFragment
  ) };

export type SignLibraryMessageFragment = { __typename?: 'SignLibraryMessage', id: string, name: string, sign?: string | null, exclusions?: Array<string> | null, overrides?: Array<(
    { __typename?: 'SignLibraryMessageOverride' }
    & SignMessageOverrideFragment
  )> | null, message: (
    { __typename?: 'SignMessage' }
    & SignMessageFragment
  ) };

export type SignMessageHistoryFragment = { __typename?: 'SignMessageHistory', associationDisplayNames: Array<string>, timeBlanked?: any | null, blankedUser?: string | null, timePosted?: any | null, timeQueued?: any | null, postedUser?: string | null, associations: Array<string>, id: string, signId: string, message: (
    { __typename?: 'SignMessage' }
    & SignMessageFragment
  ) };

export type CommunicationLogFragment = { __typename?: 'CommunicationLog', id: string, reason?: string | null, eventIds: Array<string>, detailsType?: string | null, impactedGroup?: string | null, reported?: any | null, discussion?: string | null, attributes: any, contact?: { __typename?: 'CommunicationLogContact', id?: string | null, firstName: string, lastName: string, phoneNumber: string, email: string } | null, events?: Array<{ __typename?: 'Event', id: string, name: string } | null> | null };

export type EventListFragment = { __typename?: 'Event', geometry: any, lastUpdated: any, attributes: any, category: EventCategory, created: any, status: EventStatus, type: string, name: string, id: string, fiveOneOneMessage: { __typename?: 'FiveOneOneMessage', suppressed?: boolean | null } };

export type SignMessagePreferencesFragment = { __typename?: 'SignMessagePreferences', horizontalJustification: HorizontalJustification, verticalJustification: VerticalJustification, pageOffTime: number, pageOnTime: number, id: string };

export type SignDetailsFragment = { __typename?: 'Sign', locationDescription?: string | null, communicationStatus: CommunicationState, travelTimeSegments: Array<string>, lastDataReceived?: any | null, brightnessLevels?: number | null, referencePoint?: number | null, brightnessMode?: SignBrightnessMode | null, capabilities: Array<SignCapability>, powerSource?: SignPowerSource | null, brightness?: number | null, direction?: string | null, lastReset?: any | null, location: any, firmware?: string | null, volts?: number | null, road?: string | null, name: string, id: string, messagePreferences?: (
    { __typename?: 'SignMessagePreferences' }
    & SignMessagePreferencesFragment
  ) | null, displayType: (
    { __typename?: 'SignDisplayType' }
    & SignDisplayTypeFragment
  ), fonts: Array<{ __typename?: 'SignFontNumber', number: number, hash: string }>, currentMessage?: (
    { __typename?: 'SignQueuedMessage' }
    & SignQueuedMessageFragment
  ) | null, queue: Array<(
    { __typename?: 'SignQueuedMessage' }
    & SignQueuedMessageFragment
  )>, tests?: (
    { __typename?: 'SignTests' }
    & SignTestsTypeFragment
  ) | null };

export type SaveTaskMutationVariables = Exact<{
  input: TaskInput;
}>;


export type SaveTaskMutation = { __typename?: 'Mutation', saveTask?: (
    { __typename?: 'Task' }
    & TaskFragmentFragment
  ) | null };

export type DeleteVideoTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type DeleteVideoTaskMutation = { __typename?: 'Mutation', deleteVideoTask: boolean };

export type TravelTimeDisplayMutationVariables = Exact<{
  requests: Array<TravelTimeDisplayRequest> | TravelTimeDisplayRequest;
}>;


export type TravelTimeDisplayMutation = { __typename?: 'Mutation', displayTravelTimeMessages: Array<{ __typename?: 'JobRequest', success: boolean }> };

export type SaveSignMessageMutationVariables = Exact<{
  input: SignLibraryMessageInput;
}>;


export type SaveSignMessageMutation = { __typename?: 'Mutation', saveSignMessage?: (
    { __typename?: 'SignLibraryMessage' }
    & SignLibraryMessageFragment
  ) | null };

export type SignBlankMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SignBlankMutation = { __typename?: 'Mutation', signBlank: Array<(
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  )> };

export type SignPollMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SignPollMutation = { __typename?: 'Mutation', signPoll: Array<(
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  )> };

export type SignDisplayMutationVariables = Exact<{
  requests: Array<SignMessageDisplayRequest> | SignMessageDisplayRequest;
}>;


export type SignDisplayMutation = { __typename?: 'Mutation', signDisplay: Array<(
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  )> };

export type SaveContactMutationMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type SaveContactMutationMutation = { __typename?: 'Mutation', saveContact?: { __typename?: 'Contact', id: string, firstName: string, lastName: string } | null };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
  module?: InputMaybe<KineticModule>;
  centerId?: InputMaybe<Scalars['String']>;
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: boolean };

export type SignLaneControlDisplayMutationVariables = Exact<{
  requests: Array<SignLaneControlDisplayRequest> | SignLaneControlDisplayRequest;
}>;


export type SignLaneControlDisplayMutation = { __typename?: 'Mutation', signLaneControlDisplay: Array<{ __typename?: 'JobRequest', requestId?: string | null, failure?: string | null, success: boolean, id: string }> };

export type SaveDataSourceConfigMutationVariables = Exact<{
  input: DataSourceConfigInput;
}>;


export type SaveDataSourceConfigMutation = { __typename?: 'Mutation', saveDataSourceConfig: (
    { __typename?: 'DataSourceConfig' }
    & DataSourceConfigFragment
  ) };

export type DeleteDataSourceConfigMutationVariables = Exact<{
  selection: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteDataSourceConfigMutation = { __typename?: 'Mutation', deleteDataSourceConfigs?: boolean | null };

export type SaveDataSourceMutationVariables = Exact<{
  input: DataSourceInput;
}>;


export type SaveDataSourceMutation = { __typename?: 'Mutation', saveDataSource: (
    { __typename?: 'DataSource' }
    & DataSourceFragment
  ) };

export type DeleteDataSourceMutationVariables = Exact<{
  selection: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteDataSourceMutation = { __typename?: 'Mutation', deleteDataSources?: boolean | null };

export type SaveActivityLogMutationVariables = Exact<{
  input: ActivityLogInput;
}>;


export type SaveActivityLogMutation = { __typename?: 'Mutation', addActivityLog: { __typename?: 'ActivityLog', id: string } };

export type PanTiltVideoCameraMutationVariables = Exact<{
  cameraPanTiltInput: CameraPanTiltInput;
}>;


export type PanTiltVideoCameraMutation = { __typename?: 'Mutation', panTiltCamera?: { __typename?: 'JobRequest', success: boolean } | null };

export type VideoCameraZoomMutationVariables = Exact<{
  cameraZoomInput: CameraZoomInput;
}>;


export type VideoCameraZoomMutation = { __typename?: 'Mutation', zoomCamera?: { __typename?: 'JobRequest', success: boolean } | null };

export type VideoCameraPublicFeedMutationVariables = Exact<{
  cameraPublicFeedInput: CameraPublicFeedInput;
}>;


export type VideoCameraPublicFeedMutation = { __typename?: 'Mutation', cameraPublicFeed?: { __typename?: 'JobRequest', success: boolean } | null };

export type SetAsHomeCameraPresetMutationVariables = Exact<{
  homePresetInput: HomePresetInput;
}>;


export type SetAsHomeCameraPresetMutation = { __typename?: 'Mutation', setAsHomePreset?: { __typename?: 'Preset', id: string, presetIndex: number, name: string, isHomePreset?: boolean | null } | null };

export type VideoCameraGoToPresetMutationVariables = Exact<{
  cameraId: Scalars['String'];
  presetId: Scalars['String'];
}>;


export type VideoCameraGoToPresetMutation = { __typename?: 'Mutation', gotoPreset?: { __typename?: 'JobRequest', success: boolean, requestId?: string | null, id: string, failure?: string | null } | null };

export type SaveVideoCameraPresetMutationVariables = Exact<{
  input: PresetInput;
}>;


export type SaveVideoCameraPresetMutation = { __typename?: 'Mutation', savePreset?: { __typename?: 'Preset', id: string, presetIndex: number, name: string } | null };

export type UseVideoCameraCapabilityMutationVariables = Exact<{
  cameraId: Scalars['ID'];
  capability: CameraCapability;
  option: Scalars['String'];
}>;


export type UseVideoCameraCapabilityMutation = { __typename?: 'Mutation', useCameraCapability?: { __typename?: 'JobRequest', success: boolean } | null };

export type EnableVideoCameraAutoFocusMutationVariables = Exact<{
  cameraAutoFocusInput: CameraAutoFocusInput;
}>;


export type EnableVideoCameraAutoFocusMutation = { __typename?: 'Mutation', enableCameraAutoFocus?: (
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  ) | null };

export type EnableVideoCameraAutoIrisMutationVariables = Exact<{
  cameraAutoIrisInput: CameraAutoIrisInput;
}>;


export type EnableVideoCameraAutoIrisMutation = { __typename?: 'Mutation', enableCameraAutoIris?: (
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  ) | null };

export type FocusVideoCameraMutationVariables = Exact<{
  cameraFocusInput: CameraFocusInput;
}>;


export type FocusVideoCameraMutation = { __typename?: 'Mutation', focusCamera?: (
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  ) | null };

export type IrisVideoCameraMutationVariables = Exact<{
  cameraIrisInput: CameraIrisInput;
}>;


export type IrisVideoCameraMutation = { __typename?: 'Mutation', irisCamera?: (
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  ) | null };

export type VideoCameraRecordingMutationVariables = Exact<{
  cameraId: Scalars['String'];
  streamRecorderId: Scalars['String'];
  streamAccessType: VideoStreamAccessType;
  recordingType?: InputMaybe<RecordingType>;
  start: Scalars['Boolean'];
}>;


export type VideoCameraRecordingMutation = { __typename?: 'Mutation', cameraRecording?: (
    { __typename?: 'JobRequest' }
    & JobRequestFragment
  ) | null };

export type VideoCameraExplicitLockMutationVariables = Exact<{
  cameraId: Scalars['ID'];
}>;


export type VideoCameraExplicitLockMutation = { __typename?: 'Mutation', cameraExplicitLock?: boolean | null };

export type UnlockPtzVideoCameraMutationVariables = Exact<{
  cameraId: Scalars['ID'];
}>;


export type UnlockPtzVideoCameraMutation = { __typename?: 'Mutation', unlockPTZCamera?: boolean | null };

export type ChangeVideoTourStateMutationVariables = Exact<{
  tourId: Scalars['ID'];
  paused?: InputMaybe<Scalars['Boolean']>;
}>;


export type ChangeVideoTourStateMutation = { __typename?: 'Mutation', changeTourState: boolean };

export type RemoveUserPictureMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveUserPictureMutation = { __typename?: 'Mutation', removeUserPicture?: boolean | null };

export type SaveCommunicationLogMutationVariables = Exact<{
  input: CommunicationLogInput;
}>;


export type SaveCommunicationLogMutation = { __typename?: 'Mutation', saveCommunicationLog: (
    { __typename?: 'CommunicationLog' }
    & CommunicationLogFragment
  ) };

export type DeleteUserTenantPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
}>;


export type DeleteUserTenantPreferenceMutation = { __typename?: 'Mutation', deleteUserPreference: boolean };

export type DeleteUserKineticPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
}>;


export type DeleteUserKineticPreferenceMutation = { __typename?: 'Mutation', deleteKineticUserPreference: boolean };

export type DeleteOrganizationPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
}>;


export type DeleteOrganizationPreferenceMutation = { __typename?: 'Mutation', deleteOrganizationPreference: boolean };

export type UpdatePartialKineticUserPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type UpdatePartialKineticUserPreferenceMutation = { __typename?: 'Mutation', updatePartialUserPreference: any };

export type RemovePartialKineticUserPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
  valueId: Scalars['String'];
}>;


export type RemovePartialKineticUserPreferenceMutation = { __typename?: 'Mutation', removePartialUserPreference: any };

export type SaveUserPrefMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type SaveUserPrefMutation = { __typename?: 'Mutation', saveUserPreference: any };

export type SaveKineticUserPrefMutationMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type SaveKineticUserPrefMutationMutation = { __typename?: 'Mutation', saveKineticUserPreference: any };

export type SaveOrganizationPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type SaveOrganizationPreferenceMutation = { __typename?: 'Mutation', saveOrganizationPreference: any };

export type UpdatePartialOrganizationPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type UpdatePartialOrganizationPreferenceMutation = { __typename?: 'Mutation', updatePartialOrganizationPreference: any };

export type RemovePartialOrganizationPreferenceMutationVariables = Exact<{
  key: Scalars['ID'];
  valueId: Scalars['String'];
}>;


export type RemovePartialOrganizationPreferenceMutation = { __typename?: 'Mutation', removePartialOrganizationPreference: any };

export type GetUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'KineticUserConnection', edges: Array<{ __typename?: 'KineticUserConnectionEdge', node: { __typename?: 'OrganizationKineticUser', id: string, username: string, firstName?: string | null, lastName?: string | null, email?: string | null } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetKineticUserDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKineticUserDataQuery = { __typename?: 'Query', getKineticUser?: (
    { __typename?: 'OrganizationKineticUser' }
    & SharedKineticUserFragmentFragment
  ) | null };

export type GetBasicUserGroupsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetBasicUserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'KineticUserGroup', id: string, name: string }> };

export type TravelTimeNameQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  limit: Scalars['Int'];
}>;


export type TravelTimeNameQuery = { __typename?: 'Query', travelTimes: { __typename?: 'TravelTimeConnection', edges: Array<{ __typename?: 'TravelTimeConnectionEdge', node: { __typename?: 'TravelTime', id: string, name: string } }> } };

export type BasicUserGroupsUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type BasicUserGroupsUpdatedSubscription = { __typename?: 'Subscription', userGroupsUpdated?: { __typename?: 'KineticUserGroup', id: string, name: string } | null };

export type GetBasicIntegrationGroupsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetBasicIntegrationGroupsQuery = { __typename?: 'Query', integrationGroups: { __typename?: 'IntegrationGroupConnection', edges: Array<{ __typename?: 'IntegrationGroupConnectionEdge', node: { __typename?: 'IntegrationGroup', id: string, name: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetDefaultMapConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultMapConfigQuery = { __typename?: 'Query', mapConfig?: (
    { __typename?: 'MapConfig' }
    & MapConfigFragmentFragment
  ) | null };

export type GetMapLayerConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMapLayerConfigsQuery = { __typename?: 'Query', mapLayerConfigs: Array<(
    { __typename?: 'MapLayerConfig' }
    & MapLayerConfigFragmentFragment
  )> };

export type GetMapLayerConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapLayerConfigQuery = { __typename?: 'Query', mapLayerConfig?: (
    { __typename?: 'MapLayerConfig' }
    & MapLayerConfigFragmentFragment
  ) | null };

export type GetMapConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapConfigQuery = { __typename?: 'Query', mapConfig?: (
    { __typename?: 'MapConfig' }
    & MapConfigFragmentFragment
  ) | null };

export type GetMapConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMapConfigsQuery = { __typename?: 'Query', mapConfigs: Array<(
    { __typename?: 'MapConfig' }
    & MapConfigFragmentFragment
  )> };

export type SignMessagePrioritiesQueryVariables = Exact<{ [key: string]: never; }>;


export type SignMessagePrioritiesQuery = { __typename?: 'Query', signMessagePriorities: Array<{ __typename?: 'SignMessagePriority', travelTimeDefault: boolean, messageDefault: boolean, description?: string | null, priority: number, name: string, id: string }> };

export type EffectivePrivilegesQueryVariables = Exact<{ [key: string]: never; }>;


export type EffectivePrivilegesQuery = { __typename?: 'Query', effectivePrivileges: Array<{ __typename?: 'KineticEffectivePrivilegeAssignment', id: string, privilegeType: PrivilegeType, kineticModule: KineticModule, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null }> };

export type EffectivePrivilegesUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EffectivePrivilegesUpdatedSubscription = { __typename?: 'Subscription', effectivePrivilegesUpdated?: Array<{ __typename?: 'KineticEffectivePrivilegeAssignment', id: string, privilegeType: PrivilegeType, kineticModule: KineticModule, restrictedIntegrations: Array<string>, restrictedAreas: Array<any>, restrictedEntities?: any | null } | null> | null };

export type GetCameraCapabilitiesQueryVariables = Exact<{
  cameraId: Scalars['ID'];
}>;


export type GetCameraCapabilitiesQuery = { __typename?: 'Query', cameraCapabilities?: Array<{ __typename?: 'CameraCapabilityDto', capability: CameraCapability, options?: Array<string | null> | null }> | null };

export type GetCameraQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCameraQuery = { __typename?: 'Query', camera?: (
    { __typename?: 'Camera' }
    & CameraNodeFragment
  ) | null };

export type GetVideoTasksQueryVariables = Exact<{
  cameraId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  timezone: Scalars['String'];
}>;


export type GetVideoTasksQuery = { __typename?: 'Query', videoTasks?: Array<{ __typename?: 'DateTasks', date: any, tasks?: Array<(
      { __typename?: 'TaskOccurrence' }
      & TaskOccurrenceFragmentFragment
    ) | null> | null }> | null };

export type TaskUpdatedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  creation?: InputMaybe<Scalars['Boolean']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
}>;


export type TaskUpdatedSubscription = { __typename?: 'Subscription', taskUpdated: (
    { __typename?: 'Task' }
    & TaskFragmentFragment
  ) };

export type CameraUpdatedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CameraUpdatedSubscription = { __typename?: 'Subscription', cameraUpdated: { __typename?: 'Camera', id: string, name: string, presets?: Array<{ __typename?: 'Preset', id: string, name: string, presetIndex: number, isHomePreset?: boolean | null } | null> | null } };

export type TravelTimeUpdatedShortSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TravelTimeUpdatedShortSubscription = { __typename?: 'Subscription', travelTimeUpdated: { __typename?: 'TravelTime', id: string } };

export type SignSpeedSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignSpeedSettingsQuery = { __typename?: 'Query', sign: { __typename?: 'Sign', speedSettings?: (
      { __typename?: 'SignSpeedSettings' }
      & SignSpeedSettingsFragment
    ) | null } };

export type SignCapabilitiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignCapabilitiesQuery = { __typename?: 'Query', sign: { __typename?: 'Sign', capabilities: Array<SignCapability> } };

export type SignMessageValidateQueryVariables = Exact<{
  id: Scalars['ID'];
  message: SignMessageInput;
}>;


export type SignMessageValidateQuery = { __typename?: 'Query', validateSignMessage: { __typename?: 'SignMessageValidationResult', failure?: string | null } };

export type UserPreferenceUpdatedSubscriptionVariables = Exact<{
  key: Scalars['String'];
}>;


export type UserPreferenceUpdatedSubscription = { __typename?: 'Subscription', userPreferenceUpdated: { __typename?: 'UserPreferenceStreamObject', userPreference: { __typename?: 'UserPreference', value: any } } };

export type OrganizationPreferenceUpdatedSubscriptionVariables = Exact<{
  key?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationPreferenceUpdatedSubscription = { __typename?: 'Subscription', organizationPreferenceUpdated: { __typename?: 'OrganizationPreferenceStreamObject', streamObjectOperation: StreamObjectOperation, organizationPreference: { __typename?: 'OrganizationPreference', key: string, value: any } } };

export type SignGroupsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
}>;


export type SignGroupsListQuery = { __typename?: 'Query', signGroups: { __typename?: 'SignGroupConnection', totalCount: number, edges: Array<{ __typename?: 'SignGroupConnectionEdge', node: { __typename?: 'SignGroup', description?: string | null, attributes: any, type: SignGroupType, name: string, id: string, signs: Array<{ __typename?: 'Sign', referencePoint?: number | null, direction?: string | null, id: string, displayType: (
            { __typename?: 'SignDisplayType' }
            & SignDisplayTypeFragment
          ) }> } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SignGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignGroupQuery = { __typename?: 'Query', signGroup: { __typename?: 'SignGroup', description?: string | null, attributes: any, type: SignGroupType, name: string, id: string, signs: Array<{ __typename?: 'Sign', location: any, name: string, id: string }> } };

export type SignListUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SignListUpdatedSubscription = { __typename?: 'Subscription', signUpdated: (
    { __typename?: 'Sign' }
    & SignListFragment
  ) };

export type DetectorsListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DetectorsListQuery = { __typename?: 'Query', detectors: { __typename?: 'DetectorConnection', totalCount: number, edges: Array<{ __typename?: 'DetectorConnectionEdge', node: (
        { __typename?: 'Detector' }
        & DetectorFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type DetectorListUpdatedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type DetectorListUpdatedSubscription = { __typename?: 'Subscription', detectorUpdated: (
    { __typename?: 'Detector' }
    & DetectorFragment
  ) };

export type CamerasQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type CamerasQuery = { __typename?: 'Query', cameras: { __typename?: 'CameraConnection', totalCount: number, edges: Array<{ __typename?: 'CameraConnectionEdge', node: (
        { __typename?: 'Camera' }
        & CameraNodeFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type ContactListShortQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type ContactListShortQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactConnection', totalCount: number, edges: Array<{ __typename?: 'ContactConnectionEdge', node: { __typename?: 'Contact', id: string, firstName: string, lastName: string, email: string, workNumber: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type ContactGroupShortListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type ContactGroupShortListQuery = { __typename?: 'Query', contactGroupsQuery: { __typename?: 'ContactGroupConnection', edges: Array<{ __typename?: 'ContactGroupConnectionEdge', node: { __typename?: 'ContactGroupDto', id: string, name: string } }> } };

export type OrganizationsListShortQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationsListShortQuery = { __typename?: 'Query', organizationsContact: { __typename?: 'OrganizationConnection', edges: Array<{ __typename?: 'OrganizationConnectionEdge', node: { __typename?: 'OrganizationDto', id: string, name: string } }> } };

export type ContactUpdatedSubscriptionSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ContactUpdatedSubscriptionSubscription = { __typename?: 'Subscription', contactUpdated?: { __typename?: 'ContactStreamObject', streamObjectOperation?: StreamObjectOperation | null, dto?: { __typename?: 'Contact', id: string } | null } | null };

export type ActivityLogsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  filters: Array<FilterInput> | FilterInput;
}>;


export type ActivityLogsQuery = { __typename?: 'Query', activityLogs: { __typename?: 'ActivityLogConnection', totalCount: number, edges: Array<{ __typename?: 'ActivityLogEdge', node: (
        { __typename?: 'ActivityLog' }
        & ActivityLogNodeFragmentFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type ActivityLogAddedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ActivityLogAddedSubscription = { __typename?: 'Subscription', addedActivityLog: (
    { __typename?: 'ActivityLog' }
    & ActivityLogNodeFragmentFragment
  ) };

export type DataSourceConfigurationListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  module?: InputMaybe<KineticModule>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DataSourceConfigurationListQuery = { __typename?: 'Query', dataSourceConfigs: { __typename?: 'DataSourceConfigConnection', totalCount: number, edges: Array<{ __typename?: 'DataSourceConfigConnectionEdge', node: (
        { __typename?: 'DataSourceConfig' }
        & DataSourceConfigFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type DataSourceListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  sourceId?: InputMaybe<Scalars['ID']>;
}>;


export type DataSourceListQuery = { __typename?: 'Query', dataSources: { __typename?: 'DataSourceConnection', totalCount: number, edges: Array<{ __typename?: 'DataSourceConnectionEdge', node: (
        { __typename?: 'DataSource' }
        & DataSourceFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type DataSourceConfigDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataSourceConfigDetailQuery = { __typename?: 'Query', dataSourceConfig: (
    { __typename?: 'DataSourceConfig' }
    & DataSourceConfigFragment
  ) };

export type DataSourceDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataSourceDetailQuery = { __typename?: 'Query', dataSource: (
    { __typename?: 'DataSource' }
    & DataSourceFragment
  ) };

export type DataSourceConfigNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataSourceConfigNameQuery = { __typename?: 'Query', dataSourceConfig: { __typename?: 'DataSourceConfig', name: string } };

export type GetKineticFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKineticFormQuery = { __typename?: 'Query', kineticForm: (
    { __typename?: 'KineticForm' }
    & KineticFormFragmentFragment
  ) };

export type GetFileListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  filterInput?: InputMaybe<Array<FilterInput> | FilterInput>;
  centerId?: InputMaybe<Scalars['String']>;
}>;


export type GetFileListQuery = { __typename?: 'Query', getFiles: { __typename?: 'FileConnection', totalCount: number, edges: Array<{ __typename?: 'FileEdge', node: { __typename?: 'File', id: string, name: string, size?: number | null, contentType?: string | null, path: string, timestamp?: any | null, module?: KineticModule | null, key?: string | null, additionalData?: any | null } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetGlobalFileListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  filterInput?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type GetGlobalFileListQuery = { __typename?: 'Query', getGlobalFiles: { __typename?: 'GlobalFileConnection', totalCount: number, edges: Array<{ __typename?: 'FileEdge', node: { __typename?: 'File', id: string, name: string, size?: number | null, contentType?: string | null, path: string, timestamp?: any | null, module?: KineticModule | null, key?: string | null, additionalData?: any | null } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetCameraSnapshotQueryVariables = Exact<{
  cameraId: Scalars['ID'];
}>;


export type GetCameraSnapshotQuery = { __typename?: 'Query', getCameraSnapshot?: string | null };

export type GetKineticFormsQueryVariables = Exact<{
  query?: InputMaybe<KineticFormInputQuery>;
}>;


export type GetKineticFormsQuery = { __typename?: 'Query', kineticForms: { __typename?: 'KineticFormConnection', edges: Array<{ __typename?: 'KineticFormConnectionEdge', node: (
        { __typename?: 'KineticForm' }
        & KineticFormFragmentFragment
      ) }> } };

export type CorridorEventsQueryVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CorridorEventsQuery = { __typename?: 'Query', events: { __typename?: 'EventConnection', edges: Array<{ __typename?: 'EventConnectionEdge', node: (
        { __typename?: 'Event' }
        & CorridorEventFragment
      ) }> } };

export type CorridorEventUpdatedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CorridorEventUpdatedSubscription = { __typename?: 'Subscription', eventUpdated: (
    { __typename?: 'Event' }
    & CorridorEventFragment
  ) };

export type UserSignalPrivilegesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSignalPrivilegesQuery = { __typename?: 'Query', userSignalPrivileges: Array<{ __typename?: 'SignalPrivilege', privilegeType: PrivilegeType, restrictions: any, lastUpdated: number }> };

export type SignMessageHistoryQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  association?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type SignMessageHistoryQuery = { __typename?: 'Query', signMessageHistory: { __typename?: 'SignMessageHistoryConnection', totalCount: number, edges: Array<{ __typename?: 'SignMessageHistoryConnectionEdge', node: (
        { __typename?: 'SignMessageHistory' }
        & SignMessageHistoryFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type IntersectionGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntersectionGroupsQuery = { __typename?: 'Query', intersectionGroupsUntyped: Array<any> };

export type CommunicationLogListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type CommunicationLogListQuery = { __typename?: 'Query', communicationLogs: { __typename?: 'CommunicationLogConnection', totalCount: number, edges: Array<{ __typename?: 'CommunicationLogEdge', node: (
        { __typename?: 'CommunicationLog' }
        & CommunicationLogFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type EventReviewQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters: Array<FilterInput> | FilterInput;
  entityIds: Array<Scalars['String']> | Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type EventReviewQuery = { __typename?: 'Query', history: { __typename?: 'HistoricalEntityConnection', totalCount: number, edges: Array<{ __typename?: 'HistoricalEntityEdge', node: { __typename?: 'HistoricalEntity', id: string, type: HistoricalEntityType, entityId: string, entity: any, timestamp: any } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type GetCommunicationLogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCommunicationLogQuery = { __typename?: 'Query', communicationLog?: (
    { __typename?: 'CommunicationLog' }
    & CommunicationLogFragment
  ) | null };

export type CommunicationLogUpdatedSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CommunicationLogUpdatedSubscription = { __typename?: 'Subscription', communicationLogUpdated?: { __typename?: 'CommunicationLogStreamObject', streamObjectOperation?: StreamObjectOperation | null, dto?: (
      { __typename?: 'CommunicationLog' }
      & CommunicationLogFragment
    ) | null } | null };

export type ShortEventListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ShortEventListQuery = { __typename?: 'Query', events: { __typename?: 'EventConnection', totalCount: number, edges: Array<{ __typename?: 'EventConnectionEdge', node: { __typename?: 'Event', name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type KineticEventFormQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type KineticEventFormQuery = { __typename?: 'Query', kineticEventForm?: (
    { __typename?: 'KineticForm' }
    & KineticFormFragmentFragment
  ) | null };

export type EventListUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type EventListUpdatedSubscription = { __typename?: 'Subscription', eventUpdated: (
    { __typename?: 'Event' }
    & EventListFragment
  ) };

export type EventTypesQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  category: Scalars['Object'];
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type EventTypesQuery = { __typename?: 'Query', dataSources: { __typename?: 'DataSourceConnection', totalCount: number, edges: Array<{ __typename?: 'DataSourceConnectionEdge', node: { __typename?: 'DataSource', enabled?: boolean | null, value: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SignFontsQueryVariables = Exact<{ [key: string]: never; }>;


export type SignFontsQuery = { __typename?: 'Query', signFonts: Array<{ __typename?: 'SignFont', characterSpacing: number, characterHeight: number, fontFileName: string, lineSpacing: number, fontVersion: number, font: string, id: string }> };

export type SignControllerFontsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignControllerFontsQuery = { __typename?: 'Query', signControllerFonts: Array<{ __typename?: 'SignControllerFont', characterSpacing: number, characterHeight: number, fontFileName: string, lineSpacing: number, fontVersion: number, font: string, id: string }> };

export type SignDefaultFontQueryVariables = Exact<{ [key: string]: never; }>;


export type SignDefaultFontQuery = { __typename?: 'Query', signDefaultFont: { __typename?: 'SignFont', characterSpacing: number, characterHeight: number, fontFileName: string, lineSpacing: number, fontVersion: number, font: string, id: string } };

export type SignFontQueryVariables = Exact<{
  hash: Scalars['ID'];
}>;


export type SignFontQuery = { __typename?: 'Query', signFont: { __typename?: 'SignFont', characterSpacing: number, characterHeight: number, fontFileName: string, lineSpacing: number, fontVersion: number, font: string, id: string } };

export type SignMessageLibraryQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  type?: InputMaybe<SignMessageType>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['ID']>;
}>;


export type SignMessageLibraryQuery = { __typename?: 'Query', signMessages: { __typename?: 'SignLibraryMessageConnection', totalCount: number, edges: Array<{ __typename?: 'SignLibraryMessageConnectionEdge', cursor?: string | null, node: (
        { __typename?: 'SignLibraryMessage' }
        & SignLibraryMessageFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SignLibraryMessageUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SignLibraryMessageUpdatedSubscription = { __typename?: 'Subscription', libraryMessageUpdated: (
    { __typename?: 'SignLibraryMessage' }
    & SignLibraryMessageFragment
  ) };

export type SignMessagesQueryVariables = Exact<{
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  type?: InputMaybe<SignMessageType>;
  sortOrder?: InputMaybe<SortOrder>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sign: Scalars['ID'];
}>;


export type SignMessagesQuery = { __typename?: 'Query', signMessageLibrary: { __typename?: 'SignLibraryMessageConnection', totalCount: number, edges: Array<{ __typename?: 'SignLibraryMessageConnectionEdge', cursor?: string | null, node: (
        { __typename?: 'SignLibraryMessage' }
        & SignLibraryMessageFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SignCommandMessageQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  command: Scalars['String'];
}>;


export type SignCommandMessageQuery = { __typename?: 'Query', signCommandMessage: Array<{ __typename?: 'SignLibraryMessage', sign?: string | null, message: (
      { __typename?: 'SignMessage' }
      & SignMessageFragment
    ) }> };

export type GetLedEditorManagedGraphicsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLedEditorManagedGraphicsQuery = { __typename?: 'Query', signControllerGraphics: Array<{ __typename?: 'SignGraphic', height: number, width: number, image: any, name: string, slot?: number | null, id: string }> };

export type GetSignGraphicsQueryVariables = Exact<{
  filterInput: Array<FilterInput> | FilterInput;
}>;


export type GetSignGraphicsQuery = { __typename?: 'Query', getManagedFiles: { __typename?: 'ManagedFileConnection', edges: Array<{ __typename?: 'ManagedFileEdge', node: { __typename?: 'ManagedFile', height?: number | null, image?: string | null, width?: number | null, name: string, id: string } }> } };

export type UpdateQueuedMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  queuedMessage: SignQueuedMessageInput;
}>;


export type UpdateQueuedMessageMutation = { __typename?: 'Mutation', updateQueuedMessage: { __typename?: 'JobRequest', success: boolean, failure?: string | null, id: string } };

export type SignSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SignSelectionListQuery = { __typename?: 'Query', signs: { __typename?: 'SignConnection', totalCount: number, edges: Array<{ __typename?: 'SignConnectionEdge', node: { __typename?: 'Sign', referencePoint?: number | null, lanePosition?: number | null, direction?: string | null, laneType?: LaneType | null, road?: string | null, name: string, id: string, displayType: (
          { __typename?: 'SignDisplayType' }
          & SignDisplayTypeFragment
        ) } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type RoadsQueryVariables = Exact<{
  integrationType?: InputMaybe<Array<InputMaybe<IntegrationType>> | InputMaybe<IntegrationType>>;
}>;


export type RoadsQuery = { __typename?: 'Query', roads?: Array<string | null> | null };

export type SignMessageDisplayTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type SignMessageDisplayTypesQuery = { __typename?: 'Query', signMessageDisplayTypes: Array<{ __typename?: 'SignMessageDisplayType', characterHeight?: number | null, characterWidth?: number | null, maxCharacters?: number | null, defaultFont?: string | null, matrixType: SignMatrixType, maxLines?: number | null, height: number, width: number, hash: string }> };

export type SignalActionSetOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SignalActionSetOptionsQuery = { __typename?: 'Query', signalActionSetOptions: Array<{ __typename?: 'FieldConfigOption', label?: string | null, value?: any | null } | null> };

export type SignalActionSetSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SignalActionSetSelectionListQuery = { __typename?: 'Query', signalActionSets: { __typename?: 'SignalActionSetConnection', totalCount: number, edges: Array<{ __typename?: 'SignalActionSetConnectionEdge', node: { __typename?: 'SignalActionSet', number: number, impact?: SignalActionSetImpactType | null, name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type SaveResponseRuleMutationVariables = Exact<{
  input: ResponseRuleInput;
}>;


export type SaveResponseRuleMutation = { __typename?: 'Mutation', saveResponseRule: { __typename?: 'ResponseRule', id: string } };

export type DeleteResponseRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteResponseRuleMutation = { __typename?: 'Mutation', deleteResponseRule?: boolean | null };

export type ResponseRulesListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ResponseRulesListQuery = { __typename?: 'Query', responseRules: { __typename?: 'ResponseRuleConnection', totalCount: number, edges: Array<{ __typename?: 'ResponseRuleConnectionEdge', node: { __typename?: 'ResponseRule', automatic: boolean, enabled: boolean, form?: string | null, name: string, id: string, condition: { __typename?: 'ResponseRuleCondition', priority: number, type: ResponseRuleConditionType }, action: { __typename?: 'ResponseRuleAction', type: ResponseRuleActionType } } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type KineticFormTemplateFieldsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KineticFormTemplateFieldsQuery = { __typename?: 'Query', kineticFormTemplateFields: Array<{ __typename?: 'KineticFormFieldOption', label: string, value: string, type?: string | null }> };

export type KineticFormOptionsListQueryVariables = Exact<{
  query: KineticFormInputQuery;
}>;


export type KineticFormOptionsListQuery = { __typename?: 'Query', kineticForms: { __typename?: 'KineticFormConnection', edges: Array<{ __typename?: 'KineticFormConnectionEdge', node: { __typename?: 'KineticForm', name: string, id: string } }> } };

export type ResponseRuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResponseRuleQuery = { __typename?: 'Query', responseRule: { __typename?: 'ResponseRule', automatic: boolean, corridor?: string | null, enabled: boolean, type: ResponseRuleType, form?: string | null, name: string, id: string, condition: { __typename?: 'ResponseRuleCondition', type: ResponseRuleConditionType, priority: number, tree: any, rule: string }, action: { __typename?: 'ResponseRuleAction', resourcePlans: Array<string>, actionSets: Array<string>, type: ResponseRuleActionType, message?: (
        { __typename?: 'SignMessage' }
        & SignMessageFragment
      ) | null } } };

export type ResourcePlanSelectionOptionsQueryVariables = Exact<{
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type ResourcePlanSelectionOptionsQuery = { __typename?: 'Query', resourcePlans: { __typename?: 'ResourcePlanConnection', edges: Array<{ __typename?: 'ResourcePlanConnectionEdge', node: { __typename?: 'ResourcePlan', id: string, details: { __typename?: 'ResourcePlanDetails', name: string } } }> } };

export type ActionSetSelectionOptionsQueryVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
}>;


export type ActionSetSelectionOptionsQuery = { __typename?: 'Query', signalActionSets: { __typename?: 'SignalActionSetConnection', edges: Array<{ __typename?: 'SignalActionSetConnectionEdge', node: { __typename?: 'SignalActionSet', name: string, id: string } }> } };

export type SignSelectionOptionsQueryVariables = Exact<{
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type SignSelectionOptionsQuery = { __typename?: 'Query', signs: { __typename?: 'SignConnection', edges: Array<{ __typename?: 'SignConnectionEdge', node: { __typename?: 'Sign', name: string, id: string } }> } };

export type ResponseRuleConfigQueryVariables = Exact<{
  conditionType: ResponseRuleConditionType;
  actionType: ResponseRuleActionType;
  form?: InputMaybe<Scalars['ID']>;
}>;


export type ResponseRuleConfigQuery = { __typename?: 'Query', responseRuleConfig: any };

export type CorridorIntegrationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CorridorIntegrationsQuery = { __typename?: 'Query', corridor: { __typename?: 'Corridor', integrations: Array<string> } };

export type CameraSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type CameraSelectionListQuery = { __typename?: 'Query', cameras: { __typename?: 'CameraConnection', totalCount: number, edges: Array<{ __typename?: 'CameraConnectionEdge', node: { __typename?: 'Camera', referencePoint?: string | null, direction?: string | null, road?: string | null, name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type DetectorSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type DetectorSelectionListQuery = { __typename?: 'Query', detectors: { __typename?: 'DetectorConnection', totalCount: number, edges: Array<{ __typename?: 'DetectorConnectionEdge', node: { __typename?: 'Detector', referencePoint?: number | null, direction?: string | null, road?: string | null, name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type ResourcePlanSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ResourcePlanSelectionListQuery = { __typename?: 'Query', resourcePlans: { __typename?: 'ResourcePlanConnection', totalCount: number, edges: Array<{ __typename?: 'ResourcePlanConnectionEdge', node: { __typename?: 'ResourcePlan', id: string, details: { __typename?: 'ResourcePlanDetails', description?: string | null, name: string } } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type LiveResourcePlanSelectionListSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type LiveResourcePlanSelectionListSubscription = { __typename?: 'Subscription', resourcePlanUpdated?: { __typename?: 'ResourcePlan', id: string, details: { __typename?: 'ResourcePlanDetails', description?: string | null, name: string } } | null };

export type SignSelectionMapEntitiesQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
}>;


export type SignSelectionMapEntitiesQuery = { __typename?: 'Query', signMapEntities: Array<any> };

export type TravelTimeSelectionListQueryVariables = Exact<{
  sortOrder?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type TravelTimeSelectionListQuery = { __typename?: 'Query', travelTimes: { __typename?: 'TravelTimeConnection', totalCount: number, edges: Array<{ __typename?: 'TravelTimeConnectionEdge', node: { __typename?: 'TravelTime', name: string, id: string, parts: Array<{ __typename?: 'TravelTimePart', startReference: number, endReference: number, direction: Direction, road: string }> } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type WeatherStationSelectionListQueryVariables = Exact<{
  sortField?: InputMaybe<WeatherStationSortField>;
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type WeatherStationSelectionListQuery = { __typename?: 'Query', weatherStations: { __typename?: 'WeatherStationConnection', totalCount: number, edges: Array<{ __typename?: 'WeatherStationConnectionEdge', node: { __typename?: 'WeatherStation', referencePoint?: number | null, direction?: string | null, road?: string | null, name: string, id: string } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'KineticUserConnection', edges: Array<{ __typename?: 'KineticUserConnectionEdge', node: { __typename?: 'OrganizationKineticUser', id: string, firstName?: string | null, lastName?: string | null } }> } };

export type CursorPageInfoFragment = { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type EventTypeShortFragment = { __typename?: 'EventType', name: string, id: string };

export type EventTypesListQueryVariables = Exact<{ [key: string]: never; }>;


export type EventTypesListQuery = { __typename?: 'Query', eventTypes: { __typename?: 'EventTypeConnection', edges: Array<{ __typename?: 'EventTypeConnectionEdge', node: (
        { __typename?: 'EventType' }
        & EventTypeShortFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CursorPageInfoFragment
    ) } };

export const FieldConfigFragmentFragmentDoc = gql`
    fragment FieldConfigFragment on FieldConfig {
  id
  name
  label
  type
  required
  help
  hint
  fieldSize
  sortOrder
  options {
    label
    value
  }
  disablePast
  disableFuture
  useStepper
  max
  min
  step
  multiple
  limitTags
  selectAllLabel
  deselectAllLabel
  useCheckedIcon
  useIcon
  choicesTitle
  defaultValue
  format
  displayFormat
  activeLogic
  requiredLogic
  moduleName
  sourceId
  uploadType
  accept
  loading
  valueLabelDisplay
  showInput
  maxLength
  uniqueFields
  multiline
  rows
  mode
  disabled
  disabledLogic
}
    `;
export const ModuleFormSectionFragmentFragmentDoc = gql`
    fragment ModuleFormSectionFragment on ModuleFormSection {
  fields {
    ...FieldConfigFragment
    fieldConfigs {
      ...FieldConfigFragment
      fieldConfigs {
        ...FieldConfigFragment
        fieldConfigs {
          ...FieldConfigFragment
        }
      }
    }
  }
  name
  id
}
    ${FieldConfigFragmentFragmentDoc}`;
export const FormSectionFragmentFragmentDoc = gql`
    fragment FormSectionFragment on FormSection {
  fields {
    ...FieldConfigFragment
    fieldConfigs {
      ...FieldConfigFragment
      fieldConfigs {
        ...FieldConfigFragment
        fieldConfigs {
          ...FieldConfigFragment
        }
      }
    }
  }
  name
  id
}
    ${FieldConfigFragmentFragmentDoc}`;
export const FormViewFragmentFragmentDoc = gql`
    fragment FormViewFragment on FormView {
  sections {
    ...FormSectionFragment
  }
  type
  id
  name
}
    ${FormSectionFragmentFragmentDoc}`;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  userIds
  systemNotification
  source
  severity
  createdTime
  acknowledgedTime
  acknowledgedNotes
  acknowledgedBy
  acknowledgedByName
  location
  objectId
  ignorable
  title
  details
  updatedTS
  route
  data
  actions {
    route
    title
    toolTip
  }
}
    `;
export const PrivilegeAssignmentFragmentFragmentDoc = gql`
    fragment PrivilegeAssignmentFragment on KineticPrivilegeAssignment {
  id
  privilegeType
  kineticModule
  restrictedIntegrationGroups
  restrictedIntegrations
  restrictedAreas
  restrictedEntities
}
    `;
export const KineticUserFragmentFragmentDoc = gql`
    fragment KineticUserFragment on OrganizationKineticUser {
  id
  username
  firstName
  lastName
  email
  phoneNumbers
  privilegeAssignmentList {
    ...PrivilegeAssignmentFragment
  }
  roleIds
  userGroupIds
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;
export const KineticFormEventTypeFragmentDoc = gql`
    fragment KineticFormEventType on EventType {
  id
  name
}
    `;
export const KineticFormFieldFragmentDoc = gql`
    fragment KineticFormField on KineticFormField {
  id
  name
  description
  label
  type
  fieldSize
  help
  hint
  options {
    label
    value
    type
  }
  disablePast
  disableFuture
  format
  displayFormat
  max
  min
  useStepper
  step
  source
  multiple
  sortOrder
  requiredLogic
  activeLogic
  moduleName
  loading
  uploadType
  accept
  required
  defaultValue
  mode
  iconConfig
  dataSource
}
    `;
export const KineticFormSectionFragmentDoc = gql`
    fragment KineticFormSection on KineticFormSection {
  id
  name
  fields {
    ...KineticFormField
  }
}
    ${KineticFormFieldFragmentDoc}`;
export const KineticFormViewFragmentDoc = gql`
    fragment KineticFormView on KineticFormView {
  id
  name
  sections {
    ...KineticFormSection
  }
}
    ${KineticFormSectionFragmentDoc}`;
export const KineticFormFragmentDoc = gql`
    fragment KineticForm on KineticForm {
  views {
    ...KineticFormView
  }
  system
  canOverride
  modified
  extraConfig
  description
  module
  name
  id
}
    ${KineticFormViewFragmentDoc}`;
export const TaskProgressFragmentFragmentDoc = gql`
    fragment TaskProgressFragment on TaskProgress {
  id
  name
  description
  status
  progress
  resource
  associatedIds
  systemTask
  userIds
  source
  created
  updated
  error
  task
  type
}
    `;
export const ViewFragmentFragmentDoc = gql`
    fragment viewFragment on IntersectionDeviceUIView {
  displayName
  name
}
    `;
export const SavedRouteFragmentDoc = gql`
    fragment SavedRoute on LocationRoute {
  id
  name
  classification
  fromMeasure
  toMeasure
  direction
  geometry
}
    `;
export const SubGroupShortFragmentDoc = gql`
    fragment SubGroupShort on ContactGroupDto {
  id
  name
  description
  parentGroup
  items {
    id
    firstName
    lastName
  }
}
    `;
export const ContactSubGroupShortRecursiveFragmentDoc = gql`
    fragment ContactSubGroupShortRecursive on ContactGroupDto {
  subGroups {
    ...SubGroupShort
    subGroups {
      ...SubGroupShort
      subGroups {
        ...SubGroupShort
        subGroups {
          ...SubGroupShort
          subGroups {
            ...SubGroupShort
          }
        }
      }
    }
  }
}
    ${SubGroupShortFragmentDoc}`;
export const ContactGroupShortFragmentFragmentDoc = gql`
    fragment ContactGroupShortFragment on ContactGroupDto {
  ...SubGroupShort
  ...ContactSubGroupShortRecursive
}
    ${SubGroupShortFragmentDoc}
${ContactSubGroupShortRecursiveFragmentDoc}`;
export const SignDisplayTypeFragmentDoc = gql`
    fragment SignDisplayType on SignDisplayType {
  matrixType
  characterWidth
  characterHeight
  defaultFont
  maxCharacters
  height
  width
  hash
  maxLines
}
    `;
export const SignImageFragmentDoc = gql`
    fragment SignImage on SignImage {
  height
  width
  image
  slot
  name
  id
}
    `;
export const SignPositionFragmentDoc = gql`
    fragment SignPosition on Position {
  x
  y
}
    `;
export const SignMessageLineSegmentFragmentDoc = gql`
    fragment SignMessageLineSegment on SignMessageLineSegment {
  horizontalJustification
  flashingEnabled
  foregroundColor
  backgroundColor
  flashOffMillis
  flashOnMillis
  font
  text
}
    `;
export const SignMessageLineFragmentDoc = gql`
    fragment SignMessageLine on SignMessageLine {
  integration
  horizontalJustification
  lineSegments {
    ...SignMessageLineSegment
  }
}
    ${SignMessageLineSegmentFragmentDoc}`;
export const SignMessageTextAreaFragmentDoc = gql`
    fragment SignMessageTextArea on SignMessageTextArea {
  verticalJustification
  height
  width
  position {
    ...SignPosition
  }
  lines {
    ...SignMessageLine
  }
}
    ${SignPositionFragmentDoc}
${SignMessageLineFragmentDoc}`;
export const SignMessageImageFragmentDoc = gql`
    fragment SignMessageImage on SignMessageImage {
  image
  position {
    ...SignPosition
  }
}
    ${SignPositionFragmentDoc}`;
export const SignMessagePageFragmentDoc = gql`
    fragment SignMessagePage on SignMessagePage {
  verticalJustification
  backgroundColor
  pageOffTime
  pageOnTime
  textAreas {
    ...SignMessageTextArea
  }
  images {
    ...SignMessageImage
  }
  lines {
    ...SignMessageLine
  }
}
    ${SignMessageTextAreaFragmentDoc}
${SignMessageImageFragmentDoc}
${SignMessageLineFragmentDoc}`;
export const SignMessageFragmentDoc = gql`
    fragment SignMessage on SignMessage {
  beaconsEnabled
  priority
  command
  slot
  type
  images {
    ...SignImage
  }
  pages {
    ...SignMessagePage
  }
}
    ${SignImageFragmentDoc}
${SignMessagePageFragmentDoc}`;
export const SignQueuedMessageFragmentDoc = gql`
    fragment SignQueuedMessage on SignQueuedMessage {
  associationDisplayNames
  expirationTime
  associations
  user
  id
  message {
    ...SignMessage
  }
}
    ${SignMessageFragmentDoc}`;
export const SignMessagePreferencesFragmentDoc = gql`
    fragment SignMessagePreferences on SignMessagePreferences {
  horizontalJustification
  verticalJustification
  pageOffTime
  pageOnTime
  id
}
    `;
export const SignSpeedSettingsFragmentDoc = gql`
    fragment SignSpeedSettings on SignSpeedSettings {
  secondaryMaximumSpeed
  secondaryMinimumSpeed
  backgroundColor
  foregroundColor
  maximumChange
  minimumSpeed
  maximumSpeed
  intervals
}
    `;
export const SignTestsTypeFragmentDoc = gql`
    fragment SignTestsType on SignTests {
  fan {
    running
    error
    fans
  }
  lamp {
    running
    stuckOff
    stuckOn
    error
  }
  pixel {
    pixels {
      x
      y
    }
    running
    error
  }
}
    `;
export const CorridorSchematicSignFragmentDoc = gql`
    fragment CorridorSchematicSign on Sign {
  displayType {
    ...SignDisplayType
  }
  currentMessage {
    ...SignQueuedMessage
  }
  communicationStatus
  messagePreferences {
    ...SignMessagePreferences
  }
  speedSettings {
    ...SignSpeedSettings
  }
  travelTimeSegments
  referencePoint
  capabilities
  lanePosition
  direction
  laneType
  road
  name
  id
  tests {
    ...SignTestsType
  }
}
    ${SignDisplayTypeFragmentDoc}
${SignQueuedMessageFragmentDoc}
${SignMessagePreferencesFragmentDoc}
${SignSpeedSettingsFragmentDoc}
${SignTestsTypeFragmentDoc}`;
export const DirectoryFragmentFragmentDoc = gql`
    fragment DirectoryFragment on Directory {
  id
  order
  name
  owner
  parentId
  readOnly
}
    `;
export const DirectoryRecursiveFragmentDoc = gql`
    fragment DirectoryRecursive on Directory {
  ...DirectoryFragment
  directories {
    ...DirectoryFragment
    directories {
      ...DirectoryFragment
      directories {
        ...DirectoryFragment
        directories {
          ...DirectoryFragment
          directories {
            ...DirectoryFragment
            directories {
              ...DirectoryFragment
              directories {
                ...DirectoryFragment
                directories {
                  ...DirectoryFragment
                  directories {
                    ...DirectoryFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DirectoryFragmentFragmentDoc}`;
export const SharedPrivilegeAssignmentFragmentFragmentDoc = gql`
    fragment SharedPrivilegeAssignmentFragment on KineticPrivilegeAssignment {
  id
  privilegeType
  kineticModule
  restrictedIntegrationGroups
  restrictedIntegrations
  restrictedAreas
  restrictedEntities
}
    `;
export const SharedKineticUserFragmentFragmentDoc = gql`
    fragment SharedKineticUserFragment on OrganizationKineticUser {
  id
  username
  firstName
  lastName
  email
  phoneNumbers
  privilegeAssignmentList {
    ...SharedPrivilegeAssignmentFragment
  }
  roleIds
  userGroupIds
}
    ${SharedPrivilegeAssignmentFragmentFragmentDoc}`;
export const FieldConfigFieldsFragmentDoc = gql`
    fragment FieldConfigFields on FieldConfig {
  activeLogic
  choicesTitle
  chosenTitle
  defaultValue
  showInput
  valueLabelDisplay
  deselectAllLabel
  disableFuture
  disablePast
  displayFormat
  fieldSize
  format
  help
  hint
  id
  label
  limitTags
  max
  maxLength
  min
  multiple
  name
  sortOrder
  options {
    label
    value
  }
  required
  requiredLogic
  selectAllLabel
  step
  type
  uniqueFields
  useCheckedIcon
  useIcon
  useStepper
  moduleName
  sourceId
  rows
  multiline
  mode
}
    `;
export const KineticFieldConfigFragmentDoc = gql`
    fragment KineticFieldConfig on FieldConfig {
  ...FieldConfigFields
}
    ${FieldConfigFieldsFragmentDoc}`;
export const MapConfigFragmentFragmentDoc = gql`
    fragment MapConfigFragment on MapConfig {
  id
  name
  geometry
  layers
  zoom
}
    `;
export const MapLayerConfigFragmentFragmentDoc = gql`
    fragment MapLayerConfigFragment on MapLayerConfig {
  id
  name
  type
  data
  baseLayer
  visible
  group
}
    `;
export const SignMessageDisplayTypeFragmentDoc = gql`
    fragment SignMessageDisplayType on SignMessageDisplayType {
  matrixType
  characterWidth
  characterHeight
  defaultFont
  maxCharacters
  height
  width
  hash
  maxLines
  signs
}
    `;
export const SignListDisplayTypeFragmentDoc = gql`
    fragment SignListDisplayType on SignMessageDisplayType {
  height
  width
  signs
  hash
}
    `;
export const ActivityLogNodeFragmentFragmentDoc = gql`
    fragment ActivityLogNodeFragment on ActivityLog {
  id
  entityId
  timestamp
  action
  description
  userId
  userFullName
  changes {
    path
    operation
    oldValue
    newValue
  }
  module
  object
  type
  user {
    id
    firstName
    lastName
    email
  }
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  name
  taskType
  timezone
  start
  end
  data
  taskRecurrenceType
  rrule
  recurrenceEndDate
  externalCenterId
}
    `;
export const TaskOccurrenceFragmentFragmentDoc = gql`
    fragment TaskOccurrenceFragment on TaskOccurrence {
  start
  end
  task {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export const JobRequestFragmentDoc = gql`
    fragment JobRequest on JobRequest {
  requestId
  success
  failure
  id
}
    `;
export const CameraNodeFragmentDoc = gql`
    fragment CameraNode on Camera {
  id
  name
  communicationStatus
  poweredOn
  road
  direction
  description
  location
  locationDescription
  autoFocusEnabled
  autoIrisEnabled
  userNotes
  referencePoint
  publicFeedEnabled
  privateStreamRecordingInProgress
  presets {
    id
    name
    presetIndex
    isHomePreset
    isSystem
    isSelected
  }
  ptzLock {
    isLocked
    requireExplicitCameraControlLocking
    userId
    fullName
    lockStartedAt
    lockEndsAt
    lastUsedTime
  }
  streams {
    name
    url
    accessType
    application
    streamName
    streamType
    streamManager
    recordingServer
  }
  capabilities {
    capability
    options
  }
  capabilitiesState
  communicationParameters
}
    `;
export const SignListFragmentDoc = gql`
    fragment SignList on Sign {
  currentMessage {
    ...SignQueuedMessage
  }
  communicationStatus
  lastDataReceived
  referencePoint
  displayType {
    ...SignDisplayType
  }
  lanePosition
  capabilities
  direction
  location
  laneType
  median
  road
  name
  id
  tests {
    ...SignTestsType
  }
}
    ${SignQueuedMessageFragmentDoc}
${SignDisplayTypeFragmentDoc}
${SignTestsTypeFragmentDoc}`;
export const DetectorFragmentDoc = gql`
    fragment Detector on Detector {
  communicationState
  referencePoint
  direction
  timeZone
  location
  road
  name
  id
  lanes {
    engineeringLane
    laneIndex
    occupancy
    direction
    status
    volume
    speed
    type
  }
}
    `;
export const FieldConfigRecursiveFragmentDoc = gql`
    fragment FieldConfigRecursive on FieldConfig {
  ...FieldConfigFields
  fieldConfigs {
    ...FieldConfigFields
    fieldConfigs {
      ...FieldConfigFields
      fieldConfigs {
        ...FieldConfigFields
      }
    }
  }
}
    ${FieldConfigFieldsFragmentDoc}`;
export const DataSourceFieldConfigFragmentDoc = gql`
    fragment DataSourceFieldConfig on FieldConfig {
  label
  type
  name
  id
  activeLogic
  choicesTitle
  chosenTitle
  defaultValue
  deselectAllLabel
  disableFuture
  disablePast
  displayFormat
  fieldSize
  fieldConfigs {
    ...FieldConfigRecursive
  }
  format
  help
  hint
  limitTags
  max
  maxLength
  min
  multiple
  sortOrder
  options {
    label
    value
  }
  required
  requiredLogic
  selectAllLabel
  step
  uniqueFields
  useCheckedIcon
  useIcon
  useStepper
  moduleName
  sourceId
  rows
  multiline
  mode
}
    ${FieldConfigRecursiveFragmentDoc}`;
export const DataSourceConfigFragmentDoc = gql`
    fragment DataSourceConfig on DataSourceConfig {
  fields {
    ...DataSourceFieldConfig
  }
  module
  name
  id
  system
}
    ${DataSourceFieldConfigFragmentDoc}`;
export const DataSourceFragmentDoc = gql`
    fragment DataSource on DataSource {
  config {
    ...DataSourceConfig
  }
  attributes
  enabled
  value
  id
}
    ${DataSourceConfigFragmentDoc}`;
export const KineticFormFieldFragmentFragmentDoc = gql`
    fragment KineticFormFieldFragment on KineticFormField {
  id
  name
  description
  label
  type
  fieldSize
  help
  hint
  options {
    label
    value
    type
  }
  disablePast
  disableFuture
  format
  displayFormat
  max
  min
  useStepper
  step
  source
  multiple
  sortOrder
  requiredLogic
  activeLogic
  moduleName
  loading
  uploadType
  accept
  required
  defaultValue
}
    `;
export const KineticFormSectionFragmentFragmentDoc = gql`
    fragment KineticFormSectionFragment on KineticFormSection {
  id
  name
  fields {
    ...KineticFormFieldFragment
  }
}
    ${KineticFormFieldFragmentFragmentDoc}`;
export const KineticFormViewFragmentFragmentDoc = gql`
    fragment KineticFormViewFragment on KineticFormView {
  id
  name
  sections {
    ...KineticFormSectionFragment
  }
}
    ${KineticFormSectionFragmentFragmentDoc}`;
export const KineticFormFragmentFragmentDoc = gql`
    fragment KineticFormFragment on KineticForm {
  views {
    ...KineticFormViewFragment
  }
  system
  canOverride
  modified
  extraConfig
  description
  module
  name
  id
}
    ${KineticFormViewFragmentFragmentDoc}`;
export const CorridorEventFragmentDoc = gql`
    fragment CorridorEvent on Event {
  lastUpdated
  attributes
  category
  created
  status
  lanes {
    engineeringLane
    laneStatus
    direction
    laneType
  }
  type
  name
  id
}
    `;
export const SignMessageOverrideFragmentDoc = gql`
    fragment SignMessageOverride on SignLibraryMessageOverride {
  message {
    ...SignMessage
  }
  hash
}
    ${SignMessageFragmentDoc}`;
export const SignLibraryMessageFragmentDoc = gql`
    fragment SignLibraryMessage on SignLibraryMessage {
  id
  name
  sign
  exclusions
  overrides {
    ...SignMessageOverride
  }
  message {
    ...SignMessage
  }
}
    ${SignMessageOverrideFragmentDoc}
${SignMessageFragmentDoc}`;
export const SignMessageHistoryFragmentDoc = gql`
    fragment SignMessageHistory on SignMessageHistory {
  associationDisplayNames
  timeBlanked
  blankedUser
  timePosted
  timeQueued
  postedUser
  associations
  message {
    ...SignMessage
  }
  id
  signId
}
    ${SignMessageFragmentDoc}`;
export const CommunicationLogFragmentDoc = gql`
    fragment CommunicationLog on CommunicationLog {
  id
  reason
  eventIds
  contact {
    id
    firstName
    lastName
    phoneNumber
    email
  }
  events {
    id
    name
  }
  detailsType
  impactedGroup
  reported
  discussion
  attributes
}
    `;
export const EventListFragmentDoc = gql`
    fragment EventList on Event {
  geometry
  lastUpdated
  attributes
  category
  created
  status
  type
  name
  id
  fiveOneOneMessage {
    suppressed
  }
}
    `;
export const SignDetailsFragmentDoc = gql`
    fragment SignDetails on Sign {
  locationDescription
  communicationStatus
  travelTimeSegments
  lastDataReceived
  brightnessLevels
  referencePoint
  brightnessMode
  capabilities
  powerSource
  brightness
  messagePreferences {
    ...SignMessagePreferences
  }
  direction
  displayType {
    ...SignDisplayType
  }
  fonts {
    number
    hash
  }
  lastReset
  location
  firmware
  volts
  road
  name
  id
  currentMessage {
    ...SignQueuedMessage
  }
  queue {
    ...SignQueuedMessage
  }
  tests {
    ...SignTestsType
  }
}
    ${SignMessagePreferencesFragmentDoc}
${SignDisplayTypeFragmentDoc}
${SignQueuedMessageFragmentDoc}
${SignTestsTypeFragmentDoc}`;
export const CursorPageInfoFragmentDoc = gql`
    fragment CursorPageInfo on PageInfo {
  hasPreviousPage
  hasNextPage
  startCursor
  endCursor
}
    `;
export const EventTypeShortFragmentDoc = gql`
    fragment EventTypeShort on EventType {
  name
  id
}
    `;
export const AcknowledgeNotificationDocument = gql`
    mutation AcknowledgeNotification($id: String!, $notes: String) {
  acknowledgeNotification(id: $id, notes: $notes) {
    id
    acknowledgedBy
    acknowledgedTime
  }
}
    `;
export type AcknowledgeNotificationMutationFn = Apollo.MutationFunction<AcknowledgeNotificationMutation, AcknowledgeNotificationMutationVariables>;

/**
 * __useAcknowledgeNotificationMutation__
 *
 * To run a mutation, you first call `useAcknowledgeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeNotificationMutation, { data, loading, error }] = useAcknowledgeNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAcknowledgeNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgeNotificationMutation, AcknowledgeNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgeNotificationMutation, AcknowledgeNotificationMutationVariables>(AcknowledgeNotificationDocument, options);
      }
export type AcknowledgeNotificationMutationHookResult = ReturnType<typeof useAcknowledgeNotificationMutation>;
export type AcknowledgeNotificationMutationResult = Apollo.MutationResult<AcknowledgeNotificationMutation>;
export type AcknowledgeNotificationMutationOptions = Apollo.BaseMutationOptions<AcknowledgeNotificationMutation, AcknowledgeNotificationMutationVariables>;
export const DismissNotificationDocument = gql`
    mutation DismissNotification($id: String!) {
  dismissNotification(id: $id)
}
    `;
export type DismissNotificationMutationFn = Apollo.MutationFunction<DismissNotificationMutation, DismissNotificationMutationVariables>;

/**
 * __useDismissNotificationMutation__
 *
 * To run a mutation, you first call `useDismissNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissNotificationMutation, { data, loading, error }] = useDismissNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DismissNotificationMutation, DismissNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissNotificationMutation, DismissNotificationMutationVariables>(DismissNotificationDocument, options);
      }
export type DismissNotificationMutationHookResult = ReturnType<typeof useDismissNotificationMutation>;
export type DismissNotificationMutationResult = Apollo.MutationResult<DismissNotificationMutation>;
export type DismissNotificationMutationOptions = Apollo.BaseMutationOptions<DismissNotificationMutation, DismissNotificationMutationVariables>;
export const DismissNotificationsDocument = gql`
    mutation DismissNotifications {
  dismissNotifications
}
    `;
export type DismissNotificationsMutationFn = Apollo.MutationFunction<DismissNotificationsMutation, DismissNotificationsMutationVariables>;

/**
 * __useDismissNotificationsMutation__
 *
 * To run a mutation, you first call `useDismissNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissNotificationsMutation, { data, loading, error }] = useDismissNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DismissNotificationsMutation, DismissNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissNotificationsMutation, DismissNotificationsMutationVariables>(DismissNotificationsDocument, options);
      }
export type DismissNotificationsMutationHookResult = ReturnType<typeof useDismissNotificationsMutation>;
export type DismissNotificationsMutationResult = Apollo.MutationResult<DismissNotificationsMutation>;
export type DismissNotificationsMutationOptions = Apollo.BaseMutationOptions<DismissNotificationsMutation, DismissNotificationsMutationVariables>;
export const UserNotificationsDocument = gql`
    query UserNotifications($acknowledged: Boolean, $limit: Int, $after: String) {
  notifications(
    query: {acknowledged: $acknowledged, limit: $limit, after: $after}
  ) {
    edges {
      cursor
      node {
        ...NotificationFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${NotificationFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      acknowledged: // value for 'acknowledged'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export function refetchUserNotificationsQuery(variables?: UserNotificationsQueryVariables) {
      return { query: UserNotificationsDocument, variables: variables }
    }
export const SystemNotificationsListDocument = gql`
    query SystemNotificationsList($sortOrder: SortOrder = ASCENDING, $notificationSortField: NotificationSortField, $limit: Int = 25, $after: String, $before: String, $searchText: String, $filters: [FilterInput]) {
  systemNotifications(
    query: {sortOrder: $sortOrder, limit: $limit, after: $after, before: $before, notificationSortField: $notificationSortField, searchText: $searchText, filters: $filters}
  ) {
    edges {
      node {
        ...NotificationFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${NotificationFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSystemNotificationsListQuery__
 *
 * To run a query within a React component, call `useSystemNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemNotificationsListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      notificationSortField: // value for 'notificationSortField'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      searchText: // value for 'searchText'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSystemNotificationsListQuery(baseOptions?: Apollo.QueryHookOptions<SystemNotificationsListQuery, SystemNotificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemNotificationsListQuery, SystemNotificationsListQueryVariables>(SystemNotificationsListDocument, options);
      }
export function useSystemNotificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemNotificationsListQuery, SystemNotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemNotificationsListQuery, SystemNotificationsListQueryVariables>(SystemNotificationsListDocument, options);
        }
export type SystemNotificationsListQueryHookResult = ReturnType<typeof useSystemNotificationsListQuery>;
export type SystemNotificationsListLazyQueryHookResult = ReturnType<typeof useSystemNotificationsListLazyQuery>;
export type SystemNotificationsListQueryResult = Apollo.QueryResult<SystemNotificationsListQuery, SystemNotificationsListQueryVariables>;
export function refetchSystemNotificationsListQuery(variables?: SystemNotificationsListQueryVariables) {
      return { query: SystemNotificationsListDocument, variables: variables }
    }
export const UserNotificationsListDocument = gql`
    query UserNotificationsList($sortOrder: SortOrder = ASCENDING, $limit: Int = 25, $acknowledged: Boolean, $after: String) {
  notifications(
    query: {sortOrder: $sortOrder, acknowledged: $acknowledged, limit: $limit, after: $after}
  ) {
    edges {
      cursor
      node {
        ...NotificationFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${NotificationFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useUserNotificationsListQuery__
 *
 * To run a query within a React component, call `useUserNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *      acknowledged: // value for 'acknowledged'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserNotificationsListQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsListQuery, UserNotificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsListQuery, UserNotificationsListQueryVariables>(UserNotificationsListDocument, options);
      }
export function useUserNotificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsListQuery, UserNotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsListQuery, UserNotificationsListQueryVariables>(UserNotificationsListDocument, options);
        }
export type UserNotificationsListQueryHookResult = ReturnType<typeof useUserNotificationsListQuery>;
export type UserNotificationsListLazyQueryHookResult = ReturnType<typeof useUserNotificationsListLazyQuery>;
export type UserNotificationsListQueryResult = Apollo.QueryResult<UserNotificationsListQuery, UserNotificationsListQueryVariables>;
export function refetchUserNotificationsListQuery(variables?: UserNotificationsListQueryVariables) {
      return { query: UserNotificationsListDocument, variables: variables }
    }
export const UserNotificationUpdatedDocument = gql`
    subscription UserNotificationUpdated {
  notificationUpdated {
    id
    acknowledgedTime
    urgentNotification
    acknowledgedBy
  }
}
    `;

/**
 * __useUserNotificationUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserNotificationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserNotificationUpdatedSubscription, UserNotificationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserNotificationUpdatedSubscription, UserNotificationUpdatedSubscriptionVariables>(UserNotificationUpdatedDocument, options);
      }
export type UserNotificationUpdatedSubscriptionHookResult = ReturnType<typeof useUserNotificationUpdatedSubscription>;
export type UserNotificationUpdatedSubscriptionResult = Apollo.SubscriptionResult<UserNotificationUpdatedSubscription>;
export const ClearAllPreferencesDocument = gql`
    mutation ClearAllPreferences {
  removeUserPreferences
}
    `;
export type ClearAllPreferencesMutationFn = Apollo.MutationFunction<ClearAllPreferencesMutation, ClearAllPreferencesMutationVariables>;

/**
 * __useClearAllPreferencesMutation__
 *
 * To run a mutation, you first call `useClearAllPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAllPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAllPreferencesMutation, { data, loading, error }] = useClearAllPreferencesMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearAllPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<ClearAllPreferencesMutation, ClearAllPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearAllPreferencesMutation, ClearAllPreferencesMutationVariables>(ClearAllPreferencesDocument, options);
      }
export type ClearAllPreferencesMutationHookResult = ReturnType<typeof useClearAllPreferencesMutation>;
export type ClearAllPreferencesMutationResult = Apollo.MutationResult<ClearAllPreferencesMutation>;
export type ClearAllPreferencesMutationOptions = Apollo.BaseMutationOptions<ClearAllPreferencesMutation, ClearAllPreferencesMutationVariables>;
export const SaveKineticUserPrefDocument = gql`
    mutation SaveKineticUserPref($key: ID!, $value: JSON!) {
  saveKineticUserPreference(key: $key, value: $value)
}
    `;
export type SaveKineticUserPrefMutationFn = Apollo.MutationFunction<SaveKineticUserPrefMutation, SaveKineticUserPrefMutationVariables>;

/**
 * __useSaveKineticUserPrefMutation__
 *
 * To run a mutation, you first call `useSaveKineticUserPrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveKineticUserPrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveKineticUserPrefMutation, { data, loading, error }] = useSaveKineticUserPrefMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveKineticUserPrefMutation(baseOptions?: Apollo.MutationHookOptions<SaveKineticUserPrefMutation, SaveKineticUserPrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveKineticUserPrefMutation, SaveKineticUserPrefMutationVariables>(SaveKineticUserPrefDocument, options);
      }
export type SaveKineticUserPrefMutationHookResult = ReturnType<typeof useSaveKineticUserPrefMutation>;
export type SaveKineticUserPrefMutationResult = Apollo.MutationResult<SaveKineticUserPrefMutation>;
export type SaveKineticUserPrefMutationOptions = Apollo.BaseMutationOptions<SaveKineticUserPrefMutation, SaveKineticUserPrefMutationVariables>;
export const SaveModuleMapSettingsDocument = gql`
    mutation SaveModuleMapSettings($settings: JSON!) {
  saveModuleMapSettings(settings: $settings)
}
    `;
export type SaveModuleMapSettingsMutationFn = Apollo.MutationFunction<SaveModuleMapSettingsMutation, SaveModuleMapSettingsMutationVariables>;

/**
 * __useSaveModuleMapSettingsMutation__
 *
 * To run a mutation, you first call `useSaveModuleMapSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveModuleMapSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveModuleMapSettingsMutation, { data, loading, error }] = useSaveModuleMapSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveModuleMapSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveModuleMapSettingsMutation, SaveModuleMapSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveModuleMapSettingsMutation, SaveModuleMapSettingsMutationVariables>(SaveModuleMapSettingsDocument, options);
      }
export type SaveModuleMapSettingsMutationHookResult = ReturnType<typeof useSaveModuleMapSettingsMutation>;
export type SaveModuleMapSettingsMutationResult = Apollo.MutationResult<SaveModuleMapSettingsMutation>;
export type SaveModuleMapSettingsMutationOptions = Apollo.BaseMutationOptions<SaveModuleMapSettingsMutation, SaveModuleMapSettingsMutationVariables>;
export const UserTenantPreferenceUpdatedDocument = gql`
    subscription UserTenantPreferenceUpdated {
  userPreferenceUpdated {
    streamObjectOperation
    userPreference {
      key
      value
    }
  }
}
    `;

/**
 * __useUserTenantPreferenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserTenantPreferenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserTenantPreferenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTenantPreferenceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserTenantPreferenceUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserTenantPreferenceUpdatedSubscription, UserTenantPreferenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserTenantPreferenceUpdatedSubscription, UserTenantPreferenceUpdatedSubscriptionVariables>(UserTenantPreferenceUpdatedDocument, options);
      }
export type UserTenantPreferenceUpdatedSubscriptionHookResult = ReturnType<typeof useUserTenantPreferenceUpdatedSubscription>;
export type UserTenantPreferenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<UserTenantPreferenceUpdatedSubscription>;
export const UserKineticPreferenceUpdatedDocument = gql`
    subscription UserKineticPreferenceUpdated {
  kineticUserPreferenceUpdated {
    streamObjectOperation
    userPreference {
      key
      value
    }
  }
}
    `;

/**
 * __useUserKineticPreferenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserKineticPreferenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserKineticPreferenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserKineticPreferenceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserKineticPreferenceUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserKineticPreferenceUpdatedSubscription, UserKineticPreferenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserKineticPreferenceUpdatedSubscription, UserKineticPreferenceUpdatedSubscriptionVariables>(UserKineticPreferenceUpdatedDocument, options);
      }
export type UserKineticPreferenceUpdatedSubscriptionHookResult = ReturnType<typeof useUserKineticPreferenceUpdatedSubscription>;
export type UserKineticPreferenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<UserKineticPreferenceUpdatedSubscription>;
export const ModuleMapSettingsDocument = gql`
    query ModuleMapSettings($formViewName: String!) {
  moduleMapSettings(formViewName: $formViewName) {
    id
    name
    views {
      id
      name
      views {
        ...FormViewFragment
        views {
          ...FormViewFragment
          views {
            ...FormViewFragment
          }
        }
      }
    }
    sections {
      ...ModuleFormSectionFragment
    }
    type
  }
}
    ${FormViewFragmentFragmentDoc}
${ModuleFormSectionFragmentFragmentDoc}`;

/**
 * __useModuleMapSettingsQuery__
 *
 * To run a query within a React component, call `useModuleMapSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleMapSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleMapSettingsQuery({
 *   variables: {
 *      formViewName: // value for 'formViewName'
 *   },
 * });
 */
export function useModuleMapSettingsQuery(baseOptions: Apollo.QueryHookOptions<ModuleMapSettingsQuery, ModuleMapSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleMapSettingsQuery, ModuleMapSettingsQueryVariables>(ModuleMapSettingsDocument, options);
      }
export function useModuleMapSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleMapSettingsQuery, ModuleMapSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleMapSettingsQuery, ModuleMapSettingsQueryVariables>(ModuleMapSettingsDocument, options);
        }
export type ModuleMapSettingsQueryHookResult = ReturnType<typeof useModuleMapSettingsQuery>;
export type ModuleMapSettingsLazyQueryHookResult = ReturnType<typeof useModuleMapSettingsLazyQuery>;
export type ModuleMapSettingsQueryResult = Apollo.QueryResult<ModuleMapSettingsQuery, ModuleMapSettingsQueryVariables>;
export function refetchModuleMapSettingsQuery(variables: ModuleMapSettingsQueryVariables) {
      return { query: ModuleMapSettingsDocument, variables: variables }
    }
export const SaveAtlasArchiveModuleSettingsDocument = gql`
    mutation SaveAtlasArchiveModuleSettings($settings: JSON!) {
  saveAtlasArchiveModuleSettings(settings: $settings)
}
    `;
export type SaveAtlasArchiveModuleSettingsMutationFn = Apollo.MutationFunction<SaveAtlasArchiveModuleSettingsMutation, SaveAtlasArchiveModuleSettingsMutationVariables>;

/**
 * __useSaveAtlasArchiveModuleSettingsMutation__
 *
 * To run a mutation, you first call `useSaveAtlasArchiveModuleSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAtlasArchiveModuleSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAtlasArchiveModuleSettingsMutation, { data, loading, error }] = useSaveAtlasArchiveModuleSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveAtlasArchiveModuleSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveAtlasArchiveModuleSettingsMutation, SaveAtlasArchiveModuleSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAtlasArchiveModuleSettingsMutation, SaveAtlasArchiveModuleSettingsMutationVariables>(SaveAtlasArchiveModuleSettingsDocument, options);
      }
export type SaveAtlasArchiveModuleSettingsMutationHookResult = ReturnType<typeof useSaveAtlasArchiveModuleSettingsMutation>;
export type SaveAtlasArchiveModuleSettingsMutationResult = Apollo.MutationResult<SaveAtlasArchiveModuleSettingsMutation>;
export type SaveAtlasArchiveModuleSettingsMutationOptions = Apollo.BaseMutationOptions<SaveAtlasArchiveModuleSettingsMutation, SaveAtlasArchiveModuleSettingsMutationVariables>;
export const AtlasArchiveModuleSettingsDocument = gql`
    query AtlasArchiveModuleSettings($formViewName: String!) {
  atlasArchiveModuleSettings(formViewName: $formViewName) {
    id
    name
    views {
      id
      name
      views {
        ...FormViewFragment
        views {
          ...FormViewFragment
          views {
            ...FormViewFragment
          }
        }
      }
    }
    sections {
      ...ModuleFormSectionFragment
    }
    type
  }
}
    ${FormViewFragmentFragmentDoc}
${ModuleFormSectionFragmentFragmentDoc}`;

/**
 * __useAtlasArchiveModuleSettingsQuery__
 *
 * To run a query within a React component, call `useAtlasArchiveModuleSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtlasArchiveModuleSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtlasArchiveModuleSettingsQuery({
 *   variables: {
 *      formViewName: // value for 'formViewName'
 *   },
 * });
 */
export function useAtlasArchiveModuleSettingsQuery(baseOptions: Apollo.QueryHookOptions<AtlasArchiveModuleSettingsQuery, AtlasArchiveModuleSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AtlasArchiveModuleSettingsQuery, AtlasArchiveModuleSettingsQueryVariables>(AtlasArchiveModuleSettingsDocument, options);
      }
export function useAtlasArchiveModuleSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AtlasArchiveModuleSettingsQuery, AtlasArchiveModuleSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AtlasArchiveModuleSettingsQuery, AtlasArchiveModuleSettingsQueryVariables>(AtlasArchiveModuleSettingsDocument, options);
        }
export type AtlasArchiveModuleSettingsQueryHookResult = ReturnType<typeof useAtlasArchiveModuleSettingsQuery>;
export type AtlasArchiveModuleSettingsLazyQueryHookResult = ReturnType<typeof useAtlasArchiveModuleSettingsLazyQuery>;
export type AtlasArchiveModuleSettingsQueryResult = Apollo.QueryResult<AtlasArchiveModuleSettingsQuery, AtlasArchiveModuleSettingsQueryVariables>;
export function refetchAtlasArchiveModuleSettingsQuery(variables: AtlasArchiveModuleSettingsQueryVariables) {
      return { query: AtlasArchiveModuleSettingsDocument, variables: variables }
    }
export const SaveEmailServerSettingsDocument = gql`
    mutation saveEmailServerSettings($settings: EmailServerSettingsInput!) {
  saveEmailServerSettings(settings: $settings) {
    host
    port
    auth
    startTls
    requireStartTls
    sslTrust
    username
    password
    ignoreTls
    from
    sslEnabled
  }
}
    `;
export type SaveEmailServerSettingsMutationFn = Apollo.MutationFunction<SaveEmailServerSettingsMutation, SaveEmailServerSettingsMutationVariables>;

/**
 * __useSaveEmailServerSettingsMutation__
 *
 * To run a mutation, you first call `useSaveEmailServerSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmailServerSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmailServerSettingsMutation, { data, loading, error }] = useSaveEmailServerSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveEmailServerSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmailServerSettingsMutation, SaveEmailServerSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmailServerSettingsMutation, SaveEmailServerSettingsMutationVariables>(SaveEmailServerSettingsDocument, options);
      }
export type SaveEmailServerSettingsMutationHookResult = ReturnType<typeof useSaveEmailServerSettingsMutation>;
export type SaveEmailServerSettingsMutationResult = Apollo.MutationResult<SaveEmailServerSettingsMutation>;
export type SaveEmailServerSettingsMutationOptions = Apollo.BaseMutationOptions<SaveEmailServerSettingsMutation, SaveEmailServerSettingsMutationVariables>;
export const DeleteKineticFormsDocument = gql`
    mutation DeleteKineticForms($ids: [ID!]!) {
  deleteKineticForms(ids: $ids)
}
    `;
export type DeleteKineticFormsMutationFn = Apollo.MutationFunction<DeleteKineticFormsMutation, DeleteKineticFormsMutationVariables>;

/**
 * __useDeleteKineticFormsMutation__
 *
 * To run a mutation, you first call `useDeleteKineticFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKineticFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKineticFormsMutation, { data, loading, error }] = useDeleteKineticFormsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteKineticFormsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKineticFormsMutation, DeleteKineticFormsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKineticFormsMutation, DeleteKineticFormsMutationVariables>(DeleteKineticFormsDocument, options);
      }
export type DeleteKineticFormsMutationHookResult = ReturnType<typeof useDeleteKineticFormsMutation>;
export type DeleteKineticFormsMutationResult = Apollo.MutationResult<DeleteKineticFormsMutation>;
export type DeleteKineticFormsMutationOptions = Apollo.BaseMutationOptions<DeleteKineticFormsMutation, DeleteKineticFormsMutationVariables>;
export const SaveKineticFormDocument = gql`
    mutation SaveKineticForm($form: KineticFormInput!) {
  saveKineticForm(form: $form) {
    ...KineticForm
  }
}
    ${KineticFormFragmentDoc}`;
export type SaveKineticFormMutationFn = Apollo.MutationFunction<SaveKineticFormMutation, SaveKineticFormMutationVariables>;

/**
 * __useSaveKineticFormMutation__
 *
 * To run a mutation, you first call `useSaveKineticFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveKineticFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveKineticFormMutation, { data, loading, error }] = useSaveKineticFormMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useSaveKineticFormMutation(baseOptions?: Apollo.MutationHookOptions<SaveKineticFormMutation, SaveKineticFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveKineticFormMutation, SaveKineticFormMutationVariables>(SaveKineticFormDocument, options);
      }
export type SaveKineticFormMutationHookResult = ReturnType<typeof useSaveKineticFormMutation>;
export type SaveKineticFormMutationResult = Apollo.MutationResult<SaveKineticFormMutation>;
export type SaveKineticFormMutationOptions = Apollo.BaseMutationOptions<SaveKineticFormMutation, SaveKineticFormMutationVariables>;
export const CreateKeycloakClientDocument = gql`
    mutation CreateKeycloakClient {
  createKeycloakClient {
    id
    clientId
    name
    secret
  }
}
    `;
export type CreateKeycloakClientMutationFn = Apollo.MutationFunction<CreateKeycloakClientMutation, CreateKeycloakClientMutationVariables>;

/**
 * __useCreateKeycloakClientMutation__
 *
 * To run a mutation, you first call `useCreateKeycloakClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeycloakClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeycloakClientMutation, { data, loading, error }] = useCreateKeycloakClientMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateKeycloakClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeycloakClientMutation, CreateKeycloakClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeycloakClientMutation, CreateKeycloakClientMutationVariables>(CreateKeycloakClientDocument, options);
      }
export type CreateKeycloakClientMutationHookResult = ReturnType<typeof useCreateKeycloakClientMutation>;
export type CreateKeycloakClientMutationResult = Apollo.MutationResult<CreateKeycloakClientMutation>;
export type CreateKeycloakClientMutationOptions = Apollo.BaseMutationOptions<CreateKeycloakClientMutation, CreateKeycloakClientMutationVariables>;
export const RegenerateClientSecretDocument = gql`
    mutation RegenerateClientSecret {
  regenerateClientSecret
}
    `;
export type RegenerateClientSecretMutationFn = Apollo.MutationFunction<RegenerateClientSecretMutation, RegenerateClientSecretMutationVariables>;

/**
 * __useRegenerateClientSecretMutation__
 *
 * To run a mutation, you first call `useRegenerateClientSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateClientSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateClientSecretMutation, { data, loading, error }] = useRegenerateClientSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerateClientSecretMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateClientSecretMutation, RegenerateClientSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateClientSecretMutation, RegenerateClientSecretMutationVariables>(RegenerateClientSecretDocument, options);
      }
export type RegenerateClientSecretMutationHookResult = ReturnType<typeof useRegenerateClientSecretMutation>;
export type RegenerateClientSecretMutationResult = Apollo.MutationResult<RegenerateClientSecretMutation>;
export type RegenerateClientSecretMutationOptions = Apollo.BaseMutationOptions<RegenerateClientSecretMutation, RegenerateClientSecretMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const DeleteUserGroupDocument = gql`
    mutation DeleteUserGroup($id: ID!) {
  deleteUserGroup(id: $id)
}
    `;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DeleteUserGroupDocument, options);
      }
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;
export const SaveRoleDocument = gql`
    mutation SaveRole($role: KineticRoleInput!) {
  saveRole(role: $role) {
    id
    name
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;
export type SaveRoleMutationFn = Apollo.MutationFunction<SaveRoleMutation, SaveRoleMutationVariables>;

/**
 * __useSaveRoleMutation__
 *
 * To run a mutation, you first call `useSaveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRoleMutation, { data, loading, error }] = useSaveRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSaveRoleMutation(baseOptions?: Apollo.MutationHookOptions<SaveRoleMutation, SaveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRoleMutation, SaveRoleMutationVariables>(SaveRoleDocument, options);
      }
export type SaveRoleMutationHookResult = ReturnType<typeof useSaveRoleMutation>;
export type SaveRoleMutationResult = Apollo.MutationResult<SaveRoleMutation>;
export type SaveRoleMutationOptions = Apollo.BaseMutationOptions<SaveRoleMutation, SaveRoleMutationVariables>;
export const SaveUserGroupDocument = gql`
    mutation SaveUserGroup($userGroup: KineticUserGroupInput!) {
  saveUserGroup(userGroup: $userGroup) {
    id
    name
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    roles {
      id
      name
      privilegeAssignmentList {
        ...PrivilegeAssignmentFragment
      }
    }
    users {
      id
      username
      firstName
      lastName
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;
export type SaveUserGroupMutationFn = Apollo.MutationFunction<SaveUserGroupMutation, SaveUserGroupMutationVariables>;

/**
 * __useSaveUserGroupMutation__
 *
 * To run a mutation, you first call `useSaveUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserGroupMutation, { data, loading, error }] = useSaveUserGroupMutation({
 *   variables: {
 *      userGroup: // value for 'userGroup'
 *   },
 * });
 */
export function useSaveUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserGroupMutation, SaveUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserGroupMutation, SaveUserGroupMutationVariables>(SaveUserGroupDocument, options);
      }
export type SaveUserGroupMutationHookResult = ReturnType<typeof useSaveUserGroupMutation>;
export type SaveUserGroupMutationResult = Apollo.MutationResult<SaveUserGroupMutation>;
export type SaveUserGroupMutationOptions = Apollo.BaseMutationOptions<SaveUserGroupMutation, SaveUserGroupMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: OrganizationKineticUserInput!) {
  createUser(user: $user) {
    id
    username
    firstName
    lastName
    email
    phoneNumbers
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    roleIds
    userGroupIds
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserPrivilegesDocument = gql`
    mutation UpdateUserPrivileges($user: KineticUserPrivilegeAssignmentInput!) {
  updateUserPrivileges(user: $user) {
    id
    roles {
      id
      name
    }
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;
export type UpdateUserPrivilegesMutationFn = Apollo.MutationFunction<UpdateUserPrivilegesMutation, UpdateUserPrivilegesMutationVariables>;

/**
 * __useUpdateUserPrivilegesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPrivilegesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPrivilegesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPrivilegesMutation, { data, loading, error }] = useUpdateUserPrivilegesMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserPrivilegesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPrivilegesMutation, UpdateUserPrivilegesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPrivilegesMutation, UpdateUserPrivilegesMutationVariables>(UpdateUserPrivilegesDocument, options);
      }
export type UpdateUserPrivilegesMutationHookResult = ReturnType<typeof useUpdateUserPrivilegesMutation>;
export type UpdateUserPrivilegesMutationResult = Apollo.MutationResult<UpdateUserPrivilegesMutation>;
export type UpdateUserPrivilegesMutationOptions = Apollo.BaseMutationOptions<UpdateUserPrivilegesMutation, UpdateUserPrivilegesMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($id: ID!, $newPassword: String!) {
  resetPassword(id: $id, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: ID!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const SaveMapConfigDocument = gql`
    mutation SaveMapConfig($config: MapConfigInput!) {
  saveMapConfig(config: $config) {
    ...MapConfigFragment
  }
}
    ${MapConfigFragmentFragmentDoc}`;
export type SaveMapConfigMutationFn = Apollo.MutationFunction<SaveMapConfigMutation, SaveMapConfigMutationVariables>;

/**
 * __useSaveMapConfigMutation__
 *
 * To run a mutation, you first call `useSaveMapConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMapConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMapConfigMutation, { data, loading, error }] = useSaveMapConfigMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useSaveMapConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveMapConfigMutation, SaveMapConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMapConfigMutation, SaveMapConfigMutationVariables>(SaveMapConfigDocument, options);
      }
export type SaveMapConfigMutationHookResult = ReturnType<typeof useSaveMapConfigMutation>;
export type SaveMapConfigMutationResult = Apollo.MutationResult<SaveMapConfigMutation>;
export type SaveMapConfigMutationOptions = Apollo.BaseMutationOptions<SaveMapConfigMutation, SaveMapConfigMutationVariables>;
export const SaveMapLayerConfigDocument = gql`
    mutation SaveMapLayerConfig($config: MapLayerConfigInput!) {
  saveMapLayerConfig(config: $config) {
    ...MapLayerConfigFragment
  }
}
    ${MapLayerConfigFragmentFragmentDoc}`;
export type SaveMapLayerConfigMutationFn = Apollo.MutationFunction<SaveMapLayerConfigMutation, SaveMapLayerConfigMutationVariables>;

/**
 * __useSaveMapLayerConfigMutation__
 *
 * To run a mutation, you first call `useSaveMapLayerConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMapLayerConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMapLayerConfigMutation, { data, loading, error }] = useSaveMapLayerConfigMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useSaveMapLayerConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveMapLayerConfigMutation, SaveMapLayerConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMapLayerConfigMutation, SaveMapLayerConfigMutationVariables>(SaveMapLayerConfigDocument, options);
      }
export type SaveMapLayerConfigMutationHookResult = ReturnType<typeof useSaveMapLayerConfigMutation>;
export type SaveMapLayerConfigMutationResult = Apollo.MutationResult<SaveMapLayerConfigMutation>;
export type SaveMapLayerConfigMutationOptions = Apollo.BaseMutationOptions<SaveMapLayerConfigMutation, SaveMapLayerConfigMutationVariables>;
export const DeleteMapLayerConfigDocument = gql`
    mutation DeleteMapLayerConfig($id: ID!) {
  deleteMapLayerConfig(id: $id)
}
    `;
export type DeleteMapLayerConfigMutationFn = Apollo.MutationFunction<DeleteMapLayerConfigMutation, DeleteMapLayerConfigMutationVariables>;

/**
 * __useDeleteMapLayerConfigMutation__
 *
 * To run a mutation, you first call `useDeleteMapLayerConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapLayerConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapLayerConfigMutation, { data, loading, error }] = useDeleteMapLayerConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMapLayerConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMapLayerConfigMutation, DeleteMapLayerConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMapLayerConfigMutation, DeleteMapLayerConfigMutationVariables>(DeleteMapLayerConfigDocument, options);
      }
export type DeleteMapLayerConfigMutationHookResult = ReturnType<typeof useDeleteMapLayerConfigMutation>;
export type DeleteMapLayerConfigMutationResult = Apollo.MutationResult<DeleteMapLayerConfigMutation>;
export type DeleteMapLayerConfigMutationOptions = Apollo.BaseMutationOptions<DeleteMapLayerConfigMutation, DeleteMapLayerConfigMutationVariables>;
export const DeleteMapConfigDocument = gql`
    mutation DeleteMapConfig($id: ID!) {
  deleteMapConfig(id: $id)
}
    `;
export type DeleteMapConfigMutationFn = Apollo.MutationFunction<DeleteMapConfigMutation, DeleteMapConfigMutationVariables>;

/**
 * __useDeleteMapConfigMutation__
 *
 * To run a mutation, you first call `useDeleteMapConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapConfigMutation, { data, loading, error }] = useDeleteMapConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMapConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMapConfigMutation, DeleteMapConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMapConfigMutation, DeleteMapConfigMutationVariables>(DeleteMapConfigDocument, options);
      }
export type DeleteMapConfigMutationHookResult = ReturnType<typeof useDeleteMapConfigMutation>;
export type DeleteMapConfigMutationResult = Apollo.MutationResult<DeleteMapConfigMutation>;
export type DeleteMapConfigMutationOptions = Apollo.BaseMutationOptions<DeleteMapConfigMutation, DeleteMapConfigMutationVariables>;
export const SyncDocument = gql`
    mutation sync {
  syncLicense
}
    `;
export type SyncMutationFn = Apollo.MutationFunction<SyncMutation, SyncMutationVariables>;

/**
 * __useSyncMutation__
 *
 * To run a mutation, you first call `useSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMutation, { data, loading, error }] = useSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncMutation(baseOptions?: Apollo.MutationHookOptions<SyncMutation, SyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncMutation, SyncMutationVariables>(SyncDocument, options);
      }
export type SyncMutationHookResult = ReturnType<typeof useSyncMutation>;
export type SyncMutationResult = Apollo.MutationResult<SyncMutation>;
export type SyncMutationOptions = Apollo.BaseMutationOptions<SyncMutation, SyncMutationVariables>;
export const SaveLicenseDocument = gql`
    mutation saveLicense($key: String!) {
  saveLicense(key: $key)
}
    `;
export type SaveLicenseMutationFn = Apollo.MutationFunction<SaveLicenseMutation, SaveLicenseMutationVariables>;

/**
 * __useSaveLicenseMutation__
 *
 * To run a mutation, you first call `useSaveLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLicenseMutation, { data, loading, error }] = useSaveLicenseMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSaveLicenseMutation(baseOptions?: Apollo.MutationHookOptions<SaveLicenseMutation, SaveLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLicenseMutation, SaveLicenseMutationVariables>(SaveLicenseDocument, options);
      }
export type SaveLicenseMutationHookResult = ReturnType<typeof useSaveLicenseMutation>;
export type SaveLicenseMutationResult = Apollo.MutationResult<SaveLicenseMutation>;
export type SaveLicenseMutationOptions = Apollo.BaseMutationOptions<SaveLicenseMutation, SaveLicenseMutationVariables>;
export const SaveKineticFormFieldDocument = gql`
    mutation saveKineticFormField($field: KineticFormFieldInput!) {
  saveKineticFormField(field: $field) {
    ...KineticFormField
  }
}
    ${KineticFormFieldFragmentDoc}`;
export type SaveKineticFormFieldMutationFn = Apollo.MutationFunction<SaveKineticFormFieldMutation, SaveKineticFormFieldMutationVariables>;

/**
 * __useSaveKineticFormFieldMutation__
 *
 * To run a mutation, you first call `useSaveKineticFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveKineticFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveKineticFormFieldMutation, { data, loading, error }] = useSaveKineticFormFieldMutation({
 *   variables: {
 *      field: // value for 'field'
 *   },
 * });
 */
export function useSaveKineticFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<SaveKineticFormFieldMutation, SaveKineticFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveKineticFormFieldMutation, SaveKineticFormFieldMutationVariables>(SaveKineticFormFieldDocument, options);
      }
export type SaveKineticFormFieldMutationHookResult = ReturnType<typeof useSaveKineticFormFieldMutation>;
export type SaveKineticFormFieldMutationResult = Apollo.MutationResult<SaveKineticFormFieldMutation>;
export type SaveKineticFormFieldMutationOptions = Apollo.BaseMutationOptions<SaveKineticFormFieldMutation, SaveKineticFormFieldMutationVariables>;
export const DeleteKineticFormFieldsDocument = gql`
    mutation DeleteKineticFormFields($selection: [ID!]!) {
  deleteKineticFormFields(ids: $selection)
}
    `;
export type DeleteKineticFormFieldsMutationFn = Apollo.MutationFunction<DeleteKineticFormFieldsMutation, DeleteKineticFormFieldsMutationVariables>;

/**
 * __useDeleteKineticFormFieldsMutation__
 *
 * To run a mutation, you first call `useDeleteKineticFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKineticFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKineticFormFieldsMutation, { data, loading, error }] = useDeleteKineticFormFieldsMutation({
 *   variables: {
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useDeleteKineticFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKineticFormFieldsMutation, DeleteKineticFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKineticFormFieldsMutation, DeleteKineticFormFieldsMutationVariables>(DeleteKineticFormFieldsDocument, options);
      }
export type DeleteKineticFormFieldsMutationHookResult = ReturnType<typeof useDeleteKineticFormFieldsMutation>;
export type DeleteKineticFormFieldsMutationResult = Apollo.MutationResult<DeleteKineticFormFieldsMutation>;
export type DeleteKineticFormFieldsMutationOptions = Apollo.BaseMutationOptions<DeleteKineticFormFieldsMutation, DeleteKineticFormFieldsMutationVariables>;
export const SaveCentralAuthPinSettingsDocument = gql`
    mutation saveCentralAuthPinSettings($pin: String!) {
  saveCentralAuthPinSettings(pin: $pin) {
    id
  }
}
    `;
export type SaveCentralAuthPinSettingsMutationFn = Apollo.MutationFunction<SaveCentralAuthPinSettingsMutation, SaveCentralAuthPinSettingsMutationVariables>;

/**
 * __useSaveCentralAuthPinSettingsMutation__
 *
 * To run a mutation, you first call `useSaveCentralAuthPinSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCentralAuthPinSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCentralAuthPinSettingsMutation, { data, loading, error }] = useSaveCentralAuthPinSettingsMutation({
 *   variables: {
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useSaveCentralAuthPinSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveCentralAuthPinSettingsMutation, SaveCentralAuthPinSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCentralAuthPinSettingsMutation, SaveCentralAuthPinSettingsMutationVariables>(SaveCentralAuthPinSettingsDocument, options);
      }
export type SaveCentralAuthPinSettingsMutationHookResult = ReturnType<typeof useSaveCentralAuthPinSettingsMutation>;
export type SaveCentralAuthPinSettingsMutationResult = Apollo.MutationResult<SaveCentralAuthPinSettingsMutation>;
export type SaveCentralAuthPinSettingsMutationOptions = Apollo.BaseMutationOptions<SaveCentralAuthPinSettingsMutation, SaveCentralAuthPinSettingsMutationVariables>;
export const KeycloakClientDocument = gql`
    query KeycloakClient {
  keycloakClient {
    id
    clientId
    name
    secret
  }
}
    `;

/**
 * __useKeycloakClientQuery__
 *
 * To run a query within a React component, call `useKeycloakClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeycloakClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeycloakClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useKeycloakClientQuery(baseOptions?: Apollo.QueryHookOptions<KeycloakClientQuery, KeycloakClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeycloakClientQuery, KeycloakClientQueryVariables>(KeycloakClientDocument, options);
      }
export function useKeycloakClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeycloakClientQuery, KeycloakClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeycloakClientQuery, KeycloakClientQueryVariables>(KeycloakClientDocument, options);
        }
export type KeycloakClientQueryHookResult = ReturnType<typeof useKeycloakClientQuery>;
export type KeycloakClientLazyQueryHookResult = ReturnType<typeof useKeycloakClientLazyQuery>;
export type KeycloakClientQueryResult = Apollo.QueryResult<KeycloakClientQuery, KeycloakClientQueryVariables>;
export function refetchKeycloakClientQuery(variables?: KeycloakClientQueryVariables) {
      return { query: KeycloakClientDocument, variables: variables }
    }
export const EmailServerSettingsDocument = gql`
    query EmailServerSettings {
  emailServerSettings {
    host
    port
    auth
    startTls
    requireStartTls
    sslTrust
    username
    password
    ignoreTls
    from
    sslEnabled
  }
}
    `;

/**
 * __useEmailServerSettingsQuery__
 *
 * To run a query within a React component, call `useEmailServerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailServerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailServerSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailServerSettingsQuery(baseOptions?: Apollo.QueryHookOptions<EmailServerSettingsQuery, EmailServerSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailServerSettingsQuery, EmailServerSettingsQueryVariables>(EmailServerSettingsDocument, options);
      }
export function useEmailServerSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailServerSettingsQuery, EmailServerSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailServerSettingsQuery, EmailServerSettingsQueryVariables>(EmailServerSettingsDocument, options);
        }
export type EmailServerSettingsQueryHookResult = ReturnType<typeof useEmailServerSettingsQuery>;
export type EmailServerSettingsLazyQueryHookResult = ReturnType<typeof useEmailServerSettingsLazyQuery>;
export type EmailServerSettingsQueryResult = Apollo.QueryResult<EmailServerSettingsQuery, EmailServerSettingsQueryVariables>;
export function refetchEmailServerSettingsQuery(variables?: EmailServerSettingsQueryVariables) {
      return { query: EmailServerSettingsDocument, variables: variables }
    }
export const RecentRemoteAgentsDocument = gql`
    query RecentRemoteAgents {
  recentRemoteAgents {
    instanceId
    processId
    systemName
    remoteIpAddress
    clientId
    version
    internalIpAddress
    lastHeartbeat
    labels
  }
}
    `;

/**
 * __useRecentRemoteAgentsQuery__
 *
 * To run a query within a React component, call `useRecentRemoteAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentRemoteAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentRemoteAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentRemoteAgentsQuery(baseOptions?: Apollo.QueryHookOptions<RecentRemoteAgentsQuery, RecentRemoteAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentRemoteAgentsQuery, RecentRemoteAgentsQueryVariables>(RecentRemoteAgentsDocument, options);
      }
export function useRecentRemoteAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentRemoteAgentsQuery, RecentRemoteAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentRemoteAgentsQuery, RecentRemoteAgentsQueryVariables>(RecentRemoteAgentsDocument, options);
        }
export type RecentRemoteAgentsQueryHookResult = ReturnType<typeof useRecentRemoteAgentsQuery>;
export type RecentRemoteAgentsLazyQueryHookResult = ReturnType<typeof useRecentRemoteAgentsLazyQuery>;
export type RecentRemoteAgentsQueryResult = Apollo.QueryResult<RecentRemoteAgentsQuery, RecentRemoteAgentsQueryVariables>;
export function refetchRecentRemoteAgentsQuery(variables?: RecentRemoteAgentsQueryVariables) {
      return { query: RecentRemoteAgentsDocument, variables: variables }
    }
export const GetOrgsDocument = gql`
    query GetOrgs {
  organizations {
    id
    name
    address
  }
}
    `;

/**
 * __useGetOrgsQuery__
 *
 * To run a query within a React component, call `useGetOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgsQuery, GetOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgsQuery, GetOrgsQueryVariables>(GetOrgsDocument, options);
      }
export function useGetOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgsQuery, GetOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgsQuery, GetOrgsQueryVariables>(GetOrgsDocument, options);
        }
export type GetOrgsQueryHookResult = ReturnType<typeof useGetOrgsQuery>;
export type GetOrgsLazyQueryHookResult = ReturnType<typeof useGetOrgsLazyQuery>;
export type GetOrgsQueryResult = Apollo.QueryResult<GetOrgsQuery, GetOrgsQueryVariables>;
export function refetchGetOrgsQuery(variables?: GetOrgsQueryVariables) {
      return { query: GetOrgsDocument, variables: variables }
    }
export const GetRolesDocument = gql`
    query getRoles {
  roles {
    id
    name
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export function refetchGetRolesQuery(variables?: GetRolesQueryVariables) {
      return { query: GetRolesDocument, variables: variables }
    }
export const GetRoleDocument = gql`
    query getRole($id: ID!) {
  role(id: $id) {
    id
    name
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export function refetchGetRoleQuery(variables: GetRoleQueryVariables) {
      return { query: GetRoleDocument, variables: variables }
    }
export const RolesUpdatedDocument = gql`
    subscription RolesUpdated($ids: [ID!]!) {
  rolesUpdated(ids: $ids) {
    id
    name
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;

/**
 * __useRolesUpdatedSubscription__
 *
 * To run a query within a React component, call `useRolesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRolesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRolesUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<RolesUpdatedSubscription, RolesUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RolesUpdatedSubscription, RolesUpdatedSubscriptionVariables>(RolesUpdatedDocument, options);
      }
export type RolesUpdatedSubscriptionHookResult = ReturnType<typeof useRolesUpdatedSubscription>;
export type RolesUpdatedSubscriptionResult = Apollo.SubscriptionResult<RolesUpdatedSubscription>;
export const GetUserGroupDocument = gql`
    query GetUserGroup($id: ID!) {
  userGroup(id: $id) {
    id
    name
    users {
      id
      username
      firstName
      lastName
    }
    roles {
      id
      name
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
    privilegeAssignmentList {
      ...PrivilegeAssignmentFragment
    }
  }
}
    ${PrivilegeAssignmentFragmentFragmentDoc}`;

/**
 * __useGetUserGroupQuery__
 *
 * To run a query within a React component, call `useGetUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserGroupQuery(baseOptions: Apollo.QueryHookOptions<GetUserGroupQuery, GetUserGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGroupQuery, GetUserGroupQueryVariables>(GetUserGroupDocument, options);
      }
export function useGetUserGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGroupQuery, GetUserGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGroupQuery, GetUserGroupQueryVariables>(GetUserGroupDocument, options);
        }
export type GetUserGroupQueryHookResult = ReturnType<typeof useGetUserGroupQuery>;
export type GetUserGroupLazyQueryHookResult = ReturnType<typeof useGetUserGroupLazyQuery>;
export type GetUserGroupQueryResult = Apollo.QueryResult<GetUserGroupQuery, GetUserGroupQueryVariables>;
export function refetchGetUserGroupQuery(variables: GetUserGroupQueryVariables) {
      return { query: GetUserGroupDocument, variables: variables }
    }
export const GetUserGroupsDocument = gql`
    query GetUserGroups {
  userGroups {
    id
    name
    users {
      id
    }
    roles {
      id
      name
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    `;

/**
 * __useGetUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
      }
export function useGetUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
        }
export type GetUserGroupsQueryHookResult = ReturnType<typeof useGetUserGroupsQuery>;
export type GetUserGroupsLazyQueryHookResult = ReturnType<typeof useGetUserGroupsLazyQuery>;
export type GetUserGroupsQueryResult = Apollo.QueryResult<GetUserGroupsQuery, GetUserGroupsQueryVariables>;
export function refetchGetUserGroupsQuery(variables?: GetUserGroupsQueryVariables) {
      return { query: GetUserGroupsDocument, variables: variables }
    }
export const UserGroupsUpdatedDocument = gql`
    subscription UserGroupsUpdated($ids: [ID!]!) {
  userGroupsUpdated(ids: $ids) {
    id
    name
    users {
      id
    }
    roles {
      id
      name
    }
    restrictedIntegrationGroups
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    `;

/**
 * __useUserGroupsUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserGroupsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserGroupsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserGroupsUpdatedSubscription, UserGroupsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserGroupsUpdatedSubscription, UserGroupsUpdatedSubscriptionVariables>(UserGroupsUpdatedDocument, options);
      }
export type UserGroupsUpdatedSubscriptionHookResult = ReturnType<typeof useUserGroupsUpdatedSubscription>;
export type UserGroupsUpdatedSubscriptionResult = Apollo.SubscriptionResult<UserGroupsUpdatedSubscription>;
export const GetUserWithPrivilegesDocument = gql`
    query GetUserWithPrivileges($after: String, $before: String, $limit: Int = 10, $sortField: String, $sortOrder: SortOrder, $searchText: String) {
  users(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    searchText: $searchText
  ) {
    edges {
      node {
        ...KineticUserFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${KineticUserFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useGetUserWithPrivilegesQuery__
 *
 * To run a query within a React component, call `useGetUserWithPrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWithPrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWithPrivilegesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUserWithPrivilegesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWithPrivilegesQuery, GetUserWithPrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWithPrivilegesQuery, GetUserWithPrivilegesQueryVariables>(GetUserWithPrivilegesDocument, options);
      }
export function useGetUserWithPrivilegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWithPrivilegesQuery, GetUserWithPrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWithPrivilegesQuery, GetUserWithPrivilegesQueryVariables>(GetUserWithPrivilegesDocument, options);
        }
export type GetUserWithPrivilegesQueryHookResult = ReturnType<typeof useGetUserWithPrivilegesQuery>;
export type GetUserWithPrivilegesLazyQueryHookResult = ReturnType<typeof useGetUserWithPrivilegesLazyQuery>;
export type GetUserWithPrivilegesQueryResult = Apollo.QueryResult<GetUserWithPrivilegesQuery, GetUserWithPrivilegesQueryVariables>;
export function refetchGetUserWithPrivilegesQuery(variables?: GetUserWithPrivilegesQueryVariables) {
      return { query: GetUserWithPrivilegesDocument, variables: variables }
    }
export const GetPrivilegesDocument = gql`
    query GetPrivileges {
  privileges {
    id
    privilegeType
    kineticModule
  }
}
    `;

/**
 * __useGetPrivilegesQuery__
 *
 * To run a query within a React component, call `useGetPrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivilegesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrivilegesQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivilegesQuery, GetPrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivilegesQuery, GetPrivilegesQueryVariables>(GetPrivilegesDocument, options);
      }
export function useGetPrivilegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivilegesQuery, GetPrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivilegesQuery, GetPrivilegesQueryVariables>(GetPrivilegesDocument, options);
        }
export type GetPrivilegesQueryHookResult = ReturnType<typeof useGetPrivilegesQuery>;
export type GetPrivilegesLazyQueryHookResult = ReturnType<typeof useGetPrivilegesLazyQuery>;
export type GetPrivilegesQueryResult = Apollo.QueryResult<GetPrivilegesQuery, GetPrivilegesQueryVariables>;
export function refetchGetPrivilegesQuery(variables?: GetPrivilegesQueryVariables) {
      return { query: GetPrivilegesDocument, variables: variables }
    }
export const GetKineticUserDocument = gql`
    query GetKineticUser($id: ID!) {
  getKineticUser(id: $id) {
    ...KineticUserFragment
  }
}
    ${KineticUserFragmentFragmentDoc}`;

/**
 * __useGetKineticUserQuery__
 *
 * To run a query within a React component, call `useGetKineticUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKineticUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKineticUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKineticUserQuery(baseOptions: Apollo.QueryHookOptions<GetKineticUserQuery, GetKineticUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKineticUserQuery, GetKineticUserQueryVariables>(GetKineticUserDocument, options);
      }
export function useGetKineticUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKineticUserQuery, GetKineticUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKineticUserQuery, GetKineticUserQueryVariables>(GetKineticUserDocument, options);
        }
export type GetKineticUserQueryHookResult = ReturnType<typeof useGetKineticUserQuery>;
export type GetKineticUserLazyQueryHookResult = ReturnType<typeof useGetKineticUserLazyQuery>;
export type GetKineticUserQueryResult = Apollo.QueryResult<GetKineticUserQuery, GetKineticUserQueryVariables>;
export function refetchGetKineticUserQuery(variables: GetKineticUserQueryVariables) {
      return { query: GetKineticUserDocument, variables: variables }
    }
export const PrivilegeAssignmentsUpdatedDocument = gql`
    subscription PrivilegeAssignmentsUpdated($ids: [ID!]!) {
  privilegeAssignmentsUpdated(ids: $ids) {
    id
  }
}
    `;

/**
 * __usePrivilegeAssignmentsUpdatedSubscription__
 *
 * To run a query within a React component, call `usePrivilegeAssignmentsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePrivilegeAssignmentsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivilegeAssignmentsUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePrivilegeAssignmentsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PrivilegeAssignmentsUpdatedSubscription, PrivilegeAssignmentsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PrivilegeAssignmentsUpdatedSubscription, PrivilegeAssignmentsUpdatedSubscriptionVariables>(PrivilegeAssignmentsUpdatedDocument, options);
      }
export type PrivilegeAssignmentsUpdatedSubscriptionHookResult = ReturnType<typeof usePrivilegeAssignmentsUpdatedSubscription>;
export type PrivilegeAssignmentsUpdatedSubscriptionResult = Apollo.SubscriptionResult<PrivilegeAssignmentsUpdatedSubscription>;
export const IsUsernameUniqueDocument = gql`
    query isUsernameUnique($username: String!) {
  isUsernameUnique(username: $username)
}
    `;

/**
 * __useIsUsernameUniqueQuery__
 *
 * To run a query within a React component, call `useIsUsernameUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUsernameUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUsernameUniqueQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useIsUsernameUniqueQuery(baseOptions: Apollo.QueryHookOptions<IsUsernameUniqueQuery, IsUsernameUniqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUsernameUniqueQuery, IsUsernameUniqueQueryVariables>(IsUsernameUniqueDocument, options);
      }
export function useIsUsernameUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUsernameUniqueQuery, IsUsernameUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUsernameUniqueQuery, IsUsernameUniqueQueryVariables>(IsUsernameUniqueDocument, options);
        }
export type IsUsernameUniqueQueryHookResult = ReturnType<typeof useIsUsernameUniqueQuery>;
export type IsUsernameUniqueLazyQueryHookResult = ReturnType<typeof useIsUsernameUniqueLazyQuery>;
export type IsUsernameUniqueQueryResult = Apollo.QueryResult<IsUsernameUniqueQuery, IsUsernameUniqueQueryVariables>;
export function refetchIsUsernameUniqueQuery(variables: IsUsernameUniqueQueryVariables) {
      return { query: IsUsernameUniqueDocument, variables: variables }
    }
export const LicenseFeaturesDocument = gql`
    query licenseFeatures {
  licenseFeatures {
    feature
    value
    enabled
    used
  }
}
    `;

/**
 * __useLicenseFeaturesQuery__
 *
 * To run a query within a React component, call `useLicenseFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>(LicenseFeaturesDocument, options);
      }
export function useLicenseFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>(LicenseFeaturesDocument, options);
        }
export type LicenseFeaturesQueryHookResult = ReturnType<typeof useLicenseFeaturesQuery>;
export type LicenseFeaturesLazyQueryHookResult = ReturnType<typeof useLicenseFeaturesLazyQuery>;
export type LicenseFeaturesQueryResult = Apollo.QueryResult<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>;
export function refetchLicenseFeaturesQuery(variables?: LicenseFeaturesQueryVariables) {
      return { query: LicenseFeaturesDocument, variables: variables }
    }
export const VersionDocument = gql`
    query version {
  version
  gitHash
  lastGitTag
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
      }
export function useVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export function refetchVersionQuery(variables?: VersionQueryVariables) {
      return { query: VersionDocument, variables: variables }
    }
export const LicenseDetailsDocument = gql`
    query licenseDetails {
  licenseDetails {
    key
    activationId
    expiresAt
    leaseExpiresAt
    accountId
    company
    email
  }
}
    `;

/**
 * __useLicenseDetailsQuery__
 *
 * To run a query within a React component, call `useLicenseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseDetailsQuery(baseOptions?: Apollo.QueryHookOptions<LicenseDetailsQuery, LicenseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseDetailsQuery, LicenseDetailsQueryVariables>(LicenseDetailsDocument, options);
      }
export function useLicenseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseDetailsQuery, LicenseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseDetailsQuery, LicenseDetailsQueryVariables>(LicenseDetailsDocument, options);
        }
export type LicenseDetailsQueryHookResult = ReturnType<typeof useLicenseDetailsQuery>;
export type LicenseDetailsLazyQueryHookResult = ReturnType<typeof useLicenseDetailsLazyQuery>;
export type LicenseDetailsQueryResult = Apollo.QueryResult<LicenseDetailsQuery, LicenseDetailsQueryVariables>;
export function refetchLicenseDetailsQuery(variables?: LicenseDetailsQueryVariables) {
      return { query: LicenseDetailsDocument, variables: variables }
    }
export const HasValidLicenseDocument = gql`
    query hasValidLicense {
  hasValidLicense
}
    `;

/**
 * __useHasValidLicenseQuery__
 *
 * To run a query within a React component, call `useHasValidLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasValidLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasValidLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasValidLicenseQuery(baseOptions?: Apollo.QueryHookOptions<HasValidLicenseQuery, HasValidLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasValidLicenseQuery, HasValidLicenseQueryVariables>(HasValidLicenseDocument, options);
      }
export function useHasValidLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasValidLicenseQuery, HasValidLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasValidLicenseQuery, HasValidLicenseQueryVariables>(HasValidLicenseDocument, options);
        }
export type HasValidLicenseQueryHookResult = ReturnType<typeof useHasValidLicenseQuery>;
export type HasValidLicenseLazyQueryHookResult = ReturnType<typeof useHasValidLicenseLazyQuery>;
export type HasValidLicenseQueryResult = Apollo.QueryResult<HasValidLicenseQuery, HasValidLicenseQueryVariables>;
export function refetchHasValidLicenseQuery(variables?: HasValidLicenseQueryVariables) {
      return { query: HasValidLicenseDocument, variables: variables }
    }
export const KineticFormEventTypesDocument = gql`
    query KineticFormEventTypes($sortOrder: SortOrder = ASCENDING, $sortField: String = "name", $category: EventCategory, $filters: [FilterInput], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  eventTypes(
    query: {after: $after, before: $before, limit: $limit, sortOrder: $sortOrder, searchText: $searchText, sortField: $sortField, category: $category, filters: $filters}
  ) {
    edges {
      node {
        ...KineticFormEventType
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${KineticFormEventTypeFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useKineticFormEventTypesQuery__
 *
 * To run a query within a React component, call `useKineticFormEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormEventTypesQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      category: // value for 'category'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useKineticFormEventTypesQuery(baseOptions?: Apollo.QueryHookOptions<KineticFormEventTypesQuery, KineticFormEventTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormEventTypesQuery, KineticFormEventTypesQueryVariables>(KineticFormEventTypesDocument, options);
      }
export function useKineticFormEventTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormEventTypesQuery, KineticFormEventTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormEventTypesQuery, KineticFormEventTypesQueryVariables>(KineticFormEventTypesDocument, options);
        }
export type KineticFormEventTypesQueryHookResult = ReturnType<typeof useKineticFormEventTypesQuery>;
export type KineticFormEventTypesLazyQueryHookResult = ReturnType<typeof useKineticFormEventTypesLazyQuery>;
export type KineticFormEventTypesQueryResult = Apollo.QueryResult<KineticFormEventTypesQuery, KineticFormEventTypesQueryVariables>;
export function refetchKineticFormEventTypesQuery(variables?: KineticFormEventTypesQueryVariables) {
      return { query: KineticFormEventTypesDocument, variables: variables }
    }
export const DataSourceConfigListDocument = gql`
    query DataSourceConfigList($sortOrder: SortOrder = ASCENDING, $sortField: String = "name", $module: KineticModule, $filters: [FilterInput], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  dataSourceConfigs(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, module: $module, before: $before, after: $after, limit: $limit}
  ) {
    edges {
      node {
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useDataSourceConfigListQuery__
 *
 * To run a query within a React component, call `useDataSourceConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceConfigListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      module: // value for 'module'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDataSourceConfigListQuery(baseOptions?: Apollo.QueryHookOptions<DataSourceConfigListQuery, DataSourceConfigListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceConfigListQuery, DataSourceConfigListQueryVariables>(DataSourceConfigListDocument, options);
      }
export function useDataSourceConfigListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceConfigListQuery, DataSourceConfigListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceConfigListQuery, DataSourceConfigListQueryVariables>(DataSourceConfigListDocument, options);
        }
export type DataSourceConfigListQueryHookResult = ReturnType<typeof useDataSourceConfigListQuery>;
export type DataSourceConfigListLazyQueryHookResult = ReturnType<typeof useDataSourceConfigListLazyQuery>;
export type DataSourceConfigListQueryResult = Apollo.QueryResult<DataSourceConfigListQuery, DataSourceConfigListQueryVariables>;
export function refetchDataSourceConfigListQuery(variables?: DataSourceConfigListQueryVariables) {
      return { query: DataSourceConfigListDocument, variables: variables }
    }
export const KineticFormsDocument = gql`
    query KineticForms($sortOrder: SortOrder = ASCENDING, $sortField: String = "name", $filters: [FilterInput], $searchText: String, $limit: Int = 10, $before: String, $after: String, $module: KineticModule) {
  kineticForms(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, after: $after, limit: $limit, module: $module}
  ) {
    edges {
      node {
        ...KineticForm
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${KineticFormFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useKineticFormsQuery__
 *
 * To run a query within a React component, call `useKineticFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormsQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useKineticFormsQuery(baseOptions?: Apollo.QueryHookOptions<KineticFormsQuery, KineticFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormsQuery, KineticFormsQueryVariables>(KineticFormsDocument, options);
      }
export function useKineticFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormsQuery, KineticFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormsQuery, KineticFormsQueryVariables>(KineticFormsDocument, options);
        }
export type KineticFormsQueryHookResult = ReturnType<typeof useKineticFormsQuery>;
export type KineticFormsLazyQueryHookResult = ReturnType<typeof useKineticFormsLazyQuery>;
export type KineticFormsQueryResult = Apollo.QueryResult<KineticFormsQuery, KineticFormsQueryVariables>;
export function refetchKineticFormsQuery(variables?: KineticFormsQueryVariables) {
      return { query: KineticFormsDocument, variables: variables }
    }
export const KineticFormDetailDocument = gql`
    query KineticFormDetail($id: ID!) {
  kineticForm(id: $id) {
    ...KineticForm
  }
}
    ${KineticFormFragmentDoc}`;

/**
 * __useKineticFormDetailQuery__
 *
 * To run a query within a React component, call `useKineticFormDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKineticFormDetailQuery(baseOptions: Apollo.QueryHookOptions<KineticFormDetailQuery, KineticFormDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormDetailQuery, KineticFormDetailQueryVariables>(KineticFormDetailDocument, options);
      }
export function useKineticFormDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormDetailQuery, KineticFormDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormDetailQuery, KineticFormDetailQueryVariables>(KineticFormDetailDocument, options);
        }
export type KineticFormDetailQueryHookResult = ReturnType<typeof useKineticFormDetailQuery>;
export type KineticFormDetailLazyQueryHookResult = ReturnType<typeof useKineticFormDetailLazyQuery>;
export type KineticFormDetailQueryResult = Apollo.QueryResult<KineticFormDetailQuery, KineticFormDetailQueryVariables>;
export function refetchKineticFormDetailQuery(variables: KineticFormDetailQueryVariables) {
      return { query: KineticFormDetailDocument, variables: variables }
    }
export const KineticFormFieldsDocument = gql`
    query KineticFormFields($sortOrder: SortOrder = ASCENDING, $sortField: String = "name", $filters: [FilterInput], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  kineticFormFields(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, after: $after, limit: $limit}
  ) {
    edges {
      node {
        ...KineticFormField
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${KineticFormFieldFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useKineticFormFieldsQuery__
 *
 * To run a query within a React component, call `useKineticFormFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormFieldsQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useKineticFormFieldsQuery(baseOptions?: Apollo.QueryHookOptions<KineticFormFieldsQuery, KineticFormFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormFieldsQuery, KineticFormFieldsQueryVariables>(KineticFormFieldsDocument, options);
      }
export function useKineticFormFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormFieldsQuery, KineticFormFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormFieldsQuery, KineticFormFieldsQueryVariables>(KineticFormFieldsDocument, options);
        }
export type KineticFormFieldsQueryHookResult = ReturnType<typeof useKineticFormFieldsQuery>;
export type KineticFormFieldsLazyQueryHookResult = ReturnType<typeof useKineticFormFieldsLazyQuery>;
export type KineticFormFieldsQueryResult = Apollo.QueryResult<KineticFormFieldsQuery, KineticFormFieldsQueryVariables>;
export function refetchKineticFormFieldsQuery(variables?: KineticFormFieldsQueryVariables) {
      return { query: KineticFormFieldsDocument, variables: variables }
    }
export const KineticFormFieldDetailDocument = gql`
    query KineticFormFieldDetail($id: ID!) {
  kineticFormField(id: $id) {
    ...KineticFormField
  }
}
    ${KineticFormFieldFragmentDoc}`;

/**
 * __useKineticFormFieldDetailQuery__
 *
 * To run a query within a React component, call `useKineticFormFieldDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormFieldDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormFieldDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKineticFormFieldDetailQuery(baseOptions: Apollo.QueryHookOptions<KineticFormFieldDetailQuery, KineticFormFieldDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormFieldDetailQuery, KineticFormFieldDetailQueryVariables>(KineticFormFieldDetailDocument, options);
      }
export function useKineticFormFieldDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormFieldDetailQuery, KineticFormFieldDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormFieldDetailQuery, KineticFormFieldDetailQueryVariables>(KineticFormFieldDetailDocument, options);
        }
export type KineticFormFieldDetailQueryHookResult = ReturnType<typeof useKineticFormFieldDetailQuery>;
export type KineticFormFieldDetailLazyQueryHookResult = ReturnType<typeof useKineticFormFieldDetailLazyQuery>;
export type KineticFormFieldDetailQueryResult = Apollo.QueryResult<KineticFormFieldDetailQuery, KineticFormFieldDetailQueryVariables>;
export function refetchKineticFormFieldDetailQuery(variables: KineticFormFieldDetailQueryVariables) {
      return { query: KineticFormFieldDetailDocument, variables: variables }
    }
export const GetTaskProgressDocument = gql`
    query getTaskProgress($query: TaskProgressQuery!) {
  taskProgress(query: $query) {
    edges {
      cursor
      node {
        ...TaskProgressFragment
      }
    }
  }
}
    ${TaskProgressFragmentFragmentDoc}`;

/**
 * __useGetTaskProgressQuery__
 *
 * To run a query within a React component, call `useGetTaskProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskProgressQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetTaskProgressQuery(baseOptions: Apollo.QueryHookOptions<GetTaskProgressQuery, GetTaskProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskProgressQuery, GetTaskProgressQueryVariables>(GetTaskProgressDocument, options);
      }
export function useGetTaskProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskProgressQuery, GetTaskProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskProgressQuery, GetTaskProgressQueryVariables>(GetTaskProgressDocument, options);
        }
export type GetTaskProgressQueryHookResult = ReturnType<typeof useGetTaskProgressQuery>;
export type GetTaskProgressLazyQueryHookResult = ReturnType<typeof useGetTaskProgressLazyQuery>;
export type GetTaskProgressQueryResult = Apollo.QueryResult<GetTaskProgressQuery, GetTaskProgressQueryVariables>;
export function refetchGetTaskProgressQuery(variables: GetTaskProgressQueryVariables) {
      return { query: GetTaskProgressDocument, variables: variables }
    }
export const TaskProgressUpdatedDocument = gql`
    subscription taskProgressUpdated {
  taskProgressUpdated {
    ...TaskProgressFragment
  }
}
    ${TaskProgressFragmentFragmentDoc}`;

/**
 * __useTaskProgressUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskProgressUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskProgressUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskProgressUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTaskProgressUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TaskProgressUpdatedSubscription, TaskProgressUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskProgressUpdatedSubscription, TaskProgressUpdatedSubscriptionVariables>(TaskProgressUpdatedDocument, options);
      }
export type TaskProgressUpdatedSubscriptionHookResult = ReturnType<typeof useTaskProgressUpdatedSubscription>;
export type TaskProgressUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskProgressUpdatedSubscription>;
export const SystemActivityLogsDocument = gql`
    query SystemActivityLogs($after: String, $before: String, $limit: Int = 100, $filters: [FilterInput!]!, $searchText: String, $searchFields: [String!] = ["entityId", "userId", "translationVariables", "object.name", "userFullName", "action"], $sortOrder: SortOrder) {
  activityLogs(
    searchText: $searchText
    filters: $filters
    before: $before
    after: $after
    limit: $limit
    searchFields: $searchFields
    sortOrder: $sortOrder
  ) {
    edges {
      node {
        ...ActivityLogNodeFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${ActivityLogNodeFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSystemActivityLogsQuery__
 *
 * To run a query within a React component, call `useSystemActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemActivityLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSystemActivityLogsQuery(baseOptions: Apollo.QueryHookOptions<SystemActivityLogsQuery, SystemActivityLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemActivityLogsQuery, SystemActivityLogsQueryVariables>(SystemActivityLogsDocument, options);
      }
export function useSystemActivityLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemActivityLogsQuery, SystemActivityLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemActivityLogsQuery, SystemActivityLogsQueryVariables>(SystemActivityLogsDocument, options);
        }
export type SystemActivityLogsQueryHookResult = ReturnType<typeof useSystemActivityLogsQuery>;
export type SystemActivityLogsLazyQueryHookResult = ReturnType<typeof useSystemActivityLogsLazyQuery>;
export type SystemActivityLogsQueryResult = Apollo.QueryResult<SystemActivityLogsQuery, SystemActivityLogsQueryVariables>;
export function refetchSystemActivityLogsQuery(variables: SystemActivityLogsQueryVariables) {
      return { query: SystemActivityLogsDocument, variables: variables }
    }
export const CentralAuthenticationSettingsDocument = gql`
    query CentralAuthenticationSettings {
  kineticAuthSettings {
    id
    name
    type
    sections {
      name
      id
      fields {
        id
        name
        label
        type
        required
        hint
        sourceId
        defaultValue
        loading
        showInput
        options {
          label
          value
        }
      }
    }
  }
}
    `;

/**
 * __useCentralAuthenticationSettingsQuery__
 *
 * To run a query within a React component, call `useCentralAuthenticationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCentralAuthenticationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCentralAuthenticationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCentralAuthenticationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CentralAuthenticationSettingsQuery, CentralAuthenticationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CentralAuthenticationSettingsQuery, CentralAuthenticationSettingsQueryVariables>(CentralAuthenticationSettingsDocument, options);
      }
export function useCentralAuthenticationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CentralAuthenticationSettingsQuery, CentralAuthenticationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CentralAuthenticationSettingsQuery, CentralAuthenticationSettingsQueryVariables>(CentralAuthenticationSettingsDocument, options);
        }
export type CentralAuthenticationSettingsQueryHookResult = ReturnType<typeof useCentralAuthenticationSettingsQuery>;
export type CentralAuthenticationSettingsLazyQueryHookResult = ReturnType<typeof useCentralAuthenticationSettingsLazyQuery>;
export type CentralAuthenticationSettingsQueryResult = Apollo.QueryResult<CentralAuthenticationSettingsQuery, CentralAuthenticationSettingsQueryVariables>;
export function refetchCentralAuthenticationSettingsQuery(variables?: CentralAuthenticationSettingsQueryVariables) {
      return { query: CentralAuthenticationSettingsDocument, variables: variables }
    }
export const SaveCentralAuthenticationSettingsDocument = gql`
    mutation SaveCentralAuthenticationSettings($settings: JSON!) {
  saveKineticAuthSettings(settings: $settings)
}
    `;
export type SaveCentralAuthenticationSettingsMutationFn = Apollo.MutationFunction<SaveCentralAuthenticationSettingsMutation, SaveCentralAuthenticationSettingsMutationVariables>;

/**
 * __useSaveCentralAuthenticationSettingsMutation__
 *
 * To run a mutation, you first call `useSaveCentralAuthenticationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCentralAuthenticationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCentralAuthenticationSettingsMutation, { data, loading, error }] = useSaveCentralAuthenticationSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveCentralAuthenticationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveCentralAuthenticationSettingsMutation, SaveCentralAuthenticationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCentralAuthenticationSettingsMutation, SaveCentralAuthenticationSettingsMutationVariables>(SaveCentralAuthenticationSettingsDocument, options);
      }
export type SaveCentralAuthenticationSettingsMutationHookResult = ReturnType<typeof useSaveCentralAuthenticationSettingsMutation>;
export type SaveCentralAuthenticationSettingsMutationResult = Apollo.MutationResult<SaveCentralAuthenticationSettingsMutation>;
export type SaveCentralAuthenticationSettingsMutationOptions = Apollo.BaseMutationOptions<SaveCentralAuthenticationSettingsMutation, SaveCentralAuthenticationSettingsMutationVariables>;
export const DeviceViewsDocument = gql`
    query deviceViews($id: DeviceViewsProtocolEntityEnum!) {
  deviceViews(id: $id) {
    views {
      ...viewFragment
      views {
        ...viewFragment
        views {
          ...viewFragment
          views {
            ...viewFragment
            views {
              ...viewFragment
              __typename
              views {
                ...viewFragment
                __typename
              }
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
    ${ViewFragmentFragmentDoc}`;

/**
 * __useDeviceViewsQuery__
 *
 * To run a query within a React component, call `useDeviceViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceViewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceViewsQuery(baseOptions: Apollo.QueryHookOptions<DeviceViewsQuery, DeviceViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceViewsQuery, DeviceViewsQueryVariables>(DeviceViewsDocument, options);
      }
export function useDeviceViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceViewsQuery, DeviceViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceViewsQuery, DeviceViewsQueryVariables>(DeviceViewsDocument, options);
        }
export type DeviceViewsQueryHookResult = ReturnType<typeof useDeviceViewsQuery>;
export type DeviceViewsLazyQueryHookResult = ReturnType<typeof useDeviceViewsLazyQuery>;
export type DeviceViewsQueryResult = Apollo.QueryResult<DeviceViewsQuery, DeviceViewsQueryVariables>;
export function refetchDeviceViewsQuery(variables: DeviceViewsQueryVariables) {
      return { query: DeviceViewsDocument, variables: variables }
    }
export const CentralAuthPinSettingsDocument = gql`
    query centralAuthPinSettings {
  centralAuthPinSettings {
    id
    pin
  }
}
    `;

/**
 * __useCentralAuthPinSettingsQuery__
 *
 * To run a query within a React component, call `useCentralAuthPinSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCentralAuthPinSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCentralAuthPinSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCentralAuthPinSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CentralAuthPinSettingsQuery, CentralAuthPinSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CentralAuthPinSettingsQuery, CentralAuthPinSettingsQueryVariables>(CentralAuthPinSettingsDocument, options);
      }
export function useCentralAuthPinSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CentralAuthPinSettingsQuery, CentralAuthPinSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CentralAuthPinSettingsQuery, CentralAuthPinSettingsQueryVariables>(CentralAuthPinSettingsDocument, options);
        }
export type CentralAuthPinSettingsQueryHookResult = ReturnType<typeof useCentralAuthPinSettingsQuery>;
export type CentralAuthPinSettingsLazyQueryHookResult = ReturnType<typeof useCentralAuthPinSettingsLazyQuery>;
export type CentralAuthPinSettingsQueryResult = Apollo.QueryResult<CentralAuthPinSettingsQuery, CentralAuthPinSettingsQueryVariables>;
export function refetchCentralAuthPinSettingsQuery(variables?: CentralAuthPinSettingsQueryVariables) {
      return { query: CentralAuthPinSettingsDocument, variables: variables }
    }
export const SaveNotificationPreferencesDocument = gql`
    mutation SaveNotificationPreferences($notificationPreferences: NotificationPreferenceInput) {
  saveNotificationPreferences(notificationPreferences: $notificationPreferences) {
    location
    preferenceSourceList {
      source
      severitySet
    }
  }
}
    `;
export type SaveNotificationPreferencesMutationFn = Apollo.MutationFunction<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>;

/**
 * __useSaveNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useSaveNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationPreferencesMutation, { data, loading, error }] = useSaveNotificationPreferencesMutation({
 *   variables: {
 *      notificationPreferences: // value for 'notificationPreferences'
 *   },
 * });
 */
export function useSaveNotificationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>(SaveNotificationPreferencesDocument, options);
      }
export type SaveNotificationPreferencesMutationHookResult = ReturnType<typeof useSaveNotificationPreferencesMutation>;
export type SaveNotificationPreferencesMutationResult = Apollo.MutationResult<SaveNotificationPreferencesMutation>;
export type SaveNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>;
export const NotificationPreferencesDocument = gql`
    query NotificationPreferences {
  notificationPreferences {
    location
    preferenceSourceList {
      source
      severitySet
    }
  }
}
    `;

/**
 * __useNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>(NotificationPreferencesDocument, options);
      }
export function useNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>(NotificationPreferencesDocument, options);
        }
export type NotificationPreferencesQueryHookResult = ReturnType<typeof useNotificationPreferencesQuery>;
export type NotificationPreferencesLazyQueryHookResult = ReturnType<typeof useNotificationPreferencesLazyQuery>;
export type NotificationPreferencesQueryResult = Apollo.QueryResult<NotificationPreferencesQuery, NotificationPreferencesQueryVariables>;
export function refetchNotificationPreferencesQuery(variables?: NotificationPreferencesQueryVariables) {
      return { query: NotificationPreferencesDocument, variables: variables }
    }
export const SaveRouteDocument = gql`
    mutation SaveRoute($input: LocationRouteInput!) {
  saveRoute(input: $input) {
    ...SavedRoute
  }
}
    ${SavedRouteFragmentDoc}`;
export type SaveRouteMutationFn = Apollo.MutationFunction<SaveRouteMutation, SaveRouteMutationVariables>;

/**
 * __useSaveRouteMutation__
 *
 * To run a mutation, you first call `useSaveRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRouteMutation, { data, loading, error }] = useSaveRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveRouteMutation(baseOptions?: Apollo.MutationHookOptions<SaveRouteMutation, SaveRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRouteMutation, SaveRouteMutationVariables>(SaveRouteDocument, options);
      }
export type SaveRouteMutationHookResult = ReturnType<typeof useSaveRouteMutation>;
export type SaveRouteMutationResult = Apollo.MutationResult<SaveRouteMutation>;
export type SaveRouteMutationOptions = Apollo.BaseMutationOptions<SaveRouteMutation, SaveRouteMutationVariables>;
export const DeleteRouteDocument = gql`
    mutation DeleteRoute($id: ID!) {
  deleteRoute(id: $id)
}
    `;
export type DeleteRouteMutationFn = Apollo.MutationFunction<DeleteRouteMutation, DeleteRouteMutationVariables>;

/**
 * __useDeleteRouteMutation__
 *
 * To run a mutation, you first call `useDeleteRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRouteMutation, { data, loading, error }] = useDeleteRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRouteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRouteMutation, DeleteRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRouteMutation, DeleteRouteMutationVariables>(DeleteRouteDocument, options);
      }
export type DeleteRouteMutationHookResult = ReturnType<typeof useDeleteRouteMutation>;
export type DeleteRouteMutationResult = Apollo.MutationResult<DeleteRouteMutation>;
export type DeleteRouteMutationOptions = Apollo.BaseMutationOptions<DeleteRouteMutation, DeleteRouteMutationVariables>;
export const GetRouteDocument = gql`
    query GetRoute($id: String!) {
  route(id: $id) {
    ...SavedRoute
  }
}
    ${SavedRouteFragmentDoc}`;

/**
 * __useGetRouteQuery__
 *
 * To run a query within a React component, call `useGetRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRouteQuery(baseOptions: Apollo.QueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
      }
export function useGetRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
        }
export type GetRouteQueryHookResult = ReturnType<typeof useGetRouteQuery>;
export type GetRouteLazyQueryHookResult = ReturnType<typeof useGetRouteLazyQuery>;
export type GetRouteQueryResult = Apollo.QueryResult<GetRouteQuery, GetRouteQueryVariables>;
export function refetchGetRouteQuery(variables: GetRouteQueryVariables) {
      return { query: GetRouteDocument, variables: variables }
    }
export const GetRouteDetailsDocument = gql`
    query GetRouteDetails($id: String!) {
  route(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetRouteDetailsQuery__
 *
 * To run a query within a React component, call `useGetRouteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRouteDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>(GetRouteDetailsDocument, options);
      }
export function useGetRouteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>(GetRouteDetailsDocument, options);
        }
export type GetRouteDetailsQueryHookResult = ReturnType<typeof useGetRouteDetailsQuery>;
export type GetRouteDetailsLazyQueryHookResult = ReturnType<typeof useGetRouteDetailsLazyQuery>;
export type GetRouteDetailsQueryResult = Apollo.QueryResult<GetRouteDetailsQuery, GetRouteDetailsQueryVariables>;
export function refetchGetRouteDetailsQuery(variables: GetRouteDetailsQueryVariables) {
      return { query: GetRouteDetailsDocument, variables: variables }
    }
export const RoutesListDocument = gql`
    query RoutesList($after: String, $before: String, $limit: Int = 10, $sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $searchText: String, $filters: [FilterInput]) {
  routes(
    after: $after
    before: $before
    limit: $limit
    sortOrder: $sortOrder
    searchText: $searchText
    sortField: $sortField
    filters: $filters
  ) {
    edges {
      node {
        id
        name
        classification
        fromMeasure
        toMeasure
        direction
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useRoutesListQuery__
 *
 * To run a query within a React component, call `useRoutesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      searchText: // value for 'searchText'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRoutesListQuery(baseOptions?: Apollo.QueryHookOptions<RoutesListQuery, RoutesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutesListQuery, RoutesListQueryVariables>(RoutesListDocument, options);
      }
export function useRoutesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutesListQuery, RoutesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutesListQuery, RoutesListQueryVariables>(RoutesListDocument, options);
        }
export type RoutesListQueryHookResult = ReturnType<typeof useRoutesListQuery>;
export type RoutesListLazyQueryHookResult = ReturnType<typeof useRoutesListLazyQuery>;
export type RoutesListQueryResult = Apollo.QueryResult<RoutesListQuery, RoutesListQueryVariables>;
export function refetchRoutesListQuery(variables?: RoutesListQueryVariables) {
      return { query: RoutesListDocument, variables: variables }
    }
export const ContactGroupsShortDocument = gql`
    query ContactGroupsShort($limit: Int = 1000000, $sortField: String = "name", $sortOrder: SortOrder = ASCENDING) {
  contactGroupsQuery(
    query: {limit: $limit, sortField: $sortField, sortOrder: $sortOrder}
  ) {
    edges {
      node {
        ...ContactGroupShortFragment
      }
    }
  }
}
    ${ContactGroupShortFragmentFragmentDoc}`;

/**
 * __useContactGroupsShortQuery__
 *
 * To run a query within a React component, call `useContactGroupsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactGroupsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactGroupsShortQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useContactGroupsShortQuery(baseOptions?: Apollo.QueryHookOptions<ContactGroupsShortQuery, ContactGroupsShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactGroupsShortQuery, ContactGroupsShortQueryVariables>(ContactGroupsShortDocument, options);
      }
export function useContactGroupsShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactGroupsShortQuery, ContactGroupsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactGroupsShortQuery, ContactGroupsShortQueryVariables>(ContactGroupsShortDocument, options);
        }
export type ContactGroupsShortQueryHookResult = ReturnType<typeof useContactGroupsShortQuery>;
export type ContactGroupsShortLazyQueryHookResult = ReturnType<typeof useContactGroupsShortLazyQuery>;
export type ContactGroupsShortQueryResult = Apollo.QueryResult<ContactGroupsShortQuery, ContactGroupsShortQueryVariables>;
export function refetchContactGroupsShortQuery(variables?: ContactGroupsShortQueryVariables) {
      return { query: ContactGroupsShortDocument, variables: variables }
    }
export const IntegrationsSelectionDocument = gql`
    query IntegrationsSelection($after: String, $before: String, $limit: Int = 10, $sortOrder: SortOrder = ASCENDING, $searchText: String, $filters: [FilterInput!]) {
  integrations(
    after: $after
    before: $before
    limit: $limit
    sortOrder: $sortOrder
    searchText: $searchText
    filters: $filters
  ) {
    edges {
      node {
        id
        name
        config {
          integrationType
        }
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useIntegrationsSelectionQuery__
 *
 * To run a query within a React component, call `useIntegrationsSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsSelectionQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useIntegrationsSelectionQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationsSelectionQuery, IntegrationsSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsSelectionQuery, IntegrationsSelectionQueryVariables>(IntegrationsSelectionDocument, options);
      }
export function useIntegrationsSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsSelectionQuery, IntegrationsSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsSelectionQuery, IntegrationsSelectionQueryVariables>(IntegrationsSelectionDocument, options);
        }
export type IntegrationsSelectionQueryHookResult = ReturnType<typeof useIntegrationsSelectionQuery>;
export type IntegrationsSelectionLazyQueryHookResult = ReturnType<typeof useIntegrationsSelectionLazyQuery>;
export type IntegrationsSelectionQueryResult = Apollo.QueryResult<IntegrationsSelectionQuery, IntegrationsSelectionQueryVariables>;
export function refetchIntegrationsSelectionQuery(variables?: IntegrationsSelectionQueryVariables) {
      return { query: IntegrationsSelectionDocument, variables: variables }
    }
export const SignDisplayTypeDocument = gql`
    query SignDisplayType($id: ID!) {
  signDisplayType(id: $id) {
    ...SignDisplayType
  }
}
    ${SignDisplayTypeFragmentDoc}`;

/**
 * __useSignDisplayTypeQuery__
 *
 * To run a query within a React component, call `useSignDisplayTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignDisplayTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignDisplayTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignDisplayTypeQuery(baseOptions: Apollo.QueryHookOptions<SignDisplayTypeQuery, SignDisplayTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignDisplayTypeQuery, SignDisplayTypeQueryVariables>(SignDisplayTypeDocument, options);
      }
export function useSignDisplayTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignDisplayTypeQuery, SignDisplayTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignDisplayTypeQuery, SignDisplayTypeQueryVariables>(SignDisplayTypeDocument, options);
        }
export type SignDisplayTypeQueryHookResult = ReturnType<typeof useSignDisplayTypeQuery>;
export type SignDisplayTypeLazyQueryHookResult = ReturnType<typeof useSignDisplayTypeLazyQuery>;
export type SignDisplayTypeQueryResult = Apollo.QueryResult<SignDisplayTypeQuery, SignDisplayTypeQueryVariables>;
export function refetchSignDisplayTypeQuery(variables: SignDisplayTypeQueryVariables) {
      return { query: SignDisplayTypeDocument, variables: variables }
    }
export const KineticUserDocument = gql`
    query KineticUser {
  kineticUser {
    id
    username
    firstName
    lastName
    picture
    email
    preferences {
      key
      value
    }
    kineticPreferences {
      key
      value
    }
  }
  organizationPreferences {
    key
    value
  }
}
    `;

/**
 * __useKineticUserQuery__
 *
 * To run a query within a React component, call `useKineticUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useKineticUserQuery(baseOptions?: Apollo.QueryHookOptions<KineticUserQuery, KineticUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticUserQuery, KineticUserQueryVariables>(KineticUserDocument, options);
      }
export function useKineticUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticUserQuery, KineticUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticUserQuery, KineticUserQueryVariables>(KineticUserDocument, options);
        }
export type KineticUserQueryHookResult = ReturnType<typeof useKineticUserQuery>;
export type KineticUserLazyQueryHookResult = ReturnType<typeof useKineticUserLazyQuery>;
export type KineticUserQueryResult = Apollo.QueryResult<KineticUserQuery, KineticUserQueryVariables>;
export function refetchKineticUserQuery(variables?: KineticUserQueryVariables) {
      return { query: KineticUserDocument, variables: variables }
    }
export const ExportActivityLogsDocument = gql`
    mutation ExportActivityLogs($filters: [FilterInput!]!, $searchText: String!, $config: ExportConfig!, $searchFields: [String!]!) {
  exportActivityLogs(
    filters: $filters
    searchText: $searchText
    config: $config
    searchFields: $searchFields
  )
}
    `;
export type ExportActivityLogsMutationFn = Apollo.MutationFunction<ExportActivityLogsMutation, ExportActivityLogsMutationVariables>;

/**
 * __useExportActivityLogsMutation__
 *
 * To run a mutation, you first call `useExportActivityLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportActivityLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportActivityLogsMutation, { data, loading, error }] = useExportActivityLogsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      config: // value for 'config'
 *      searchFields: // value for 'searchFields'
 *   },
 * });
 */
export function useExportActivityLogsMutation(baseOptions?: Apollo.MutationHookOptions<ExportActivityLogsMutation, ExportActivityLogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportActivityLogsMutation, ExportActivityLogsMutationVariables>(ExportActivityLogsDocument, options);
      }
export type ExportActivityLogsMutationHookResult = ReturnType<typeof useExportActivityLogsMutation>;
export type ExportActivityLogsMutationResult = Apollo.MutationResult<ExportActivityLogsMutation>;
export type ExportActivityLogsMutationOptions = Apollo.BaseMutationOptions<ExportActivityLogsMutation, ExportActivityLogsMutationVariables>;
export const CorridorCutoutsListDocument = gql`
    query CorridorCutoutsList($after: String, $before: String, $limit: Int = 10, $sortField: String = "name", $sortOrder: SortOrder = DESCENDING, $searchText: String, $searchFields: [String!] = ["name", "type", "description"], $filters: [FilterInput]) {
  signGroups(
    query: {after: $after, before: $before, limit: $limit, sortField: $sortField, sortOrder: $sortOrder, searchText: $searchText, searchFields: $searchFields, filters: $filters}
  ) {
    edges {
      node {
        description
        attributes
        signs {
          ...CorridorSchematicSign
        }
        type
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CorridorSchematicSignFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useCorridorCutoutsListQuery__
 *
 * To run a query within a React component, call `useCorridorCutoutsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorridorCutoutsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorridorCutoutsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCorridorCutoutsListQuery(baseOptions?: Apollo.QueryHookOptions<CorridorCutoutsListQuery, CorridorCutoutsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorridorCutoutsListQuery, CorridorCutoutsListQueryVariables>(CorridorCutoutsListDocument, options);
      }
export function useCorridorCutoutsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorridorCutoutsListQuery, CorridorCutoutsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorridorCutoutsListQuery, CorridorCutoutsListQueryVariables>(CorridorCutoutsListDocument, options);
        }
export type CorridorCutoutsListQueryHookResult = ReturnType<typeof useCorridorCutoutsListQuery>;
export type CorridorCutoutsListLazyQueryHookResult = ReturnType<typeof useCorridorCutoutsListLazyQuery>;
export type CorridorCutoutsListQueryResult = Apollo.QueryResult<CorridorCutoutsListQuery, CorridorCutoutsListQueryVariables>;
export function refetchCorridorCutoutsListQuery(variables?: CorridorCutoutsListQueryVariables) {
      return { query: CorridorCutoutsListDocument, variables: variables }
    }
export const ResourcePlanResourcesListDocument = gql`
    query ResourcePlanResourcesList($id: ID!) {
  resourcePlan(id: $id) {
    resources {
      status
      type
      data
      id
    }
  }
}
    `;

/**
 * __useResourcePlanResourcesListQuery__
 *
 * To run a query within a React component, call `useResourcePlanResourcesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePlanResourcesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePlanResourcesListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourcePlanResourcesListQuery(baseOptions: Apollo.QueryHookOptions<ResourcePlanResourcesListQuery, ResourcePlanResourcesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePlanResourcesListQuery, ResourcePlanResourcesListQueryVariables>(ResourcePlanResourcesListDocument, options);
      }
export function useResourcePlanResourcesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePlanResourcesListQuery, ResourcePlanResourcesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePlanResourcesListQuery, ResourcePlanResourcesListQueryVariables>(ResourcePlanResourcesListDocument, options);
        }
export type ResourcePlanResourcesListQueryHookResult = ReturnType<typeof useResourcePlanResourcesListQuery>;
export type ResourcePlanResourcesListLazyQueryHookResult = ReturnType<typeof useResourcePlanResourcesListLazyQuery>;
export type ResourcePlanResourcesListQueryResult = Apollo.QueryResult<ResourcePlanResourcesListQuery, ResourcePlanResourcesListQueryVariables>;
export function refetchResourcePlanResourcesListQuery(variables: ResourcePlanResourcesListQueryVariables) {
      return { query: ResourcePlanResourcesListDocument, variables: variables }
    }
export const SchematicResponsePostDocument = gql`
    mutation SchematicResponsePost($requests: [SignMessageDisplayRequest!]!) {
  signDisplay(requests: $requests) {
    requestId
    success
    failure
    id
  }
}
    `;
export type SchematicResponsePostMutationFn = Apollo.MutationFunction<SchematicResponsePostMutation, SchematicResponsePostMutationVariables>;

/**
 * __useSchematicResponsePostMutation__
 *
 * To run a mutation, you first call `useSchematicResponsePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchematicResponsePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schematicResponsePostMutation, { data, loading, error }] = useSchematicResponsePostMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useSchematicResponsePostMutation(baseOptions?: Apollo.MutationHookOptions<SchematicResponsePostMutation, SchematicResponsePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SchematicResponsePostMutation, SchematicResponsePostMutationVariables>(SchematicResponsePostDocument, options);
      }
export type SchematicResponsePostMutationHookResult = ReturnType<typeof useSchematicResponsePostMutation>;
export type SchematicResponsePostMutationResult = Apollo.MutationResult<SchematicResponsePostMutation>;
export type SchematicResponsePostMutationOptions = Apollo.BaseMutationOptions<SchematicResponsePostMutation, SchematicResponsePostMutationVariables>;
export const CorridorSchematicSignsDocument = gql`
    query CorridorSchematicSigns($filters: [FilterInput!]!, $limit: Int!) {
  signs(filters: $filters, limit: $limit) {
    edges {
      node {
        ...CorridorSchematicSign
      }
    }
  }
}
    ${CorridorSchematicSignFragmentDoc}`;

/**
 * __useCorridorSchematicSignsQuery__
 *
 * To run a query within a React component, call `useCorridorSchematicSignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorridorSchematicSignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorridorSchematicSignsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCorridorSchematicSignsQuery(baseOptions: Apollo.QueryHookOptions<CorridorSchematicSignsQuery, CorridorSchematicSignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorridorSchematicSignsQuery, CorridorSchematicSignsQueryVariables>(CorridorSchematicSignsDocument, options);
      }
export function useCorridorSchematicSignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorridorSchematicSignsQuery, CorridorSchematicSignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorridorSchematicSignsQuery, CorridorSchematicSignsQueryVariables>(CorridorSchematicSignsDocument, options);
        }
export type CorridorSchematicSignsQueryHookResult = ReturnType<typeof useCorridorSchematicSignsQuery>;
export type CorridorSchematicSignsLazyQueryHookResult = ReturnType<typeof useCorridorSchematicSignsLazyQuery>;
export type CorridorSchematicSignsQueryResult = Apollo.QueryResult<CorridorSchematicSignsQuery, CorridorSchematicSignsQueryVariables>;
export function refetchCorridorSchematicSignsQuery(variables: CorridorSchematicSignsQueryVariables) {
      return { query: CorridorSchematicSignsDocument, variables: variables }
    }
export const LiveCorridorSchematicSignsDocument = gql`
    subscription LiveCorridorSchematicSigns($integrations: [ID!]!) {
  signUpdated(ids: $integrations) {
    ...CorridorSchematicSign
  }
}
    ${CorridorSchematicSignFragmentDoc}`;

/**
 * __useLiveCorridorSchematicSignsSubscription__
 *
 * To run a query within a React component, call `useLiveCorridorSchematicSignsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveCorridorSchematicSignsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveCorridorSchematicSignsSubscription({
 *   variables: {
 *      integrations: // value for 'integrations'
 *   },
 * });
 */
export function useLiveCorridorSchematicSignsSubscription(baseOptions: Apollo.SubscriptionHookOptions<LiveCorridorSchematicSignsSubscription, LiveCorridorSchematicSignsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LiveCorridorSchematicSignsSubscription, LiveCorridorSchematicSignsSubscriptionVariables>(LiveCorridorSchematicSignsDocument, options);
      }
export type LiveCorridorSchematicSignsSubscriptionHookResult = ReturnType<typeof useLiveCorridorSchematicSignsSubscription>;
export type LiveCorridorSchematicSignsSubscriptionResult = Apollo.SubscriptionResult<LiveCorridorSchematicSignsSubscription>;
export const SaveSystemDirectoriesDocument = gql`
    mutation SaveSystemDirectories($input: [DirectoryInput!]!, $removedDirectories: [String!]!) {
  saveSystemDirectories(input: $input, removedDirectories: $removedDirectories) {
    ...DirectoryRecursive
  }
}
    ${DirectoryRecursiveFragmentDoc}`;
export type SaveSystemDirectoriesMutationFn = Apollo.MutationFunction<SaveSystemDirectoriesMutation, SaveSystemDirectoriesMutationVariables>;

/**
 * __useSaveSystemDirectoriesMutation__
 *
 * To run a mutation, you first call `useSaveSystemDirectoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSystemDirectoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSystemDirectoriesMutation, { data, loading, error }] = useSaveSystemDirectoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      removedDirectories: // value for 'removedDirectories'
 *   },
 * });
 */
export function useSaveSystemDirectoriesMutation(baseOptions?: Apollo.MutationHookOptions<SaveSystemDirectoriesMutation, SaveSystemDirectoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSystemDirectoriesMutation, SaveSystemDirectoriesMutationVariables>(SaveSystemDirectoriesDocument, options);
      }
export type SaveSystemDirectoriesMutationHookResult = ReturnType<typeof useSaveSystemDirectoriesMutation>;
export type SaveSystemDirectoriesMutationResult = Apollo.MutationResult<SaveSystemDirectoriesMutation>;
export type SaveSystemDirectoriesMutationOptions = Apollo.BaseMutationOptions<SaveSystemDirectoriesMutation, SaveSystemDirectoriesMutationVariables>;
export const SaveUserDirectoriesDocument = gql`
    mutation SaveUserDirectories($input: [DirectoryInput!]!, $removedDirectories: [String!]!) {
  saveUserDirectories(input: $input, removedDirectories: $removedDirectories) {
    ...DirectoryRecursive
  }
}
    ${DirectoryRecursiveFragmentDoc}`;
export type SaveUserDirectoriesMutationFn = Apollo.MutationFunction<SaveUserDirectoriesMutation, SaveUserDirectoriesMutationVariables>;

/**
 * __useSaveUserDirectoriesMutation__
 *
 * To run a mutation, you first call `useSaveUserDirectoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserDirectoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserDirectoriesMutation, { data, loading, error }] = useSaveUserDirectoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      removedDirectories: // value for 'removedDirectories'
 *   },
 * });
 */
export function useSaveUserDirectoriesMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserDirectoriesMutation, SaveUserDirectoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserDirectoriesMutation, SaveUserDirectoriesMutationVariables>(SaveUserDirectoriesDocument, options);
      }
export type SaveUserDirectoriesMutationHookResult = ReturnType<typeof useSaveUserDirectoriesMutation>;
export type SaveUserDirectoriesMutationResult = Apollo.MutationResult<SaveUserDirectoriesMutation>;
export type SaveUserDirectoriesMutationOptions = Apollo.BaseMutationOptions<SaveUserDirectoriesMutation, SaveUserDirectoriesMutationVariables>;
export const DeleteManagedFilesDocument = gql`
    mutation DeleteManagedFiles($fileIds: [ID!]!) {
  deleteManagedFiles(fileIds: $fileIds)
}
    `;
export type DeleteManagedFilesMutationFn = Apollo.MutationFunction<DeleteManagedFilesMutation, DeleteManagedFilesMutationVariables>;

/**
 * __useDeleteManagedFilesMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFilesMutation, { data, loading, error }] = useDeleteManagedFilesMutation({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useDeleteManagedFilesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFilesMutation, DeleteManagedFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFilesMutation, DeleteManagedFilesMutationVariables>(DeleteManagedFilesDocument, options);
      }
export type DeleteManagedFilesMutationHookResult = ReturnType<typeof useDeleteManagedFilesMutation>;
export type DeleteManagedFilesMutationResult = Apollo.MutationResult<DeleteManagedFilesMutation>;
export type DeleteManagedFilesMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFilesMutation, DeleteManagedFilesMutationVariables>;
export const InitMyFilesDocument = gql`
    mutation InitMyFiles {
  initializeMyFilesDirectory {
    id
    order
    name
    owner
    parentId
  }
}
    `;
export type InitMyFilesMutationFn = Apollo.MutationFunction<InitMyFilesMutation, InitMyFilesMutationVariables>;

/**
 * __useInitMyFilesMutation__
 *
 * To run a mutation, you first call `useInitMyFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitMyFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initMyFilesMutation, { data, loading, error }] = useInitMyFilesMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitMyFilesMutation(baseOptions?: Apollo.MutationHookOptions<InitMyFilesMutation, InitMyFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitMyFilesMutation, InitMyFilesMutationVariables>(InitMyFilesDocument, options);
      }
export type InitMyFilesMutationHookResult = ReturnType<typeof useInitMyFilesMutation>;
export type InitMyFilesMutationResult = Apollo.MutationResult<InitMyFilesMutation>;
export type InitMyFilesMutationOptions = Apollo.BaseMutationOptions<InitMyFilesMutation, InitMyFilesMutationVariables>;
export const MoveFileDocument = gql`
    mutation MoveFile($destinationDirectoryId: String!, $fileId: String!) {
  moveFile(destinationDirectoryId: $destinationDirectoryId, fileId: $fileId)
}
    `;
export type MoveFileMutationFn = Apollo.MutationFunction<MoveFileMutation, MoveFileMutationVariables>;

/**
 * __useMoveFileMutation__
 *
 * To run a mutation, you first call `useMoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFileMutation, { data, loading, error }] = useMoveFileMutation({
 *   variables: {
 *      destinationDirectoryId: // value for 'destinationDirectoryId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useMoveFileMutation(baseOptions?: Apollo.MutationHookOptions<MoveFileMutation, MoveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveFileMutation, MoveFileMutationVariables>(MoveFileDocument, options);
      }
export type MoveFileMutationHookResult = ReturnType<typeof useMoveFileMutation>;
export type MoveFileMutationResult = Apollo.MutationResult<MoveFileMutation>;
export type MoveFileMutationOptions = Apollo.BaseMutationOptions<MoveFileMutation, MoveFileMutationVariables>;
export const CopyFileDocument = gql`
    mutation CopyFile($destinationDirectoryId: String!, $fileId: String!) {
  copyFile(destinationDirectoryId: $destinationDirectoryId, fileId: $fileId)
}
    `;
export type CopyFileMutationFn = Apollo.MutationFunction<CopyFileMutation, CopyFileMutationVariables>;

/**
 * __useCopyFileMutation__
 *
 * To run a mutation, you first call `useCopyFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyFileMutation, { data, loading, error }] = useCopyFileMutation({
 *   variables: {
 *      destinationDirectoryId: // value for 'destinationDirectoryId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCopyFileMutation(baseOptions?: Apollo.MutationHookOptions<CopyFileMutation, CopyFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyFileMutation, CopyFileMutationVariables>(CopyFileDocument, options);
      }
export type CopyFileMutationHookResult = ReturnType<typeof useCopyFileMutation>;
export type CopyFileMutationResult = Apollo.MutationResult<CopyFileMutation>;
export type CopyFileMutationOptions = Apollo.BaseMutationOptions<CopyFileMutation, CopyFileMutationVariables>;
export const GetSystemDirectoriesDocument = gql`
    query GetSystemDirectories($searchText: String) {
  getSystemDirectories(searchText: $searchText) {
    ...DirectoryRecursive
  }
}
    ${DirectoryRecursiveFragmentDoc}`;

/**
 * __useGetSystemDirectoriesQuery__
 *
 * To run a query within a React component, call `useGetSystemDirectoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemDirectoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemDirectoriesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetSystemDirectoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemDirectoriesQuery, GetSystemDirectoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemDirectoriesQuery, GetSystemDirectoriesQueryVariables>(GetSystemDirectoriesDocument, options);
      }
export function useGetSystemDirectoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemDirectoriesQuery, GetSystemDirectoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemDirectoriesQuery, GetSystemDirectoriesQueryVariables>(GetSystemDirectoriesDocument, options);
        }
export type GetSystemDirectoriesQueryHookResult = ReturnType<typeof useGetSystemDirectoriesQuery>;
export type GetSystemDirectoriesLazyQueryHookResult = ReturnType<typeof useGetSystemDirectoriesLazyQuery>;
export type GetSystemDirectoriesQueryResult = Apollo.QueryResult<GetSystemDirectoriesQuery, GetSystemDirectoriesQueryVariables>;
export function refetchGetSystemDirectoriesQuery(variables?: GetSystemDirectoriesQueryVariables) {
      return { query: GetSystemDirectoriesDocument, variables: variables }
    }
export const GetUserDirectoriesDocument = gql`
    query GetUserDirectories($searchText: String) {
  getUserDirectories(searchText: $searchText) {
    ...DirectoryRecursive
  }
}
    ${DirectoryRecursiveFragmentDoc}`;

/**
 * __useGetUserDirectoriesQuery__
 *
 * To run a query within a React component, call `useGetUserDirectoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDirectoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDirectoriesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUserDirectoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDirectoriesQuery, GetUserDirectoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDirectoriesQuery, GetUserDirectoriesQueryVariables>(GetUserDirectoriesDocument, options);
      }
export function useGetUserDirectoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDirectoriesQuery, GetUserDirectoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDirectoriesQuery, GetUserDirectoriesQueryVariables>(GetUserDirectoriesDocument, options);
        }
export type GetUserDirectoriesQueryHookResult = ReturnType<typeof useGetUserDirectoriesQuery>;
export type GetUserDirectoriesLazyQueryHookResult = ReturnType<typeof useGetUserDirectoriesLazyQuery>;
export type GetUserDirectoriesQueryResult = Apollo.QueryResult<GetUserDirectoriesQuery, GetUserDirectoriesQueryVariables>;
export function refetchGetUserDirectoriesQuery(variables?: GetUserDirectoriesQueryVariables) {
      return { query: GetUserDirectoriesDocument, variables: variables }
    }
export const GetManagedFilesByDirectoryDocument = gql`
    query GetManagedFilesByDirectory($directoryId: String!, $searchFields: [String!], $searchText: String) {
  getManagedFilesByDirectory(
    directoryId: $directoryId
    searchFields: $searchFields
    searchText: $searchText
  ) {
    contentType
    directoryId
    fileName
    id
    name
    owner
    tags
  }
}
    `;

/**
 * __useGetManagedFilesByDirectoryQuery__
 *
 * To run a query within a React component, call `useGetManagedFilesByDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedFilesByDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedFilesByDirectoryQuery({
 *   variables: {
 *      directoryId: // value for 'directoryId'
 *      searchFields: // value for 'searchFields'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetManagedFilesByDirectoryQuery(baseOptions: Apollo.QueryHookOptions<GetManagedFilesByDirectoryQuery, GetManagedFilesByDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagedFilesByDirectoryQuery, GetManagedFilesByDirectoryQueryVariables>(GetManagedFilesByDirectoryDocument, options);
      }
export function useGetManagedFilesByDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagedFilesByDirectoryQuery, GetManagedFilesByDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagedFilesByDirectoryQuery, GetManagedFilesByDirectoryQueryVariables>(GetManagedFilesByDirectoryDocument, options);
        }
export type GetManagedFilesByDirectoryQueryHookResult = ReturnType<typeof useGetManagedFilesByDirectoryQuery>;
export type GetManagedFilesByDirectoryLazyQueryHookResult = ReturnType<typeof useGetManagedFilesByDirectoryLazyQuery>;
export type GetManagedFilesByDirectoryQueryResult = Apollo.QueryResult<GetManagedFilesByDirectoryQuery, GetManagedFilesByDirectoryQueryVariables>;
export function refetchGetManagedFilesByDirectoryQuery(variables: GetManagedFilesByDirectoryQueryVariables) {
      return { query: GetManagedFilesByDirectoryDocument, variables: variables }
    }
export const GetTagsDocument = gql`
    query GetTags {
  getFileTags
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export function refetchGetTagsQuery(variables?: GetTagsQueryVariables) {
      return { query: GetTagsDocument, variables: variables }
    }
export const ManagedFileDocument = gql`
    query ManagedFile($id: ID!) {
  getManagedFile(id: $id) {
    contentType
    directoryId
    fileName
    id
    name
    owner
    tags
  }
}
    `;

/**
 * __useManagedFileQuery__
 *
 * To run a query within a React component, call `useManagedFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagedFileQuery(baseOptions: Apollo.QueryHookOptions<ManagedFileQuery, ManagedFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagedFileQuery, ManagedFileQueryVariables>(ManagedFileDocument, options);
      }
export function useManagedFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagedFileQuery, ManagedFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagedFileQuery, ManagedFileQueryVariables>(ManagedFileDocument, options);
        }
export type ManagedFileQueryHookResult = ReturnType<typeof useManagedFileQuery>;
export type ManagedFileLazyQueryHookResult = ReturnType<typeof useManagedFileLazyQuery>;
export type ManagedFileQueryResult = Apollo.QueryResult<ManagedFileQuery, ManagedFileQueryVariables>;
export function refetchManagedFileQuery(variables: ManagedFileQueryVariables) {
      return { query: ManagedFileDocument, variables: variables }
    }
export const SaveTaskDocument = gql`
    mutation SaveTask($input: TaskInput!) {
  saveTask(input: $input) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type SaveTaskMutationFn = Apollo.MutationFunction<SaveTaskMutation, SaveTaskMutationVariables>;

/**
 * __useSaveTaskMutation__
 *
 * To run a mutation, you first call `useSaveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskMutation, { data, loading, error }] = useSaveTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskMutation(baseOptions?: Apollo.MutationHookOptions<SaveTaskMutation, SaveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTaskMutation, SaveTaskMutationVariables>(SaveTaskDocument, options);
      }
export type SaveTaskMutationHookResult = ReturnType<typeof useSaveTaskMutation>;
export type SaveTaskMutationResult = Apollo.MutationResult<SaveTaskMutation>;
export type SaveTaskMutationOptions = Apollo.BaseMutationOptions<SaveTaskMutation, SaveTaskMutationVariables>;
export const DeleteVideoTaskDocument = gql`
    mutation DeleteVideoTask($taskId: ID!) {
  deleteVideoTask(taskId: $taskId)
}
    `;
export type DeleteVideoTaskMutationFn = Apollo.MutationFunction<DeleteVideoTaskMutation, DeleteVideoTaskMutationVariables>;

/**
 * __useDeleteVideoTaskMutation__
 *
 * To run a mutation, you first call `useDeleteVideoTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoTaskMutation, { data, loading, error }] = useDeleteVideoTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteVideoTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoTaskMutation, DeleteVideoTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoTaskMutation, DeleteVideoTaskMutationVariables>(DeleteVideoTaskDocument, options);
      }
export type DeleteVideoTaskMutationHookResult = ReturnType<typeof useDeleteVideoTaskMutation>;
export type DeleteVideoTaskMutationResult = Apollo.MutationResult<DeleteVideoTaskMutation>;
export type DeleteVideoTaskMutationOptions = Apollo.BaseMutationOptions<DeleteVideoTaskMutation, DeleteVideoTaskMutationVariables>;
export const TravelTimeDisplayDocument = gql`
    mutation TravelTimeDisplay($requests: [TravelTimeDisplayRequest!]!) {
  displayTravelTimeMessages(requests: $requests) {
    success
  }
}
    `;
export type TravelTimeDisplayMutationFn = Apollo.MutationFunction<TravelTimeDisplayMutation, TravelTimeDisplayMutationVariables>;

/**
 * __useTravelTimeDisplayMutation__
 *
 * To run a mutation, you first call `useTravelTimeDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelTimeDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelTimeDisplayMutation, { data, loading, error }] = useTravelTimeDisplayMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useTravelTimeDisplayMutation(baseOptions?: Apollo.MutationHookOptions<TravelTimeDisplayMutation, TravelTimeDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TravelTimeDisplayMutation, TravelTimeDisplayMutationVariables>(TravelTimeDisplayDocument, options);
      }
export type TravelTimeDisplayMutationHookResult = ReturnType<typeof useTravelTimeDisplayMutation>;
export type TravelTimeDisplayMutationResult = Apollo.MutationResult<TravelTimeDisplayMutation>;
export type TravelTimeDisplayMutationOptions = Apollo.BaseMutationOptions<TravelTimeDisplayMutation, TravelTimeDisplayMutationVariables>;
export const SaveSignMessageDocument = gql`
    mutation SaveSignMessage($input: SignLibraryMessageInput!) {
  saveSignMessage(input: $input) {
    ...SignLibraryMessage
  }
}
    ${SignLibraryMessageFragmentDoc}`;
export type SaveSignMessageMutationFn = Apollo.MutationFunction<SaveSignMessageMutation, SaveSignMessageMutationVariables>;

/**
 * __useSaveSignMessageMutation__
 *
 * To run a mutation, you first call `useSaveSignMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSignMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSignMessageMutation, { data, loading, error }] = useSaveSignMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSignMessageMutation(baseOptions?: Apollo.MutationHookOptions<SaveSignMessageMutation, SaveSignMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSignMessageMutation, SaveSignMessageMutationVariables>(SaveSignMessageDocument, options);
      }
export type SaveSignMessageMutationHookResult = ReturnType<typeof useSaveSignMessageMutation>;
export type SaveSignMessageMutationResult = Apollo.MutationResult<SaveSignMessageMutation>;
export type SaveSignMessageMutationOptions = Apollo.BaseMutationOptions<SaveSignMessageMutation, SaveSignMessageMutationVariables>;
export const SignBlankDocument = gql`
    mutation SignBlank($ids: [ID!]!) {
  signBlank(ids: $ids) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type SignBlankMutationFn = Apollo.MutationFunction<SignBlankMutation, SignBlankMutationVariables>;

/**
 * __useSignBlankMutation__
 *
 * To run a mutation, you first call `useSignBlankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignBlankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signBlankMutation, { data, loading, error }] = useSignBlankMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignBlankMutation(baseOptions?: Apollo.MutationHookOptions<SignBlankMutation, SignBlankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignBlankMutation, SignBlankMutationVariables>(SignBlankDocument, options);
      }
export type SignBlankMutationHookResult = ReturnType<typeof useSignBlankMutation>;
export type SignBlankMutationResult = Apollo.MutationResult<SignBlankMutation>;
export type SignBlankMutationOptions = Apollo.BaseMutationOptions<SignBlankMutation, SignBlankMutationVariables>;
export const SignPollDocument = gql`
    mutation SignPoll($ids: [ID!]!) {
  signPoll(ids: $ids) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type SignPollMutationFn = Apollo.MutationFunction<SignPollMutation, SignPollMutationVariables>;

/**
 * __useSignPollMutation__
 *
 * To run a mutation, you first call `useSignPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPollMutation, { data, loading, error }] = useSignPollMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignPollMutation(baseOptions?: Apollo.MutationHookOptions<SignPollMutation, SignPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignPollMutation, SignPollMutationVariables>(SignPollDocument, options);
      }
export type SignPollMutationHookResult = ReturnType<typeof useSignPollMutation>;
export type SignPollMutationResult = Apollo.MutationResult<SignPollMutation>;
export type SignPollMutationOptions = Apollo.BaseMutationOptions<SignPollMutation, SignPollMutationVariables>;
export const SignDisplayDocument = gql`
    mutation SignDisplay($requests: [SignMessageDisplayRequest!]!) {
  signDisplay(requests: $requests) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type SignDisplayMutationFn = Apollo.MutationFunction<SignDisplayMutation, SignDisplayMutationVariables>;

/**
 * __useSignDisplayMutation__
 *
 * To run a mutation, you first call `useSignDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signDisplayMutation, { data, loading, error }] = useSignDisplayMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useSignDisplayMutation(baseOptions?: Apollo.MutationHookOptions<SignDisplayMutation, SignDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignDisplayMutation, SignDisplayMutationVariables>(SignDisplayDocument, options);
      }
export type SignDisplayMutationHookResult = ReturnType<typeof useSignDisplayMutation>;
export type SignDisplayMutationResult = Apollo.MutationResult<SignDisplayMutation>;
export type SignDisplayMutationOptions = Apollo.BaseMutationOptions<SignDisplayMutation, SignDisplayMutationVariables>;
export const SaveContactMutationDocument = gql`
    mutation SaveContactMutation($contact: ContactInput!) {
  saveContact(contact: $contact) {
    id
    firstName
    lastName
  }
}
    `;
export type SaveContactMutationMutationFn = Apollo.MutationFunction<SaveContactMutationMutation, SaveContactMutationMutationVariables>;

/**
 * __useSaveContactMutationMutation__
 *
 * To run a mutation, you first call `useSaveContactMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContactMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContactMutationMutation, { data, loading, error }] = useSaveContactMutationMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useSaveContactMutationMutation(baseOptions?: Apollo.MutationHookOptions<SaveContactMutationMutation, SaveContactMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveContactMutationMutation, SaveContactMutationMutationVariables>(SaveContactMutationDocument, options);
      }
export type SaveContactMutationMutationHookResult = ReturnType<typeof useSaveContactMutationMutation>;
export type SaveContactMutationMutationResult = Apollo.MutationResult<SaveContactMutationMutation>;
export type SaveContactMutationMutationOptions = Apollo.BaseMutationOptions<SaveContactMutationMutation, SaveContactMutationMutationVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($id: ID!, $module: KineticModule, $centerId: String) {
  deleteFile(id: $id, module: $module, centerId: $centerId)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      module: // value for 'module'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const SignLaneControlDisplayDocument = gql`
    mutation SignLaneControlDisplay($requests: [SignLaneControlDisplayRequest!]!) {
  signLaneControlDisplay(requests: $requests) {
    requestId
    failure
    success
    id
  }
}
    `;
export type SignLaneControlDisplayMutationFn = Apollo.MutationFunction<SignLaneControlDisplayMutation, SignLaneControlDisplayMutationVariables>;

/**
 * __useSignLaneControlDisplayMutation__
 *
 * To run a mutation, you first call `useSignLaneControlDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignLaneControlDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signLaneControlDisplayMutation, { data, loading, error }] = useSignLaneControlDisplayMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useSignLaneControlDisplayMutation(baseOptions?: Apollo.MutationHookOptions<SignLaneControlDisplayMutation, SignLaneControlDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignLaneControlDisplayMutation, SignLaneControlDisplayMutationVariables>(SignLaneControlDisplayDocument, options);
      }
export type SignLaneControlDisplayMutationHookResult = ReturnType<typeof useSignLaneControlDisplayMutation>;
export type SignLaneControlDisplayMutationResult = Apollo.MutationResult<SignLaneControlDisplayMutation>;
export type SignLaneControlDisplayMutationOptions = Apollo.BaseMutationOptions<SignLaneControlDisplayMutation, SignLaneControlDisplayMutationVariables>;
export const SaveDataSourceConfigDocument = gql`
    mutation SaveDataSourceConfig($input: DataSourceConfigInput!) {
  saveDataSourceConfig(input: $input) {
    ...DataSourceConfig
  }
}
    ${DataSourceConfigFragmentDoc}`;
export type SaveDataSourceConfigMutationFn = Apollo.MutationFunction<SaveDataSourceConfigMutation, SaveDataSourceConfigMutationVariables>;

/**
 * __useSaveDataSourceConfigMutation__
 *
 * To run a mutation, you first call `useSaveDataSourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDataSourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDataSourceConfigMutation, { data, loading, error }] = useSaveDataSourceConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDataSourceConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveDataSourceConfigMutation, SaveDataSourceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDataSourceConfigMutation, SaveDataSourceConfigMutationVariables>(SaveDataSourceConfigDocument, options);
      }
export type SaveDataSourceConfigMutationHookResult = ReturnType<typeof useSaveDataSourceConfigMutation>;
export type SaveDataSourceConfigMutationResult = Apollo.MutationResult<SaveDataSourceConfigMutation>;
export type SaveDataSourceConfigMutationOptions = Apollo.BaseMutationOptions<SaveDataSourceConfigMutation, SaveDataSourceConfigMutationVariables>;
export const DeleteDataSourceConfigDocument = gql`
    mutation DeleteDataSourceConfig($selection: [ID!]!) {
  deleteDataSourceConfigs(ids: $selection)
}
    `;
export type DeleteDataSourceConfigMutationFn = Apollo.MutationFunction<DeleteDataSourceConfigMutation, DeleteDataSourceConfigMutationVariables>;

/**
 * __useDeleteDataSourceConfigMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceConfigMutation, { data, loading, error }] = useDeleteDataSourceConfigMutation({
 *   variables: {
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useDeleteDataSourceConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSourceConfigMutation, DeleteDataSourceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSourceConfigMutation, DeleteDataSourceConfigMutationVariables>(DeleteDataSourceConfigDocument, options);
      }
export type DeleteDataSourceConfigMutationHookResult = ReturnType<typeof useDeleteDataSourceConfigMutation>;
export type DeleteDataSourceConfigMutationResult = Apollo.MutationResult<DeleteDataSourceConfigMutation>;
export type DeleteDataSourceConfigMutationOptions = Apollo.BaseMutationOptions<DeleteDataSourceConfigMutation, DeleteDataSourceConfigMutationVariables>;
export const SaveDataSourceDocument = gql`
    mutation SaveDataSource($input: DataSourceInput!) {
  saveDataSource(input: $input) {
    ...DataSource
  }
}
    ${DataSourceFragmentDoc}`;
export type SaveDataSourceMutationFn = Apollo.MutationFunction<SaveDataSourceMutation, SaveDataSourceMutationVariables>;

/**
 * __useSaveDataSourceMutation__
 *
 * To run a mutation, you first call `useSaveDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDataSourceMutation, { data, loading, error }] = useSaveDataSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<SaveDataSourceMutation, SaveDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDataSourceMutation, SaveDataSourceMutationVariables>(SaveDataSourceDocument, options);
      }
export type SaveDataSourceMutationHookResult = ReturnType<typeof useSaveDataSourceMutation>;
export type SaveDataSourceMutationResult = Apollo.MutationResult<SaveDataSourceMutation>;
export type SaveDataSourceMutationOptions = Apollo.BaseMutationOptions<SaveDataSourceMutation, SaveDataSourceMutationVariables>;
export const DeleteDataSourceDocument = gql`
    mutation DeleteDataSource($selection: [ID!]!) {
  deleteDataSources(ids: $selection)
}
    `;
export type DeleteDataSourceMutationFn = Apollo.MutationFunction<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;

/**
 * __useDeleteDataSourceMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceMutation, { data, loading, error }] = useDeleteDataSourceMutation({
 *   variables: {
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useDeleteDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>(DeleteDataSourceDocument, options);
      }
export type DeleteDataSourceMutationHookResult = ReturnType<typeof useDeleteDataSourceMutation>;
export type DeleteDataSourceMutationResult = Apollo.MutationResult<DeleteDataSourceMutation>;
export type DeleteDataSourceMutationOptions = Apollo.BaseMutationOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;
export const SaveActivityLogDocument = gql`
    mutation SaveActivityLog($input: ActivityLogInput!) {
  addActivityLog(input: $input) {
    id
  }
}
    `;
export type SaveActivityLogMutationFn = Apollo.MutationFunction<SaveActivityLogMutation, SaveActivityLogMutationVariables>;

/**
 * __useSaveActivityLogMutation__
 *
 * To run a mutation, you first call `useSaveActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityLogMutation, { data, loading, error }] = useSaveActivityLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveActivityLogMutation(baseOptions?: Apollo.MutationHookOptions<SaveActivityLogMutation, SaveActivityLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveActivityLogMutation, SaveActivityLogMutationVariables>(SaveActivityLogDocument, options);
      }
export type SaveActivityLogMutationHookResult = ReturnType<typeof useSaveActivityLogMutation>;
export type SaveActivityLogMutationResult = Apollo.MutationResult<SaveActivityLogMutation>;
export type SaveActivityLogMutationOptions = Apollo.BaseMutationOptions<SaveActivityLogMutation, SaveActivityLogMutationVariables>;
export const PanTiltVideoCameraDocument = gql`
    mutation PanTiltVideoCamera($cameraPanTiltInput: CameraPanTiltInput!) {
  panTiltCamera(cameraPanTiltInput: $cameraPanTiltInput) {
    success
  }
}
    `;
export type PanTiltVideoCameraMutationFn = Apollo.MutationFunction<PanTiltVideoCameraMutation, PanTiltVideoCameraMutationVariables>;

/**
 * __usePanTiltVideoCameraMutation__
 *
 * To run a mutation, you first call `usePanTiltVideoCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanTiltVideoCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panTiltVideoCameraMutation, { data, loading, error }] = usePanTiltVideoCameraMutation({
 *   variables: {
 *      cameraPanTiltInput: // value for 'cameraPanTiltInput'
 *   },
 * });
 */
export function usePanTiltVideoCameraMutation(baseOptions?: Apollo.MutationHookOptions<PanTiltVideoCameraMutation, PanTiltVideoCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PanTiltVideoCameraMutation, PanTiltVideoCameraMutationVariables>(PanTiltVideoCameraDocument, options);
      }
export type PanTiltVideoCameraMutationHookResult = ReturnType<typeof usePanTiltVideoCameraMutation>;
export type PanTiltVideoCameraMutationResult = Apollo.MutationResult<PanTiltVideoCameraMutation>;
export type PanTiltVideoCameraMutationOptions = Apollo.BaseMutationOptions<PanTiltVideoCameraMutation, PanTiltVideoCameraMutationVariables>;
export const VideoCameraZoomDocument = gql`
    mutation VideoCameraZoom($cameraZoomInput: CameraZoomInput!) {
  zoomCamera(cameraZoomInput: $cameraZoomInput) {
    success
  }
}
    `;
export type VideoCameraZoomMutationFn = Apollo.MutationFunction<VideoCameraZoomMutation, VideoCameraZoomMutationVariables>;

/**
 * __useVideoCameraZoomMutation__
 *
 * To run a mutation, you first call `useVideoCameraZoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCameraZoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCameraZoomMutation, { data, loading, error }] = useVideoCameraZoomMutation({
 *   variables: {
 *      cameraZoomInput: // value for 'cameraZoomInput'
 *   },
 * });
 */
export function useVideoCameraZoomMutation(baseOptions?: Apollo.MutationHookOptions<VideoCameraZoomMutation, VideoCameraZoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCameraZoomMutation, VideoCameraZoomMutationVariables>(VideoCameraZoomDocument, options);
      }
export type VideoCameraZoomMutationHookResult = ReturnType<typeof useVideoCameraZoomMutation>;
export type VideoCameraZoomMutationResult = Apollo.MutationResult<VideoCameraZoomMutation>;
export type VideoCameraZoomMutationOptions = Apollo.BaseMutationOptions<VideoCameraZoomMutation, VideoCameraZoomMutationVariables>;
export const VideoCameraPublicFeedDocument = gql`
    mutation VideoCameraPublicFeed($cameraPublicFeedInput: CameraPublicFeedInput!) {
  cameraPublicFeed(cameraPublicFeedInput: $cameraPublicFeedInput) {
    success
  }
}
    `;
export type VideoCameraPublicFeedMutationFn = Apollo.MutationFunction<VideoCameraPublicFeedMutation, VideoCameraPublicFeedMutationVariables>;

/**
 * __useVideoCameraPublicFeedMutation__
 *
 * To run a mutation, you first call `useVideoCameraPublicFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCameraPublicFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCameraPublicFeedMutation, { data, loading, error }] = useVideoCameraPublicFeedMutation({
 *   variables: {
 *      cameraPublicFeedInput: // value for 'cameraPublicFeedInput'
 *   },
 * });
 */
export function useVideoCameraPublicFeedMutation(baseOptions?: Apollo.MutationHookOptions<VideoCameraPublicFeedMutation, VideoCameraPublicFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCameraPublicFeedMutation, VideoCameraPublicFeedMutationVariables>(VideoCameraPublicFeedDocument, options);
      }
export type VideoCameraPublicFeedMutationHookResult = ReturnType<typeof useVideoCameraPublicFeedMutation>;
export type VideoCameraPublicFeedMutationResult = Apollo.MutationResult<VideoCameraPublicFeedMutation>;
export type VideoCameraPublicFeedMutationOptions = Apollo.BaseMutationOptions<VideoCameraPublicFeedMutation, VideoCameraPublicFeedMutationVariables>;
export const SetAsHomeCameraPresetDocument = gql`
    mutation SetAsHomeCameraPreset($homePresetInput: HomePresetInput!) {
  setAsHomePreset(homePresetInput: $homePresetInput) {
    id
    presetIndex
    name
    isHomePreset
  }
}
    `;
export type SetAsHomeCameraPresetMutationFn = Apollo.MutationFunction<SetAsHomeCameraPresetMutation, SetAsHomeCameraPresetMutationVariables>;

/**
 * __useSetAsHomeCameraPresetMutation__
 *
 * To run a mutation, you first call `useSetAsHomeCameraPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAsHomeCameraPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAsHomeCameraPresetMutation, { data, loading, error }] = useSetAsHomeCameraPresetMutation({
 *   variables: {
 *      homePresetInput: // value for 'homePresetInput'
 *   },
 * });
 */
export function useSetAsHomeCameraPresetMutation(baseOptions?: Apollo.MutationHookOptions<SetAsHomeCameraPresetMutation, SetAsHomeCameraPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAsHomeCameraPresetMutation, SetAsHomeCameraPresetMutationVariables>(SetAsHomeCameraPresetDocument, options);
      }
export type SetAsHomeCameraPresetMutationHookResult = ReturnType<typeof useSetAsHomeCameraPresetMutation>;
export type SetAsHomeCameraPresetMutationResult = Apollo.MutationResult<SetAsHomeCameraPresetMutation>;
export type SetAsHomeCameraPresetMutationOptions = Apollo.BaseMutationOptions<SetAsHomeCameraPresetMutation, SetAsHomeCameraPresetMutationVariables>;
export const VideoCameraGoToPresetDocument = gql`
    mutation VideoCameraGoToPreset($cameraId: String!, $presetId: String!) {
  gotoPreset(cameraGotoPresetInput: {cameraId: $cameraId, presetId: $presetId}) {
    success
    requestId
    id
    failure
  }
}
    `;
export type VideoCameraGoToPresetMutationFn = Apollo.MutationFunction<VideoCameraGoToPresetMutation, VideoCameraGoToPresetMutationVariables>;

/**
 * __useVideoCameraGoToPresetMutation__
 *
 * To run a mutation, you first call `useVideoCameraGoToPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCameraGoToPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCameraGoToPresetMutation, { data, loading, error }] = useVideoCameraGoToPresetMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      presetId: // value for 'presetId'
 *   },
 * });
 */
export function useVideoCameraGoToPresetMutation(baseOptions?: Apollo.MutationHookOptions<VideoCameraGoToPresetMutation, VideoCameraGoToPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCameraGoToPresetMutation, VideoCameraGoToPresetMutationVariables>(VideoCameraGoToPresetDocument, options);
      }
export type VideoCameraGoToPresetMutationHookResult = ReturnType<typeof useVideoCameraGoToPresetMutation>;
export type VideoCameraGoToPresetMutationResult = Apollo.MutationResult<VideoCameraGoToPresetMutation>;
export type VideoCameraGoToPresetMutationOptions = Apollo.BaseMutationOptions<VideoCameraGoToPresetMutation, VideoCameraGoToPresetMutationVariables>;
export const SaveVideoCameraPresetDocument = gql`
    mutation SaveVideoCameraPreset($input: PresetInput!) {
  savePreset(input: $input) {
    id
    presetIndex
    name
  }
}
    `;
export type SaveVideoCameraPresetMutationFn = Apollo.MutationFunction<SaveVideoCameraPresetMutation, SaveVideoCameraPresetMutationVariables>;

/**
 * __useSaveVideoCameraPresetMutation__
 *
 * To run a mutation, you first call `useSaveVideoCameraPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVideoCameraPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVideoCameraPresetMutation, { data, loading, error }] = useSaveVideoCameraPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveVideoCameraPresetMutation(baseOptions?: Apollo.MutationHookOptions<SaveVideoCameraPresetMutation, SaveVideoCameraPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVideoCameraPresetMutation, SaveVideoCameraPresetMutationVariables>(SaveVideoCameraPresetDocument, options);
      }
export type SaveVideoCameraPresetMutationHookResult = ReturnType<typeof useSaveVideoCameraPresetMutation>;
export type SaveVideoCameraPresetMutationResult = Apollo.MutationResult<SaveVideoCameraPresetMutation>;
export type SaveVideoCameraPresetMutationOptions = Apollo.BaseMutationOptions<SaveVideoCameraPresetMutation, SaveVideoCameraPresetMutationVariables>;
export const UseVideoCameraCapabilityDocument = gql`
    mutation UseVideoCameraCapability($cameraId: ID!, $capability: CameraCapability!, $option: String!) {
  useCameraCapability(
    cameraId: $cameraId
    capability: $capability
    option: $option
  ) {
    success
  }
}
    `;
export type UseVideoCameraCapabilityMutationFn = Apollo.MutationFunction<UseVideoCameraCapabilityMutation, UseVideoCameraCapabilityMutationVariables>;

/**
 * __useUseVideoCameraCapabilityMutation__
 *
 * To run a mutation, you first call `useUseVideoCameraCapabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseVideoCameraCapabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useVideoCameraCapabilityMutation, { data, loading, error }] = useUseVideoCameraCapabilityMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      capability: // value for 'capability'
 *      option: // value for 'option'
 *   },
 * });
 */
export function useUseVideoCameraCapabilityMutation(baseOptions?: Apollo.MutationHookOptions<UseVideoCameraCapabilityMutation, UseVideoCameraCapabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseVideoCameraCapabilityMutation, UseVideoCameraCapabilityMutationVariables>(UseVideoCameraCapabilityDocument, options);
      }
export type UseVideoCameraCapabilityMutationHookResult = ReturnType<typeof useUseVideoCameraCapabilityMutation>;
export type UseVideoCameraCapabilityMutationResult = Apollo.MutationResult<UseVideoCameraCapabilityMutation>;
export type UseVideoCameraCapabilityMutationOptions = Apollo.BaseMutationOptions<UseVideoCameraCapabilityMutation, UseVideoCameraCapabilityMutationVariables>;
export const EnableVideoCameraAutoFocusDocument = gql`
    mutation EnableVideoCameraAutoFocus($cameraAutoFocusInput: CameraAutoFocusInput!) {
  enableCameraAutoFocus(cameraAutoFocusInput: $cameraAutoFocusInput) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type EnableVideoCameraAutoFocusMutationFn = Apollo.MutationFunction<EnableVideoCameraAutoFocusMutation, EnableVideoCameraAutoFocusMutationVariables>;

/**
 * __useEnableVideoCameraAutoFocusMutation__
 *
 * To run a mutation, you first call `useEnableVideoCameraAutoFocusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableVideoCameraAutoFocusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableVideoCameraAutoFocusMutation, { data, loading, error }] = useEnableVideoCameraAutoFocusMutation({
 *   variables: {
 *      cameraAutoFocusInput: // value for 'cameraAutoFocusInput'
 *   },
 * });
 */
export function useEnableVideoCameraAutoFocusMutation(baseOptions?: Apollo.MutationHookOptions<EnableVideoCameraAutoFocusMutation, EnableVideoCameraAutoFocusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableVideoCameraAutoFocusMutation, EnableVideoCameraAutoFocusMutationVariables>(EnableVideoCameraAutoFocusDocument, options);
      }
export type EnableVideoCameraAutoFocusMutationHookResult = ReturnType<typeof useEnableVideoCameraAutoFocusMutation>;
export type EnableVideoCameraAutoFocusMutationResult = Apollo.MutationResult<EnableVideoCameraAutoFocusMutation>;
export type EnableVideoCameraAutoFocusMutationOptions = Apollo.BaseMutationOptions<EnableVideoCameraAutoFocusMutation, EnableVideoCameraAutoFocusMutationVariables>;
export const EnableVideoCameraAutoIrisDocument = gql`
    mutation EnableVideoCameraAutoIris($cameraAutoIrisInput: CameraAutoIrisInput!) {
  enableCameraAutoIris(cameraAutoIrisInput: $cameraAutoIrisInput) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type EnableVideoCameraAutoIrisMutationFn = Apollo.MutationFunction<EnableVideoCameraAutoIrisMutation, EnableVideoCameraAutoIrisMutationVariables>;

/**
 * __useEnableVideoCameraAutoIrisMutation__
 *
 * To run a mutation, you first call `useEnableVideoCameraAutoIrisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableVideoCameraAutoIrisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableVideoCameraAutoIrisMutation, { data, loading, error }] = useEnableVideoCameraAutoIrisMutation({
 *   variables: {
 *      cameraAutoIrisInput: // value for 'cameraAutoIrisInput'
 *   },
 * });
 */
export function useEnableVideoCameraAutoIrisMutation(baseOptions?: Apollo.MutationHookOptions<EnableVideoCameraAutoIrisMutation, EnableVideoCameraAutoIrisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableVideoCameraAutoIrisMutation, EnableVideoCameraAutoIrisMutationVariables>(EnableVideoCameraAutoIrisDocument, options);
      }
export type EnableVideoCameraAutoIrisMutationHookResult = ReturnType<typeof useEnableVideoCameraAutoIrisMutation>;
export type EnableVideoCameraAutoIrisMutationResult = Apollo.MutationResult<EnableVideoCameraAutoIrisMutation>;
export type EnableVideoCameraAutoIrisMutationOptions = Apollo.BaseMutationOptions<EnableVideoCameraAutoIrisMutation, EnableVideoCameraAutoIrisMutationVariables>;
export const FocusVideoCameraDocument = gql`
    mutation FocusVideoCamera($cameraFocusInput: CameraFocusInput!) {
  focusCamera(cameraFocusInput: $cameraFocusInput) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type FocusVideoCameraMutationFn = Apollo.MutationFunction<FocusVideoCameraMutation, FocusVideoCameraMutationVariables>;

/**
 * __useFocusVideoCameraMutation__
 *
 * To run a mutation, you first call `useFocusVideoCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFocusVideoCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [focusVideoCameraMutation, { data, loading, error }] = useFocusVideoCameraMutation({
 *   variables: {
 *      cameraFocusInput: // value for 'cameraFocusInput'
 *   },
 * });
 */
export function useFocusVideoCameraMutation(baseOptions?: Apollo.MutationHookOptions<FocusVideoCameraMutation, FocusVideoCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FocusVideoCameraMutation, FocusVideoCameraMutationVariables>(FocusVideoCameraDocument, options);
      }
export type FocusVideoCameraMutationHookResult = ReturnType<typeof useFocusVideoCameraMutation>;
export type FocusVideoCameraMutationResult = Apollo.MutationResult<FocusVideoCameraMutation>;
export type FocusVideoCameraMutationOptions = Apollo.BaseMutationOptions<FocusVideoCameraMutation, FocusVideoCameraMutationVariables>;
export const IrisVideoCameraDocument = gql`
    mutation IrisVideoCamera($cameraIrisInput: CameraIrisInput!) {
  irisCamera(cameraIrisInput: $cameraIrisInput) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type IrisVideoCameraMutationFn = Apollo.MutationFunction<IrisVideoCameraMutation, IrisVideoCameraMutationVariables>;

/**
 * __useIrisVideoCameraMutation__
 *
 * To run a mutation, you first call `useIrisVideoCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIrisVideoCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [irisVideoCameraMutation, { data, loading, error }] = useIrisVideoCameraMutation({
 *   variables: {
 *      cameraIrisInput: // value for 'cameraIrisInput'
 *   },
 * });
 */
export function useIrisVideoCameraMutation(baseOptions?: Apollo.MutationHookOptions<IrisVideoCameraMutation, IrisVideoCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IrisVideoCameraMutation, IrisVideoCameraMutationVariables>(IrisVideoCameraDocument, options);
      }
export type IrisVideoCameraMutationHookResult = ReturnType<typeof useIrisVideoCameraMutation>;
export type IrisVideoCameraMutationResult = Apollo.MutationResult<IrisVideoCameraMutation>;
export type IrisVideoCameraMutationOptions = Apollo.BaseMutationOptions<IrisVideoCameraMutation, IrisVideoCameraMutationVariables>;
export const VideoCameraRecordingDocument = gql`
    mutation VideoCameraRecording($cameraId: String!, $streamRecorderId: String!, $streamAccessType: VideoStreamAccessType!, $recordingType: RecordingType, $start: Boolean!) {
  cameraRecording(
    cameraStreamRecordingInput: {cameraId: $cameraId, recordingInitiatedBy: USER, streamRecorderId: $streamRecorderId, streamAccessType: $streamAccessType, recordingType: $recordingType, start: $start}
  ) {
    ...JobRequest
  }
}
    ${JobRequestFragmentDoc}`;
export type VideoCameraRecordingMutationFn = Apollo.MutationFunction<VideoCameraRecordingMutation, VideoCameraRecordingMutationVariables>;

/**
 * __useVideoCameraRecordingMutation__
 *
 * To run a mutation, you first call `useVideoCameraRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCameraRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCameraRecordingMutation, { data, loading, error }] = useVideoCameraRecordingMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      streamRecorderId: // value for 'streamRecorderId'
 *      streamAccessType: // value for 'streamAccessType'
 *      recordingType: // value for 'recordingType'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useVideoCameraRecordingMutation(baseOptions?: Apollo.MutationHookOptions<VideoCameraRecordingMutation, VideoCameraRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCameraRecordingMutation, VideoCameraRecordingMutationVariables>(VideoCameraRecordingDocument, options);
      }
export type VideoCameraRecordingMutationHookResult = ReturnType<typeof useVideoCameraRecordingMutation>;
export type VideoCameraRecordingMutationResult = Apollo.MutationResult<VideoCameraRecordingMutation>;
export type VideoCameraRecordingMutationOptions = Apollo.BaseMutationOptions<VideoCameraRecordingMutation, VideoCameraRecordingMutationVariables>;
export const VideoCameraExplicitLockDocument = gql`
    mutation VideoCameraExplicitLock($cameraId: ID!) {
  cameraExplicitLock(cameraId: $cameraId)
}
    `;
export type VideoCameraExplicitLockMutationFn = Apollo.MutationFunction<VideoCameraExplicitLockMutation, VideoCameraExplicitLockMutationVariables>;

/**
 * __useVideoCameraExplicitLockMutation__
 *
 * To run a mutation, you first call `useVideoCameraExplicitLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCameraExplicitLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCameraExplicitLockMutation, { data, loading, error }] = useVideoCameraExplicitLockMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useVideoCameraExplicitLockMutation(baseOptions?: Apollo.MutationHookOptions<VideoCameraExplicitLockMutation, VideoCameraExplicitLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCameraExplicitLockMutation, VideoCameraExplicitLockMutationVariables>(VideoCameraExplicitLockDocument, options);
      }
export type VideoCameraExplicitLockMutationHookResult = ReturnType<typeof useVideoCameraExplicitLockMutation>;
export type VideoCameraExplicitLockMutationResult = Apollo.MutationResult<VideoCameraExplicitLockMutation>;
export type VideoCameraExplicitLockMutationOptions = Apollo.BaseMutationOptions<VideoCameraExplicitLockMutation, VideoCameraExplicitLockMutationVariables>;
export const UnlockPtzVideoCameraDocument = gql`
    mutation unlockPTZVideoCamera($cameraId: ID!) {
  unlockPTZCamera(cameraId: $cameraId)
}
    `;
export type UnlockPtzVideoCameraMutationFn = Apollo.MutationFunction<UnlockPtzVideoCameraMutation, UnlockPtzVideoCameraMutationVariables>;

/**
 * __useUnlockPtzVideoCameraMutation__
 *
 * To run a mutation, you first call `useUnlockPtzVideoCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockPtzVideoCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockPtzVideoCameraMutation, { data, loading, error }] = useUnlockPtzVideoCameraMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useUnlockPtzVideoCameraMutation(baseOptions?: Apollo.MutationHookOptions<UnlockPtzVideoCameraMutation, UnlockPtzVideoCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockPtzVideoCameraMutation, UnlockPtzVideoCameraMutationVariables>(UnlockPtzVideoCameraDocument, options);
      }
export type UnlockPtzVideoCameraMutationHookResult = ReturnType<typeof useUnlockPtzVideoCameraMutation>;
export type UnlockPtzVideoCameraMutationResult = Apollo.MutationResult<UnlockPtzVideoCameraMutation>;
export type UnlockPtzVideoCameraMutationOptions = Apollo.BaseMutationOptions<UnlockPtzVideoCameraMutation, UnlockPtzVideoCameraMutationVariables>;
export const ChangeVideoTourStateDocument = gql`
    mutation ChangeVideoTourState($tourId: ID!, $paused: Boolean) {
  changeTourState(tourId: $tourId, paused: $paused)
}
    `;
export type ChangeVideoTourStateMutationFn = Apollo.MutationFunction<ChangeVideoTourStateMutation, ChangeVideoTourStateMutationVariables>;

/**
 * __useChangeVideoTourStateMutation__
 *
 * To run a mutation, you first call `useChangeVideoTourStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVideoTourStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVideoTourStateMutation, { data, loading, error }] = useChangeVideoTourStateMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      paused: // value for 'paused'
 *   },
 * });
 */
export function useChangeVideoTourStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVideoTourStateMutation, ChangeVideoTourStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVideoTourStateMutation, ChangeVideoTourStateMutationVariables>(ChangeVideoTourStateDocument, options);
      }
export type ChangeVideoTourStateMutationHookResult = ReturnType<typeof useChangeVideoTourStateMutation>;
export type ChangeVideoTourStateMutationResult = Apollo.MutationResult<ChangeVideoTourStateMutation>;
export type ChangeVideoTourStateMutationOptions = Apollo.BaseMutationOptions<ChangeVideoTourStateMutation, ChangeVideoTourStateMutationVariables>;
export const RemoveUserPictureDocument = gql`
    mutation RemoveUserPicture {
  removeUserPicture
}
    `;
export type RemoveUserPictureMutationFn = Apollo.MutationFunction<RemoveUserPictureMutation, RemoveUserPictureMutationVariables>;

/**
 * __useRemoveUserPictureMutation__
 *
 * To run a mutation, you first call `useRemoveUserPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPictureMutation, { data, loading, error }] = useRemoveUserPictureMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveUserPictureMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserPictureMutation, RemoveUserPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserPictureMutation, RemoveUserPictureMutationVariables>(RemoveUserPictureDocument, options);
      }
export type RemoveUserPictureMutationHookResult = ReturnType<typeof useRemoveUserPictureMutation>;
export type RemoveUserPictureMutationResult = Apollo.MutationResult<RemoveUserPictureMutation>;
export type RemoveUserPictureMutationOptions = Apollo.BaseMutationOptions<RemoveUserPictureMutation, RemoveUserPictureMutationVariables>;
export const SaveCommunicationLogDocument = gql`
    mutation SaveCommunicationLog($input: CommunicationLogInput!) {
  saveCommunicationLog(input: $input) {
    ...CommunicationLog
  }
}
    ${CommunicationLogFragmentDoc}`;
export type SaveCommunicationLogMutationFn = Apollo.MutationFunction<SaveCommunicationLogMutation, SaveCommunicationLogMutationVariables>;

/**
 * __useSaveCommunicationLogMutation__
 *
 * To run a mutation, you first call `useSaveCommunicationLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCommunicationLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCommunicationLogMutation, { data, loading, error }] = useSaveCommunicationLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCommunicationLogMutation(baseOptions?: Apollo.MutationHookOptions<SaveCommunicationLogMutation, SaveCommunicationLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCommunicationLogMutation, SaveCommunicationLogMutationVariables>(SaveCommunicationLogDocument, options);
      }
export type SaveCommunicationLogMutationHookResult = ReturnType<typeof useSaveCommunicationLogMutation>;
export type SaveCommunicationLogMutationResult = Apollo.MutationResult<SaveCommunicationLogMutation>;
export type SaveCommunicationLogMutationOptions = Apollo.BaseMutationOptions<SaveCommunicationLogMutation, SaveCommunicationLogMutationVariables>;
export const DeleteUserTenantPreferenceDocument = gql`
    mutation DeleteUserTenantPreference($key: ID!) {
  deleteUserPreference(key: $key)
}
    `;
export type DeleteUserTenantPreferenceMutationFn = Apollo.MutationFunction<DeleteUserTenantPreferenceMutation, DeleteUserTenantPreferenceMutationVariables>;

/**
 * __useDeleteUserTenantPreferenceMutation__
 *
 * To run a mutation, you first call `useDeleteUserTenantPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTenantPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTenantPreferenceMutation, { data, loading, error }] = useDeleteUserTenantPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteUserTenantPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserTenantPreferenceMutation, DeleteUserTenantPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserTenantPreferenceMutation, DeleteUserTenantPreferenceMutationVariables>(DeleteUserTenantPreferenceDocument, options);
      }
export type DeleteUserTenantPreferenceMutationHookResult = ReturnType<typeof useDeleteUserTenantPreferenceMutation>;
export type DeleteUserTenantPreferenceMutationResult = Apollo.MutationResult<DeleteUserTenantPreferenceMutation>;
export type DeleteUserTenantPreferenceMutationOptions = Apollo.BaseMutationOptions<DeleteUserTenantPreferenceMutation, DeleteUserTenantPreferenceMutationVariables>;
export const DeleteUserKineticPreferenceDocument = gql`
    mutation DeleteUserKineticPreference($key: ID!) {
  deleteKineticUserPreference(key: $key)
}
    `;
export type DeleteUserKineticPreferenceMutationFn = Apollo.MutationFunction<DeleteUserKineticPreferenceMutation, DeleteUserKineticPreferenceMutationVariables>;

/**
 * __useDeleteUserKineticPreferenceMutation__
 *
 * To run a mutation, you first call `useDeleteUserKineticPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserKineticPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserKineticPreferenceMutation, { data, loading, error }] = useDeleteUserKineticPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteUserKineticPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserKineticPreferenceMutation, DeleteUserKineticPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserKineticPreferenceMutation, DeleteUserKineticPreferenceMutationVariables>(DeleteUserKineticPreferenceDocument, options);
      }
export type DeleteUserKineticPreferenceMutationHookResult = ReturnType<typeof useDeleteUserKineticPreferenceMutation>;
export type DeleteUserKineticPreferenceMutationResult = Apollo.MutationResult<DeleteUserKineticPreferenceMutation>;
export type DeleteUserKineticPreferenceMutationOptions = Apollo.BaseMutationOptions<DeleteUserKineticPreferenceMutation, DeleteUserKineticPreferenceMutationVariables>;
export const DeleteOrganizationPreferenceDocument = gql`
    mutation DeleteOrganizationPreference($key: ID!) {
  deleteOrganizationPreference(key: $key)
}
    `;
export type DeleteOrganizationPreferenceMutationFn = Apollo.MutationFunction<DeleteOrganizationPreferenceMutation, DeleteOrganizationPreferenceMutationVariables>;

/**
 * __useDeleteOrganizationPreferenceMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationPreferenceMutation, { data, loading, error }] = useDeleteOrganizationPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteOrganizationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationPreferenceMutation, DeleteOrganizationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationPreferenceMutation, DeleteOrganizationPreferenceMutationVariables>(DeleteOrganizationPreferenceDocument, options);
      }
export type DeleteOrganizationPreferenceMutationHookResult = ReturnType<typeof useDeleteOrganizationPreferenceMutation>;
export type DeleteOrganizationPreferenceMutationResult = Apollo.MutationResult<DeleteOrganizationPreferenceMutation>;
export type DeleteOrganizationPreferenceMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationPreferenceMutation, DeleteOrganizationPreferenceMutationVariables>;
export const UpdatePartialKineticUserPreferenceDocument = gql`
    mutation UpdatePartialKineticUserPreference($key: ID!, $value: JSON!) {
  updatePartialUserPreference(key: $key, value: $value)
}
    `;
export type UpdatePartialKineticUserPreferenceMutationFn = Apollo.MutationFunction<UpdatePartialKineticUserPreferenceMutation, UpdatePartialKineticUserPreferenceMutationVariables>;

/**
 * __useUpdatePartialKineticUserPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePartialKineticUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartialKineticUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartialKineticUserPreferenceMutation, { data, loading, error }] = useUpdatePartialKineticUserPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdatePartialKineticUserPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartialKineticUserPreferenceMutation, UpdatePartialKineticUserPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartialKineticUserPreferenceMutation, UpdatePartialKineticUserPreferenceMutationVariables>(UpdatePartialKineticUserPreferenceDocument, options);
      }
export type UpdatePartialKineticUserPreferenceMutationHookResult = ReturnType<typeof useUpdatePartialKineticUserPreferenceMutation>;
export type UpdatePartialKineticUserPreferenceMutationResult = Apollo.MutationResult<UpdatePartialKineticUserPreferenceMutation>;
export type UpdatePartialKineticUserPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdatePartialKineticUserPreferenceMutation, UpdatePartialKineticUserPreferenceMutationVariables>;
export const RemovePartialKineticUserPreferenceDocument = gql`
    mutation RemovePartialKineticUserPreference($key: ID!, $valueId: String!) {
  removePartialUserPreference(key: $key, valueId: $valueId)
}
    `;
export type RemovePartialKineticUserPreferenceMutationFn = Apollo.MutationFunction<RemovePartialKineticUserPreferenceMutation, RemovePartialKineticUserPreferenceMutationVariables>;

/**
 * __useRemovePartialKineticUserPreferenceMutation__
 *
 * To run a mutation, you first call `useRemovePartialKineticUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartialKineticUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartialKineticUserPreferenceMutation, { data, loading, error }] = useRemovePartialKineticUserPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *      valueId: // value for 'valueId'
 *   },
 * });
 */
export function useRemovePartialKineticUserPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartialKineticUserPreferenceMutation, RemovePartialKineticUserPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePartialKineticUserPreferenceMutation, RemovePartialKineticUserPreferenceMutationVariables>(RemovePartialKineticUserPreferenceDocument, options);
      }
export type RemovePartialKineticUserPreferenceMutationHookResult = ReturnType<typeof useRemovePartialKineticUserPreferenceMutation>;
export type RemovePartialKineticUserPreferenceMutationResult = Apollo.MutationResult<RemovePartialKineticUserPreferenceMutation>;
export type RemovePartialKineticUserPreferenceMutationOptions = Apollo.BaseMutationOptions<RemovePartialKineticUserPreferenceMutation, RemovePartialKineticUserPreferenceMutationVariables>;
export const SaveUserPrefDocument = gql`
    mutation SaveUserPref($key: ID!, $value: JSON!) {
  saveUserPreference(key: $key, value: $value)
}
    `;
export type SaveUserPrefMutationFn = Apollo.MutationFunction<SaveUserPrefMutation, SaveUserPrefMutationVariables>;

/**
 * __useSaveUserPrefMutation__
 *
 * To run a mutation, you first call `useSaveUserPrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserPrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserPrefMutation, { data, loading, error }] = useSaveUserPrefMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveUserPrefMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserPrefMutation, SaveUserPrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserPrefMutation, SaveUserPrefMutationVariables>(SaveUserPrefDocument, options);
      }
export type SaveUserPrefMutationHookResult = ReturnType<typeof useSaveUserPrefMutation>;
export type SaveUserPrefMutationResult = Apollo.MutationResult<SaveUserPrefMutation>;
export type SaveUserPrefMutationOptions = Apollo.BaseMutationOptions<SaveUserPrefMutation, SaveUserPrefMutationVariables>;
export const SaveKineticUserPrefMutationDocument = gql`
    mutation SaveKineticUserPrefMutation($key: ID!, $value: JSON!) {
  saveKineticUserPreference(key: $key, value: $value)
}
    `;
export type SaveKineticUserPrefMutationMutationFn = Apollo.MutationFunction<SaveKineticUserPrefMutationMutation, SaveKineticUserPrefMutationMutationVariables>;

/**
 * __useSaveKineticUserPrefMutationMutation__
 *
 * To run a mutation, you first call `useSaveKineticUserPrefMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveKineticUserPrefMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveKineticUserPrefMutationMutation, { data, loading, error }] = useSaveKineticUserPrefMutationMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveKineticUserPrefMutationMutation(baseOptions?: Apollo.MutationHookOptions<SaveKineticUserPrefMutationMutation, SaveKineticUserPrefMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveKineticUserPrefMutationMutation, SaveKineticUserPrefMutationMutationVariables>(SaveKineticUserPrefMutationDocument, options);
      }
export type SaveKineticUserPrefMutationMutationHookResult = ReturnType<typeof useSaveKineticUserPrefMutationMutation>;
export type SaveKineticUserPrefMutationMutationResult = Apollo.MutationResult<SaveKineticUserPrefMutationMutation>;
export type SaveKineticUserPrefMutationMutationOptions = Apollo.BaseMutationOptions<SaveKineticUserPrefMutationMutation, SaveKineticUserPrefMutationMutationVariables>;
export const SaveOrganizationPreferenceDocument = gql`
    mutation SaveOrganizationPreference($key: ID!, $value: JSON!) {
  saveOrganizationPreference(key: $key, value: $value)
}
    `;
export type SaveOrganizationPreferenceMutationFn = Apollo.MutationFunction<SaveOrganizationPreferenceMutation, SaveOrganizationPreferenceMutationVariables>;

/**
 * __useSaveOrganizationPreferenceMutation__
 *
 * To run a mutation, you first call `useSaveOrganizationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrganizationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrganizationPreferenceMutation, { data, loading, error }] = useSaveOrganizationPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveOrganizationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<SaveOrganizationPreferenceMutation, SaveOrganizationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOrganizationPreferenceMutation, SaveOrganizationPreferenceMutationVariables>(SaveOrganizationPreferenceDocument, options);
      }
export type SaveOrganizationPreferenceMutationHookResult = ReturnType<typeof useSaveOrganizationPreferenceMutation>;
export type SaveOrganizationPreferenceMutationResult = Apollo.MutationResult<SaveOrganizationPreferenceMutation>;
export type SaveOrganizationPreferenceMutationOptions = Apollo.BaseMutationOptions<SaveOrganizationPreferenceMutation, SaveOrganizationPreferenceMutationVariables>;
export const UpdatePartialOrganizationPreferenceDocument = gql`
    mutation UpdatePartialOrganizationPreference($key: ID!, $value: JSON!) {
  updatePartialOrganizationPreference(key: $key, value: $value)
}
    `;
export type UpdatePartialOrganizationPreferenceMutationFn = Apollo.MutationFunction<UpdatePartialOrganizationPreferenceMutation, UpdatePartialOrganizationPreferenceMutationVariables>;

/**
 * __useUpdatePartialOrganizationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePartialOrganizationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartialOrganizationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartialOrganizationPreferenceMutation, { data, loading, error }] = useUpdatePartialOrganizationPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdatePartialOrganizationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartialOrganizationPreferenceMutation, UpdatePartialOrganizationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartialOrganizationPreferenceMutation, UpdatePartialOrganizationPreferenceMutationVariables>(UpdatePartialOrganizationPreferenceDocument, options);
      }
export type UpdatePartialOrganizationPreferenceMutationHookResult = ReturnType<typeof useUpdatePartialOrganizationPreferenceMutation>;
export type UpdatePartialOrganizationPreferenceMutationResult = Apollo.MutationResult<UpdatePartialOrganizationPreferenceMutation>;
export type UpdatePartialOrganizationPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdatePartialOrganizationPreferenceMutation, UpdatePartialOrganizationPreferenceMutationVariables>;
export const RemovePartialOrganizationPreferenceDocument = gql`
    mutation RemovePartialOrganizationPreference($key: ID!, $valueId: String!) {
  removePartialOrganizationPreference(key: $key, valueId: $valueId)
}
    `;
export type RemovePartialOrganizationPreferenceMutationFn = Apollo.MutationFunction<RemovePartialOrganizationPreferenceMutation, RemovePartialOrganizationPreferenceMutationVariables>;

/**
 * __useRemovePartialOrganizationPreferenceMutation__
 *
 * To run a mutation, you first call `useRemovePartialOrganizationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartialOrganizationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartialOrganizationPreferenceMutation, { data, loading, error }] = useRemovePartialOrganizationPreferenceMutation({
 *   variables: {
 *      key: // value for 'key'
 *      valueId: // value for 'valueId'
 *   },
 * });
 */
export function useRemovePartialOrganizationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartialOrganizationPreferenceMutation, RemovePartialOrganizationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePartialOrganizationPreferenceMutation, RemovePartialOrganizationPreferenceMutationVariables>(RemovePartialOrganizationPreferenceDocument, options);
      }
export type RemovePartialOrganizationPreferenceMutationHookResult = ReturnType<typeof useRemovePartialOrganizationPreferenceMutation>;
export type RemovePartialOrganizationPreferenceMutationResult = Apollo.MutationResult<RemovePartialOrganizationPreferenceMutation>;
export type RemovePartialOrganizationPreferenceMutationOptions = Apollo.BaseMutationOptions<RemovePartialOrganizationPreferenceMutation, RemovePartialOrganizationPreferenceMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($after: String, $before: String, $limit: Int = 10, $sortField: String, $sortOrder: SortOrder, $searchText: String) {
  users(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    searchText: $searchText
  ) {
    edges {
      node {
        id
        username
        firstName
        lastName
        email
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetKineticUserDataDocument = gql`
    query GetKineticUserData($id: ID!) {
  getKineticUser(id: $id) {
    ...SharedKineticUserFragment
  }
}
    ${SharedKineticUserFragmentFragmentDoc}`;

/**
 * __useGetKineticUserDataQuery__
 *
 * To run a query within a React component, call `useGetKineticUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKineticUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKineticUserDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKineticUserDataQuery(baseOptions: Apollo.QueryHookOptions<GetKineticUserDataQuery, GetKineticUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKineticUserDataQuery, GetKineticUserDataQueryVariables>(GetKineticUserDataDocument, options);
      }
export function useGetKineticUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKineticUserDataQuery, GetKineticUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKineticUserDataQuery, GetKineticUserDataQueryVariables>(GetKineticUserDataDocument, options);
        }
export type GetKineticUserDataQueryHookResult = ReturnType<typeof useGetKineticUserDataQuery>;
export type GetKineticUserDataLazyQueryHookResult = ReturnType<typeof useGetKineticUserDataLazyQuery>;
export type GetKineticUserDataQueryResult = Apollo.QueryResult<GetKineticUserDataQuery, GetKineticUserDataQueryVariables>;
export function refetchGetKineticUserDataQuery(variables: GetKineticUserDataQueryVariables) {
      return { query: GetKineticUserDataDocument, variables: variables }
    }
export const GetBasicUserGroupsDocument = gql`
    query GetBasicUserGroups($id: String) {
  userGroups(userId: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetBasicUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetBasicUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicUserGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasicUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetBasicUserGroupsQuery, GetBasicUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasicUserGroupsQuery, GetBasicUserGroupsQueryVariables>(GetBasicUserGroupsDocument, options);
      }
export function useGetBasicUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasicUserGroupsQuery, GetBasicUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasicUserGroupsQuery, GetBasicUserGroupsQueryVariables>(GetBasicUserGroupsDocument, options);
        }
export type GetBasicUserGroupsQueryHookResult = ReturnType<typeof useGetBasicUserGroupsQuery>;
export type GetBasicUserGroupsLazyQueryHookResult = ReturnType<typeof useGetBasicUserGroupsLazyQuery>;
export type GetBasicUserGroupsQueryResult = Apollo.QueryResult<GetBasicUserGroupsQuery, GetBasicUserGroupsQueryVariables>;
export function refetchGetBasicUserGroupsQuery(variables?: GetBasicUserGroupsQueryVariables) {
      return { query: GetBasicUserGroupsDocument, variables: variables }
    }
export const TravelTimeNameDocument = gql`
    query TravelTimeName($filters: [FilterInput!]!, $limit: Int!) {
  travelTimes(query: {filters: $filters, limit: $limit}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTravelTimeNameQuery__
 *
 * To run a query within a React component, call `useTravelTimeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelTimeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelTimeNameQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTravelTimeNameQuery(baseOptions: Apollo.QueryHookOptions<TravelTimeNameQuery, TravelTimeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TravelTimeNameQuery, TravelTimeNameQueryVariables>(TravelTimeNameDocument, options);
      }
export function useTravelTimeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TravelTimeNameQuery, TravelTimeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TravelTimeNameQuery, TravelTimeNameQueryVariables>(TravelTimeNameDocument, options);
        }
export type TravelTimeNameQueryHookResult = ReturnType<typeof useTravelTimeNameQuery>;
export type TravelTimeNameLazyQueryHookResult = ReturnType<typeof useTravelTimeNameLazyQuery>;
export type TravelTimeNameQueryResult = Apollo.QueryResult<TravelTimeNameQuery, TravelTimeNameQueryVariables>;
export function refetchTravelTimeNameQuery(variables: TravelTimeNameQueryVariables) {
      return { query: TravelTimeNameDocument, variables: variables }
    }
export const BasicUserGroupsUpdatedDocument = gql`
    subscription BasicUserGroupsUpdated($ids: [ID!]!) {
  userGroupsUpdated(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useBasicUserGroupsUpdatedSubscription__
 *
 * To run a query within a React component, call `useBasicUserGroupsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBasicUserGroupsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicUserGroupsUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBasicUserGroupsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<BasicUserGroupsUpdatedSubscription, BasicUserGroupsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BasicUserGroupsUpdatedSubscription, BasicUserGroupsUpdatedSubscriptionVariables>(BasicUserGroupsUpdatedDocument, options);
      }
export type BasicUserGroupsUpdatedSubscriptionHookResult = ReturnType<typeof useBasicUserGroupsUpdatedSubscription>;
export type BasicUserGroupsUpdatedSubscriptionResult = Apollo.SubscriptionResult<BasicUserGroupsUpdatedSubscription>;
export const GetBasicIntegrationGroupsDocument = gql`
    query GetBasicIntegrationGroups($after: String, $before: String, $limit: Int = 10, $sortField: String, $sortOrder: SortOrder, $searchText: String) {
  integrationGroups(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    searchText: $searchText
  ) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useGetBasicIntegrationGroupsQuery__
 *
 * To run a query within a React component, call `useGetBasicIntegrationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicIntegrationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicIntegrationGroupsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetBasicIntegrationGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetBasicIntegrationGroupsQuery, GetBasicIntegrationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasicIntegrationGroupsQuery, GetBasicIntegrationGroupsQueryVariables>(GetBasicIntegrationGroupsDocument, options);
      }
export function useGetBasicIntegrationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasicIntegrationGroupsQuery, GetBasicIntegrationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasicIntegrationGroupsQuery, GetBasicIntegrationGroupsQueryVariables>(GetBasicIntegrationGroupsDocument, options);
        }
export type GetBasicIntegrationGroupsQueryHookResult = ReturnType<typeof useGetBasicIntegrationGroupsQuery>;
export type GetBasicIntegrationGroupsLazyQueryHookResult = ReturnType<typeof useGetBasicIntegrationGroupsLazyQuery>;
export type GetBasicIntegrationGroupsQueryResult = Apollo.QueryResult<GetBasicIntegrationGroupsQuery, GetBasicIntegrationGroupsQueryVariables>;
export function refetchGetBasicIntegrationGroupsQuery(variables?: GetBasicIntegrationGroupsQueryVariables) {
      return { query: GetBasicIntegrationGroupsDocument, variables: variables }
    }
export const GetDefaultMapConfigDocument = gql`
    query GetDefaultMapConfig {
  mapConfig(id: "DEFAULT_MAP_BOUNDS") {
    ...MapConfigFragment
  }
}
    ${MapConfigFragmentFragmentDoc}`;

/**
 * __useGetDefaultMapConfigQuery__
 *
 * To run a query within a React component, call `useGetDefaultMapConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultMapConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultMapConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultMapConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultMapConfigQuery, GetDefaultMapConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultMapConfigQuery, GetDefaultMapConfigQueryVariables>(GetDefaultMapConfigDocument, options);
      }
export function useGetDefaultMapConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultMapConfigQuery, GetDefaultMapConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultMapConfigQuery, GetDefaultMapConfigQueryVariables>(GetDefaultMapConfigDocument, options);
        }
export type GetDefaultMapConfigQueryHookResult = ReturnType<typeof useGetDefaultMapConfigQuery>;
export type GetDefaultMapConfigLazyQueryHookResult = ReturnType<typeof useGetDefaultMapConfigLazyQuery>;
export type GetDefaultMapConfigQueryResult = Apollo.QueryResult<GetDefaultMapConfigQuery, GetDefaultMapConfigQueryVariables>;
export function refetchGetDefaultMapConfigQuery(variables?: GetDefaultMapConfigQueryVariables) {
      return { query: GetDefaultMapConfigDocument, variables: variables }
    }
export const GetMapLayerConfigsDocument = gql`
    query GetMapLayerConfigs {
  mapLayerConfigs {
    ...MapLayerConfigFragment
  }
}
    ${MapLayerConfigFragmentFragmentDoc}`;

/**
 * __useGetMapLayerConfigsQuery__
 *
 * To run a query within a React component, call `useGetMapLayerConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapLayerConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapLayerConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMapLayerConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetMapLayerConfigsQuery, GetMapLayerConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapLayerConfigsQuery, GetMapLayerConfigsQueryVariables>(GetMapLayerConfigsDocument, options);
      }
export function useGetMapLayerConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapLayerConfigsQuery, GetMapLayerConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapLayerConfigsQuery, GetMapLayerConfigsQueryVariables>(GetMapLayerConfigsDocument, options);
        }
export type GetMapLayerConfigsQueryHookResult = ReturnType<typeof useGetMapLayerConfigsQuery>;
export type GetMapLayerConfigsLazyQueryHookResult = ReturnType<typeof useGetMapLayerConfigsLazyQuery>;
export type GetMapLayerConfigsQueryResult = Apollo.QueryResult<GetMapLayerConfigsQuery, GetMapLayerConfigsQueryVariables>;
export function refetchGetMapLayerConfigsQuery(variables?: GetMapLayerConfigsQueryVariables) {
      return { query: GetMapLayerConfigsDocument, variables: variables }
    }
export const GetMapLayerConfigDocument = gql`
    query GetMapLayerConfig($id: ID!) {
  mapLayerConfig(id: $id) {
    ...MapLayerConfigFragment
  }
}
    ${MapLayerConfigFragmentFragmentDoc}`;

/**
 * __useGetMapLayerConfigQuery__
 *
 * To run a query within a React component, call `useGetMapLayerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapLayerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapLayerConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapLayerConfigQuery(baseOptions: Apollo.QueryHookOptions<GetMapLayerConfigQuery, GetMapLayerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapLayerConfigQuery, GetMapLayerConfigQueryVariables>(GetMapLayerConfigDocument, options);
      }
export function useGetMapLayerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapLayerConfigQuery, GetMapLayerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapLayerConfigQuery, GetMapLayerConfigQueryVariables>(GetMapLayerConfigDocument, options);
        }
export type GetMapLayerConfigQueryHookResult = ReturnType<typeof useGetMapLayerConfigQuery>;
export type GetMapLayerConfigLazyQueryHookResult = ReturnType<typeof useGetMapLayerConfigLazyQuery>;
export type GetMapLayerConfigQueryResult = Apollo.QueryResult<GetMapLayerConfigQuery, GetMapLayerConfigQueryVariables>;
export function refetchGetMapLayerConfigQuery(variables: GetMapLayerConfigQueryVariables) {
      return { query: GetMapLayerConfigDocument, variables: variables }
    }
export const GetMapConfigDocument = gql`
    query GetMapConfig($id: ID!) {
  mapConfig(id: $id) {
    ...MapConfigFragment
  }
}
    ${MapConfigFragmentFragmentDoc}`;

/**
 * __useGetMapConfigQuery__
 *
 * To run a query within a React component, call `useGetMapConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapConfigQuery(baseOptions: Apollo.QueryHookOptions<GetMapConfigQuery, GetMapConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapConfigQuery, GetMapConfigQueryVariables>(GetMapConfigDocument, options);
      }
export function useGetMapConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapConfigQuery, GetMapConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapConfigQuery, GetMapConfigQueryVariables>(GetMapConfigDocument, options);
        }
export type GetMapConfigQueryHookResult = ReturnType<typeof useGetMapConfigQuery>;
export type GetMapConfigLazyQueryHookResult = ReturnType<typeof useGetMapConfigLazyQuery>;
export type GetMapConfigQueryResult = Apollo.QueryResult<GetMapConfigQuery, GetMapConfigQueryVariables>;
export function refetchGetMapConfigQuery(variables: GetMapConfigQueryVariables) {
      return { query: GetMapConfigDocument, variables: variables }
    }
export const GetMapConfigsDocument = gql`
    query GetMapConfigs {
  mapConfigs {
    ...MapConfigFragment
  }
}
    ${MapConfigFragmentFragmentDoc}`;

/**
 * __useGetMapConfigsQuery__
 *
 * To run a query within a React component, call `useGetMapConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMapConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetMapConfigsQuery, GetMapConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapConfigsQuery, GetMapConfigsQueryVariables>(GetMapConfigsDocument, options);
      }
export function useGetMapConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapConfigsQuery, GetMapConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapConfigsQuery, GetMapConfigsQueryVariables>(GetMapConfigsDocument, options);
        }
export type GetMapConfigsQueryHookResult = ReturnType<typeof useGetMapConfigsQuery>;
export type GetMapConfigsLazyQueryHookResult = ReturnType<typeof useGetMapConfigsLazyQuery>;
export type GetMapConfigsQueryResult = Apollo.QueryResult<GetMapConfigsQuery, GetMapConfigsQueryVariables>;
export function refetchGetMapConfigsQuery(variables?: GetMapConfigsQueryVariables) {
      return { query: GetMapConfigsDocument, variables: variables }
    }
export const SignMessagePrioritiesDocument = gql`
    query SignMessagePriorities {
  signMessagePriorities {
    travelTimeDefault
    messageDefault
    description
    priority
    name
    id
  }
}
    `;

/**
 * __useSignMessagePrioritiesQuery__
 *
 * To run a query within a React component, call `useSignMessagePrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessagePrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessagePrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignMessagePrioritiesQuery(baseOptions?: Apollo.QueryHookOptions<SignMessagePrioritiesQuery, SignMessagePrioritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessagePrioritiesQuery, SignMessagePrioritiesQueryVariables>(SignMessagePrioritiesDocument, options);
      }
export function useSignMessagePrioritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessagePrioritiesQuery, SignMessagePrioritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessagePrioritiesQuery, SignMessagePrioritiesQueryVariables>(SignMessagePrioritiesDocument, options);
        }
export type SignMessagePrioritiesQueryHookResult = ReturnType<typeof useSignMessagePrioritiesQuery>;
export type SignMessagePrioritiesLazyQueryHookResult = ReturnType<typeof useSignMessagePrioritiesLazyQuery>;
export type SignMessagePrioritiesQueryResult = Apollo.QueryResult<SignMessagePrioritiesQuery, SignMessagePrioritiesQueryVariables>;
export function refetchSignMessagePrioritiesQuery(variables?: SignMessagePrioritiesQueryVariables) {
      return { query: SignMessagePrioritiesDocument, variables: variables }
    }
export const EffectivePrivilegesDocument = gql`
    query EffectivePrivileges {
  effectivePrivileges {
    id
    privilegeType
    kineticModule
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    `;

/**
 * __useEffectivePrivilegesQuery__
 *
 * To run a query within a React component, call `useEffectivePrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectivePrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectivePrivilegesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEffectivePrivilegesQuery(baseOptions?: Apollo.QueryHookOptions<EffectivePrivilegesQuery, EffectivePrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectivePrivilegesQuery, EffectivePrivilegesQueryVariables>(EffectivePrivilegesDocument, options);
      }
export function useEffectivePrivilegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectivePrivilegesQuery, EffectivePrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectivePrivilegesQuery, EffectivePrivilegesQueryVariables>(EffectivePrivilegesDocument, options);
        }
export type EffectivePrivilegesQueryHookResult = ReturnType<typeof useEffectivePrivilegesQuery>;
export type EffectivePrivilegesLazyQueryHookResult = ReturnType<typeof useEffectivePrivilegesLazyQuery>;
export type EffectivePrivilegesQueryResult = Apollo.QueryResult<EffectivePrivilegesQuery, EffectivePrivilegesQueryVariables>;
export function refetchEffectivePrivilegesQuery(variables?: EffectivePrivilegesQueryVariables) {
      return { query: EffectivePrivilegesDocument, variables: variables }
    }
export const EffectivePrivilegesUpdatedDocument = gql`
    subscription EffectivePrivilegesUpdated {
  effectivePrivilegesUpdated {
    id
    privilegeType
    kineticModule
    restrictedIntegrations
    restrictedAreas
    restrictedEntities
  }
}
    `;

/**
 * __useEffectivePrivilegesUpdatedSubscription__
 *
 * To run a query within a React component, call `useEffectivePrivilegesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEffectivePrivilegesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectivePrivilegesUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEffectivePrivilegesUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<EffectivePrivilegesUpdatedSubscription, EffectivePrivilegesUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EffectivePrivilegesUpdatedSubscription, EffectivePrivilegesUpdatedSubscriptionVariables>(EffectivePrivilegesUpdatedDocument, options);
      }
export type EffectivePrivilegesUpdatedSubscriptionHookResult = ReturnType<typeof useEffectivePrivilegesUpdatedSubscription>;
export type EffectivePrivilegesUpdatedSubscriptionResult = Apollo.SubscriptionResult<EffectivePrivilegesUpdatedSubscription>;
export const GetCameraCapabilitiesDocument = gql`
    query GetCameraCapabilities($cameraId: ID!) {
  cameraCapabilities(cameraId: $cameraId) {
    capability
    options
  }
}
    `;

/**
 * __useGetCameraCapabilitiesQuery__
 *
 * To run a query within a React component, call `useGetCameraCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraCapabilitiesQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useGetCameraCapabilitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCameraCapabilitiesQuery, GetCameraCapabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraCapabilitiesQuery, GetCameraCapabilitiesQueryVariables>(GetCameraCapabilitiesDocument, options);
      }
export function useGetCameraCapabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraCapabilitiesQuery, GetCameraCapabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraCapabilitiesQuery, GetCameraCapabilitiesQueryVariables>(GetCameraCapabilitiesDocument, options);
        }
export type GetCameraCapabilitiesQueryHookResult = ReturnType<typeof useGetCameraCapabilitiesQuery>;
export type GetCameraCapabilitiesLazyQueryHookResult = ReturnType<typeof useGetCameraCapabilitiesLazyQuery>;
export type GetCameraCapabilitiesQueryResult = Apollo.QueryResult<GetCameraCapabilitiesQuery, GetCameraCapabilitiesQueryVariables>;
export function refetchGetCameraCapabilitiesQuery(variables: GetCameraCapabilitiesQueryVariables) {
      return { query: GetCameraCapabilitiesDocument, variables: variables }
    }
export const GetCameraDocument = gql`
    query GetCamera($id: ID!) {
  camera(id: $id) {
    ...CameraNode
  }
}
    ${CameraNodeFragmentDoc}`;

/**
 * __useGetCameraQuery__
 *
 * To run a query within a React component, call `useGetCameraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCameraQuery(baseOptions: Apollo.QueryHookOptions<GetCameraQuery, GetCameraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraQuery, GetCameraQueryVariables>(GetCameraDocument, options);
      }
export function useGetCameraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraQuery, GetCameraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraQuery, GetCameraQueryVariables>(GetCameraDocument, options);
        }
export type GetCameraQueryHookResult = ReturnType<typeof useGetCameraQuery>;
export type GetCameraLazyQueryHookResult = ReturnType<typeof useGetCameraLazyQuery>;
export type GetCameraQueryResult = Apollo.QueryResult<GetCameraQuery, GetCameraQueryVariables>;
export function refetchGetCameraQuery(variables: GetCameraQueryVariables) {
      return { query: GetCameraDocument, variables: variables }
    }
export const GetVideoTasksDocument = gql`
    query GetVideoTasks($cameraId: ID!, $from: DateTime!, $to: DateTime!, $timezone: String!) {
  videoTasks(cameraId: $cameraId, from: $from, to: $to, timezone: $timezone) {
    date
    tasks {
      ...TaskOccurrenceFragment
    }
  }
}
    ${TaskOccurrenceFragmentFragmentDoc}`;

/**
 * __useGetVideoTasksQuery__
 *
 * To run a query within a React component, call `useGetVideoTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoTasksQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useGetVideoTasksQuery(baseOptions: Apollo.QueryHookOptions<GetVideoTasksQuery, GetVideoTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoTasksQuery, GetVideoTasksQueryVariables>(GetVideoTasksDocument, options);
      }
export function useGetVideoTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoTasksQuery, GetVideoTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoTasksQuery, GetVideoTasksQueryVariables>(GetVideoTasksDocument, options);
        }
export type GetVideoTasksQueryHookResult = ReturnType<typeof useGetVideoTasksQuery>;
export type GetVideoTasksLazyQueryHookResult = ReturnType<typeof useGetVideoTasksLazyQuery>;
export type GetVideoTasksQueryResult = Apollo.QueryResult<GetVideoTasksQuery, GetVideoTasksQueryVariables>;
export function refetchGetVideoTasksQuery(variables: GetVideoTasksQueryVariables) {
      return { query: GetVideoTasksDocument, variables: variables }
    }
export const TaskUpdatedDocument = gql`
    subscription TaskUpdated($ids: [ID!], $creation: Boolean, $deletion: Boolean) {
  taskUpdated(ids: $ids, creation: $creation, deletion: $deletion) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *      creation: // value for 'creation'
 *      deletion: // value for 'deletion'
 *   },
 * });
 */
export function useTaskUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TaskUpdatedSubscription, TaskUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskUpdatedSubscription, TaskUpdatedSubscriptionVariables>(TaskUpdatedDocument, options);
      }
export type TaskUpdatedSubscriptionHookResult = ReturnType<typeof useTaskUpdatedSubscription>;
export type TaskUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskUpdatedSubscription>;
export const CameraUpdatedDocument = gql`
    subscription CameraUpdated($ids: [ID!]) {
  cameraUpdated(ids: $ids) {
    id
    name
    presets {
      id
      name
      presetIndex
      isHomePreset
    }
  }
}
    `;

/**
 * __useCameraUpdatedSubscription__
 *
 * To run a query within a React component, call `useCameraUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCameraUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCameraUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CameraUpdatedSubscription, CameraUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CameraUpdatedSubscription, CameraUpdatedSubscriptionVariables>(CameraUpdatedDocument, options);
      }
export type CameraUpdatedSubscriptionHookResult = ReturnType<typeof useCameraUpdatedSubscription>;
export type CameraUpdatedSubscriptionResult = Apollo.SubscriptionResult<CameraUpdatedSubscription>;
export const TravelTimeUpdatedShortDocument = gql`
    subscription TravelTimeUpdatedShort {
  travelTimeUpdated {
    id
  }
}
    `;

/**
 * __useTravelTimeUpdatedShortSubscription__
 *
 * To run a query within a React component, call `useTravelTimeUpdatedShortSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTravelTimeUpdatedShortSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelTimeUpdatedShortSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTravelTimeUpdatedShortSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TravelTimeUpdatedShortSubscription, TravelTimeUpdatedShortSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TravelTimeUpdatedShortSubscription, TravelTimeUpdatedShortSubscriptionVariables>(TravelTimeUpdatedShortDocument, options);
      }
export type TravelTimeUpdatedShortSubscriptionHookResult = ReturnType<typeof useTravelTimeUpdatedShortSubscription>;
export type TravelTimeUpdatedShortSubscriptionResult = Apollo.SubscriptionResult<TravelTimeUpdatedShortSubscription>;
export const SignSpeedSettingsDocument = gql`
    query SignSpeedSettings($id: ID!) {
  sign(id: $id) {
    speedSettings {
      ...SignSpeedSettings
    }
  }
}
    ${SignSpeedSettingsFragmentDoc}`;

/**
 * __useSignSpeedSettingsQuery__
 *
 * To run a query within a React component, call `useSignSpeedSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignSpeedSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignSpeedSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignSpeedSettingsQuery(baseOptions: Apollo.QueryHookOptions<SignSpeedSettingsQuery, SignSpeedSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignSpeedSettingsQuery, SignSpeedSettingsQueryVariables>(SignSpeedSettingsDocument, options);
      }
export function useSignSpeedSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignSpeedSettingsQuery, SignSpeedSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignSpeedSettingsQuery, SignSpeedSettingsQueryVariables>(SignSpeedSettingsDocument, options);
        }
export type SignSpeedSettingsQueryHookResult = ReturnType<typeof useSignSpeedSettingsQuery>;
export type SignSpeedSettingsLazyQueryHookResult = ReturnType<typeof useSignSpeedSettingsLazyQuery>;
export type SignSpeedSettingsQueryResult = Apollo.QueryResult<SignSpeedSettingsQuery, SignSpeedSettingsQueryVariables>;
export function refetchSignSpeedSettingsQuery(variables: SignSpeedSettingsQueryVariables) {
      return { query: SignSpeedSettingsDocument, variables: variables }
    }
export const SignCapabilitiesDocument = gql`
    query SignCapabilities($id: ID!) {
  sign(id: $id) {
    capabilities
  }
}
    `;

/**
 * __useSignCapabilitiesQuery__
 *
 * To run a query within a React component, call `useSignCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignCapabilitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignCapabilitiesQuery(baseOptions: Apollo.QueryHookOptions<SignCapabilitiesQuery, SignCapabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignCapabilitiesQuery, SignCapabilitiesQueryVariables>(SignCapabilitiesDocument, options);
      }
export function useSignCapabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignCapabilitiesQuery, SignCapabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignCapabilitiesQuery, SignCapabilitiesQueryVariables>(SignCapabilitiesDocument, options);
        }
export type SignCapabilitiesQueryHookResult = ReturnType<typeof useSignCapabilitiesQuery>;
export type SignCapabilitiesLazyQueryHookResult = ReturnType<typeof useSignCapabilitiesLazyQuery>;
export type SignCapabilitiesQueryResult = Apollo.QueryResult<SignCapabilitiesQuery, SignCapabilitiesQueryVariables>;
export function refetchSignCapabilitiesQuery(variables: SignCapabilitiesQueryVariables) {
      return { query: SignCapabilitiesDocument, variables: variables }
    }
export const SignMessageValidateDocument = gql`
    query SignMessageValidate($id: ID!, $message: SignMessageInput!) {
  validateSignMessage(id: $id, message: $message) {
    failure
  }
}
    `;

/**
 * __useSignMessageValidateQuery__
 *
 * To run a query within a React component, call `useSignMessageValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessageValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessageValidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSignMessageValidateQuery(baseOptions: Apollo.QueryHookOptions<SignMessageValidateQuery, SignMessageValidateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessageValidateQuery, SignMessageValidateQueryVariables>(SignMessageValidateDocument, options);
      }
export function useSignMessageValidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessageValidateQuery, SignMessageValidateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessageValidateQuery, SignMessageValidateQueryVariables>(SignMessageValidateDocument, options);
        }
export type SignMessageValidateQueryHookResult = ReturnType<typeof useSignMessageValidateQuery>;
export type SignMessageValidateLazyQueryHookResult = ReturnType<typeof useSignMessageValidateLazyQuery>;
export type SignMessageValidateQueryResult = Apollo.QueryResult<SignMessageValidateQuery, SignMessageValidateQueryVariables>;
export function refetchSignMessageValidateQuery(variables: SignMessageValidateQueryVariables) {
      return { query: SignMessageValidateDocument, variables: variables }
    }
export const UserPreferenceUpdatedDocument = gql`
    subscription UserPreferenceUpdated($key: String!) {
  userPreferenceUpdated(key: $key) {
    userPreference {
      value
    }
  }
}
    `;

/**
 * __useUserPreferenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserPreferenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferenceUpdatedSubscription({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUserPreferenceUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserPreferenceUpdatedSubscription, UserPreferenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserPreferenceUpdatedSubscription, UserPreferenceUpdatedSubscriptionVariables>(UserPreferenceUpdatedDocument, options);
      }
export type UserPreferenceUpdatedSubscriptionHookResult = ReturnType<typeof useUserPreferenceUpdatedSubscription>;
export type UserPreferenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<UserPreferenceUpdatedSubscription>;
export const OrganizationPreferenceUpdatedDocument = gql`
    subscription OrganizationPreferenceUpdated($key: String) {
  organizationPreferenceUpdated(key: $key) {
    streamObjectOperation
    organizationPreference {
      key
      value
    }
  }
}
    `;

/**
 * __useOrganizationPreferenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useOrganizationPreferenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPreferenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPreferenceUpdatedSubscription({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useOrganizationPreferenceUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OrganizationPreferenceUpdatedSubscription, OrganizationPreferenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OrganizationPreferenceUpdatedSubscription, OrganizationPreferenceUpdatedSubscriptionVariables>(OrganizationPreferenceUpdatedDocument, options);
      }
export type OrganizationPreferenceUpdatedSubscriptionHookResult = ReturnType<typeof useOrganizationPreferenceUpdatedSubscription>;
export type OrganizationPreferenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<OrganizationPreferenceUpdatedSubscription>;
export const SignGroupsListDocument = gql`
    query SignGroupsList($after: String, $before: String, $limit: Int = 10, $sortField: String = "name", $sortOrder: SortOrder = DESCENDING, $searchText: String, $searchFields: [String!] = ["name", "type", "description"], $filters: [FilterInput]) {
  signGroups(
    query: {after: $after, before: $before, limit: $limit, sortField: $sortField, sortOrder: $sortOrder, searchText: $searchText, searchFields: $searchFields, filters: $filters}
  ) {
    edges {
      node {
        description
        attributes
        signs {
          referencePoint
          displayType {
            ...SignDisplayType
          }
          direction
          id
        }
        type
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${SignDisplayTypeFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSignGroupsListQuery__
 *
 * To run a query within a React component, call `useSignGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignGroupsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSignGroupsListQuery(baseOptions?: Apollo.QueryHookOptions<SignGroupsListQuery, SignGroupsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignGroupsListQuery, SignGroupsListQueryVariables>(SignGroupsListDocument, options);
      }
export function useSignGroupsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignGroupsListQuery, SignGroupsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignGroupsListQuery, SignGroupsListQueryVariables>(SignGroupsListDocument, options);
        }
export type SignGroupsListQueryHookResult = ReturnType<typeof useSignGroupsListQuery>;
export type SignGroupsListLazyQueryHookResult = ReturnType<typeof useSignGroupsListLazyQuery>;
export type SignGroupsListQueryResult = Apollo.QueryResult<SignGroupsListQuery, SignGroupsListQueryVariables>;
export function refetchSignGroupsListQuery(variables?: SignGroupsListQueryVariables) {
      return { query: SignGroupsListDocument, variables: variables }
    }
export const SignGroupDocument = gql`
    query SignGroup($id: ID!) {
  signGroup(id: $id) {
    description
    attributes
    signs {
      location
      name
      id
    }
    type
    name
    id
  }
}
    `;

/**
 * __useSignGroupQuery__
 *
 * To run a query within a React component, call `useSignGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignGroupQuery(baseOptions: Apollo.QueryHookOptions<SignGroupQuery, SignGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignGroupQuery, SignGroupQueryVariables>(SignGroupDocument, options);
      }
export function useSignGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignGroupQuery, SignGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignGroupQuery, SignGroupQueryVariables>(SignGroupDocument, options);
        }
export type SignGroupQueryHookResult = ReturnType<typeof useSignGroupQuery>;
export type SignGroupLazyQueryHookResult = ReturnType<typeof useSignGroupLazyQuery>;
export type SignGroupQueryResult = Apollo.QueryResult<SignGroupQuery, SignGroupQueryVariables>;
export function refetchSignGroupQuery(variables: SignGroupQueryVariables) {
      return { query: SignGroupDocument, variables: variables }
    }
export const SignListUpdatedDocument = gql`
    subscription SignListUpdated($ids: [ID!]!) {
  signUpdated(ids: $ids) {
    ...SignList
  }
}
    ${SignListFragmentDoc}`;

/**
 * __useSignListUpdatedSubscription__
 *
 * To run a query within a React component, call `useSignListUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSignListUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignListUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignListUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SignListUpdatedSubscription, SignListUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SignListUpdatedSubscription, SignListUpdatedSubscriptionVariables>(SignListUpdatedDocument, options);
      }
export type SignListUpdatedSubscriptionHookResult = ReturnType<typeof useSignListUpdatedSubscription>;
export type SignListUpdatedSubscriptionResult = Apollo.SubscriptionResult<SignListUpdatedSubscription>;
export const DetectorsListDocument = gql`
    query DetectorsList($sortOrder: SortOrder = ASCENDING, $filters: [FilterInput], $searchText: String, $sortField: String, $limit: Int = 100, $before: String, $after: String) {
  detectors(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        ...Detector
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${DetectorFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useDetectorsListQuery__
 *
 * To run a query within a React component, call `useDetectorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetectorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectorsListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      sortField: // value for 'sortField'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDetectorsListQuery(baseOptions?: Apollo.QueryHookOptions<DetectorsListQuery, DetectorsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetectorsListQuery, DetectorsListQueryVariables>(DetectorsListDocument, options);
      }
export function useDetectorsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetectorsListQuery, DetectorsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetectorsListQuery, DetectorsListQueryVariables>(DetectorsListDocument, options);
        }
export type DetectorsListQueryHookResult = ReturnType<typeof useDetectorsListQuery>;
export type DetectorsListLazyQueryHookResult = ReturnType<typeof useDetectorsListLazyQuery>;
export type DetectorsListQueryResult = Apollo.QueryResult<DetectorsListQuery, DetectorsListQueryVariables>;
export function refetchDetectorsListQuery(variables?: DetectorsListQueryVariables) {
      return { query: DetectorsListDocument, variables: variables }
    }
export const DetectorListUpdatedDocument = gql`
    subscription DetectorListUpdated($ids: [ID!]) {
  detectorUpdated(ids: $ids) {
    ...Detector
  }
}
    ${DetectorFragmentDoc}`;

/**
 * __useDetectorListUpdatedSubscription__
 *
 * To run a query within a React component, call `useDetectorListUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDetectorListUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectorListUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDetectorListUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DetectorListUpdatedSubscription, DetectorListUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DetectorListUpdatedSubscription, DetectorListUpdatedSubscriptionVariables>(DetectorListUpdatedDocument, options);
      }
export type DetectorListUpdatedSubscriptionHookResult = ReturnType<typeof useDetectorListUpdatedSubscription>;
export type DetectorListUpdatedSubscriptionResult = Apollo.SubscriptionResult<DetectorListUpdatedSubscription>;
export const CamerasDocument = gql`
    query Cameras($after: String, $before: String, $limit: Int = 800, $sortField: String = "id", $sortOrder: SortOrder = ASCENDING, $searchText: String, $filters: [FilterInput!]) {
  cameras(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    searchText: $searchText
    filters: $filters
  ) {
    edges {
      node {
        ...CameraNode
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CameraNodeFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useCamerasQuery__
 *
 * To run a query within a React component, call `useCamerasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamerasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamerasQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCamerasQuery(baseOptions?: Apollo.QueryHookOptions<CamerasQuery, CamerasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CamerasQuery, CamerasQueryVariables>(CamerasDocument, options);
      }
export function useCamerasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CamerasQuery, CamerasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CamerasQuery, CamerasQueryVariables>(CamerasDocument, options);
        }
export type CamerasQueryHookResult = ReturnType<typeof useCamerasQuery>;
export type CamerasLazyQueryHookResult = ReturnType<typeof useCamerasLazyQuery>;
export type CamerasQueryResult = Apollo.QueryResult<CamerasQuery, CamerasQueryVariables>;
export function refetchCamerasQuery(variables?: CamerasQueryVariables) {
      return { query: CamerasDocument, variables: variables }
    }
export const ContactListShortDocument = gql`
    query ContactListShort($after: String, $before: String, $limit: Int = 10000000, $sortField: String = "firstName", $sortOrder: SortOrder = ASCENDING, $searchText: String, $searchFields: [String] = ["firstName", "lastName", "email"], $filters: [FilterInput!]) {
  contacts(
    query: {after: $after, before: $before, limit: $limit, sortField: $sortField, sortOrder: $sortOrder, searchText: $searchText, searchFields: $searchFields, filters: $filters}
  ) {
    edges {
      node {
        id
        firstName
        lastName
        email
        workNumber
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useContactListShortQuery__
 *
 * To run a query within a React component, call `useContactListShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListShortQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactListShortQuery(baseOptions?: Apollo.QueryHookOptions<ContactListShortQuery, ContactListShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactListShortQuery, ContactListShortQueryVariables>(ContactListShortDocument, options);
      }
export function useContactListShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListShortQuery, ContactListShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactListShortQuery, ContactListShortQueryVariables>(ContactListShortDocument, options);
        }
export type ContactListShortQueryHookResult = ReturnType<typeof useContactListShortQuery>;
export type ContactListShortLazyQueryHookResult = ReturnType<typeof useContactListShortLazyQuery>;
export type ContactListShortQueryResult = Apollo.QueryResult<ContactListShortQuery, ContactListShortQueryVariables>;
export function refetchContactListShortQuery(variables?: ContactListShortQueryVariables) {
      return { query: ContactListShortDocument, variables: variables }
    }
export const ContactGroupShortListDocument = gql`
    query ContactGroupShortList($limit: Int = 1000000, $sortField: String = "name", $sortOrder: SortOrder = ASCENDING, $searchText: String, $searchFields: [String] = ["name"]) {
  contactGroupsQuery(
    query: {limit: $limit, sortField: $sortField, sortOrder: $sortOrder, searchText: $searchText, searchFields: $searchFields}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useContactGroupShortListQuery__
 *
 * To run a query within a React component, call `useContactGroupShortListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactGroupShortListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactGroupShortListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *   },
 * });
 */
export function useContactGroupShortListQuery(baseOptions?: Apollo.QueryHookOptions<ContactGroupShortListQuery, ContactGroupShortListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactGroupShortListQuery, ContactGroupShortListQueryVariables>(ContactGroupShortListDocument, options);
      }
export function useContactGroupShortListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactGroupShortListQuery, ContactGroupShortListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactGroupShortListQuery, ContactGroupShortListQueryVariables>(ContactGroupShortListDocument, options);
        }
export type ContactGroupShortListQueryHookResult = ReturnType<typeof useContactGroupShortListQuery>;
export type ContactGroupShortListLazyQueryHookResult = ReturnType<typeof useContactGroupShortListLazyQuery>;
export type ContactGroupShortListQueryResult = Apollo.QueryResult<ContactGroupShortListQuery, ContactGroupShortListQueryVariables>;
export function refetchContactGroupShortListQuery(variables?: ContactGroupShortListQueryVariables) {
      return { query: ContactGroupShortListDocument, variables: variables }
    }
export const OrganizationsListShortDocument = gql`
    query OrganizationsListShort($limit: Int = 1000000, $sortField: String = "name", $searchFields: [String] = ["name"], $sortOrder: SortOrder = ASCENDING, $searchText: String) {
  organizationsContact(
    query: {limit: $limit, sortField: $sortField, sortOrder: $sortOrder, searchText: $searchText, searchFields: $searchFields}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useOrganizationsListShortQuery__
 *
 * To run a query within a React component, call `useOrganizationsListShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListShortQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      searchFields: // value for 'searchFields'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOrganizationsListShortQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsListShortQuery, OrganizationsListShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsListShortQuery, OrganizationsListShortQueryVariables>(OrganizationsListShortDocument, options);
      }
export function useOrganizationsListShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsListShortQuery, OrganizationsListShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsListShortQuery, OrganizationsListShortQueryVariables>(OrganizationsListShortDocument, options);
        }
export type OrganizationsListShortQueryHookResult = ReturnType<typeof useOrganizationsListShortQuery>;
export type OrganizationsListShortLazyQueryHookResult = ReturnType<typeof useOrganizationsListShortLazyQuery>;
export type OrganizationsListShortQueryResult = Apollo.QueryResult<OrganizationsListShortQuery, OrganizationsListShortQueryVariables>;
export function refetchOrganizationsListShortQuery(variables?: OrganizationsListShortQueryVariables) {
      return { query: OrganizationsListShortDocument, variables: variables }
    }
export const ContactUpdatedSubscriptionDocument = gql`
    subscription ContactUpdatedSubscription($ids: [ID!]) {
  contactUpdated(ids: $ids) {
    streamObjectOperation
    dto {
      id
    }
  }
}
    `;

/**
 * __useContactUpdatedSubscriptionSubscription__
 *
 * To run a query within a React component, call `useContactUpdatedSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useContactUpdatedSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUpdatedSubscriptionSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useContactUpdatedSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ContactUpdatedSubscriptionSubscription, ContactUpdatedSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ContactUpdatedSubscriptionSubscription, ContactUpdatedSubscriptionSubscriptionVariables>(ContactUpdatedSubscriptionDocument, options);
      }
export type ContactUpdatedSubscriptionSubscriptionHookResult = ReturnType<typeof useContactUpdatedSubscriptionSubscription>;
export type ContactUpdatedSubscriptionSubscriptionResult = Apollo.SubscriptionResult<ContactUpdatedSubscriptionSubscription>;
export const ActivityLogsDocument = gql`
    query ActivityLogs($after: String, $before: String, $limit: Int = 100, $searchText: String, $searchFields: [String!] = ["entityId", "userId", "description", "object.name", "userFullName", "action"], $sortOrder: SortOrder, $filters: [FilterInput!]!) {
  activityLogs(
    after: $after
    before: $before
    limit: $limit
    searchText: $searchText
    filters: $filters
    searchFields: $searchFields
    sortOrder: $sortOrder
  ) {
    edges {
      node {
        ...ActivityLogNodeFragment
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${ActivityLogNodeFragmentFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useActivityLogsQuery__
 *
 * To run a query within a React component, call `useActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useActivityLogsQuery(baseOptions: Apollo.QueryHookOptions<ActivityLogsQuery, ActivityLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityLogsQuery, ActivityLogsQueryVariables>(ActivityLogsDocument, options);
      }
export function useActivityLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityLogsQuery, ActivityLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityLogsQuery, ActivityLogsQueryVariables>(ActivityLogsDocument, options);
        }
export type ActivityLogsQueryHookResult = ReturnType<typeof useActivityLogsQuery>;
export type ActivityLogsLazyQueryHookResult = ReturnType<typeof useActivityLogsLazyQuery>;
export type ActivityLogsQueryResult = Apollo.QueryResult<ActivityLogsQuery, ActivityLogsQueryVariables>;
export function refetchActivityLogsQuery(variables: ActivityLogsQueryVariables) {
      return { query: ActivityLogsDocument, variables: variables }
    }
export const ActivityLogAddedDocument = gql`
    subscription ActivityLogAdded($ids: [ID!]) {
  addedActivityLog(ids: $ids) {
    ...ActivityLogNodeFragment
  }
}
    ${ActivityLogNodeFragmentFragmentDoc}`;

/**
 * __useActivityLogAddedSubscription__
 *
 * To run a query within a React component, call `useActivityLogAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogAddedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useActivityLogAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ActivityLogAddedSubscription, ActivityLogAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ActivityLogAddedSubscription, ActivityLogAddedSubscriptionVariables>(ActivityLogAddedDocument, options);
      }
export type ActivityLogAddedSubscriptionHookResult = ReturnType<typeof useActivityLogAddedSubscription>;
export type ActivityLogAddedSubscriptionResult = Apollo.SubscriptionResult<ActivityLogAddedSubscription>;
export const DataSourceConfigurationListDocument = gql`
    query DataSourceConfigurationList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $module: KineticModule, $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  dataSourceConfigs(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, module: $module, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        ...DataSourceConfig
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${DataSourceConfigFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useDataSourceConfigurationListQuery__
 *
 * To run a query within a React component, call `useDataSourceConfigurationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceConfigurationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceConfigurationListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      module: // value for 'module'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDataSourceConfigurationListQuery(baseOptions?: Apollo.QueryHookOptions<DataSourceConfigurationListQuery, DataSourceConfigurationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceConfigurationListQuery, DataSourceConfigurationListQueryVariables>(DataSourceConfigurationListDocument, options);
      }
export function useDataSourceConfigurationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceConfigurationListQuery, DataSourceConfigurationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceConfigurationListQuery, DataSourceConfigurationListQueryVariables>(DataSourceConfigurationListDocument, options);
        }
export type DataSourceConfigurationListQueryHookResult = ReturnType<typeof useDataSourceConfigurationListQuery>;
export type DataSourceConfigurationListLazyQueryHookResult = ReturnType<typeof useDataSourceConfigurationListLazyQuery>;
export type DataSourceConfigurationListQueryResult = Apollo.QueryResult<DataSourceConfigurationListQuery, DataSourceConfigurationListQueryVariables>;
export function refetchDataSourceConfigurationListQuery(variables?: DataSourceConfigurationListQueryVariables) {
      return { query: DataSourceConfigurationListDocument, variables: variables }
    }
export const DataSourceListDocument = gql`
    query DataSourceList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $enabled: Boolean = true, $filters: [FilterInput!], $searchText: String, $searchFields: [String!] = ["value", "attributes.LABEL"], $limit: Int = 10, $before: String, $after: String, $sourceId: ID) {
  dataSources(
    query: {searchText: $searchText, searchFields: $searchFields, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, formId: $sourceId, enabled: $enabled, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        ...DataSource
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${DataSourceFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useDataSourceListQuery__
 *
 * To run a query within a React component, call `useDataSourceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      enabled: // value for 'enabled'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useDataSourceListQuery(baseOptions?: Apollo.QueryHookOptions<DataSourceListQuery, DataSourceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceListQuery, DataSourceListQueryVariables>(DataSourceListDocument, options);
      }
export function useDataSourceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceListQuery, DataSourceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceListQuery, DataSourceListQueryVariables>(DataSourceListDocument, options);
        }
export type DataSourceListQueryHookResult = ReturnType<typeof useDataSourceListQuery>;
export type DataSourceListLazyQueryHookResult = ReturnType<typeof useDataSourceListLazyQuery>;
export type DataSourceListQueryResult = Apollo.QueryResult<DataSourceListQuery, DataSourceListQueryVariables>;
export function refetchDataSourceListQuery(variables?: DataSourceListQueryVariables) {
      return { query: DataSourceListDocument, variables: variables }
    }
export const DataSourceConfigDetailDocument = gql`
    query DataSourceConfigDetail($id: ID!) {
  dataSourceConfig(id: $id) {
    ...DataSourceConfig
  }
}
    ${DataSourceConfigFragmentDoc}`;

/**
 * __useDataSourceConfigDetailQuery__
 *
 * To run a query within a React component, call `useDataSourceConfigDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceConfigDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceConfigDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataSourceConfigDetailQuery(baseOptions: Apollo.QueryHookOptions<DataSourceConfigDetailQuery, DataSourceConfigDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceConfigDetailQuery, DataSourceConfigDetailQueryVariables>(DataSourceConfigDetailDocument, options);
      }
export function useDataSourceConfigDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceConfigDetailQuery, DataSourceConfigDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceConfigDetailQuery, DataSourceConfigDetailQueryVariables>(DataSourceConfigDetailDocument, options);
        }
export type DataSourceConfigDetailQueryHookResult = ReturnType<typeof useDataSourceConfigDetailQuery>;
export type DataSourceConfigDetailLazyQueryHookResult = ReturnType<typeof useDataSourceConfigDetailLazyQuery>;
export type DataSourceConfigDetailQueryResult = Apollo.QueryResult<DataSourceConfigDetailQuery, DataSourceConfigDetailQueryVariables>;
export function refetchDataSourceConfigDetailQuery(variables: DataSourceConfigDetailQueryVariables) {
      return { query: DataSourceConfigDetailDocument, variables: variables }
    }
export const DataSourceDetailDocument = gql`
    query DataSourceDetail($id: ID!) {
  dataSource(id: $id) {
    ...DataSource
  }
}
    ${DataSourceFragmentDoc}`;

/**
 * __useDataSourceDetailQuery__
 *
 * To run a query within a React component, call `useDataSourceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataSourceDetailQuery(baseOptions: Apollo.QueryHookOptions<DataSourceDetailQuery, DataSourceDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceDetailQuery, DataSourceDetailQueryVariables>(DataSourceDetailDocument, options);
      }
export function useDataSourceDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceDetailQuery, DataSourceDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceDetailQuery, DataSourceDetailQueryVariables>(DataSourceDetailDocument, options);
        }
export type DataSourceDetailQueryHookResult = ReturnType<typeof useDataSourceDetailQuery>;
export type DataSourceDetailLazyQueryHookResult = ReturnType<typeof useDataSourceDetailLazyQuery>;
export type DataSourceDetailQueryResult = Apollo.QueryResult<DataSourceDetailQuery, DataSourceDetailQueryVariables>;
export function refetchDataSourceDetailQuery(variables: DataSourceDetailQueryVariables) {
      return { query: DataSourceDetailDocument, variables: variables }
    }
export const DataSourceConfigNameDocument = gql`
    query DataSourceConfigName($id: ID!) {
  dataSourceConfig(id: $id) {
    name
  }
}
    `;

/**
 * __useDataSourceConfigNameQuery__
 *
 * To run a query within a React component, call `useDataSourceConfigNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceConfigNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceConfigNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataSourceConfigNameQuery(baseOptions: Apollo.QueryHookOptions<DataSourceConfigNameQuery, DataSourceConfigNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourceConfigNameQuery, DataSourceConfigNameQueryVariables>(DataSourceConfigNameDocument, options);
      }
export function useDataSourceConfigNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourceConfigNameQuery, DataSourceConfigNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourceConfigNameQuery, DataSourceConfigNameQueryVariables>(DataSourceConfigNameDocument, options);
        }
export type DataSourceConfigNameQueryHookResult = ReturnType<typeof useDataSourceConfigNameQuery>;
export type DataSourceConfigNameLazyQueryHookResult = ReturnType<typeof useDataSourceConfigNameLazyQuery>;
export type DataSourceConfigNameQueryResult = Apollo.QueryResult<DataSourceConfigNameQuery, DataSourceConfigNameQueryVariables>;
export function refetchDataSourceConfigNameQuery(variables: DataSourceConfigNameQueryVariables) {
      return { query: DataSourceConfigNameDocument, variables: variables }
    }
export const GetKineticFormDocument = gql`
    query GetKineticForm($id: ID!) {
  kineticForm(id: $id) {
    ...KineticFormFragment
  }
}
    ${KineticFormFragmentFragmentDoc}`;

/**
 * __useGetKineticFormQuery__
 *
 * To run a query within a React component, call `useGetKineticFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKineticFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKineticFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKineticFormQuery(baseOptions: Apollo.QueryHookOptions<GetKineticFormQuery, GetKineticFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKineticFormQuery, GetKineticFormQueryVariables>(GetKineticFormDocument, options);
      }
export function useGetKineticFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKineticFormQuery, GetKineticFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKineticFormQuery, GetKineticFormQueryVariables>(GetKineticFormDocument, options);
        }
export type GetKineticFormQueryHookResult = ReturnType<typeof useGetKineticFormQuery>;
export type GetKineticFormLazyQueryHookResult = ReturnType<typeof useGetKineticFormLazyQuery>;
export type GetKineticFormQueryResult = Apollo.QueryResult<GetKineticFormQuery, GetKineticFormQueryVariables>;
export function refetchGetKineticFormQuery(variables: GetKineticFormQueryVariables) {
      return { query: GetKineticFormDocument, variables: variables }
    }
export const GetFileListDocument = gql`
    query GetFileList($after: String, $before: String, $limit: Int = 10000, $sortField: String, $sortOrder: SortOrder = ASCENDING, $filterInput: [FilterInput!], $centerId: String) {
  getFiles(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    filterInput: $filterInput
    centerId: $centerId
  ) {
    edges {
      node {
        id
        name
        size
        contentType
        path
        timestamp
        module
        key
        additionalData
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useGetFileListQuery__
 *
 * To run a query within a React component, call `useGetFileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filterInput: // value for 'filterInput'
 *      centerId: // value for 'centerId'
 *   },
 * });
 */
export function useGetFileListQuery(baseOptions?: Apollo.QueryHookOptions<GetFileListQuery, GetFileListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileListQuery, GetFileListQueryVariables>(GetFileListDocument, options);
      }
export function useGetFileListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileListQuery, GetFileListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileListQuery, GetFileListQueryVariables>(GetFileListDocument, options);
        }
export type GetFileListQueryHookResult = ReturnType<typeof useGetFileListQuery>;
export type GetFileListLazyQueryHookResult = ReturnType<typeof useGetFileListLazyQuery>;
export type GetFileListQueryResult = Apollo.QueryResult<GetFileListQuery, GetFileListQueryVariables>;
export function refetchGetFileListQuery(variables?: GetFileListQueryVariables) {
      return { query: GetFileListDocument, variables: variables }
    }
export const GetGlobalFileListDocument = gql`
    query GetGlobalFileList($after: String, $before: String, $limit: Int = 10000, $sortField: String, $sortOrder: SortOrder = ASCENDING, $filterInput: [FilterInput!]) {
  getGlobalFiles(
    after: $after
    before: $before
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
    filterInput: $filterInput
  ) {
    edges {
      node {
        id
        name
        size
        contentType
        path
        timestamp
        module
        key
        additionalData
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useGetGlobalFileListQuery__
 *
 * To run a query within a React component, call `useGetGlobalFileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalFileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalFileListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetGlobalFileListQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalFileListQuery, GetGlobalFileListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalFileListQuery, GetGlobalFileListQueryVariables>(GetGlobalFileListDocument, options);
      }
export function useGetGlobalFileListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalFileListQuery, GetGlobalFileListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalFileListQuery, GetGlobalFileListQueryVariables>(GetGlobalFileListDocument, options);
        }
export type GetGlobalFileListQueryHookResult = ReturnType<typeof useGetGlobalFileListQuery>;
export type GetGlobalFileListLazyQueryHookResult = ReturnType<typeof useGetGlobalFileListLazyQuery>;
export type GetGlobalFileListQueryResult = Apollo.QueryResult<GetGlobalFileListQuery, GetGlobalFileListQueryVariables>;
export function refetchGetGlobalFileListQuery(variables?: GetGlobalFileListQueryVariables) {
      return { query: GetGlobalFileListDocument, variables: variables }
    }
export const GetCameraSnapshotDocument = gql`
    query GetCameraSnapshot($cameraId: ID!) {
  getCameraSnapshot(cameraId: $cameraId)
}
    `;

/**
 * __useGetCameraSnapshotQuery__
 *
 * To run a query within a React component, call `useGetCameraSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraSnapshotQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useGetCameraSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetCameraSnapshotQuery, GetCameraSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraSnapshotQuery, GetCameraSnapshotQueryVariables>(GetCameraSnapshotDocument, options);
      }
export function useGetCameraSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraSnapshotQuery, GetCameraSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraSnapshotQuery, GetCameraSnapshotQueryVariables>(GetCameraSnapshotDocument, options);
        }
export type GetCameraSnapshotQueryHookResult = ReturnType<typeof useGetCameraSnapshotQuery>;
export type GetCameraSnapshotLazyQueryHookResult = ReturnType<typeof useGetCameraSnapshotLazyQuery>;
export type GetCameraSnapshotQueryResult = Apollo.QueryResult<GetCameraSnapshotQuery, GetCameraSnapshotQueryVariables>;
export function refetchGetCameraSnapshotQuery(variables: GetCameraSnapshotQueryVariables) {
      return { query: GetCameraSnapshotDocument, variables: variables }
    }
export const GetKineticFormsDocument = gql`
    query GetKineticForms($query: KineticFormInputQuery) {
  kineticForms(query: $query) {
    edges {
      node {
        ...KineticFormFragment
      }
    }
  }
}
    ${KineticFormFragmentFragmentDoc}`;

/**
 * __useGetKineticFormsQuery__
 *
 * To run a query within a React component, call `useGetKineticFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKineticFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKineticFormsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetKineticFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetKineticFormsQuery, GetKineticFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKineticFormsQuery, GetKineticFormsQueryVariables>(GetKineticFormsDocument, options);
      }
export function useGetKineticFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKineticFormsQuery, GetKineticFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKineticFormsQuery, GetKineticFormsQueryVariables>(GetKineticFormsDocument, options);
        }
export type GetKineticFormsQueryHookResult = ReturnType<typeof useGetKineticFormsQuery>;
export type GetKineticFormsLazyQueryHookResult = ReturnType<typeof useGetKineticFormsLazyQuery>;
export type GetKineticFormsQueryResult = Apollo.QueryResult<GetKineticFormsQuery, GetKineticFormsQueryVariables>;
export function refetchGetKineticFormsQuery(variables?: GetKineticFormsQueryVariables) {
      return { query: GetKineticFormsDocument, variables: variables }
    }
export const CorridorEventsDocument = gql`
    query CorridorEvents($filters: [FilterInput], $limit: Int = 10) {
  events(query: {filters: $filters, limit: $limit}) {
    edges {
      node {
        ...CorridorEvent
      }
    }
  }
}
    ${CorridorEventFragmentDoc}`;

/**
 * __useCorridorEventsQuery__
 *
 * To run a query within a React component, call `useCorridorEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorridorEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorridorEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCorridorEventsQuery(baseOptions?: Apollo.QueryHookOptions<CorridorEventsQuery, CorridorEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorridorEventsQuery, CorridorEventsQueryVariables>(CorridorEventsDocument, options);
      }
export function useCorridorEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorridorEventsQuery, CorridorEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorridorEventsQuery, CorridorEventsQueryVariables>(CorridorEventsDocument, options);
        }
export type CorridorEventsQueryHookResult = ReturnType<typeof useCorridorEventsQuery>;
export type CorridorEventsLazyQueryHookResult = ReturnType<typeof useCorridorEventsLazyQuery>;
export type CorridorEventsQueryResult = Apollo.QueryResult<CorridorEventsQuery, CorridorEventsQueryVariables>;
export function refetchCorridorEventsQuery(variables?: CorridorEventsQueryVariables) {
      return { query: CorridorEventsDocument, variables: variables }
    }
export const CorridorEventUpdatedDocument = gql`
    subscription CorridorEventUpdated($ids: [ID!]) {
  eventUpdated(ids: $ids) {
    ...CorridorEvent
  }
}
    ${CorridorEventFragmentDoc}`;

/**
 * __useCorridorEventUpdatedSubscription__
 *
 * To run a query within a React component, call `useCorridorEventUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCorridorEventUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorridorEventUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCorridorEventUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CorridorEventUpdatedSubscription, CorridorEventUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CorridorEventUpdatedSubscription, CorridorEventUpdatedSubscriptionVariables>(CorridorEventUpdatedDocument, options);
      }
export type CorridorEventUpdatedSubscriptionHookResult = ReturnType<typeof useCorridorEventUpdatedSubscription>;
export type CorridorEventUpdatedSubscriptionResult = Apollo.SubscriptionResult<CorridorEventUpdatedSubscription>;
export const UserSignalPrivilegesDocument = gql`
    query userSignalPrivileges {
  userSignalPrivileges {
    privilegeType
    restrictions
    lastUpdated
  }
}
    `;

/**
 * __useUserSignalPrivilegesQuery__
 *
 * To run a query within a React component, call `useUserSignalPrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSignalPrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSignalPrivilegesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSignalPrivilegesQuery(baseOptions?: Apollo.QueryHookOptions<UserSignalPrivilegesQuery, UserSignalPrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSignalPrivilegesQuery, UserSignalPrivilegesQueryVariables>(UserSignalPrivilegesDocument, options);
      }
export function useUserSignalPrivilegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSignalPrivilegesQuery, UserSignalPrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSignalPrivilegesQuery, UserSignalPrivilegesQueryVariables>(UserSignalPrivilegesDocument, options);
        }
export type UserSignalPrivilegesQueryHookResult = ReturnType<typeof useUserSignalPrivilegesQuery>;
export type UserSignalPrivilegesLazyQueryHookResult = ReturnType<typeof useUserSignalPrivilegesLazyQuery>;
export type UserSignalPrivilegesQueryResult = Apollo.QueryResult<UserSignalPrivilegesQuery, UserSignalPrivilegesQueryVariables>;
export function refetchUserSignalPrivilegesQuery(variables?: UserSignalPrivilegesQueryVariables) {
      return { query: UserSignalPrivilegesDocument, variables: variables }
    }
export const SignMessageHistoryDocument = gql`
    query SignMessageHistory($sortOrder: SortOrder = DESCENDING, $sortField: String = "timePosted", $filters: [FilterInput!], $startDateTime: DateTime, $endDateTime: DateTime, $association: String, $searchText: String, $limit: Int = 10, $before: String, $after: String, $id: ID!) {
  signMessageHistory(
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    association: $association
    searchText: $searchText
    sortField: $sortField
    sortOrder: $sortOrder
    filters: $filters
    before: $before
    limit: $limit
    after: $after
    id: $id
  ) {
    edges {
      node {
        ...SignMessageHistory
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${SignMessageHistoryFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSignMessageHistoryQuery__
 *
 * To run a query within a React component, call `useSignMessageHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessageHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessageHistoryQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      association: // value for 'association'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignMessageHistoryQuery(baseOptions: Apollo.QueryHookOptions<SignMessageHistoryQuery, SignMessageHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessageHistoryQuery, SignMessageHistoryQueryVariables>(SignMessageHistoryDocument, options);
      }
export function useSignMessageHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessageHistoryQuery, SignMessageHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessageHistoryQuery, SignMessageHistoryQueryVariables>(SignMessageHistoryDocument, options);
        }
export type SignMessageHistoryQueryHookResult = ReturnType<typeof useSignMessageHistoryQuery>;
export type SignMessageHistoryLazyQueryHookResult = ReturnType<typeof useSignMessageHistoryLazyQuery>;
export type SignMessageHistoryQueryResult = Apollo.QueryResult<SignMessageHistoryQuery, SignMessageHistoryQueryVariables>;
export function refetchSignMessageHistoryQuery(variables: SignMessageHistoryQueryVariables) {
      return { query: SignMessageHistoryDocument, variables: variables }
    }
export const IntersectionGroupsDocument = gql`
    query IntersectionGroups {
  intersectionGroupsUntyped
}
    `;

/**
 * __useIntersectionGroupsQuery__
 *
 * To run a query within a React component, call `useIntersectionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntersectionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntersectionGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntersectionGroupsQuery(baseOptions?: Apollo.QueryHookOptions<IntersectionGroupsQuery, IntersectionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntersectionGroupsQuery, IntersectionGroupsQueryVariables>(IntersectionGroupsDocument, options);
      }
export function useIntersectionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntersectionGroupsQuery, IntersectionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntersectionGroupsQuery, IntersectionGroupsQueryVariables>(IntersectionGroupsDocument, options);
        }
export type IntersectionGroupsQueryHookResult = ReturnType<typeof useIntersectionGroupsQuery>;
export type IntersectionGroupsLazyQueryHookResult = ReturnType<typeof useIntersectionGroupsLazyQuery>;
export type IntersectionGroupsQueryResult = Apollo.QueryResult<IntersectionGroupsQuery, IntersectionGroupsQueryVariables>;
export function refetchIntersectionGroupsQuery(variables?: IntersectionGroupsQueryVariables) {
      return { query: IntersectionGroupsDocument, variables: variables }
    }
export const CommunicationLogListDocument = gql`
    query CommunicationLogList($sortOrder: SortOrder = DESCENDING, $sortField: String = "reported", $filters: [FilterInput], $searchText: String, $searchFields: [String] = ["reason", "detailsType", "discussion"], $limit: Int = 10, $before: String, $after: String) {
  communicationLogs(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after, searchFields: $searchFields}
  ) {
    edges {
      node {
        ...CommunicationLog
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CommunicationLogFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useCommunicationLogListQuery__
 *
 * To run a query within a React component, call `useCommunicationLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationLogListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCommunicationLogListQuery(baseOptions?: Apollo.QueryHookOptions<CommunicationLogListQuery, CommunicationLogListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunicationLogListQuery, CommunicationLogListQueryVariables>(CommunicationLogListDocument, options);
      }
export function useCommunicationLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunicationLogListQuery, CommunicationLogListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunicationLogListQuery, CommunicationLogListQueryVariables>(CommunicationLogListDocument, options);
        }
export type CommunicationLogListQueryHookResult = ReturnType<typeof useCommunicationLogListQuery>;
export type CommunicationLogListLazyQueryHookResult = ReturnType<typeof useCommunicationLogListLazyQuery>;
export type CommunicationLogListQueryResult = Apollo.QueryResult<CommunicationLogListQuery, CommunicationLogListQueryVariables>;
export function refetchCommunicationLogListQuery(variables?: CommunicationLogListQueryVariables) {
      return { query: CommunicationLogListDocument, variables: variables }
    }
export const EventReviewDocument = gql`
    query EventReview($sortOrder: SortOrder = ASCENDING, $sortField: String = "timestamp", $filters: [FilterInput!]!, $entityIds: [String!]!, $limit: Int = 25, $before: String, $after: String, $searchText: String, $searchFields: [String!]) {
  history(
    entityIds: $entityIds
    sortField: $sortField
    sortOrder: $sortOrder
    filters: $filters
    before: $before
    after: $after
    limit: $limit
    searchText: $searchText
    searchFields: $searchFields
  ) {
    edges {
      node {
        id
        type
        entityId
        entity
        timestamp
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useEventReviewQuery__
 *
 * To run a query within a React component, call `useEventReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventReviewQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      entityIds: // value for 'entityIds'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      searchText: // value for 'searchText'
 *      searchFields: // value for 'searchFields'
 *   },
 * });
 */
export function useEventReviewQuery(baseOptions: Apollo.QueryHookOptions<EventReviewQuery, EventReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventReviewQuery, EventReviewQueryVariables>(EventReviewDocument, options);
      }
export function useEventReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventReviewQuery, EventReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventReviewQuery, EventReviewQueryVariables>(EventReviewDocument, options);
        }
export type EventReviewQueryHookResult = ReturnType<typeof useEventReviewQuery>;
export type EventReviewLazyQueryHookResult = ReturnType<typeof useEventReviewLazyQuery>;
export type EventReviewQueryResult = Apollo.QueryResult<EventReviewQuery, EventReviewQueryVariables>;
export function refetchEventReviewQuery(variables: EventReviewQueryVariables) {
      return { query: EventReviewDocument, variables: variables }
    }
export const GetCommunicationLogDocument = gql`
    query GetCommunicationLog($id: ID!) {
  communicationLog(id: $id) {
    ...CommunicationLog
  }
}
    ${CommunicationLogFragmentDoc}`;

/**
 * __useGetCommunicationLogQuery__
 *
 * To run a query within a React component, call `useGetCommunicationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunicationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunicationLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommunicationLogQuery(baseOptions: Apollo.QueryHookOptions<GetCommunicationLogQuery, GetCommunicationLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunicationLogQuery, GetCommunicationLogQueryVariables>(GetCommunicationLogDocument, options);
      }
export function useGetCommunicationLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunicationLogQuery, GetCommunicationLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunicationLogQuery, GetCommunicationLogQueryVariables>(GetCommunicationLogDocument, options);
        }
export type GetCommunicationLogQueryHookResult = ReturnType<typeof useGetCommunicationLogQuery>;
export type GetCommunicationLogLazyQueryHookResult = ReturnType<typeof useGetCommunicationLogLazyQuery>;
export type GetCommunicationLogQueryResult = Apollo.QueryResult<GetCommunicationLogQuery, GetCommunicationLogQueryVariables>;
export function refetchGetCommunicationLogQuery(variables: GetCommunicationLogQueryVariables) {
      return { query: GetCommunicationLogDocument, variables: variables }
    }
export const CommunicationLogUpdatedDocument = gql`
    subscription CommunicationLogUpdated($ids: [ID!]) {
  communicationLogUpdated(ids: $ids) {
    dto {
      ...CommunicationLog
    }
    streamObjectOperation
  }
}
    ${CommunicationLogFragmentDoc}`;

/**
 * __useCommunicationLogUpdatedSubscription__
 *
 * To run a query within a React component, call `useCommunicationLogUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationLogUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationLogUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCommunicationLogUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CommunicationLogUpdatedSubscription, CommunicationLogUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CommunicationLogUpdatedSubscription, CommunicationLogUpdatedSubscriptionVariables>(CommunicationLogUpdatedDocument, options);
      }
export type CommunicationLogUpdatedSubscriptionHookResult = ReturnType<typeof useCommunicationLogUpdatedSubscription>;
export type CommunicationLogUpdatedSubscriptionResult = Apollo.SubscriptionResult<CommunicationLogUpdatedSubscription>;
export const ShortEventListDocument = gql`
    query ShortEventList($sortOrder: SortOrder = DESCENDING, $filters: [FilterInput], $searchText: String, $sortField: String = "lastUpdated", $limit: Int = 10000000, $before: String, $after: String) {
  events(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, after: $after, limit: $limit}
  ) {
    edges {
      node {
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useShortEventListQuery__
 *
 * To run a query within a React component, call `useShortEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortEventListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      sortField: // value for 'sortField'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useShortEventListQuery(baseOptions?: Apollo.QueryHookOptions<ShortEventListQuery, ShortEventListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortEventListQuery, ShortEventListQueryVariables>(ShortEventListDocument, options);
      }
export function useShortEventListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortEventListQuery, ShortEventListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortEventListQuery, ShortEventListQueryVariables>(ShortEventListDocument, options);
        }
export type ShortEventListQueryHookResult = ReturnType<typeof useShortEventListQuery>;
export type ShortEventListLazyQueryHookResult = ReturnType<typeof useShortEventListLazyQuery>;
export type ShortEventListQueryResult = Apollo.QueryResult<ShortEventListQuery, ShortEventListQueryVariables>;
export function refetchShortEventListQuery(variables?: ShortEventListQueryVariables) {
      return { query: ShortEventListDocument, variables: variables }
    }
export const KineticEventFormDocument = gql`
    query KineticEventForm($category: String!) {
  kineticEventForm(category: $category) {
    ...KineticFormFragment
  }
}
    ${KineticFormFragmentFragmentDoc}`;

/**
 * __useKineticEventFormQuery__
 *
 * To run a query within a React component, call `useKineticEventFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticEventFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticEventFormQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useKineticEventFormQuery(baseOptions: Apollo.QueryHookOptions<KineticEventFormQuery, KineticEventFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticEventFormQuery, KineticEventFormQueryVariables>(KineticEventFormDocument, options);
      }
export function useKineticEventFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticEventFormQuery, KineticEventFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticEventFormQuery, KineticEventFormQueryVariables>(KineticEventFormDocument, options);
        }
export type KineticEventFormQueryHookResult = ReturnType<typeof useKineticEventFormQuery>;
export type KineticEventFormLazyQueryHookResult = ReturnType<typeof useKineticEventFormLazyQuery>;
export type KineticEventFormQueryResult = Apollo.QueryResult<KineticEventFormQuery, KineticEventFormQueryVariables>;
export function refetchKineticEventFormQuery(variables: KineticEventFormQueryVariables) {
      return { query: KineticEventFormDocument, variables: variables }
    }
export const EventListUpdatedDocument = gql`
    subscription EventListUpdated($ids: [ID!]!) {
  eventUpdated(ids: $ids) {
    ...EventList
  }
}
    ${EventListFragmentDoc}`;

/**
 * __useEventListUpdatedSubscription__
 *
 * To run a query within a React component, call `useEventListUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventListUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useEventListUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<EventListUpdatedSubscription, EventListUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EventListUpdatedSubscription, EventListUpdatedSubscriptionVariables>(EventListUpdatedDocument, options);
      }
export type EventListUpdatedSubscriptionHookResult = ReturnType<typeof useEventListUpdatedSubscription>;
export type EventListUpdatedSubscriptionResult = Apollo.SubscriptionResult<EventListUpdatedSubscription>;
export const EventTypesDocument = gql`
    query EventTypes($sortOrder: SortOrder = ASCENDING, $sortField: String = "value", $searchText: String, $category: Object!, $limit: Int = 10, $before: String, $after: String) {
  dataSources(
    query: {filters: [{field: "attributes.category", value: $category, operator: EQUALS}], searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, formId: "eventType", before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        enabled
        value
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useEventTypesQuery__
 *
 * To run a query within a React component, call `useEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      searchText: // value for 'searchText'
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEventTypesQuery(baseOptions: Apollo.QueryHookOptions<EventTypesQuery, EventTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventTypesQuery, EventTypesQueryVariables>(EventTypesDocument, options);
      }
export function useEventTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventTypesQuery, EventTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventTypesQuery, EventTypesQueryVariables>(EventTypesDocument, options);
        }
export type EventTypesQueryHookResult = ReturnType<typeof useEventTypesQuery>;
export type EventTypesLazyQueryHookResult = ReturnType<typeof useEventTypesLazyQuery>;
export type EventTypesQueryResult = Apollo.QueryResult<EventTypesQuery, EventTypesQueryVariables>;
export function refetchEventTypesQuery(variables: EventTypesQueryVariables) {
      return { query: EventTypesDocument, variables: variables }
    }
export const SignFontsDocument = gql`
    query SignFonts {
  signFonts {
    characterSpacing
    characterHeight
    fontFileName
    lineSpacing
    fontVersion
    font
    id
  }
}
    `;

/**
 * __useSignFontsQuery__
 *
 * To run a query within a React component, call `useSignFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignFontsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignFontsQuery(baseOptions?: Apollo.QueryHookOptions<SignFontsQuery, SignFontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignFontsQuery, SignFontsQueryVariables>(SignFontsDocument, options);
      }
export function useSignFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignFontsQuery, SignFontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignFontsQuery, SignFontsQueryVariables>(SignFontsDocument, options);
        }
export type SignFontsQueryHookResult = ReturnType<typeof useSignFontsQuery>;
export type SignFontsLazyQueryHookResult = ReturnType<typeof useSignFontsLazyQuery>;
export type SignFontsQueryResult = Apollo.QueryResult<SignFontsQuery, SignFontsQueryVariables>;
export function refetchSignFontsQuery(variables?: SignFontsQueryVariables) {
      return { query: SignFontsDocument, variables: variables }
    }
export const SignControllerFontsDocument = gql`
    query SignControllerFonts($id: ID!) {
  signControllerFonts(id: $id) {
    characterSpacing
    characterHeight
    fontFileName
    lineSpacing
    fontVersion
    font
    id
  }
}
    `;

/**
 * __useSignControllerFontsQuery__
 *
 * To run a query within a React component, call `useSignControllerFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignControllerFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignControllerFontsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignControllerFontsQuery(baseOptions: Apollo.QueryHookOptions<SignControllerFontsQuery, SignControllerFontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignControllerFontsQuery, SignControllerFontsQueryVariables>(SignControllerFontsDocument, options);
      }
export function useSignControllerFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignControllerFontsQuery, SignControllerFontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignControllerFontsQuery, SignControllerFontsQueryVariables>(SignControllerFontsDocument, options);
        }
export type SignControllerFontsQueryHookResult = ReturnType<typeof useSignControllerFontsQuery>;
export type SignControllerFontsLazyQueryHookResult = ReturnType<typeof useSignControllerFontsLazyQuery>;
export type SignControllerFontsQueryResult = Apollo.QueryResult<SignControllerFontsQuery, SignControllerFontsQueryVariables>;
export function refetchSignControllerFontsQuery(variables: SignControllerFontsQueryVariables) {
      return { query: SignControllerFontsDocument, variables: variables }
    }
export const SignDefaultFontDocument = gql`
    query SignDefaultFont {
  signDefaultFont {
    characterSpacing
    characterHeight
    fontFileName
    lineSpacing
    fontVersion
    font
    id
  }
}
    `;

/**
 * __useSignDefaultFontQuery__
 *
 * To run a query within a React component, call `useSignDefaultFontQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignDefaultFontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignDefaultFontQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignDefaultFontQuery(baseOptions?: Apollo.QueryHookOptions<SignDefaultFontQuery, SignDefaultFontQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignDefaultFontQuery, SignDefaultFontQueryVariables>(SignDefaultFontDocument, options);
      }
export function useSignDefaultFontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignDefaultFontQuery, SignDefaultFontQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignDefaultFontQuery, SignDefaultFontQueryVariables>(SignDefaultFontDocument, options);
        }
export type SignDefaultFontQueryHookResult = ReturnType<typeof useSignDefaultFontQuery>;
export type SignDefaultFontLazyQueryHookResult = ReturnType<typeof useSignDefaultFontLazyQuery>;
export type SignDefaultFontQueryResult = Apollo.QueryResult<SignDefaultFontQuery, SignDefaultFontQueryVariables>;
export function refetchSignDefaultFontQuery(variables?: SignDefaultFontQueryVariables) {
      return { query: SignDefaultFontDocument, variables: variables }
    }
export const SignFontDocument = gql`
    query SignFont($hash: ID!) {
  signFont(id: $hash) {
    characterSpacing
    characterHeight
    fontFileName
    lineSpacing
    fontVersion
    font
    id
  }
}
    `;

/**
 * __useSignFontQuery__
 *
 * To run a query within a React component, call `useSignFontQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignFontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignFontQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useSignFontQuery(baseOptions: Apollo.QueryHookOptions<SignFontQuery, SignFontQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignFontQuery, SignFontQueryVariables>(SignFontDocument, options);
      }
export function useSignFontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignFontQuery, SignFontQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignFontQuery, SignFontQueryVariables>(SignFontDocument, options);
        }
export type SignFontQueryHookResult = ReturnType<typeof useSignFontQuery>;
export type SignFontLazyQueryHookResult = ReturnType<typeof useSignFontLazyQuery>;
export type SignFontQueryResult = Apollo.QueryResult<SignFontQuery, SignFontQueryVariables>;
export function refetchSignFontQuery(variables: SignFontQueryVariables) {
      return { query: SignFontDocument, variables: variables }
    }
export const SignMessageLibraryDocument = gql`
    query SignMessageLibrary($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $type: SignMessageType, $searchText: String, $limit: Int = 10, $before: String, $after: String, $sign: ID) {
  signMessages(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, after: $after, limit: $limit, sign: $sign, type: $type}
  ) {
    edges {
      node {
        ...SignLibraryMessage
      }
      cursor
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${SignLibraryMessageFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSignMessageLibraryQuery__
 *
 * To run a query within a React component, call `useSignMessageLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessageLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessageLibraryQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      type: // value for 'type'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      sign: // value for 'sign'
 *   },
 * });
 */
export function useSignMessageLibraryQuery(baseOptions?: Apollo.QueryHookOptions<SignMessageLibraryQuery, SignMessageLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessageLibraryQuery, SignMessageLibraryQueryVariables>(SignMessageLibraryDocument, options);
      }
export function useSignMessageLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessageLibraryQuery, SignMessageLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessageLibraryQuery, SignMessageLibraryQueryVariables>(SignMessageLibraryDocument, options);
        }
export type SignMessageLibraryQueryHookResult = ReturnType<typeof useSignMessageLibraryQuery>;
export type SignMessageLibraryLazyQueryHookResult = ReturnType<typeof useSignMessageLibraryLazyQuery>;
export type SignMessageLibraryQueryResult = Apollo.QueryResult<SignMessageLibraryQuery, SignMessageLibraryQueryVariables>;
export function refetchSignMessageLibraryQuery(variables?: SignMessageLibraryQueryVariables) {
      return { query: SignMessageLibraryDocument, variables: variables }
    }
export const SignLibraryMessageUpdatedDocument = gql`
    subscription SignLibraryMessageUpdated {
  libraryMessageUpdated {
    ...SignLibraryMessage
  }
}
    ${SignLibraryMessageFragmentDoc}`;

/**
 * __useSignLibraryMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useSignLibraryMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSignLibraryMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignLibraryMessageUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSignLibraryMessageUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SignLibraryMessageUpdatedSubscription, SignLibraryMessageUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SignLibraryMessageUpdatedSubscription, SignLibraryMessageUpdatedSubscriptionVariables>(SignLibraryMessageUpdatedDocument, options);
      }
export type SignLibraryMessageUpdatedSubscriptionHookResult = ReturnType<typeof useSignLibraryMessageUpdatedSubscription>;
export type SignLibraryMessageUpdatedSubscriptionResult = Apollo.SubscriptionResult<SignLibraryMessageUpdatedSubscription>;
export const SignMessagesDocument = gql`
    query SignMessages($filters: [FilterInput!], $type: SignMessageType, $sortOrder: SortOrder, $searchText: String, $sortField: String, $before: String, $after: String, $limit: Int, $sign: ID!) {
  signMessageLibrary(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after, sign: $sign, type: $type}
  ) {
    edges {
      node {
        ...SignLibraryMessage
      }
      cursor
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${SignLibraryMessageFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSignMessagesQuery__
 *
 * To run a query within a React component, call `useSignMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      type: // value for 'type'
 *      sortOrder: // value for 'sortOrder'
 *      searchText: // value for 'searchText'
 *      sortField: // value for 'sortField'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      sign: // value for 'sign'
 *   },
 * });
 */
export function useSignMessagesQuery(baseOptions: Apollo.QueryHookOptions<SignMessagesQuery, SignMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessagesQuery, SignMessagesQueryVariables>(SignMessagesDocument, options);
      }
export function useSignMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessagesQuery, SignMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessagesQuery, SignMessagesQueryVariables>(SignMessagesDocument, options);
        }
export type SignMessagesQueryHookResult = ReturnType<typeof useSignMessagesQuery>;
export type SignMessagesLazyQueryHookResult = ReturnType<typeof useSignMessagesLazyQuery>;
export type SignMessagesQueryResult = Apollo.QueryResult<SignMessagesQuery, SignMessagesQueryVariables>;
export function refetchSignMessagesQuery(variables: SignMessagesQueryVariables) {
      return { query: SignMessagesDocument, variables: variables }
    }
export const SignCommandMessageDocument = gql`
    query SignCommandMessage($ids: [ID!]!, $command: String!) {
  signCommandMessage(ids: $ids, command: $command) {
    sign
    message {
      ...SignMessage
    }
  }
}
    ${SignMessageFragmentDoc}`;

/**
 * __useSignCommandMessageQuery__
 *
 * To run a query within a React component, call `useSignCommandMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignCommandMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignCommandMessageQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSignCommandMessageQuery(baseOptions: Apollo.QueryHookOptions<SignCommandMessageQuery, SignCommandMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignCommandMessageQuery, SignCommandMessageQueryVariables>(SignCommandMessageDocument, options);
      }
export function useSignCommandMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignCommandMessageQuery, SignCommandMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignCommandMessageQuery, SignCommandMessageQueryVariables>(SignCommandMessageDocument, options);
        }
export type SignCommandMessageQueryHookResult = ReturnType<typeof useSignCommandMessageQuery>;
export type SignCommandMessageLazyQueryHookResult = ReturnType<typeof useSignCommandMessageLazyQuery>;
export type SignCommandMessageQueryResult = Apollo.QueryResult<SignCommandMessageQuery, SignCommandMessageQueryVariables>;
export function refetchSignCommandMessageQuery(variables: SignCommandMessageQueryVariables) {
      return { query: SignCommandMessageDocument, variables: variables }
    }
export const GetLedEditorManagedGraphicsDocument = gql`
    query GetLedEditorManagedGraphics($id: ID!) {
  signControllerGraphics(id: $id) {
    height
    width
    image
    name
    slot
    id
  }
}
    `;

/**
 * __useGetLedEditorManagedGraphicsQuery__
 *
 * To run a query within a React component, call `useGetLedEditorManagedGraphicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedEditorManagedGraphicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedEditorManagedGraphicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLedEditorManagedGraphicsQuery(baseOptions: Apollo.QueryHookOptions<GetLedEditorManagedGraphicsQuery, GetLedEditorManagedGraphicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedEditorManagedGraphicsQuery, GetLedEditorManagedGraphicsQueryVariables>(GetLedEditorManagedGraphicsDocument, options);
      }
export function useGetLedEditorManagedGraphicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedEditorManagedGraphicsQuery, GetLedEditorManagedGraphicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedEditorManagedGraphicsQuery, GetLedEditorManagedGraphicsQueryVariables>(GetLedEditorManagedGraphicsDocument, options);
        }
export type GetLedEditorManagedGraphicsQueryHookResult = ReturnType<typeof useGetLedEditorManagedGraphicsQuery>;
export type GetLedEditorManagedGraphicsLazyQueryHookResult = ReturnType<typeof useGetLedEditorManagedGraphicsLazyQuery>;
export type GetLedEditorManagedGraphicsQueryResult = Apollo.QueryResult<GetLedEditorManagedGraphicsQuery, GetLedEditorManagedGraphicsQueryVariables>;
export function refetchGetLedEditorManagedGraphicsQuery(variables: GetLedEditorManagedGraphicsQueryVariables) {
      return { query: GetLedEditorManagedGraphicsDocument, variables: variables }
    }
export const GetSignGraphicsDocument = gql`
    query GetSignGraphics($filterInput: [FilterInput!]!) {
  getManagedFiles(filterInput: $filterInput) {
    edges {
      node {
        height
        image
        width
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetSignGraphicsQuery__
 *
 * To run a query within a React component, call `useGetSignGraphicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignGraphicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignGraphicsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetSignGraphicsQuery(baseOptions: Apollo.QueryHookOptions<GetSignGraphicsQuery, GetSignGraphicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignGraphicsQuery, GetSignGraphicsQueryVariables>(GetSignGraphicsDocument, options);
      }
export function useGetSignGraphicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignGraphicsQuery, GetSignGraphicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignGraphicsQuery, GetSignGraphicsQueryVariables>(GetSignGraphicsDocument, options);
        }
export type GetSignGraphicsQueryHookResult = ReturnType<typeof useGetSignGraphicsQuery>;
export type GetSignGraphicsLazyQueryHookResult = ReturnType<typeof useGetSignGraphicsLazyQuery>;
export type GetSignGraphicsQueryResult = Apollo.QueryResult<GetSignGraphicsQuery, GetSignGraphicsQueryVariables>;
export function refetchGetSignGraphicsQuery(variables: GetSignGraphicsQueryVariables) {
      return { query: GetSignGraphicsDocument, variables: variables }
    }
export const UpdateQueuedMessageDocument = gql`
    mutation UpdateQueuedMessage($id: ID!, $queuedMessage: SignQueuedMessageInput!) {
  updateQueuedMessage(id: $id, queuedMessage: $queuedMessage) {
    success
    failure
    id
  }
}
    `;
export type UpdateQueuedMessageMutationFn = Apollo.MutationFunction<UpdateQueuedMessageMutation, UpdateQueuedMessageMutationVariables>;

/**
 * __useUpdateQueuedMessageMutation__
 *
 * To run a mutation, you first call `useUpdateQueuedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueuedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueuedMessageMutation, { data, loading, error }] = useUpdateQueuedMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      queuedMessage: // value for 'queuedMessage'
 *   },
 * });
 */
export function useUpdateQueuedMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQueuedMessageMutation, UpdateQueuedMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQueuedMessageMutation, UpdateQueuedMessageMutationVariables>(UpdateQueuedMessageDocument, options);
      }
export type UpdateQueuedMessageMutationHookResult = ReturnType<typeof useUpdateQueuedMessageMutation>;
export type UpdateQueuedMessageMutationResult = Apollo.MutationResult<UpdateQueuedMessageMutation>;
export type UpdateQueuedMessageMutationOptions = Apollo.BaseMutationOptions<UpdateQueuedMessageMutation, UpdateQueuedMessageMutationVariables>;
export const SignSelectionListDocument = gql`
    query SignSelectionList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  signs(
    searchText: $searchText
    sortField: $sortField
    sortOrder: $sortOrder
    filters: $filters
    before: $before
    limit: $limit
    after: $after
  ) {
    edges {
      node {
        referencePoint
        lanePosition
        direction
        laneType
        displayType {
          ...SignDisplayType
        }
        road
        name
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${SignDisplayTypeFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useSignSelectionListQuery__
 *
 * To run a query within a React component, call `useSignSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSignSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<SignSelectionListQuery, SignSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignSelectionListQuery, SignSelectionListQueryVariables>(SignSelectionListDocument, options);
      }
export function useSignSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignSelectionListQuery, SignSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignSelectionListQuery, SignSelectionListQueryVariables>(SignSelectionListDocument, options);
        }
export type SignSelectionListQueryHookResult = ReturnType<typeof useSignSelectionListQuery>;
export type SignSelectionListLazyQueryHookResult = ReturnType<typeof useSignSelectionListLazyQuery>;
export type SignSelectionListQueryResult = Apollo.QueryResult<SignSelectionListQuery, SignSelectionListQueryVariables>;
export function refetchSignSelectionListQuery(variables?: SignSelectionListQueryVariables) {
      return { query: SignSelectionListDocument, variables: variables }
    }
export const RoadsDocument = gql`
    query Roads($integrationType: [IntegrationType]) {
  roads(integrationType: $integrationType)
}
    `;

/**
 * __useRoadsQuery__
 *
 * To run a query within a React component, call `useRoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadsQuery({
 *   variables: {
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useRoadsQuery(baseOptions?: Apollo.QueryHookOptions<RoadsQuery, RoadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoadsQuery, RoadsQueryVariables>(RoadsDocument, options);
      }
export function useRoadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoadsQuery, RoadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoadsQuery, RoadsQueryVariables>(RoadsDocument, options);
        }
export type RoadsQueryHookResult = ReturnType<typeof useRoadsQuery>;
export type RoadsLazyQueryHookResult = ReturnType<typeof useRoadsLazyQuery>;
export type RoadsQueryResult = Apollo.QueryResult<RoadsQuery, RoadsQueryVariables>;
export function refetchRoadsQuery(variables?: RoadsQueryVariables) {
      return { query: RoadsDocument, variables: variables }
    }
export const SignMessageDisplayTypesDocument = gql`
    query SignMessageDisplayTypes {
  signMessageDisplayTypes {
    characterHeight
    characterWidth
    maxCharacters
    defaultFont
    matrixType
    maxLines
    height
    width
    hash
  }
}
    `;

/**
 * __useSignMessageDisplayTypesQuery__
 *
 * To run a query within a React component, call `useSignMessageDisplayTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignMessageDisplayTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignMessageDisplayTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignMessageDisplayTypesQuery(baseOptions?: Apollo.QueryHookOptions<SignMessageDisplayTypesQuery, SignMessageDisplayTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignMessageDisplayTypesQuery, SignMessageDisplayTypesQueryVariables>(SignMessageDisplayTypesDocument, options);
      }
export function useSignMessageDisplayTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignMessageDisplayTypesQuery, SignMessageDisplayTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignMessageDisplayTypesQuery, SignMessageDisplayTypesQueryVariables>(SignMessageDisplayTypesDocument, options);
        }
export type SignMessageDisplayTypesQueryHookResult = ReturnType<typeof useSignMessageDisplayTypesQuery>;
export type SignMessageDisplayTypesLazyQueryHookResult = ReturnType<typeof useSignMessageDisplayTypesLazyQuery>;
export type SignMessageDisplayTypesQueryResult = Apollo.QueryResult<SignMessageDisplayTypesQuery, SignMessageDisplayTypesQueryVariables>;
export function refetchSignMessageDisplayTypesQuery(variables?: SignMessageDisplayTypesQueryVariables) {
      return { query: SignMessageDisplayTypesDocument, variables: variables }
    }
export const SignalActionSetOptionsDocument = gql`
    query SignalActionSetOptions {
  signalActionSetOptions {
    label
    value
  }
}
    `;

/**
 * __useSignalActionSetOptionsQuery__
 *
 * To run a query within a React component, call `useSignalActionSetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalActionSetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalActionSetOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignalActionSetOptionsQuery(baseOptions?: Apollo.QueryHookOptions<SignalActionSetOptionsQuery, SignalActionSetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalActionSetOptionsQuery, SignalActionSetOptionsQueryVariables>(SignalActionSetOptionsDocument, options);
      }
export function useSignalActionSetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalActionSetOptionsQuery, SignalActionSetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalActionSetOptionsQuery, SignalActionSetOptionsQueryVariables>(SignalActionSetOptionsDocument, options);
        }
export type SignalActionSetOptionsQueryHookResult = ReturnType<typeof useSignalActionSetOptionsQuery>;
export type SignalActionSetOptionsLazyQueryHookResult = ReturnType<typeof useSignalActionSetOptionsLazyQuery>;
export type SignalActionSetOptionsQueryResult = Apollo.QueryResult<SignalActionSetOptionsQuery, SignalActionSetOptionsQueryVariables>;
export function refetchSignalActionSetOptionsQuery(variables?: SignalActionSetOptionsQueryVariables) {
      return { query: SignalActionSetOptionsDocument, variables: variables }
    }
export const SignalActionSetSelectionListDocument = gql`
    query SignalActionSetSelectionList($sortOrder: SortOrder = ASCENDING, $filters: [FilterInput], $searchText: String, $sortField: String, $limit: Int = 10, $before: String, $after: String) {
  signalActionSets(
    searchText: $searchText
    sortOrder: $sortOrder
    sortField: $sortField
    filters: $filters
    before: $before
    after: $after
    limit: $limit
  ) {
    edges {
      node {
        number
        impact
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useSignalActionSetSelectionListQuery__
 *
 * To run a query within a React component, call `useSignalActionSetSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalActionSetSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalActionSetSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      sortField: // value for 'sortField'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSignalActionSetSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<SignalActionSetSelectionListQuery, SignalActionSetSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalActionSetSelectionListQuery, SignalActionSetSelectionListQueryVariables>(SignalActionSetSelectionListDocument, options);
      }
export function useSignalActionSetSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalActionSetSelectionListQuery, SignalActionSetSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalActionSetSelectionListQuery, SignalActionSetSelectionListQueryVariables>(SignalActionSetSelectionListDocument, options);
        }
export type SignalActionSetSelectionListQueryHookResult = ReturnType<typeof useSignalActionSetSelectionListQuery>;
export type SignalActionSetSelectionListLazyQueryHookResult = ReturnType<typeof useSignalActionSetSelectionListLazyQuery>;
export type SignalActionSetSelectionListQueryResult = Apollo.QueryResult<SignalActionSetSelectionListQuery, SignalActionSetSelectionListQueryVariables>;
export function refetchSignalActionSetSelectionListQuery(variables?: SignalActionSetSelectionListQueryVariables) {
      return { query: SignalActionSetSelectionListDocument, variables: variables }
    }
export const SaveResponseRuleDocument = gql`
    mutation SaveResponseRule($input: ResponseRuleInput!) {
  saveResponseRule(input: $input) {
    id
  }
}
    `;
export type SaveResponseRuleMutationFn = Apollo.MutationFunction<SaveResponseRuleMutation, SaveResponseRuleMutationVariables>;

/**
 * __useSaveResponseRuleMutation__
 *
 * To run a mutation, you first call `useSaveResponseRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResponseRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResponseRuleMutation, { data, loading, error }] = useSaveResponseRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveResponseRuleMutation(baseOptions?: Apollo.MutationHookOptions<SaveResponseRuleMutation, SaveResponseRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveResponseRuleMutation, SaveResponseRuleMutationVariables>(SaveResponseRuleDocument, options);
      }
export type SaveResponseRuleMutationHookResult = ReturnType<typeof useSaveResponseRuleMutation>;
export type SaveResponseRuleMutationResult = Apollo.MutationResult<SaveResponseRuleMutation>;
export type SaveResponseRuleMutationOptions = Apollo.BaseMutationOptions<SaveResponseRuleMutation, SaveResponseRuleMutationVariables>;
export const DeleteResponseRuleDocument = gql`
    mutation DeleteResponseRule($id: ID!) {
  deleteResponseRule(id: $id)
}
    `;
export type DeleteResponseRuleMutationFn = Apollo.MutationFunction<DeleteResponseRuleMutation, DeleteResponseRuleMutationVariables>;

/**
 * __useDeleteResponseRuleMutation__
 *
 * To run a mutation, you first call `useDeleteResponseRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResponseRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResponseRuleMutation, { data, loading, error }] = useDeleteResponseRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResponseRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResponseRuleMutation, DeleteResponseRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResponseRuleMutation, DeleteResponseRuleMutationVariables>(DeleteResponseRuleDocument, options);
      }
export type DeleteResponseRuleMutationHookResult = ReturnType<typeof useDeleteResponseRuleMutation>;
export type DeleteResponseRuleMutationResult = Apollo.MutationResult<DeleteResponseRuleMutation>;
export type DeleteResponseRuleMutationOptions = Apollo.BaseMutationOptions<DeleteResponseRuleMutation, DeleteResponseRuleMutationVariables>;
export const ResponseRulesListDocument = gql`
    query ResponseRulesList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  responseRules(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        condition {
          priority
          type
        }
        action {
          type
        }
        automatic
        enabled
        form
        name
        id
      }
    }
    totalCount
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useResponseRulesListQuery__
 *
 * To run a query within a React component, call `useResponseRulesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponseRulesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponseRulesListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useResponseRulesListQuery(baseOptions?: Apollo.QueryHookOptions<ResponseRulesListQuery, ResponseRulesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseRulesListQuery, ResponseRulesListQueryVariables>(ResponseRulesListDocument, options);
      }
export function useResponseRulesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseRulesListQuery, ResponseRulesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseRulesListQuery, ResponseRulesListQueryVariables>(ResponseRulesListDocument, options);
        }
export type ResponseRulesListQueryHookResult = ReturnType<typeof useResponseRulesListQuery>;
export type ResponseRulesListLazyQueryHookResult = ReturnType<typeof useResponseRulesListLazyQuery>;
export type ResponseRulesListQueryResult = Apollo.QueryResult<ResponseRulesListQuery, ResponseRulesListQueryVariables>;
export function refetchResponseRulesListQuery(variables?: ResponseRulesListQueryVariables) {
      return { query: ResponseRulesListDocument, variables: variables }
    }
export const KineticFormTemplateFieldsDocument = gql`
    query KineticFormTemplateFields($id: ID!) {
  kineticFormTemplateFields(id: $id) {
    label
    value
    type
  }
}
    `;

/**
 * __useKineticFormTemplateFieldsQuery__
 *
 * To run a query within a React component, call `useKineticFormTemplateFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormTemplateFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormTemplateFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKineticFormTemplateFieldsQuery(baseOptions: Apollo.QueryHookOptions<KineticFormTemplateFieldsQuery, KineticFormTemplateFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormTemplateFieldsQuery, KineticFormTemplateFieldsQueryVariables>(KineticFormTemplateFieldsDocument, options);
      }
export function useKineticFormTemplateFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormTemplateFieldsQuery, KineticFormTemplateFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormTemplateFieldsQuery, KineticFormTemplateFieldsQueryVariables>(KineticFormTemplateFieldsDocument, options);
        }
export type KineticFormTemplateFieldsQueryHookResult = ReturnType<typeof useKineticFormTemplateFieldsQuery>;
export type KineticFormTemplateFieldsLazyQueryHookResult = ReturnType<typeof useKineticFormTemplateFieldsLazyQuery>;
export type KineticFormTemplateFieldsQueryResult = Apollo.QueryResult<KineticFormTemplateFieldsQuery, KineticFormTemplateFieldsQueryVariables>;
export function refetchKineticFormTemplateFieldsQuery(variables: KineticFormTemplateFieldsQueryVariables) {
      return { query: KineticFormTemplateFieldsDocument, variables: variables }
    }
export const KineticFormOptionsListDocument = gql`
    query KineticFormOptionsList($query: KineticFormInputQuery!) {
  kineticForms(query: $query) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useKineticFormOptionsListQuery__
 *
 * To run a query within a React component, call `useKineticFormOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKineticFormOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKineticFormOptionsListQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useKineticFormOptionsListQuery(baseOptions: Apollo.QueryHookOptions<KineticFormOptionsListQuery, KineticFormOptionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KineticFormOptionsListQuery, KineticFormOptionsListQueryVariables>(KineticFormOptionsListDocument, options);
      }
export function useKineticFormOptionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KineticFormOptionsListQuery, KineticFormOptionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KineticFormOptionsListQuery, KineticFormOptionsListQueryVariables>(KineticFormOptionsListDocument, options);
        }
export type KineticFormOptionsListQueryHookResult = ReturnType<typeof useKineticFormOptionsListQuery>;
export type KineticFormOptionsListLazyQueryHookResult = ReturnType<typeof useKineticFormOptionsListLazyQuery>;
export type KineticFormOptionsListQueryResult = Apollo.QueryResult<KineticFormOptionsListQuery, KineticFormOptionsListQueryVariables>;
export function refetchKineticFormOptionsListQuery(variables: KineticFormOptionsListQueryVariables) {
      return { query: KineticFormOptionsListDocument, variables: variables }
    }
export const ResponseRuleDocument = gql`
    query ResponseRule($id: ID!) {
  responseRule(id: $id) {
    condition {
      type
      priority
      tree
      rule
    }
    action {
      resourcePlans
      actionSets
      message {
        ...SignMessage
      }
      type
    }
    automatic
    corridor
    enabled
    type
    form
    name
    id
  }
}
    ${SignMessageFragmentDoc}`;

/**
 * __useResponseRuleQuery__
 *
 * To run a query within a React component, call `useResponseRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponseRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponseRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResponseRuleQuery(baseOptions: Apollo.QueryHookOptions<ResponseRuleQuery, ResponseRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseRuleQuery, ResponseRuleQueryVariables>(ResponseRuleDocument, options);
      }
export function useResponseRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseRuleQuery, ResponseRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseRuleQuery, ResponseRuleQueryVariables>(ResponseRuleDocument, options);
        }
export type ResponseRuleQueryHookResult = ReturnType<typeof useResponseRuleQuery>;
export type ResponseRuleLazyQueryHookResult = ReturnType<typeof useResponseRuleLazyQuery>;
export type ResponseRuleQueryResult = Apollo.QueryResult<ResponseRuleQuery, ResponseRuleQueryVariables>;
export function refetchResponseRuleQuery(variables: ResponseRuleQueryVariables) {
      return { query: ResponseRuleDocument, variables: variables }
    }
export const ResourcePlanSelectionOptionsDocument = gql`
    query ResourcePlanSelectionOptions($filters: [FilterInput!]) {
  resourcePlans(query: {limit: 1000, filters: $filters}) {
    edges {
      node {
        details {
          name
        }
        id
      }
    }
  }
}
    `;

/**
 * __useResourcePlanSelectionOptionsQuery__
 *
 * To run a query within a React component, call `useResourcePlanSelectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePlanSelectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePlanSelectionOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useResourcePlanSelectionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ResourcePlanSelectionOptionsQuery, ResourcePlanSelectionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePlanSelectionOptionsQuery, ResourcePlanSelectionOptionsQueryVariables>(ResourcePlanSelectionOptionsDocument, options);
      }
export function useResourcePlanSelectionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePlanSelectionOptionsQuery, ResourcePlanSelectionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePlanSelectionOptionsQuery, ResourcePlanSelectionOptionsQueryVariables>(ResourcePlanSelectionOptionsDocument, options);
        }
export type ResourcePlanSelectionOptionsQueryHookResult = ReturnType<typeof useResourcePlanSelectionOptionsQuery>;
export type ResourcePlanSelectionOptionsLazyQueryHookResult = ReturnType<typeof useResourcePlanSelectionOptionsLazyQuery>;
export type ResourcePlanSelectionOptionsQueryResult = Apollo.QueryResult<ResourcePlanSelectionOptionsQuery, ResourcePlanSelectionOptionsQueryVariables>;
export function refetchResourcePlanSelectionOptionsQuery(variables?: ResourcePlanSelectionOptionsQueryVariables) {
      return { query: ResourcePlanSelectionOptionsDocument, variables: variables }
    }
export const ActionSetSelectionOptionsDocument = gql`
    query ActionSetSelectionOptions($filters: [FilterInput]) {
  signalActionSets(limit: 1000, filters: $filters) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useActionSetSelectionOptionsQuery__
 *
 * To run a query within a React component, call `useActionSetSelectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionSetSelectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionSetSelectionOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useActionSetSelectionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ActionSetSelectionOptionsQuery, ActionSetSelectionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionSetSelectionOptionsQuery, ActionSetSelectionOptionsQueryVariables>(ActionSetSelectionOptionsDocument, options);
      }
export function useActionSetSelectionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionSetSelectionOptionsQuery, ActionSetSelectionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionSetSelectionOptionsQuery, ActionSetSelectionOptionsQueryVariables>(ActionSetSelectionOptionsDocument, options);
        }
export type ActionSetSelectionOptionsQueryHookResult = ReturnType<typeof useActionSetSelectionOptionsQuery>;
export type ActionSetSelectionOptionsLazyQueryHookResult = ReturnType<typeof useActionSetSelectionOptionsLazyQuery>;
export type ActionSetSelectionOptionsQueryResult = Apollo.QueryResult<ActionSetSelectionOptionsQuery, ActionSetSelectionOptionsQueryVariables>;
export function refetchActionSetSelectionOptionsQuery(variables?: ActionSetSelectionOptionsQueryVariables) {
      return { query: ActionSetSelectionOptionsDocument, variables: variables }
    }
export const SignSelectionOptionsDocument = gql`
    query SignSelectionOptions($filters: [FilterInput!], $limit: Int) {
  signs(filters: $filters, limit: $limit) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useSignSelectionOptionsQuery__
 *
 * To run a query within a React component, call `useSignSelectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignSelectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignSelectionOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSignSelectionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<SignSelectionOptionsQuery, SignSelectionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignSelectionOptionsQuery, SignSelectionOptionsQueryVariables>(SignSelectionOptionsDocument, options);
      }
export function useSignSelectionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignSelectionOptionsQuery, SignSelectionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignSelectionOptionsQuery, SignSelectionOptionsQueryVariables>(SignSelectionOptionsDocument, options);
        }
export type SignSelectionOptionsQueryHookResult = ReturnType<typeof useSignSelectionOptionsQuery>;
export type SignSelectionOptionsLazyQueryHookResult = ReturnType<typeof useSignSelectionOptionsLazyQuery>;
export type SignSelectionOptionsQueryResult = Apollo.QueryResult<SignSelectionOptionsQuery, SignSelectionOptionsQueryVariables>;
export function refetchSignSelectionOptionsQuery(variables?: SignSelectionOptionsQueryVariables) {
      return { query: SignSelectionOptionsDocument, variables: variables }
    }
export const ResponseRuleConfigDocument = gql`
    query ResponseRuleConfig($conditionType: ResponseRuleConditionType!, $actionType: ResponseRuleActionType!, $form: ID) {
  responseRuleConfig(
    conditionType: $conditionType
    actionType: $actionType
    form: $form
  )
}
    `;

/**
 * __useResponseRuleConfigQuery__
 *
 * To run a query within a React component, call `useResponseRuleConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponseRuleConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponseRuleConfigQuery({
 *   variables: {
 *      conditionType: // value for 'conditionType'
 *      actionType: // value for 'actionType'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useResponseRuleConfigQuery(baseOptions: Apollo.QueryHookOptions<ResponseRuleConfigQuery, ResponseRuleConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseRuleConfigQuery, ResponseRuleConfigQueryVariables>(ResponseRuleConfigDocument, options);
      }
export function useResponseRuleConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseRuleConfigQuery, ResponseRuleConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseRuleConfigQuery, ResponseRuleConfigQueryVariables>(ResponseRuleConfigDocument, options);
        }
export type ResponseRuleConfigQueryHookResult = ReturnType<typeof useResponseRuleConfigQuery>;
export type ResponseRuleConfigLazyQueryHookResult = ReturnType<typeof useResponseRuleConfigLazyQuery>;
export type ResponseRuleConfigQueryResult = Apollo.QueryResult<ResponseRuleConfigQuery, ResponseRuleConfigQueryVariables>;
export function refetchResponseRuleConfigQuery(variables: ResponseRuleConfigQueryVariables) {
      return { query: ResponseRuleConfigDocument, variables: variables }
    }
export const CorridorIntegrationsDocument = gql`
    query CorridorIntegrations($id: ID!) {
  corridor(id: $id) {
    integrations
  }
}
    `;

/**
 * __useCorridorIntegrationsQuery__
 *
 * To run a query within a React component, call `useCorridorIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorridorIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorridorIntegrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCorridorIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<CorridorIntegrationsQuery, CorridorIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorridorIntegrationsQuery, CorridorIntegrationsQueryVariables>(CorridorIntegrationsDocument, options);
      }
export function useCorridorIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorridorIntegrationsQuery, CorridorIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorridorIntegrationsQuery, CorridorIntegrationsQueryVariables>(CorridorIntegrationsDocument, options);
        }
export type CorridorIntegrationsQueryHookResult = ReturnType<typeof useCorridorIntegrationsQuery>;
export type CorridorIntegrationsLazyQueryHookResult = ReturnType<typeof useCorridorIntegrationsLazyQuery>;
export type CorridorIntegrationsQueryResult = Apollo.QueryResult<CorridorIntegrationsQuery, CorridorIntegrationsQueryVariables>;
export function refetchCorridorIntegrationsQuery(variables: CorridorIntegrationsQueryVariables) {
      return { query: CorridorIntegrationsDocument, variables: variables }
    }
export const CameraSelectionListDocument = gql`
    query CameraSelectionList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  cameras(
    searchText: $searchText
    sortField: $sortField
    sortOrder: $sortOrder
    filters: $filters
    before: $before
    limit: $limit
    after: $after
  ) {
    edges {
      node {
        referencePoint
        direction
        road
        name
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useCameraSelectionListQuery__
 *
 * To run a query within a React component, call `useCameraSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCameraSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCameraSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<CameraSelectionListQuery, CameraSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CameraSelectionListQuery, CameraSelectionListQueryVariables>(CameraSelectionListDocument, options);
      }
export function useCameraSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CameraSelectionListQuery, CameraSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CameraSelectionListQuery, CameraSelectionListQueryVariables>(CameraSelectionListDocument, options);
        }
export type CameraSelectionListQueryHookResult = ReturnType<typeof useCameraSelectionListQuery>;
export type CameraSelectionListLazyQueryHookResult = ReturnType<typeof useCameraSelectionListLazyQuery>;
export type CameraSelectionListQueryResult = Apollo.QueryResult<CameraSelectionListQuery, CameraSelectionListQueryVariables>;
export function refetchCameraSelectionListQuery(variables?: CameraSelectionListQueryVariables) {
      return { query: CameraSelectionListDocument, variables: variables }
    }
export const DetectorSelectionListDocument = gql`
    query DetectorSelectionList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  detectors(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        referencePoint
        direction
        road
        name
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useDetectorSelectionListQuery__
 *
 * To run a query within a React component, call `useDetectorSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetectorSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectorSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDetectorSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<DetectorSelectionListQuery, DetectorSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetectorSelectionListQuery, DetectorSelectionListQueryVariables>(DetectorSelectionListDocument, options);
      }
export function useDetectorSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetectorSelectionListQuery, DetectorSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetectorSelectionListQuery, DetectorSelectionListQueryVariables>(DetectorSelectionListDocument, options);
        }
export type DetectorSelectionListQueryHookResult = ReturnType<typeof useDetectorSelectionListQuery>;
export type DetectorSelectionListLazyQueryHookResult = ReturnType<typeof useDetectorSelectionListLazyQuery>;
export type DetectorSelectionListQueryResult = Apollo.QueryResult<DetectorSelectionListQuery, DetectorSelectionListQueryVariables>;
export function refetchDetectorSelectionListQuery(variables?: DetectorSelectionListQueryVariables) {
      return { query: DetectorSelectionListDocument, variables: variables }
    }
export const ResourcePlanSelectionListDocument = gql`
    query ResourcePlanSelectionList($sortOrder: SortOrder = ASCENDING, $sortField: String = "name", $filters: [FilterInput], $searchText: String, $limit: Int = 1000, $before: String, $after: String) {
  resourcePlans(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, after: $after, limit: $limit}
  ) {
    edges {
      node {
        details {
          description
          name
        }
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useResourcePlanSelectionListQuery__
 *
 * To run a query within a React component, call `useResourcePlanSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePlanSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePlanSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useResourcePlanSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<ResourcePlanSelectionListQuery, ResourcePlanSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePlanSelectionListQuery, ResourcePlanSelectionListQueryVariables>(ResourcePlanSelectionListDocument, options);
      }
export function useResourcePlanSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePlanSelectionListQuery, ResourcePlanSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePlanSelectionListQuery, ResourcePlanSelectionListQueryVariables>(ResourcePlanSelectionListDocument, options);
        }
export type ResourcePlanSelectionListQueryHookResult = ReturnType<typeof useResourcePlanSelectionListQuery>;
export type ResourcePlanSelectionListLazyQueryHookResult = ReturnType<typeof useResourcePlanSelectionListLazyQuery>;
export type ResourcePlanSelectionListQueryResult = Apollo.QueryResult<ResourcePlanSelectionListQuery, ResourcePlanSelectionListQueryVariables>;
export function refetchResourcePlanSelectionListQuery(variables?: ResourcePlanSelectionListQueryVariables) {
      return { query: ResourcePlanSelectionListDocument, variables: variables }
    }
export const LiveResourcePlanSelectionListDocument = gql`
    subscription LiveResourcePlanSelectionList($ids: [ID!]!) {
  resourcePlanUpdated(ids: $ids) {
    details {
      description
      name
    }
    id
  }
}
    `;

/**
 * __useLiveResourcePlanSelectionListSubscription__
 *
 * To run a query within a React component, call `useLiveResourcePlanSelectionListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveResourcePlanSelectionListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveResourcePlanSelectionListSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLiveResourcePlanSelectionListSubscription(baseOptions: Apollo.SubscriptionHookOptions<LiveResourcePlanSelectionListSubscription, LiveResourcePlanSelectionListSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LiveResourcePlanSelectionListSubscription, LiveResourcePlanSelectionListSubscriptionVariables>(LiveResourcePlanSelectionListDocument, options);
      }
export type LiveResourcePlanSelectionListSubscriptionHookResult = ReturnType<typeof useLiveResourcePlanSelectionListSubscription>;
export type LiveResourcePlanSelectionListSubscriptionResult = Apollo.SubscriptionResult<LiveResourcePlanSelectionListSubscription>;
export const SignSelectionMapEntitiesDocument = gql`
    query SignSelectionMapEntities($filters: [FilterInput!]!) {
  signMapEntities(filters: $filters)
}
    `;

/**
 * __useSignSelectionMapEntitiesQuery__
 *
 * To run a query within a React component, call `useSignSelectionMapEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignSelectionMapEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignSelectionMapEntitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSignSelectionMapEntitiesQuery(baseOptions: Apollo.QueryHookOptions<SignSelectionMapEntitiesQuery, SignSelectionMapEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignSelectionMapEntitiesQuery, SignSelectionMapEntitiesQueryVariables>(SignSelectionMapEntitiesDocument, options);
      }
export function useSignSelectionMapEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignSelectionMapEntitiesQuery, SignSelectionMapEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignSelectionMapEntitiesQuery, SignSelectionMapEntitiesQueryVariables>(SignSelectionMapEntitiesDocument, options);
        }
export type SignSelectionMapEntitiesQueryHookResult = ReturnType<typeof useSignSelectionMapEntitiesQuery>;
export type SignSelectionMapEntitiesLazyQueryHookResult = ReturnType<typeof useSignSelectionMapEntitiesLazyQuery>;
export type SignSelectionMapEntitiesQueryResult = Apollo.QueryResult<SignSelectionMapEntitiesQuery, SignSelectionMapEntitiesQueryVariables>;
export function refetchSignSelectionMapEntitiesQuery(variables: SignSelectionMapEntitiesQueryVariables) {
      return { query: SignSelectionMapEntitiesDocument, variables: variables }
    }
export const TravelTimeSelectionListDocument = gql`
    query TravelTimeSelectionList($sortOrder: SortOrder = ASCENDING, $sortField: String = "id", $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  travelTimes(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        parts {
          startReference
          endReference
          direction
          road
        }
        name
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useTravelTimeSelectionListQuery__
 *
 * To run a query within a React component, call `useTravelTimeSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelTimeSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelTimeSelectionListQuery({
 *   variables: {
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTravelTimeSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<TravelTimeSelectionListQuery, TravelTimeSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TravelTimeSelectionListQuery, TravelTimeSelectionListQueryVariables>(TravelTimeSelectionListDocument, options);
      }
export function useTravelTimeSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TravelTimeSelectionListQuery, TravelTimeSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TravelTimeSelectionListQuery, TravelTimeSelectionListQueryVariables>(TravelTimeSelectionListDocument, options);
        }
export type TravelTimeSelectionListQueryHookResult = ReturnType<typeof useTravelTimeSelectionListQuery>;
export type TravelTimeSelectionListLazyQueryHookResult = ReturnType<typeof useTravelTimeSelectionListLazyQuery>;
export type TravelTimeSelectionListQueryResult = Apollo.QueryResult<TravelTimeSelectionListQuery, TravelTimeSelectionListQueryVariables>;
export function refetchTravelTimeSelectionListQuery(variables?: TravelTimeSelectionListQueryVariables) {
      return { query: TravelTimeSelectionListDocument, variables: variables }
    }
export const WeatherStationSelectionListDocument = gql`
    query WeatherStationSelectionList($sortField: WeatherStationSortField = NAME, $sortOrder: SortOrder = ASCENDING, $filters: [FilterInput!], $searchText: String, $limit: Int = 10, $before: String, $after: String) {
  weatherStations(
    query: {searchText: $searchText, sortField: $sortField, sortOrder: $sortOrder, filters: $filters, before: $before, limit: $limit, after: $after}
  ) {
    edges {
      node {
        referencePoint
        direction
        road
        name
        id
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
    totalCount
  }
}
    ${CursorPageInfoFragmentDoc}`;

/**
 * __useWeatherStationSelectionListQuery__
 *
 * To run a query within a React component, call `useWeatherStationSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherStationSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherStationSelectionListQuery({
 *   variables: {
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useWeatherStationSelectionListQuery(baseOptions?: Apollo.QueryHookOptions<WeatherStationSelectionListQuery, WeatherStationSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherStationSelectionListQuery, WeatherStationSelectionListQueryVariables>(WeatherStationSelectionListDocument, options);
      }
export function useWeatherStationSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherStationSelectionListQuery, WeatherStationSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherStationSelectionListQuery, WeatherStationSelectionListQueryVariables>(WeatherStationSelectionListDocument, options);
        }
export type WeatherStationSelectionListQueryHookResult = ReturnType<typeof useWeatherStationSelectionListQuery>;
export type WeatherStationSelectionListLazyQueryHookResult = ReturnType<typeof useWeatherStationSelectionListLazyQuery>;
export type WeatherStationSelectionListQueryResult = Apollo.QueryResult<WeatherStationSelectionListQuery, WeatherStationSelectionListQueryVariables>;
export function refetchWeatherStationSelectionListQuery(variables?: WeatherStationSelectionListQueryVariables) {
      return { query: WeatherStationSelectionListDocument, variables: variables }
    }
export const UsersDocument = gql`
    query Users {
  users(limit: 10000) {
    edges {
      node {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export function refetchUsersQuery(variables?: UsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const EventTypesListDocument = gql`
    query EventTypesList {
  eventTypes {
    edges {
      node {
        ...EventTypeShort
      }
    }
    pageInfo {
      ...CursorPageInfo
    }
  }
}
    ${EventTypeShortFragmentDoc}
${CursorPageInfoFragmentDoc}`;

/**
 * __useEventTypesListQuery__
 *
 * To run a query within a React component, call `useEventTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventTypesListQuery(baseOptions?: Apollo.QueryHookOptions<EventTypesListQuery, EventTypesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventTypesListQuery, EventTypesListQueryVariables>(EventTypesListDocument, options);
      }
export function useEventTypesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventTypesListQuery, EventTypesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventTypesListQuery, EventTypesListQueryVariables>(EventTypesListDocument, options);
        }
export type EventTypesListQueryHookResult = ReturnType<typeof useEventTypesListQuery>;
export type EventTypesListLazyQueryHookResult = ReturnType<typeof useEventTypesListLazyQuery>;
export type EventTypesListQueryResult = Apollo.QueryResult<EventTypesListQuery, EventTypesListQueryVariables>;
export function refetchEventTypesListQuery(variables?: EventTypesListQueryVariables) {
      return { query: EventTypesListDocument, variables: variables }
    }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FormSection": [
      "DetectorReportConfigFormSection",
      "IntegrationConfigFormSection",
      "IntegrationStateSection",
      "ModuleFormSection"
    ],
    "FormView": [
      "DetectorReportConfigFormView",
      "IntegrationConfigFormView",
      "IntegrationStateView",
      "ModuleSettingsFormView"
    ],
    "SceneContent": [
      "Camera",
      "Tour",
      "Widget"
    ],
    "VideoWallObject": [
      "CameraGroup",
      "Scene",
      "Screen",
      "Widget"
    ],
    "_Entity": [
      "DetectorReportConfiguration",
      "DetectorReportRule",
      "OrganizationKineticUser"
    ]
  }
};
      export default result;
    