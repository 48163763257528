import { PREFERENCE_KEYS } from 'types/preferenceTypes';
import {
  useKineticUserQuery,
  useSaveKineticUserPrefMutationMutation,
  useDeleteUserKineticPreferenceMutation,
} from 'generated/graphql';
import { useCallback } from 'react';

/** Low level hook that grabs all the user kinetic-wide preferences, cache-first */
export const useUserKineticPreferences = <TValue = Record<string, unknown>>() => {
  const { data, loading } = useKineticUserQuery({ fetchPolicy: 'cache-first' });
  const [savePreference] = useSaveKineticUserPrefMutationMutation();
  const [deletePref] = useDeleteUserKineticPreferenceMutation();

  const setPreference = useCallback(
    (key: PREFERENCE_KEYS, value: TValue) => {
      return savePreference({
        variables: { key, value },
      });
    },
    [savePreference]
  );

  const deletePreference = useCallback(
    (key: PREFERENCE_KEYS) => {
      return deletePref({ variables: { key } });
    },
    [deletePref]
  );

  return {
    data: data?.kineticUser.kineticPreferences,
    loading,
    setPreference,
    deletePreference,
  };
};
